import { denominationConstants } from '../_constants'

const initialState = {
  items: [],
  item: {},
}

function denominations(state = initialState, { type, payload }) {
  switch (type) {
    case denominationConstants.GETALL_SUCCESS:
      return { ...state, items: payload, item: {} }
    case denominationConstants.GETBYID_SUCCESS:
      return { ...state, item: payload }
    case denominationConstants.DELETE_SUCCESS:
      return { ...state }
    case denominationConstants.UPDATE_SUCCESS:
      return { ...state, items: state.items }
    case denominationConstants.ADD_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          total: state.items.total + 1,
        },
      }
    case denominationConstants.SETBYID:
      return {
        ...state,
        item: {},
      }
    case denominationConstants.SETALL:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default denominations
