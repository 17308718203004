const spinnerConstants = {
  START: 'START',
  STOP: 'STOP',
  START_GLOBAL_LOADER: 'START_GLOBAL_LOADER',
  STOP_GLOBAL_LOADER: 'STOP_GLOBAL_LOADER',
  dontStartSpinner: [
    'paginate',
    'sort',
    'filter',
    'advanced',
    'assign',
    'change-visible',
    'expand',
    'background',
  ],
  START_ON_MANAGE_PAGE: 'START_ON_MANAGE_PAGE',
  STOP_ON_MANAGE_PAGE: 'STOP_ON_MANAGE_PAGE',
}
export default spinnerConstants
