import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import v4 from 'uuid/v4'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { queryParamsActions } from '../../../../_actions'
import { apiKeysConstants } from '../../../../_constants'
import apiKeysActions from '_actions/api-keys.actions'
import { getPage, getApiKeysRows, getTotalItems } from '../../../../_selectors/api-keys.selector.js'

const { navMenu, advancedSearch } = apiKeysConstants

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '20%',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Api Key',
    dataIndex: 'uid',
    key: 'uid',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
]

const navMenuInit = [navMenu.manage, navMenu.view, navMenu.edit]

const ManageApiKeys = ({ match }) => {
  const history = useHistory()
  const { id } = useParams()
  const dispatch = useDispatch()

  const rows = useSelector(getApiKeysRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const [navMenuParse, setNavMenuParse] = useState([])
  const view = getCurrentUrl(match.url)

  useEffect(() => {
    return () => {
      dispatch({ type: apiKeysConstants.SETALL })
    }
  }, [])

  const onEditItem = () => {
    history.push({
      pathname: `${view}/edit/${id}`,
    })
  }

  const onViewItem = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  const onListItems = () => {
    dispatch(queryParamsActions.setQueryParams(null))
    history.push({
      pathname: '/clients/list',
    })
  }

  const onManageItems = () => {
    dispatch(queryParamsActions.setQueryParams(null))
    history.push({
      pathname: '/clients',
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.edit.key:
          return {
            ...menu,
            url: onEditItem,
          }
        case navMenu.view.key:
          return {
            ...menu,
            url: onViewItem,
          }
        case navMenu.list.key:
          return {
            ...menu,
            url: onListItems,
          }
        case navMenu.manage.key:
          return {
            ...menu,
            url: onManageItems,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  return (
    <NavContainer match={match} menu={navMenuParse}>
      <TableFilter
        columns={initColumns}
        data={dataSource}
        getDataAction={apiKeysActions.getAll}
        isEditOnId={false}
        actionsColum={false}
        advancedSearch={advancedSearch}
      />
    </NavContainer>
  )
}

export default ManageApiKeys
