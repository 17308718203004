import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import dayjs from 'dayjs'
import NavContainer from '../../../../components/nav-container'
import { notificationActions } from '../../../../_actions'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { notificationConstants } from '../../../../_constants'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import AdvancedCheckboxList from 'components/advanced-checkbox-list'

const { notificationStatuses, notificationColor, formItemLayout, navMenu } = notificationConstants

const navMenuInit = [navMenu.list, navMenu.view, navMenu.delete, navMenu.manage]

const EditItem = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const match = useRouteMatch()
  const view = getCurrentUrl(match.url)
  const [form] = Form.useForm()
  const { id } = useParams()

  const {
    item: { notification, user_groups: groups },
  } = useSelector(state => state.notifications)

  const [navMenuParse, setNavMenuParse] = useState([])
  const [changed, setChange] = useState(false)
  const [notificationDateStart, setNotificationDateStart] = useState('')
  const [notificationDateEnd, setNotificationDateEnd] = useState('')

  useEffect(() => {
    dispatch(notificationActions.getItemById(id))
    return () => {
      dispatch({ type: notificationConstants.SETBYID })
    }
  }, [id])

  const clientGroups = useMemo(() => groups?.map(e => ({ value: e.id, label: e.name })), [groups])

  const plainStatuses = useMemo(
    () => notificationStatuses.map(status => ({ value: status.value, label: status.text })),
    [notificationStatuses],
  )

  const plainColors = useMemo(
    () => notificationColor.map(status => ({ value: status.value, label: status.text })),
    [notificationColor],
  )

  useEffect(() => {
    form.setFieldsValue({
      ...notification,
      starts_at: notification?.starts_at ? dayjs(notification.starts_at) : null,
      ends_at: notification?.ends_at ? dayjs(notification.ends_at) : null,
      user_groups: groups?.filter(group => group.value).map(group => group.id),
    })

    setNotificationDateStart(notification?.starts_at)
    setNotificationDateEnd(notification?.ends_at)
  }, [notification, form, groups])

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToView,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  // function range(start, end) {
  //   const result = []
  //   for (let i = start; i < end; i++) {
  //     result.push(i)
  //   }
  //   return result
  // }

  // function disabledDateTime() {
  //   return {
  //     disabledMinutes: () => range(1, 60),
  //     disabledSeconds: () => range(1, 60),
  //   }
  // }

  // //////////////////
  // Methods
  // /////////////////

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(notificationActions.deleteItem(id))
      },
    })
  }

  const navigateToView = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  // //////////////////
  // Form handlers
  // /////////////////

  const onFinish = async values => {
    const mergedNotification = {
      ...values,
      id: notification.id,
      starts_at: values.starts_at?.format('YYYY-MM-DD hh:00'),
      ends_at: values.ends_at?.format('YYYY-MM-DD hh:00'),
    }

    const isAfter = moment(mergedNotification.starts_at).isAfter(mergedNotification.ends_at)

    if (isAfter && !!mergedNotification.starts_at) {
      return form.setFields([
        {
          name: 'ends_at',
          errors: ['End date is lower than start date'],
        },
      ])
    }
    const convertedNotification = convertFormValuesDeep(mergedNotification)
    await dispatch(notificationActions.updateItemById(notification.id, convertedNotification))

    return form.setFields([])
  }

  const isDateValid = useMemo(() => {
    return moment(notificationDateStart).isAfter(notificationDateEnd)
  }, [notificationDateEnd, notificationDateStart])

  const format = 'YYYY-MM-DD HH:00'

  return (
    <NavContainer menu={navMenuParse}>
      {useGlobalLoader(
        <div>
          <p>Fields with * are required.</p>
          <Form
            {...formItemLayout}
            name="editClient"
            form={form}
            onFinish={onFinish}
            onFieldsChange={() => setChange(true)}
            scrollToFirstError
          >
            <Form.Item
              label="Message"
              name="message"
              rules={[
                {
                  required: true,
                  message: 'Please input message!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Color" name="color">
              <Select options={plainColors} />
            </Form.Item>

            <Form.Item label="Status" name="status">
              <Select options={plainStatuses} />
            </Form.Item>

            <Form.Item label="Starts At" name="starts_at">
              <DatePicker
                showTime={{ format: 'HH:mm' }}
                format={format}
                onChange={(_, date) => setNotificationDateStart(date)}
              />
            </Form.Item>

            <Form.Item
              label="Ends At"
              name="ends_at"
              validateStatus={isDateValid && 'error'}
              help={isDateValid && 'End date is lower than start date'}
            >
              <DatePicker
                disabled={!notificationDateStart}
                showTime={{ format: 'HH:mm' }}
                format={format}
                onChange={(_, date) => setNotificationDateEnd(date)}
              />
            </Form.Item>

            <Form.Item
              label="Visible To Unauthorized"
              name="visible_to_guest"
              valuePropName="checked"
            >
              <Checkbox name="visible_to_guest" />
            </Form.Item>

            <Form.Item
              label="Visible To All Authorized"
              name="visible_to_all"
              valuePropName="checked"
            >
              <Checkbox name="visible_to_all" />
            </Form.Item>

            <AdvancedCheckboxList
              formItemProps={{
                label: 'Customer Groups',
                name: 'user_groups',
                valuePropName: 'value',
              }}
              buttonText="Add Customer Group"
              options={clientGroups}
              form={form}
              onChangeValues={() => setChange(true)}
              initialValues={groups?.filter(group => group.value).map(group => group.id)}
            />

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={isDateValid || !changed}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>,
      )}
    </NavContainer>
  )
}

export default EditItem
