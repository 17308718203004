import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, Input, Select } from 'antd'
import NavContainer from '../../../../components/nav-container'
import {
  clientGroupActions,
  templateActions,
  templateCategoriesActions as tcActions,
} from '../../../../_actions'
import { templateConstants } from '../../../../_constants'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import { convertDataForSelect } from '../../../../_helpers'
import AdvancedCheckboxList from 'components/advanced-checkbox-list'
import { getTemplateCategoriesRows } from '_selectors/template-categories.selector'
import { getClientGroupsRows } from '_selectors/client-group.selector'

const { navMenu, formItemLayout, statuses } = templateConstants

const navMenuInit = [navMenu.list, navMenu.manage]

const CreateItem = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const templateCategories = useSelector(getTemplateCategoriesRows)

  const clientGroups = useSelector(getClientGroupsRows)

  const templateCategoriesSelect = useMemo(() => {
    return templateCategories?.map(template => ({ value: template.id, label: template.name }))
  }, [templateCategories])

  const clientGroupsCheckbox = useMemo(() => {
    return clientGroups?.map(group => ({ value: group.id, label: group.title }))
  }, [clientGroups])

  useEffect(() => {
    dispatch(tcActions.getAll())
    dispatch(clientGroupActions.getAll())
  }, [dispatch])

  const onFinish = async values => {
    const convert = convertFormValuesDeep(values)
    await dispatch(templateActions.addItem(convert))
  }
  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      <Form {...formItemLayout} name="basic" form={form} onFinish={onFinish} scrollToFirstError>
        <Form.Item label="Category" name="category_id">
          <Select options={templateCategoriesSelect} />
        </Form.Item>

        <Form.Item name="status" label="Status" initialValue={0}>
          <Select options={convertDataForSelect(statuses)} />
        </Form.Item>

        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input name!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Message"
          name="message"
          rules={[
            {
              required: true,
              message: 'Please input message!',
            },
          ]}
        >
          <Input.TextArea rows={5} />
        </Form.Item>

        <Form.Item label="Wishes" name="wishes">
          <Input.TextArea rows={3} />
        </Form.Item>

        <AdvancedCheckboxList
          formItemProps={{
            label: 'Customer Groups',
            name: 'user_groups',
            valuePropName: 'value',
          }}
          buttonText="Add Customer Group"
          options={clientGroupsCheckbox}
          form={form}
        />

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </NavContainer>
  )
}

export default CreateItem
