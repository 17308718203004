import axios from 'axios'
import { v1 } from './api'
import apiUrl from './service'

const assignManifest = async id => {
  const result = await axios.get(`${apiUrl}/${v1}/qa/manifests/${id}`)
  return handleResponse(result)
}

const submitQa = async (id, payload) => {
  const result = await axios.put(`${apiUrl}/${v1}/qa/manifests/${id}/submit`, payload)
  return handleResponse(result)
}

const updateManifestOrders = async (id, payload) => {
  const result = await axios.put(`${apiUrl}/${v1}/qa/manifests/${id}/update`, payload)
  return handleResponse(result)
}

const handleResponse = result => {
  return result.data
}

const qaService = {
  assignManifest,
  submitQa,
  updateManifestOrders,
}

export default qaService
