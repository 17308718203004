import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import v4 from 'uuid/v4'
import getCurrentUrl from '../../../../_helpers/url-utils'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import { subscriptionActions } from '../../../../_actions'
import { settingsConstants, subscriptionConstants } from '../../../../_constants'
import { getSubscriptionsRows, getPage, getTotalItems } from '_selectors/subscriptions.selector'

const {
  subscriptionStatuses,
  subscriptionVisibility,
  navMenu,
  advancedSearch,
  subscriptionConfirmationStatuses,
} = subscriptionConstants
const statusRender = text => {
  return `${subscriptionStatuses.find(st => st.value === text.toString())?.text}`
}

const statusConfirmationRender = text => {
  return `${subscriptionConfirmationStatuses.find(st => st.value === text.toString())?.text}`
}

const visibilityRender = text => {
  return `${subscriptionVisibility.find(v => v.value === text.toString())?.text}`
}

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status_filter',
    render: statusRender,
    filters: subscriptionStatuses,
    onFilter: (value, record) => record.status?.toString().indexOf(value) === 0,
    width: '10%',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Visibility',
    dataIndex: 'visibility',
    key: 'visibility_filter',
    render: visibilityRender,
    filters: subscriptionVisibility,
    onFilter: (value, record) => {
      return record.visibility?.toString().indexOf(value) === 0
    },
    width: '10%',
  },
  {
    title: 'Delivery Confirmation',
    dataIndex: 'confirmation_free',
    key: 'confirmation_free',
    render: statusConfirmationRender,
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Fee',
    dataIndex: 'fee',
    key: 'fee',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Period (Days)',
    dataIndex: 'period',
    key: 'period',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
]

const navMenuInit = [navMenu.list, navMenu.create]

const ManageItems = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getSubscriptionsRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)
  const { can_edit_subscriptions, superuser } = useSelector(state => state.authentication.user)

  const deleteItem = async id => {
    await dispatch(subscriptionActions.deleteItem(id))
  }

  const navigateToList = () => {
    dispatch({ type: subscriptionConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}/list`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.list.key:
          return {
            ...menu,
            url: navigateToList,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  const statusesSelect = useMemo(() => {
    return subscriptionStatuses.map(status => ({ value: status.value, label: status.text }))
  }, [subscriptionStatuses])

  const updAdvancedSearch = advancedSearch.map(field => {
    if (field.key === 'status_filter') return { ...field, options: statusesSelect }
    return field
  })

  const canEdit = superuser || can_edit_subscriptions

  return (
    <NavContainer match={match} menu={navMenuParse}>
      <TableFilter
        deleteItems={deleteItem}
        columns={initColumns}
        data={dataSource}
        getDataAction={subscriptionActions.getAll}
        isEditOnId={!!canEdit}
        advancedSearch={updAdvancedSearch}
        showAdvancedButton
      />
    </NavContainer>
  )
}

export default ManageItems
