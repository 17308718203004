import axios from 'axios'
import serialize from '_helpers/hw-serialize-params'
import apiUrl from './service'
import { v1 } from './api'

const getAll = async (params, typeAction) => {
  const result = await axios.get(`${apiUrl}/${v1}/shipping/rates/?${serialize(params)}`, {
    params: { stopSpinner: typeAction },
  })
  return handleResponse(result)
}

const addItem = async ({ value }) => {
  const result = await axios.post(`${apiUrl}/${v1}/shipping/rates/`, JSON.stringify(value))
  return handleResponse(result)
}

const getItemById = async id => {
  const result = await axios.get(`${apiUrl}/${v1}/shipping/rates/${id}`)
  return handleResponse(result)
}

const deleteItem = async value => {
  const result = await axios.delete(`${apiUrl}/${v1}/shipping/rates/${value}`, {
    params: { stopSpinner: 'paginate' },
  })
  return handleResponse(result)
}

const updateItemById = async (id, value) => {
  const result = await axios.put(`${apiUrl}/${v1}/shipping/rates/${id}`, JSON.stringify(value))
  return handleResponse(result)
}

const handleResponse = result => {
  return result.data
}

const ShippingRatesService = {
  getAll,
  getItemById,
  deleteItem,
  updateItemById,
  addItem,
}

export default ShippingRatesService
