import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, DatePicker, Form } from 'antd'
import NavContainer from '../../../../components/nav-container'
import { holidayActions } from '../../../../_actions'
import holidayConstants from '../../../../_constants/holiday.constants'

const { navMenu, formItemLayout } = holidayConstants

const navMenuInit = [navMenu.list, navMenu.manage]

const CreateItem = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const onFinish = async values => {
    const newValue = {
      ...values,
      date: values.date?.format('YYYY-MM-DD'),
    }
    await dispatch(holidayActions.addItem(newValue))
  }

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      <Form {...formItemLayout} name="basic" form={form} onFinish={onFinish} scrollToFirstError>
        <Form.Item
          label="Date"
          name="date"
          rules={[
            {
              required: true,
              message: 'Please input date!',
            },
          ]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item name="create">
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </NavContainer>
  )
}

export default CreateItem
