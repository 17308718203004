function isEmptyObject(obj) {
  const isObject = typeof obj

  if (!isObject) return true

  const keysList = Object.keys(obj)

  return keysList.length === 0
}

export default isEmptyObject
