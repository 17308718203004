import { Timeline } from 'antd'
import dayjs from 'dayjs'
import React from 'react'

const DetailsTimeline = ({ timestamp, processedOn, finishedOn }) => {
  const formatDate = date => dayjs(date).format('MM/DD/YYYY HH:mm:ss')

  const calculateDifference = (date1, date2) => {
    const milliseconds = dayjs(date2).diff(dayjs(date1))
    if (milliseconds < 1000) return `${milliseconds} milliseconds`

    const seconds = (milliseconds / 1000).toFixed()
    if (seconds < 60) return `${seconds} seconds`

    const minutes = (seconds / 60).toFixed()
    return `${minutes} minutes`
  }

  const timelineData = [
    {
      label: 'Added at',
      value: formatDate(timestamp),
    },
    {
      label: calculateDifference(timestamp, processedOn),
      withoutDot: true,
    },
    {
      label: 'Process started at',
      value: formatDate(processedOn),
    },
    {
      label: calculateDifference(processedOn, finishedOn),
      withoutDot: true,
    },
    {
      label: 'Finished at',
      value: formatDate(finishedOn),
    },
  ]

  const timelineItems = timelineData.map(({ label, value, withoutDot }) => ({
    children: (
      <div className="timeline-item">
        <p className="timeline-item__label">{label}</p>
        {value ? <p className="timeline-item__date">{value}</p> : null}
      </div>
    ),
    ...(withoutDot ? { dot: ' ' } : {}),
  }))

  return <Timeline items={timelineItems} mode="right" />
}

export default DetailsTimeline
