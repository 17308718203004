import { cronJobConstants } from '../_constants'

const initialState = {
  items: [],
  item: {},
}

function cronJobs(state = initialState, { type, payload }) {
  switch (type) {
    case cronJobConstants.GETALL_SUCCESS:
      return { ...state, items: payload, item: {} }
    case cronJobConstants.GET_BY_NAME_SUCCESS:
      return { ...state, item: payload }
    case cronJobConstants.REMOVE_BY_NAME_SUCCESS:
      return { ...state, item: payload }
    case cronJobConstants.CLEAR_ALL_SUCCESS:
      return { ...state, item: payload }
    case cronJobConstants.RETRY_FAILED_SUCCESS:
      return { ...state, item: payload }
    case cronJobConstants.SETBYID:
      return {
        ...state,
        item: {},
      }
    case cronJobConstants.SETALL:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default cronJobs
