import axios from 'axios'
import serialize from '_helpers/hw-serialize-params'
import apiUrl from './service'
import { v1, v1_user } from './api'

const getAll = async (params, typeAction) => {
  const result = await axios.get(`${apiUrl}/${v1}/clients?${serialize(params)}`, {
    params: { stopSpinner: typeAction },
  })
  return handleResponse(result)
}

const getListClients = async params => {
  const result = await axios.get(`${apiUrl}/${v1}/clients/list?${serialize(params)}`)
  return handleResponse(result)
}

const getItemById = async id => {
  const result = await axios.get(`${apiUrl}/${v1}/clients/${id}`)
  return handleResponse(result)
}

const deleteItem = async value => {
  const result = await axios.delete(`${apiUrl}/${v1}/clients/${value}`, {
    params: { stopSpinner: 'paginate' },
  })
  return handleResponse(result)
}

const updateItemById = async (id, value) => {
  const result = await axios.put(
    `${apiUrl}/${v1}/clients/${id}`,
    JSON.stringify({ changes: value }),
  )
  return handleResponse(result)
}

const getOrdersClient = async params => {
  const result = await axios.get(`${apiUrl}/${v1}/clients/${params.like}/orders`, {
    params: { ...params },
  })
  return handleResponse(result)
}

const resetPasswords = async login => {
  const result = await axios.post(`${apiUrl}/${v1_user}/auth/resetPasswordRequest`, { login })
  return handleResponse(result)
  // return params
}

const genToken = async id => {
  const result = await axios.post(`${apiUrl}/${v1}/clients/${id}/token`)
  return handleResponse(result)
}

const genLogInAsUser = async id => {
  const result = await axios.post(`${apiUrl}/${v1}/clients/${id}/logInAsUser`)
  return handleResponse(result)
}

const handleResponse = result => {
  return result.data
}

const clientsService = {
  getAll,
  getItemById,
  deleteItem,
  updateItemById,
  getOrdersClient,
  getListClients,
  genToken,
  resetPasswords,
  genLogInAsUser,
}

export default clientsService
