import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Form, Input, InputNumber, Select } from 'antd'
import NavContainer from '../../../../components/nav-container'
import { shippingConfigsActions } from '../../../../_actions'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import { shippingConfigsConstants } from '../../../../_constants'
import { convertDataForSelect } from '../../../../_helpers'

const {
  formItemLayout,
  shippingConfigsStatuses,
  navMenu,
  configTypes,
  cardSizes,
} = shippingConfigsConstants
const navMenuInit = [navMenu.list, navMenu.manage]

const CreateItem = () => {
  const [activeType, setActiveType] = useState(configTypes[0].value)
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const handleTypeChange = value => setActiveType(value)

  const onFinish = values => {
    const convertedShippingConfigs = convertFormValuesDeep(values)
    dispatch(shippingConfigsActions.addItem(convertedShippingConfigs))
  }

  const formItems = useMemo(() => {
    if (activeType === 'box') {
      return (
        <>
          <Form.Item label="Card Size" name="card_size" initialValue={cardSizes[0].value}>
            <Select options={cardSizes} />
          </Form.Item>
          <Form.Item
            label="Box Length"
            name="box_length"
            initialValue={0}
            rules={[
              {
                required: true,
                message: 'Please input Box Length!',
              },
            ]}
          >
            <InputNumber step={0.1} min={0} />
          </Form.Item>
          <Form.Item
            label="Box Width"
            name="box_width"
            initialValue={0}
            rules={[
              {
                required: true,
                message: 'Please input Box Width!',
              },
            ]}
          >
            <InputNumber step={0.1} min={0} />
          </Form.Item>
          <Form.Item
            label="Box Height"
            name="box_height"
            initialValue={0}
            rules={[
              {
                required: true,
                message: 'Please input Box Height!',
              },
            ]}
          >
            <InputNumber step={0.1} min={0} />
          </Form.Item>
          <Form.Item
            label="Empty Box Weight"
            name="empty_box_weight"
            initialValue={0}
            rules={[
              {
                required: true,
                message: 'Please input Empty Box Weight!',
              },
            ]}
          >
            <InputNumber step={0.1} min={0} />
          </Form.Item>
          <Form.Item
            label="Quantity From"
            name="quantity_from"
            initialValue={0}
            rules={[
              {
                required: true,
                message: 'Please input Quantity!',
              },
            ]}
          >
            <InputNumber step={1} min={0} />
          </Form.Item>
          <Form.Item
            label="Quantity To"
            name="quantity_to"
            initialValue={0}
            rules={[
              {
                required: true,
                message: 'Please input Quantity!',
              },
            ]}
          >
            <InputNumber step={1} min={0} />
          </Form.Item>
        </>
      )
    }
    if (activeType === 'card') {
      return (
        <>
          <Form.Item label="Card Size" name="card_size" initialValue={cardSizes[0].value}>
            <Select options={cardSizes} />
          </Form.Item>
          <Form.Item
            label="Card Weight"
            name="card_weight"
            initialValue={0}
            rules={[
              {
                required: true,
                message: 'Please input Card Weight!',
              },
            ]}
          >
            <InputNumber step={0.1} min={0} />
          </Form.Item>
          <Form.Item
            label="Gift Weight"
            name="gift_weight"
            initialValue={0}
            rules={[
              {
                required: true,
                message: 'Please input Gift Weight!',
              },
            ]}
          >
            <InputNumber step={0.1} min={0} />
          </Form.Item>
          <Form.Item
            label="Insert Weight"
            name="insert_weight"
            initialValue={0}
            rules={[
              {
                required: true,
                message: 'Please input Insert Weight!',
              },
            ]}
          >
            <InputNumber step={0.1} min={0} />
          </Form.Item>
        </>
      )
    }
    return (
      <>
        <Form.Item
          label="Shipper Name"
          name="shipper_name"
          rules={[
            {
              required: true,
              message: 'Please input Shipper Name!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Shipper ID"
          name="shipper_id"
          rules={[
            {
              required: true,
              message: 'Please input Shipper ID!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Shipper Address"
          name="shipper_address"
          rules={[
            {
              required: true,
              message: 'Please input Shipper Address!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Shipper City"
          name="shipper_city"
          rules={[
            {
              required: true,
              message: 'Please input Shipper City!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Shipper State"
          name="shipper_state"
          rules={[
            {
              required: true,
              message: 'Please input Shipper State!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Shipper Zip"
          name="shipper_zip"
          rules={[
            {
              required: true,
              message: 'Please input Shipper Zip!',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </>
    )
  }, [activeType])

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      <Form {...formItemLayout} name="basic" form={form} onFinish={onFinish} scrollToFirstError>
        <Form.Item label="Type" name="type" initialValue={configTypes[0].value}>
          <Select options={convertDataForSelect(configTypes)} onChange={handleTypeChange} />
        </Form.Item>
        {formItems}
        <Form.Item label="Status" name="status" initialValue={0}>
          <Select options={convertDataForSelect(shippingConfigsStatuses)} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </NavContainer>
  )
}

export default CreateItem
