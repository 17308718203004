import { ordersService } from '../_services'
import { history } from '../_helpers'
import { ordersConstants } from '../_constants'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: ordersConstants.GETALL_REQUEST })
  try {
    const orders = await ordersService.getAll(params, typeAction)
    dispatch({ type: ordersConstants.GETALL_SUCCESS, payload: orders.data })
    return orders.data
  } catch (error) {
    dispatch({ type: ordersConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: ordersConstants.GETBYID_REQUEST })

  try {
    const order = await ordersService.getItemById(id)
    dispatch({ type: ordersConstants.GETBYID_SUCCESS, payload: order.data })
  } catch (error) {
    dispatch({ type: ordersConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const deleteItem = id => async dispatch => {
  dispatch({ type: ordersConstants.DELETE_REQUEST })
  try {
    await ordersService.deleteItem(id)
    history.push(`/orders`)
    dispatch({ type: ordersConstants.DELETE_SUCCESS, payload: id })
  } catch (error) {
    dispatch({ type: ordersConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const updateItemById = (id, value, isAssign) => async dispatch => {
  dispatch({ type: ordersConstants.UPDATE_REQUEST })

  try {
    await ordersService.updateItemById(id, value, isAssign)
    dispatch({ type: ordersConstants.UPDATE_SUCCESS, payload: value })
    if (!isAssign) history.push(`/orders/view/${id}`)
  } catch (error) {
    dispatch({ type: ordersConstants.UPDATE_FAILURE, payload: error.toString() })
  }
}

const getDocxNote = orderId => async dispatch => {
  dispatch({ type: ordersConstants.DOWNLOAD_DOCX_REQUEST })

  try {
    await ordersService.downloadDocxNote(orderId)
    dispatch({ type: ordersConstants.DOWNLOAD_DOCX_SUCCESS })
  } catch (error) {
    dispatch({ type: ordersConstants.DOWNLOAD_DOCX_FAILURE })
  }
}

const getCreateRedo = orderId => async dispatch => {
  dispatch({ type: ordersConstants.CREATE_REDO_REQUEST })

  try {
    await ordersService.createRedo(orderId)
    dispatch({ type: ordersConstants.CREATE_REDO_SUCCESS })
  } catch (error) {
    dispatch({ type: ordersConstants.CREATE_REDO_FAILURE })
  }
}

const getDocxEnvelope = orderId => async dispatch => {
  dispatch({ type: ordersConstants.DOWNLOAD_DOCX_REQUEST })

  try {
    await ordersService.downloadDocxEnvelope(orderId)
    dispatch({ type: ordersConstants.DOWNLOAD_DOCX_SUCCESS })
  } catch (error) {
    dispatch({ type: ordersConstants.DOWNLOAD_DOCX_FAILURE })
  }
}

const ordersActions = {
  getAll,
  getItemById,
  deleteItem,
  updateItemById,
  getDocxNote,
  getDocxEnvelope,
  getCreateRedo,
}

export default ordersActions
