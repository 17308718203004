const framesConstants = {
  GETALL_REQUEST: 'FRAMES_GETALL_REQUEST',
  GETALL_SUCCESS: 'FRAMES_SUCCESS',
  GETALL_FAILURE: 'FRAMES_FAILURE',

  ADD_REQUEST: 'FRAMES_ADD_REQUEST',
  ADD_SUCCESS: 'FRAMES_ADD_SUCCESS',
  ADD_FAILURE: 'FRAMES_ADD_FAILURE',

  GETBYID_REQUEST: 'FRAMES_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'FRAMES_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'FRAMES_GETBYID_FAILURE',

  DELETE_REQUEST: 'FRAMES_DELETE_REQUEST',
  DELETE_SUCCESS: 'FRAMES_DELETE_SUCCESS',
  DELETE_FAILURE: 'FRAMES_DELETE_FAILURE',

  UPDATE_SUCCESS: 'FRAMES_UPDATE_SUCCESS',
  UPDATE_REQUEST: 'FRAMES_UPDATE_REQUEST',
  UPDATE_FAILURE: 'FRAMES_UPDATE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    list: { key: 'list', title: 'List QR Code Frames', url: '/qr-code-frames/list' },
    manage: { key: 'manage', title: 'Manage QR Code Frames', url: '/qr-code-frames' },
    create: { title: 'Create QR Code Frame', key: 'create', url: '/qr-code-frames/create' },
    edit: { key: 'edit', title: 'Update QR Code Frame', url: '' },
    delete: { key: 'delete', title: 'Delete QR Code Frame', url: '' },
    view: { key: 'view', title: 'View QR Code Frame', url: '' },
  },

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },

  frameStatuses: [
    { value: 0, text: 'Not Active' },
    { value: 1, text: 'Active' },
  ],

  frameTypes: [
    { value: 'mixed', text: 'Mixed' },
    { value: 'header', text: 'Header' },
    { value: 'footer', text: 'Footer' },
  ],

  listItemKeyToLabel: {
    id: 'ID',
    status: 'Status',
    type: 'Type',
    group: 'Client Group',
    frame: 'Frame',
  },

  viewItemKeyToLabel: {
    id: 'ID',
    status: 'Status',
    type: 'Type',
    group: 'Client Group',
    frame: 'Frame',
  },
  advancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'Type', key: 'type' },
    { label: 'Status', key: 'status_filter', type: 'select' },
  ],
}
export default framesConstants
