import { isEqual } from 'lodash'

const arrayEquals = (a = [], b) => {
  if (a.length !== b.length) return false
  const copyA = a.slice()
  const copyB = b.slice()

  const res = isEqual(copyA.sort(), copyB.sort())
  return res
}

export default arrayEquals
