const usaFinancialConstants = {
  GETALL_REQUEST: 'USA_FINANCIAL_GETALL_REQUEST',
  GETALL_SUCCESS: 'USA_FINANCIAL_GETALL_SUCCESS',
  GETALL_FAILURE: 'USA_FINANCIAL_GETALL_FAILURE',

  GETBYID_REQUEST: 'USA_FINANCIAL_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'USA_FINANCIAL_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'USA_FINANCIAL_GETBYID_FAILURE',

  ADD_REQUEST: 'USA_FINANCIAL_ADD_REQUEST',
  ADD_SUCCESS: 'USA_FINANCIAL_ADD_SUCCESS',
  ADD_FAILURE: 'USA_FINANCIAL_ADD_FAILURE',

  DELETE_REQUEST: 'USA_FINANCIAL_DELETE_REQUEST',
  DELETE_SUCCESS: 'USA_FINANCIAL_DELETE_SUCCESS',
  DELETE_FAILURE: 'USA_FINANCIAL_DELETE_FAILURE',

  UPDATE_REQUEST: 'USA_FINANCIAL_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'USA_FINANCIAL_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'USA_FINANCIAL_UPDATE_FAILURE',

  GET_USA_FINANCIAL_TRIGGER_REQUEST: 'GET_USA_FINANCIAL_TRIGGER_REQUEST',
  GET_USA_FINANCIAL_TRIGGER_SUCCESS: 'GET_USA_FINANCIAL_TRIGGER_SUCCESS',
  GET_USA_FINANCIAL_TRIGGER_FAILURE: 'GET_USA_FINANCIAL_TRIGGER_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  statuses: [
    { value: 1, text: 'ACTIVE' },
    { value: 0, text: 'NO ACTIVE' },
  ],

  defaultRadioButton: 'holiday',

  radioButton: [
    { value: 'holiday', title: 'Holiday' },
    { value: 'dob', title: 'Birthday' },
    { value: 'client_since', title: 'Anniversary' },
  ],

  navMenu: {
    list: { key: 'list', title: 'List USA Financial', url: '/usa-financial/list' },
    manage: { key: 'manage', title: 'Manage USA Financial', url: '/usa-financial' },
    create: { title: 'Create USA Financial', key: 'create', url: '/usa-financial/create' },
    edit: { key: 'edit', title: 'Update USA Financial', url: '' },
    delete: { key: 'delete', title: 'Delete USA Financial', url: '' },
    view: { key: 'view', title: 'View USA Financial', url: '' },
  },

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },

  listItemKeyToLabel: {
    id: 'ID',
    label: 'Label',
    date: 'Date',
    default_card_id: 'Default Card ID',
    sort_order: 'Sort Order',
    status: 'Status',
  },

  viewItemKeyToLabel: {
    id: 'ID',
    label: 'Label',
    date: 'Date',
    default_card_id: 'Default Card ID',
    sort_order: 'Sort Order',
    status: 'Status',
  },
}
export default usaFinancialConstants
