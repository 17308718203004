import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import ItemViewer from '../../../../components/item-viewer'
import { shippingConfigsActions } from '../../../../_actions'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { shippingConfigsConstants } from '../../../../_constants'

const {
  defaultViewItemKeyToLabel,
  shipperKeyToLabel,
  boxKeyToLabel,
  cardKeyToLabel,
  navMenu,
  shippingConfigsStatuses,
} = shippingConfigsConstants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.edit, navMenu.delete, navMenu.manage]

const ViewItem = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const match = useRouteMatch()
  const { id } = useParams()

  const { item: shippingConfig } = useSelector(state => state.shippingConfigs)

  const [navMenuParse, setNavMenuParse] = useState([])
  const view = getCurrentUrl(match.url)

  const onEditItem = () => {
    history.push({
      pathname: `${view}/edit/${id}`,
    })
  }

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(shippingConfigsActions.deleteItem(id))
      },
    })
  }

  useEffect(() => {
    dispatch(shippingConfigsActions.getItemById(id))
    return () => {
      dispatch({ type: shippingConfigsConstants.SETBYID })
    }
  }, [])

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.edit.key:
          return {
            ...menu,
            url: onEditItem,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const viewItemKeyToLabel = useMemo(() => {
    let viewItems = { ...defaultViewItemKeyToLabel }
    if (shippingConfig.type === 'shipper') viewItems = { ...viewItems, ...shipperKeyToLabel }
    else if (shippingConfig.type === 'box') viewItems = { ...viewItems, ...boxKeyToLabel }
    else viewItems = { ...viewItems, ...cardKeyToLabel }
    return viewItems
  }, [shippingConfig.type])

  const viewItem = useMemo(() => {
    if (shippingConfig) {
      return Object.keys(viewItemKeyToLabel).reduce((obj, sKey) => {
        let itemValue
        if (sKey === 'status') {
          itemValue = shippingConfigsStatuses.find(status => status.value === shippingConfig[sKey])
            ?.text
        } else {
          itemValue = shippingConfig[sKey]
        }
        return {
          ...obj,
          [sKey]: itemValue,
        }
      }, {})
    }
    return {}
  }, [shippingConfig])

  return (
    <NavContainer menu={navMenuParse}>
      <ItemViewer item={viewItem} keyToLabelMap={viewItemKeyToLabel} />
    </NavContainer>
  )
}

export default ViewItem
