import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router'
import getCurrentUrl from '../../../../_helpers/url-utils'
import NavContainer from '../../../../components/nav-container'
import ListViewer from '../../../../components/list-viewer'
import { appConstants, presetCardImagesConstants, settingsConstants } from '../../../../_constants'
import {
  getPresetCardImageRows,
  getPage,
  getTotalItems,
} from '_selectors/preset-card-images.selector'
import presetCardImagesActions from '_actions/preset-card-images.actions'

const {
  navMenu,
  listItemKeyToLabel,
  renderComponent,
  presetCardImageStatuses,
} = presetCardImagesConstants

const navMenuInit = [navMenu.manage, navMenu.create]

const ListItems = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getPresetCardImageRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const navigateToManage = () => {
    dispatch({ type: presetCardImagesConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.manage.key:
          return {
            ...menu,
            url: navigateToManage,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const listItems = useMemo(() => {
    return {
      total,
      page,
      rows: rows?.map(item => {
        const res = []
        Object.keys(listItemKeyToLabel).forEach(sKey => {
          const itemLine = { key: sKey, label: listItemKeyToLabel[sKey], value: item[sKey] || '' }
          if (sKey === 'id')
            itemLine.clickHandler = () =>
              history.push({ pathname: `/preset-card-images/view/${item[sKey]}` })
          if (sKey === 'url') {
            itemLine.type = 'image'
            itemLine.value = renderComponent.image(
              `${appConstants.IMAGES_URL}/${item.url}`,
              item.url,
            )
          }
          if (sKey === 'sort_no') {
            itemLine.value = `${item.sort_no}`
          }
          if (sKey === 'status') {
            itemLine.value = `${
              presetCardImageStatuses.find(st => st.value.toString() === item.status.toString())
                ?.text
            }`
          }
          res.push(itemLine)
        })
        return res
      }),
    }
  }, [rows, total, page])

  return (
    <NavContainer menu={navMenuParse}>
      <ListViewer data={listItems} getDataAction={presetCardImagesActions.getAll} />
    </NavContainer>
  )
}

export default ListItems
