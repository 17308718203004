import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { batchOrdersActions } from '_actions'
import { batchOrdersConstants } from '_constants'
import { MyOrders, OrdersList } from './views'

// TODO refresh page after complete orders

const BatchOrders = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isFirstRender, setFirstRender] = useState(true)
  const [changeUrl, setChange] = useState(false)
  const [refresh, setRefresh] = useState(false)

  const ordersInWorkCountChecked = useSelector(state => state.batchOrders.ordersInWorkCountChecked)
  const ordersInWorkCount = useSelector(state => state.batchOrders.ordersInWorkCount)
  const discardedOrders = useSelector(state => state.batchOrders.discardedOrders)
  const completedOrders = useSelector(state => state.batchOrders.completedOrders)

  // batch

  useEffect(() => {
    if (ordersInWorkCountChecked === -1 && history.location.pathname === '/batch-orders') {
      dispatch(batchOrdersActions.checkInWorkOrders())
      setFirstRender(false)
    }
    return () => {
      dispatch(batchOrdersActions.clearView(batchOrdersConstants.CLEAR_BATCH_ORDERS_VIEW))
      dispatch(batchOrdersActions.clearView(batchOrdersConstants.CLEAR_CHECKED_ORDERS))
    }
  }, [])

  // if we have my orders, change url to my-orders

  useEffect(() => {
    if (ordersInWorkCountChecked > 0 && history.location.pathname === '/batch-orders') {
      setChange(true)
      history.push('/my-orders')
    }
  }, [ordersInWorkCountChecked])

  useEffect(() => {
    if (
      ordersInWorkCountChecked === 0 &&
      (discardedOrders.length === ordersInWorkCount || completedOrders.length > 0)
    ) {
      if (
        (ordersInWorkCount === 0 && completedOrders.length > 0) ||
        discardedOrders.length === ordersInWorkCount
      )
        history.push('/batch-orders')
    } else if (discardedOrders.length) {
      dispatch(batchOrdersActions.getAllInWork())
    }
  }, [ordersInWorkCountChecked, discardedOrders, completedOrders])

  // my orders

  useEffect(() => {
    if (ordersInWorkCount === -1 && history.location.pathname === '/my-orders') {
      dispatch(batchOrdersActions.getAllInWork())
      setFirstRender(false)
    }
    return () => {
      dispatch(batchOrdersActions.clearView(batchOrdersConstants.CLEAR_MY_ORDERS_VIEW))
    }
  }, [])

  // change route from my to batch

  useEffect(() => {
    if (history.location.pathname === '/batch-orders' && !isFirstRender) {
      dispatch(batchOrdersActions.clearView(batchOrdersConstants.CLEAR_CHECKED_ORDERS))
      dispatch(batchOrdersActions.checkInWorkOrders())
    } else if (history.location.pathname === '/my-orders' && !isFirstRender) {
      if (!changeUrl) {
        dispatch(batchOrdersActions.getAllInWork())
        setRefresh(true)
      }
    }
  }, [history.location.pathname])

  return (
    <>
      {ordersInWorkCountChecked === 0 && history.location.pathname === '/batch-orders' && (
        <OrdersList refresh={refresh} setRefresh={setRefresh} />
      )}
      {(ordersInWorkCountChecked > 0 || ordersInWorkCount >= 0) &&
        history.location.pathname === '/my-orders' && <MyOrders setRefresh={setRefresh} />}
    </>
  )
}

export default BatchOrders
