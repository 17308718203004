import { shareOptionConstants } from '../_constants'
import { shareOptionsService } from '../_services'
import { history } from '../_helpers'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: shareOptionConstants.GETALL_REQUEST })
  try {
    const shareOptions = await shareOptionsService.getAll(params, typeAction)
    dispatch({ type: shareOptionConstants.GETALL_SUCCESS, payload: shareOptions.data })
  } catch (error) {
    dispatch({ type: shareOptionConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const addItem = value => async dispatch => {
  dispatch({ type: shareOptionConstants.ADD_REQUEST })

  try {
    const shareOption = await shareOptionsService.addItem({ value })
    dispatch({ type: shareOptionConstants.ADD_SUCCESS, payload: { ...value, id: shareOption.id } })
    history.push(`/share-options/view/${shareOption.id}`)
  } catch (error) {
    dispatch({ type: shareOptionConstants.ADD_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: shareOptionConstants.GETBYID_REQUEST })

  try {
    const shareOption = await shareOptionsService.getItemById(id)
    dispatch({ type: shareOptionConstants.GETBYID_SUCCESS, payload: shareOption.data })
  } catch (error) {
    dispatch({ type: shareOptionConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const deleteItem = id => async dispatch => {
  dispatch({ type: shareOptionConstants.DELETE_REQUEST })
  try {
    await shareOptionsService.deleteItem(id)
    history.push(`/share-options/`)
    dispatch({ type: shareOptionConstants.DELETE_SUCCESS, payload: id })
    history.push(`/share-options/view/${id}`)
  } catch (error) {
    dispatch({ type: shareOptionConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const updateItemById = (id, value) => async dispatch => {
  dispatch({ type: shareOptionConstants.UPDATE_REQUEST })

  try {
    const updated = await shareOptionsService.updateItemById(id, value)
    dispatch({ type: shareOptionConstants.UPDATE_SUCCESS, payload: updated?.message?.toString() })
  } catch (error) {
    dispatch({ type: shareOptionConstants.UPDATE_FAILURE, payload: error.toString() })
  }
}

const shareOptionActions = {
  getAll,
  getItemById,
  deleteItem,
  updateItemById,
  addItem,
}

export default shareOptionActions
