const dimensionCardConstants = {
  GETALL_REQUEST: 'DIMENSION_CARDS_GETALL_REQUEST',
  GETALL_SUCCESS: 'DIMENSION_CARDS_GETALL_SUCCESS',
  GETALL_FAILURE: 'DIMENSION_CARDS_GETALL_FAILURE',

  GETBYID_REQUEST: 'DIMENSION_CARDS_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'DIMENSION_CARDS_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'DIMENSION_CARDS_GETBYID_FAILURE',

  ADD_REQUEST: 'DIMENSION_CARDS_ADD_REQUEST',
  ADD_SUCCESS: 'DIMENSION_CARDS_ADD_SUCCESS',
  ADD_FAILURE: 'DIMENSION_CARDS_ADD_FAILURE',

  DELETE_REQUEST: 'DIMENSION_CARDS_DELETE_REQUEST',
  DELETE_SUCCESS: 'DIMENSION_CARDS_DELETE_SUCCESS',
  DELETE_FAILURE: 'DIMENSION_CARDS_DELETE_FAILURE',

  UPDATE_REQUEST: 'DIMENSION_CARDS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'DIMENSION_CARDS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'DIMENSION_CARDS_UPDATE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    manage: { key: 'manage', title: 'Manage Dimension Card', url: '/card-dimension' },
    create: { title: 'Create Dimension Card', key: 'create', url: '/card-dimension/create' },
    view: { key: 'view', title: 'View Dimension Card', url: '' },
    delete: { key: 'delete', title: 'Delete Dimension Card', url: '' },
    edit: { key: 'edit', title: 'Update Dimension Card', url: '' },
  },

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },
  viewItemKeyToLabel: {
    id: 'ID',
    name: 'Name',
    status: 'Status',
    orientation: 'Orientation',
    can_customize: 'Can Customize',
    price: 'Price',
    sku: 'SKU',
    description: 'Description',
    open_height: 'Open Height',
    open_width: 'Open Width',
    character_count: 'Character Count',
    margin_bottom: 'Margin Bottom',
    margin_left: 'Margin Left',
    margin_right: 'Margin Right',
    margin_top: 'Margin Top',

    envelope_height: 'Envelope Height',
    envelope_width: 'Envelope Width',
    envelope_font_size: 'Envelope Font Size',
    envelope_height: 'Envelope Height',
    envelope_lines_between_addresses: 'Lines Between Addresses',
    envelope_margin_bottom: 'Envelope Margin Bottom',
    envelope_margin_left: 'Envelope Margin Left',
    envelope_margin_right: 'Envelope Margin Right',
    envelope_margin_top: 'Envelope Margin Top',
    envelope_to_tabs: 'Envelope To Tabs',
  },

  marginsTooltipMessage: 'These fields belongs to create custom cards',

  cardStatuses: [
    { value: 1, text: 'Active' },
    { value: 0, text: 'Not Active' },
  ],

  cardOrientation: [
    { value: 'L', text: 'Landscape' },
    { value: 'P', text: 'Portrait' },
    { value: 'F', text: 'Flat' },
  ],

  cardCanCustomize: [
    { value: 1, text: 'Yes' },
    { value: 0, text: 'No' },
  ],
}
export default dimensionCardConstants
