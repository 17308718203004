const ShippingRatesConstants = {
  GETALL_REQUEST: 'SHIPPING_RATES_GETALL_REQUEST',
  GETALL_SUCCESS: 'SHIPPING_RATES_SUCCESS',
  GETALL_FAILURE: 'SHIPPING_RATES_FAILURE',

  ADD_REQUEST: 'SHIPPING_RATES_ADD_REQUEST',
  ADD_SUCCESS: 'SHIPPING_RATES_ADD_SUCCESS',
  ADD_FAILURE: 'SHIPPING_RATES_ADD_FAILURE',

  GETBYID_REQUEST: 'SHIPPING_RATES_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'SHIPPING_RATES_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'SHIPPING_RATES_GETBYID_FAILURE',

  DELETE_REQUEST: 'SHIPPING_RATES_DELETE_REQUEST',
  DELETE_SUCCESS: 'SHIPPING_RATES_DELETE_SUCCESS',
  DELETE_FAILURE: 'SHIPPING_RATES_DELETE_FAILURE',

  UPDATE_REQUEST: 'SHIPPING_RATES_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'SHIPPING_RATES_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'SHIPPING_RATES_UPDATE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    list: { key: 'list', title: 'List Shipping Rates', url: '/shipping-rates/list' },
    manage: { key: 'manage', title: 'Manage Shipping Rates', url: '/shipping-rates' },
    create: { key: 'create', title: 'Create Shipping Rates', url: '/shipping-rates/create' },
    edit: { key: 'edit', title: 'Update Shipping Rates', url: '' },
    delete: { key: 'delete', title: 'Delete Shipping Rates', url: '' },
    view: { key: 'view', title: 'View Shipping Rates', url: '' },
  },
  ShippingRatesStatuses: [
    { value: 1, text: 'Active' },
    { value: 0, text: 'Not Active' },
  ],

  listItemKeyToLabel: {
    id: 'ID',
    name: 'Name',
    ups_code: 'Ups Code',
    base: 'Base',
    per_card_fee: 'Plus Price per Card',
    minimum_fee: 'Minimum fee',
    additional_fee: 'Additional fee',
    mark_up: 'Markup',
    sort_order: 'Sort Order',
    earliest_business_days: 'Earliest business days',
    extra_day_per_num_orders: 'Extra day per num orders',
    status: 'status',
  },
  viewItemKeyToLabel: {
    id: 'ID',
    name: 'Name',
    ups_code: 'Ups Code',
    base: 'Base',
    per_card_fee: 'Plus Price per Card',
    minimum_fee: 'Minimum fee',
    additional_fee: 'Additional fee',
    mark_up: 'Markup',
    sort_order: 'Sort Order',
    earliest_business_days: 'Earliest business days',
    extra_day_per_num_orders: 'Extra day per num orders',
    status: 'status',
  },
  advancedSearch: [
    { id: 'ID' },
    { name: 'Name' },
    { base: 'Base' },
    { per_card_fee: 'Plus Price per Card' },
    { minimum_fee: 'Minimum fee' },
    { sort_order: 'Sort Order' },
    { earliest_business_days: 'Earliest business days' },
    { extra_day_per_num_orders: 'Extra day per num orders' },
    { status: 'status' },
  ],
  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },
}
export default ShippingRatesConstants
