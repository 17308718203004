import React, { useState, useEffect, useRef } from 'react'
import { Form, Button, InputNumber, Modal, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { batchOrdersActions } from '_actions'

const GenerateWorkListModal = ({ isVisible, onOk, onCancel, groupByGCards, groupByInserts }) => {
  const dispatch = useDispatch()

  const [form] = Form.useForm()

  const [disableButton, setDisableButton] = useState(true)
  const [countLoading, setCountLoading] = useState(false)
  const [generationLoading, setGenerationLoading] = useState(false)

  const inputRef = useRef()

  const totalOrders = useSelector(state => state.batchOrders.totalOrders)

  const getOrdersCount = async () => {
    setCountLoading(true)
    await dispatch(batchOrdersActions.getTotalOrdersCount())
    setCountLoading(false)
  }

  useEffect(() => {
    if (isVisible) getOrdersCount()
  }, [isVisible])

  useEffect(() => {
    if (totalOrders) {
      form.setFieldsValue({
        total_order: totalOrders,
      })
      checkFields()
    }
  }, [isVisible, totalOrders])

  const handleFormChange = () => checkFields()

  const checkFields = () => {
    const { total_order, total_employee } = form.getFieldsValue()
    const values = [total_order, total_employee]
    const isValuesEmpty = values.some(value => !value)
    const isLimitExceeded = total_order > totalOrders
    setDisableButton(isValuesEmpty || isLimitExceeded)
  }

  const onCloseModal = () => {
    form.resetFields()
    onCancel()
  }

  const generateWorkListHandler = async values => {
    const payload = { ...values, group_gift_card: groupByGCards, group_insert: groupByInserts }

    setGenerationLoading(true)
    await dispatch(batchOrdersActions.generateWorkList(payload))
    setGenerationLoading(false)
  }

  const onSubmit = async values => {
    await generateWorkListHandler(values)
    form.resetFields()
    onOk()
  }

  const onOrdersInputBlur = () => {
    const value = form.getFieldValue('total_order')
    if (value > totalOrders)
      form.setFieldsValue({
        total_order: totalOrders,
      })
  }

  return (
    <Modal
      maskClosable={false}
      visible={isVisible}
      onCancel={onCloseModal}
      width={400}
      footer={null}
      afterOpenChange={open => open && inputRef.current?.focus()}
    >
      <Spin spinning={countLoading}>
        <Form
          name="generateWorkList"
          form={form}
          layout="horizontal"
          onFinish={onSubmit}
          onFieldsChange={handleFormChange}
        >
          <Form.Item
            label="Total Orders"
            name="total_order"
            labelCol={{ flex: 1, span: 24 }}
            rules={[
              {
                type: 'number',
                max: totalOrders,
                message: 'Cannot be bigger than total orders count!',
              },
            ]}
            validateTrigger={['onChange', 'onBlur']}
            onBlur={onOrdersInputBlur}
          >
            <InputNumber ref={inputRef} type="number" min={0} />
          </Form.Item>
          <Form.Item
            label="Total Employees"
            name="total_employee"
            labelCol={{ flex: 0.82, span: 24 }}
          >
            <InputNumber type="number" min={0} />
          </Form.Item>
          <div className="d-flex flex-row" style={{ justifyContent: 'center' }}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={disableButton}
              loading={generationLoading}
            >
              Generate
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  )
}

export default GenerateWorkListModal
