import { presetCardImagesConstants } from '../_constants'

const initialState = {
  items: [],
  item: {},
}

function presetCardImages(state = initialState, { type, payload }) {
  switch (type) {
    case presetCardImagesConstants.GETALL_SUCCESS:
      return { ...state, items: payload, item: {} }
    case presetCardImagesConstants.GETBYID_SUCCESS:
      return { ...state, item: payload }
    case presetCardImagesConstants.DELETE_SUCCESS:
      return { ...state }
    case presetCardImagesConstants.UPDATE_SUCCESS:
      return { ...state, items: state.items }
    case presetCardImagesConstants.ADD_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          total: state.items.total + 1,
        },
      }
    case presetCardImagesConstants.SETBYID:
      return {
        ...state,
        item: {},
      }
    case presetCardImagesConstants.SETALL:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default presetCardImages
