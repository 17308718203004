import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { Button, Form, Input, Modal, Select } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import { shareOptionActions } from '../../../../_actions'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { shareOptionConstants } from '../../../../_constants'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'

const { formItemLayout, navMenu, sharingMethods } = shareOptionConstants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.view, navMenu.delete, navMenu.manage]

const EditItem = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)
  const { id } = useParams()

  const [navMenuParse, setNavMenuParse] = useState([])
  const [changed, setChange] = useState(false)
  const {
    item: { share_option: option },
  } = useSelector(state => state.shareOptions)

  useEffect(() => {
    dispatch(shareOptionActions.getItemById(id))
    return () => {
      dispatch({ type: shareOptionConstants.SETBYID })
    }
  }, [])

  useEffect(() => {
    if (option) {
      form.setFieldsValue({ ...option })
    }
  }, [option, form])

  const plainSharingMethods = useMemo(
    () => sharingMethods.map(methods => ({ value: methods.value, label: methods.text })),
    [sharingMethods],
  )

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(shareOptionActions.deleteItem(id))
      },
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToView,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  // //////////////////
  // Methods
  // /////////////////

  const navigateToView = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  // //////////////////
  // Form handlers
  // /////////////////

  const onFinish = async values => {
    const mergedOption = {
      ...values,
      id: option.id,
    }
    const convertedOption = convertFormValuesDeep(mergedOption)
    await dispatch(shareOptionActions.updateItemById(option.id, convertedOption))
  }

  return (
    <NavContainer menu={navMenuParse}>
      {useGlobalLoader(
        <div>
          <p>Fields with * are required.</p>
          <Form
            {...formItemLayout}
            name="editClient"
            form={form}
            onFinish={onFinish}
            onFieldsChange={() => setChange(true)}
            scrollToFirstError
          >
            <Form.Item
              label="Sharing Method"
              name="name"
              initialValue="default"
              rules={[
                {
                  required: true,
                  message: 'Please input name!',
                },
              ]}
            >
              <Select options={plainSharingMethods} />
            </Form.Item>

            <Form.Item label="Text" name="text">
              <Input.TextArea rows={6} />
            </Form.Item>

            <Form.Item label="Link" name="link">
              <Input />
            </Form.Item>

            <Form.Item label="Link (Android)" name="link_android">
              <Input />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!changed}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>,
      )}
    </NavContainer>
  )
}

export default EditItem
