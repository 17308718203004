import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import v4 from 'uuid/v4'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { clientGroupActions } from '../../../../_actions'
import { appConstants, clientGroupConstants, settingsConstants } from '../../../../_constants'
import {
  getClientGroupsRows,
  getPage,
  getTotalItems,
} from '../../../../_selectors/client-group.selector.js'

const { navMenu, advancedSearch, deliveryType } = clientGroupConstants

const statusDeliveryRender = text => {
  return `${deliveryType.find(st => st.value === text)?.text}`
}

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    width: '25%',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Logo',
    dataIndex: 'logo_url',
    key: 'logo_url',
    width: '25%',
    render: imageUrl =>
      imageUrl ? (
        <img
          style={{ height: '40px' }}
          alt=" "
          src={`${appConstants.IMAGES_URL}/clientGroupImages/${imageUrl}`}
        />
      ) : null,
  },
  {
    title: 'No Delivery Confirmation',
    dataIndex: 'no_delivery_confirmation',
    key: 'no_delivery_confirmation',
    render: statusDeliveryRender,
    filters: deliveryType,
  },
]

const navMenuInit = [navMenu.list, navMenu.create]

const ManageItems = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getClientGroupsRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const deleteItems = async id => {
    await dispatch(clientGroupActions.deleteItem(id))
  }

  const navigateToList = () => {
    dispatch({ type: clientGroupConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}/list`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.list.key:
          return {
            ...menu,
            url: navigateToList,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  return (
    <NavContainer menu={navMenuParse}>
      <TableFilter
        deleteItems={deleteItems}
        columns={initColumns}
        data={dataSource}
        getDataAction={clientGroupActions.getAll}
        advancedSearch={advancedSearch}
      />
    </NavContainer>
  )
}

export default ManageItems
