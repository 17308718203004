import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { Button, Form, Input, InputNumber, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import { countriesActions } from '../../../../_actions'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { countryConstants } from '../../../../_constants'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'

const { formItemLayout, navMenu } = countryConstants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.view, navMenu.delete, navMenu.manage]

const EditItem = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)
  const { id } = useParams()

  const [navMenuParse, setNavMenuParse] = useState([])
  const [changed, setChange] = useState(false)
  const {
    item: { country },
  } = useSelector(state => state.countries)

  useEffect(() => {
    dispatch(countriesActions.getItemById(id))
    return () => {
      dispatch({ type: countryConstants.SETBYID })
    }
  }, [])

  useEffect(() => {
    if (country) {
      form.setFieldsValue({ ...country })
    }
  }, [country, form])

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToView,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  // //////////////////

  // Methods
  // /////////////////

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(countriesActions.deleteItem(id))
      },
    })
  }

  const navigateToView = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  // //////////////////
  // Form handlers
  // /////////////////

  const onFinish = async values => {
    const mergedCountry = {
      ...values,
      id: country.id,
    }
    const convertedCountry = convertFormValuesDeep(mergedCountry)
    await dispatch(countriesActions.updateItemById(country.id, convertedCountry))
  }

  return (
    <NavContainer menu={navMenuParse}>
      {useGlobalLoader(
        <div>
          <p>Fields with * are required.</p>
          <Form
            {...formItemLayout}
            name="editClient"
            form={form}
            onFinish={onFinish}
            onFieldsChange={() => setChange(true)}
            scrollToFirstError
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input name!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Aliases" name="aliases">
              <Input.TextArea rows={6} />
            </Form.Item>

            <Form.Item label="Delivery Cost" name="delivery_cost">
              <InputNumber step={0.01} min={0.0} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!changed}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>,
      )}
    </NavContainer>
  )
}

export default EditItem
