import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { Button, Form, Input, InputNumber, Modal, Select, DatePicker } from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import { usaFinancialActions } from '../../../../_actions'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import getCurrentUrl from '../../../../_helpers/url-utils'

import { usaFinancialConstants } from '../../../../_constants'

import { convertDataForSelect } from '../../../../_helpers'

const { statuses, formItemLayout, navMenu, radioButton, defaultRadioButton } = usaFinancialConstants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.view, navMenu.manage]

const EditItem = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)
  const { id } = useParams()

  const [navMenuParse, setNavMenuParse] = useState([])
  const [changed, setChange] = useState(false)
  const { item: usaFinancial } = useSelector(state => state.usaFinancial)

  useEffect(() => {
    dispatch(usaFinancialActions.getItemById(id))
    return () => {
      dispatch({ type: usaFinancialConstants.SETBYID })
    }
  }, [])

  const correctTriggerType = useMemo(() => {
    const triggerKey = usaFinancial?.key ?? defaultRadioButton
    return radioButton.find(el => el.value === triggerKey)?.title
  }, [usaFinancial.key])

  const visibleDataPicker = useMemo(() => {
    return !usaFinancial.key
  }, [usaFinancial.key])

  useEffect(() => {
    if (usaFinancial) {
      form.setFieldsValue({
        ...usaFinancial,
        date: usaFinancial?.date ? dayjs(usaFinancial.date) : null,
      })
    }
  }, [usaFinancial, form])

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToView,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const navigateToView = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(usaFinancialActions.deleteItem(id))
      },
    })
  }

  const onFinish = async values => {
    const correctDate = values.date ? moment(values.date.toString()).format('YYYY-MM-DD') : null
    await dispatch(
      usaFinancialActions.updateItemById(
        usaFinancial.id,
        convertFormValuesDeep({ ...values, date: correctDate, key: usaFinancial.key }),
      ),
    )
  }

  return (
    <NavContainer menu={navMenuParse}>
      {usaFinancial && (
        <div>
          <p>Fields with * are required.</p>
          <Form
            {...formItemLayout}
            name="editUsaFinancial"
            form={form}
            onFinish={onFinish}
            onFieldsChange={() => setChange(true)}
            scrollToFirstError
          >
            <Form.Item
              label="Label"
              name="label"
              rules={[
                {
                  required: true,
                  message: 'Please input Label!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Trigger Type">
              <span>{correctTriggerType}</span>
            </Form.Item>
            {visibleDataPicker && (
              <Form.Item
                label="Date"
                name="date"
                rules={[
                  {
                    required: true,
                    message: 'Please choose date!',
                  },
                ]}
              >
                <DatePicker format="MM-DD" />
              </Form.Item>
            )}

            <Form.Item label="Default Card ID" name="default_card_id">
              <InputNumber min={0} />
            </Form.Item>
            <Form.Item label="Sort Order" name="sort_order" initialValue={0}>
              <InputNumber step={1} min={0} />
            </Form.Item>
            <Form.Item name="status" label="Status" initialValue={0}>
              <Select options={convertDataForSelect(statuses)} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!changed}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </NavContainer>
  )
}

export default EditItem
