import { combineReducers } from 'redux'

import { connectRouter } from 'connected-react-router'
import authentication from './authentication.reducer'
import registration from './registration.reducer'
import users from './users.reducer'
import alerts from './alert.reducer'
import settings from './settings.reducers'
import menu from './menu.reducers'
import history from '../_helpers/history'
import clientGroups from './client-group.reducer'
import clients from './clients.reducer'
import categories from './categories.reducer'
import orders from './orders.reducer'
import countries from './countries.reducer'
import inserts from './inserts.reducer'
import cards from './cards.reducer'
import templateCategories from './template-categories.reducer'
import templates from './template.reducer'
import giftCards from './gift-cards.reducer'
import fonts from './fonts.reducer'
import coupons from './coupons.reducer'
import discountRules from './discount-rules.reducer'
import discountCodes from './discounts-codes.reducer'
import signatures from './signatures.reducer'
import holidays from './holiday.reducer'
import notifications from './notifications.reducer'
import denominations from './denominations.reducer'
import employees from './employees.reducer'
import subscriptions from './subscriptions.reducer'
import taxcodes from './tax-codes.reducer'
import shareOptions from './share-options.reducer'
import pagination from './pagination.reducer'
import prePayCredits from './pre-pay-credits.reducer'
import creditCards from './credit-cards.reducer'
import mails from './clients-mails.reducer'
import apiKeys from './api-keys.reducer'
import activities from './activities.reducer'
import queryParams from './query-params.reducer'
import cardImages from './card-images.reducer'
import spinner from './spinner.reducer'
import batchOrders from './batch-orders.reducer'
import ShippingRates from './shipping-rates.reducer'
import ShippingMethods from './shipping-methods.reducer'
import banIP from './banIp.reducer'
import clientNotes from './client-notes.reducer'
import creditHistory from './credit-history.reducer'
import contexts from './contexts.reducer.js'
import automations from './automations.reducer'
import shippingConfigs from './shipping-configs.reducer'
import usaFinancial from './usa-financial.reducer'
import prePaidConfigs from './pre-paid-configs.reducer'
import shopify from './shopify.reducer'
import multiStepCampaigns from './multi-step.reducer'
import campaignSteps from './campaign-steps.reducer'
import prePayCreditsType3 from './pre-paid-credits-type3.reducer'
import qa from './qa.reducer'
import dimensionCard from './card-dimension.reducer'
import cronJobs from './cron-jobs.reducer'
import labelsCreation from './labels-creation.reducer'
import frames from './frames.reducer'
import wordMapping from './word-mapping.reducer'
import impositionLayouts from './imposition-layouts.reducer'
import presetCardImages from './preset-card-images.reducer'

const rootReducer = combineReducers({
  router: connectRouter(history),
  authentication,
  registration,
  activities,
  ShippingMethods,
  batchOrders,
  alerts,
  cards,
  dimensionCard,
  categories,
  clientGroups,
  clients,
  countries,
  coupons,
  creditCards,
  discountCodes,
  discountRules,
  fonts,
  giftCards,
  inserts,
  mails,
  menu,
  notifications,
  orders,
  settings,
  shareOptions,
  signatures,
  spinner,
  holidays,
  denominations,
  employees,
  queryParams,
  subscriptions,
  taxcodes,
  ShippingRates,
  templateCategories,
  templates,
  users,
  pagination,
  prePayCredits,
  prePayCreditsType3,
  cardImages,
  banIP,
  clientNotes,
  creditHistory,
  contexts,
  apiKeys,
  automations,
  shippingConfigs,
  usaFinancial,
  prePaidConfigs,
  shopify,
  multiStepCampaigns,
  campaignSteps,
  qa,
  cronJobs,
  labelsCreation,
  frames,
  wordMapping,
  impositionLayouts,
  presetCardImages,
})

export default rootReducer
