import React, { useEffect, useRef, useState } from 'react'
import { Select, Tag } from 'antd'

import { TweenOneGroup } from 'rc-tween-one'
import { PlusOutlined } from '@ant-design/icons'

import './style.scss'

const TagsGroup = ({
  buttonText,
  tags,
  options,
  addTag,
  removeTag,
  tagsContainerStyle,
  isButtonAboveTags,
}) => {
  const [inputVisible, setInputVisible] = useState(false)
  const inputRef = useRef(null)

  useEffect(() => {
    if (inputVisible) return inputRef.current?.focus()
  }, [inputVisible])

  const tagChild = tags.map(tag => {
    const tagElem = (
      <Tag
        key={tag.value}
        closable
        onClose={e => {
          e.preventDefault()
          removeTag(tag.value)
        }}
        style={{ fontSize: '1rem', lineHeight: 1.5 }}
      >
        {tag.label}
      </Tag>
    )
    return (
      <span key={tag.label} style={{ display: 'inline-block' }}>
        {tagElem}
      </span>
    )
  })

  const showInput = () => setInputVisible(true)

  const closeInput = () => setInputVisible(false)

  const onSelectValue = (value, option) => {
    if (!tags.find(tag => tag.value === value)) addTag(value, option)
  }

  const onDeselectValue = value => removeTag(value)

  return (
    <div
      style={{ display: 'flex', flexDirection: isButtonAboveTags ? 'column-reverse' : 'column' }}
    >
      <div
        style={{
          marginTop: tags.length ? '0.3rem' : 0,
        }}
      >
        <TweenOneGroup
          enter={{
            scale: 0.8,
            opacity: 0,
            type: 'from',
            duration: 100,
          }}
          onEnd={e => {
            if (e.type === 'appear' || e.type === 'enter') {
              e.target.style = 'display: inline-block'
            }
          }}
          leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
          appear={false}
          style={tagsContainerStyle}
        >
          {tagChild}
        </TweenOneGroup>
      </div>
      <div
        style={{
          marginTop: tags.length ? '0.3rem' : 0,
        }}
      >
        {inputVisible ? (
          <Select
            ref={inputRef}
            showSearch
            allowClear
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
            onBlur={closeInput}
            onSelect={onSelectValue}
            onDeselect={onDeselectValue}
            style={{ minWidth: '10rem' }}
            maxTagCount={0}
            mode="multiple"
            autoClearSearchValue
            options={options}
            value={tags}
          />
        ) : (
          <Tag onClick={showInput} className="add-new-tag">
            <PlusOutlined /> {buttonText}
          </Tag>
        )}
      </div>
    </div>
  )
}

export default TagsGroup
