import React from 'react'
import CronJobCard from './cron-job.card'

import './style.scss'

const CronJobsList = ({ data }) => {
  return (
    <div className="cron-jobs-list">
      {data.map(cronJob => (
        <CronJobCard
          key={cronJob.name}
          name={cronJob.name}
          counts={cronJob.counts}
          total={cronJob.total}
        />
      ))}
    </div>
  )
}

export default CronJobsList
