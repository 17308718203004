import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import LabelsCreationPage from './labels-creation'
import Content from '../../components/content'

const LabelsCreation = () => {
  const match = useRouteMatch()

  return (
    <Content>
      <Switch>
        <Route exact path={match.url} component={LabelsCreationPage} />

        <Redirect to={match.url} />
      </Switch>
    </Content>
  )
}

export default LabelsCreation
