import { menuConstants } from '../_constants'

const initialState = {
  search: '',
  menuList: [
    {
      title: 'Home',
      key: 'home',
      icon: 'fe fe-home',
      url: '/',
      visible: true,
    },
    {
      title: 'Client Groups',
      key: 'clientGroups',
      icon: 'fe fe-grid',
      url: '/client-groups',
      permis: ['can_view_users', 'can_edit_users'],
    },
    {
      title: 'Clients',
      key: 'clients',
      icon: 'fe fe-users',
      url: '/clients',
      permis: ['can_view_users', 'can_edit_users'],
    },
    {
      title: 'Orders',
      key: 'orders',
      icon: 'fe fe-shopping-cart',
      url: '/orders',
      permis: ['can_edit_orders'],
    },
    {
      title: 'Batch Orders',
      key: 'batchOrders',
      icon: 'fe fe-shopping-bag',
      url: '/batch-orders',
      permis: ['can_edit_orders'],
    },
    {
      title: 'My Orders',
      key: 'myOrders',
      icon: 'fe fe-clipboard',
      url: '/my-orders',
      permis: ['can_edit_orders'],
    },
    {
      title: 'UPS/USPS',
      key: 'labelCreation',
      icon: 'fe fe-truck',
      url: '/labels-creation',
      permis: ['can_edit_orders'],
    },
    {
      title: 'Preset Card Images',
      key: 'PresetCardImages',
      icon: 'fe fe-database',
      url: '/preset-card-images',
      permis: ['superuser', 'can_edit_cads'],
    },
    {
      title: 'Categories',
      key: 'categories',
      icon: 'fe fe-folder',
      url: '/categories',
      permis: ['can_edit_cards'],
    },
    {
      title: 'Cards',
      key: 'cards',
      icon: 'fe fe-credit-card',
      url: '/cards',
      permis: ['can_edit_cards'],
    },
    {
      title: 'Card Dimension',
      key: 'cardDimension',
      icon: 'fe fe-layers',
      url: '/card-dimension',
      permis: ['can_edit_card_dimensions'],
    },
    {
      title: 'QR Code Frames',
      key: 'frames',
      icon: 'fa fa-qrcode',
      url: '/qr-code-frames',
      permis: ['superuser'],
    },
    {
      title: 'Imposition Layouts',
      key: 'impositionLayouts',
      icon: 'fe fe-layout',
      url: '/imposition-layouts',
      permis: ['can_edit_imposition_layouts'],
    },
    {
      title: 'Template Categories',
      key: 'templateCategories',
      icon: 'fe fe-database',
      url: '/template-categories',
      permis: ['can_edit_templates'],
    },
    {
      title: 'Templates',
      key: 'templates',
      icon: 'fe fe-image',
      url: '/templates',
      permis: ['can_edit_templates'],
    },
    {
      title: 'Gift Cards',
      key: 'giftCards',
      icon: 'fe fe-gift',
      url: '/gift-cards',
      permis: ['can_edit_gift_cards'],
    },
    {
      title: 'Denominations',
      key: 'denominations',
      icon: 'fe fe-dollar-sign',
      url: '/denominations',
      permis: ['can_edit_templates'],
    },
    {
      title: 'Inserts',
      key: 'inserts',
      icon: 'fe fe-arrow-down-circle',
      url: '/inserts',
      permis: ['can_edit_users'],
    },
    {
      title: 'Employees',
      key: 'employees',
      icon: 'fe fe-award',
      url: '/employees',
      permis: ['superuser'],
    },
    {
      title: 'Queue',
      key: 'cron-jobs',
      icon: 'fe fe-edit-3',
      url: '/cron-jobs',
      permis: ['superuser'],
    },
    {
      title: 'Contexts',
      key: 'contexts',
      icon: 'fe fe-package',
      url: '/contexts',
      permis: ['superuser'],
    },
    {
      title: 'Discount Codes',
      key: 'discountCodes',
      icon: 'fe fe-code',
      url: '/discount-codes',
      permis: ['can_edit_discount_codes'],
    },
    {
      title: 'Discount Rules',
      key: 'discountRules',
      icon: 'fe fe-list',
      url: '/discount-rules',
      permis: ['can_edit_discount_rules'],
    },
    {
      title: 'Coupons',
      key: 'coupons',
      icon: 'fe fe-percent',
      url: '/coupons',
      permis: ['can_edit_coupons'],
    },
    {
      title: 'Fonts',
      key: 'fonts',
      icon: 'fe fe-feather',
      url: '/fonts',
      permis: ['can_edit_fonts'],
    },
    {
      title: 'Share Options',
      key: 'shareOptions',
      icon: 'fe fe-share-2',
      url: '/share-options',
      permis: ['superuser'],
    },
    {
      title: 'Countries',
      key: 'countries',
      icon: 'fe fe-flag',
      url: '/countries',
      permis: ['superuser'],
    },
    {
      title: 'Profile',
      key: 'user',
      icon: 'fe fe-user',
      url: '/user/profile',
      visible: true,
    },
    {
      title: 'Signature',
      key: 'signatures',
      icon: 'fe fe-edit-3',
      url: '/signatures',
      permis: ['can_edit_users'],
    },
    {
      title: 'Tax Codes',
      key: 'taxcodes',
      icon: 'fe fe-code',
      url: '/taxcodes',
      permis: ['superuser'],
    },
    {
      title: 'Subscriptions Plans',
      key: 'subscriptions',
      icon: 'fe fe-target',
      url: '/subscriptions',
      permis: ['can_edit_subscriptions'],
    },
    {
      title: 'Shipping Methods',
      key: 'ShippingMethods',
      icon: 'fe fe-list',
      url: '/shipping-methods',
      permis: ['can_edit_shipping_methods'],
    },
    {
      title: 'Shipping Rates',
      key: 'ShippingRates',
      icon: 'fe fe-tag',
      url: '/shipping-rates',
      permis: ['can_edit_shipping_rates'],
    },
    {
      title: 'Shipping Configs',
      key: 'ShippingConfigs',
      icon: 'fe fe-sliders',
      url: '/shipping-configs',
      permis: ['can_view_users'],
    },
    {
      title: 'Shopify',
      key: 'shopify',
      icon: 'fe fe-monitor',
      url: '/shopify',
      permis: ['can_view_users'],
    },
    {
      title: 'Holidays',
      key: 'holidays',
      icon: 'fe fe-sun',
      url: '/holidays',
      permis: ['can_edit_holidays'],
    },
    {
      title: 'Notifications',
      key: 'notifications',
      icon: 'fe fe-alert-circle',
      url: '/notifications',
      permis: ['can_edit_notifications'],
    },
    {
      title: 'Automations',
      key: 'automations',
      icon: 'fe fe-gift',
      url: '/automations',
    },
    {
      title: 'Multi Step Campaigns',
      key: 'multi-step',
      icon: 'fe fe-layers',
      url: '/multi-step',
    },
    {
      title: 'USA Financial Configs',
      key: 'usa-financial',
      icon: 'fe fe-package',
      url: '/usa-financial',
      permis: ['superuser'],
    },
    {
      title: 'Pre-paid Configs',
      key: 'pre-paid-configs',
      icon: 'fe fe-star',
      url: '/pre-paid-configs',
      permis: ['can_edit_subscriptions', 'superuser'],
    },
    {
      title: 'Word Mapping',
      key: 'word-mapping',
      icon: 'fe fe-align-left',
      url: '/word-mapping',
      permis: ['can_edit_orders'],
    },
    {
      title: 'Ban IP Rules',
      key: 'ban_ip',
      icon: 'fe fe-slash',
      url: '/ban_ip',
      permis: ['can_ban_ip_rules'],
    },
  ],
}

const menu = (state = initialState, action) => {
  switch (action.type) {
    case menuConstants.SET_STATE:
      return {
        ...state,
        menuList: action.payload ? [...action.payload] : [],
      }
    case menuConstants.SET_MENU_SEARCH:
      return {
        ...state,
        search: action?.payload,
      }
    default:
      return state
  }
}

export default menu
