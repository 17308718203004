import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { prePayCreditsType3Actions } from '../../../../_actions'
import NavContainer from '../../../../components/nav-container'
import ItemViewer from '../../../../components/item-viewer'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { prepaidCreditsType3Constants } from '../../../../_constants'

const { navMenu, viewItemKeyToLabel } = prepaidCreditsType3Constants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.edit, navMenu.manage]

const ViewItem = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const view = getCurrentUrl(match.url)
  const { id } = useParams()

  const [navMenuParse, setNavMenuParse] = useState([])

  const { item: prePayCredit } = useSelector(state => state.prePayCreditsType3)

  useEffect(() => {
    dispatch(prePayCreditsType3Actions.getItemById(id))
    return () => {
      dispatch({ type: prepaidCreditsType3Constants.SETBYID })
    }
  }, [id, dispatch])

  const onEditItem = () => {
    history.push({
      pathname: `${view}/edit/${id}`,
    })
  }

  const navigateToList = () => {
    history.push({
      pathname: `${view}/list`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.edit.key:
          return {
            ...menu,
            url: onEditItem,
          }
        case navMenu.list.key:
          return {
            ...menu,
            url: navigateToList,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const viewItem = useMemo(() => {
    if (Object.keys(prePayCredit).length) {
      return Object.keys(viewItemKeyToLabel).reduce((obj, sKey) => {
        let itemValue = prePayCredit[sKey]
        if (sKey === 'user_id')
          itemValue = (
            <Link to={`/clients/edit/${prePayCredit.user_id}`}>{prePayCredit.user_id}</Link>
          )
        else if (sKey === 'discount') itemValue = `${itemValue}%`
        return {
          ...obj,
          [sKey]: itemValue,
        }
      }, {})
    }
    return {}
  }, [prePayCredit])

  return (
    <NavContainer menu={navMenuParse}>
      <ItemViewer item={viewItem} keyToLabelMap={viewItemKeyToLabel} />
    </NavContainer>
  )
}

export default ViewItem
