import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { Button, Form, Input, InputNumber, Modal, Upload } from 'antd'
import { ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import { giftCardActions } from '../../../../_actions'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { appConstants, giftCardsConstants } from '../../../../_constants'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'

const { formItemLayout, navMenu } = giftCardsConstants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.view, navMenu.delete, navMenu.manage]

const EditItem = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { id } = useParams()

  const [navMenuParse, setNavMenuParse] = useState([])
  const [giftCardImage, setGiftCardImage] = useState(null)
  const [changed, setChange] = useState(false)
  const {
    item: { gcard },
  } = useSelector(state => state.giftCards)

  const view = getCurrentUrl(match.url)

  useEffect(() => {
    dispatch(giftCardActions.getItemById(id))
    return () => {
      dispatch({ type: giftCardsConstants.SETBYID })
    }
  }, [id, dispatch])

  const navigateToView = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(giftCardActions.deleteItem(id))
      },
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToView,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  useEffect(() => {
    form.setFieldsValue({
      ...gcard,
      image: [
        {
          uid: '-1',
          name: gcard?.image,
          status: 'done',
          url: `${appConstants.IMAGES_URL}/cardimages/${gcard?.image}`,
          key: gcard?.image,
        },
      ],
    })
  }, [gcard, form])

  const normFile = e => {
    let file = e && e.fileList

    if (Array.isArray(e)) file = e

    const reader = new FileReader()
    reader.onload = () => setGiftCardImage(reader.result)
    reader.readAsDataURL(file[0].originFileObj)

    return file
  }

  const onFinish = async values => {
    const [image] = values.image
    const newOrder = { name: values.name, file: null }

    if (image.name !== gcard.image) newOrder.file = { ...image, thumbUrl: giftCardImage }
    newOrder.sort = values.sort
    await dispatch(giftCardActions.updateItemById(gcard.id, newOrder))
  }

  return (
    <NavContainer menu={navMenuParse}>
      {useGlobalLoader(
        <div>
          <p>Fields with * are required.</p>
          <Form
            {...formItemLayout}
            name="basic"
            form={form}
            onFinish={onFinish}
            onFieldsChange={() => setChange(true)}
            scrollToFirstError
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input name!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Sort Order" name="sort">
              <InputNumber min={0} />
            </Form.Item>

            <Form.Item
              label="Image"
              name="image"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: true,
                  message: 'Please upload image!',
                },
              ]}
            >
              <Upload
                name="icon"
                maxCount={1}
                beforeUpload={file => {
                  console.log(file)
                  return false
                }}
                action=""
                listType="picture"
                style={{ width: 'auto' }}
                showUploadList={{ showRemoveIcon: false }}
              >
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!changed}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>,
      )}
    </NavContainer>
  )
}

export default EditItem
