const templateConstants = {
  GETALL_REQUEST: 'TEMPLATE_CONSTANTS_GETALL_REQUEST',
  GETALL_SUCCESS: 'TEMPLATE_CONSTANTS_GETALL_SUCCESS',
  GETALL_FAILURE: 'TEMPLATE_CONSTANTS_GETALL_FAILURE',

  GETBYID_REQUEST: 'TEMPLATE_CONSTANTS_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'TEMPLATE_CONSTANTS_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'TEMPLATE_CONSTANTS_GETBYID_FAILURE',

  ADD_REQUEST: 'TEMPLATE_CONSTANTS_ADD_REQUEST',
  ADD_SUCCESS: 'TEMPLATE_CONSTANTS_ADD_SUCCESS',
  ADD_FAILURE: 'TEMPLATE_CONSTANTS_ADD_FAILURE',

  DELETE_REQUEST: 'TEMPLATE_CONSTANTS_DELETE_REQUEST',
  DELETE_SUCCESS: 'TEMPLATE_CONSTANTS_DELETE_SUCCESS',
  DELETE_FAILURE: 'TEMPLATE_CONSTANTS_DELETE_FAILURE',

  UPDATE_REQUEST: 'TEMPLATE_CONSTANTS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'TEMPLATE_CONSTANTS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'TEMPLATE_CONSTANTS_UPDATE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  statuses: [
    { value: 0, text: 'ACTIVE' },
    { value: 1, text: 'NO ACTIVE' },
  ],

  navMenu: {
    list: { key: 'list', title: 'List Template', url: '/templates/list' },
    manage: { key: 'manage', title: 'Manage Template', url: '/templates' },
    create: { title: 'Create Template', key: 'create', url: '/templates/create' },
    edit: { key: 'edit', title: 'Update Template', url: '' },
    delete: { key: 'delete', title: 'Delete Template', url: '' },
    view: { key: 'view', title: 'View Template', url: '' },
  },

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },

  checkBoxGroupStyle: {
    display: 'grid',
    gridGap: 20,
    maxHeight: '300px',
    border: '1px solid #e4e9f0',
    overflowY: 'scroll',
    padding: '10px 0 10px 10px',
  },

  listItemKeyToLabel: {
    id: 'ID',
    category_id: 'Category',
    status: 'Status',
    name: 'Name',
    message: 'Message',
    wishes: 'Wishes',
    user_groups: 'User group',
  },

  viewItemKeyToLabel: {
    id: 'ID',
    status: 'Status',
    name: 'Name',
    category_id: 'Category',
    message: 'Message',
    wishes: 'Wishes',
  },
  advancedSearch: [
    { label: 'Category', key: 'category_id', type: 'select' },
    { label: 'Status', key: 'status_filter', type: 'select' },
    { label: 'Name', key: 'name' },
    { label: 'Message', key: 'message' },
  ],
}
export default templateConstants
