import { appConstants } from '../_constants'

const generateAdvancedStr = (value, key, query, match) => {
  const indexOperator = appConstants.ADVANCED_SEARCH.findIndex(operator =>
    value?.toString().includes(operator),
  )
  if (indexOperator !== -1 && key !== 'code') {
    const operator = appConstants.ADVANCED_SEARCH[indexOperator]
    const trimValue = value.trim()
    const splitTrimValue = trimValue.split(operator)
    if (match === '/orders' && key === 'gcard_id') {
      query.where.denomination = `${key},${operator} ${splitTrimValue[1].trim()}`
    } else {
      query.where[key] = `${operator} ${splitTrimValue[1].trim()}`
    }
    return query
  }
  if (value !== undefined) {
    query.like[key] = value
  }
  return query
}

export default generateAdvancedStr
