import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import {
  Alert,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Popover,
  Select,
  Tooltip,
} from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import NavContainer from '../../../../components/nav-container'
import { clientGroupActions, clientsActions, queryParamsActions } from '../../../../_actions'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { clientsConstants, limitConstants } from '../../../../_constants'
import clientsService from '../../../../_services/clients.service'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import useValidationEmailForNotification from '../../../../hooks/clients/useValidationEmailForNotification.js'
import { getClientGroupsRows } from '_selectors/client-group.selector'

const { clientStatuses, navMenu, formItemLayout } = clientsConstants
const { MAX_ADDRESS_LENGTH, MAX_NAME_LENGTH } = limitConstants

const navMenuInit = [
  navMenu.view,
  navMenu.delete,
  navMenu.manage,
  navMenu.orders,
  navMenu.creditCards,
  navMenu.creditHistory,
  navMenu.activity,
  navMenu.listMails,
  navMenu.clientNotes,
  navMenu.inserts,
  navMenu.signatures,
  navMenu.cards,
  navMenu.prePayCredits,
  navMenu.prePayCreditsType3,
  navMenu.apiKeys,
  navMenu.automations,
  navMenu.usaFinancialTriggers,
  navMenu.resetPassword,
]

const EditItem = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const match = useRouteMatch()

  const view = getCurrentUrl(match.url)
  const [form] = Form.useForm()
  const { id } = useParams()

  const [navMenuParse, setNavMenuParse] = useState([])
  const [isChangedCredit, setChangedCredit] = useState(false)
  const [addressBillingWarning, setAddressBillingWarning] = useState(true)
  const [changed, setChange] = useState(false)

  const {
    item: { countries, client, employees },
  } = useSelector(state => state.clients)
  const clientGroups = useSelector(getClientGroupsRows)

  const { emailWarning, setEmailWarning, validateEmailList } = useValidationEmailForNotification()

  const countOfItems = useMemo(() => {
    return {
      clientNotesCount: client?.total_user_notes,
      // totalApiKeys: client?.total_api_keys,
      totalInserts: client?.total_inserts,
      totalOrders: client?.total_orders,
      totalSignatures: client?.total_signatures,
      totalCreditCards: client?.total_credit_cards,
      totalCards: client?.total_cards,
      totalAutomations: client?.total_automations,
      usaFinancialTriggers: client?.total_usa_financial_triggers,
    }
  }, [client])

  const plainStatuses = useMemo(
    () => clientStatuses.map(status => ({ value: parseInt(status.value, 10), label: status.text })),
    [clientStatuses],
  )

  const plainGroups = useMemo(
    () => clientGroups?.map(group => ({ value: group.id, label: group.title })),
    [clientGroups],
  )

  const plainCountries = useMemo(
    () =>
      countries?.map(country => ({
        value: country.id,
        label: country.name,
      })),
    [countries],
  )

  const plainEmployees = useMemo(
    () =>
      employees?.map(employee => ({
        value: employee.id,
        label: employee.username,
      })),
    [employees],
  )

  useEffect(() => {
    Promise.all([dispatch(clientsActions.getItemById(id)), dispatch(clientGroupActions.getAll())])

    return () => {
      dispatch({ type: clientsConstants.SETBYID })
    }
  }, [id])

  useEffect(() => {
    if (Object.keys(client).length > 0) {
      setAddressBillingWarning(
        !!client.billing_zip?.value &&
          !!client.billing_country_id?.value &&
          !!client.billing_address?.value,
      )

      form.setFieldsValue({
        ...client,
        address: {
          ...client.address,
          birthday: client.address?.birthday ? dayjs(client.address.birthday) : null,
        },
      })
    }
  }, [client, form])

  const onCreditChange = useCallback(
    value => {
      setChangedCredit(Number(client?.credit) !== value)
    },
    [client],
  )

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToView,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        case navMenu.creditCards.key:
          return {
            ...menu,
            url: navigateToCreditCards,
            count: countOfItems.totalCreditCards,
          }
        case navMenu.creditHistory.key:
          return {
            ...menu,
            url: navigateToCreditHistory,
          }
        case navMenu.orders.key:
          return {
            ...menu,
            url: navigateToOrders,
            count: countOfItems.totalOrders,
          }
        case navMenu.activity.key:
          return {
            ...menu,
            url: navigateToActivity,
          }
        case navMenu.listMails.key:
          return {
            ...menu,
            url: navigateToMail,
          }
        case navMenu.clientNotes.key:
          return {
            ...menu,
            url: navigateToNotes,
            count: countOfItems.clientNotesCount,
          }
        case navMenu.prePayCredits.key:
          return {
            ...menu,
            url: navigateToprePayCredits,
          }
        case navMenu.prePayCreditsType3.key:
          return {
            ...menu,
            url: navigateToPrepaidCreditsType3,
          }
        case navMenu.inserts.key:
          return {
            ...menu,
            url: navigateToInserts,
            count: countOfItems.totalInserts,
          }
        case navMenu.signatures.key:
          return {
            ...menu,
            url: navigateToSignatures,
            count: countOfItems.totalSignatures,
          }
        case navMenu.cards.key:
          return {
            ...menu,
            url: () => navigateToCardsClientGroup(client),
          }
        case navMenu.apiKeys.key:
          return {
            ...menu,
            url: navigateToApiKeys,
            // count: countOfItems.totalApiKeys,
          }
        case navMenu.resetPassword.key:
          return {
            ...menu,
            url: resetPassword,
          }
        case navMenu.usaFinancialTriggers.key:
          return {
            ...menu,
            url: navigateToUsaFinancialTriggers,
            count: countOfItems.usaFinancialTriggers,
          }
        case navMenu.automations.key:
          return {
            ...menu,
            url: navigateToAutomations,
            count: countOfItems.totalAutomations,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit, countOfItems])

  // //////////////////
  // Methods
  // /////////////////

  const resetPassword = () => {
    const { login } = form.getFieldsValue()

    Modal.confirm({
      title: 'Reset Password',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to reset password for this user?',
      okText: 'Reset',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: () => clientsService.resetPasswords(login),
    })
  }

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(clientsActions.deleteItem(id))
      },
    })
  }

  const navigateToSignatures = () => {
    dispatch(queryParamsActions.setQueryParams({ user_id: id }))
    history.push({
      pathname: '/signatures',
    })
  }

  const navigateToInserts = () => {
    dispatch(queryParamsActions.setQueryParams({ user_id: id }))
    history.push({
      pathname: '/inserts',
    })
  }

  const navigateToView = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  const navigateToActivity = () => {
    history.push({
      pathname: `${view}/activities/${id}`,
    })
  }

  const navigateToMail = () => {
    dispatch(queryParamsActions.setQueryParams({ user_id: id }))
    history.push({
      pathname: `${view}/client-mails/${id}`,
    })
  }

  const navigateToNotes = () => {
    dispatch(queryParamsActions.setQueryParams({ user_id: id }))
    history.push({
      pathname: '/client-notes',
    })
  }

  const navigateToOrders = () => {
    dispatch(queryParamsActions.setQueryParams({ user_id: id }))
    history.push({
      pathname: '/orders',
    })
  }

  const navigateToprePayCredits = () => {
    dispatch(queryParamsActions.setQueryParams({ user_id: id }))
    history.push({
      pathname: `/credit_cards2`,
    })
  }

  const navigateToPrepaidCreditsType3 = () => {
    dispatch(queryParamsActions.setQueryParams({ user_id: id }))
    history.push({
      pathname: `/credit_type3`,
    })
  }

  const navigateToAutomations = () => {
    dispatch(queryParamsActions.setQueryParams({ user_id: id }))
    history.push({
      pathname: `/automations`,
    })
  }

  const navigateToCardsClientGroup = async client => {
    let clientGroupCategories = null

    if (client.group_id) {
      const res = await dispatch(clientGroupActions.getItemById(client.group_id))
      clientGroupCategories = res.data?.user_groups?.category.map(cat => cat.id)
    }

    dispatch(
      queryParamsActions.setQueryParams({
        category_id: clientGroupCategories,
        isCustom_id: client.id,
      }),
    )

    history.push({
      pathname: `/cards`,
    })
  }

  const navigateToCreditCards = () => {
    dispatch(queryParamsActions.setQueryParams({ user_id: id }))
    history.push({
      pathname: `${view}/credit-cards`,
    })
  }

  const navigateToApiKeys = () => {
    dispatch(queryParamsActions.setQueryParams({ user_id: id }))
    history.push({
      pathname: `${view}/api-keys/${id}`,
    })
  }

  const navigateToUsaFinancialTriggers = () => {
    dispatch(queryParamsActions.setQueryParams({ user_id: id }))
    history.push({
      pathname: `/usa_financial_triggers`,
    })
  }

  const navigateToCreditHistory = () => {
    dispatch(queryParamsActions.setQueryParams({ user_id: id }))
    history.push({
      pathname: `${view}/credit_history/${id}`,
    })
  }

  // //////////////////
  // Form handlers
  // /////////////////

  const onFinish = async values => {
    const { credit, ...rest } = values
    const mergedClient = {
      ...rest,
      id: client.id,
      free_cards: values.free_cards ?? 0,
      ...(isChangedCredit ? { credit: credit } : {}),
      address: client.address
        ? {
            ...values.address,
            ...(client.address ? { id: client.address.id } : {}),
            user_id: client.id,
            birthday: values.address?.birthday?.format('YYYY-MM-DD'),
          }
        : null,
    }
    const convertedClient = convertFormValuesDeep(mergedClient)

    await dispatch(clientsActions.updateItemById(client.id, convertedClient))
  }

  const navigateToOrder = () => {
    dispatch(queryParamsActions.setQueryParams({ user_id: client.id }))
    history.push({
      pathname: '/orders',
    })
  }

  const genToken = async clientId => {
    await dispatch(clientsActions.genToken(clientId))
  }

  const onFieldsChange = (changedFields, allFields) => {
    setChange(true)
    const addressArrayFields = allFields.filter(
      ({ name }) =>
        name.indexOf('billing_zip') >= 0 ||
        name.indexOf('billing_country_id') >= 0 ||
        name.indexOf('billing_address') >= 0,
    )
    setAddressBillingWarning(!addressArrayFields.every(({ value }) => value))
  }

  const logInAsUser = useMemo(() => {
    if (!client?.token) return ''
    return `${process.env.REACT_APP_API_URL_WEB}/hash=${client?.token}`
  }, [process.env.REACT_APP_API_URL_WEB, client?.token])

  const contentCreditsPopUp = useCallback(prepaid_list => {
    if (prepaid_list) {
      const content = prepaid_list.map(el => {
        return (
          <>
            <div
              key={el.id}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '.5rem 0',
                minWidth: '30rem',
              }}
            >
              <span>
                <b>credit: </b>${el.credit}
              </span>
              &nbsp;
              <span>
                <b>discount:</b> {el.discount}%
              </span>
              &nbsp;
              <span>
                <b>exparites date:</b> {el.expires_at}
              </span>
            </div>
          </>
        )
      })
      return content
    }
    return []
  }, [])

  const correctAutoReplenishment = useMemo(() => {
    if (!client?.prepaid_plan) return '-'
    return client?.prepaid_plan?.threshold
  }, [client?.prepaid_plan?.threshold])

  return (
    <NavContainer menu={navMenuParse}>
      {useGlobalLoader(
        <div>
          <p>Fields with * are required.</p>
          <Form
            {...formItemLayout}
            name="editClient"
            form={form}
            onFinish={onFinish}
            onFieldsChange={onFieldsChange}
            scrollToFirstError
          >
            <Form.Item label="Status" name="status">
              <Select options={plainStatuses} />
            </Form.Item>

            <Form.Item
              label="Login"
              name="login"
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: 'Please input login!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="First Name"
              name="fname"
              rules={[
                {
                  required: true,
                  message: 'Please input first name!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Last Name"
              name="lname"
              rules={[
                {
                  required: true,
                  message: 'Please input last name!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Group" name="group_id">
              <Select
                showSearch
                options={plainGroups}
                allowClear
                filterOption={(input, option) =>
                  option.label?.toLowerCase()?.includes(input.toLowerCase())
                }
              />
            </Form.Item>

            <Form.Item label="Phone" name="user_phone">
              <InputNumber controls={false} style={{ minWidth: '13rem' }} stringMode />
            </Form.Item>

            <Form.Item label="Free Cards" name="free_cards">
              <InputNumber step={1} precision={0} />
            </Form.Item>

            <Form.Item label="Shared" name="shared">
              <span className="ant-form-text">{client.shared}</span>
            </Form.Item>

            <Form.Item label="Registered Date" name="registered_date">
              <span className="ant-form-text">{client.registered_date}</span>
            </Form.Item>

            <Form.Item label="Last Visit Date" name="last_visit_date">
              <span className="ant-form-text">{client.last_visit_date}</span>
            </Form.Item>

            <Form.Item label="Anet Customer" name="anet_customer_id">
              <span className="ant-form-text">{client.anet_customer_id}</span>
            </Form.Item>

            <Form.Item label="Discount Code" name="discount_code">
              <span className="ant-form-text">{client.discount_code}</span>
            </Form.Item>

            <Form.Item label="Api key" name="token">
              <div>
                <span className="ant-form-text">{client.token}</span>
                <Button htmlType="button" onClick={() => genToken(client.id)} className="btn">
                  Regenerate token
                </Button>
                <Button style={{ marginLeft: '0.5rem' }}>
                  <a href={logInAsUser} target="_blank" rel="noopener noreferrer">
                    Log In As User
                  </a>
                </Button>
              </div>
            </Form.Item>

            <Form.Item label="Orders" name="total_orders">
              <span
                style={{ cursor: 'pointer' }}
                className={`ant-form-text ${
                  client.total_orders ? 'list-view-item-bold list-item-underline px-0' : ''
                }`}
                onClick={() => navigateToOrder()}
              >
                {client.total_orders ? client.total_orders : 'none'}
              </span>
            </Form.Item>

            <Form.Item label="Salesperson" name="last_employee_id">
              <Select options={plainEmployees} allowClear />
            </Form.Item>

            <Form.Item
              label="Can customize cards"
              name="can_customize_card"
              valuePropName="checked"
            >
              <Checkbox name="can_customize_card" />
            </Form.Item>

            <Form.Item label="Invoiced" name="invoiced" valuePropName="checked">
              <Checkbox name="invoiced" />
            </Form.Item>

            <Form.Item
              label="Attach Invoice to email"
              name="attach_invoice"
              valuePropName="checked"
            >
              <Checkbox name="attach_invoice" />
            </Form.Item>

            <Form.Item label="Tax Exempt" name="tax_exempt" valuePropName="checked">
              <Checkbox name="tax_exempt" />
            </Form.Item>

            <Form.Item label="Test Mode" name="test_mode" valuePropName="checked">
              <Checkbox name="test_mode" />
            </Form.Item>

            <Form.Item label="Credit Cards" name="total_cards">
              <span
                style={{ cursor: 'pointer' }}
                className={`ant-form-text ${
                  client.total_credit_cards ? 'list-view-item-bold list-item-underline px-0' : ''
                }`}
                onClick={() => navigateToCreditCards()}
              >
                {client.total_credit_cards || 'none'}
              </span>
            </Form.Item>

            <Form.Item label="Credit" name="credit">
              <InputNumber
                step={0.1}
                precision={2}
                decimalSeparator="."
                onChange={onCreditChange}
              />
            </Form.Item>

            {isChangedCredit && (
              <Form.Item label="Note" name="credit_note">
                <Input />
              </Form.Item>
            )}

            <Divider orientation="left">Prepaid Credit</Divider>

            <Form.Item label="Total Balance">
              {client.credit_type3?.balance ? (
                <span className="ant-form-text">
                  {`$${client.credit_type3.balance.toFixed(2)}`}
                </span>
              ) : (
                '----'
              )}
            </Form.Item>

            <Form.Item label="Max Discount">
              {client.credit_type3?.discount ? (
                <span className="ant-form-text">{`${client.credit_type3.discount}%`}</span>
              ) : (
                '----'
              )}
            </Form.Item>

            <Form.Item label="Subscription">
              {client.subscription ? (
                <span className="ant-form-text" style={{ marginTop: '.3rem' }}>
                  <span className="ant-form-text">
                    ${client?.credit_type3?.subscription_balance?.toFixed(2) ?? 0}
                  </span>
                  {client?.credit_type3?.subscription_list?.length === 1 ? (
                    <span>
                      {' '}
                      Expires at: {client?.credit_type3?.subscription_list[0].expires_at}
                    </span>
                  ) : (
                    <Popover
                      content={contentCreditsPopUp(client?.credit_type3?.subscription_list)}
                      title={<b>Subscription Plans Credit:</b>}
                      trigger="hover"
                    >
                      <span>
                        <b>More info</b>
                      </span>
                    </Popover>
                  )}
                  <br />
                  Plan: {client.subscription.name}
                  <br />
                  Credits provided: {client.subscription.credit2} $<br />
                  Discount: {client.subscription.discount} %<br />
                  Expiration date: {client.subscription.expires_at}
                  <br />
                </span>
              ) : (
                '----'
              )}
            </Form.Item>
            <Form.Item label="Prepaid Plan:">
              {client?.credit_type3?.prepaid_list?.length ? (
                <>
                  <span className="ant-form-text" style={{ marginTop: '.3rem' }}>
                    ${client?.credit_type3?.prepaid_balance?.toFixed(2) ?? 0}
                  </span>
                  {client.credit_type3.prepaid_list?.length === 1 ? (
                    <span> Expires at: {client?.credit_type3?.prepaid_list[0].expires_at}</span>
                  ) : (
                    <Popover
                      content={contentCreditsPopUp(client?.credit_type3?.prepaid_list)}
                      title={<b>Prepaid Plans Credit:</b>}
                      trigger="hover"
                    >
                      <span>
                        <b>More info</b>
                      </span>
                    </Popover>
                  )}
                  <br />
                  <span className="ant-form-text">
                    Discount: {client?.prepaid_plan ? `${client?.prepaid_plan?.discount}%` : '--'}
                  </span>

                  <br />
                  <span className="ant-form-text">
                    Auto-replenish: {client?.prepaid_plan ? 'YES' : 'NO'}
                  </span>
                  <br />

                  {!!client?.prepaid_plan && (
                    <span className="ant-form-text">
                      Last plan credit ${client?.prepaid_plan.credit} (auto-replenishment when less
                      than ${correctAutoReplenishment})
                    </span>
                  )}
                </>
              ) : (
                '----'
              )}
            </Form.Item>

            <Divider orientation="left">Low Prepaid Credit Notifications</Divider>

            <Form.Item
              label="Low Prepaid Credit Notifications"
              name="low_credit_notification_send"
              valuePropName="checked"
            >
              <Checkbox name="low_credit_notification_send" />
            </Form.Item>

            <Form.Item label="Low Prepaid Credit Threshold" name="low_credit_threshold">
              <InputNumber step={0.1} precision={2} decimalSeparator="." />
            </Form.Item>

            <Form.Item label="Client Notification Emails">
              <Tooltip title="Wrong format. Check your value." open={emailWarning}>
                <Form.Item name="low_credit_notification_emails" noStyle>
                  <Input.TextArea
                    rows={6}
                    onFocus={() => setEmailWarning(false)}
                    onBlur={validateEmailList}
                    style={emailWarning ? { border: '1px solid #E9D502' } : {}}
                  />
                </Form.Item>
              </Tooltip>
              <p style={{ fontSize: '13px' }}>
                * You can separate emails by writing them on a new line, use space or one of the
                special symbols like ";" or ","
              </p>
            </Form.Item>

            <Divider orientation="left">User Address</Divider>

            {client.address ? (
              <>
                <Form.Item
                  label="Name"
                  name={['address', 'name']}
                  rules={[
                    {
                      required: true,
                      message: 'Please input name!',
                    },
                    {
                      max: MAX_NAME_LENGTH,
                      message: `Name must be less than ${MAX_NAME_LENGTH} characters`,
                    },
                  ]}
                >
                  <Input maxLength={MAX_NAME_LENGTH} showCount />
                </Form.Item>
                <Form.Item
                  label="Business Name"
                  name={['address', 'business_name']}
                  initialValue=""
                  rules={[
                    {
                      max: MAX_NAME_LENGTH,
                      message: `Business Name must be less than ${MAX_NAME_LENGTH} characters`,
                    },
                  ]}
                >
                  <Input maxLength={MAX_NAME_LENGTH} showCount />
                </Form.Item>
                <Form.Item
                  label="Address1"
                  name={['address', 'address1']}
                  rules={[
                    {
                      required: true,
                      message: 'Please input address1!',
                    },
                    {
                      max: MAX_ADDRESS_LENGTH,
                      message: `Address1 must be less than ${MAX_ADDRESS_LENGTH} characters`,
                    },
                  ]}
                >
                  <Input maxLength={MAX_ADDRESS_LENGTH} showCount />
                </Form.Item>
                <Form.Item
                  label="Address2"
                  name={['address', 'address2']}
                  initialValue=""
                  rules={[
                    {
                      max: MAX_ADDRESS_LENGTH,
                      message: `Address2 must be less than ${MAX_ADDRESS_LENGTH} characters`,
                    },
                  ]}
                >
                  <Input maxLength={MAX_ADDRESS_LENGTH} showCount />
                </Form.Item>
                <Form.Item
                  label="City"
                  name={['address', 'city']}
                  rules={[
                    {
                      required: true,
                      message: 'Please input city!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label="State" name={['address', 'state']} initialValue="">
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Zip"
                  name={['address', 'zip']}
                  rules={[
                    {
                      required: true,
                      message: 'Please input zip!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label="Country" name={['address', 'country']}>
                  <Input />
                </Form.Item>
                <Form.Item label="Birthday" name={['address', 'birthday']}>
                  <DatePicker />
                </Form.Item>
              </>
            ) : (
              <Alert message="Warning" description="No address." type="warning" showIcon />
            )}
            <Divider orientation="left" />

            <Form.Item label="Billing Country" name="billing_country_id">
              <Select
                allowClear
                showSearch
                options={plainCountries}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())
                }
              />
            </Form.Item>

            <Form.Item label="Billing Zip" name="billing_zip">
              <Input />
            </Form.Item>

            <Form.Item label="Billing Address" name="billing_address">
              <Input />
            </Form.Item>

            {addressBillingWarning && (
              <Alert
                message="Warning"
                description="No Billing address."
                type="warning"
                showIcon
                style={{ marginBottom: 20 }}
              />
            )}

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!changed}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>,
      )}
    </NavContainer>
  )
}

export default EditItem
