import lodash from 'lodash'
import { appConstants, batchOrdersConstants } from '../_constants'
import { batchOrdersService, ordersService } from '../_services'
import alertActions from './alert.actions'

const getGroups = (groupByGCards, groupByInserts) => async dispatch => {
  dispatch({ type: batchOrdersConstants.GET_GROUPS_REQUEST })
  try {
    const { data } = await batchOrdersService.getGroups(groupByGCards, groupByInserts)

    dispatch({ type: batchOrdersConstants.GET_GROUPS_SUCCESS, payload: data })
  } catch (error) {
    dispatch({ type: batchOrdersConstants.GET_GROUPS_FAILURE, payload: error.toString() })
  }
}

const getPrintedOrders = (printedOrders, groupByGCards, groupByInserts) => async dispatch => {
  dispatch({ type: batchOrdersConstants.GET_PRINTED_ORDERS_REQUEST })
  try {
    const { data } = await batchOrdersService.getPrintedOrders(
      printedOrders,
      groupByGCards,
      groupByInserts,
    )

    dispatch({
      type: batchOrdersConstants.GET_PRINTED_ORDERS_SUCCESS,
      payload: { rows: data.rows },
    })
  } catch (error) {
    dispatch({
      type: batchOrdersConstants.GET_PRINTED_ORDERS_FAILURE,
      payload: error.toString(),
    })
  }
}

const getOrdersByDate = (date, groupByGCards, groupByInserts) => async dispatch => {
  dispatch({ type: batchOrdersConstants.GET_ORDERS_BY_DATE_REQUEST })
  try {
    const { data } = await batchOrdersService.getOrdersByDate(date, groupByGCards, groupByInserts)

    dispatch({
      type: batchOrdersConstants.GET_ORDERS_BY_DATE_SUCCESS,
      payload: { rows: data.rows, send_date: date },
    })
  } catch (error) {
    dispatch({ type: batchOrdersConstants.GET_ORDERS_BY_DATE_FAILURE, payload: error.toString() })
  }
}

const getAllInWork = () => async dispatch => {
  dispatch({ type: batchOrdersConstants.GETALL_IN_WORK_REQUEST })
  try {
    const resultData = await batchOrdersService.getAllInWork()
    dispatch({
      type: batchOrdersConstants.GETALL_IN_WORK_SUCCESS,
      payload: { ...updateOrdersDataForRender(resultData) },
    })
  } catch (error) {
    dispatch({ type: batchOrdersConstants.GETALL_IN_WORK_FAILURE, payload: error.toString() })
  }
}

const getByCardId = (
  cardId,
  is_redo,
  is_ups,
  rateId,
  deliveryConfirmation,
  is_gcard,
  is_insert,
  dateSend,
  isCardPrinted,
) => async (dispatch, getState) => {
  const state = getState()
  const isPrinted = typeof state.batchOrders.printedOrderCount === 'number'

  dispatch({ type: batchOrdersConstants.GETBYCARD_REQUEST })

  try {
    const ordersByCardId = await batchOrdersService.getByCardId(
      cardId,
      is_redo,
      is_ups,
      rateId,
      deliveryConfirmation,
      is_gcard,
      is_insert,
      dateSend,
      isCardPrinted,
      isPrinted,
    )

    const updatedOrders = [...(ordersByCardId?.data?.rows || [])].map(orderM => {
      return { ...formatOrder(orderM), modCardId: cardId }
    })
    dispatch({
      type: batchOrdersConstants.GETBYCARD_REQUEST_SUCCESS,
      payload: { [cardId]: updatedOrders },
    })
  } catch (error) {
    dispatch({ type: batchOrdersConstants.GETBYCARD_REQUEST_FAILURE, payload: error.toString() })
  }
}

const saveNote = (id, card_id, value) => async dispatch => {
  dispatch({ type: batchOrdersConstants.SAVENOTE_REQUEST })
  try {
    await ordersService.updateItemById(id, { note: value })
    dispatch({ type: batchOrdersConstants.SAVENOTE_SUCCESS, payload: { id, card_id, value } })
  } catch (error) {
    dispatch({ type: batchOrdersConstants.SAVENOTE_FAILURE, payload: error.toString() })
  }
}

const saveSelectedRows = selectedRows => dispatch => {
  dispatch({ type: batchOrdersConstants.SAVE_SELECTED_ROWS, payload: { ...selectedRows } })
}

const setActivePanel = panel => dispatch => {
  dispatch({ type: batchOrdersConstants.SET_ACTIVE_PANEL, payload: panel })
}

const setRowsWithButton = data => dispatch => {
  dispatch({ type: batchOrdersConstants.SET_ROWS_WITH_BUTTON, payload: data })
}

const clearSelectedRows = cardId => dispatch => {
  dispatch({ type: batchOrdersConstants.CLEAR_SELECTED_ROWS, payload: cardId })
}

const setOrientation = orientation => dispatch => {
  dispatch({ type: batchOrdersConstants.SET_ORIENTATION, payload: orientation })
}

const resetOrientation = () => dispatch => {
  dispatch({ type: batchOrdersConstants.RESET_ORIENTATION, payload: '' })
}

const clearView = type => dispatch => {
  dispatch({ type })
}

const clearManifestsStatus = type => dispatch => {
  dispatch({ type })
}

const processSelectedRows = rowsData => async dispatch => {
  dispatch({ type: batchOrdersConstants.PROCESS_REQUEST })
  try {
    const resultData = await batchOrdersService.processSelectedRows(rowsData)
    dispatch({
      type: batchOrdersConstants.PROCESS_SUCCESS,
      payload: { ...updateOrdersDataForRender(resultData) },
    })
  } catch (error) {
    dispatch({ type: batchOrdersConstants.PROCESS_FAILURE, payload: error.toString() })
  }
}

const checkInWorkOrders = () => async dispatch => {
  dispatch({ type: batchOrdersConstants.CHECK_IN_WORK_REQUEST })
  try {
    const resultData = await batchOrdersService.checkInWork()
    dispatch({
      type: batchOrdersConstants.CHECK_IN_WORK_SUCCESS,
      payload: { ...updateOrdersDataForRender(resultData) },
    })
  } catch (error) {
    dispatch({ type: batchOrdersConstants.CHECK_IN_WORK_FAILURE, payload: error.toString() })
  }
}

const updateOrdersDataForRender = ordersData => {
  return {
    ...ordersData,
    ordersInWork: [...ordersData.ordersInWork]?.map(groupedByCard => {
      return {
        ...groupedByCard,
        orders: groupedByCard.orders?.map(orderM => formatOrder(orderM)),
      }
    }),
  }
}

const formatOrder = order => {
  return {
    ...order,
    key: order.id,
    redo: order.redo_id ? 'Yes' : '',
    date_send: order.date_send.substr(0, 10),
    user_email: order?.user?.name,
    gcard: order?.denomination
      ? `${order?.denomination?.nominal}$ ${order?.denomination?.gcard?.name}`
      : '-',
    custom_inserts: order?.inserts && order?.inserts.length !== 0 ? order?.inserts[0].name : '-',
    intl: order?.to?.country_id !== 1 ? 'intl.' : '',
    ...(order.children && {
      children:
        order.children?.length === 0 ? null : order.children.map(orderM => formatOrder(orderM)),
    }),
  }
}

const completeOrders = ordersIds => async dispatch => {
  dispatch({ type: batchOrdersConstants.COMPLETE_ORDERS_REQUEST })

  try {
    const completedOrdersResult = await batchOrdersService.completeOrders(ordersIds)
    await dispatch(batchOrdersActions.getAllInWork())
    dispatch({ type: batchOrdersConstants.COMPLETE_ORDERS_SUCCESS, payload: completedOrdersResult })
  } catch (error) {
    dispatch({ type: batchOrdersConstants.COMPLETE_ORDERS_FAILURE })
  }
}

const discardOrders = (ordersIds, isConfirm) => async dispatch => {
  dispatch({ type: batchOrdersConstants.DISCARD_ORDERS_REQUEST })

  try {
    const discardedOrdersResult = await batchOrdersService.discardOrders(ordersIds, isConfirm)
    if (!discardedOrdersResult.modalMessage) {
      dispatch({
        type: batchOrdersConstants.DISCARD_ORDERS_SUCCESS,
        payload: discardedOrdersResult,
      })
    }
  } catch (error) {
    dispatch({
      type: batchOrdersConstants.DISCARD_ORDERS_FAILURE,
    })
  }
}

const createDocxNotes = ordersIds => async dispatch => {
  dispatch({ type: batchOrdersConstants.CREATE_DOCX_NOTES_REQUEST })

  try {
    await batchOrdersService.createDocxNotes(ordersIds)

    dispatch({ type: batchOrdersConstants.CREATE_DOCX_NOTES_SUCCESS })
  } catch (error) {
    const { response } = error

    dispatch({
      type: batchOrdersConstants.CREATE_DOCX_ENVELOPES_FAILURE,
    })
    dispatch(alertActions.error(response?.data?.message || response?.statusText))
  }
}

const createPDFNotes = ordersIds => async dispatch => {
  dispatch({ type: batchOrdersConstants.CREATE_DOCX_NOTES_PDF_REQUEST })
  try {
    await batchOrdersService.createPDFNotes(ordersIds)
    dispatch({ type: batchOrdersConstants.CREATE_DOCX_NOTES_PDF_SUCCESS })
  } catch (error) {
    const { response } = error
    dispatch({ type: batchOrdersConstants.CREATE_DOCX_NOTES_PDF_FAILURE })

    dispatch(alertActions.error(response?.data?.message || response?.statusText))
  }
}

const createPDFEnvelopes = ordersIds => async dispatch => {
  dispatch({ type: batchOrdersConstants.CREATE_DOCX_PDF_ENVELOPES_REQUEST })
  try {
    await batchOrdersService.createPDFEnvelopes(ordersIds)
    dispatch({ type: batchOrdersConstants.CREATE_DOCX_PDF_ENVELOPES_SUCCESS })
  } catch (error) {
    const { response } = error
    dispatch({
      type: batchOrdersConstants.CREATE_DOCX_PDF_ENVELOPES_FAILURE,
    })

    dispatch(alertActions.error(response?.data?.message || response?.statusText))
  }
}

const createDocxEnvelopes = ordersIds => async dispatch => {
  dispatch({ type: batchOrdersConstants.CREATE_DOCX_ENVELOPES_REQUEST })

  try {
    await batchOrdersService.createDocxEnvelopes(ordersIds)

    dispatch({ type: batchOrdersConstants.CREATE_DOCX_ENVELOPES_SUCCESS })
  } catch (error) {
    const { response } = error

    dispatch({
      type: batchOrdersConstants.CREATE_DOCX_ENVELOPES_FAILURE,
    })
    dispatch(alertActions.error(response?.data?.message || response?.statusText))
  }
}

const createManifest = (orderIds, isCut = false, isNewManifest) => async dispatch => {
  dispatch({ type: batchOrdersConstants.CREATE_MANIFEST_REQUEST })

  try {
    const result = await batchOrdersService.createManifest(orderIds, isCut, isNewManifest)
    dispatch({ type: batchOrdersConstants.CREATE_MANIFEST_SUCCESS })
    if (isNewManifest) {
      dispatch({
        type: batchOrdersConstants.STATUS_MANIFEST_SUCCESS,
        payload: {
          status: 'new',
          [isCut
            ? 'file_cutLink'
            : 'fileLink']: `${appConstants.IMAGES_URL}/manifests/${result.headers['manifest-name']}`,
          [!isCut ? 'file_cutLink' : 'fileLink']: null,
          orders: orderIds,
          manifest_id: result.headers['manifest-id'],
        },
      })
    } else {
      dispatch({
        type: batchOrdersConstants.UPDATE_MANIFESTS_STATUS,
        payload: {
          [isCut
            ? 'file_cut'
            : 'file']: `${appConstants.IMAGES_URL}/manifests/${result.headers['manifest-name']}`,
        },
      })
    }
  } catch (error) {
    dispatch({
      type: batchOrdersConstants.CREATE_MANIFEST_FAILURE,
    })
  }
}

const generateImposedCards = data => async dispatch => {
  let filename = `${`${data?.manifest_id ? `${data?.manifest_id}_` : ''}`}${
    data?.employee_name
  }_imposed_${data.pageSize.height}x${data.pageSize.width}${data.withBarcode ? '_Barcode' : ''}${`${
    data?.file_name_suffix ? `_${data?.file_name_suffix}` : ''
  }`}.pdf`

  if (data?.worklist)
    filename = `worklist#${data?.worklist}_imposed_${data.pageSize.height}x${data.pageSize.width}${
      data.withBarcode ? '_Barcode' : ''
    }.pdf`

  if (data?.cardId) {
    const cardName = lodash.snakeCase(data.cardName)
    filename = `imposed_${cardName}_${data.pageSize.height}x${data.pageSize.width}${
      data.withBarcode ? '_Barcode' : ''
    }.pdf`
  }

  dispatch({ type: batchOrdersConstants.GENERATE_IMPOSED_CARDS_REQUEST, payload: filename })

  const onDownload = event => {
    const filename = `${event.target.getResponseHeader('file-name')}.pdf`
    const total = event.target.getResponseHeader('X-File-Length')
    const percentage = Math.round((event.loaded * 100) / total)

    if (percentage <= 100) {
      dispatch({
        type: batchOrdersConstants.IMPOSITIONS_DOWNLOAD_PROGRESS,
        payload: { percentage, filename },
      })

      if (percentage === 100)
        setTimeout(() => {
          dispatch({
            type: batchOrdersConstants.IMPOSITIONS_DOWNLOAD_END,
            payload: filename,
          })
        }, 2000)
    }
  }

  try {
    const res = await batchOrdersService.generateImposedCards(data, onDownload)
    dispatch({ type: batchOrdersConstants.GENERATE_IMPOSED_CARDS_REQUEST_SUCCESS })

    if (!data.version) {
      dispatch({
        type: batchOrdersConstants.IMPOSITIONS_DOWNLOAD_END,
        payload: filename,
      })
    }
    return res
  } catch (error) {
    dispatch({
      type: batchOrdersConstants.GENERATE_IMPOSED_CARDS_FAILURE,
      payload: filename,
    })
    return error
  }
}

const printLabels = params => async dispatch => {
  dispatch({ type: batchOrdersConstants.PRINT_LABELS_REQUEST })

  try {
    await batchOrdersService.printLabels(params)
    dispatch({ type: batchOrdersConstants.PRINT_LABELS_SUCCESS })
  } catch (error) {
    dispatch({
      type: batchOrdersConstants.PRINT_LABELS_FAILURE,
    })
  }
}

const cancelLabels = params => async dispatch => {
  dispatch({ type: batchOrdersConstants.CANCEL_LABELS_REQUEST })

  try {
    await batchOrdersService.cancelLabels(params)
    dispatch({ type: batchOrdersConstants.CANCEL_LABELS_SUCCESS })
  } catch (error) {
    dispatch({
      type: batchOrdersConstants.CANCEL_LABELS_FAILURE,
    })
  }
}

const generateLabels = params => async dispatch => {
  dispatch({ type: batchOrdersConstants.GENERATE_LABELS_REQUEST })

  try {
    const { shipmentMessage } = await batchOrdersService.generateLabels(params)
    dispatch({ type: batchOrdersConstants.GENERATE_LABELS_SUCCESS, payload: shipmentMessage })
  } catch (error) {
    dispatch({
      type: batchOrdersConstants.GENERATE_LABELS_FAILURE,
    })
  }
}

const statusLabels = isDetail => async dispatch => {
  dispatch({ type: batchOrdersConstants.STATUS_LABELS_REQUEST })

  try {
    const status = await batchOrdersService.statusLabels(isDetail)
    dispatch({ type: batchOrdersConstants.STATUS_LABELS_SUCCESS, payload: status.data })
  } catch (error) {
    dispatch({ type: batchOrdersConstants.STATUS_LABELS_FAILURE })
  }
}

const statusManifest = () => async dispatch => {
  dispatch({ type: batchOrdersConstants.STATUS_MANIFEST_REQUEST })

  try {
    const status = await batchOrdersService.statusManifest()
    dispatch({ type: batchOrdersConstants.STATUS_MANIFEST_SUCCESS, payload: status.data })
  } catch (error) {
    dispatch({ type: batchOrdersConstants.STATUS_MANIFEST_FAILURE })
  }
}

const getTotalOrdersCount = () => async dispatch => {
  dispatch({ type: batchOrdersConstants.GET_TOTAL_COUNT_REQUEST })

  try {
    const res = await batchOrdersService.getTotalOrdersCount()
    dispatch({ type: batchOrdersConstants.GET_TOTAL_COUNT_SUCCESS, payload: res.data.total_order })
  } catch (error) {
    dispatch({ type: batchOrdersConstants.GET_TOTAL_COUNT_FAILURE })
  }
}

const generateWorkList = params => async dispatch => {
  dispatch({ type: batchOrdersConstants.GENERATE_WORK_LIST_REQUEST })

  try {
    const res = await batchOrdersService.generateWorkList(params)
    dispatch({
      type: batchOrdersConstants.GENERATE_WORK_LIST_SUCCESS,
      payload: res.data,
    })
    return res.data
  } catch (error) {
    dispatch({ type: batchOrdersConstants.GENERATE_WORK_LIST_FAILURE })
  }
}

const generatePrintableList = params => async () => {
  try {
    await batchOrdersService.generatePrintableList(params)
  } catch (error) {
    console.log(error)
  }
}

const batchOrdersActions = {
  getGroups,
  getOrdersByDate,
  getPrintedOrders,
  getByCardId,
  saveNote,
  saveSelectedRows,
  clearSelectedRows,
  setOrientation,
  resetOrientation,
  processSelectedRows,
  completeOrders,
  discardOrders,
  createDocxEnvelopes,
  createDocxNotes,
  createManifest,
  printLabels,
  cancelLabels,
  generateLabels,
  getAllInWork,
  checkInWorkOrders,
  clearView,
  statusLabels,
  statusManifest,
  clearManifestsStatus,
  generateImposedCards,
  createPDFNotes,
  createPDFEnvelopes,
  getTotalOrdersCount,
  generateWorkList,
  generatePrintableList,
  setActivePanel,
  setRowsWithButton,
}

export default batchOrdersActions
