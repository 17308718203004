import { Button, Typography } from 'antd'
import React from 'react'

const QaWarning = ({ manifest_id, title, message, onSubmit, submitText }) => {
  return (
    <div className="qa-page">
      <Typography.Title level={2}>Manifest #{manifest_id}</Typography.Title>
      <Typography style={{ fontWeight: 500 }}>{title}</Typography>
      <Typography>{message}</Typography>
      <Button type="primary" block onClick={onSubmit} style={{ marginTop: '1rem' }}>
        {submitText || 'Continue'}
      </Button>
    </div>
  )
}

export default QaWarning
