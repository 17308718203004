const ordersConstants = {
  GETALL_REQUEST: 'ORDERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'ORDERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'ORDERS_GETALL_FAILURE',

  GETBYID_REQUEST: 'ORDERS_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'ORDERS_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'ORDERS_GETBYID_FAILURE',

  DELETE_REQUEST: 'ORDERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'ORDERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'ORDERS_DELETE_FAILURE',

  UPDATE_REQUEST: 'ORDERS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'ORDERS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'ORDERS_UPDATE_FAILURE',

  DOWNLOAD_DOCX_REQUEST: 'DOWNLOAD_DOCX_REQUEST',
  DOWNLOAD_DOCX_SUCCESS: 'DOWNLOAD_DOCX_SUCCESS',
  DOWNLOAD_DOCX_FAILURE: 'DOWNLOAD_DOCX_FAILURE',

  CREATE_REDO_REQUEST: 'CREATE_REDO_REQUEST',
  CREATE_REDO_SUCCESS: 'CREATE_REDO_SUCCESS',
  CREATE_REDO_FAILURE: 'CREATE_REDO_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    // list: { key: 'list', title: 'List Orders', url: '/orders/list' },
    // listMyOrders: { key: 'list-my-orders', title: 'List My Orders', url: '/orders/my' },
    manage: { key: 'manage', title: 'Manage Orders', url: '/orders' },
    create: { title: 'Create Order', key: 'create', url: '/orders/create' },
    // availableOrders: {
    //   title: 'List Available Orders',
    //   key: 'available-orders',
    //   url: '/orders/available',
    // },
    view: { key: 'view', title: 'View Order', url: '' },
    note: { title: 'Get word: Note', key: 'note', url: '/' },
    note_hmf: { title: 'Get HWF: Note', key: 'note_hmf', url: '/' },
    envelope: { title: 'Get word: Envelope', key: 'envelope', url: '/' },
    envelope_hmf: { title: 'Get HWF: Envelope', key: 'envelope_hmf', url: '/' },
    edit: { key: 'edit', title: 'Update Orders', url: '' },
    delete: { key: 'delete', title: 'Delete Orders', url: '' },
  },

  orderStatuses: [
    { value: 'basket', text: 'BASKET' },
    { value: 'new', text: 'NEW' },
    { value: 'suspended', text: 'SUSPENDED' },
    { value: 'paid', text: 'PAID' },
    { value: 'in_work', text: 'IN WORK' },
    { value: 'complete', text: 'COMPLETE' },
    { value: 'cancelled', text: 'CANCELLED' },
    { value: 'test', text: 'TEST' },
    { value: 'unknown', text: 'unknown' },
  ],

  orderPlatform: [
    { value: 'Desktop/Web', text: 'Desktop/Web' },
    { value: 'Android/Web', text: 'Android/Web' },
    { value: 'iOS/Web', text: 'iOS/Web' },
    { value: 'Unrecognized', text: 'Unrecognized' },
  ],
  orderIsRedo: [
    { value: 1, text: 'Yes' },
    { value: 0, text: 'No' },
  ],

  listItemKeyToLabel: {
    id: 'ID',
    status: 'Status',
    user: 'User',
    card: 'Card',
    gift_card: 'Gift Card',
    inserts: 'Custom Inserts',
    coupon: 'Coupon',
    used_credit: 'Used Credit',
    date_created: 'Date Created',
    date_send: 'Date Send',
    employee: 'Employee',
    font: 'Font',
    platform: 'Platform',
  },

  viewItemKeyToLabel: {
    id: 'ID',
    user: 'User',
    card: 'Card',
    gift_card: 'Gift Card',
    inserts: 'Custom Inserts',
    coupon: 'Coupon',
    used_credit: 'Used Credit',
    date_created: 'Date Created',
    date_payed: 'Date Payed',
    date_complete: 'Date Complete',
    date_send: 'Date Send',
    salesperson_employee_id: 'Salesperson',
    am_employee_id: 'Account Manager',
    status: 'Status',
    refund_status: 'Refund Status',
    employee: 'Employee',
    message: 'Message',
    wishes: 'Wishes',
    size_offset: 'Signature Size Offset',
    transaction_id: 'Transaction Id',
    for_free: 'For free',
    client_metadata: 'Client Metadata',
    delivery_confirmation: 'Delivery Confirmation',
    invoiced: 'Payment Method',
    tax_exempt: 'Tax exempt',
    price: 'Order Price',
    card_price: 'Card Price',
    postage: 'Postage',
    font: 'Font',
    platform: 'Platform',
  },

  advancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'User', key: 'user_id' },
    { label: 'Card', key: 'card_id' },
    { label: 'Gift Card', key: 'gcard_id' },
    { label: 'Recipient Name', key: 'address' },
    { label: 'Date Created', key: 'date_created*date' },
    { label: 'Status', key: 'status_filter', type: 'select' },
    { label: 'Employee', key: 'employee_id' },
    { label: 'Message', key: 'message' },
    { label: 'Transaction Id', key: 'transaction_id' },
    { label: 'For free', key: 'for_free' },
    { label: 'Platform', key: 'platform_filter', type: 'select' },
    { label: 'Payment Method', key: 'invoiced_filter', type: 'select' },
  ],

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },

  invoices: [
    { value: 0, text: 'Credit card' },
    { value: 1, text: 'Invoice' },
  ],

  refundStatus: [
    { value: -2, text: 'Not Available' },
    { value: -1, text: 'Error' },
    { value: 0, text: 'None' },
    { value: 1, text: 'Required (Pending)' },
    { value: 2, text: 'In Progress' },
    { value: 3, text: 'Refunded' },
  ],
}
export default ordersConstants
