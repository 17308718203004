import creditHistoryConstants from '../_constants/credit-history.constants.js'
import { creditHistoryService } from '../_services'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: creditHistoryConstants.GETALL_REQUEST })
  try {
    const creditHistory = await creditHistoryService.getAll(params, typeAction)
    dispatch({ type: creditHistoryConstants.GETALL_SUCCESS, payload: creditHistory.data })
  } catch (error) {
    dispatch({ type: creditHistoryConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const creditHistoryActions = {
  getAll,
}

export default creditHistoryActions
