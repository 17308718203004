import { labelsCreationConstants } from '../_constants'
import { labelsCreationService } from '../_services'

const readyToShipping = (params, typeAction) => async dispatch => {
  dispatch({ type: labelsCreationConstants.GET_READY_TO_SHIPPING_REQUEST })
  try {
    const resultData = await labelsCreationService.readyToShipping(params, typeAction)
    dispatch({
      type: labelsCreationConstants.GET_READY_TO_SHIPPING_SUCCESS,
      payload: resultData?.data,
    })
  } catch (error) {
    dispatch({
      type: labelsCreationConstants.GET_READY_TO_SHIPPING_FAILURE,
      payload: error.toString(),
    })
  }
}

const archivedLabels = (params, typeAction) => async dispatch => {
  dispatch({ type: labelsCreationConstants.GET_ARCHIVED_LABELS_REQUEST })
  try {
    const { data } = await labelsCreationService.archivedLabels(params, typeAction)
    dispatch({
      type: labelsCreationConstants.GET_ARCHIVED_LABELS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: labelsCreationConstants.GET_ARCHIVED_LABELS_FAILURE,
      payload: error.toString(),
    })
  }
}

const generateLabelsV2 = params => async dispatch => {
  dispatch({ type: labelsCreationConstants.GENERATE_LABELS_REQUEST })

  try {
    const res = await labelsCreationService.generateLabelsV2(params)
    return res
  } catch (error) {
    dispatch({
      type: labelsCreationConstants.GENERATE_LABELS_FAILURE,
    })
    return error
  }
}

const printLabelsV2 = params => async dispatch => {
  dispatch({ type: labelsCreationConstants.PRINT_LABELS_REQUEST })

  try {
    await labelsCreationService.printLabelsV2(params)
    dispatch({ type: labelsCreationConstants.PRINT_LABELS_SUCCESS })
  } catch (error) {
    dispatch({
      type: labelsCreationConstants.PRINT_LABELS_FAILURE,
    })
  }
}

const completeLabelsV2 = params => async dispatch => {
  dispatch({ type: labelsCreationConstants.COMPLETE_LABELS_REQUEST })

  try {
    await labelsCreationService.completeLabelsV2(params)
    dispatch({ type: labelsCreationConstants.COMPLETE_LABELS_SUCCESS })
  } catch (error) {
    dispatch({
      type: labelsCreationConstants.COMPLETE_LABELS_FAILURE,
    })
  }
}

const cancelLabelsV2 = params => async dispatch => {
  dispatch({ type: labelsCreationConstants.CANCEL_LABELS_REQUEST })

  try {
    await labelsCreationService.cancelLabelsV2(params)
    dispatch({ type: labelsCreationConstants.CANCEL_LABELS_SUCCESS })
  } catch (error) {
    dispatch({
      type: labelsCreationConstants.CANCEL_LABELS_FAILURE,
    })
  }
}

const statusLabelsV2 = () => async dispatch => {
  dispatch({ type: labelsCreationConstants.STATUS_LABELS_V2_REQUEST })

  try {
    const status = await labelsCreationService.statusLabelsV2()
    dispatch({ type: labelsCreationConstants.STATUS_LABELS_V2_SUCCESS, payload: status.data })
  } catch (error) {
    dispatch({ type: labelsCreationConstants.STATUS_LABELS_V2_FAILURE })
  }
}

const getServiceOptions = params => async dispatch => {
  dispatch({ type: labelsCreationConstants.GET_SERVICE_OPTIONS_REQUEST })

  try {
    const res = await labelsCreationService.getServiceOptions(params)
    dispatch({
      type: labelsCreationConstants.GET_SERVICE_OPTIONS_SUCCESS,
      payload: res,
    })
  } catch (error) {
    dispatch({
      type: labelsCreationConstants.GET_SERVICE_OPTIONS_FAILURE,
    })
  }
}

const labelsCreationActions = {
  readyToShipping,
  archivedLabels,
  generateLabelsV2,
  printLabelsV2,
  completeLabelsV2,
  cancelLabelsV2,
  statusLabelsV2,
  getServiceOptions,
}

export default labelsCreationActions
