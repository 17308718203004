import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

const getEndOfString = path => {
  const result = path.split('/').filter(item => item !== '/' && item !== '')
  return result.length > 1 ? result.join(': ') : result[0]
}

const Content = ({ children }) => {
  const location = useLocation()

  return (
    <div>
      <Helmet title={getEndOfString(location.pathname)} />
      {children}
    </div>
  )
}

export default Content
