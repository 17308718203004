import React, { memo } from 'react'

import { Popconfirm, Popover, Tooltip } from 'antd'
import { DeleteOutlined, FullscreenOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons'

const CardContent = ({
  id,
  edges,
  cardSize,
  popoverOpen,
  containerSize,
  isLocked,
  isRepeatMode,
  hideActions,
  onDelete,
  togglePopoverHandler,
  changePositionPopover,
  togglePositionLock,
}) => {
  return (
    <div className="draggable-container__content">
      <div className="text-content">
        <h1 className="card-title">I am example of card image {id}</h1>

        <div className="card-property">
          <span className="card-property__title">Left edge:</span>
          <span className="card-property__value">{edges.left}</span>
        </div>

        <div className="card-property">
          <span className="card-property__title">Top edge:</span>
          <span className="card-property__value">{edges.top}</span>
        </div>

        <div className="card-property">
          <span className="card-property__title">Right edge:</span>
          <span className="card-property__value">{edges.right}</span>
        </div>

        <div className="card-property">
          <span className="card-property__title">Bottom edge:</span>
          <span className="card-property__value">{edges.bottom}</span>
        </div>

        <br />

        <div className="card-property">
          <span className="card-property__title">Card Size:</span>
          <span className="card-property__value">
            {cardSize.width} / {cardSize.height}in
          </span>
        </div>

        <div className="card-property">
          <span className="card-property__title">Container Size:</span>
          <span className="card-property__value">
            {containerSize.width} / {containerSize.height}in
          </span>
        </div>
      </div>

      {!hideActions ? (
        <div className="actions-content">
          <Popconfirm
            title="Sure to delete card?"
            onConfirm={onDelete}
            okText="Yes"
            cancelText="Cancel"
          >
            <DeleteOutlined
              className={`actions-content__button ${
                isLocked || isRepeatMode ? 'button-disabled' : ''
              }`}
            />
          </Popconfirm>

          <Tooltip title={!isLocked ? 'Change Position' : ''}>
            <Popover
              open={popoverOpen}
              onOpenChange={togglePopoverHandler}
              content={changePositionPopover}
              title="Card Position (mm)"
              trigger="click"
            >
              <FullscreenOutlined
                className={`actions-content__button ${isLocked ? 'button-disabled' : ''}`}
              />
            </Popover>
          </Tooltip>

          <Tooltip title={`${isLocked ? 'Unlock' : 'Lock'} Card Position`}>
            {isLocked ? (
              <LockOutlined className="actions-content__button" onClick={togglePositionLock} />
            ) : (
              <UnlockOutlined className="actions-content__button" onClick={togglePositionLock} />
            )}
          </Tooltip>
        </div>
      ) : null}
    </div>
  )
}

export default memo(CardContent)
