import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Form, Input, InputNumber, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import { giftCardActions } from '../../../../_actions'
import { giftCardsConstants } from '../../../../_constants'

const { formItemLayout, navMenu } = giftCardsConstants

const navMenuInit = [navMenu.list, navMenu.manage]

const CreateItem = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const [giftCardImage, setGiftCardImage] = useState(null)

  const normFile = e => {
    let file = e && e.fileList

    if (Array.isArray(e)) file = e

    const reader = new FileReader()
    reader.onload = () => setGiftCardImage(reader.result)
    reader.readAsDataURL(file[0].originFileObj)

    return file
  }

  const onFinish = async values => {
    const newItem = {
      name: values.name,
      file: { ...values.image[0], thumbUrl: giftCardImage },
      sort: values.sort,
    }
    await dispatch(giftCardActions.addItem(newItem))
  }

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      <Form {...formItemLayout} name="basic" form={form} onFinish={onFinish} scrollToFirstError>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input name!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Sort Order" initialValue={0} name="sort">
          <InputNumber min={0} />
        </Form.Item>

        <Form.Item
          label="Image"
          name="image"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: 'Please upload image!',
            },
          ]}
        >
          <Upload
            name="icon"
            beforeUpload={file => {
              console.log(file)
              return false
            }}
            action=""
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </NavContainer>
  )
}

export default CreateItem
