import {paginationConstants} from '../_constants'

const storedPaginationStr = localStorage.getItem('pagination')
const storedPagination = storedPaginationStr ? JSON.parse(storedPaginationStr) : {}
const { username } = JSON.parse(localStorage.getItem('user')) || {}
const initialState = storedPagination[username] ? { ...storedPagination[username] } : {}

function pagination(state = initialState, { type, value }) {
  switch (type) {
    case paginationConstants.PAGINATION_CHANGED:
      return { ...state, current: value }
    case paginationConstants.PAGE_SIZE_CHANGED:
      return { ...state, [value.view] : value.pageSize }
    default:
      return state
  }
}

export default pagination
