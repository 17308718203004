const clientsConstants = {
  GETALL_REQUEST: 'CLIENTS_GETALL_REQUEST',
  GETALL_SUCCESS: 'CLIENTS_SUCCESS',
  GETALL_FAILURE: 'CLIENTS_FAILURE',

  GETBYID_REQUEST: 'CLIENTS_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'CLIENTS_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'CLIENTS_GETBYID_FAILURE',

  GETCONTEXT_REQUEST: 'CLIENTS_GETCONTEXT_REQUEST',
  GETCONTEXT_SUCCESS: 'CLIENTS_GETCONTEXT_SUCCESS',
  GETCONTEXT_FAILURE: 'CLIENTS_GETCONTEXT_FAILURE',

  DELETE_REQUEST: 'CLIENTS_DELETE_REQUEST',
  DELETE_SUCCESS: 'CLIENTS_DELETE_SUCCESS',
  DELETE_FAILURE: 'CLIENTS_DELETE_FAILURE',

  UPDATE_REQUEST: 'CLIENTS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CLIENTS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CLIENTS_UPDATE_FAILURE',

  ORDERS_REQUEST: 'CLIENTS_UPDATE_REQUEST',
  ORDERS_SUCCESS: 'CLIENTS_UPDATE_SUCCESS',
  ORDERS_FAILURE: 'CLIENTS_UPDATE_FAILURE',

  GETLISTUSERS_REQUEST: 'CLIENTS_LIST_REQUEST',
  GETLISTUSERS_SUCCESS: 'CLIENTS_LIST_SUCCESS',
  GETLISTUSERS_FAILURE: 'CLIENTS_LIST_FAILURE',
  GEN_TOKEN_REQUEST: 'GEN_TOKEN_REQUEST',
  GEN_TOKEN_SUCCESS: 'GEN_TOKEN_SUCCESS',
  GEN_TOKEN_FAILURE: 'GEN_TOKEN_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    // list: { key: 'list', title: 'List Clients', url: '/clients/list' },
    manage: { key: 'manage', title: 'Manage Clients', url: '/clients' },
    orders: { key: 'orders', title: 'Orders', url: '/orders' },
    creditCards: { title: 'Credit Cards', key: 'credit-cards', url: '' },
    activity: { title: 'List Activity', key: 'activity', url: '/clients/activity' },
    listMails: { title: 'List Mails', key: 'listMails', url: '/' },
    inserts: { title: 'List Inserts', key: 'inserts', url: '/' },
    signatures: { title: 'List Signatures', key: 'signatures', url: '/' },
    cards: { title: 'List Cards', key: 'cards', url: '/' },
    prePayCredits: { title: 'List Pre-Pay Credits', key: 'prePayCredits', url: '/' },
    prePayCreditsType3: {
      title: 'List Pre-Pay Credits (Credit type 3)',
      key: 'prePayCreditsType3',
      url: '/',
    },
    resetPassword: { title: 'Reset Password', key: 'resetPassword', url: '/' },
    edit: { key: 'edit', title: 'Update Client', url: '' },
    delete: { key: 'delete', title: 'Delete Client', url: '' },
    view: { key: 'view', title: 'View Client', url: '' },
    clientNotes: { title: 'List Client Notes', key: 'clientNotes', url: '/' },
    creditHistory: { title: 'Credit History', key: 'creditHistory', url: '/' },
    apiKeys: { title: 'List Api Keys', key: 'apiKeys', url: '/' },
    usaFinancialTriggers: {
      title: 'USA Financial Triggers (enabled)',
      key: 'total_usa_financial_triggers',
      url: '/',
    },
    automations: { title: 'List Automations', key: 'automations', url: '/automations' },
  },

  clientStatuses: [
    { value: '-1', text: 'Not Activated' },
    { value: '0', text: 'Activated' },
    { value: '1', text: 'Banned' },
    { value: '2', text: 'Verizon' },
  ],

  listItemKeyToLabel: {
    id: 'ID',
    status: 'Status',
    fname: 'First Name',
    lname: 'Last Name',
    group_id: 'Group',
    platform: 'Platform',
    can_customize_card: 'Can Customize Cards',
  },

  viewItemKeyToLabel: {
    id: 'ID',
    status: 'Status',
    group_id: 'Client Group',
    login: 'Login',
    fname: 'First Name',
    lname: 'Last Name',
    user_phone: 'Phone',
    free_cards: 'Free cards',
    shared: 'Shared',
    anet_customer_id: 'Anet Customer',
    registered_date: 'Registered Date',
    last_visit_date: 'Last Visit Date',
    discount_code: 'Discount Сode',
    credit: 'Credit',
    credit_type2: 'Pre-Pay Credit (Except Gift Cards)',
    subscription: 'Subscription',
    platform: 'Platform',
    last_employee_id: 'Salesperson',
    can_customize_card: 'Can customize cards',
    invoiced: 'Invoiced',
    attach_invoice: 'Attach Invoice to email',
    tax_exempt: 'Tax Exempt',
    test_mode: 'Test Mode',
  },

  viewItemKeyToLabelUsaTriggers: {
    id: 'ID',
    user_id: 'User ID',
    card_id: 'Card ID',
    trigger_id: 'Trigger ID',
    redtail_tag: 'Redtail Tag',
    all_users: 'All User',
    message: 'Message',
    wishes: 'Wishes',
    font_id: 'Font ID',
    status: 'Status',
  },

  clientPlatform: [
    { value: 'Desktop/Web', text: 'Desktop/Web' },
    { value: 'Android/Web', text: 'Android/Web' },
    { value: 'iOS/Web', text: 'iOS/Web' },
    { value: 'Unrecognized', text: 'Unrecognized' },
  ],

  advancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'Status', key: 'status_filter', type: 'select' },
    { label: 'Login', key: 'login' },
    { label: 'First Name', key: 'fname' },
    { label: 'Last Name', key: 'lname' },
    { label: 'Registration date', key: 'registered_date*date' },
    { label: 'Last Visit Date', key: 'last_visit_date*date' },
    { label: 'Restore Hash', key: 'restore_hash' },
    { label: 'Restore Time', key: 'restore_time*date' },
    { label: 'Free Cards', key: 'free_cards' },
    { label: 'Shared', key: 'shared' },
    { label: 'Anet Customer', key: 'anet_customer_id' },
    { label: 'Platform', key: 'platform', type: 'select' },
  ],

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },
}
export default clientsConstants
