import React from 'react'
import { Typography } from 'antd'

const DetailsHeader = ({ id, name }) => {
  return (
    <div className="details-header">
      <Typography.Text className="details-header__id">#{id}</Typography.Text>
      <Typography.Text className="details-header__name">{name}</Typography.Text>
    </div>
  )
}

export default DetailsHeader
