import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { fontActions } from '../../../../_actions'
import NavContainer from '../../../../components/nav-container'
import ItemViewer from '../../../../components/item-viewer'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { fontConstants } from '../../../../_constants'
import { getFont } from '../../../../_selectors/fonts.selector.js'

const { navMenu, viewItemKeyToLabel, renderComponent } = fontConstants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.edit, navMenu.delete, navMenu.manage]

const ViewItem = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const { id } = useParams()

  const [navMenuParse, setNavMenuParse] = useState([])

  const font = useSelector(getFont)

  const view = getCurrentUrl(match.url)

  useEffect(() => {
    dispatch(fontActions.getItemById(id))
    return () => {
      dispatch({ type: fontConstants.SETBYID })
    }
  }, [id, dispatch])

  const onEditItem = () => {
    history.push({
      pathname: `${view}/edit/${id}`,
    })
  }

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(fontActions.deleteItem(font.font_id))
      },
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.edit.key:
          return {
            ...menu,
            url: onEditItem,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit, font])

  const viewItem = useMemo(() => {
    if (font) {
      return Object.keys(viewItemKeyToLabel).reduce((obj, sKey) => {
        let newItem
        if (sKey === 'image') {
          newItem = renderComponent.image(font.image, font.font_name)
        } else if (sKey === 'sort') {
          newItem = font.sort || '0'
        } else {
          newItem = font[sKey] || '---'
        }
        return {
          ...obj,
          [sKey]: newItem,
        }
      }, {})
    }
    return {}
  }, [font])

  return (
    <NavContainer menu={navMenuParse}>
      <ItemViewer item={viewItem} keyToLabelMap={viewItemKeyToLabel} />
    </NavContainer>
  )
}

export default ViewItem
