import { prePaidConfigsConstants } from '../_constants'
import { prePaidConfigsService } from '../_services'
import { history } from '../_helpers'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: prePaidConfigsConstants.GETALL_REQUEST })
  try {
    const result = await prePaidConfigsService.getAll(params, typeAction)
    dispatch({ type: prePaidConfigsConstants.GETALL_SUCCESS, payload: result.data })
  } catch (error) {
    dispatch({ type: prePaidConfigsConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const addItem = value => async dispatch => {
  dispatch({ type: prePaidConfigsConstants.ADD_REQUEST })

  try {
    const item = await prePaidConfigsService.addItem({ value })
    dispatch({
      type: prePaidConfigsConstants.ADD_SUCCESS,
      payload: { ...value, id: item.id },
    })
    history.push(`/pre-paid-configs/view/${item.id}`)
  } catch (error) {
    dispatch({ type: prePaidConfigsConstants.ADD_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: prePaidConfigsConstants.GETBYID_REQUEST })

  try {
    const item = await prePaidConfigsService.getItemById(id)
    dispatch({ type: prePaidConfigsConstants.GETBYID_SUCCESS, payload: item.data })
  } catch (error) {
    dispatch({ type: prePaidConfigsConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const deleteItem = id => async dispatch => {
  dispatch({ type: prePaidConfigsConstants.DELETE_REQUEST })
  try {
    await prePaidConfigsService.deleteItem(id)
    history.push(`/pre-paid-configs`)
    dispatch({ type: prePaidConfigsConstants.DELETE_SUCCESS, payload: id })
  } catch (error) {
    dispatch({ type: prePaidConfigsConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const updateItemById = (id, value) => async dispatch => {
  dispatch({ type: prePaidConfigsConstants.UPDATE_REQUEST })

  try {
    const updated = await prePaidConfigsService.updateItemById(id, value)
    dispatch({
      type: prePaidConfigsConstants.UPDATE_SUCCESS,
      payload: updated?.message?.toString(),
    })
    history.push(`/pre-paid-configs/view/${id}`)
  } catch (error) {
    dispatch({ type: prePaidConfigsConstants.UPDATE_FAILURE, payload: error.toString() })
  }
}

const prePaidConfigsActions = {
  getAll,
  getItemById,
  addItem,
  deleteItem,
  updateItemById,
}

export default prePaidConfigsActions
