import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, Input, InputNumber, Select, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import { categoriesActions, clientGroupActions } from '../../../../_actions'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import { categoriesConstants } from '../../../../_constants'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import AdvancedCheckboxList from 'components/advanced-checkbox-list'
import { getClientGroupsRows } from '../../../../_selectors/client-group.selector.js'

const { formItemLayout, navMenu, taxonomySelects } = categoriesConstants

const navMenuInit = [navMenu.list, navMenu.manage]

const CreateItem = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const clientGroups = useSelector(getClientGroupsRows)

  const categories = useMemo(() => clientGroups?.map(e => ({ value: e.id, label: e.title })), [
    clientGroups,
  ])

  useEffect(() => {
    dispatch(clientGroupActions.getAll())
  }, [dispatch])

  const normFile = e => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const onFinish = values => {
    const newObj = { file: values.icon?.[0] || null }

    Object.keys(values).forEach(item => {
      if (item !== 'icon') newObj[item] = values[item]
    })

    dispatch(categoriesActions.addItem(convertFormValuesDeep(newObj)))
  }

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      {useGlobalLoader(
        <Form {...formItemLayout} name="basic" form={form} onFinish={onFinish} scrollToFirstError>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input title!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Slug"
            name="slug"
            rules={[
              {
                required: true,
                message: 'Please input title!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Meta title" name="meta_title">
            <Input />
          </Form.Item>

          <Form.Item label="Meta description" name="meta_description">
            <Input />
          </Form.Item>

          <Form.Item label="Icon" name="icon" valuePropName="fileList" getValueFromEvent={normFile}>
            <Upload
              name="icon"
              beforeUpload={file => {
                console.log(file)
                return false
              }}
              action=""
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item initialValue={taxonomySelects[0].value} name="taxonomy" label="Taxonomy">
            <Select>
              {taxonomySelects.map(item => (
                <Select.Option key={item.value} value={item.value}>
                  {item.text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="sort" label="Sort" initialValue={0}>
            <InputNumber />
          </Form.Item>

          <AdvancedCheckboxList
            formItemProps={{
              label: 'Customer Groups',
              name: 'user_groups',
              valuePropName: 'value',
            }}
            buttonText="Add Customer Group"
            options={categories}
            form={form}
          />

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>,
      )}
    </NavContainer>
  )
}

export default CreateItem
