const employeesConstants = {
  GETALL_REQUEST: 'EMPLOYEES_CONSTANTS_GETALL_REQUEST',
  GETALL_SUCCESS: 'EMPLOYEES_CONSTANTS_GETALL_SUCCESS',
  GETALL_FAILURE: 'EMPLOYEES_CONSTANTS_GETALL_FAILURE',

  GETBYID_REQUEST: 'EMPLOYEES_CONSTANTS_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'EMPLOYEES_CONSTANTS_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'EMPLOYEES_CONSTANTS_GETBYID_FAILURE',

  ADD_REQUEST: 'EMPLOYEES_CONSTANTS_ADD_REQUEST',
  ADD_SUCCESS: 'EMPLOYEES_CONSTANTS_ADD_SUCCESS',
  ADD_FAILURE: 'EMPLOYEES_CONSTANTS_ADD_FAILURE',

  DELETE_REQUEST: 'EMPLOYEES_CONSTANTS_DELETE_REQUEST',
  DELETE_SUCCESS: 'EMPLOYEES_CONSTANTS_DELETE_SUCCESS',
  DELETE_FAILURE: 'EMPLOYEES_CONSTANTS_DELETE_FAILURE',

  UPDATE_REQUEST: 'EMPLOYEES_CONSTANTS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'EMPLOYEES_CONSTANTS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'EMPLOYEES_CONSTANTS_UPDATE_FAILURE',

  GETTOKEN_REQUEST: 'EMPLOYEES_CONSTANTS_GETTOKEN_REQUEST',
  GETTOKEN_SUCCESS: 'EMPLOYEES_CONSTANTS_GETTOKEN_SUCCESS',
  GETTOKEN_FAILURE: 'EMPLOYEES_CONSTANTS_GETTOKEN_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  checkbox: {
    style: { lineHeight: '32px' },
    span: 10,
  },

  navMenu: {
    create: { key: 'create', title: 'Create Employee', url: '/employees/create' },
    manage: { key: 'manage', title: 'Manage Employees', url: '/employees' },
    manageProfileField: { key: 'manageProfileField', title: 'Manage Profile Field', url: '/' },
    listUser: { key: 'listUser', title: 'List Employees', url: '/employees/list' },
    view: { key: 'view', title: 'View Employee', url: '' },
    activity: { key: 'activity', title: 'List Activity', url: '' },
    password: { key: 'password', title: 'Reset Password', url: '' },
    edit: { key: 'edit', title: 'Update Employee', url: '' },
    delete: { key: 'delete', title: 'Delete Employee', url: '' },
  },

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },

  superUserStatus: [
    { value: '0', text: 'No' },
    { value: '1', text: 'Yes' },
  ],

  userStatuses: [
    { value: '-1', text: 'Banned' },
    { value: '0', text: 'Not Active' },
    { value: '1', text: 'Active' },
  ],

  listItemKeyToLabel: {
    username: 'Username',
    create_at: 'Registration date',
    lastvisit_at: 'Last visit',
  },

  viewItemKeyToLabel: {
    id: 'ID',
    username: 'Username',
    fName: 'First Name',
    lName: 'Last Name',
    password: 'Password',
    email: 'E-mail',
    activkey: 'Activation key',
    create_at: 'Registration date',
    lastvisit_at: 'Last visit',
    superuser: 'Superuser',
    status: 'Status',
  },

  permissionsField: [
    { value: 'can_view_users', label: 'Can View Users' },
    { value: 'can_edit_users', label: 'Can Edit Users' },
    { value: 'can_edit_cards', label: 'Can Edit Cards' },
    { value: 'can_edit_templates', label: 'Can Edit Templates' },
    { value: 'can_edit_posts', label: 'Can Edit Posts' },
    { value: 'can_edit_feedbacks', label: 'Can Edit Feedbacks' },
    { value: 'can_edit_orders', label: 'Can Edit Orders ' },
    { value: 'can_edit_gift_cards', label: 'Can Edit Gift Cards' },
    { value: 'can_edit_fonts', label: 'Can Edit Fonts' },
    { value: 'can_edit_coupons', label: 'Can Edit Coupons' },
    { value: 'can_edit_discount_codes', label: 'Can Edit Discount Codes' },
    { value: 'can_edit_discount_rules', label: 'Can Edit Discount Rules' },
    { value: 'can_edit_holidays', label: 'Can Edit Holidays' },
    { value: 'can_create_ups_shipping_labels', label: 'Can Create Ups Shipping Labels' },
    { value: 'can_edit_notifications', label: 'Can Edit Notifications' },
    { value: 'can_edit_subscriptions', label: 'Can Edit Subscriptions' },
    { value: 'can_edit_shipping_methods', label: 'Can Edit Shipping Rates' },
    { value: 'can_edit_imposition_layouts', label: 'Can Edit Imposition Layouts' },
    { value: 'can_edit_card_dimensions', label: 'Can Edit Card Dimensions' },
  ],

  advancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'username', key: 'username' },
    { label: 'E-mail', key: 'email' },
    { label: 'Activation Key', key: 'activkey' },
    { label: 'Registration date', key: 'create_at*date' },
    { label: 'Last visit', key: 'lastvisit_at*date' },
    { label: 'Superuser', key: 'superuser_filter', type: 'select' },
    { label: 'Status', key: 'status_filter', type: 'select' },
  ],

  actionsFindLink: {
    post: false,
    auth: false,
    login: false,
    logout: false,
    cardOrder: false,
    card: 'cards',
    order: 'orders',
    client: 'clients',
    admin: 'employees',
    employee: 'employees',
    gcard: 'gift-cards',
    giftCard: 'gift-cards',
    template: 'templates',
    cardImage: 'card-images',
    card_images: 'card-images',
    templateCategory: 'template-categories',
    template_category: 'template-categories',
  },
}
export default employeesConstants
