import { ordersConstants } from '../_constants'

const initialState = {
  items: [],
  item: {
    fonts: [],
    order: {},
  },
}

function orders(state = initialState, { type, payload }) {
  switch (type) {
    case ordersConstants.GETALL_SUCCESS:
      return { ...state, items: payload }
    case ordersConstants.GETBYID_SUCCESS:
      return { ...state, item: payload }
    case ordersConstants.DELETE_SUCCESS:
      return { ...state }
    case ordersConstants.UPDATE_SUCCESS:
      return { ...state, item: { ...state.item, order: { ...state.item?.order, ...payload } } }
    case ordersConstants.SETBYID:
      return {
        ...state,
        item: {
          fonts: [],
          order: {},
        },
      }
    case ordersConstants.SETALL:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default orders
