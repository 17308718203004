import { holidayConstants } from '../_constants'
import { holidayService } from '../_services'
import { history } from '../_helpers'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: holidayConstants.GETALL_REQUEST })
  try {
    const holidays = await holidayService.getAll(params, typeAction)
    dispatch({ type: holidayConstants.GETALL_SUCCESS, payload: holidays.data })
  } catch (error) {
    dispatch({ type: holidayConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const addItem = value => async dispatch => {
  dispatch({ type: holidayConstants.ADD_REQUEST })

  try {
    const holiday = await holidayService.addItem({ value })
    dispatch({ type: holidayConstants.ADD_SUCCESS, payload: { ...value, id: holiday.id } })
    history.push(`/holidays/view/${holiday.id}`)
  } catch (error) {
    dispatch({ type: holidayConstants.ADD_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: holidayConstants.GETBYID_REQUEST })

  try {
    const holiday = await holidayService.getItemById(id)
    dispatch({ type: holidayConstants.GETBYID_SUCCESS, payload: holiday.data })
  } catch (error) {
    dispatch({ type: holidayConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const deleteItem = id => async dispatch => {
  dispatch({ type: holidayConstants.DELETE_REQUEST })
  try {
    await holidayService.deleteItem(id)
    history.push(`/holidays`)
    dispatch({ type: holidayConstants.DELETE_SUCCESS, payload: id })
  } catch (error) {
    dispatch({ type: holidayConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const updateItemById = (id, value) => async dispatch => {
  dispatch({ type: holidayConstants.UPDATE_REQUEST })

  try {
    const updated = await holidayService.updateItemById(id, value)
    dispatch({ type: holidayConstants.UPDATE_SUCCESS, payload: updated?.message?.toString() })
    history.push(`/holidays/view/${id}`)
  } catch (error) {
    dispatch({ type: holidayConstants.UPDATE_FAILURE, payload: error.toString() })
  }
}

const holidayActions = {
  getAll,
  getItemById,
  updateItemById,
  deleteItem,
  addItem,
}

export default holidayActions
