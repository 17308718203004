import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'

import NavContainer from '../../../../components/nav-container'
import ItemViewer from '../../../../components/item-viewer'
import { multiStepActions } from '../../../../_actions'
import { multiStepConstants } from '../../../../_constants'

const { viewItemKeyToLabel, navMenu } = multiStepConstants

const navMenuInit = [navMenu.manage, navMenu.manageSteps]

const ViewItem = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const history = useHistory()

  const { item: multiStepCampaign } = useSelector(state => state.multiStepCampaigns)

  const [navMenuParse, setNavMenuParse] = useState([])

  const navigateToSteps = () => {
    history.push({
      pathname: `/campaign-steps/${multiStepCampaign.id}`,
    })
  }

  useEffect(() => {
    dispatch(multiStepActions.getItemById(id))
    return () => {
      dispatch({ type: multiStepConstants.SETBYID })
    }
  }, [])

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.manageSteps.key:
          return {
            ...menu,
            url: navigateToSteps,
            count: multiStepCampaign.total_steps,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit, multiStepCampaign])

  const viewItem = useMemo(() => {
    if (multiStepCampaign) {
      return Object.keys(viewItemKeyToLabel).reduce((obj, sKey) => {
        let itemValue = multiStepCampaign[sKey]

        if (sKey === 'total_steps') {
          itemValue = itemValue ? (
            <Link to={`/campaign-steps/${multiStepCampaign.id}`}>{itemValue}</Link>
          ) : (
            itemValue
          )
        } else if (sKey === 'user') {
          itemValue = itemValue ? (
            <Link to={`/clients/edit/${multiStepCampaign.user.id}`}>
              [ID: {multiStepCampaign.user.id}] {multiStepCampaign.user.name}
            </Link>
          ) : (
            itemValue
          )
        }
        return {
          ...obj,
          [sKey]: itemValue,
        }
      }, {})
    }
    return {}
  }, [multiStepCampaign])

  return (
    <NavContainer menu={navMenuParse}>
      <ItemViewer item={viewItem} keyToLabelMap={viewItemKeyToLabel} />
    </NavContainer>
  )
}

export default ViewItem
