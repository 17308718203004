import { cronJobConstants } from '../_constants'
import { cronJobsService } from '../_services'

const getAll = typeAction => async dispatch => {
  dispatch({ type: cronJobConstants.GETALL_REQUEST })
  try {
    const jobs = await cronJobsService.getAll(typeAction)
    dispatch({ type: cronJobConstants.GETALL_SUCCESS, payload: jobs.data })
  } catch (error) {
    dispatch({ type: cronJobConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const getByName = (name, status, typeAction) => async dispatch => {
  dispatch({ type: cronJobConstants.GET_BY_NAME_REQUEST })
  try {
    const job = await cronJobsService.getByName(name, status, typeAction)
    dispatch({ type: cronJobConstants.GET_BY_NAME_SUCCESS, payload: job })
  } catch (error) {
    dispatch({ type: cronJobConstants.GET_BY_NAME_FAILURE, payload: error.toString() })
  }
}

const clearAll = (name, status, typeAction) => async dispatch => {
  dispatch({ type: cronJobConstants.CLEAR_ALL_REQUEST })
  try {
    const job = await cronJobsService.cleanAll(name, status, typeAction)
    dispatch({ type: cronJobConstants.CLEAR_ALL_SUCCESS, payload: job })
  } catch (error) {
    dispatch({ type: cronJobConstants.CLEAR_ALL_FAILURE, payload: error.toString() })
  }
}

const removeByName = (id, name, typeAction) => async dispatch => {
  dispatch({ type: cronJobConstants.REMOVE_BY_NAME_REQUEST })
  try {
    const jobs = await cronJobsService.remove(id, name, typeAction)
    dispatch({ type: cronJobConstants.REMOVE_BY_NAME_SUCCESS, payload: jobs })
  } catch (error) {
    dispatch({ type: cronJobConstants.CLEAR_ALL_FAILURE, payload: error.toString() })
  }
}

const retryFailed = (name, id) => async dispatch => {
  dispatch({ type: cronJobConstants.RETRY_FAILED_REQUEST })
  try {
    const failedList = await cronJobsService.retry(name, id)
    dispatch({ type: cronJobConstants.RETRY_FAILED_SUCCESS, payload: failedList })
  } catch (error) {
    dispatch({ type: cronJobConstants.RETRY_FAILED_FAILURE })
  }
}

const cronJobsActions = {
  getAll,
  getByName,
  clearAll,
  removeByName,
  retryFailed,
}

export default cronJobsActions
