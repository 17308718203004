import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Button, Checkbox, Form, Input, InputNumber, Select } from 'antd'
import NavContainer from '../../../../components/nav-container'
import { ShippingMethodsActions } from '../../../../_actions'
import { convertDataForSelect } from '../../../../_helpers'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import { ShippingMethodsConstants } from '../../../../_constants'

const { ShippingMethodsStatuses, formItemLayout, navMenu } = ShippingMethodsConstants

const navMenuInit = [navMenu.list, navMenu.manage]

const CreateItem = () => {
  const history = useHistory()
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const onFinish = async values => {
    const convertedShippingMethods = convertFormValuesDeep(values)
    // convertedShippingMethods.sort_order = 1

    await dispatch(ShippingMethodsActions.addItem(convertedShippingMethods))
    await dispatch({ type: ShippingMethodsConstants.SETALL })
    history.push('/shipping-methods')
  }

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      <Form {...formItemLayout} name="basic" form={form} onFinish={onFinish} scrollToFirstError>
        <Form.Item
          label="Methods Name"
          name="method_name"
          rules={[
            {
              required: true,
              message: 'Please input methods name!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Methods Label"
          name="method_label"
          rules={[
            {
              required: true,
              message: 'Please input methods label!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Add Postage Fee"
          name="add_postage_fee"
          initialValue={0}
          rules={[
            {
              required: true,
              message: 'Please input add Postage Fee!',
            },
          ]}
        >
          <Select options={convertDataForSelect(ShippingMethodsStatuses)} />
        </Form.Item>

        <Form.Item
          label="Show Options"
          name="show_options"
          initialValue={0}
          rules={[
            {
              required: true,
              message: 'Please input add Show Options',
            },
          ]}
        >
          <Select options={convertDataForSelect(ShippingMethodsStatuses)} />
        </Form.Item>

        <Form.Item
          label="Status"
          name="status"
          initialValue={0}
          rules={[
            {
              required: true,
              message: 'Please input add status',
            },
          ]}
        >
          <Select options={convertDataForSelect(ShippingMethodsStatuses)} />
        </Form.Item>

        <Form.Item
          label="Check Recipient Address"
          name="check_recipient_address"
          initialValue={0}
          rules={[
            {
              required: true,
              message: 'Please input add recipient address',
            },
          ]}
        >
          <Select options={convertDataForSelect(ShippingMethodsStatuses)} />
        </Form.Item>

        <Form.Item
          label="Check Return Address"
          name="check_return_address"
          initialValue={0}
          rules={[
            {
              required: true,
              message: 'Please input add return address',
            },
          ]}
        >
          <Select options={convertDataForSelect(ShippingMethodsStatuses)} />
        </Form.Item>

        <Form.Item label="Show in Outbound" name="show_in_outbound" valuePropName="checked">
          <Checkbox />
        </Form.Item>

        <Form.Item
          label="Discount"
          name="discount"
          initialValue={0}
          rules={[
            {
              required: true,
              message: 'Please input discount!',
            },
          ]}
        >
          <InputNumber step={0.1} min={0.0} />
        </Form.Item>

        <Form.Item label="Sort Order" name="sort_order" initialValue={0}>
          <InputNumber step={1} min={0} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </NavContainer>
  )
}

export default CreateItem
