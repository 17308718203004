import { campaignStepsConstants } from '../_constants'
import { campaignStepsService } from '../_services'

const getAll = (params, typeAction, id) => async dispatch => {
  dispatch({ type: campaignStepsConstants.GETALL_REQUEST })
  try {
    const campaignSteps = await campaignStepsService.getAll(params, typeAction, id)
    dispatch({ type: campaignStepsConstants.GETALL_SUCCESS, payload: campaignSteps.data })
  } catch (error) {
    dispatch({ type: campaignStepsConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: campaignStepsConstants.GETBYID_REQUEST })
  try {
    const campaignStep = await campaignStepsService.getItemById(id)
    dispatch({ type: campaignStepsConstants.GETBYID_SUCCESS, payload: campaignStep.data })
  } catch (error) {
    dispatch({ type: campaignStepsConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const campaignStepsActions = {
  getAll,
  getItemById,
}

export default campaignStepsActions
