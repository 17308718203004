import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Checkbox, Form, Input, Select } from 'antd'
import NavContainer from '../../../../components/nav-container'
import { contextsActions, employeesActions } from '../../../../_actions'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import { contextsConstants } from '../../../../_constants'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import { getEmployeeRows } from '../../../../_selectors/employees.selector.js'
import useClientsToOptionsList from 'hooks/clients/useClientsToOptionsList'

const { formItemLayout, navMenu } = contextsConstants

const navMenuInit = [navMenu.manage]

const CreateItem = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const employees = useSelector(getEmployeeRows)

  const clientsListOptions = useClientsToOptionsList(employeesActions.getAll, {
    order: { key: 'username', order: 'ASC' },
    where: { status: 1 },
  })

  const onFinish = async values => {
    const { employee_ids } = values

    const employee_idsList = []

    employees.forEach(employee => {
      if (employee_ids.includes(employee.email)) {
        employee_idsList.push(employee.id)
      }
    })

    values.employee_ids = employee_idsList

    const convertedContext = convertFormValuesDeep(values)
    await dispatch(contextsActions.addItem(convertedContext))
  }

  const employeesList = useMemo(() => {
    if (employees) {
      return employees.map(({ id, email }) => {
        return {
          key: id,
          value: email,
          label: email,
        }
      })
    }
    return []
  }, [employees])

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      {useGlobalLoader(
        <Form {...formItemLayout} name="basic" form={form} onFinish={onFinish} scrollToFirstError>
          <Form.Item label="Description" name="description">
            <Input.TextArea rows={5} />
          </Form.Item>

          <Form.Item label="Employees" name="employee_ids">
            <Select
              mode="multiple"
              size="large"
              placeholder="Please select employees or search by email"
              options={employeesList}
            />
          </Form.Item>

          <Form.Item label="Clients" name="user_ids">
            <Select
              mode="multiple"
              size="large"
              placeholder="Please select clients or search by login"
              filterOption={(input, option) => option.label.includes(input.toString())}
              options={clientsListOptions}
            />
          </Form.Item>
          <Form.Item
            label="Group by Date on Batch"
            name="group_by_day_on_batch"
            initialValue
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>,
      )}
    </NavContainer>
  )
}

export default CreateItem
