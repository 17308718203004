const shareOptionConstants = {
  GETALL_REQUEST: 'SHARE_OPTION_GETALL_REQUEST',
  GETALL_SUCCESS: 'SHARE_OPTION_SUCCESS',
  GETALL_FAILURE: 'SHARE_OPTION_FAILURE',

  ADD_REQUEST: 'SHARE_OPTION_ADD_REQUEST',
  ADD_SUCCESS: 'SHARE_OPTION_ADD_SUCCESS',
  ADD_FAILURE: 'SHARE_OPTION_ADD_FAILURE',

  GETBYID_REQUEST: 'SHARE_OPTION_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'SHARE_OPTION_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'SHARE_OPTION_GETBYID_FAILURE',

  DELETE_REQUEST: 'SHARE_OPTION_DELETE_REQUEST',
  DELETE_SUCCESS: 'SHARE_OPTION_DELETE_SUCCESS',
  DELETE_FAILURE: 'SHARE_OPTION_DELETE_FAILURE',

  UPDATE_REQUEST: 'SHARE_OPTION_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'SHARE_OPTION_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'SHARE_OPTION_UPDATE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    list: { key: 'list', title: 'List Share Option', url: '/share-options/list' },
    manage: { key: 'manage', title: 'Manage Share Option', url: '/share-options' },
    create: { key: 'create', title: 'Create Share Option', url: '/share-options/create' },
    edit: { key: 'edit', title: 'Update Share Option', url: '' },
    delete: { key: 'delete', title: 'Delete Share Option', url: '' },
    view: { key: 'view', title: 'View Share Option', url: '' },
  },

  sharingMethods: [
    { value: 'default', text: 'default' },
    { value: 'facebook', text: 'facebook' },
    { value: 'twitter', text: 'twitter' },
    { value: 'mail', text: 'mail' },
    { value: 'message', text: 'message' },
  ],

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },

  listItemKeyToLabel: {
    id: 'ID',
    name: 'Sharing Method',
    text: 'Text',
    link: 'Link',
    link_android: 'Link (Android)',
  },
  viewItemKeyToLabel: {
    id: 'ID',
    name: 'Sharing Method',
    text: 'Text',
    link: 'Link',
    link_android: 'Link (Android)',
  },
  advancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'Sharing Method', key: 'name' },
    { label: 'Text', key: 'text' },
    { label: 'Link', key: 'link' },
    { label: 'Link (Android)', key: 'link_android' }
  ],
}
export default shareOptionConstants
