import { history } from '../_helpers'
import presetCardImagesConstants from '../_constants/preset-card-images.constants'
import presetCardImagesService from '../_services/preset-card-images.service'

const getAll = (params, typeAction, id) => async dispatch => {
  const paramsWithId = {
    ...params,
    where: {
      ...params.where,
      card_id: id,
    },
  }

  const requestParams = id ? paramsWithId : params

  dispatch({ type: presetCardImagesConstants.GETALL_REQUEST })
  try {
    const cardImages = await presetCardImagesService.getAll(requestParams, typeAction)
    dispatch({ type: presetCardImagesConstants.GETALL_SUCCESS, payload: cardImages.data })
  } catch (error) {
    dispatch({ type: presetCardImagesConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const addItem = value => async dispatch => {
  dispatch({ type: presetCardImagesConstants.ADD_REQUEST })

  try {
    const cardImage = await presetCardImagesService.addItem(value)
    dispatch({
      type: presetCardImagesConstants.ADD_SUCCESS,
      payload: { ...value, id: cardImage.id },
    })
    history.push(`/preset-card-images/view/${cardImage.id}`)
  } catch (error) {
    dispatch({ type: presetCardImagesConstants.ADD_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: presetCardImagesConstants.GETBYID_REQUEST })

  try {
    const cardImage = await presetCardImagesService.getItemById(id)
    dispatch({ type: presetCardImagesConstants.GETBYID_SUCCESS, payload: cardImage.data })
  } catch (error) {
    dispatch({ type: presetCardImagesConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const deleteItem = id => async dispatch => {
  dispatch({ type: presetCardImagesConstants.DELETE_REQUEST })
  try {
    await presetCardImagesService.deleteItem(id)
    history.push(`/preset-card-images`)
    dispatch({ type: presetCardImagesConstants.DELETE_SUCCESS, payload: id })
  } catch (error) {
    dispatch({ type: presetCardImagesConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const updateItemById = (id, value) => async dispatch => {
  dispatch({ type: presetCardImagesConstants.UPDATE_REQUEST })

  try {
    const updated = await presetCardImagesService.updateItemById(id, value)
    dispatch({
      type: presetCardImagesConstants.UPDATE_SUCCESS,
      payload: updated?.message?.toString(),
    })
    history.push(`/preset-card-images/view/${id}`)
  } catch (error) {
    dispatch({ type: presetCardImagesConstants.UPDATE_FAILURE, payload: error.toString() })
  }
}

const presetCardImagesActions = {
  getAll,
  getItemById,
  updateItemById,
  deleteItem,
  addItem,
}

export default presetCardImagesActions
