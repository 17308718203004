import { prepaidCreditsType3Constants } from '../_constants'
import { prepaidCreditsType3Service } from '../_services'
import { history } from '../_helpers'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: prepaidCreditsType3Constants.GETALL_REQUEST })
  try {
    const prePayCredits = await prepaidCreditsType3Service.getAll(params, typeAction)
    dispatch({ type: prepaidCreditsType3Constants.GETALL_SUCCESS, payload: prePayCredits.data })
  } catch (error) {
    dispatch({ type: prepaidCreditsType3Constants.GETALL_FAILURE, payload: error.toString() })
  }
}

const addItem = value => async dispatch => {
  dispatch({ type: prepaidCreditsType3Constants.ADD_REQUEST })

  try {
    const prePayCredit = await prepaidCreditsType3Service.addItem({ value })
    dispatch({
      type: prepaidCreditsType3Constants.ADD_SUCCESS,
      payload: { ...value, id: prePayCredit.id },
    })
    history.push(`/credit_type3/view/${prePayCredit.id}`)
  } catch (error) {
    dispatch({ type: prepaidCreditsType3Constants.ADD_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: prepaidCreditsType3Constants.GETBYID_REQUEST })

  try {
    const prePayCredit = await prepaidCreditsType3Service.getItemById(id)
    dispatch({ type: prepaidCreditsType3Constants.GETBYID_SUCCESS, payload: prePayCredit.data })
  } catch (error) {
    dispatch({ type: prepaidCreditsType3Constants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const updateItemById = (id, value) => async dispatch => {
  dispatch({ type: prepaidCreditsType3Constants.UPDATE_REQUEST })

  try {
    const updated = await prepaidCreditsType3Service.updateItemById(id, value)
    dispatch({
      type: prepaidCreditsType3Constants.UPDATE_SUCCESS,
      payload: updated?.message?.toString(),
    })
    history.push(`/credit_type3/view/${id}`)
  } catch (error) {
    dispatch({ type: prepaidCreditsType3Constants.UPDATE_FAILURE, payload: error.toString() })
  }
}

const prePayCreditsType3Actions = {
  getAll,
  getItemById,
  updateItemById,
  addItem,
}

export default prePayCreditsType3Actions
