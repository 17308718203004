import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import v4 from 'uuid/v4'
import { Tooltip } from 'antd'

import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import { automationsConstants } from '../../../../_constants'
import automationActions from '_actions/automations.actions'
import {
  getPage,
  getAutomationRows,
  getTotalItems,
} from '../../../../_selectors/automations.selector.js'

const { automationType, automationStatus, automationDeliveryType } = automationsConstants

const typeRender = text => {
  return `${automationType.find(st => st.value === text)?.text}`
}

const typeDeliveryRender = text => {
  return `${automationDeliveryType.find(st => st.value === text)?.text}`
}
const statusRender = text => {
  return `${automationStatus.find(st => st.value === text)?.text}`
}

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: typeRender,
    sorter: true,
    filters: automationType,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user=>login',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    width: '10%',
  },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    ellipsis: {
      showTitle: false,
    },
    maxWidth: '50px',
    render: message => (
      <Tooltip placement="topLeft" title={message}>
        <div
          style={{
            maxWidth: '120px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {message}
        </div>
      </Tooltip>
    ),
  },
  {
    title: 'Wishes',
    dataIndex: 'wishes',
    key: 'wishes',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render(children) {
      return {
        props: {
          style: {
            maxWidth: '200px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
          },
        },
        children,
      }
    },
  },
  {
    title: 'Start Date',
    dataIndex: 'start_date',
    key: 'start_date*date',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    width: '5%',
  },
  {
    title: 'End Date',
    dataIndex: 'end_date',
    key: 'end_date*date',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    width: '5%',
  },
  {
    title: 'Delivery Confirmation',
    dataIndex: 'delivery_confirmation',
    key: 'delivery_confirmation',
    render: typeDeliveryRender,
    filters: automationDeliveryType,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: statusRender,
    width: '10%',
    hideFilter: true,
  },
]

const ManageItems = () => {
  const rows = useSelector(getAutomationRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        user: <Link to={`/clients/edit/${item.user.id}`}> {item.user.name} </Link>,
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  return (
    <NavContainer menu={[]}>
      <TableFilter
        actionsColum={['view']}
        columns={initColumns}
        data={dataSource}
        isEditOnId={false}
        getDataAction={automationActions.getAll}
      />
    </NavContainer>
  )
}

export default ManageItems
