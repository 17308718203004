import axios from 'axios'
import { isEmpty, isEqual } from 'lodash'
import { userActions } from '_actions'
import alertActions from '../_actions/alert.actions'
import { alertConstants, spinnerConstants } from '../_constants'
import { history, store } from '../_helpers'

const { dispatch, getState } = store
const apiUrl = process.env.REACT_APP_API_URL

axios.defaults.withCredentials = true
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.put['Content-Type'] = 'application/json'

// if you want to connect localhost to node-v14, please use x-api-key
// axios.defaults.headers.common = {
//   'X-API-Key': '08a52b64-ea52-470b-9756-69dd40bafd6e',
// }

let numberOfAjaxCAllPending = 0

axios.interceptors.request.use(req => {
  const params = req?.params?.stopSpinner
  const stopSpinner =
    spinnerConstants.dontStartSpinner.includes(params) || req?.data?.stopSpinner === 'changeVisible'

  if (!stopSpinner) {
    numberOfAjaxCAllPending += 1
    dispatch({ type: spinnerConstants.START_GLOBAL_LOADER })
    dispatch({ type: spinnerConstants.START })
  }
  return req
})

axios.interceptors.response.use(
  result => {
    const state = getState()
    const userRules = result?.headers?.['user-rules']

    if (userRules) {
      const newPermission = JSON.parse(userRules)
      const prevPermission = state.users.permis

      const isEqualPermission = isEqual(prevPermission, newPermission)
      // if changed permission, we set to reducer new permission
      if (!isEqualPermission) {
        dispatch(userActions.setPermission(newPermission))
        // initPermission === null, first render will with null and receive message
        if (prevPermission) dispatch(alertActions.success('Your permissions was changed!'))
      }
    }

    // when delete item or register new user,
    // set to typeResponse and dont clear alert after redirect to manage page || login page
    if (result?.config?.method === 'delete' || result?.data?.register) {
      dispatch({ type: alertConstants.TYPE_RESPONSE, payload: 'skipClear' })
    } else {
      const { typeResponse } = state.alerts
      if (typeResponse) {
        dispatch({ type: alertConstants.TYPE_RESPONSE, payload: null })
      }
    }

    if (result?.data?.staticMessages && !result?.config?.url.includes('/labels/v2/generate')) {
      dispatch(alertActions.status(result.data.staticMessages))
    }
    if (result?.data?.modalMessage) {
      dispatch(alertActions.modal(result.data.modalMessage))
    }
    if (result?.data?.message && result?.data?.type !== 'password') {
      dispatch(alertActions.success(result.data.message.toString()))
    }
    if (numberOfAjaxCAllPending > 0) {
      numberOfAjaxCAllPending -= 1
    }

    if (numberOfAjaxCAllPending === 0) dispatch({ type: spinnerConstants.STOP_GLOBAL_LOADER })
    dispatch({ type: spinnerConstants.STOP })

    return result
  },
  async err => {
    const isExpiredSession = err.response?.data?.httpCode === 440
    const isNotExist = err.response?.data?.httpCode === 404
    const isQaManifestError = err.response?.config.url.includes('qa/manifests')

    if (isNotExist && !isQaManifestError) {
      history.push('/error')
    }

    if (
      err.response?.data?.staticMessages &&
      err.response?.config?.url.includes('/labels/v2/generate')
    ) {
      return err.response?.data
    }

    if (isExpiredSession) {
      // const path = sessionStorage.getItem('path')
      // if (!path) {
      //   sessionStorage.setItem('path', history.location.pathname)
      // }

      const state = getState()
      const { user } = state.authentication

      if (isEmpty(user)) {
        await dispatch(userActions.logout())
      } else {
        history.push('/login')
      }
      numberOfAjaxCAllPending = 0
      dispatch({ type: spinnerConstants.STOP_GLOBAL_LOADER })
      localStorage.removeItem('user')
    }
    if (err.response?.request?.responseType === 'blob') {
      err.response.data = JSON.parse(await err.response.data.text())
    }

    if (numberOfAjaxCAllPending > 0) {
      numberOfAjaxCAllPending -= 1
    }
    if (numberOfAjaxCAllPending === 0) dispatch({ type: spinnerConstants.STOP_GLOBAL_LOADER })

    dispatch({ type: spinnerConstants.STOP })
    dispatch(alertActions.error(err?.response?.data?.message?.toString() || err.message.toString()))

    throw err
  },
)

export default apiUrl
