import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { Button, Checkbox, Divider, Form, Input, Modal, Upload } from 'antd'
import { ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import getCurrentUrl from '../../../../_helpers/url-utils'

import { clientGroupActions, queryParamsActions } from '../../../../_actions'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import { convertValuesToFormData } from '../../../../_helpers/hw-object-utils'
import { appConstants, clientGroupConstants } from '../../../../_constants'
import { parseFileForSetFields } from '_helpers'
import AdvancedCheckboxList from 'components/advanced-checkbox-list'
import sortByIdAndPrice from '_helpers/sort-by-id-and-price'

const {
  navMenu,
  layout,
  tailLayout,
  LIMIT_CATEGORIES_PROP,
  LIMIT_DENOMINATIONS_PROP,
} = clientGroupConstants

const navMenuInit = [
  navMenu.list,
  navMenu.create,
  navMenu.view,
  navMenu.delete,
  navMenu.manage,
  navMenu.inserts,
]

const EditItem = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const match = useRouteMatch()
  const [form] = Form.useForm()
  const { id } = useParams()

  const [navMenuParse, setNavMenuParse] = useState([])
  const [changed, setChange] = useState(false)
  const {
    item: { category: categoriesList, user_groups: group, denomination: denominationList },
  } = useSelector(state => state.clientGroups)

  const view = getCurrentUrl(match.url)

  const navigateToView = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  const navigateToInserts = () => {
    dispatch(queryParamsActions.setQueryParams({ group_id: id }))
    history.push({
      pathname: '/inserts',
    })
  }

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(clientGroupActions.deleteItem(id))
      },
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToView,
          }
        case navMenu.inserts.key:
          return {
            ...menu,
            url: navigateToInserts,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const [, setGroupsDisabled] = useState(group?.[LIMIT_CATEGORIES_PROP] === 0)

  useEffect(() => {
    dispatch(clientGroupActions.getItemById(id))
    return () => {
      dispatch({ type: clientGroupConstants.SETBYID })
    }
  }, [dispatch, id])

  useEffect(() => {
    form.setFieldsValue({
      title: group?.title,
      limit_categories: group?.[LIMIT_CATEGORIES_PROP] === 1,
      limit_denominations: group?.[LIMIT_DENOMINATIONS_PROP] === 1,
      category: categoriesList?.filter(ca => ca.value).map(fca => fca.id),
      denomination: denominationList?.filter(ca => ca.value).map(fca => ({ value: fca.id })),
      no_delivery_confirmation: group?.no_delivery_confirmation,
      logo: parseFileForSetFields(group?.logo_url, {
        url: group?.logo_url
          ? `${appConstants.IMAGES_URL}/clientGroupImages/${group.logo_url}`
          : '',
      }),
    })
    setGroupsDisabled(group?.[LIMIT_CATEGORIES_PROP] === 0)
  }, [form, categoriesList, group, denominationList])

  const categories = useMemo(() => {
    return categoriesList?.map(e => ({ value: e.id, label: e.name }))
  }, [categoriesList])

  const denominations = useMemo(
    () =>
      denominationList?.map(e => ({
        value: e.id,
        label: e.name,
        id: e.gcard_id,
        price: e.nominal,
      })),
    [denominationList],
  )

  const onFinish = async values => {
    let { logo } = values;

    if (logo[0]?.uid === '-1') logo = group.logo_url // logo doesn't update

    const mergedGroup = {
      ...values,
      denomination: values.denomination.map(item => item.value),
      logo,
      id: group.id,
    }

    if (!logo.length) delete mergedGroup.logo // logo was removed

    const convertedGroup = convertValuesToFormData(mergedGroup)
    await dispatch(clientGroupActions.updateItemById(group.id, convertedGroup))
  }

  const onLimitChange = ({ target: { checked } }) => {
    setGroupsDisabled(!checked)

    // if (!checked) form.setFieldsValue({ category: [] })
  }

  const onUpload = e => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  return (
    <NavContainer menu={navMenuParse}>
      {useGlobalLoader(
        <div>
          <p>Fields with * are required.</p>
          <Form
            {...layout}
            name="editClientGroup"
            form={form}
            onFinish={onFinish}
            onFieldsChange={() => setChange(true)}
            scrollToFirstError
          >
            <Form.Item
              {...tailLayout}
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Title cannot be blank!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="No Delivery Confirmation"
              name="no_delivery_confirmation"
              valuePropName="checked"
            >
              <Checkbox name="no_delivery_confirmation" />
            </Form.Item>

            <Form.Item
              {...tailLayout}
              label="Logo"
              name="logo"
              valuePropName="fileList"
              getValueFromEvent={onUpload}
            >
              <Upload
                name="icon"
                maxCount={1}
                beforeUpload={() => {
                  return false
                }}
                action=""
                listType="picture"
              >
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>
            <p style={{ marginTop: '-12px', fontSize: '13px' }}>
              * Upload size must be no larger than 157 x 44.
            </p>

            <Divider orientation="left">Categories</Divider>
            <Form.Item
              {...tailLayout}
              name={LIMIT_CATEGORIES_PROP}
              valuePropName="checked"
              onChange={onLimitChange}
            >
              <Checkbox>Limit Categories</Checkbox>
            </Form.Item>

            <div>
              <AdvancedCheckboxList
                formItemProps={{
                  name: 'category',
                  valuePropName: 'value',
                }}
                buttonText="Add Category"
                options={categories}
                form={form}
                onChangeValues={() => setChange(true)}
                initialValues={categoriesList?.filter(ca => ca.value).map(fca => fca.id)}
              />
            </div>
            <Divider orientation="left">Denominations</Divider>
            <Form.Item {...tailLayout} name={LIMIT_DENOMINATIONS_PROP} valuePropName="checked">
              <Checkbox>Limit Denominations</Checkbox>
            </Form.Item>

            <div>
              <AdvancedCheckboxList
                isButtonAboveTags
                sortOnAddTag={sortByIdAndPrice}
                formItemProps={{
                  name: 'denomination',
                  valuePropName: 'value',
                }}
                buttonText="Add Gift Card"
                checkboxName="denominations"
                options={denominations}
                form={form}
                onChangeValues={() => setChange(true)}
                switchStyle={{ flex: 'unset' }}
                tagsContainerStyle={{ display: 'flex', flexDirection: 'column' }}
                initialValues={denominationList?.filter(ca => ca.value).map(fca => fca.id)}
              />
            </div>
            <Divider orientation="left" />

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit" disabled={!changed}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>,
      )}
    </NavContainer>
  )
}

export default EditItem
