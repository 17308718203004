import { labelsCreationConstants } from '../_constants'

const initialState = {
  readyToShipping: {
    rows: [],
    can_print_orders: [],
    rates: [],
    total: 0,
    generated: 0,
    error: 0,
  },
  ordersWithArchivedLabels: {},
  serviceOptions: null,
}

function labelsCreation(state = initialState, { type, payload }) {
  switch (type) {
    case labelsCreationConstants.GET_READY_TO_SHIPPING_SUCCESS:
      return {
        ...state,
        readyToShipping: payload,
      }
    case labelsCreationConstants.GET_ARCHIVED_LABELS_SUCCESS:
      return {
        ...state,
        ordersWithArchivedLabels: payload,
      }
    case labelsCreationConstants.GET_SERVICE_OPTIONS_SUCCESS:
      return {
        ...state,
        serviceOptions: payload,
      }
    case labelsCreationConstants.STATUS_LABELS_V2_SUCCESS:
      const readyToShippingCopy = { ...state.readyToShipping }

      const updatedRows = []

      readyToShippingCopy.rows.forEach(row => {
        const dataToUpdate = payload.rows.find(r => r.id === row.id)

        if (dataToUpdate) {
          updatedRows.push({ ...row, ...dataToUpdate })
        }
      })

      const readyToShippingUpdated = { ...readyToShippingCopy, ...payload, rows: updatedRows }

      return {
        ...state,
        readyToShipping: readyToShippingUpdated,
      }
    case labelsCreationConstants.RESET_SERVICE_OPTIONS:
      return {
        ...state,
        serviceOptions: null,
      }
    default:
      return state
  }
}

export default labelsCreation
