const impositionLayoutsConstants = {
  GETALL_REQUEST: 'IMPOSITION_LAYOUTS_GETALL_REQUEST',
  GETALL_SUCCESS: 'IMPOSITION_LAYOUTS_GETALL_SUCCESS',
  GETALL_FAILURE: 'IMPOSITION_LAYOUTS_GETALL_FAILURE',

  GETBYID_REQUEST: 'IMPOSITION_LAYOUTS_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'IMPOSITION_LAYOUTS_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'IMPOSITION_LAYOUTS_GETBYID_FAILURE',

  GET_IMPOSITION_CONFIGS_REQUEST: 'IMPOSITION_LAYOUTS_GET_CONFIGS_REQUEST',
  GET_IMPOSITION_CONFIGS_SUCCESS: 'IMPOSITION_LAYOUTS_GET_CONFIGS_SUCCESS',
  GET_IMPOSITION_CONFIGS_FAILURE: 'IMPOSITION_LAYOUTS_GET_CONFIGS_FAILURE',

  ADD_REQUEST: 'IMPOSITION_LAYOUTS_ADD_REQUEST',
  ADD_SUCCESS: 'IMPOSITION_LAYOUTS_ADD_SUCCESS',
  ADD_FAILURE: 'IMPOSITION_LAYOUTS_ADD_FAILURE',

  UPDATE_REQUEST: 'IMPOSITION_LAYOUTS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'IMPOSITION_LAYOUTS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'IMPOSITION_LAYOUTS_UPDATE_FAILURE',

  DELETE_REQUEST: 'IMPOSITION_LAYOUTS_DELETE_REQUEST',
  DELETE_SUCCESS: 'IMPOSITION_LAYOUTS_DELETE_SUCCESS',
  DELETE_FAILURE: 'IMPOSITION_LAYOUTS_DELETE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  CREATE_EMPTY_CONFIG: 'CREATE_IMPOSITION_EMPTY_CONFIG',
  UPDATE_ELEMENT_POSITION: 'UPDATE_IMPOSITION_ELEMENT_POSITION',
  UPDATE_ELEMENT_ROTATE: 'UPDATE_IMPOSITION_ELEMENT_ROTATE',
  CHANGE_BARCODE_POSITION: 'CHANGE_IMPOSITION_ELEMENT_BARCODE_POSITION',

  UPDATE_PAGE_BARCODE_VALUE: 'UPDATE_IMPOSITION_PAGE_BARCODE_VALUE',
  UPDATE_PAGE_BARCODE_POSITION: 'UPDATE_IMPOSITION_PAGE_BARCODE_POSITION',
  UPDATE_PAGE_BARCODE_ROTATE: 'UPDATE_IMPOSITION_PAGE_BARCODE_ROTATE',

  ADD_NEW_CARD: 'ADD_IMPOSITION_NEW_CARD',
  DELETE_CARD: 'DELETE_IMPOSITION_CARD',
  RESET_CONFIGS: 'RESET_CONFIGS',

  CREATE_CONFIG_REQUEST: 'IMPOSITION_LAYOUTS_CREATE_CONFIG_REQUEST',
  CREATE_CONFIG_SUCCESS: 'IMPOSITION_LAYOUTS_CREATE_CONFIG_SUCCESS',
  CREATE_CONFIG_FAILURE: 'IMPOSITION_LAYOUTS_CREATE_CONFIG_FAILURE',

  UPDATE_CONFIG_REQUEST: 'IMPOSITION_LAYOUTS_UPDATE_CONFIG_REQUEST',
  UPDATE_CONFIG_SUCCESS: 'IMPOSITION_LAYOUTS_UPDATE_CONFIG_SUCCESS',
  UPDATE_CONFIG_FAILURE: 'IMPOSITION_LAYOUTS_UPDATE_CONFIG_FAILURE',

  // REPEAT MODE
  SET_REPEAT_MODE: 'IMPOSITION_LAYOUTS_SET_REPEAT_MODE',
  UPDATE_ADDITIONAL_BARCODES: 'IMPOSITION_LAYOUTS_UPDATE_ADDITIONAL_BARCODES',
  UPDATE_REPEATED_CARDS: 'IMPOSITION_LAYOUTS_UPDATE_REPEATED_CARDS',

  navMenu: {
    manage: { key: 'manage', title: 'Manage Impositions', url: '/imposition-layouts' },
    create: {
      title: 'Create Imposition',
      key: 'create',
      url: '/imposition-layouts/create',
    },
    view: { key: 'view', title: 'View Imposition', url: '' },
    delete: { key: 'delete', title: 'Delete Imposition', url: '' },
    edit: { key: 'edit', title: 'Update Imposition', url: '' },
  },

  viewItemKeyToLabel: {
    id: 'ID',
    name: 'Name',
    height: 'Height',
    width: 'Width',
    total_layouts: 'Layouts',
    status: 'Status',
  },

  layoutStatuses: [
    { value: 1, text: 'Active' },
    { value: 0, text: 'Not Active' },
  ],

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },
}

export default impositionLayoutsConstants
