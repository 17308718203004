const activitiesConstants = {
  GETACTIVITYCLIENT_REQUEST: 'GETACTIVITYCLIENT_GETALL_REQUEST',
  GETACTIVITYCLIENT_SUCCESS: 'GETACTIVITYCLIENT_SUCCESS',
  GETACTIVITYCLIENT_FAILURE: 'GETACTIVITYCLIENT_FAILURE',

  GETACTIVITYEMPLOYEE_REQUEST: 'GETACTIVITYEMPLOYEE_GETALL_REQUEST',
  GETACTIVITYEMPLOYEE_SUCCESS: 'GETACTIVITYEMPLOYEE_SUCCESS',
  GETACTIVITYEMPLOYEE_FAILURE: 'GETACTIVITYEMPLOYEE_FAILURE',

  DELETE_REQUEST: 'ACTIVITY_DELETE_REQUEST',
  DELETE_SUCCESS: 'ACTIVITY_DELETE_SUCCESS',
  DELETE_FAILURE: 'ACTIVITY_DELETE_FAILURE',

  RESEND_REQUEST: 'ACTIVITY_RESEND_REQUEST',
  RESEND_SUCCESS: 'ACTIVITY_RESEND_SUCCESS',
  RESEND_FAILURE: 'ACTIVITY_RESEND_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    view: { key: 'view', title: 'View Client', url: '' },
    edit: { key: 'edit', title: 'Update Client', url: '' },
    list: { key: 'list', title: 'List Client', url: '/clients/list' },
    manage: { key: 'manage', title: 'Manage Client', url: '/clients' },
  },

  advancedSearch: [
    { label: 'ID', key: 'id'},
    { label: 'Date Created', key: 'date_created*date' },
    { label: 'User', key: 'user_id'},
    { label: 'Device', key: 'device' },
  ],

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },
}
export default activitiesConstants
