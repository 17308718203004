import React from 'react'
import { Button, Divider, Space, Typography } from 'antd'
import { useSelector } from 'react-redux'

const LabelsCreationHeader = ({
  selectedOrders,
  onPrintLabels,
  onCompleteLabels,
  onCancelLabels,
  resetSelected,
  disablePrintAndCancel,
}) => {
  const { generated, error, can_print_orders } = useSelector(
    state => state.labelsCreation.readyToShipping,
  )

  const printAvailableOrderLabels = async () => {
    await onPrintLabels(can_print_orders)
  }

  const printSelectedLabels = async () => {
    await onPrintLabels(selectedOrders)
    resetSelected()
  }

  const completeSelectedLabels = async () => {
    await onCompleteLabels(selectedOrders)
    resetSelected()
  }

  const cancelSelectedLabels = async () => {
    await onCancelLabels(selectedOrders)
    resetSelected()
  }

  return (
    <Space style={{ width: '100%', justifyContent: 'space-between' }}>
      <Space
        split={<Divider type="vertical" />}
        style={{
          border: '1px solid rgba(5, 5, 5, 0.06)',
          padding: '0.1rem 0.5rem',
          borderRadius: '0.2rem',
        }}
      >
        <Typography.Text style={{ whiteSpace: 'nowrap' }}>
          <b>Generated:</b> {generated}
        </Typography.Text>
        <Typography.Text style={{ whiteSpace: 'nowrap' }}>
          <b>Errors:</b> {error}
        </Typography.Text>
      </Space>

      <Space style={{ flexWrap: 'wrap' }}>
        <Button
          onClick={printSelectedLabels}
          type="primary"
          disabled={!selectedOrders.length || disablePrintAndCancel}
        >
          Print Selected ({selectedOrders.length})
        </Button>
        <Button onClick={completeSelectedLabels} type="primary" disabled={!selectedOrders.length}>
          Complete Selected ({selectedOrders.length})
        </Button>
        <Button
          onClick={cancelSelectedLabels}
          danger
          disabled={!selectedOrders.length || disablePrintAndCancel}
        >
          Cancel Selected ({selectedOrders.length})
        </Button>

        <Button
          onClick={printAvailableOrderLabels}
          disabled={!can_print_orders.length}
          type="primary"
        >
          Print Generated Labels ({can_print_orders.length})
        </Button>
      </Space>
    </Space>
  )
}

export default LabelsCreationHeader
