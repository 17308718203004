import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import NavContainer from '../../../../components/nav-container'
import ListViewer from '../../../../components/list-viewer'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { clientGroupActions } from '../../../../_actions'
import { appConstants, clientGroupConstants, settingsConstants } from '../../../../_constants'
import { Image } from 'antd'
import {
  getClientGroupsRows,
  getPage,
  getTotalItems,
} from '../../../../_selectors/client-group.selector.js'

const { navMenu, listItemKeyToLabel } = clientGroupConstants

const navMenuInit = [navMenu.create, navMenu.manage]

const ListItems = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getClientGroupsRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const navigateToManage = () => {
    dispatch({ type: clientGroupConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.manage.key:
          return {
            ...menu,
            url: navigateToManage,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const listItems = useMemo(() => {
    return {
      total,
      page,
      rows: rows?.map(item => {
        const res = []
        Object.keys(listItemKeyToLabel).forEach(sKey => {
          const itemLine = { label: listItemKeyToLabel[sKey], value: item[sKey], key: sKey }
          if (sKey === 'id') {
            itemLine.clickHandler = () => {
              history.push({ pathname: `/client-groups/view/${item[sKey]}` })
            }
          } else if (sKey === 'no_delivery_confirmation') {
            itemLine.value = `${!item[sKey] ? 'NO' : 'YES'}`
          } else if (sKey === 'logo') {
            itemLine.float = true
            itemLine.type = 'image'
            itemLine.value = (
              <Image
                width={40}
                src={`${appConstants.IMAGES_URL}/clientGroupImages/${item.logo_url}`}
                fallback={appConstants.FALLBACK_IMAGE}
                preview={false}
              />
            )
          }
          res.push(itemLine)
        })
        return res
      }),
    }
  }, [rows, total, page])

  return (
    <NavContainer menu={navMenuParse}>
      <ListViewer data={listItems} getDataAction={clientGroupActions.getAll} />
    </NavContainer>
  )
}

export default ListItems
