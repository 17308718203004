import { qaConstants } from '../_constants'

const initialState = {
  completedOrderIds: null,
  incompletedOrderIds: null,
  skipOrderIds: null,
  error: null,
}

function qa(state = initialState, { type, payload }) {
  switch (type) {
    case qaConstants.ASSIGN_MANIFEST_SUCCESS:
      const { completedOrderIds, incompletedOrderIds, skipOrderIds } = payload
      return { ...state, completedOrderIds, incompletedOrderIds, skipOrderIds }
    case qaConstants.ASSIGN_MANIFEST_FAILURE:
      return { ...state, error: payload }
    case qaConstants.SETALL:
      return initialState
    default:
      return state
  }
}

export default qa
