import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams, useRouteMatch } from 'react-router-dom'

import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import ItemViewer from '../../../../components/item-viewer'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { insertsActions } from '../../../../_actions'
import { insertsConstants } from '../../../../_constants'

const { navMenu, viewItemKeyToLabel, insertStatus, insertable } = insertsConstants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.edit, navMenu.delete, navMenu.manage]

const ViewItem = () => {
  const match = useRouteMatch()
  const { id } = useParams()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])

  const {
    item: { insert },
  } = useSelector(state => state.inserts)

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(insertsActions.deleteItem(id))
      },
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.edit.key:
          return {
            ...menu,
            url: `${view}/edit/${id}`,
          }
        case navMenu.list.key:
          return {
            ...menu,
            url: `${view}/list/`,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  useEffect(() => {
    dispatch(insertsActions.getItemById(id))
    return () => {
      dispatch({ type: insertsConstants.SETBYID })
    }
  }, [id])

  const viewItem = useMemo(() => {
    if (insert) {
      return Object.keys(viewItemKeyToLabel).reduce((obj, sKey) => {
        let itemValue
        if (sKey === 'user') {
          itemValue = <Link to={`/clients/edit/${insert?.user?.id}`}>{insert?.user?.name}</Link>
        } else if (sKey === 'status') {
          itemValue = `${
            insertStatus.find(st => st.value.toString() === insert.status.toString())?.text
          }`
        } else if (sKey === 'insertable') {
          itemValue = `${insertable.find(st => st.value.toString() === insert.insertable)?.text}`
        } else {
          itemValue = insert[sKey]
        }
        return {
          ...obj,
          [sKey]: itemValue,
        }
      }, {})
    }
    return {}
  }, [insert])

  return (
    <NavContainer menu={navMenuParse}>
      <ItemViewer item={viewItem} keyToLabelMap={viewItemKeyToLabel} />
    </NavContainer>
  )
}

export default ViewItem
