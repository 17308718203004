const generateUniqueCardId = (
  id,
  is_redo,
  is_ups,
  is_confirmation,
  rate_id,
  is_gift_card,
  is_insert,
  dateSend,
  printedOrderCount,
) => {
  return `${id}_${is_redo ?? 0}${is_ups ?? 0}${is_confirmation ?? 0}${rate_id ?? 0}${is_gift_card ??
    0}${is_insert ?? 0}${dateSend || ''}${printedOrderCount ?? 0}`
}

export default generateUniqueCardId
