import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { Button, Form, Input, InputNumber, Modal, Select } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import { shippingConfigsActions } from '../../../../_actions'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import getCurrentUrl from '../../../../_helpers/url-utils'

import { shippingConfigsConstants } from '../../../../_constants'

import { convertDataForSelect } from '../../../../_helpers'

const {
  configTypes,
  shippingConfigsStatuses,
  formItemLayout,
  cardSizes,
  navMenu,
} = shippingConfigsConstants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.view, navMenu.manage]

const EditItem = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)
  const { id } = useParams()

  const [activeType, setActiveType] = useState(configTypes[0].value)
  const [navMenuParse, setNavMenuParse] = useState([])
  const [changed, setChange] = useState(false)
  const { item: shippingConfig } = useSelector(state => state.shippingConfigs)

  useEffect(() => {
    dispatch(shippingConfigsActions.getItemById(id))
    return () => {
      dispatch({ type: shippingConfigsConstants.SETBYID })
    }
  }, [])

  useEffect(() => {
    if (shippingConfig) {
      setActiveType(shippingConfig?.type)
      form.setFieldsValue({ ...shippingConfig })
    }
  }, [shippingConfig, form])

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToView,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const handleTypeChange = value => setActiveType(value)

  const navigateToView = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(shippingConfigsActions.deleteItem(id))
      },
    })
  }

  const onFinish = async values => {
    const convertedShippingConfig = convertFormValuesDeep(values)
    await dispatch(
      shippingConfigsActions.updateItemById(shippingConfig.id, convertedShippingConfig),
    )
  }

  const formItems = useMemo(() => {
    if (activeType === 'box') {
      return (
        <>
          <Form.Item label="Card Size" name="card_size" initialValue={cardSizes[0].value}>
            <Select options={cardSizes} />
          </Form.Item>
          <Form.Item
            label="Box Length"
            name="box_length"
            initialValue={0}
            rules={[
              {
                required: true,
                message: 'Please input Box Length!',
              },
            ]}
          >
            <InputNumber step={0.1} min={0} />
          </Form.Item>
          <Form.Item
            label="Box Width"
            name="box_width"
            initialValue={0}
            rules={[
              {
                required: true,
                message: 'Please input Box Width!',
              },
            ]}
          >
            <InputNumber step={0.1} min={0} />
          </Form.Item>
          <Form.Item
            label="Box Height"
            name="box_height"
            initialValue={0}
            rules={[
              {
                required: true,
                message: 'Please input Box Height!',
              },
            ]}
          >
            <InputNumber step={0.1} min={0} />
          </Form.Item>
          <Form.Item
            label="Empty Box Weight"
            name="empty_box_weight"
            initialValue={0}
            rules={[
              {
                required: true,
                message: 'Please input Empty Box Weight!',
              },
            ]}
          >
            <InputNumber step={0.1} min={0} />
          </Form.Item>
          <Form.Item
            label="Quantity From"
            name="quantity_from"
            initialValue={0}
            rules={[
              {
                required: true,
                message: 'Please input Quantity!',
              },
            ]}
          >
            <InputNumber step={1} min={0} />
          </Form.Item>
          <Form.Item
            label="Quantity To"
            name="quantity_to"
            initialValue={0}
            rules={[
              {
                required: true,
                message: 'Please input Quantity!',
              },
            ]}
          >
            <InputNumber step={1} min={0} />
          </Form.Item>
        </>
      )
    }
    if (activeType === 'card') {
      return (
        <>
          <Form.Item label="Card Size" name="card_size" initialValue={cardSizes[0].value}>
            <Select options={cardSizes} />
          </Form.Item>
          <Form.Item
            label="Card Weight"
            name="card_weight"
            initialValue={0}
            rules={[
              {
                required: true,
                message: 'Please input Card Weight!',
              },
            ]}
          >
            <InputNumber step={0.1} min={0} />
          </Form.Item>
          <Form.Item
            label="Gift Weight"
            name="gift_weight"
            initialValue={0}
            rules={[
              {
                required: true,
                message: 'Please input Gift Weight!',
              },
            ]}
          >
            <InputNumber step={0.1} min={0} />
          </Form.Item>
          <Form.Item
            label="Insert Weight"
            name="insert_weight"
            initialValue={0}
            rules={[
              {
                required: true,
                message: 'Please input Insert Weight!',
              },
            ]}
          >
            <InputNumber step={0.1} min={0} />
          </Form.Item>
        </>
      )
    }
    return (
      <>
        <Form.Item
          label="Shipper Name"
          name="shipper_name"
          rules={[
            {
              required: true,
              message: 'Please input Shipper Name!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Shipper ID"
          name="shipper_id"
          rules={[
            {
              required: true,
              message: 'Please input Shipper ID!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Shipper Address"
          name="shipper_address"
          rules={[
            {
              required: true,
              message: 'Please input Shipper Address!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Shipper City"
          name="shipper_city"
          rules={[
            {
              required: true,
              message: 'Please input Shipper City!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Shipper State"
          name="shipper_state"
          rules={[
            {
              required: true,
              message: 'Please input Shipper State!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Shipper Zip"
          name="shipper_zip"
          rules={[
            {
              required: true,
              message: 'Please input Shipper Zip!',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </>
    )
  }, [activeType])

  return (
    <NavContainer menu={navMenuParse}>
      {shippingConfig && (
        <div>
          <p>Fields with * are required.</p>
          <Form
            {...formItemLayout}
            name="editShippingConfig"
            form={form}
            onFinish={onFinish}
            onFieldsChange={() => setChange(true)}
            scrollToFirstError
          >
            <Form.Item label="Type" name="type" initialValue={configTypes[0].value}>
              <Select options={convertDataForSelect(configTypes)} onChange={handleTypeChange} />
            </Form.Item>
            {formItems}
            <Form.Item label="Status" name="status" initialValue={0}>
              <Select options={convertDataForSelect(shippingConfigsStatuses)} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!changed}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </NavContainer>
  )
}

export default EditItem
