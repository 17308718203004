const countryConstants = {
  GETALL_REQUEST: 'COUNTRY_GETALL_REQUEST',
  GETALL_SUCCESS: 'COUNTRY_SUCCESS',
  GETALL_FAILURE: 'COUNTRY_FAILURE',

  ADD_REQUEST: 'COUNTRY_ADD_REQUEST',
  ADD_SUCCESS: 'COUNTRY_ADD_SUCCESS',
  ADD_FAILURE: 'COUNTRY_ADD_FAILURE',

  GETBYID_REQUEST: 'COUNTRY_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'COUNTRY_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'COUNTRY_GETBYID_FAILURE',

  DELETE_REQUEST: 'COUNTRY_DELETE_REQUEST',
  DELETE_SUCCESS: 'COUNTRY_DELETE_SUCCESS',
  DELETE_FAILURE: 'COUNTRY_DELETE_FAILURE',

  UPDATE_REQUEST: 'COUNTRY_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'COUNTRY_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'COUNTRY_UPDATE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    list: { key: 'list', title: 'List Country', url: '/countries/list' },
    manage: { key: 'manage', title: 'Manage Country', url: '/countries' },
    create: { title: 'Create Country', key: 'create', url: '/countries/create' },
    edit: { key: 'edit', title: 'Update Country', url: '' },
    delete: { key: 'delete', title: 'Delete Country', url: '' },
    view: { key: 'view', title: 'View Country', url: '' },
  },

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },

  listItemKeyToLabel: {
    id: 'ID',
    name: 'Name',
    aliases: 'Aliases',
    delivery_cost: 'Delivery Cost',
  },
  viewItemKeyToLabel: {
    id: 'ID',
    name: 'Name',
    aliases: 'Aliases',
    delivery_cost: 'Delivery Cost',
  },
  advancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'Name', key: 'name' },
    { label: 'Delivery Cost', key: 'delivery_cost' }
  ],
}
export default countryConstants
