import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { ordersActions } from '../../../../_actions'

import NavContainer from '../../../../components/nav-container'
import ListViewer from '../../../../components/list-viewer'
import { appConstants, ordersConstants } from '../../../../_constants'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import { getOrdersRows, getPage, getTotalItems } from '_selectors/orders.selector'

const { listItemKeyToLabel, orderStatuses, navMenu } = ordersConstants

const navMenuInit = [navMenu.manage, navMenu.list, navMenu.listMyOrders]

const MyOrder = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()

  const rows = useSelector(getOrdersRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const { user } = useSelector(state => state.authentication)
  const { [match.url]: storedPageSize } = useSelector(state => state.pagination)

  const [isAssign, setAssign] = useState(false)

  const currentPageSize = useMemo(() => {
    return storedPageSize || appConstants.DEFAULT_PAGE_SIZE
  }, [storedPageSize])

  useEffect(() => {
    return () => {
      dispatch({ type: ordersConstants.SETALL })
    }
  }, [])

  const employeeAssignToMe = async id => {
    setAssign(true)
    await dispatch(
      ordersActions.updateItemById(id, convertFormValuesDeep({ employee_id: user.id }), 'assign'),
    )
    const newPage = rows.length === 1 && page !== 1 ? page - 1 : page

    await dispatch(
      ordersActions.getAll(
        {
          offset: newPage,
          limit: currentPageSize,
          where: { employee_id: null, status: 'paid' },
          like: null,
          order: null,
        },
        'assign',
        id,
      ),
    )
    setAssign(false)
  }

  const listItems = useMemo(() => {
    if (rows?.length > 0) {
      return {
        total,
        page,
        rows: rows?.map(item => {
          const res = []
          Object.keys(listItemKeyToLabel).forEach(sKey => {
            const itemLine = { label: listItemKeyToLabel[sKey], key: sKey, value: item[sKey] }
            if (sKey === 'id') {
              itemLine.clickHandler = () => history.push({ pathname: `/orders/edit/${item.id}` })
            } else if (sKey === 'status') {
              itemLine.value =
                orderStatuses.find(status => status.value === item.status)?.text ||
                `No status type found: ${item.status}`
            } else if (sKey === 'inserts') {
              itemLine.value = `${
                item?.inserts[0] ? `${item.inserts[0]?.name} ($${item.inserts[0]?.price})` : '---'
              }`
            } else if (sKey === 'card') {
              if (item?.card?.id) {
                itemLine.clickHandler = () =>
                  history.push({ pathname: `/cards/edit/${item.card.id}` })
                itemLine.value = `[ID:${item?.card?.id}] ${item?.card?.name || '----'}`
              }
            } else if (sKey === 'user') {
              itemLine.clickHandler = () =>
                history.push({ pathname: `/clients/edit/${item.user.id}` })
              itemLine.value = `[ID: ${item.user?.id}] ${item.user?.name || '----'}`
            } else if (sKey === 'employee') {
              itemLine.clickHandler = () => employeeAssignToMe(item.id)
              itemLine.value = `assign to me`
              itemLine.toolTip = 'Click to assign'
            }
            res.push(itemLine)
          })
          return res
        }),
      }
    }
    return { rows: [], total, page }
  }, [rows, total, page])

  return (
    <NavContainer menu={navMenuInit}>
      <ListViewer data={listItems} getDataAction={ordersActions.getAll} isAssign={isAssign} />
    </NavContainer>
  )
}

export default MyOrder
