const holidayConstants = {
  GETALL_REQUEST: 'HOLIDAY_GETALL_REQUEST',
  GETALL_SUCCESS: 'HOLIDAY_GETALL_SUCCESS',
  GETALL_FAILURE: 'HOLIDAY_GETALL_FAILURE',

  GETBYID_REQUEST: 'HOLIDAY_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'HOLIDAY_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'HOLIDAY_GETBYID_FAILURE',

  ADD_REQUEST: 'HOLIDAY_ADD_REQUEST',
  ADD_SUCCESS: 'HOLIDAY_ADD_SUCCESS',
  ADD_FAILURE: 'HOLIDAY_ADD_FAILURE',

  DELETE_REQUEST: 'HOLIDAY_DELETE_REQUEST',
  DELETE_SUCCESS: 'HOLIDAY_DELETE_SUCCESS',
  DELETE_FAILURE: 'HOLIDAY_DELETE_FAILURE',

  UPDATE_REQUEST: 'HOLIDAY_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'HOLIDAY_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'HOLIDAY_UPDATE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    list: { key: 'list', title: 'List Holiday', url: '/holidays/list' },
    create: { key: 'create', title: 'Create Holiday', url: '/holidays/create' },
    manage: { key: 'manage', title: 'Manage Holiday', url: '/holidays' },
    edit: { key: 'edit', title: 'Update Holiday ', url: '' },
    delete: { key: 'delete', title: 'Delete Holiday', url: '' },
    view: { key: 'view', title: 'View Holiday', url: '' },
  },

  listItemKeyToLabel: {
    id: 'ID',
    date: 'Date',
  },

  viewItemKeyToLabel: {
    id: 'ID',
    date: 'Date',
  },

  advancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'Date', key: 'date*date' },
  ],

  formItemLayout: {
    labelCol: {
      xs: { span: 1 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 4 },
      sm: { span: 10 },
    },
  },
}
export default holidayConstants
