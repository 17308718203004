import { batchOrdersConstants } from '../_constants'

const initialState = {
  orderDates: [],
  paidOrders: [],
  printedOrderCount: null,
  ordersByCardId: {},
  paidOrdersByDate: {},
  printedOrders: [],
  selectedRows: {},
  orientation: '',
  ordersInWork: [],
  // isDocxAllowed: false,
  ordersInWorkCount: -1,
  ordersInWorkCountChecked: -1,
  discardedOrders: [],
  completedOrders: [],
  discardedOrdersAfterComplete: [],
  statusLabels: {},
  countOrdersWithLabels: 0,
  generatedLabelsError: null,
  manifestsStatus: null,
  isNotesDownloaded: false,
  isImposedDownloaded: false,
  isEnvelopesDownloaded: false,
  totalOrders: null,
  workList: [],
  impositionProgress: [],
  activePanel: {},
  rowsWithButton: null,
}

const createSelectedRows = (state, payload) => {
  return payload
    ? Object.keys(state.selectedRows)
        .filter(key => key.toString() !== payload.toString())
        .reduce((obj, key) => {
          return {
            ...obj,
            [key]: state.selectedRows[key],
          }
        }, {})
    : {}
}

function batchOrders(state = initialState, { type, payload }) {
  switch (type) {
    case batchOrdersConstants.SET_ORIENTATION:
      return {
        ...state,
        orientation: payload,
      }
    case batchOrdersConstants.RESET_ORIENTATION:
      return {
        ...state,
        orientation: '',
      }
    case batchOrdersConstants.GET_GROUPS_SUCCESS:
      return {
        ...state,
        orderDates: payload?.dates,
        paidOrders: payload.rows,
        printedOrderCount: payload?.printed ?? null,
      }
    case batchOrdersConstants.GET_ORDERS_BY_DATE_SUCCESS:
      return {
        ...state,
        paidOrdersByDate: { ...state.paidOrdersByDate, [payload.send_date]: payload.rows },
      }
    case batchOrdersConstants.GET_PRINTED_ORDERS_SUCCESS:
      return {
        ...state,
        printedOrders: payload.rows,
      }
    case batchOrdersConstants.GETBYCARD_REQUEST_SUCCESS:
      return { ...state, ordersByCardId: { ...state.ordersByCardId, ...payload } }
    case batchOrdersConstants.SAVENOTE_SUCCESS:
      return {
        ...state,
        ordersByCardId: {
          ...state.ordersByCardId,
          [payload.card_id]: state.ordersByCardId[payload.card_id].map(item =>
            item.id === payload.id ? { ...item, note: payload.value } : item,
          ),
        },
      }
    case batchOrdersConstants.SAVE_SELECTED_ROWS:
      return {
        ...state,
        selectedRows: {
          ...state.selectedRows,
          [payload.card_id]: {
            ...state.selectedRows[payload.card_id],
            ...(payload.rows && { rows: payload.rows, ids: payload.ids }),
            count: payload.count,
            orientation: payload.rows ? payload.orientation : '',
            isPrintedOrder: !!payload.isPrintedList,
          },
        },
      }
    case batchOrdersConstants.CLEAR_SELECTED_ROWS:
      return {
        ...state,
        selectedRows: {
          ...createSelectedRows(state, payload),
        },
      }
    case batchOrdersConstants.PROCESS_SUCCESS:
      return {
        ...state,
        ordersInWork: [...payload.ordersInWork],
        ordersInWorkCount: payload.count,
      }
    case batchOrdersConstants.GETALL_IN_WORK_SUCCESS:
      return {
        ...state,
        ordersInWork: [...payload.ordersInWork],
        ordersInWorkCount: payload.count,
        countOrdersWithLabels: payload.countOrdersWithLabels,
      }
    case batchOrdersConstants.CHECK_IN_WORK_SUCCESS:
      return {
        ...state,
        ordersInWorkCountChecked: payload.count,
        ordersInWork: [...payload.ordersInWork],
        ordersInWorkCount: payload.count,
      }
    case batchOrdersConstants.CLEAR_BATCH_ORDERS_VIEW:
      return {
        ...state,
        orderDates: [],
        paidOrdersByDate: {},
        printedOrders: [],
        printedOrderCount: null,
        ordersByCardId: {},
        selectedRows: {},
        activePanel: {},
        rowsWithButton: null,
      }
    case batchOrdersConstants.CLEAR_MY_ORDERS_VIEW:
      return {
        ...state,
        ordersInWorkCount: -1,
        ordersInWork: [],
        discardedOrders: [],
        activePanel: {},
        countOrdersWithLabels: 0,
        generatedLabelsError: null,
        statusLabels: {},
        rowsWithButton: null,
      }
    case batchOrdersConstants.CLEAR_CHECKED_ORDERS:
      return {
        ...state,
        ordersInWorkCountChecked: -1,
      }
    case batchOrdersConstants.CREATE_MANIFEST_SUCCESS:
      return {
        ...state,
        // isDocxAllowed: true,
      }
    case batchOrdersConstants.DISCARD_ORDERS_SUCCESS:
      return {
        ...state,
        discardedOrders: [...payload.discardedOrders],
        ordersInWorkCountChecked: 0,
        isDocxAllowed: false,
        manifestsStatus: null,
        isNotesDownloaded: false,
        isEnvelopesDownloaded: false,
      }
    case batchOrdersConstants.COMPLETE_ORDERS_SUCCESS:
      return {
        ...state,
        completedOrders: [...payload.completedOrders],
        // discardedOrdersAfterComplete: [...payload.discardedOrders],
        ordersInWorkCountChecked: 0,
        isDocxAllowed: false,
        isNotesDownloaded: false,
        isEnvelopesDownloaded: false,
      }
    case batchOrdersConstants.STATUS_LABELS_SUCCESS:
      return { ...state, statusLabels: payload }
    case batchOrdersConstants.GET_TOTAL_COUNT_SUCCESS:
      return { ...state, totalOrders: payload }
    case batchOrdersConstants.GENERATE_LABELS_SUCCESS:
      return { ...state, generatedLabelsError: payload }
    case batchOrdersConstants.STATUS_MANIFEST_SUCCESS:
      return {
        ...state,
        manifestsStatus: {
          manifest_id: payload && payload?.manifest_id,
          status: payload && payload.status === 'new',
          file: payload && payload.fileLink,
          file_cut: payload && payload.file_cutLink,
          manifestsForIds: payload && payload.orders,
        },
        isNotesDownloaded: false,
        isEnvelopesDownloaded: false,
      }
    case batchOrdersConstants.CLEAR_MANIFESTS_STATUS:
      return { ...state, manifestsStatus: null }
    case batchOrdersConstants.UPDATE_MANIFESTS_STATUS:
      return {
        ...state,
        manifestsStatus: {
          ...state.manifestsStatus,
          ...payload,
        },
      }
    case batchOrdersConstants.CREATE_DOCX_NOTES_SUCCESS:
      return {
        ...state,
      }
    case batchOrdersConstants.CREATE_DOCX_NOTES_FAILURE:
      return {
        ...state,
      }
    case batchOrdersConstants.CREATE_DOCX_NOTES_PDF_SUCCESS:
      return {
        ...state,
        isNotesDownloaded: true,
      }
    case batchOrdersConstants.CREATE_DOCX_NOTES_PDF_FAILURE:
      return {
        ...state,
        isNotesDownloaded: false,
      }

    case batchOrdersConstants.CREATE_DOCX_PDF_ENVELOPES_SUCCESS:
      return {
        ...state,
        isEnvelopesDownloaded: true,
      }

    case batchOrdersConstants.CREATE_DOCX_PDF_ENVELOPES_FAILURE:
      return {
        ...state,
        isEnvelopesDownloaded: false,
      }

    case batchOrdersConstants.GENERATE_IMPOSED_CARDS_REQUEST_SUCCESS:
      return {
        ...state,
        isImposedDownloaded: true,
      }

    case batchOrdersConstants.GENERATE_WORK_LIST_SUCCESS:
      return {
        ...state,
        workList: payload,
      }
    case batchOrdersConstants.GENERATE_IMPOSED_CARDS_REQUEST:
      const newProgress = { filename: payload, percentage: null }
      return {
        ...state,
        impositionProgress: [...state.impositionProgress, newProgress],
      }
    case batchOrdersConstants.IMPOSITIONS_DOWNLOAD_PROGRESS:
      const downloadsClone = [...state.impositionProgress]

      const existingProgressIndex = state.impositionProgress.findIndex(
        el => el.filename === payload.filename,
      )

      const progressExist = existingProgressIndex !== -1

      if (progressExist) downloadsClone[existingProgressIndex].percentage = payload.percentage

      return {
        ...state,
        impositionProgress: !progressExist
          ? [...state.impositionProgress, payload]
          : downloadsClone,
      }
    case batchOrdersConstants.IMPOSITIONS_DOWNLOAD_END:
    case batchOrdersConstants.GENERATE_IMPOSED_CARDS_FAILURE:
      const downloads = [...state.impositionProgress]
      const indexToRemove = state.impositionProgress.findIndex(el => el.filename === payload)

      if (indexToRemove > -1) {
        downloads.splice(indexToRemove, 1)
      }
      return {
        ...state,
        impositionProgress: downloads,
      }
    case batchOrdersConstants.SET_ACTIVE_PANEL:
      return { ...state, activePanel: payload }
    case batchOrdersConstants.SET_ROWS_WITH_BUTTON:
      return { ...state, rowsWithButton: payload }
    default:
      return state
  }
}

export default batchOrders
