import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import v4 from 'uuid/v4'
import getCurrentUrl from '../../../../_helpers/url-utils'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import { employeesActions } from '../../../../_actions'
import { employeesConstants, settingsConstants } from '../../../../_constants'
import {
  getEmployeeRows,
  getPage,
  getTotalItems,
} from '../../../../_selectors/employees.selector.js'

const { navMenu, userStatuses, superUserStatus, advancedSearch } = employeesConstants

const statusRender = text => {
  return `${userStatuses.find(st => st.value.toString() === text.toString())?.text}`
}

const superUserRender = text => {
  return `${superUserStatus.find(st => st.value.toString() === text.toString())?.text}`
}

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    width: '100px',
  },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    key: 'email',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Registration date',
    dataIndex: 'create_at',
    key: 'create_at*date',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Last visit',
    dataIndex: 'lastvisit_at',
    key: 'lastvisit_at*date',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Superuser',
    dataIndex: 'superuser',
    key: 'superuser_filter',
    render: superUserRender,
    filters: superUserStatus,
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status_filter',
    render: statusRender,
    filters: userStatuses,
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
]

const navMenuInit = [navMenu.create, navMenu.manage, navMenu.listUser]

const ManageItems = ({ match }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getEmployeeRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const deleteItems = async id => {
    await dispatch(employeesActions.deleteItem(id))
  }

  const navigateToList = () => {
    dispatch({ type: employeesConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}/list`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.listUser.key:
          return {
            ...menu,
            url: navigateToList,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        email: <a href={`mailto:${item.email}`}>{item.email}</a>,
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  const superUserSelect = useMemo(() => {
    return superUserStatus?.map(status => ({ value: status.value, label: status.text }))
  }, [superUserStatus])

  const statusesSelect = useMemo(() => {
    return userStatuses.map(status => ({ value: status.value, label: status.text }))
  }, [userStatuses])

  const updAdvancedSearch = advancedSearch.map(field => {
    if (field.key === 'superuser_filter') return { ...field, options: superUserSelect }
    if (field.key === 'status_filter') return { ...field, options: statusesSelect }
    return field
  })

  return (
    <NavContainer match={match} menu={navMenuParse}>
      <TableFilter
        deleteItems={deleteItems}
        columns={initColumns}
        data={dataSource}
        getDataAction={employeesActions.getAll}
        advancedSearch={updAdvancedSearch}
        showAdvancedButton={!!true}
      />
    </NavContainer>
  )
}

export default ManageItems
