import React, { useEffect, useState } from 'react'
import { Form } from 'antd'
import TagsGroup from 'components/tags-group'

const AdvancedCheckboxList = ({
  form,
  formItemProps,
  buttonText,
  options,
  initialValues,
  onChangeValues = () => {},
  sortOnAddTag,
  tagsContainerStyle,
  isButtonAboveTags = false,
}) => {
  const [checked, setChecked] = useState([])
  const [isInitial, setIsInitial] = useState(true)

  useEffect(() => {
    if (initialValues && isInitial && options) {
      const initialCheckedItems = getCheckedItems(initialValues)
      setChecked(initialCheckedItems)
      setIsInitial(false)
    }
  }, [initialValues, options])

  const getCheckedItems = ids => {
    const checkedItems = []
    ids.forEach(id => {
      const checkedItem = options.find(option => option.value === id)
      if (checkedItem) checkedItems.push(checkedItem)
    })

    return checkedItems
  }

  const handleAddTag = (_, item) => {
    onChangeValues()
    const updatedItems = [...checked, item]
    const updatedIds = sortOnAddTag
      ? sortOnAddTag(updatedItems)
      : updatedItems.map(ch => ch.value)

    setChecked(updatedItems)
    form.setFieldsValue({ [formItemProps.name]: updatedIds })
  }

  const handleRemoveTag = id => {
    onChangeValues()
    const updatedItems = checked.filter(checked => checked.value !== id)
    // if we have sortOnAddTag we should not to return array of numbers cause we need to have the same array as on add tag
    const updatedIds = sortOnAddTag ? updatedItems : updatedItems.map(ch => ch.value)

    setChecked(updatedItems)
    form.setFieldsValue({ [formItemProps.name]: updatedIds })
  }

  return (
    <div>
      <Form.Item {...formItemProps}>
        <TagsGroup
          isButtonAboveTags={isButtonAboveTags}
          tagsContainerStyle={tagsContainerStyle}
          buttonText={buttonText}
          tags={checked}
          options={options}
          addTag={handleAddTag}
          removeTag={handleRemoveTag}
        />
      </Form.Item>
    </div>
  )
}

export default AdvancedCheckboxList
