import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import v4 from 'uuid/v4'
import NavContainer from '../../../../components/nav-container'
import { employeesActions } from '../../../../_actions'
import { employeesConstants, settingsConstants } from '../../../../_constants'
import TableFilter from '../../../../components/table-filter'
import getCurrentUrl from '../../../../_helpers/url-utils'
import {
  getEmployeeRows,
  getPage,
  getTotalItems,
} from '../../../../_selectors/employees.selector.js'

const { navMenu, listItemKeyToLabel } = employeesConstants

const navMenuInit = [navMenu.manage]

const initColumns = [
  {
    title: listItemKeyToLabel.username,
    dataIndex: 'username',
    key: 'username',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    hideFilter: true,
  },
  {
    title: listItemKeyToLabel.create_at,
    dataIndex: 'create_at',
    key: 'create_at',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    hideFilter: true,
  },
  {
    title: listItemKeyToLabel.lastvisit_at,
    dataIndex: 'lastvisit_at',
    key: 'lastvisit_at',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    hideFilter: true,
  },
]

const ListItems = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getEmployeeRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const navigateToManage = () => {
    dispatch({ type: employeesConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.manage.key:
          return {
            ...menu,
            url: navigateToManage,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const listItems = useMemo(() => {
    return {
      total,
      page,
      rows: rows?.map(item => {
        const res = { key: v4() }
        Object.keys(listItemKeyToLabel).forEach(sKey => {
          if (sKey === 'username') {
            res[sKey] = <Link to={`${view}/view/${item.id}`}>{item[sKey]}</Link>
          } else {
            res[sKey] = item[sKey]
          }
        })
        return res
      }),
    }
  }, [rows, total, page])

  return (
    <NavContainer match={match} menu={view === '/user' ? [] : navMenuParse}>
      <TableFilter
        columns={initColumns}
        data={listItems}
        actionsColum={false}
        getDataAction={employeesActions.getAll}
        showAdvancedSearch={false}
        showAdvancedButton={!!true}
      />
    </NavContainer>
  )
}

export default ListItems
