export const getAutomationRows = state => state.automations?.items?.rows || null
export const getPage = state => state.automations?.items?.page || 0
export const getTotalItems = state => state.automations?.items?.total || 0

export const getOrderRows = state => state.automations?.orders?.rows || null
export const getOrderPage = state => state.automations?.orders?.page || 0
export const getOrderTotalItems = state => state.automations?.orders?.total || 0

export const getAddresses = state => state.automations?.item?.addresses || null

export const getAutomationById = state => state.automations?.item || null
