import { couponConstants } from '../_constants'
import { couponsService } from '../_services'
import { history } from '../_helpers'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: couponConstants.GETALL_REQUEST })
  try {
    const coupons = await couponsService.getAll(params, typeAction)
    dispatch({ type: couponConstants.GETALL_SUCCESS, payload: coupons.data })
  } catch (error) {
    dispatch({ type: couponConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const addItem = value => async dispatch => {
  dispatch({ type: couponConstants.ADD_REQUEST })

  try {
    const coupon = await couponsService.addItem({ value })
    dispatch({ type: couponConstants.ADD_SUCCESS, payload: { ...value, id: coupon.id } })
    history.push(`/coupons/view/${coupon.id}`)
  } catch (error) {
    dispatch({ type: couponConstants.ADD_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: couponConstants.GETBYID_REQUEST })

  try {
    const coupon = await couponsService.getItemById(id)
    dispatch({ type: couponConstants.GETBYID_SUCCESS, payload: coupon.data })
  } catch (error) {
    dispatch({ type: couponConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const deleteItem = id => async dispatch => {
  dispatch({ type: couponConstants.DELETE_REQUEST })
  try {
    await couponsService.deleteItem(id)
    history.push(`/coupons`)
    dispatch({ type: couponConstants.DELETE_SUCCESS, payload: id })
  } catch (error) {
    dispatch({ type: couponConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const updateItemById = (id, value) => async dispatch => {
  dispatch({ type: couponConstants.UPDATE_REQUEST })

  try {
    const updated = await couponsService.updateItemById(id, value)
    dispatch({ type: couponConstants.UPDATE_SUCCESS, payload: updated?.message?.toString() })
    history.push(`/coupons/view/${id}`)
  } catch (error) {
    dispatch({ type: couponConstants.UPDATE_FAILURE, payload: error.toString() })
  }
}

const couponActions = {
  getAll,
  getItemById,
  updateItemById,
  deleteItem,
  addItem,
}

export default couponActions
