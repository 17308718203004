const subscriptionConstants = {
  GETALL_REQUEST: 'SUBSCRIPTION_GETALL_REQUEST',
  GETALL_SUCCESS: 'SUBSCRIPTION_SUCCESS',
  GETALL_FAILURE: 'SUBSCRIPTION_FAILURE',

  ADD_REQUEST: 'SUBSCRIPTION_ADD_REQUEST',
  ADD_SUCCESS: 'SUBSCRIPTION_ADD_SUCCESS',
  ADD_FAILURE: 'SUBSCRIPTION_ADD_FAILURE',

  GETBYID_REQUEST: 'SUBSCRIPTION_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'SUBSCRIPTION_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'SUBSCRIPTION_GETBYID_FAILURE',

  DELETE_REQUEST: 'SUBSCRIPTION_DELETE_REQUEST',
  DELETE_SUCCESS: 'SUBSCRIPTION_DELETE_SUCCESS',
  DELETE_FAILURE: 'SUBSCRIPTION_DELETE_FAILURE',

  UPDATE_REQUEST: 'SUBSCRIPTION_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'SUBSCRIPTION_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'SUBSCRIPTION_UPDATE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    list: { key: 'list', title: 'List Subscription', url: '/subscriptions/list' },
    manage: { key: 'manage', title: 'Manage Subscription', url: '/subscriptions' },
    create: { key: 'create', title: 'Create Subscription', url: '/subscriptions/create' },
    edit: { key: 'edit', title: 'Update Subscription', url: '' },
    delete: { key: 'delete', title: 'Delete Subscription', url: '' },
    view: { key: 'view', title: 'View Subscription', url: '' },
  },

  subscriptionStatuses: [
    { value: 'ACTIVE', text: 'ACTIVE' },
    { value: 'DRAFT', text: 'DRAFT' },
  ],

  subscriptionConfirmationStatuses: [
    { value: '1', text: 'FREE' },
    { value: '0', text: 'NO FREE' },
  ],

  subscriptionVisibility: [
    { value: 'standard', text: 'Standard' },
    { value: 'additional', text: 'Additional' },
    { value: 'hidden', text: 'Hidden' },
  ],

  listItemKeyToLabel: {
    id: 'ID',
    status: 'Status',
    sort: 'Sort',
    name: 'Name',
    visibility: 'Visibility',
    description: 'Description',
    cost_description: 'Cost Description',
    // confirmation_free: 'Delivery Confirmation',
    fee: 'Fee',
    taxable_amount: 'Taxable amount',
    period: 'Period (Days)',
    credit2: 'Credit2',
    is_best_value: 'Is best value',
    color: 'Color',
  },
  viewItemKeyToLabel: {
    id: 'ID',
    status: 'Status',
    sort: 'Sort',
    name: 'Name',
    visibility: 'Visibility',
    description: 'Description',
    // cost_description: 'Cost Description',
    fee: 'Fee',
    taxable_amount: 'Taxable amount',
    period: 'Period (Days)',
    credit2: 'Credit2',
    discount: 'Discount',
    is_best_value: 'Is best value',
    color: 'Color',
    employee_id: 'Employee',
    created_at: 'Created At',
    updated_at: 'Updated At',
  },
  advancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'Status', key: 'status_filter', type: 'select' },
    { label: 'Sort', key: 'sort' },
    { label: 'Name', key: 'name' },
    { label: 'Fee', key: 'fee' },
    { label: 'Taxable amount', key: 'taxable_amount' },
    { label: 'Period (Days)', key: 'period' },
    { label: 'Credit2', key: 'credit2' },
    { label: 'Discount', key: 'discount' },
    { label: 'Employee', key: 'employee_id' },
    { label: 'Created At', key: 'created_at*date' },
    { label: 'Updated At', key: 'updated_at*date' },
  ],
  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },
}
export default subscriptionConstants
