import { inchToPx, pxToInch } from './convert'

const pxToRoundInch = num => +pxToInch(num).toFixed(3)

const convertInchCoordinates = configsData => {
  const convertedConfigsData = configsData.map(data => {
    const { config } = data
    let elementsRotate = 0

    if (config.front.length) {
      elementsRotate = Math.abs(config.front[0].rotate) // convert rotate from backend (backend -90deg === frontend 90deg)
    }

    const updatedConfig = {
      ...config,
      front: config.front.map(card => ({
        ...card,
        x: inchToPx(card.x),
        y: inchToPx(card.y),
        rotate: Math.abs(card.rotate), // convert rotate from backend (backend -90deg === frontend 90deg)
      })),
      back: config.back.map(card => ({
        ...card,
        x: inchToPx(card.x),
        y: inchToPx(card.y),
        rotate: Math.abs(card.rotate), // convert rotate from backend (backend -90deg === frontend 90deg)
      })),
      barcode: {
        ...config.barcode,
        x: inchToPx(config.barcode.x),
        y: inchToPx(config.barcode.y),
        rotate: Math.abs(config.barcode.rotate), // convert rotate from backend (backend -90deg === frontend 90deg)
      },
      card_rotate: elementsRotate,
    }

    return {
      ...data,
      config: updatedConfig,
    }
  })

  return convertedConfigsData
}

const generateBackPage = (config, pageSize, cardSize) => {
  config.back = config.front.map(element => {
    const cardWidthAfterRotate = element.rotate ? cardSize.height : cardSize.width

    // back card position - is mirror X position of the front card
    const mirrorXPositionForCards = pageSize.width - element.x - cardWidthAfterRotate
    const mirrorXPositionForCode = pageSize.width - element.x - element.width

    return {
      ...element,
      x: element.type === 'code39' ? mirrorXPositionForCode : mirrorXPositionForCards,
    }
  })

  return config
}

// convert values according to backend requirements (px to inch, counterclockwise rotation)
// and add back elements
const convertConfigBeforeSave = (config, pageSize, cardSize) => {
  const pageBarcode = config.barcode
  const frontElements = config.front

  const isRepeatMode = frontElements.some(elem => elem.type === 'code39')

  const updatedFrontElements = frontElements.map((element, index) => ({
    ...element,
    id: isRepeatMode ? element.section_id + 1 : index + 1,
    rotate: element.rotate ? -element.rotate : 0, // convert rotate according to backend requirements
    x: pxToRoundInch(element.x),
    y: pxToRoundInch(element.y),
  }))

  const configWithInchCoordinates = {
    ...config,
    front: updatedFrontElements,
    barcode: {
      ...pageBarcode,
      y: pxToRoundInch(pageBarcode.y),
      x: pxToRoundInch(pageBarcode.x),
      rotate: pageBarcode.rotate ? -pageBarcode.rotate : 0, // convert rotate according to backend requirements
    },
    card_per_page: isRepeatMode ? frontElements.length / 2 : frontElements.length,
  }

  // delete temporary data
  delete configWithInchCoordinates.card_rotate

  const configWithBackPage = generateBackPage(configWithInchCoordinates, pageSize, cardSize)

  return configWithBackPage
}

const getInitialRepeatCardsPositions = (paperWidth, sectionsCount, barcodePosition) => {
  const newCardPositions = []
  const sectionWidth = paperWidth / sectionsCount

  for (let xPosition = 0; xPosition < paperWidth; xPosition += sectionWidth) {
    let position = xPosition
    if (barcodePosition === 'left') {
      position += 0.5 // add barcode width if needed
    }
    newCardPositions.push({ x: inchToPx(position), y: 0 })
  }

  return newCardPositions
}

// y of the additional barcode equal pageBarcode.x
// x of the additional barcode is mirror pageBarcode.y
const getAdditionalBarcodePosition = (sectionId, sectionWidth, pageBarcode) => {
  const endOfSection = sectionWidth * (sectionId + 1)
  const xPosition = endOfSection - inchToPx(pageBarcode.width) - pageBarcode.y

  return {
    x: xPosition,
    y: pageBarcode.x,
  }
}

const createAdditionalBarcodes = (paperWidth, sectionsCount, pageBarcode) => {
  const barcodes = []
  const sectionWidth = inchToPx(paperWidth / sectionsCount)

  for (let index = 0; index < sectionsCount; index++) {
    const { x, y } = getAdditionalBarcodePosition(index, sectionWidth, pageBarcode)

    barcodes.push({
      id: `${Date.now()}_${index}_barcode`,
      x,
      y,
      rotate: 0,
      width: pageBarcode.width,
      height: pageBarcode.height,
      type: 'code39',
      section_id: index,
    })
  }

  return barcodes
}

const getRepeatedCardPosition = (sectionId, sectionWidth, sampleCard) => {
  const startOfSection = sectionWidth * sectionId
  const xPosition = startOfSection + sampleCard.x

  return {
    x: xPosition,
    y: sampleCard.y,
  }
}

const impositionHelpers = {
  convertInchCoordinates,
  convertConfigBeforeSave,
  getInitialRepeatCardsPositions,
  createAdditionalBarcodes,
  getAdditionalBarcodePosition,
  getRepeatedCardPosition,
}

export default impositionHelpers
