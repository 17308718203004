import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import v4 from 'uuid/v4'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import { queryParamsActions } from '../../../../_actions'
import { ordersConstants, automationsConstants } from '../../../../_constants'
import automationActions from '_actions/automations.actions'
import {
  getOrderPage,
  getOrderRows,
  getOrderTotalItems,
} from '../../../../_selectors/automations.selector.js'

const { orderStatuses } = ordersConstants
const { navMenu } = automationsConstants
const navMenuInit = [navMenu.manage, navMenu.view]

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    width: '100px',
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user=>login',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Card',
    dataIndex: 'card',
    key: 'card=>name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Date Created',
    dataIndex: 'date_created',
    key: 'date_created*date',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Date Send',
    dataIndex: 'date_send',
    key: 'date_send*date',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status_filter',
    filters: orderStatuses,
    filteredValue: ['paid'],
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
]

const ManageItems = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()

  const [navMenuParse, setNavMenuParse] = useState([])
  const [render, setRender] = useState(false)

  const rows = useSelector(getOrderRows)
  const page = useSelector(getOrderPage)
  const total = useSelector(getOrderTotalItems)

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        user: <Link to={`/clients/edit/${item.user.id}`}> {item.user.name} </Link>,
        card: item.card ? <Link to={`/cards/edit/${item.card.id}`}> {item.card.name} </Link> : '',
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  const navigateToManageAutomations = () => {
    dispatch(queryParamsActions.clearQueryParams())
    history.push({
      pathname: `/automations`,
    })
  }

  const navigateToViewAutomation = () => {
    dispatch(queryParamsActions.clearQueryParams())
    history.push({
      pathname: `/automations/view/${params.automation_id}`,
    })
  }

  useLayoutEffect(() => {
    if (params) {
      dispatch(queryParamsActions.setQueryParams({ id: params?.ids }))
      setRender(true)
    }
  }, [params])

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.manage.key:
          return {
            ...menu,
            url: navigateToManageAutomations,
          }
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToViewAutomation,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  useEffect(() => {
    return () => {
      dispatch(queryParamsActions.clearQueryParams())
    }
  }, [])

  if (!render) return null

  return (
    <NavContainer menu={navMenuParse}>
      <TableFilter
        columns={initColumns}
        data={dataSource}
        getDataAction={automationActions.getAutomationOrders}
        actionsColum={false}
        itemBasePath="/orders"
      />
    </NavContainer>
  )
}

export default ManageItems
