import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { Button, DatePicker, Form, Input, InputNumber, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import convertFormValuesDeep from '_helpers/hw-object-utils'
import NavContainer from '../../../../components/nav-container'
import { couponActions } from '../../../../_actions'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { couponConstants } from '../../../../_constants'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'

const { formItemLayout, navMenu } = couponConstants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.view, navMenu.delete, navMenu.manage]

const EditItem = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { id } = useParams()

  const [navMenuParse, setNavMenuParse] = useState([])
  const [changed, setChange] = useState(false)
  const {
    item: { coupon },
  } = useSelector(state => state.coupons)

  const view = getCurrentUrl(match.url)

  useEffect(() => {
    dispatch(couponActions.getItemById(id))
    return () => {
      dispatch({ type: couponConstants.SETBYID })
    }
  }, [id, dispatch])

  const navigateToView = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(couponActions.deleteItem(id))
      },
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToView,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  useEffect(() => {
    form.setFieldsValue({
      ...coupon,
      expiration_date: coupon?.expiration_date ? dayjs(coupon.expiration_date) : null,
    })
  }, [coupon, form])

  const onFinish = async values => {
    const mergedCoupon = {
      ...values,
      id: coupon.id,
      expiration_date: `${values.expiration_date.format('YYYY-MM-DD')} 00:00:00`,
    }
    const convertedCoupon = convertFormValuesDeep(mergedCoupon)
    await dispatch(couponActions.updateItemById(coupon.id, convertedCoupon))
  }

  return (
    <NavContainer menu={navMenuParse}>
      {useGlobalLoader(
        <div>
          <p>Fields with * are required.</p>
          <Form
            {...formItemLayout}
            name="basic"
            form={form}
            onFinish={onFinish}
            onFieldsChange={() => setChange(true)}
            scrollToFirstError
          >
            <Form.Item
              label="Coupon name"
              name="code"
              rules={[
                {
                  required: true,
                  message: 'Please input name!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Coupon value"
              name="credit"
              rules={[
                {
                  required: true,
                  message: 'Please input value!',
                },
              ]}
            >
              <InputNumber step={1} />
            </Form.Item>

            <Form.Item
              label="Expiration Date"
              name="expiration_date"
              rules={[
                {
                  required: true,
                  message: 'Please select date!',
                },
              ]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!changed}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>,
      )}
    </NavContainer>
  )
}

export default EditItem
