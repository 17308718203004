import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { Button, Form, Input, InputNumber, Modal, Select } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import html2canvas from 'html2canvas'
import { measureHeight } from '_helpers'
import NavContainer from '../../../../components/nav-container'
import { signatureActions } from '../../../../_actions'
import { signatureConstants, spinnerConstants } from '../../../../_constants'
import getCurrentUrl from '../../../../_helpers/url-utils'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import useClientsToOptionsList from 'hooks/clients/useClientsToOptionsList'

const { formItemLayout, navMenu } = signatureConstants

const navMenuInit = [navMenu.create, navMenu.view, navMenu.manage, navMenu.upload]

const EditItem = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const view = getCurrentUrl(match.url)

  const [form] = Form.useForm()
  const { id } = useParams()
  const refSign = useRef()

  const [navMenuParse, setNavMenuParse] = useState([])
  const [signaturePreview, setSignaturePreview] = useState('')
  const [changed, setChange] = useState(false)
  const {
    item: { signature },
  } = useSelector(state => state.signatures)

  const clientsListOptions = useClientsToOptionsList()

  const croppedSignImage = async (signaturePreview) => {
    const res = await html2canvas(refSign.current).then(canvas => {
      const font = '166px HWSign'
      const croppedCanvas = document.createElement('canvas')
      const ctx = croppedCanvas.getContext('2d')
      const ctxCanvas = canvas.getContext('2d')
      const measure = measureHeight(font, 166, signaturePreview, {
        canAndCtx: { can: canvas, ctx: ctxCanvas },
      })

      croppedCanvas.width = measure.width + 40
      croppedCanvas.height = measure.height + 40

      ctx.clearRect(0, 0, croppedCanvas.width, croppedCanvas.height)
      ctx.fillStyle = '#0040ac'
      ctx.font = font
      ctx.textBaseline = 'alphabetic'
      const yBaseline = -measure.relativeTop + 20
      ctx.fillText(signaturePreview, 20, yBaseline)
      const imgData2 = croppedCanvas.toDataURL('img/png')
      return imgData2
    })
    return res
  }

  const onFinish = async values => {
    const res = await croppedSignImage(signaturePreview, refSign)
    dispatch({ type: spinnerConstants.START })
    const newItem = {
      ...values,
      file: {
        name: `${values.name}.png`,
        thumbUrl: res,
      },
      size_offset: values.size_offset === null ? 0 : values.size_offset,
    }
    dispatch(signatureActions.updateItemById(id, convertFormValuesDeep(newItem)))
  }

  const navigateToView = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  useEffect(() => {
    if (signature) setSignaturePreview(signature.code)
  }, [signature])

  useEffect(() => {
    dispatch(signatureActions.getItemById(id))
    return () => {
      dispatch({ type: signatureConstants.SETBYID })
    }
  }, [id, dispatch])

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(signatureActions.deleteItem(id))
      },
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToView,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  useEffect(() => {
    form.setFieldsValue({ ...signature })
  }, [signature, form])

  return (
    <NavContainer menu={navMenuParse}>
      {useGlobalLoader(
        <div>
          <p>Fields with * are required.</p>
          <Form
            {...formItemLayout}
            name="basic"
            form={form}
            onFinish={onFinish}
            onFieldsChange={() => setChange(true)}
            scrollToFirstError
          >
            <Form.Item
              label="User"
              name="user_id"
              rules={[
                {
                  required: true,
                  message: 'Please input User!',
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Select or search a user"
                filterOption={(input, option) => option.label.includes(input.toString())}
                options={clientsListOptions}
              />
            </Form.Item>

            <Form.Item label="Name" name="name">
              <Input />
            </Form.Item>

            <Form.Item
              label="Code"
              name="code"
              rules={[
                {
                  required: true,
                  message: 'Please input code!',
                },
              ]}
            >
              <Input onChange={event => setSignaturePreview(event.target.value)} />
            </Form.Item>
            <Form.Item label="Size Offset (percent)" name="size_offset">
              <InputNumber step={1} min={-99} max={99} defaultValue={0} />
            </Form.Item>
            <span
              ref={refSign}
              style={{
                fontFamily: 'HWSign',
                fontSize: 166,
                color: '#0040ac',
              }}
            >
              {signaturePreview}
            </span>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!changed}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>,
      )}
    </NavContainer>
  )
}

export default EditItem
