import { giftCardsConstants } from '../_constants'
import { giftCardService } from '../_services'
import { history } from '../_helpers'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: giftCardsConstants.GETALL_REQUEST })
  try {
    const giftCards = await giftCardService.getAll(params, typeAction)
    dispatch({ type: giftCardsConstants.GETALL_SUCCESS, payload: giftCards.data })
  } catch (error) {
    dispatch({ type: giftCardsConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const addItem = value => async dispatch => {
  dispatch({ type: giftCardsConstants.ADD_REQUEST })

  try {
    const giftCard = await giftCardService.addItem({ value })
    dispatch({ type: giftCardsConstants.ADD_SUCCESS, payload: { ...value, id: giftCard.id } })
    history.push(`/gift-cards/view/${giftCard.id}`)
  } catch (error) {
    dispatch({ type: giftCardsConstants.ADD_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: giftCardsConstants.GETBYID_REQUEST })

  try {
    const giftCard = await giftCardService.getItemById(id)
    dispatch({ type: giftCardsConstants.GETBYID_SUCCESS, payload: giftCard.data })
  } catch (error) {
    dispatch({ type: giftCardsConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const deleteItem = id => async dispatch => {
  dispatch({ type: giftCardsConstants.DELETE_REQUEST })
  try {
    await giftCardService.deleteItem(id)
    history.push(`/gift-cards`)
    dispatch({ type: giftCardsConstants.DELETE_SUCCESS, payload: id })
  } catch (error) {
    dispatch({ type: giftCardsConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const updateItemById = (id, value) => async dispatch => {
  dispatch({ type: giftCardsConstants.UPDATE_REQUEST })

  try {
    const updated = await giftCardService.updateItemById(id, value)
    dispatch({ type: giftCardsConstants.UPDATE_SUCCESS, payload: updated?.message?.toString() })
    history.push(`/gift-cards/view/${id}`)
  } catch (error) {
    dispatch({ type: giftCardsConstants.UPDATE_FAILURE, payload: error.toString() })
  }
}

const giftCardActions = {
  getAll,
  getItemById,
  updateItemById,
  deleteItem,
  addItem,
}

export default giftCardActions
