import React, { useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Button, Space, Tooltip, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
  CheckOutlined,
  CodeSandboxOutlined,
  DropboxOutlined,
  PlusOutlined,
  PrinterOutlined,
  StopOutlined,
} from '@ant-design/icons'

import TableFilter from 'components/table-filter'
import LabelsCreationHeader from './labels-creation.header'
import GenerateLabelModal from './generate-labels.modal'
import { labelsCreationActions } from '_actions'
import { labelsCreationConstants, spinnerConstants } from '_constants'

import './style.scss'

const { remainingAdvancedSearch } = labelsCreationConstants

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    fixed: 'left',
  },
  {
    title: 'Card',
    dataIndex: 'card',
    key: 'card=>name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Count',
    dataIndex: 'cnt',
    key: 'cnt',
    hideFilter: true,
  },
  {
    title: 'Date Send',
    dataIndex: 'date_send',
    key: 'date_send*date',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    hideFilter: true,
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user=>login',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Packages',
    dataIndex: 'packages',
    key: 'packages',
    hideFilter: true,
    children: [
      {
        title: (
          <Tooltip title="Generated">
            <CodeSandboxOutlined />
          </Tooltip>
        ),
        dataIndex: 'generated_packages',
        key: 'generated_packages',
        render: value => <Tooltip title="Generated">{value}</Tooltip>,
      },
      {
        title: (
          <Tooltip title="Recommended">
            <DropboxOutlined />
          </Tooltip>
        ),
        dataIndex: 'recommended_packages',
        key: 'recommended_packages',
        render: value => <Tooltip title="Recommended">{value}</Tooltip>,
      },
    ],
  },
]

const ReadyToShippingList = () => {
  const dispatch = useDispatch()
  const { rows, total, can_print_orders } = useSelector(
    state => state.labelsCreation.readyToShipping,
  )

  const [currentId, setCurrentId] = useState(null)
  const [generateLabelsModalActive, setGenerateLabelsModalActive] = useState(false)
  const [selectedOrders, setSelectedOrders] = useState([])

  const currentOrder = useMemo(() => rows.find(row => row.id === currentId), [rows, currentId])

  const initialFormValues = useMemo(() => {
    if (!currentOrder) return null

    return {
      service_name: 'UPS',
      service_code: currentOrder.service.code,
      recommended_packages: currentOrder.recommended_packages,
    }
  }, [currentOrder])

  const disablePrintAndCancel = useMemo(() => {
    // disable print selected and cancel selected if some of the selected orders cannot be printed
    return selectedOrders.some(selectedId => !can_print_orders.includes(selectedId))
  }, [selectedOrders, can_print_orders])

  const generateLabelsHandler = id => {
    setCurrentId(id)
    setGenerateLabelsModalActive(true)
  }

  const printLabelsHandler = async ids => {
    dispatch({ type: spinnerConstants.START_ON_MANAGE_PAGE })
    await dispatch(labelsCreationActions.printLabelsV2({ order_ids: ids }))
    dispatch({ type: spinnerConstants.STOP_ON_MANAGE_PAGE })
  }

  const completeLabelsHandler = async ids => {
    dispatch({ type: spinnerConstants.START_ON_MANAGE_PAGE })
    await dispatch(labelsCreationActions.completeLabelsV2({ order_ids: ids }))
    await dispatch(labelsCreationActions.statusLabelsV2())
    dispatch({ type: spinnerConstants.STOP_ON_MANAGE_PAGE })
  }

  const cancelLabelsHandler = async ids => {
    dispatch({ type: spinnerConstants.START_ON_MANAGE_PAGE })
    await dispatch(labelsCreationActions.cancelLabelsV2({ order_ids: ids }))
    await dispatch(labelsCreationActions.statusLabelsV2())
    dispatch({ type: spinnerConstants.STOP_ON_MANAGE_PAGE })
  }

  const getRowAdditionalInfo = row => {
    const { name, business_name, address1, address2, city, state, zip, country } = row.address

    const convertedAddress = `${name}, ${business_name ? `${business_name},` : ''} ${address1}, ${
      address2 ? `${address2},` : ''
    } ${city ? `${city},` : ''} ${state ? `${state},` : ''} ${zip} ${country ? `, ${country}` : ''}`

    return (
      <Space direction="vertical">
        <Space align="start">
          <Typography.Text strong>Address:</Typography.Text>
          <Typography.Text style={{ whiteSpace: 'break-spaces' }}>
            {convertedAddress}
          </Typography.Text>
        </Space>
        {row.error_message ? (
          <Space style={{ color: 'red' }}>
            <Typography.Text strong type="danger">
              Error:
            </Typography.Text>
            <Typography.Text type="danger">{row.error_message}</Typography.Text>
          </Space>
        ) : null}
      </Space>
    )
  }

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        user: item.user ? (
          <Link to={`/clients/edit/${item.user.id}`}> {item.user.email} </Link>
        ) : (
          '-'
        ),
        card: item.card ? <Link to={`/cards/edit/${item.card.id}`}> {item.card.name} </Link> : '-',
        recommended_packages: item.recommended_packages.length,
        generated_packages: item.generated_packages ? item.generated_packages.length : '-',
        key: item.id,
        expandableContent: getRowAdditionalInfo(item),
      })),
      total,
      page: 1,
    }
  }, [rows, total])

  const rowActions = row => (
    <Space size="small">
      <Tooltip title="Generate Label" placement="top">
        <Button
          style={{ color: '#595c97' }}
          size="small"
          shape="square"
          icon={<PlusOutlined />}
          onClick={() => generateLabelsHandler(row.id)}
        />
      </Tooltip>

      <Tooltip title="Print Label" placement="top">
        <Button
          style={{ color: row.can_print ? '#595c97' : 'grey' }}
          size="small"
          shape="square"
          icon={<PrinterOutlined />}
          onClick={() => printLabelsHandler([row.id])}
          disabled={!row.can_print}
        />
      </Tooltip>

      <Tooltip title="Complete" placement="top">
        <Button
          style={{ color: 'green' }}
          size="small"
          shape="square"
          icon={<CheckOutlined />}
          onClick={() => completeLabelsHandler([row.id])}
        />
      </Tooltip>

      <Tooltip title="Cancel" placement="top">
        <Button
          style={{ color: row.can_cancel ? 'red' : 'grey' }}
          size="small"
          shape="square"
          icon={<StopOutlined />}
          onClick={() => cancelLabelsHandler([row.id])}
          disabled={!row.can_cancel}
        />
      </Tooltip>
    </Space>
  )

  return (
    <Space className="ready-to-shipping" direction="vertical" style={{ width: '100%' }}>
      <GenerateLabelModal
        orderId={currentId}
        currentOrder={currentOrder}
        setCurrentId={setCurrentId}
        isVisible={generateLabelsModalActive}
        initialValues={initialFormValues}
        onOk={() => setGenerateLabelsModalActive(false)}
        onCancel={() => setGenerateLabelsModalActive(false)}
      />
      <LabelsCreationHeader
        selectedOrders={selectedOrders}
        onPrintLabels={printLabelsHandler}
        onCompleteLabels={completeLabelsHandler}
        onCancelLabels={cancelLabelsHandler}
        resetSelected={() => setSelectedOrders([])}
        disablePrintAndCancel={disablePrintAndCancel}
      />
      <TableFilter
        columns={initColumns}
        data={dataSource}
        getDataAction={labelsCreationActions.readyToShipping}
        advancedSearch={remainingAdvancedSearch}
        customActions={rowActions}
        isEditOnId={false}
        doubleClick={false}
        expandableTable
        fixedActions
        noLimit
        rowSelection={{
          type: 'checkbox',
          renderCell: (checked, record, index, node) => {
            const { card, shipment_info } = record
            return (
              <div>
                {shipment_info ? (
                  <div>
                    {node}
                    {card.name}
                    <Tooltip title={shipment_info.tooltip}>
                      <img
                        src={shipment_info.img}
                        alt={shipment_info.alt}
                        style={{ width: '2rem', marginLeft: '0.5rem' }}
                      />
                    </Tooltip>
                  </div>
                ) : (
                  node
                )}
              </div>
            )
          },
          onChange: selectedRowKeys => {
            setSelectedOrders(selectedRowKeys)
          },
          selectedRowKeys: selectedOrders,
        }}
      />
    </Space>
  )
}

export default ReadyToShippingList
