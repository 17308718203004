import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { Button, Divider, Form, InputNumber, Modal, Select, Tooltip, Upload } from 'antd'
import {
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import { presetCardImagesActions } from '../../../../_actions'
import { appConstants, presetCardImagesConstants } from '../../../../_constants'
import { convertDataForSelect, parseFileForSetFields } from '../../../../_helpers'
import getCurrentUrl from '../../../../_helpers/url-utils'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import { getCardImage, getCardImageDimensionList } from '_selectors/preset-card-images.selector'
import AdvancedCheckboxList from 'components/advanced-checkbox-list'

const {
  formItemLayout,
  navMenu,
  cardImageType,
  presetCardImageStatuses,
  sortNoTooltipMessage,
} = presetCardImagesConstants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.view, navMenu.delete, navMenu.manage]

const EditItem = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const view = getCurrentUrl(match.url)
  const [form] = Form.useForm()
  const params = useParams()

  const { id } = params

  const card_image = useSelector(getCardImage)
  const dimensionList = useSelector(getCardImageDimensionList)

  const [navMenuParse, setNavMenuParse] = useState([])
  const [cardImage, setCardImage] = useState(null)
  const [changed, setChange] = useState(false)

  useEffect(() => {
    dispatch(presetCardImagesActions.getItemById(id))
  }, [dispatch, id])

  useEffect(() => {
    return () => {
      dispatch({ type: presetCardImagesConstants.SETBYID })
    }
  }, [])

  const statuses = useMemo(
    () =>
      presetCardImageStatuses.map(status => ({
        value: parseInt(status.value, 10),
        label: status.text,
      })),
    [presetCardImageStatuses],
  )

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(presetCardImagesActions.deleteItem(id))
      },
    })
  }

  const onViewItem = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }
  const onCreateItem = () => {
    history.push(`${view}/create`)
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: onViewItem,
          }
        case navMenu.create.key:
          return {
            ...menu,
            url: onCreateItem,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit, card_image])

  const getImageName = url => {
    const name = url.split('/')
    return name[name.length - 1]
  }

  useEffect(() => {
    if (card_image) {
      form.setFieldsValue({
        ...card_image,
        file: parseFileForSetFields(getImageName(card_image.url), {
          url: `${appConstants.IMAGES_URL}/${card_image.url}`,
        }),
        dimension: card_image.dimensions.map(dimension => dimension.id),
      })
      setCardImage(card_image.url)
    }
  }, [card_image, form])

  const normFile = e => {
    let file = e && e.fileList

    if (Array.isArray(e)) file = e

    const reader = new FileReader()
    reader.onload = () => setCardImage(reader.result)
    reader.readAsDataURL(file[0].originFileObj)

    return file
  }

  const onFinish = async ({ type, sort_no, status, file, dimension }) => {
    const image = file.length ? file[0] : undefined

    const newItem = {
      file: {
        name: image?.name || card_image.image,
      },
      type,
      sort_no,
      status,
      dimension,
    }

    if (image?.name !== getImageName(card_image.url)) {
      newItem.file.thumbUrl = cardImage
      newItem.file.size = image.size
    }

    if (!newItem.file?.thumbUrl) newItem.file = null

    await dispatch(presetCardImagesActions.updateItemById(id, convertFormValuesDeep(newItem)))
  }

  const props = {
    showUploadList: {
      showRemoveIcon: false,
    },
  }

  const dimensions = useMemo(() => {
    return dimensionList?.map(e => ({ value: e.id, label: e.name }))
  }, [dimensionList])

  return (
    <NavContainer menu={navMenuParse}>
      {useGlobalLoader(
        <div>
          <p>Fields with * are required.</p>
          <Form
            {...formItemLayout}
            name="basic"
            form={form}
            onFinish={onFinish}
            scrollToFirstError
            onFieldsChange={() => setChange(true)}
          >
            <Form.Item
              label="Status"
              name="status"
              initialValue={1}
              rules={[
                {
                  required: true,
                  message: 'Please select Status!',
                },
              ]}
            >
              <Select options={statuses} />
            </Form.Item>

            <Form.Item
              name="type"
              label="Type"
              initialValue=""
              rules={[
                {
                  required: true,
                  message: 'Please select Type!',
                },
              ]}
            >
              <Select style={{ width: '140px' }} options={convertDataForSelect(cardImageType)} />
            </Form.Item>

            <Form.Item
              label={
                <>
                  Sort No
                  <Tooltip title={sortNoTooltipMessage} placement="top">
                    <QuestionCircleOutlined style={{ marginLeft: '0.2rem' }} />
                  </Tooltip>
                </>
              }
              name="sort_no"
              initialValue={0}
              rules={[
                {
                  required: true,
                  message: 'Please fill the field!',
                },
              ]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label="Image"
              name="file"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: true,
                  message: 'Please input Image!',
                },
              ]}
            >
              <Upload
                {...props}
                name="icon"
                maxCount={1}
                accept="image/png, image/jpeg"
                beforeUpload={() => false}
                action=""
                listType="picture"
              >
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>

            <Divider orientation="left">Dimension</Divider>
            <div>
              <AdvancedCheckboxList
                formItemProps={{
                  name: 'dimension',
                  valuePropName: 'value',
                  rules: [
                    {
                      required: true,
                      message: 'Please select Dimension!',
                    },
                  ],
                }}
                buttonText="Add Dimension"
                options={dimensions}
                form={form}
                onChangeValues={() => setChange(true)}
                initialValues={dimensionList?.filter(ca => ca.value).map(fca => fca.id)}
              />
            </div>

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!changed}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>,
      )}
    </NavContainer>
  )
}

export default EditItem
