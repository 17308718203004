import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import ItemViewer from '../../../../components/item-viewer'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { appConstants, presetCardImagesConstants } from '../../../../_constants'
import presetCardImagesActions from '_actions/preset-card-images.actions'
import { getCardImage } from '_selectors/preset-card-images.selector'

const {
  navMenu,
  viewItemKeyToLabel,
  renderComponent,
  presetCardImageStatuses,
} = presetCardImagesConstants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.edit, navMenu.delete, navMenu.manage]

const ViewItem = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const view = getCurrentUrl(match.url)
  const { id } = useParams()

  const [navMenuParse, setNavMenuParse] = useState([])

  const card_image = useSelector(getCardImage)

  useEffect(() => {
    dispatch(presetCardImagesActions.getItemById(id))
    return () => {
      dispatch({ type: presetCardImagesConstants.SETBYID })
    }
  }, [id])

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(presetCardImagesActions.deleteItem(id))
      },
    })
  }

  const onEditItem = () => {
    history.push({
      pathname: `${view}/edit/${id}`,
    })
  }

  const onCreateItem = () => {
    history.push({
      pathname: `${view}/create`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.edit.key:
          return {
            ...menu,
            url: onEditItem,
          }
        case navMenu.create.key:
          return {
            ...menu,
            url: onCreateItem,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit, card_image])

  const viewItem = useMemo(() => {
    if (card_image) {
      return Object.keys(viewItemKeyToLabel).reduce((obj, sKey) => {
        let newItem
        if (sKey === 'url') {
          newItem = renderComponent.image(
            `${appConstants.IMAGES_URL}/${card_image.url}`,
            card_image.url,
          )
        } else if (sKey === 'sort_no') {
          newItem = `${card_image.sort_no}`
        } else if (sKey === 'status') {
          newItem = `${
            presetCardImageStatuses.find(st => st.value.toString() === card_image.status.toString())
              ?.text
          }`
        } else if (sKey === 'dimension') {
          newItem = card_image.dimensions.map(dimension => dimension.name).join(', ')
        } else {
          newItem = card_image[sKey] || '----'
        }
        return {
          ...obj,
          [sKey]: newItem,
        }
      }, {})
    }
    return {}
  }, [card_image])

  return (
    <NavContainer menu={navMenuParse}>
      <ItemViewer item={viewItem} keyToLabelMap={viewItemKeyToLabel} />
    </NavContainer>
  )
}

export default ViewItem
