const alertConstants = {
  SUCCESS: 'ALERT_SUCCESS',
  ERROR: 'ALERT_ERROR',
  CLEAR: 'ALERT_CLEAR',
  STATUS: 'ALERT_STATUS',
  MODAL: 'ALERT_MODAL',
  COMPLETE_MODAL: 'ALERT_COMPLETE_MODAL',
  CLEAR_COMPLETE_MODAL: 'ALERT_CLEAR_COMPLETE_MODAL',
  STATUS_CLEAR: 'ALERT_STATUS_CLEAR',
  TYPE_RESPONSE: 'TYPE_RESPONSE',
}
export default alertConstants
