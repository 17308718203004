import { couponConstants } from '../_constants'

const initialState = {
  items: [],
  item: {},
}

function coupons(state = initialState, { type, payload }) {
  switch (type) {
    case couponConstants.GETALL_SUCCESS:
      return { ...state, items: payload, item: {} }
    case couponConstants.GETBYID_SUCCESS:
      return { ...state, item: payload }
    case couponConstants.DELETE_SUCCESS:
      return { ...state }
    case couponConstants.UPDATE_SUCCESS:
      return { ...state, items: state.items }
    case couponConstants.ADD_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          total: state.items.total + 1,
        },
      }
    case couponConstants.SETBYID:
      return {
        ...state,
        item: {},
      }
    case couponConstants.SETALL:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default coupons
