import generateAdvancedStr from './generate-advanced-string'
import { appConstants } from '../_constants'

const generateParamsAdvancedSearch = (values, match) => {
  const keys = Object.keys(values)
  const params = keys.reduce(
    (acc, cur) => {
      if (!values[cur] && !(typeof values[cur] === 'number')) return acc
      const indexOperator = appConstants.ADVANCED_SEARCH.findIndex(operator =>
        values[cur][0].toString().includes(operator),
      )
      if (indexOperator !== -1) {
        generateAdvancedStr(values[cur][0], cur, acc, match)
        return acc
      }
      if (cur === 'id' || cur.includes('_id')) {
        let newParam = {}
        if (match === '/orders' && cur === 'gcard_id') {
          newParam = {
            ...acc.where,
            denomination: `gcard_id,${values[cur]}`,
          }
        } else {
          newParam = {
            ...acc.where,
            [cur]: values[cur],
          }
        }
        acc.where = newParam
        return acc
      }
      if (cur === 'address' && match === '/orders') {
        const newParam = {
          ...acc.where,
          address: `name,${values[cur]}`,
        }
        acc.where = newParam
        return acc
      }
      if (cur.includes('*date')) {
        const param = cur.split('*date')
        const newParam = {
          ...acc.date,
          [param[0]]: values[cur],
        }
        acc.date = newParam
        return acc
      }
      if (cur.includes('_filter')) {
        const parseParam = cur.split('_filter')
        const newParam = {
          ...acc.where,
          [parseParam[0]]: values[cur],
        }
        acc.where = newParam
        return acc
      }
      const newParam = {
        ...acc.like,
        [cur]: values[cur],
      }
      acc.like = newParam
      return acc
    },
    { like: {}, where: {}, date: {}, order: {} },
  )

  return params
}

export default generateParamsAdvancedSearch
