import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import ItemViewer from '../../../../components/item-viewer'
import {
  clientGroupActions,
  clientsActions,
  queryParamsActions,
  clientNotesActions,
} from '../../../../_actions'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { clientsConstants, notesConstants } from '../../../../_constants'
import { getClientGroupsRows } from '../../../../_selectors/client-group.selector.js'

const { viewItemKeyToLabel, clientStatuses, navMenu } = clientsConstants

const navMenuInit = [
  navMenu.edit,
  navMenu.delete,
  navMenu.manage,
  navMenu.orders,
  navMenu.activity,
  navMenu.listMails,
  navMenu.clientNotes,
  navMenu.inserts,
  navMenu.signatures,
  navMenu.prePayCredits,
  navMenu.prePayCreditsType3,
  navMenu.apiKeys,
  navMenu.automations,
  navMenu.resetPassword,
]

const ViewItem = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const match = useRouteMatch()
  const { id } = useParams()

  const {
    item: { client, employees },
  } = useSelector(state => state.clients)

  const clientGroups = useSelector(getClientGroupsRows)

  const [navMenuParse, setNavMenuParse] = useState([])
  const view = getCurrentUrl(match.url)

  const countOfItems = useMemo(() => {
    return {
      clientNotesCount: client?.total_user_notes,
      // totalApiKeys: client?.total_api_keys,
      totalInserts: client?.total_inserts,
      totalOrders: client?.total_orders,
      totalSignatures: client?.total_signatures,
      totalCreditCards: client?.total_credit_cards,
      totalCards: client?.total_cards,
      totalAutomations: client?.total_automations,
    }
  }, [client])

  const onEditItem = () => {
    history.push({
      pathname: `${view}/edit/${id}`,
    })
  }

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(clientsActions.deleteItem(id))
      },
    })
  }

  const resetPassword = () => {
    Modal.confirm({
      title: 'Reset Password',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to reset password for this user?',
      okText: 'Reset',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      // onOk: () => {
      //  TODO service to reset password for client
      // },
    })
  }

  const onActivitiesItem = () => {
    history.push({
      pathname: `${view}/activities/${id}`,
    })
  }

  const navigateToprePayCredits = () => {
    dispatch(queryParamsActions.setQueryParams({ user_id: id }))
    history.push({
      pathname: `/credit_cards2`,
    })
  }

  const navigateToPrepaidCreditsType3 = () => {
    dispatch(queryParamsActions.setQueryParams({ user_id: id }))
    history.push({
      pathname: `/credit_type3`,
    })
  }

  const navigateToApiKeys = () => {
    dispatch(queryParamsActions.setQueryParams({ user_id: id }))
    history.push({
      pathname: `${view}/api-keys/${id}`,
    })
  }

  const navigateToOrders = () => {
    dispatch(queryParamsActions.setQueryParams({ user_id: id }))
    history.push({
      pathname: '/orders',
    })
  }

  const navigateToMail = () => {
    dispatch(queryParamsActions.setQueryParams({ user_id: id }))
    history.push({
      pathname: `${view}/client-mails/${id}`,
    })
  }

  const navigateToNotes = () => {
    dispatch(queryParamsActions.setQueryParams({ user_id: id }))
    history.push({
      pathname: `/client-notes`,
    })
  }

  const navigateToSignatures = () => {
    dispatch(queryParamsActions.setQueryParams({ user_id: id }))
    history.push({
      pathname: '/signatures',
    })
  }

  const navigateToAutomations = () => {
    dispatch(queryParamsActions.setQueryParams({ user_id: id }))
    history.push({
      pathname: `/automations`,
    })
  }

  useEffect(() => {
    Promise.all([
      dispatch(clientsActions.getItemById(id)),
      dispatch(clientGroupActions.getAll()),
      dispatch(clientNotesActions.getCountNotes(id)),
    ])
    return () => {
      dispatch({ type: clientsConstants.SETBYID })
      dispatch({ type: notesConstants.SETCOUNT })
    }
  }, [id])

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.edit.key:
          return {
            ...menu,
            url: onEditItem,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        case navMenu.activity.key:
          return {
            ...menu,
            url: onActivitiesItem,
          }
        case navMenu.orders.key:
          return {
            ...menu,
            url: navigateToOrders,
            count: countOfItems.totalOrders,
          }
        case navMenu.listMails.key:
          return {
            ...menu,
            url: navigateToMail,
          }
        case navMenu.clientNotes.key:
          return {
            ...menu,
            url: navigateToNotes,
            count: countOfItems.clientNotesCount,
          }
        case navMenu.prePayCredits.key:
          return {
            ...menu,
            url: navigateToprePayCredits,
          }
        case navMenu.prePayCreditsType3.key:
          return {
            ...menu,
            url: navigateToPrepaidCreditsType3,
          }
        case navMenu.apiKeys.key:
          return {
            ...menu,
            url: navigateToApiKeys,
            // count: countOfItems.totalApiKeys,
          }
        case navMenu.inserts.key:
          return {
            ...menu,
            url: `/inserts/client/${id}`,
            count: countOfItems.totalInserts,
          }
        case navMenu.signatures.key:
          return {
            ...menu,
            url: navigateToSignatures,
            count: countOfItems.totalSignatures,
          }
        case navMenu.resetPassword.key:
          return {
            ...menu,
            url: resetPassword,
          }
        case navMenu.automations.key:
          return {
            ...menu,
            url: navigateToAutomations,
            count: countOfItems.totalAutomations,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit, countOfItems])

  const viewItem = useMemo(() => {
    if (client) {
      return Object.keys(viewItemKeyToLabel).reduce((obj, sKey) => {
        let itemValue
        if (sKey === 'group_id') {
          itemValue = clientGroups?.find(group => group.id === client.group_id)?.title || '----'
        } else if (sKey === 'status') {
          itemValue =
            clientStatuses.find(status => status.value === client.status?.toString())?.text ||
            'Unknown Status'
        } else if (sKey === 'last_employee_id' && client.last_employee_id) {
          itemValue =
            employees.find(employee => employee.id === client.last_employee_id)?.username || '----'
        } else if (sKey === 'credit_type2') {
          if (client?.credit_type2?.non_expiring) {
            itemValue = (
              <>
                Non-expiring pre-pay credits: {client.credit_type2.non_expiring || 0}
                <br />
                Expiring pre-pay credits: {client.credit_type2.expiring?.total || 0} (nearest
                expiration:{' '}
                {client.credit_type2?.expiring?.total
                  ? `${client.credit_type2.expiring.nearest_amount} an ${client.credit_type2.expiring.nearest_expires_at}`
                  : 'none'}
                )
              </>
            )
          }
        } else if (sKey === 'subscription') {
          itemValue = client.subscription ? (
            <span>
              Plan: {client.subscription.name}
              <br />
              Credits provided: {client.subscription.credit2} $<br />
              Discount: {client.subscription.discount} %<br />
              Expiration date: {client.subscription.expires_at}
              <br />
            </span>
          ) : (
            '----'
          )
        } else {
          itemValue = client[sKey]
        }
        return {
          ...obj,
          [sKey]: itemValue,
        }
      }, {})
    }
    return {}
  }, [client, clientGroups, employees])

  return (
    <NavContainer menu={navMenuParse}>
      <ItemViewer item={viewItem} keyToLabelMap={viewItemKeyToLabel} />
    </NavContainer>
  )
}

export default ViewItem
