import { activitiesConstants } from '../_constants'

const initialState = {
  employee: [],
  client: [],
}

function activities(state = initialState, { type, payload }) {
  switch (type) {
    case activitiesConstants.GETACTIVITYCLIENT_SUCCESS:
      return { ...state, client: payload }
    case activitiesConstants.GETACTIVITYEMPLOYEE_SUCCESS:
      return { ...state, employee: payload }
    case activitiesConstants.SETALL:
      return initialState
    default:
      return state
  }
}

export default activities
