import { activitiesConstants } from '../_constants'
import { activityService } from '../_services'

const getClientActivity = (params, typeAction, id) => async dispatch => {
  dispatch({ type: activitiesConstants.GETACTIVITYCLIENT_REQUEST })
  try {
    const activities = await activityService.getClientActivity(params, typeAction, id)
    dispatch({ type: activitiesConstants.GETACTIVITYCLIENT_SUCCESS, payload: activities.data })
  } catch (error) {
    dispatch({ type: activitiesConstants.GETACTIVITYCLIENT_FAILURE, payload: error.toString() })
  }
}

const getEmployeeActivity = (params, typeAction, id) => async dispatch => {
  dispatch({ type: activitiesConstants.GETACTIVITYEMPLOYEE_REQUEST })
  try {
    const activities = await activityService.getEmployeeActivity(params, typeAction, id)
    dispatch({ type: activitiesConstants.GETACTIVITYEMPLOYEE_SUCCESS, payload: activities.data })
  } catch (error) {
    dispatch({ type: activitiesConstants.GETACTIVITYEMPLOYEE_FAILURE, payload: error.toString() })
  }
}

const activitiesActions = {
  getEmployeeActivity,
  getClientActivity,
}

export default activitiesActions
