import { menuConstants } from '../_constants'

const setSearch = payload => dispatch => {
  dispatch({ type: menuConstants.SET_MENU_SEARCH, payload })
}

const menuActions = {
  setSearch,
}

export default menuActions
