import { userConstants } from '../_constants'

const user = localStorage.getItem('user')
const initialState = user !== 'undefined' ? { loggedIn: true, user: JSON.parse(user) } : {}

function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        user: action.user,
      }
    case userConstants.LOGIN_FAILURE:
      return {}
    case userConstants.LOGOUT_SUCCESS:
      return {}
    default:
      return state
  }
}

export default authentication
