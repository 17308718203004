import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import v4 from 'uuid/v4'
import { useHistory } from 'react-router-dom'

import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { wordMappingConstants, settingsConstants } from '../../../../_constants'
import { wordMappingActions } from '../../../../_actions'
import {
  getPage,
  getWordMappingRows,
  getTotalItems,
} from '../../../../_selectors/word-mapping.selector.js'

const { navMenu, advancedSearch, statuses } = wordMappingConstants

const statusRender = text => {
  return `${statuses.find(st => st.value.toString() === text.toString())?.text}`
}

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
  },
  {
    title: 'Break Word',
    dataIndex: 'full_word',
    key: 'full_word',
    sorter: true,
  },
  {
    title: 'Abbreviation',
    dataIndex: 'short_word',
    key: 'short_word',
    sorter: true,
  },
  {
    title: 'Allow Replace',
    dataIndex: 'allow_replace',
    key: 'allow_replace_filter',
    render: statusRender,
    filters: statuses,
    width: '10%',
    sorter: true,
  },
  {
    title: 'Allow Split',
    dataIndex: 'allow_split',
    key: 'allow_split_filter',
    render: statusRender,
    filters: statuses,
    width: '10%',
    sorter: true,
  },
]

const navMenuInit = [navMenu.list, navMenu.create]

const ManageItems = ({ match }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getWordMappingRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const navigateToList = () => {
    dispatch({ type: wordMappingConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}/list`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.list.key:
          return {
            ...menu,
            url: navigateToList,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const deleteItems = async id => {
    await dispatch(wordMappingActions.deleteItem(id))
  }

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  return (
    <NavContainer match={match} menu={navMenuParse}>
      <TableFilter
        deleteItems={deleteItems}
        columns={initColumns}
        data={dataSource}
        getDataAction={wordMappingActions.getAll}
        advancedSearch={advancedSearch}
      />
    </NavContainer>
  )
}

export default ManageItems
