import generateAdvancedStr from './generate-advanced-string'

const parseParams = (query, sorted, filters) => {
  if (sorted) {
    const { order, columnKey } = sorted
    const orderBy = {}

    if (!order) {
      orderBy.key = order
      orderBy.order = order
    } else {
      orderBy.key = columnKey
      orderBy.order = order === 'ascend' ? 'ASC' : 'DESC'
    }

    query.order = orderBy
  }

  if (filters) {
    const filterKeys = Object.keys(filters)
    filterKeys.forEach(key => {
      const value = filters[key]
      if (key.includes('_relation_filter')) {
        const param = key.split('_relation_filter')
        let relation
        if (value) {
          if (value.length > 1) {
            const multiWhere = value.reduce((acc, cur) => {
              const str = `${acc},${cur}`
              return str
            })
            relation = multiWhere
          } else {
            ;[relation] = value
          }
        }

        if (relation || typeof relation === 'number') {
          query.relation[param[0]] = relation
        }
      } else if (key.includes('_filter')) {
        const param = key.split('_filter')
        let where
        if (value) {
          if (value.length > 1) {
            const multiWhere = value.reduce((acc, cur) => {
              const str = `${acc},${cur}`
              return str
            })
            where = multiWhere
          } else {
            ;[where] = value
          }
        }
        if (where || typeof where === 'number') {
          query.where[param[0]] = where
        }
      } else if (key.includes('_id') || key === 'id') {
        if (value) query.where[key] = value
      } else if (key.includes('=>')) {
        if (value) {
          const param = key.split('=>')
          query.where[param[0]] = `${param[1]},${value}`
        }
      } else if (key.includes('*date')) {
        const param = key.split('*date')
        if (value) {
          query.date[param[0]] = value
        } else if (value === null && query.date[param[0]]) {
          delete query.date[param[0]]
        }
      } else if (value === null && query.like[key]) {
        delete query.like[key]
      } else if (value) {
        const [like] = value
        generateAdvancedStr(like, key, query)
      }
    })
  }
}

export default parseParams
