import axios from 'axios'
import serialize from '_helpers/hw-serialize-params'
import apiUrl from './service'
import { v1 } from './api'

const getAll = async (params, typeAction) => {
  const result = await axios.get(`${apiUrl}/${v1}/imposition?${serialize(params)}`, {
    params: { stopSpinner: typeAction },
  })
  return handleResponse(result)
}

const getItemById = async id => {
  const result = await axios.get(`${apiUrl}/${v1}/imposition/${id}`)
  return handleResponse(result)
}

const addItem = async ({ value }) => {
  const result = await axios.post(`${apiUrl}/${v1}/imposition`, JSON.stringify(value))
  return handleResponse(result)
}

const updateItemById = async (id, value) => {
  const result = await axios.put(`${apiUrl}/${v1}/imposition/${id}`, JSON.stringify({ ...value }))
  return handleResponse(result)
}

const deleteItem = async value => {
  const result = await axios.delete(`${apiUrl}/${v1}/imposition/${value}`, {
    params: { stopSpinner: 'paginate' },
  })
  return handleResponse(result)
}

// CONFIGS

const getImpositionConfigs = async id => {
  const result = await axios.get(`${apiUrl}/${v1}/imposition_layouts?where[imposition_id]=${id}`)
  return handleResponse(result)
}

const createConfig = async payload => {
  const result = await axios.post(`${apiUrl}/${v1}/imposition_layouts`, JSON.stringify(payload))
  return handleResponse(result)
}

const updateConfigById = async (id, payload) => {
  const result = await axios.put(
    `${apiUrl}/${v1}/imposition_layouts/${id}`,
    JSON.stringify(payload),
  )
  return handleResponse(result)
}

const handleResponse = result => {
  return result.data
}

const impositionLayoutsService = {
  getAll,
  getItemById,
  addItem,
  updateItemById,
  deleteItem,
  getImpositionConfigs,
  createConfig,
  updateConfigById,
}

export default impositionLayoutsService
