import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Form, Input, Select } from 'antd'
import NavContainer from '../../../../components/nav-container'
import { shareOptionActions } from '../../../../_actions'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import { shareOptionConstants } from '../../../../_constants'

const { formItemLayout, navMenu, sharingMethods } = shareOptionConstants

const navMenuInit = [navMenu.list, navMenu.manage]

const CreateItem = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const plainSharingMethods = useMemo(
    () => sharingMethods.map(methods => ({ value: methods.value, label: methods.text })),
    [sharingMethods],
  )

  const onFinish = async values => {
    const convertedMethod = convertFormValuesDeep(values)
    await dispatch(shareOptionActions.addItem(convertedMethod))
  }

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      <Form {...formItemLayout} name="basic" form={form} onFinish={onFinish} scrollToFirstError>
        <Form.Item
          label="Sharing Method"
          name="name"
          initialValue="default"
          rules={[
            {
              required: true,
              message: 'Please input name!',
            },
          ]}
        >
          <Select options={plainSharingMethods} />
        </Form.Item>

        <Form.Item label="Text" name="text">
          <Input.TextArea rows={6} />
        </Form.Item>

        <Form.Item label="Link" name="link">
          <Input />
        </Form.Item>

        <Form.Item label="Link (Android)" name="link_android">
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </NavContainer>
  )
}

export default CreateItem
