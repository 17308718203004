import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { Button, Checkbox, Form, Input, InputNumber, Modal, Select } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import { ShippingMethodsActions } from '../../../../_actions'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { convertDataForSelect } from '../../../../_helpers'
import { ShippingMethodsConstants } from '../../../../_constants'

const { ShippingMethodsStatuses, formItemLayout, navMenu } = ShippingMethodsConstants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.view, navMenu.manage]

const EditItem = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)
  const { id } = useParams()

  const [navMenuParse, setNavMenuParse] = useState([])

  const { item: ShippingMethods } = useSelector(state => state.ShippingMethods)
  const [changed, setChange] = useState(false)
  useEffect(() => {
    dispatch(ShippingMethodsActions.getItemById(id))
    return () => {
      dispatch({ type: ShippingMethodsConstants.SETBYID })
    }
  }, [])

  useEffect(() => {
    if (ShippingMethods) {
      form.setFieldsValue({ ...ShippingMethods })
    }
  }, [ShippingMethods, form])

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToView,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const navigateToView = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(ShippingMethodsActions.deleteItem(id))
      },
    })
  }

  const onFinish = async values => {
    const convertedSippingMethods = convertFormValuesDeep(values)
    await dispatch(
      ShippingMethodsActions.updateItemById(ShippingMethods.id, convertedSippingMethods),
    )
  }

  return (
    <NavContainer menu={navMenuParse}>
      {ShippingMethods && (
        <div>
          <p>Fields with * are required.</p>
          <Form
            {...formItemLayout}
            name="basic"
            form={form}
            onFinish={onFinish}
            onFieldsChange={() => setChange(true)}
            scrollToFirstError
          >
            <Form.Item
              label="Methods Name"
              name="method_name"
              rules={[
                {
                  required: true,
                  message: 'Please input methods name!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Methods Label"
              name="method_label"
              rules={[
                {
                  required: true,
                  message: 'Please input methods label!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Add Postage Fee"
              name="add_postage_fee"
              initialValue={0}
              rules={[
                {
                  required: true,
                  message: 'Please input add Postage Fee!',
                },
              ]}
            >
              <Select options={convertDataForSelect(ShippingMethodsStatuses)} />
            </Form.Item>

            <Form.Item
              label="Show Options"
              name="show_options"
              initialValue={0}
              rules={[
                {
                  required: true,
                  message: 'Please input add Show Options',
                },
              ]}
            >
              <Select options={convertDataForSelect(ShippingMethodsStatuses)} />
            </Form.Item>

            <Form.Item
              label="Status"
              name="status"
              initialValue={0}
              rules={[
                {
                  required: true,
                  message: 'Please input add status',
                },
              ]}
            >
              <Select options={convertDataForSelect(ShippingMethodsStatuses)} />
            </Form.Item>

            <Form.Item
              label="Check Recipient Address"
              name="check_recipient_address"
              initialValue={0}
              rules={[
                {
                  required: true,
                  message: 'Please input add recipient address',
                },
              ]}
            >
              <Select options={convertDataForSelect(ShippingMethodsStatuses)} />
            </Form.Item>

            <Form.Item
              label="Check Return Address"
              name="check_return_address"
              initialValue={0}
              rules={[
                {
                  required: true,
                  message: 'Please input add return address',
                },
              ]}
            >
              <Select options={convertDataForSelect(ShippingMethodsStatuses)} />
            </Form.Item>

            <Form.Item label="Show in Outbound" name="show_in_outbound" valuePropName="checked">
              <Checkbox />
            </Form.Item>

            <Form.Item
              label="Discount"
              name="discount"
              initialValue={0}
              rules={[
                {
                  required: true,
                  message: 'Please input discount!',
                },
              ]}
            >
              <InputNumber step={0.1} min={0.0} />
            </Form.Item>

            <Form.Item label="Sort Order" name="sort_order" initialValue={0}>
              <InputNumber step={1} min={0} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!changed}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </NavContainer>
  )
}

export default EditItem
