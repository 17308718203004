import { banIpService } from '../_services'
import { banIpConstants } from '../_constants'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: banIpConstants.GETALL_REQUEST })
  try {
    const banIP = await banIpService.getAll(params, typeAction)
    dispatch({ type: banIpConstants.GETALL_SUCCESS, payload: banIP })
  } catch (error) {
    dispatch({ type: banIpConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const saveBanIP = orderId => async dispatch => {
  dispatch({ type: banIpConstants.SAVE_BAN_IP_REQUEST })

  try {
    await banIpService.saveBanIP(orderId)
    dispatch({ type: banIpConstants.SAVE_BAN_IP_SUCCESS })
  } catch (error) {
    dispatch({ type: banIpConstants.SAVE_BAN_IP_FAILURE })
  }
}

const banIpActions = {
  getAll,
  saveBanIP,
}

export default banIpActions
