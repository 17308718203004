import axios from 'axios'
import apiUrl from './service'
import { v1 } from './api'
import serialize from '../_helpers/hw-serialize-params'
import downloader from '../_helpers/file-download'

const getGroups = async (groupByGCards, groupByInserts) => {
  const groupByGCardValue = groupByGCards ? 1 : 0
  const groupByInsertValue = groupByInserts ? 1 : 0

  const result = await axios.get(
    `${apiUrl}/${v1}/batch_orders/paid/groups?group_gift_card=${groupByGCardValue}&group_insert=${groupByInsertValue}`,
  )
  return handleResponse(result)
}

const getOrdersByDate = async (date, groupByGCards, groupByInserts) => {
  const groupByGCardValue = groupByGCards ? 1 : 0
  const groupByInsertValue = groupByInserts ? 1 : 0

  const result = await axios.get(
    `${apiUrl}/${v1}/batch_orders/paid?group_gift_card=${groupByGCardValue}&group_insert=${groupByInsertValue}&where[date_send]=${date}`,
  )
  return handleResponse(result)
}

const getPrintedOrders = async (printedOrders, groupByGCards, groupByInserts) => {
  const groupByGCardValue = groupByGCards ? 1 : 0
  const groupByInsertValue = groupByInserts ? 1 : 0
  const isOnlyPrinted = printedOrders ? 1 : 0

  const result = await axios.get(
    `${apiUrl}/${v1}/batch_orders/paid?group_gift_card=${groupByGCardValue}&group_insert=${groupByInsertValue}&only_printed=${isOnlyPrinted}`,
  )
  return handleResponse(result)
}

const checkInWork = async () => {
  const result = await axios.get(`${apiUrl}/${v1}/batch_orders/check`)
  return handleResponse(result)
}

const getAllInWork = async () => {
  const result = await axios.get(`${apiUrl}/${v1}/batch_orders/in_work`)
  return handleResponse(result)
}

// eslint-disable-next-line no-unused-vars
const getByCardId = async (
  cardId,
  is_redo,
  is_ups,
  rateId,
  deliveryConfirmation,
  is_gcard,
  is_insert,
  dateSend,
  isCardPrinted,
  isPrinted,
) => {
  const _Index = cardId.indexOf('_')
  const card_id = cardId.slice(0, _Index)

  const params = { where: { card_id, status: 'paid', parent_id: null } }

  let query = ''

  const isCardPrintedValue = isCardPrinted ? 1 : 0

  const shouldUseGCardsQuery = typeof is_gcard === 'number'
  const shouldUseInsertQuery = typeof is_insert === 'number'

  if (deliveryConfirmation) {
    query += `&where[delivery_confirmation]=1`
  } else {
    query += `&where[delivery_confirmation]=0`
  }

  if (is_ups) {
    query += `&where[ups_id]=1&where[shipping_details]=shipping_rate_id,${rateId}`
  } else {
    query += '&where[ups_id]=0'
  }

  if (is_redo) {
    query += '&where[redo_id]=%3C%3E%20null'
  } else {
    query += '&where[redo_id]=null'
  }

  if (shouldUseGCardsQuery) {
    if (is_gcard) {
      query += '&where[denomination_id]=%3C%3E%20null'
    } else {
      query += '&where[denomination_id]=null'
    }
  }

  if (shouldUseInsertQuery) {
    if (is_insert) {
      query += '&where[insert_id]=1'
    } else {
      query += '&where[insert_id]=0'
    }
  }

  if (dateSend) {
    query += `&where[date_send]=${dateSend}`
  }

  if (isPrinted) {
    query += `&where[is_printed]=${isCardPrintedValue}`
  }

  const result = await axios.get(`${apiUrl}/${v1}/batch_orders?${serialize(params)}${query}`)
  return handleResponse(result)
}

const processSelectedRows = async rowsData => {
  const result = await axios.post(
    `${apiUrl}/${v1}/batch_orders/process`,
    JSON.stringify({ rowsData }),
  )
  return handleResponse(result)
}

const completeOrders = async ordersIds => {
  const result = await axios.post(`${apiUrl}/${v1}/batch_orders/complete`, { ordersIds })
  return handleResponse(result)
}

const discardOrders = async (ordersIds, confirmed) => {
  const result = await axios.post(`${apiUrl}/${v1}/batch_orders/discard`, { ordersIds, confirmed })
  return handleResponse(result)
}

const createDocxNotes = async ordersIds => {
  const result = await axios.post(
    `${apiUrl}/${v1}/batch_orders/docx/notes`,
    {
      ordersIds,
    },
    {
      responseType: 'blob',
    },
  )

  const filename = result.headers['content-disposition'].split('filename=')[1].slice(1, -1)

  downloader(result.data, filename)
  return handleResponse(result)
}

const createPDFNotes = async ordersIds => {
  const result = await axios.post(
    `${apiUrl}/${v1}/batch_orders/pdf/notes`,
    {
      ordersIds,
    },
    {
      responseType: 'blob',
    },
  )

  const filename = result.headers['content-disposition'].split('filename=')[1].slice(1, -1)

  downloader(result.data, filename)
  return handleResponse(result)
}

const createPDFEnvelopes = async ordersIds => {
  const result = await axios.post(
    `${apiUrl}/${v1}/batch_orders/pdf/envelopes`,
    {
      ordersIds,
    },
    {
      responseType: 'blob',
    },
  )

  const filename = result.headers['content-disposition'].split('filename=')[1].slice(1, -1)

  downloader(result.data, filename)
  return handleResponse(result)
}

const createDocxEnvelopes = async ordersIds => {
  const result = await axios.post(
    `${apiUrl}/${v1}/batch_orders/docx/envelopes`,
    {
      ordersIds,
    },
    {
      responseType: 'blob',
    },
  )

  const filename = result.headers['content-disposition'].split('filename=')[1].slice(1, -1)

  downloader(result.data, filename)
  return handleResponse(result)
}

const createManifest = async (orderIds, isCut, isNewManifest) => {
  const result = await axios.post(
    `${apiUrl}/${v1}/batch_orders/manifest?cut=${isCut}&isNewManifest=${isNewManifest}`,
    { orderIds },
    { responseType: 'blob' },
  )
  downloader(result.data, result.headers['manifest-name'])
  return result
}

const createManifestCut = async () => {
  const result = await axios.post(`${apiUrl}/${v1}/batch_orders/manifest/cut/create`)
  return handleResponse(result)
}

const printLabels = async params => {
  const result = await axios.post(
    `${apiUrl}/${v1}/batch_orders/labels/print`,
    JSON.stringify(params),
    { responseType: 'blob' },
  )
  downloader(result.data, `labels.pdf`)
}

const cancelLabels = async params => {
  const result = await axios.post(
    `${apiUrl}/${v1}/batch_orders/labels/cancel`,
    JSON.stringify(params),
  )
  return handleResponse(result)
}

const generateLabels = async params => {
  const result = await axios.post(
    `${apiUrl}/${v1}/batch_orders/labels/generate`,
    JSON.stringify(params),
  )
  return handleResponse(result)
}

const generateImposedCards = async (payload, onDownload) => {
  const {
    ids,
    pageSize,
    withBarcode,
    worklist,
    manifest_id,
    employee_name,
    cardId,
    quantity,
    onlyCustom,
    version,
    file_name_suffix,
    imposition_id,
  } = payload

  let data = {}

  if (cardId) {
    data = {
      onlyCustom: version && onlyCustom,
      withBarcode,
      cardId,
      quantity,
    }
  } else {
    data = {
      ordersIds: ids,
      withBarcode,
      worklist,
      manifest_id,
      employee_name,
      onlyCustom: version && onlyCustom,
      file_name_suffix,
    }
  }

  // imposition template mode
  if (imposition_id) {
    data.imposition_id = imposition_id
  } else {
    // page-size mode
    data.pageSize = { height: +pageSize.height, width: +pageSize.width }
  }

  let routeVersion = ''

  if (!version) routeVersion = !onlyCustom ? 'v2/' : ''
  else routeVersion = `v${version}/`

  const result = await axios.post(
    `${apiUrl}/${v1}/batch_orders/impositionCard/${routeVersion}print`,
    JSON.stringify(data),
    {
      params: { stopSpinner: 'background' },
      responseType: 'blob',
      onDownloadProgress: onDownload,
    },
  )
  downloader(result.data, `${result.headers['file-name']}.pdf`)
  return result
}

const getTotalOrdersCount = async () => {
  const result = await axios.get(`${apiUrl}/${v1}/batch_orders/paid/count`)
  return handleResponse(result)
}

const generateWorkList = async params => {
  const result = await axios.post(`${apiUrl}/${v1}/batch_orders/worklist`, JSON.stringify(params))
  return handleResponse(result)
}

const generatePrintableList = async params => {
  const result = await axios.post(
    `${apiUrl}/${v1}/batch_orders/printableList`,
    JSON.stringify(params),
    { responseType: 'blob' },
  )
  downloader(result.data, `${result.headers['file-name']}.pdf`)
}

const statusLabels = async isDetail => {
  const result = await axios.get(`${apiUrl}/${v1}/batch_orders/labels/status/?details=${isDetail}`)
  return handleResponse(result)
}

const statusManifest = async () => {
  const result = await axios.get(`${apiUrl}/${v1}/batch_orders/manifest/status`)
  return handleResponse(result)
}

const handleResponse = result => {
  return result.data
}

const batchOrdersService = {
  getGroups,
  getOrdersByDate,
  getPrintedOrders,
  getByCardId,
  processSelectedRows,
  completeOrders,
  discardOrders,
  createDocxNotes,
  createDocxEnvelopes,
  createManifest,
  createManifestCut,
  printLabels,
  cancelLabels,
  generateLabels,
  getAllInWork,
  checkInWork,
  statusLabels,
  statusManifest,
  generateImposedCards,
  createPDFNotes,
  createPDFEnvelopes,
  getTotalOrdersCount,
  generateWorkList,
  generatePrintableList,
}

export default batchOrdersService
