import { ShippingMethodsConstants } from '../_constants'
import { ShippingMethodsService } from '../_services'
import { history } from '../_helpers'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: ShippingMethodsConstants.GETALL_REQUEST })
  try {
    const ShippingMethods = await ShippingMethodsService.getAll(params, typeAction)
    dispatch({ type: ShippingMethodsConstants.GETALL_SUCCESS, payload: ShippingMethods.data })
  } catch (error) {
    dispatch({ type: ShippingMethodsConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const addItem = value => async dispatch => {
  dispatch({ type: ShippingMethodsConstants.ADD_REQUEST })

  try {
    const shippingMethod = await ShippingMethodsService.addItem({ value })
    dispatch({
      type: ShippingMethodsConstants.ADD_SUCCESS,
      payload: { ...value, id: shippingMethod.id },
    })
  } catch (error) {
    dispatch({ type: ShippingMethodsConstants.ADD_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: ShippingMethodsConstants.GETBYID_REQUEST })

  try {
    const shippingMethod = await ShippingMethodsService.getItemById(id)
    dispatch({ type: ShippingMethodsConstants.GETBYID_SUCCESS, payload: shippingMethod.data })
  } catch (error) {
    dispatch({ type: ShippingMethodsConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const deleteItem = id => async dispatch => {
  dispatch({ type: ShippingMethodsConstants.DELETE_REQUEST })
  try {
    await ShippingMethodsService.deleteItem(id)
    history.push('/shipping-methods')
    dispatch({ type: ShippingMethodsConstants.DELETE_SUCCESS, payload: id })
  } catch (error) {
    dispatch({ type: ShippingMethodsConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const updateItemById = (id, value) => async dispatch => {
  dispatch({ type: ShippingMethodsConstants.UPDATE_REQUEST })

  try {
    const updated = await ShippingMethodsService.updateItemById(id, value)
    dispatch({
      type: ShippingMethodsConstants.UPDATE_SUCCESS,
      payload: updated?.message?.toString(),
    })
    history.push(`/shipping-methods/view/${id}`)
  } catch (error) {
    dispatch({ type: ShippingMethodsConstants.UPDATE_FAILURE, payload: error.toString() })
  }
}

const ShippingMethodsActions = {
  getAll,
  getItemById,
  deleteItem,
  updateItemById,
  addItem,
}

export default ShippingMethodsActions
