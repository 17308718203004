import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import v4 from 'uuid/v4'
import getCurrentUrl from '../../../../_helpers/url-utils'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import { settingsConstants, shippingConfigsConstants } from '../../../../_constants'
import { queryParamsActions, shippingConfigsActions } from '../../../../_actions'
import {
  getGroupedShippingConfigsRows,
  getGroupedPage,
  getGroupedTotalItems,
} from '_selectors/shipping-configs.selectors'

const {
  navMenu,
  shipperKeyToLabel,
  boxKeyToLabel,
  cardKeyToLabel,
} = shippingConfigsConstants

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Count',
    dataIndex: 'count',
    key: 'count',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
]

const navMenuInit = [navMenu.list, navMenu.create]

const GroupTable = ({ type }) => {
  const dispatch = useDispatch()
  const {
    [type]: { rows, page, total },
  } = useSelector(state => state.shippingConfigs)

  const [render, setRender] = useState(false)

  useEffect(() => {
    dispatch(queryParamsActions.setQueryParams({ type }))
    setRender(true)
  }, [])

  const deleteItem = async id => {
    await dispatch(shippingConfigsActions.deleteItem(id))
  }

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  const columnKeys = useMemo(() => {
    let keys = { id: 'ID' }
    if (type === 'shipper') keys = { ...keys, ...shipperKeyToLabel }
    else if (type === 'box') keys = { ...keys, ...boxKeyToLabel }
    else keys = { ...keys, ...cardKeyToLabel }
    return { ...keys, status: 'Status' }
  }, [type])

  const getColumns = () =>
    Object.keys(columnKeys).map(key => ({
      title: columnKeys[key],
      dataIndex: key,
      key,
    }))

  const onPaginationChange = () => {
    dispatch(queryParamsActions.setQueryParams({ type }))
  }

  if (!render) return null

  return (
    <div style={{ border: '1px solid rgba(0, 0, 0, 0.125)' }}>
      <TableFilter
        deleteItems={deleteItem}
        columns={getColumns()}
        data={dataSource}
        getDataAction={shippingConfigsActions.getGroupByType}
        showAdvancedSearch={false}
        onPagination={onPaginationChange}
        isChildTable
      />
    </div>
  )
}

const ManageItems = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)

  const navigateToList = () => {
    dispatch({ type: shippingConfigsConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}/list`,
    })
  }
  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getGroupedShippingConfigsRows)
  const page = useSelector(getGroupedPage)
  const total = useSelector(getGroupedTotalItems)

  useEffect(() => {
    return () => dispatch(queryParamsActions.clearQueryParams())
  }, [])

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.list.key:
          return {
            ...menu,
            url: navigateToList,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        expandableContent: <GroupTable type={item.type} />,
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  return (
    <NavContainer match={match} menu={navMenuParse}>
      <TableFilter
        columns={initColumns}
        data={dataSource}
        actionsColum={false}
        getDataAction={shippingConfigsActions.getGrouped}
        isEditOnId={false}
        expandableTable
      />
    </NavContainer>
  )
}

export default ManageItems
