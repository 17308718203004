const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT_REQUEST: 'USERS_LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'USERS_LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'USERS_LOGOUT_FAILURE',

  // LOGOUT: 'USERS_LOGOUT',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',

  UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

  GETBYID_REQUEST: 'USERS_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'USERS_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'USERS_GETBYID_FAILURE',

  CHANGE_PASSWORD_REQUEST: 'USERS_CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'USERS_CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'USERS_CHANGE_PASSWORD_FAILURE',

  SENT_EMAIL_REQUEST: 'USERS_SENT_EMAIL_REQUEST',
  SENT_EMAIL_SUCCESS: 'USERS_SENT_EMAIL_SUCCESS',
  SENT_EMAIL_FAILURE: 'USERS_SENT_EMAIL_FAILURE',

  RESTORE_PASSWORD_REQUEST: 'USERS_RESTORE_PASSWORD_REQUEST',
  RESTORE_PASSWORD_SUCCESS: 'USERS_RESTORE_PASSWORD_SUCCESS',
  RESTORE_PASSWORD_FAILURE: 'USERS_RESTORE_PASSWORD_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',
  SET_PERMIS: 'SET_PERMIS',

  viewItemKeyToLabel: {
    username: 'username',
    first_name: 'First Name',
    last_name: 'Last Name',
    email: 'E-mail',
    create_at: 'Registration date',
    lastvisit_at: 'Last visit',
    status: 'Status',
  },

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },

  navMenu: {
    profile: { key: 'profile', title: 'Profile', url: 'profile' },
    list: { key: 'list', title: 'List User', url: '/user' },
    edit: { key: 'edit', title: 'Edit', url: '/user/edit' },
    password: { key: 'password', title: 'Change password', url: '/user/changepassword' },
    logout: { key: 'logout', title: 'Logout', url: '' },
  },

  userStatuses: [
    { value: 1, text: 'Active' },
    { value: 0, text: 'Not Active' },
  ],
}
export default userConstants
