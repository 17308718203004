import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Button, Form, InputNumber, Modal, Select, Input, Space } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import NavContainer from '../../../../components/nav-container'
import getCurrentUrl from '../../../../_helpers/url-utils'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import { convertDataForSelect } from '../../../../_helpers'

import { impositionLayoutsActions } from '../../../../_actions'
import { impositionLayoutsConstants } from '../../../../_constants'
import { impositionLayoutsSelectors } from '_selectors/imposition-layouts.selector'

const { formItemLayout, navMenu, layoutStatuses } = impositionLayoutsConstants

const navMenuInit = [navMenu.manage, navMenu.create, navMenu.view, navMenu.delete]

const EditItem = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const match = useRouteMatch()
  const { id } = useParams()
  const [form] = Form.useForm()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])
  const [changed, setChange] = useState(false)

  const imposition = useSelector(impositionLayoutsSelectors.getImposition)

  useEffect(() => {
    dispatch(impositionLayoutsActions.getItemById(id))
    return () => {
      dispatch({ type: impositionLayoutsConstants.SETBYID })
    }
  }, [id, dispatch])

  const deleteItems = async () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item?',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(impositionLayoutsActions.deleteItem(id))
        history.push({
          pathname: `${view}`,
        })
      },
    })
  }

  const navigateToView = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  const navigateToLayoutForm = () => {
    history.push({
      pathname: `${view}/layout/${id}`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToView,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: deleteItems,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit, imposition])

  useEffect(() => {
    if (imposition) {
      form.setFieldsValue({
        ...imposition,
      })
    }
  }, [form, imposition])

  const onFinish = async values => {
    await dispatch(impositionLayoutsActions.updateItemById(id, values))
  }

  return (
    <NavContainer menu={navMenuParse}>
      <p>Fields with * are required.</p>

      {useGlobalLoader(
        <Form
          {...formItemLayout}
          name="basic"
          form={form}
          onFinish={onFinish}
          onFieldsChange={() => setChange(true)}
          scrollToFirstError
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input title!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Paper Height"
            name="height"
            rules={[
              {
                required: true,
                message: 'Please fill the field!',
              },
            ]}
          >
            <InputNumber min={1} />
          </Form.Item>

          <Form.Item
            label="Paper Width"
            name="width"
            rules={[
              {
                required: true,
                message: 'Please fill the field!',
              },
            ]}
          >
            <InputNumber min={1} />
          </Form.Item>

          <Form.Item name="status" label="Status">
            <Select options={convertDataForSelect(layoutStatuses)} />
          </Form.Item>

          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit" disabled={!changed}>
                Save
              </Button>
              <Button type="primary" onClick={navigateToLayoutForm}>
                Go To Layout Form
              </Button>
            </Space>
          </Form.Item>
        </Form>,
      )}
    </NavContainer>
  )
}

export default EditItem
