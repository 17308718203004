import { cardImagesConstants } from '../_constants'

const initialState = {
  items: [],
  item: {},
}

function cardImages(state = initialState, { type, payload }) {
  switch (type) {
    case cardImagesConstants.GETALL_SUCCESS:
      return { ...state, items: payload, item: {} }
    case cardImagesConstants.GETBYID_SUCCESS:
      return { ...state, item: payload }
    case cardImagesConstants.DELETE_SUCCESS:
      return { ...state }
    case cardImagesConstants.UPDATE_SUCCESS:
      return { ...state, items: state.items }
    case cardImagesConstants.ADD_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          total: state.items.total + 1,
        },
      }
    case cardImagesConstants.SETBYID:
      return {
        ...state,
        item: {},
      }
    case cardImagesConstants.SETALL:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default cardImages
