import React from 'react'
import { List } from 'antd'
import useGlobalLoader from '../../hooks/loader/useGlobalLoader.js'

const ItemViewer = ({ item, keyToLabelMap }) => {
  const dataSource = Object.entries(item)

  const data = useGlobalLoader(
    <div className="item-viewer">
      <List
        itemLayout="horizontal"
        size="small"
        dataSource={dataSource}
        renderItem={itemValue => {
          const check = item.changeBackground?.find(item => item.value === itemValue[0])
          return (
            itemValue[0] !== 'changeBackground' && (
              <List.Item className={(check && check.class) || ''}>
                <div className="d-flex flex-row" style={{ width: '100%' }}>
                  <span className="list-view-item-bold">
                    {keyToLabelMap[itemValue[0]] || itemValue[0]}
                  </span>
                  <span className="list-view-value">{itemValue[1] ?? '---'}</span>
                </div>
              </List.Item>
            )
          )
        }}
      />
    </div>,
  )
  return data
}
ItemViewer.defaultProps = {
  item: {},
  keyToLabelMap: {},
}

export default ItemViewer
