import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RedirectPage } from 'containers/layouts'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import {
  EditItem,
  ListActivity,
  ListCreditCards,
  ManageApiKeys,
  ManageItems,
  ManageMails,
  ViewItem,
} from './views'
import Content from '../../components/content'
import ManageCreditHistory from './views/credit-history'

const Clients = () => {
  const permis = useSelector(state => state.users.permis)
  const permisKeys = useMemo(() => {
    return Object.keys(permis)
  }, [permis])

  const match = useRouteMatch()
  return (
    <Content>
      <Switch>
        <Route exact path={match.url} component={ManageItems} />
        <Route
          path={`${match.url}/edit/:id`}
          component={
            permisKeys.includes('superuser') || permisKeys.includes('can_edit_users')
              ? EditItem
              : RedirectPage
          }
        />
        <Route path={`${match.url}/view/:id`} component={ViewItem} />
        <Route path={`${match.url}/activities/:id`} component={ListActivity} />

        <Route path={`${match.url}/credit-cards`} component={ListCreditCards} />
        <Route path={`${match.url}/client-mails/:id`} component={ManageMails} />
        <Route path={`${match.url}/api-keys/:id`} component={ManageApiKeys} />
        <Route path={`${match.url}/credit_history`} component={ManageCreditHistory} />
        <Redirect to={match.url} />
      </Switch>
    </Content>
  )
}

export default Clients
