import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Form, Input, InputNumber } from 'antd'
import NavContainer from '../../../../components/nav-container'
import { countriesActions } from '../../../../_actions'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import { countryConstants } from '../../../../_constants'

const { formItemLayout, navMenu } = countryConstants

const navMenuInit = [navMenu.list, navMenu.manage]

const CreateItem = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const onFinish = async values => {
    const convertedCountry = convertFormValuesDeep(values)
    await dispatch(countriesActions.addItem(convertedCountry))
  }

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      <Form {...formItemLayout} name="basic" form={form} onFinish={onFinish} scrollToFirstError>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input name!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Aliases" name="aliases">
          <Input.TextArea rows={6} />
        </Form.Item>

        <Form.Item label="Delivery Cost" name="delivery_cost" initialValue={0.0}>
          <InputNumber step={0.01} min={0.0} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </NavContainer>
  )
}

export default CreateItem
