import React, { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, Layout, Modal, Typography } from 'antd'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'

import { userActions } from '_actions'
import { qaConstants } from '_constants'

const QaLayout = ({ children }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname } = useLocation()

  const isLoginPage = useMemo(() => pathname.includes('/login'), [pathname])
  const isQaInProgress = useMemo(() => pathname.includes('/manifest/view'), [pathname])

  const navigateToManifestForm = () => {
    dispatch({ type: qaConstants.SETALL })
    history.push('/completion/manifest')
  }

  const logout = () => {
    dispatch(userActions.logout(true))
  }

  const callConfirmationPopup = onOk => {
    Modal.confirm({
      title: 'Are you sure you want to leave this page?',
      content: 'Changes made on this page will not be applied.',
      onOk,
    })
  }

  const startClickHandler = () => {
    if (isQaInProgress) {
      callConfirmationPopup(navigateToManifestForm)
    } else {
      navigateToManifestForm()
    }
  }

  const logoutClickHandler = () => {
    if (isQaInProgress) {
      callConfirmationPopup(logout)
    } else {
      logout()
    }
  }

  return (
    <Layout>
      <Layout.Header
        className={classNames('cui__layout__header', {
          cui__layout__fixedHeader: true,
        })}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: isLoginPage ? 'center' : 'space-between',
            alignItems: 'center',
            backgroundColor: '#4b7cf3',
            height: '4rem',
          }}
        >
          {isLoginPage ? (
            <Typography.Title level={3} style={{ color: 'white', margin: 0 }}>
              Handwrytten
            </Typography.Title>
          ) : (
            <>
              <Button
                type="text"
                style={{ fontSize: '1rem', fontWeight: 600, color: 'white' }}
                onClick={startClickHandler}
              >
                Start
              </Button>
              <Typography.Title level={3} style={{ color: 'white', margin: 0 }}>
                Handwrytten
              </Typography.Title>
              <Button
                type="text"
                style={{ fontSize: '1rem', fontWeight: 600, color: 'white' }}
                onClick={logoutClickHandler}
              >
                Logout
              </Button>
            </>
          )}
        </div>
      </Layout.Header>
      {children}
    </Layout>
  )
}

export default QaLayout
