import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router'
import getCurrentUrl from '../../../../_helpers/url-utils'
import NavContainer from '../../../../components/nav-container'
import ListViewer from '../../../../components/list-viewer'
import { notificationActions } from '../../../../_actions'
import { notificationConstants, settingsConstants } from '../../../../_constants'
import { getNotificationsRows, getPage, getTotalItems } from '_selectors/notifications.selector'

const { navMenu, listItemKeyToLabel } = notificationConstants

const navMenuInit = [navMenu.manage, navMenu.create]

const ListItems = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getNotificationsRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const navigateToManage = () => {
    dispatch({ type: notificationConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.manage.key:
          return {
            ...menu,
            url: navigateToManage,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const listItems = useMemo(() => {
    return {
      total,
      page,
      rows: rows?.map(item => {
        const res = []
        Object.keys(listItemKeyToLabel).forEach(sKey => {
          const itemLine = { label: listItemKeyToLabel[sKey], value: item[sKey], key: sKey }
          if (sKey === 'id') {
            itemLine.clickHandler = () => {
              history.push({ pathname: `/notifications/view/${item[sKey]}` })
            }
          }
          res.push(itemLine)
        })
        return res
      }),
    }
  }, [rows, total, page])

  return (
    <NavContainer menu={navMenuParse}>
      <ListViewer data={listItems} getDataAction={notificationActions.getAll} />
    </NavContainer>
  )
}

export default ListItems
