import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { ImpositionLayoutFormGroup } from 'components/imposition-layout'
import useImpositionLayout from 'hooks/imposition-configs/useImpositionLayout'
import { impositionLayoutsSelectors } from '_selectors/imposition-layouts.selector'
import { impositionLayoutsConstants } from '_constants'

const LayoutForm = () => {
  const dispatch = useDispatch()
  const { id } = useParams()

  const imposition = useSelector(impositionLayoutsSelectors.getImposition)

  const { name, activeTab, tabItems, setActiveTab } = useImpositionLayout(id)

  useEffect(() => {
    return () => {
      // clear imposition layout data on unmount
      dispatch({ type: impositionLayoutsConstants.SETBYID })
    }
  }, [])

  if (!imposition) return null

  return (
    <ImpositionLayoutFormGroup
      name={name}
      activeTab={activeTab}
      tabItems={tabItems}
      setActiveTab={setActiveTab}
    />
  )
}

export default LayoutForm
