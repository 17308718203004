import React, { useState } from 'react'
import { Button, Modal, List, Typography } from 'antd'
import { useSelector } from 'react-redux'

const WorkListModal = ({ isVisible, onCancel, getImposedOrders, getPrintableList }) => {
  const [printableListLoading, setPrintableListLoading] = useState([])
  const workList = useSelector(state => state.batchOrders.workList)

  const getPrintableListHandler = async (name, printable) => {
    setPrintableListLoading(prev => [...prev, name])
    await getPrintableList(printable, name)
    setPrintableListLoading(prev => prev.filter(id => id !== name))
  }

  const getOrdersData = (name, orders, type) => {
    const worklist_id = name.split(' ').slice(-1)[0]
    getImposedOrders(orders, worklist_id, type)
  }

  const renderListItem = ({ name, orders, printable }) => (
    <List.Item
      style={{
        justifyContent: 'flex-start',
        border: 'none',
        borderBottom: '1px solid #e4e9f0',
        margin: 0,
      }}
    >
      <div style={{ marginRight: '1rem' }}>
        <p style={{ marginBottom: 0 }}>{name}</p>
      </div>
      <div
        className="d-flex flex-row"
        style={{ justifyContent: 'space-between', padding: '5px 0' }}
      >
        <Button
          onClick={() => getPrintableListHandler(name, printable)}
          type="primary"
          style={{ marginRight: '1rem' }}
          loading={printableListLoading.includes(name)}
        >
          Get Printable List
        </Button>
        {/* <Button */}
        {/*  onClick={() => getOrdersData(name, orders, 'print-imposed-custom')} */}
        {/*  type="primary" */}
        {/*  style={{ marginRight: '1rem' }} */}
        {/* > */}
        {/*  Get Imposed Orders */}
        {/* </Button> */}
        <Button
          onClick={() => getOrdersData(name, orders, 'imposed-modal-python')}
          style={{ backgroundColor: '#2eb82e', color: '#ffffff' }}
        >
          Get Imposed Orders (Python)
        </Button>
        <Button
          onClick={() => getOrdersData(name, orders, 'imposed-modal-python-template')}
          style={{ backgroundColor: '#2eb82e', color: '#ffffff' }}
        >
          Get Imposed Orders (Python Template)
        </Button>
      </div>
    </List.Item>
  )

  return (
    <Modal
      maskClosable={false}
      visible={isVisible}
      onCancel={onCancel}
      width="fit-content"
      footer={null}
    >
      <Typography.Title level={3}>Work Lists</Typography.Title>
      <div
        style={{
          maxHeight: 450,
          overflow: 'auto',
        }}
      >
        <List dataSource={workList} renderItem={renderListItem} />
      </div>
    </Modal>
  )
}

export default WorkListModal
