import { prepaidCreditsType3Constants } from '_constants'
const initialState = {
  items: [],
  item: {},
}

function prePayCreditsType3(state = initialState, { type, payload }) {
  switch (type) {
    case prepaidCreditsType3Constants.GETALL_SUCCESS:
      return { ...state, items: payload, item: {} }
    case prepaidCreditsType3Constants.GETBYID_SUCCESS:
      return { ...state, item: payload }
    case prepaidCreditsType3Constants.UPDATE_SUCCESS:
      return { ...state, items: state.items }
    case prepaidCreditsType3Constants.ADD_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          total: state.items.total + 1,
        },
      }
    default:
      return state
  }
}

export default prePayCreditsType3
