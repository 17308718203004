import React, { useMemo } from 'react'
import { Table, Typography } from 'antd'

import { inchToMm, inchToPx, pxToMm } from '_helpers/convert'

const getNumberArray = n => Array.from({ length: n }, (_, i) => i)

const getAlphabetArray = n => Array.from({ length: n }, (_, i) => String.fromCharCode(65 + i))

const CuttingMarkupSection = ({ config, cardDimensions, paperSize }) => {
  const cardSizeWithRotate = useMemo(() => {
    const width = inchToPx(cardDimensions.card_width)
    const height = inchToPx(cardDimensions.card_height)
    return config.card_rotate ? { width: height, height: width } : { width, height }
  }, [cardDimensions, config.card_rotate])

  const pageLimit = useMemo(
    () => ({
      left: 0,
      top: 0,
      right: inchToMm(paperSize.width),
      bottom: inchToMm(paperSize.height),
    }),
    [paperSize],
  )

  const getPoints = () => {
    const points = { cut: [], slit: [], crease: [] }
    config.front.forEach(element => {
      if (element.type === 'image') {
        const edges = {
          left: +pxToMm(element.x).toFixed(1),
          top: +pxToMm(element.y).toFixed(1),
          right: +pxToMm(element.x + cardSizeWithRotate.width).toFixed(1),
          bottom: +pxToMm(element.y + cardSizeWithRotate.height).toFixed(1),
        }

        const barcodeWidth = inchToMm(0.5)
        edges[config.barcode_position] = +(edges[config.barcode_position] - barcodeWidth).toFixed(1)

        if (!points.cut.includes(edges.left) && edges.left > pageLimit.left) {
          points.cut.push(edges.left)
        }
        if (!points.cut.includes(edges.right) && edges.right < pageLimit.right) {
          points.cut.push(edges.right)
        }
        if (!points.slit.includes(edges.top) && edges.top > pageLimit.top) {
          points.slit.push(edges.top)
        }
        if (!points.slit.includes(edges.bottom) && edges.bottom < pageLimit.bottom) {
          points.slit.push(edges.bottom)
        }

        let creasePoint = null
        if (cardDimensions.orientation !== 'F') {
          if (
            (cardDimensions.orientation === 'P' && !config.card_rotate) ||
            (cardDimensions.orientation === 'L' && config.card_rotate)
          ) {
            creasePoint = edges.left + +pxToMm(cardSizeWithRotate.width / 2) + barcodeWidth
          } else {
            creasePoint = edges.top + +pxToMm(cardSizeWithRotate.height / 2) + barcodeWidth
          }
          creasePoint = +creasePoint.toFixed(1)
        }

        if (creasePoint && !points.crease.includes(creasePoint)) {
          points.crease.push(creasePoint)
        }
      }
    })
    return points
  }

  const tableData = useMemo(() => {
    const points = getPoints()
    return {
      cut: [...points.cut].sort((a, b) => a - b),
      slit: [...points.slit].sort((a, b) => a - b),
      crease: [...points.crease].sort((a, b) => a - b),
      creaseDirection:
        (cardDimensions.orientation === 'P' && !config.card_rotate) ||
        (cardDimensions.orientation === 'L' && config.card_rotate)
          ? 'vertical'
          : 'horizontal',
    }
  }, [config, cardDimensions, cardSizeWithRotate, pageLimit])

  const renderTable = (title, dataKey, getColumns = getNumberArray) => {
    if (tableData[dataKey].length) {
      return (
        <Table
          title={() => <Typography.Text strong>{title}:</Typography.Text>}
          pagination={false}
          columns={getColumns(tableData[dataKey].length).map((col, index) => ({
            title: typeof col === 'number' ? col + 1 : col,
            dataIndex: `${dataKey}_${index}`,
            key: `${dataKey}_${index}`,
          }))}
          dataSource={[
            tableData[dataKey].reduce((acc, value, index) => {
              acc[`${dataKey}_${index}`] = value
              acc.key = `${dataKey}_${index}`
              return acc
            }, {}),
          ]}
          size="small"
        />
      )
    }
    return null
  }

  return (
    <div>
      {/* horizontal cut lines */}
      {renderTable('Slit', 'slit', getAlphabetArray)}

      {/* vertical cut lines */}
      {renderTable('Cut', 'cut')}

      {/* fold lines */}
      {cardDimensions.orientation !== 'F' &&
        renderTable(`Crease (${tableData.creaseDirection})`, 'crease')}
    </div>
  )
}

export default CuttingMarkupSection
