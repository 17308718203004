import React, { useEffect, useState, useMemo } from 'react'
import { Button, Input, Table } from 'antd'
import Text from 'antd/es/typography/Text'
import { useBatchOrdersTable } from 'hooks/batch-orders/useBatchOrdersTable'
import { useDispatch, useSelector } from 'react-redux'
import BatchOrdersTable from '../../../../../components/batch-orders-table'
import { batchOrdersActions } from '../../../../../_actions'

import '../../../../../components/batch-orders-table/style.scss'

const { TextArea } = Input

const NoteComponent = ({ row }) => {
  const { note, id, card_id, childrenNodes, modCardId } = row

  const dispatch = useDispatch()
  const [noteOrder, setNoteOrder] = useState(note)

  const saveNoteOrder = text => dispatch(batchOrdersActions.saveNote(id, card_id, text))
  const selectedChildren = useSelector(state => state.batchOrders.selectedRows[modCardId]?.rows)

  const childrenCount = selectedChildren?.filter(i => i.id === id)[0]?.childrenNodes.length || 0

  return (
    <div className="w-100 d-flex">
      <span
        style={{
          alignItems: 'center',
          justifyContent: 'flex-start',
          display: 'flex',
          position: 'relative',
          left: -20,
        }}
      >
        ({childrenCount} / {childrenNodes.length})
      </span>
      <TextArea
        rows={1}
        placeholder="No notes"
        className="w-75 me-auto"
        value={noteOrder}
        onChange={event => setNoteOrder(event.target.value)}
      />
      <Button type="primary" className="align-self-end" onClick={() => saveNoteOrder(noteOrder)}>
        Save
      </Button>
    </div>
  )
}

// eslint-disable-next-line no-unused-vars
const renderBulk = (text, row, index) => {
  const obj = {
    children: text,
    props: { colSpan: 1 },
  }

  if (row.is_bulk) {
    obj.props.colSpan = 7
    obj.children = <NoteComponent row={row} />
  }

  if (row.key === 'more') {
    obj.props.colSpan = 1
    obj.children = (
      <div className="w-100 d-flex">
        <span
          onClick={() => row.onChange(row.cardId)}
          style={{
            cursor: 'pointer',
            color: 'blue',
            textDecoration: 'underline',
          }}
        >
          more...
        </span>
      </div>
    )
  }
  return obj
}

// eslint-disable-next-line no-unused-vars
const renderNonBulk = (text, row, index) => {
  const obj = {
    children: text || null,
    props: { colSpan: 1 },
  }
  if (row.is_bulk) {
    obj.props.colSpan = 0
    obj.children = row.note
  }
  return obj
}

const initColumns = [
  {
    title: 'Id',
    dataIndex: 'id',
    width: 130,
    render: id => {
      return {
        children: (
          <Text
            style={{ cursor: 'pointer' }}
            onClick={() => window.open(`/orders/view/${id}`, '_blank')}
            strong
            underline
          >
            {id}
          </Text>
        ),
      }
    },
  },
  {
    title: 'Users Email',
    dataIndex: 'user_email',
    width: 200,
    // eslint-disable-next-line no-unused-vars,react/display-name
    render: renderBulk,
  },
  {
    title: 'Redo',
    dataIndex: 'redo',
    width: 75,
    render: renderNonBulk,
  },
  {
    title: 'Gift Card',
    dataIndex: 'gcard',
    render: renderNonBulk,
  },
  {
    title: 'Custom Inserts',
    dataIndex: 'custom_inserts',
    render: renderNonBulk,
  },
  {
    // Order has international shipping
    title: 'Intl',
    dataIndex: 'intl',
    width: 75,
    render: renderNonBulk,
  },
  {
    title: 'From Name',
    dataIndex: ['from', 'name'],
    width: 100,
    render: renderNonBulk,
  },
  {
    title: 'To Name',
    dataIndex: ['to', 'name'],
    width: 100,
    render: renderNonBulk,
  },
  {
    title: 'Date Send',
    dataIndex: 'date_send',
    render: renderNonBulk,
  },
  {
    title: 'Date Created',
    dataIndex: 'date_created',
    render: renderNonBulk,
  },
]

const OrdersTable = ({
  cardId,
  is_redo,
  is_ups,
  deliveryConfirmation,
  rateId,
  is_gift_card,
  is_insert,
  dateSend,
  isCardPrinted,
  isPrintedList,
}) => {
  const dispatch = useDispatch()
  const tableData = useSelector(state => state.batchOrders.ordersByCardId[cardId])
  const { paidOrdersByDate, paidOrders, printedOrders } = useSelector(state => state.batchOrders)
  const activePanel = useSelector(state => state.batchOrders.activePanel[cardId])
  const selectedRowsWithButton = useSelector(state =>
    state.batchOrders.rowsWithButton ? state.batchOrders.rowsWithButton[cardId] : null,
  )

  const [data, setData] = useState([])

  const card_id = useMemo(() => {
    const _Index = cardId.indexOf('_')
    return cardId.slice(0, _Index)
  }, [cardId])

  const {
    selectedRowKeys,
    rowClassName,
    selectChildrenNodes,
    configureSelection,
    selectedOrders,
    totalLength,
    defaultSelectAll,
    countSelect,
  } = useBatchOrdersTable(data)

  useEffect(() => {
    if (!tableData && activePanel)
      dispatch(
        batchOrdersActions.getByCardId(
          cardId,
          is_redo,
          is_ups,
          rateId,
          deliveryConfirmation,
          is_gift_card,
          is_insert,
          dateSend,
          isCardPrinted,
        ),
      )
  }, [activePanel, tableData])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (data && selectedRowsWithButton) {
      if (selectedRowsWithButton.count === totalLength()) {
        return defaultSelectAll(data)
      }

      if (selectedRowsWithButton.count < totalLength()) {
        return countSelect(data, selectedRowsWithButton.count)
      }
    }
  }, [data, selectedRowsWithButton, cardId])

  useEffect(() => {
    if (data) {
      if (selectedRowKeys?.length > 0 || selectedOrders?.length > 0) {
        const ordersByDate = Object.values(paidOrdersByDate)?.flat() || []

        const orientation = [...ordersByDate, ...paidOrders, ...printedOrders].find(
          order => order.id.toString() === card_id,
        )?.orientation

        dispatch(
          batchOrdersActions.saveSelectedRows({
            card_id: cardId,
            rows: filterSelectedRows(data, selectedOrders),
            count: countSelectedRows(data, selectedOrders),
            ids: selectedOrders,
            orientation,
            isPrintedList,
          }),
        )
      } else {
        dispatch(batchOrdersActions.clearSelectedRows(cardId))
      }
    }
  }, [selectedRowKeys, selectedOrders])

  /**
   * Filters table data by selected rows keys
   * @param tData
   * @param rowKeys
   * @returns {*} filtered orders and its children
   */
  const filterSelectedRows = (tData, rowKeys) => {
    return tData
      .filter(
        fRow =>
          rowKeys.includes(fRow.id) ||
          fRow.childrenNodes?.some(child => rowKeys.includes(child.id)),
      )
      .map(mRow => {
        return {
          ...mRow,
          ...(mRow.childrenNodes && {
            childrenNodes: mRow.childrenNodes.filter(child => rowKeys.includes(child.id)),
          }),
        }
      })
  }

  /**
   * Counts selected rows - all except is_bulk=1, to match totalOrders in Panel
   * @param tData
   * @param rowKeys
   */
  const countSelectedRows = (tData, rowKeys) => {
    return filterSelectedRows(tData, rowKeys).reduce(
      (acc, item) => acc + (item.is_bulk === 1 ? item.childrenNodes?.length || 0 : 1),
      0,
    )
  }

  useEffect(() => {
    if (tableData) {
      const tableDataCopy = JSON.parse(JSON.stringify(tableData))

      tableDataCopy.forEach(row => {
        row.childrenNodes = row.children
        delete row.children
        return row
      })

      setData(tableDataCopy)
    }
  }, [tableData])

  const expandedRowRender = record => {
    const rowSelection = {
      selectedRowKeys: selectedOrders,
      onSelect: selectChildrenNodes,
      hideSelectAll: true,
    }

    return (
      <Table
        rowSelection={rowSelection}
        columns={initColumns}
        dataSource={record.childrenNodes}
        pagination
        className="batch-orders-nested__table"
      />
    )
  }

  return (
    <BatchOrdersTable
      columns={initColumns}
      dataSource={data}
      rowSelection={{
        ...configureSelection,
      }}
      expandable={{
        rowExpandable: record => record?.childrenNodes?.length,
        expandedRowRender,
      }}
      rowClassName={rowClassName}
      // pagination
    />
  )
}

export default OrdersTable
