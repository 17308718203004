import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { Alert, Button, Form, Input, Layout, Space } from 'antd'
import { userActions } from '_actions'
import Footer from '../../../components/cleanui/layout/Footer'

const { Content } = Layout

const ResetPassword = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const history = useHistory()

  const isSentEmail = useSelector(state => state.users.sentMailToResetPassword)

  const moveToLogin = () => {
    history.push('/login')
  }

  const onFinish = ({ email }) => {
    dispatch(userActions.sentEmailToResetPassword({ email }))
  }

  return (
    <Layout>
      <Content>
        <div className="container col-m-6 col-md-offset-3 container-login-form">
          <h2 className="text-center my-lg-2 my-sm-2 my-md-2">Restore Password</h2>
          {isSentEmail ? (
            <div style={{ marginBottom: '10px' }}>
              <Alert
                message="Please check your email. An instructions was sent to your email address."
                type="info"
                showIcon
              />
            </div>
          ) : (
            <Form name="Reset" form={form} onFinish={onFinish} layout="vertical" scrollToFirstError>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'email cannot be blank',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item>
                <Space>
                  <Button type="primary" htmlType="submit">
                    Restore Password
                  </Button>
                  <Button onClick={moveToLogin} style={{ background: '#23bc23', color: '#fff' }}>
                    LogIn
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          )}
        </div>
      </Content>
      <Footer />
    </Layout>
  )
}

export default ResetPassword
