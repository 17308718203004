import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { Button, Checkbox, Form, Input, InputNumber, Modal, Upload } from 'antd'
import { ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import { clientGroupActions as cgActions, fontActions } from '../../../../_actions'
import { fontConstants } from '../../../../_constants'
import { convertFormValuesDeep, getCurrentUrl, parseFileForSetFields } from '../../../../_helpers'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import AdvancedCheckboxList from 'components/advanced-checkbox-list'
import { getClientGroupsRows } from '../../../../_selectors/client-group.selector.js'
import { getClientGroup, getFont } from '../../../../_selectors/fonts.selector.js'

const { formItemLayout, navMenu, renderComponent } = fontConstants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.view, navMenu.delete, navMenu.manage]

const EditItem = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { id } = useParams()

  const [navMenuParse, setNavMenuParse] = useState([])
  const [previewImage, setPreviewImage] = useState(null)
  const [ttfFile, setTtfFile] = useState(null)
  const [centerlineTtfFile, setCenterlineTtfFile] = useState(null)
  const [changed, setChange] = useState(false)

  const font = useSelector(getFont)
  const groups = useSelector(getClientGroup)
  const clientGroups = useSelector(getClientGroupsRows)

  const view = getCurrentUrl(match.url)

  const clientGroupsCheckbox = useMemo(() => {
    return clientGroups?.map(category => ({ value: category.id, label: category.title }))
  }, [clientGroups])

  useEffect(() => {
    dispatch(fontActions.getItemById(id))
    return () => {
      dispatch({ type: fontConstants.SETBYID })
    }
  }, [id, dispatch])

  useEffect(() => {
    dispatch(cgActions.getAll())
  }, [])

  const navigateToView = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(fontActions.deleteItem(font.font_id))
      },
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToView,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit, font])

  useEffect(() => {
    form.setFieldsValue({
      ...font,
      font_file: parseFileForSetFields(font?.font_file),
      font_centerline_file: parseFileForSetFields(font?.font_centerline_file),
      image: parseFileForSetFields(font?.image),
      user_groups: groups?.filter(group => group.value).map(group => group.id),
      visible: font?.visible === 1,
    })
  }, [font, form])

  const normFile = e => {
    let file = e && e.fileList

    if (Array.isArray(e)) file = e
    const reader = new FileReader()
    reader.onload = () => setTtfFile(reader.result)

    if (e.fileList.length) reader.readAsDataURL(file[0].originFileObj)
    else setTtfFile(null)

    return file
  }

  const normCenterlineFile = e => {
    let file = e && e.fileList

    if (Array.isArray(e)) file = e
    const reader = new FileReader()
    reader.onload = () => setCenterlineTtfFile(reader.result)

    if (e.fileList.length) reader.readAsDataURL(file[0].originFileObj)
    else setCenterlineTtfFile(null)

    return file
  }

  const normFilePreviewImage = e => {
    let file = e && e.fileList

    if (Array.isArray(e)) file = e
    const reader = new FileReader()
    reader.onload = () => setPreviewImage(reader.result)

    if (e.fileList.length) reader.readAsDataURL(file[0].originFileObj)
    else setPreviewImage(null)

    return file
  }

  const onFinish = async values => {
    const newObj = {}

    Object.keys(values).forEach(item => {
      if (item === 'font_file') newObj[item] = { ...values.font_file?.[0], buffer: ttfFile }
      else if (item === 'font_centerline_file')
        newObj[item] = { ...values.font_centerline_file?.[0], buffer: centerlineTtfFile }
      else if (item === 'image') newObj[item] = { ...values.image?.[0], thumbUrl: previewImage }
      else newObj[item] = values[item]
    })

    dispatch(fontActions.updateItemById(+font.font_id, convertFormValuesDeep(newObj)))
  }

  const props = {
    showUploadList: {
      showRemoveIcon: false,
    },
  }

  const previewImageImg = useMemo(() => {
    if (font) return renderComponent.image(font.image)
    return ''
  }, [font])

  return (
    <NavContainer menu={navMenuParse}>
      {useGlobalLoader(
        <div>
          <p>Fields with * are required.</p>
          <Form
            {...formItemLayout}
            name="basic"
            form={form}
            onFinish={onFinish}
            onFieldsChange={() => setChange(true)}
            scrollToFirstError
          >
            <Form.Item label="Unique Font ID (example: astDunn)" name="id">
              <Input disabled />
            </Form.Item>

            <Form.Item label="Font Name" name="font_name">
              <Input disabled />
            </Form.Item>

            <Form.Item label="Font Title" name="label">
              <Input disabled />
            </Form.Item>

            <Form.Item
              label="Line Spacing"
              name="line_spacing"
              rules={[
                {
                  required: true,
                  message: 'Please input line spacing!',
                },
              ]}
            >
              <InputNumber step={0.1} />
            </Form.Item>

            <AdvancedCheckboxList
              formItemProps={{
                label: 'Customer Groups',
                name: 'user_groups',
                valuePropName: 'value',
              }}
              buttonText="Add Customer Group"
              options={clientGroupsCheckbox}
              form={form}
              onChangeValues={() => setChange(true)}
              initialValues={groups?.filter(group => group.value).map(group => group.id)}
            />

            <Form.Item
              label="Font File (ttf)"
              name="font_file"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: true,
                  message: 'Please input Font File!',
                },
              ]}
            >
              <Upload
                {...props}
                accept=".ttf"
                maxCount={1}
                beforeUpload={file => {
                  console.log(file)
                  return false
                }}
                action=""
              >
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>

            <Form.Item
              label="Centerline Font File (ttf)"
              name="font_centerline_file"
              valuePropName="fileList"
              getValueFromEvent={normCenterlineFile}
              rules={[
                {
                  required: true,
                  message: 'Please input Centerline Font File!',
                },
              ]}
            >
              <Upload
                {...props}
                accept=".ttf"
                maxCount={1}
                beforeUpload={file => {
                  console.log(file)
                  return false
                }}
                action=""
              >
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>

            {/* <Form.Item
              label="Font File (eot - optional)"
              name="font_eot_file"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload {...props} accept=".eot">
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item> */}

            <Form.Item
              name="image"
              label="Image"
              valuePropName="file"
              getValueFromEvent={normFilePreviewImage}
              rules={[
                {
                  required: true,
                  message: 'Please input Preview Image!',
                },
              ]}
            >
              <Upload
                name="image"
                maxCount={1}
                beforeUpload={file => {
                  console.log(file)
                  return false
                }}
                action=""
                listType="picture"
              >
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>
            {font?.image && (
              <Form.Item label="Preview image">
                <div>
                  {previewImageImg}
                  {/* eslint-disable-next-line react/jsx-no-target-blank */}
                  <p>
                    <a
                      href={font.image}
                      download="template"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      (Click to download template)
                    </a>
                  </p>
                </div>
              </Form.Item>
            )}
            <Form.Item label="Sort" name="sort">
              <Input />
            </Form.Item>

            <Form.Item label="Visible" name="visible" valuePropName="checked">
              <Checkbox />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!changed}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>,
      )}
    </NavContainer>
  )
}

export default EditItem
