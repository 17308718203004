import React, { useEffect, useMemo } from 'react'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay'
import { useDispatch, useSelector } from 'react-redux'
import { StylesProvider } from '@material-ui/core/styles'
import { alertActions, userActions } from '_actions'
import { Alert } from 'antd'
import { history } from '../_helpers'

import AppLayout from '../containers/layouts'
import { ChangePassword, LoginPage, QaSystem, RegisterPage, ResetPassword } from '../pages'

const PrivateRoute = ({ token, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      token ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      )
    }
  />
)

const App = props => {
  const dispatch = useDispatch()

  const { match } = props

  const { isGlobalLoading } = useSelector(state => state.spinner)
  const alert = useSelector(state => state.alerts.alert)
  const typeResponse = useSelector(state => state.alerts.typeResponse)
  const alertStatus = useSelector(state => state.alerts.alertStatus)
  const userId = useSelector(state => state.authentication?.user?.id)
  const locationChange = useSelector(state => state.router?.location?.pathname)

  const token = JSON.parse(localStorage.getItem('user'))?.activkey || ''

  const authData = useMemo(() => {
    // if (userId && token) {
    //   const path = sessionStorage.getItem('path')
    //   if (path) history.push(sessionStorage.getItem('path'))
    return !!(userId && token)
    // }
    // return false
  }, [userId, token])

  // when change location, clear alerts. When delete item, dont clear
  useEffect(() => {
    if (locationChange && typeResponse !== 'skipClear' && alert) {
      dispatch(alertActions.clear())
    }

    if (alertStatus && locationChange !== '/my-orders') {
      dispatch(alertActions.statusClear())
    }
  }, [locationChange])

  useEffect(() => {
    if (userId) dispatch(userActions.getUserById(userId))
  }, [])

  return (
    <div>
      {alert?.message && (
        <div className="alert alert-message">
          <Alert
            message={alert?.message}
            type={alert?.type}
            showIcon
            closable
            onClose={() => dispatch(alertActions.clear())}
          />
        </div>
      )}
      <StylesProvider injectFirst>
        <LoadingOverlay
          active={isGlobalLoading}
          spinner
          fadeSpeed={300}
          classNamePrefix="spinner_"
          text="Loading..."
        >
          <Router history={history}>
            <Switch>
              <Route path="/login">{authData ? <Redirect to="/" /> : <LoginPage />}</Route>
              <Route path="/register">{authData ? <Redirect to="/" /> : <RegisterPage />}</Route>
              <Route path="/reset">{authData ? <Redirect to="/" /> : <ResetPassword />}</Route>
              <Route path="/user/reset/:hash">
                {authData ? <Redirect to="/" /> : <ChangePassword />}
              </Route>
              <Route path={`${match.url}completion`} component={QaSystem} />
              <PrivateRoute token={token} path={`${match.url}`} component={AppLayout} />
              <Redirect to="/" />
            </Switch>
          </Router>
        </LoadingOverlay>
      </StylesProvider>
    </div>
  )
}

export default App
