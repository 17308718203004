import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { Button, DatePicker, Form, Input, InputNumber, Modal, Select } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import NavContainer from '../../../../components/nav-container'
import { prePayCreditsConstants } from '../../../../_constants'
import { prePayCreditsActions } from '../../../../_actions'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import useClientsToOptionsList from 'hooks/clients/useClientsToOptionsList'

const { navMenu, formItemLayout } = prePayCreditsConstants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.view, navMenu.manage]

const EditItem = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { id } = useParams()

  const { item: prePayCredit } = useSelector(state => state.prePayCredits)

  const [navMenuParse, setNavMenuParse] = useState([])
  const [changed, setChange] = useState(false)
  const [isChangedAmount, setChangedAmount] = useState(false)

  useEffect(() => {
    return () => {
      dispatch({ type: prePayCreditsConstants.SETBYID })
    }
  }, [])

  const clientsListOptions = useClientsToOptionsList()

  useEffect(() => {
    dispatch(prePayCreditsActions.getItemById(id))
  }, [dispatch, id])

  const onViewItem = () => {
    history.push({
      pathname: `/credit_cards2/view/${id}`,
    })
  }

  const onListItem = () => {
    history.push({
      pathname: `/credit_cards2/list`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: onViewItem,
          }
        case navMenu.list.key:
          return {
            ...menu,
            url: onListItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  useEffect(() => {
    if (Object.keys(prePayCredit).length) {
      form.setFieldsValue({
        ...prePayCredit,
        expires_at: prePayCredit?.expires_at ? dayjs(prePayCredit?.expires_at) : null,
      })
    }
  }, [prePayCredit, form])

  const onFinish = async value => {
    const { amount, ...rest } = value
    const mergedHoliday = {
      ...rest,
      ...(isChangedAmount ? { amount: amount } : {}),
      expires_at: value?.expires_at?.format('YYYY-MM-DD'),
    }
    const convertedPrePayCredits = convertFormValuesDeep(mergedHoliday)

    await dispatch(prePayCreditsActions.updateItemById(id, convertedPrePayCredits))
  }

  const onAmountChange = useCallback(
    value => {
      setChangedAmount(Number(prePayCredit?.amount) !== value)
    },
    [prePayCredit],
  )

  const range = (start, end) => {
    const result = []
    for (let i = start; i < end; i++) {
      result.push(i)
    }
    return result
  }

  const disabledDateTime = () => {
    return {
      disabledMinutes: () => range(1, 60),
      disabledSeconds: () => range(1, 60),
    }
  }

  return (
    <NavContainer menu={navMenuParse}>
      {useGlobalLoader(
        <>
          <p>Fields with * are required.</p>
          <Form
            {...formItemLayout}
            name="editPayPreCredit"
            form={form}
            onFinish={onFinish}
            onFieldsChange={() => setChange(true)}
            scrollToFirstError
          >
            <Form.Item
              label="User"
              name="user_id"
              rules={[
                {
                  required: true,
                  message: 'Please input User!',
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Select or search a user"
                filterOption={(input, option) => option.label.includes(input.toString())}
                options={clientsListOptions}
              />
            </Form.Item>

            <Form.Item
              label="Amount"
              name="amount"
              rules={[
                {
                  required: true,
                  message: 'Please input amount!',
                },
              ]}
            >
              <InputNumber step={0.1} style={{ width: '146px' }} onChange={onAmountChange} />
            </Form.Item>

            {isChangedAmount && (
              <Form.Item label="Note" name="note">
                <Input />
              </Form.Item>
            )}

            <Form.Item label="Expires At" name="expires_at">
              <DatePicker
                disabledTime={disabledDateTime}
                showTime={{
                  hideDisabledOptions: true,
                }}
              />
            </Form.Item>

            <Form.Item name="create">
              <Button type="primary" htmlType="submit" disabled={!changed}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </>,
      )}
    </NavContainer>
  )
}

export default EditItem
