import { banIpConstants } from '../_constants'

const initialState = {
  items: [],
}

function banIP(state = initialState, { type, payload }) {
  switch (type) {
    case banIpConstants.GETALL_SUCCESS:
      return { ...state, items: payload }
    default:
      return state
  }
}

export default banIP
