import { automationsConstants } from '_constants'

const initialState = {
  items: null,
  item: null,
  orders: null,
}

function automations(state = initialState, { type, payload }) {
  switch (type) {
    case automationsConstants.GETALL_SUCCESS:
      return { ...state, items: payload }
    case automationsConstants.GETBYID_SUCCESS:
      return { ...state, item: payload }
    case automationsConstants.GET_ORDER_SUCCESS:
      return { ...state, orders: payload }
    case automationsConstants.SETBYID:
      return {
        ...state,
        item: null,
      }
    case automationsConstants.SETALL:
      return {
        ...state,
        items: null,
      }
    default:
      return state
  }
}

export default automations
