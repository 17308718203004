import { notification } from 'antd'
import { useMemo } from 'react'

const useAddValueToClipboard = path => {
  const [api, contextHolder] = notification.useNotification()

  const isEditOrViewPage = useMemo(() => {
    if (!path) return false
    if (typeof path === 'string') {
      return path.toLowerCase() === 'edit' || path.toLowerCase() === 'view'
    }
    return path.find(path => path === 'edit' || path === 'view')
  }, [path])

  const addValueToClipboard = (value, name) => {
    navigator.clipboard.writeText(value)
    api.success({
      message: `The ${name || 'id'} ${!name ? value : ''} was copied`,
      placement: 'topLeft',
    })
  }

  return {
    contextHolder,
    addValueToClipboard,
    isEditOrViewPage,
  }
}

export default useAddValueToClipboard
