const notesConstants = {
  GETALL_REQUEST: 'CLIENT_NOTES_GETALL_REQUEST',
  GETALL_SUCCESS: 'CLIENT_NOTES_SUCCESS',
  GETALL_FAILURE: 'CLIENT_NOTES_FAILURE',

  GETBYID_REQUEST: 'CLIENT_NOTES_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'CLIENT_NOTES_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'CLIENT_NOTES_GETBYID_FAILURE',

  DELETE_REQUEST: 'CLIENT_NOTES_DELETE_REQUEST',
  DELETE_SUCCESS: 'CLIENT_NOTES_DELETE_SUCCESS',
  DELETE_FAILURE: 'CLIENT_NOTES_DELETE_FAILURE',

  UPDATE_REQUEST: 'CLIENT_NOTES_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CLIENT_NOTES_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CLIENT_NOTES_UPDATE_FAILURE',

  CREATE_REQUEST: 'CLIENT_NOTES_CREATE_REQUEST',
  CREATE_SUCCESS: 'CLIENT_NOTES_CREATE_SUCCESS',
  CREATE_FAILURE: 'CLIENT_NOTES_CREATE_FAILURE',

  GETCOUNT_REQUEST: 'CLIENT_NOTES_COUNT_REQUEST',
  GETCOUNT_SUCCESS: 'CLIENT_NOTES_COUNT_SUCCESS',
  GETCOUNT_FAILURE: 'CLIENT_NOTES_COUNT_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',
  SETCOUNT: 'SETCOUNT',

  navMenu: {
    view: { key: 'view', title: 'View Note', url: '' },
    edit: { key: 'edit', title: 'Update Note', url: '' },
    manage: { key: 'manage', title: 'Manage Notes', url: '/client-notes' },
    list: { key: 'list', title: 'List Notes', url: '' },
    create: { key: 'create', title: 'Create Note', url: '' },
    delete: { key: 'delete', title: 'Delete Note', url: '' },
  },

  advancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'Emplyee', key: 'employee_id' },
    { label: 'Timestamp', key: 'timestamp' },
    { label: 'Note', key: 'note' },
  ],

  listItemKeyToLabel: {
    id: 'ID',
    user: 'User',
    note: 'Note',
    employee: 'Employee',
    timestamp: 'Timestamp',
  },

  viewItemKeyToLabel: {
    id: 'ID',
    user: 'User',
    note: 'Note',
    employee_id: 'Employee',
    timestamp: 'Timestamp',
  },

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },
}
export default notesConstants
