/* eslint-disable react/jsx-boolean-value */
import React, { memo } from 'react'
import { Table } from 'antd'

// eslint-disable-next-line react/display-name
const BatchOrdersTable = memo(
  ({ columns, dataSource, rowSelection, expandable, rowClassName, onRow, rowKey }) => {
    return (
      <Table
        virtual
        scroll={{ x: 900 }}
        columns={columns}
        dataSource={dataSource}
        pagination={{
          showSizeChanger: true,
        }}
        rowSelection={rowSelection}
        expandable={expandable}
        rowClassName={rowClassName}
        onRow={onRow}
        rowKey={rowKey}
        // tableLayout="fixed"
      />
    )
  },
)

export default BatchOrdersTable
