import React, { useState } from 'react'
import { Form, Modal, Spin } from 'antd'
import ShippingLabelsForm from 'components/shipping-labels-form'

const GenerateLabelModal = ({
  orderId,
  isVisible,
  initialValues,
  onCancel,
  currentOrder,
  setCurrentId,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [form] = Form.useForm()

  const onCloseModal = () => {
    setCurrentId(null)
    onCancel()
    form.resetFields()
  }

  return (
    <Modal
      title="Generate Labels"
      maskClosable={false}
      open={isVisible}
      footer={[]}
      onCancel={onCloseModal}
      destroyOnClose
      styles={{ body: { overflowY: 'auto', maxHeight: '70vh', paddingRight: '0.5rem' } }}
    >
      {isLoading ? (
        <div className="container-spinner">
          <Spin />
        </div>
      ) : null}
      <ShippingLabelsForm
        setIsLoading={setIsLoading}
        form={form}
        currentOrder={currentOrder}
        setActive={onCloseModal}
        orderId={orderId}
        initialValues={initialValues}
      />
    </Modal>
  )
}

export default GenerateLabelModal
