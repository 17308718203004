import axios from 'axios'
import { v1 } from './api'

import apiUrl from './service'

const baseUrl = `${apiUrl}/${v1}`
const authService = {
  login,
  logout,
  register,
  getItemById,
  changePassword,
  updateUser,
  sentEmailToResetPassword,
  restorePassword,
}

async function login(user) {
  const result = await axios.post(`${baseUrl}/auth/login`, JSON.stringify(user))
  return result
}

async function logout() {
  await axios.post(`${baseUrl}/auth/logout`)
  localStorage.removeItem('user')
  localStorage.removeItem('sampleCardData')
}

async function register(user) {
  const result = await axios.post(`${baseUrl}/auth/registration`, JSON.stringify(user))
  return result
}

async function changePassword(userPassword, userId) {
  const result = await axios.put(
    `${baseUrl}/profile/${userId}/changePass`,
    JSON.stringify(userPassword),
  )
  return result
}

async function updateUser(userInfo, userId) {
  const result = await axios.put(`${baseUrl}/profile/${userId}/edit`, JSON.stringify(userInfo))
  return result
}

async function getItemById(id) {
  const result = await axios.get(`${baseUrl}/profile/${id}`)
  return handleResponse(result)
}

async function sentEmailToResetPassword(email) {
  const result = await axios.post(`${baseUrl}/auth/reset`, JSON.stringify(email))
  return result
}

async function restorePassword({ password, passwordConfirmation }, token) {
  const result = await axios.put(
    `${baseUrl}/auth/reset/${token}`,
    JSON.stringify({ password, passwordConfirmation }),
  )
  return result
}

const handleResponse = result => {
  return result.data
}

export default authService
