import { multiStepConstants } from '../_constants'

const initialState = {
  items: [],
  item: {},
}

function multiStepCampaigns(state = initialState, { type, payload }) {
  switch (type) {
    case multiStepConstants.GETALL_SUCCESS:
      return { ...state, items: payload, item: {} }
    case multiStepConstants.GETBYID_SUCCESS:
      return { ...state, item: payload }
    case multiStepConstants.SETBYID:
      return {
        ...state,
        item: {},
      }
    case multiStepConstants.SETALL:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default multiStepCampaigns
