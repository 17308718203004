import { qaConstants } from '_constants'
import { history } from '_helpers'
import { qaService } from '_services'
import alertActions from './alert.actions'

const assignManifest = id => async dispatch => {
  dispatch({ type: qaConstants.ASSIGN_MANIFEST_REQUEST })

  try {
    const result = await qaService.assignManifest(id)

    if (result) {
      setTimeout(() => {
        history.push(`/completion/manifest/view/${id}`)
      }, 100)
    }

    dispatch({ type: qaConstants.ASSIGN_MANIFEST_SUCCESS, payload: result })
  } catch (error) {
    dispatch({ type: qaConstants.ASSIGN_MANIFEST_FAILURE, payload: error.toString() })
  }
}

const submitQa = (id, payload) => async dispatch => {
  dispatch({ type: qaConstants.SUBMIT_QA_REQUEST })

  try {
    const result = await qaService.submitQa(id, payload)
    dispatch({ type: qaConstants.SUBMIT_QA_SUCCESS, payload: result })

    history.push(`/completion/manifest`)
    dispatch(alertActions.success(result.message))
    dispatch({ type: qaConstants.SETALL })
  } catch (error) {
    dispatch({ type: qaConstants.SUBMIT_QA_FAILURE, payload: error.toString() })
  }
}

const updateManifestOrders = (id, payload) => async dispatch => {
  dispatch({ type: qaConstants.UPDATE_QA_REQUEST })

  try {
    const result = await qaService.updateManifestOrders(id, payload)

    dispatch({ type: qaConstants.UPDATE_QA_SUCCESS, payload: result })

    dispatch(assignManifest(id))
  } catch (error) {
    dispatch({ type: qaConstants.UPDATE_QA_FAILURE, payload: error.toString() })
  }
}

const qaActions = {
  assignManifest,
  submitQa,
  updateManifestOrders,
}

export default qaActions
