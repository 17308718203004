import React, { useState } from 'react'
import { QrcodeOutlined } from '@ant-design/icons'
import { Button, Form, InputNumber, Typography } from 'antd'
import { QrReader } from 'react-qr-reader'
import { useDispatch } from 'react-redux'
import { qaActions } from '_actions'

import '../style.scss'

const ManifestForm = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [qrReaderActive, setQrReaderActive] = useState(false)

  const closeScannerHandler = () => {
    setQrReaderActive(false)
    try {
      navigator.getUserMedia(
        { audio: false, video: true },
        function(stream) {
          const track = stream.getTracks()[0] // if only one media track
          track.stop()
        },
        function(error) {
          console.log('getUserMedia() error', error)
        },
      )
    } catch (e) {
      console.loge('e', e)
    }
  }

  const qrScannerButton = (
    <Button onClick={() => setQrReaderActive(true)}>
      <QrcodeOutlined />
    </Button>
  )

  const handleQrCodeResult = (result) => {
    if (result) {
      const resultString = result?.text || result.getText()

      form.setFieldValue('manifest_id', resultString)
      closeScannerHandler()
    }
  }

  if (qrReaderActive) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '80vh',
        }}
      >
        <QrReader
          constraints={{ facingMode: 'environment' }}
          scanDelay={1000}
          onResult={handleQrCodeResult}
          containerStyle={{ width: '90%', marginBottom: '1rem' }}
        />
        <Button onClick={() => setQrReaderActive(false)}>Cancel</Button>
      </div>
    )
  }

  const onFinish = async values => {
    await dispatch(qaActions.assignManifest(values.manifest_id))
  }

  return (
    <div className="qa-page">
      <Form layout="vertical" name="qa-manifest" form={form} onFinish={onFinish} scrollToFirstError>
        <Typography.Title level={2}>Manifest</Typography.Title>
        <Form.Item
          label="ID"
          name="manifest_id"
          rules={[
            {
              required: true,
              message: 'ID cannot be blank.',
            },
          ]}
        >
          <InputNumber
            step={1}
            size="large"
            min={0}
            addonAfter={qrScannerButton}
            style={{ width: '100%' }}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" size="large" htmlType="submit" block>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default ManifestForm
