import React from 'react'
import { Checkbox } from 'antd'

const ConfirmWarningCheckbox = ({ checked, field, visible, setAllow }) => {
  if (!visible) return null
  return (
    <Checkbox
      checked={checked}
      onChange={() =>
        setAllow(prev => ({
          ...prev,
          [field]: !prev[field],
        }))
      }
    >
      I understand the risks involved in changing the default values.
    </Checkbox>
  )
}

export default ConfirmWarningCheckbox
