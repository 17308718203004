import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import v4 from 'uuid/v4'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import { dimensionCardConstants } from '../../../../_constants'
import { cardDimensionActions } from '../../../../_actions'
import {
  getCardDimensions,
  getPage,
  getTotalItems,
} from '../../../../_selectors/card-dimension.selector.js'

const { navMenu, cardStatuses, cardOrientation, cardCanCustomize } = dimensionCardConstants

const statusRender = text => {
  return `${cardStatuses.find(st => st.value.toString() === text.toString())?.text}`
}

const orientationRender = text => {
  return `${cardOrientation.find(orientation => orientation.value === text)?.text}`
}

const canCustomizeRended = value => {
  return `${cardCanCustomize.find(canCustomize => canCustomize.value === value)?.text}`
}

const initColumns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: statusRender,
    filters: cardStatuses,
    width: '10%',
    sorter: true,
  },
  {
    title: 'Open Width',
    dataIndex: 'open_width',
    key: 'open_width',
    sorter: true,
  },
  {
    title: 'Open Height',
    dataIndex: 'open_height',
    key: 'open_height',
    sorter: true,
  },
  {
    title: 'Card Orientation',
    dataIndex: 'orientation',
    key: 'orientation',
    render: orientationRender,
    filters: cardOrientation,
    sorter: true,
  },
  {
    title: 'Can Customize',
    dataIndex: 'can_customize',
    key: 'can_customize',
    render: canCustomizeRended,
    filters: cardCanCustomize,
    sorter: true,
  },
  {
    title: 'Character Count',
    dataIndex: 'character_count',
    key: 'character_count',
    sorter: true,
  },
]

const navMenuInit = [navMenu.create]

const ManageItems = ({ match }) => {
  const dispatch = useDispatch()

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getCardDimensions)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  useEffect(() => {
    return () => {
      dispatch({ type: dimensionCardConstants.SETALL })
    }
  }, [])

  useEffect(() => {
    setNavMenuParse([...navMenuInit])
  }, [navMenuInit])

  const deleteItems = async id => {
    await dispatch(cardDimensionActions.deleteItem(id))
  }

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  return (
    <NavContainer match={match} menu={navMenuParse}>
      <TableFilter
        deleteItems={deleteItems}
        columns={initColumns}
        data={dataSource}
        getDataAction={cardDimensionActions.getAll}
      />
    </NavContainer>
  )
}

export default ManageItems
