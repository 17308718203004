const campaignStepsConstants = {
  GETALL_REQUEST: 'CAMPAIGN_STEPS_GETALL_REQUEST',
  GETALL_SUCCESS: 'CAMPAIGN_STEPS_SUCCESS',
  GETALL_FAILURE: 'CAMPAIGN_STEPS_FAILURE',

  GETBYID_REQUEST: 'CAMPAIGN_STEPS_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'CAMPAIGN_STEPS_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'CAMPAIGN_STEPS_GETBYID_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    manage: { key: 'manage', title: 'Manage Steps', url: '' },
    view: { key: 'view', title: 'View Step', url: '' },
    manageCampaigns: { key: 'manage-campaign', title: 'Manage Campaign', url: '' },
    viewCampaign: { key: 'view-campaign', title: 'View Campaign', url: '' },
  },

  listItemKeyToLabel: {
    id: 'ID',
    step_name: 'Step Name',
    campaign_id: 'Campaign ID',
  },
  viewItemKeyToLabel: {
    id: 'ID',
    name: 'Step Name',
    multi_campaign_id: 'Campaign ID',
    user: 'User',
    card: 'Card',
    insert: 'Insert',
    denomination: 'Gift Card',
    message: 'Message',
    wishes: 'Wishes',
    font: 'Font',
    delivery_confirmation: 'Delivery Confirmation',
    delay: 'Delay',
  },

  deliveryConfirmationFilters: [
    { value: 1, text: 'YES' },
    { value: 0, text: 'NO' },
  ],

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },
}
export default campaignStepsConstants
