import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import v4 from 'uuid/v4'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import { automationsConstants } from '../../../../_constants'
import automationActions from '_actions/automations.actions'
import { getAddresses } from '../../../../_selectors/automations.selector.js'

const { navMenu } = automationsConstants
const navMenuInit = [navMenu.manage, navMenu.view]

const initColumns = [
  {
    title: 'First Name',
    dataIndex: 'first_name',
    key: 'first_name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Last Name',
    dataIndex: 'last_name',
    key: 'last_name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Birthday Date',
    dataIndex: 'birthday',
    key: 'birthday',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Anniversary Date',
    dataIndex: 'anniversary',
    key: 'anniversary',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
]

const ManageItems = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { automation_id } = useParams()

  const [navMenuParse, setNavMenuParse] = useState([])

  // const { addresses: rows } = useSelector(state => state.automations.item)
  const rows = useSelector(getAddresses)

  useEffect(() => {
    dispatch(automationActions.getItemById(automation_id))
    return () => {
      dispatch({ type: automationsConstants.SETBYID })
    }
  }, [automation_id, dispatch])

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.manage.key:
          return {
            ...menu,
            url: navigateToManageAutomations,
          }
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToViewAutomation,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const navigateToManageAutomations = () => {
    history.push({
      pathname: `/automations`,
    })
  }

  const navigateToViewAutomation = () => {
    history.push({
      pathname: `/automations/view/${automation_id}`,
    })
  }

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        key: v4(),
      })),
      total: rows?.length,
      page: 1,
    }
  }, [rows])

  return (
    <NavContainer menu={navMenuParse}>
      <TableFilter columns={initColumns} data={dataSource} actionsColum={false} />
    </NavContainer>
  )
}

export default ManageItems
