import { queryParamsActions } from '_actions'
import { shopifyConstants } from '../_constants'
import { shopifyService } from '../_services'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: shopifyConstants.GETALL_REQUEST })
  try {
    const shopify = await shopifyService.getAll(params, typeAction)
    dispatch({ type: shopifyConstants.GETALL_SUCCESS, payload: shopify.data })
  } catch (error) {
    dispatch({ type: shopifyConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const getCountOfIssuesShops = (params, typeAction) => async dispatch => {
  dispatch({ type: shopifyConstants.GETBYID_REQUEST })
  try {
    const shopify = await shopifyService.getCountOfIssuesShops(params, typeAction)
    const isShopify = localStorage.getItem('app.menu.selectedKeys').includes('shopify')
    if (isShopify) {
      dispatch(queryParamsActions.setQueryParams({ shop_id: shopify.data.shop_ids.join(',') }))
    }
    dispatch({ type: shopifyConstants.GETISSUES_SUCCESS, payload: shopify.data })
    return shopify.data
  } catch (error) {
    dispatch({ type: shopifyConstants.GETISSUES_FAILURE, payload: error.toString() })
  }
}

const getIssuesShops = (params, typeAction) => async dispatch => {
  dispatch({ type: shopifyConstants.GETISSUES_ITEMS_REQUEST })
  try {
    const shopify = await shopifyService.getIssuesShops(params, typeAction)
    dispatch({ type: shopifyConstants.GETISSUES_ITEMS_SUCCESS, payload: shopify.data })
  } catch (error) {
    dispatch({ type: shopifyConstants.GETISSUES_ITEMS_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: shopifyConstants.GETBYID_REQUEST })

  try {
    const country = await shopifyService.getItemById(id)
    dispatch({ type: shopifyConstants.GETBYID_SUCCESS, payload: country.data })
  } catch (error) {
    dispatch({ type: shopifyConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const reSubscribeShop = (params, typeAction) => async dispatch => {
  dispatch({ type: shopifyConstants.RESUBSCRIBE_SHOP_REQUEST })

  try {
    await shopifyService.reSubscribeShop(params, typeAction)
    dispatch({ type: shopifyConstants.RESUBSCRIBE_SHOP_SUCCESS })
  } catch (error) {
    dispatch({ type: shopifyConstants.RESUBSCRIBE_SHOP_FAILURE, payload: error.toString() })
  }
}

const shopifyActions = {
  getAll,
  getItemById,
  getCountOfIssuesShops,
  getIssuesShops,
  reSubscribeShop,
}

export default shopifyActions
