import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Form, Input } from 'antd'
import NavContainer from '../../../../components/nav-container'
import { templateCategoriesConstants } from '../../../../_constants'
import { templateCategoriesActions } from '../../../../_actions'

const { navMenu, formItemLayout } = templateCategoriesConstants

const navMenuInit = [navMenu.list, navMenu.manage]

const CreateItem = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const onFinish = values => dispatch(templateCategoriesActions.addItem(values))

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      <Form {...formItemLayout} name="basic" form={form} onFinish={onFinish} scrollToFirstError>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input name!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </NavContainer>
  )
}

export default CreateItem
