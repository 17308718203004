import { contextsConstants } from '../_constants'
import { contextsService } from '../_services'
import { history } from '../_helpers'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: contextsConstants.GETALL_REQUEST })
  try {
    const discounts = await contextsService.getAll(params, typeAction)
    dispatch({ type: contextsConstants.GETALL_SUCCESS, payload: discounts.data })
  } catch (error) {
    dispatch({ type: contextsConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const addItem = value => async dispatch => {
  dispatch({ type: contextsConstants.ADD_REQUEST })

  try {
    const context = await contextsService.addItem({ value })
    dispatch({ type: contextsConstants.ADD_SUCCESS, payload: { ...value, id: context.id } })
    history.push(`/contexts/view/${context.id}`)
  } catch (error) {
    dispatch({ type: contextsConstants.ADD_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: contextsConstants.GETBYID_REQUEST })

  try {
    const discount = await contextsService.getItemById(id)
    dispatch({ type: contextsConstants.GETBYID_SUCCESS, payload: discount.data })
  } catch (error) {
    dispatch({ type: contextsConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const deleteItem = id => async dispatch => {
  dispatch({ type: contextsConstants.DELETE_REQUEST })
  try {
    await contextsService.deleteItem(id)
    history.push(`/contexts`)
    dispatch({ type: contextsConstants.DELETE_SUCCESS, payload: id })
  } catch (error) {
    dispatch({ type: contextsConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const updateItemById = (id, value) => async dispatch => {
  dispatch({ type: contextsConstants.UPDATE_REQUEST })

  try {
    const updated = await contextsService.updateItemById(id, value)
    dispatch({ type: contextsConstants.UPDATE_SUCCESS, payload: updated?.message?.toString() })
    history.push(`/contexts/view/${id}`)
  } catch (error) {
    dispatch({ type: contextsConstants.UPDATE_FAILURE, payload: error.toString() })
  }
}

const contextsActions = {
  getAll,
  getItemById,
  updateItemById,
  deleteItem,
  addItem,
}

export default contextsActions
