import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import lodash from 'lodash'

import NavContainer from '../../../../components/nav-container'
import ItemViewer from '../../../../components/item-viewer'
import { campaignStepsActions } from '../../../../_actions'
import { campaignStepsConstants } from '../../../../_constants'
import { getStep } from '../../../../_selectors/campaign-step.selector.js'

const { viewItemKeyToLabel, navMenu } = campaignStepsConstants

const navMenuInit = [navMenu.manage, navMenu.viewCampaign]

const ViewItem = () => {
  const dispatch = useDispatch()
  const { step_id } = useParams()
  const history = useHistory()

  const step = useSelector(getStep)

  const [navMenuParse, setNavMenuParse] = useState([])

  const navigateToCampaign = () => {
    history.push({
      pathname: `/multi-step/view/${step.multi_campaign_id}`,
    })
  }

  const navigateToManageSteps = () => {
    history.push({
      pathname: `/campaign-steps/${step.multi_campaign_id}`,
    })
  }

  useEffect(() => {
    dispatch(campaignStepsActions.getItemById(step_id))
    return () => {
      dispatch({ type: campaignStepsConstants.SETBYID })
    }
  }, [])

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.manage.key:
          return {
            ...menu,
            url: navigateToManageSteps,
          }
        case navMenu.viewCampaign.key:
          return {
            ...menu,
            url: navigateToCampaign,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit, step])

  const viewItem = useMemo(() => {
    if (!lodash.isEmpty(step)) {
      return Object.keys(viewItemKeyToLabel).reduce((obj, sKey) => {
        let itemValue
        if (sKey === 'multi_campaign_id') {
          itemValue = (
            <Link to={`/multi-step/view/${step.multi_campaign_id}`}>{step.multi_campaign_id}</Link>
          )
        } else if (sKey === 'user') {
          itemValue = (
            <Link to={`/clients/edit/${step.user.id}`}>
              [ID: {step.user.id}] {step.user.name}
            </Link>
          )
        } else if (sKey === 'card') {
          itemValue = (
            <Link to={`/cards/edit/${step.card_id}`}>
              [ID: {step.card_id}] {step.card.name}
            </Link>
          )
        } else if (sKey === 'insert') {
          if (step.insert) {
            itemValue = (
              <Link to={`/inserts/edit/${step.insert.id}`}>
                [ID: {step.insert.id}] {step.insert.name}
              </Link>
            )
          } else itemValue = '---'
        } else if (sKey === 'denomination') {
          if (!lodash.isEmpty(step.denomination)) {
            const { nominal, gcard, id } = step.denomination
            itemValue = (
              <Link to={`/denominations/edit/${id}`}>
                [ID: {id}] {gcard.name} ${nominal}
              </Link>
            )
          } else itemValue = '---'
        } else if (sKey === 'font') {
          itemValue = <Link to={`/fonts/edit/${step.font.id}`}>{step.font.font_name}</Link>
        } else if (sKey === 'delivery_confirmation') {
          itemValue = step.delivery_confirmation ? 'YES' : 'NO'
        } else {
          itemValue = step[sKey]
        }
        return {
          ...obj,
          [sKey]: itemValue,
        }
      }, {})
    }
    return {}
  }, [step])

  return (
    <NavContainer menu={navMenuParse}>
      <ItemViewer item={viewItem} keyToLabelMap={viewItemKeyToLabel} />
    </NavContainer>
  )
}

export default ViewItem
