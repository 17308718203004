import { creditCardsConstants } from '../_constants'
import { creditCardsService } from '../_services'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: creditCardsConstants.GETALL_REQUEST })
  try {
    const creditCards = await creditCardsService.getAll(params, typeAction)
    dispatch({ type: creditCardsConstants.GETALL_SUCCESS, payload: creditCards.data })
  } catch (error) {
    dispatch({ type: creditCardsConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const creditCardsActions = {
  getAll,
}

export default creditCardsActions
