import React from 'react'
import { WindowScroller, List as VirtualList, AutoSizer } from 'react-virtualized'

import CronJobDetailsCard from './cron-job-details.card'

import './style.scss'

const CronJobsDetailsList = ({ activeTab, list, group, jobName }) => {
  function renderRow({ index, key, style }) {
    const item = list[index]
    return (
      <div key={key} style={{ ...style, width: '100%' }}>
        <CronJobDetailsCard
          activeTab={activeTab}
          jobData={item}
          deleteAllowed={group === 'completed'}
          jobName={jobName}
        />
      </div>
    )
  }

  return (
    <WindowScroller>
      {({ height, onChildScroll, scrollTop }) => (
        <AutoSizer disableHeight>
          {({ width }) => (
            <VirtualList
              className="jobs-details-list"
              scrollTop={scrollTop}
              onScroll={onChildScroll}
              autoHeight
              height={height}
              width={width}
              rowCount={list.length}
              rowHeight={465}
              rowRenderer={renderRow}
            />
          )}
        </AutoSizer>
      )}
    </WindowScroller>
  )
}

export default CronJobsDetailsList
