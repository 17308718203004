const categoriesConstants = {
  GETALL_REQUEST: 'CATEGORIES_GETALL_REQUEST',
  GETALL_SUCCESS: 'CATEGORIES_SUCCESS',
  GETALL_FAILURE: 'CATEGORIES_FAILURE',

  ADD_REQUEST: 'CATEGORIES_ADD_REQUEST',
  ADD_SUCCESS: 'CATEGORIES_ADD_SUCCESS',
  ADD_FAILURE: 'CATEGORIES_ADD_FAILURE',

  GETBYID_REQUEST: 'CATEGORIES_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'CATEGORIES_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'CATEGORIES_GETBYID_FAILURE',

  DELETE_REQUEST: 'CATEGORIES_DELETE_REQUEST',
  DELETE_SUCCESS: 'CATEGORIES_DELETE_SUCCESS',
  DELETE_FAILURE: 'CATEGORIES_DELETE_FAILURE',

  UPDATE_REQUEST: 'CATEGORIES_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CATEGORIES_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CATEGORIES_UPDATE_FAILURE',

  CATEGORY_PROP: 'category',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    list: { key: 'list', title: 'List Categories', url: '/categories/list' },
    manage: { key: 'manage', title: 'Manage Categories', url: '/categories' },
    create: { title: 'Create Category', key: 'create', url: '/categories/create' },
    edit: { key: 'edit', title: 'Update Category', url: '' },
    delete: { key: 'delete', title: 'Delete Category', url: '' },
    view: { key: 'view', title: 'View Category', url: '' },
  },

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },

  // checkBoxGroupStyle: { display: 'grid', gridGap: 20, maxHeight: '300px', border: '1px solid #e4e9f0', overflowY: 'scroll', padding: '10px 0 10px 10px' },

  listItemKeyToLabel: {
    id: 'ID',
    name: 'Name',
    slug: 'Slug',
    meta_title: 'Meta title',
    meta_description: 'Meta description',
    taxonomy: 'Taxonomy',
  },

  viewItemKeyToLabel: {
    id: 'ID',
    name: 'Name',
    slug: 'Slug',
    meta_title: 'Meta title',
    meta_description: 'Meta description',
    taxonomy: 'Taxonomy',
    icon: 'Icon',
    icon_image: 'Icon Image',
  },
  advancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'Name', key: 'name' },
    { label: 'Slug', key: 'slug' },
    { label: 'Meta title', key: 'meta_title' },
    { label: 'Meta description', key: 'meta_description' },
  ],

  checkedOnCardsPage: [
    { value: 0, text: 'No' },
    { value: 1, text: 'Yes' },
  ],

  taxonomySelects: [
    { value: 'NONE', text: 'NONE' },
    { value: 'VERIZON', text: 'VERIZON' },
    { value: 'CUSTOM', text: 'CUSTOM' },
    { value: 'CUSTOMIZED', text: 'CUSTOMIZED' },
  ],
}
export default categoriesConstants
