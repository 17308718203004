import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, InputNumber, Select } from 'antd'
import NavContainer from '../../../../components/nav-container'
import { clientGroupActions, discountsRulesActions as drActions } from '../../../../_actions'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import { discountsRulesConstants } from '../../../../_constants'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import { getClientGroupsRows } from '../../../../_selectors/client-group.selector.js'

const { formItemLayout, navMenu } = discountsRulesConstants

const navMenuInit = [navMenu.list, navMenu.manage]

const CreateItem = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const clientGroups = useSelector(getClientGroupsRows)

  const clientGroupsSelect = useMemo(() => {
    return clientGroups?.map(group => ({ value: group.id, label: group.title }))
  }, [clientGroups])

  useEffect(() => {
    dispatch(clientGroupActions.getAll())
  }, [])

  const onFinish = async values => {
    const convertedRule = convertFormValuesDeep(values)
    await dispatch(drActions.addItem(convertedRule))
  }

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      {useGlobalLoader(
        <Form {...formItemLayout} name="basic" form={form} onFinish={onFinish} scrollToFirstError>
          <Form.Item label="User Group" name="user_group_id">
            <Select
              showSearch
              options={clientGroupsSelect}
              allowClear
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item
            label="Min Number"
            name="min_number"
            rules={[
              {
                required: true,
                message: 'Please input number!',
              },
            ]}
          >
            <InputNumber step={1} min={0} />
          </Form.Item>

          <Form.Item label="Discount" name="discount">
            <InputNumber step={1} min={0} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>,
      )}
    </NavContainer>
  )
}

export default CreateItem
