import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { getCurrentUrl } from '_helpers'
import { ordersActions } from '../../../../_actions'
import { ordersConstants, settingsConstants } from '../../../../_constants'

import NavContainer from '../../../../components/nav-container'
import ListViewer from '../../../../components/list-viewer'
import { getOrdersRows, getPage, getTotalItems } from '_selectors/orders.selector'

const { listItemKeyToLabel, orderStatuses, navMenu } = ordersConstants

const navMenuInit = [navMenu.manage, navMenu.list, navMenu.availableOrders]

const MyOrder = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getOrdersRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const navigateToManage = () => {
    dispatch({ type: ordersConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}`,
    })
  }

  const navigateToList = () => {
    dispatch({ type: ordersConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}/list`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.manage.key:
          return {
            ...menu,
            url: navigateToManage,
          }
        case navMenu.list.key:
          return {
            ...menu,
            url: navigateToList,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const listItems = useMemo(() => {
    if (rows?.length > 0) {
      return {
        total,
        page,
        rows: rows?.map(item => {
          const res = []
          Object.keys(listItemKeyToLabel).forEach(sKey => {
            const itemLine = { label: listItemKeyToLabel[sKey], key: sKey, value: item[sKey] }
            if (sKey === 'id') {
              itemLine.clickHandler = () => history.push({ pathname: `/orders/edit/${item.id}` })
            } else if (sKey === 'status') {
              itemLine.value =
                orderStatuses.find(status => status.value === item.status)?.text ||
                `No status type found: ${item.status}`
            } else if (sKey === 'inserts') {
              itemLine.value = `${
                item?.inserts[0] ? `${item.inserts[0]?.name} ($${item.inserts[0]?.price})` : '---'
              }`
            } else if (sKey === 'card') {
              if (item?.card?.id) {
                itemLine.clickHandler = () =>
                  history.push({ pathname: `/cards/edit/${item.card.id}` })
                itemLine.value = `[ID:${item?.card?.id}] ${item?.card?.name || '----'}`
              }
            } else if (sKey === 'user') {
              itemLine.clickHandler = () =>
                history.push({ pathname: `/clients/edit/${item.user.id}` })
              itemLine.value = `[ID: ${item?.user?.id}] ${item?.user?.name || '----'}`
            } else if (sKey === 'employee' && item?.employee) {
              itemLine.clickHandler = () =>
                history.push({ pathname: `/employees/edit/${item?.employee?.id}` })
              itemLine.value = `[ID:${item?.employee?.id}] ${item?.employee?.name || '----'}`
            }
            res.push(itemLine)
          })
          return res
        }),
      }
    }
    return { rows: [], total, page }
  }, [rows, total, page])

  return (
    <NavContainer menu={navMenuParse}>
      <ListViewer data={listItems} getDataAction={ordersActions.getAll} />
    </NavContainer>
  )
}

export default MyOrder
