/* eslint-disable no-else-return */
import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Alert, Result } from 'antd'
import StackedLayouts from './stacked-layouts'
import {
  BatchOrders,
  CardImages,
  Cards,
  Categories,
  ClientGroups,
  Clients,
  Countries,
  Coupons,
  DashboardAlpha,
  Denominations,
  DiscountCode,
  DiscountRules,
  Employees,
  Fonts,
  GiftCard,
  Holidays,
  Inserts,
  Notifications,
  Orders,
  PrePayCredits,
  Profile,
  ShareOptions,
  Signatures,
  Subscriptions,
  TaxCodes,
  Template,
  TemplateCategories,
  ShippingMethod,
  ShippingRates,
  BanIp,
  ClientNotes,
  Contexts,
  Automations,
  AutomationOrders,
  AutomationRecipients,
  ShippingConfigs,
  UsaFinancial,
  PrePaidConfigs,
  Shopify,
  MultiStep,
  CampaignSteps,
  PrepaidCreditsType3,
  CardDimension,
  CronJobs,
  LabelsCreation,
  Frames,
  WordMapping,
  ImpositionLayouts,
  PresetCardImages,
} from '../../pages'
import { ListUsaFinancialTriggers } from 'pages/clients/views'

const AppLayout = ({ match }) => {
  const alertStatus = useSelector(state => state.alerts.alertStatus)

  const permis = useSelector(state => state.users.permis)
  const menuData = useSelector(state => state.menu.menuList)

  const permisKeys = useMemo(() => {
    if (permis) return Object.keys(permis)
    return []
  }, [permis])

  const isSuperUser = useMemo(() => {
    return !!permis?.superuser
  }, [permis])

  const permission = useMemo(() => {
    if (isSuperUser) return {}
    return menuData.reduce((acc, cur) => {
      const route = cur.url.split('/')[1]
      if (cur.visible && route) {
        return {
          ...acc,
          [route]: true,
        }
      }
      const hasPermission = cur.permis?.some(value => permisKeys.includes(value))
      if (route) {
        return {
          ...acc,
          [route]: hasPermission,
        }
      }
      return acc
    }, {})
  }, [permis, isSuperUser])

  let alertStatuses
  if (alertStatus) {
    alertStatuses = alertStatus.map(({ key, text, type }) => {
      return (
        <div key={key} style={{ margin: '0 15px 10px -15px', padding: '0' }}>
          <Alert description={text} type={type} />
        </div>
      )
    })
  }

  useEffect(() => {
    if (alertStatus && alertStatus.length !== 0) window.scrollTo(0, 0)
  }, [alertStatus])

  if (!permis) return ''

  return (
    <StackedLayouts>
      {alertStatuses}
      <Switch>
        <Route exact path={match.url} component={DashboardAlpha} />
        <Route
          path={`${match.url}client-groups`}
          component={isSuperUser || permission['client-groups'] ? ClientGroups : RedirectPage}
        />
        <Route
          path={`${match.url}client-notes`}
          component={
            isSuperUser || permisKeys.includes('can_edit_users') ? ClientNotes : RedirectPage
          }
        />
        <Route
          path={`${match.url}clients`}
          component={isSuperUser || permission.clients ? Clients : RedirectPage}
        />
        <Route
          path={`${match.url}usa_financial_triggers`}
          component={isSuperUser || permission.clients ? ListUsaFinancialTriggers : RedirectPage}
        />
        <Route
          path={`${match.url}credit_cards2`}
          component={
            isSuperUser || permisKeys.includes('can_edit_users') ? PrePayCredits : RedirectPage
          }
        />
        <Route
          path={`${match.url}credit_type3`}
          component={
            isSuperUser || permisKeys.includes('can_edit_users')
              ? PrepaidCreditsType3
              : RedirectPage
          }
        />
        <Route
          path={`${match.url}orders`}
          component={isSuperUser || permission.orders ? Orders : RedirectPage}
        />
        <Route
          path={`${match.url}(batch-orders|my-orders)`}
          component={isSuperUser || permission.orders ? BatchOrders : RedirectPage}
        />
        <Route
          path={`${match.url}categories`}
          component={isSuperUser || permission.categories ? Categories : RedirectPage}
        />
        <Route
          path={`${match.url}preset-card-images`}
          component={isSuperUser || permission.cards ? PresetCardImages : RedirectPage}
        />
        <Route
          path={`${match.url}inserts`}
          component={isSuperUser || permission.inserts ? Inserts : RedirectPage}
        />
        <Route
          path={`${match.url}cards`}
          component={isSuperUser || permission.cards ? Cards : RedirectPage}
        />
        <Route
          path={`${match.url}qr-code-frames`}
          component={isSuperUser || permission.frames ? Frames : RedirectPage}
        />
        <Route
          path={`${match.url}card-dimension`}
          component={isSuperUser || permission['card-dimension'] ? CardDimension : RedirectPage}
        />
        <Route
          path={`${match.url}imposition-layouts`}
          component={
            isSuperUser || permission['imposition-layouts'] ? ImpositionLayouts : RedirectPage
          }
        />
        <Route
          path={`${match.url}template-categories`}
          component={
            isSuperUser || permission['template-categories'] ? TemplateCategories : RedirectPage
          }
        />
        <Route
          path={`${match.url}templates`}
          component={isSuperUser || permission.templates ? Template : RedirectPage}
        />
        <Route
          path={`${match.url}gift-cards`}
          component={isSuperUser || permission['gift-cards'] ? GiftCard : RedirectPage}
        />
        <Route
          path={`${match.url}holidays`}
          component={isSuperUser || permission.holidays ? Holidays : RedirectPage}
        />
        <Route
          path={`${match.url}discount-codes`}
          component={isSuperUser || permission['discount-codes'] ? DiscountCode : RedirectPage}
        />
        <Route
          path={`${match.url}coupons`}
          component={isSuperUser || permission.coupons ? Coupons : RedirectPage}
        />
        <Route
          path={`${match.url}fonts`}
          component={isSuperUser || permission.fonts ? Fonts : RedirectPage}
        />
        <Route
          path={`${match.url}discount-rules`}
          component={isSuperUser || permission['discount-rules'] ? DiscountRules : RedirectPage}
        />
        <Route path={`${match.url}user`} component={Profile} />
        <Route
          path={`${match.url}signatures`}
          component={isSuperUser || permission.signatures ? Signatures : RedirectPage}
        />
        <Route
          path={`${match.url}denominations`}
          component={isSuperUser || permission.denominations ? Denominations : RedirectPage}
        />
        <Route
          path={`${match.url}subscriptions`}
          component={isSuperUser || permission.subscriptions ? Subscriptions : RedirectPage}
        />
        <Route
          path={`${match.url}shipping-rates`}
          component={isSuperUser || permission.subscriptions ? ShippingRates : RedirectPage}
        />
        <Route
          path={`${match.url}shipping-methods`}
          component={isSuperUser || permission.subscriptions ? ShippingMethod : RedirectPage}
        />
        <Route
          path={`${match.url}shipping-configs`}
          component={
            isSuperUser || permisKeys.includes('can_view_users') ? ShippingConfigs : RedirectPage
          }
        />
        <Route path={`${match.url}shopify`} component={isSuperUser ? Shopify : RedirectPage} />
        <Route
          path={`${match.url}share-options`}
          component={isSuperUser ? ShareOptions : RedirectPage}
        />
        <Route path={`${match.url}countries`} component={isSuperUser ? Countries : RedirectPage} />
        <Route path={`${match.url}taxcodes`} component={isSuperUser ? TaxCodes : RedirectPage} />
        <Route path={`${match.url}automations`} component={Automations} />
        <Route path={`${match.url}multi-step`} component={MultiStep} />
        <Route path={`${match.url}campaign-steps`} component={CampaignSteps} />
        <Route path={`${match.url}automation-orders`} component={AutomationOrders} />
        <Route path={`${match.url}automation-recipients`} component={AutomationRecipients} />
        <Route
          path={`${match.url}notifications`}
          component={isSuperUser || permission.notifications ? Notifications : RedirectPage}
        />
        <Route path={`${match.url}employees`} component={isSuperUser ? Employees : RedirectPage} />
        <Route path={`${match.url}cron-jobs`} component={isSuperUser ? CronJobs : RedirectPage} />
        <Route path={`${match.url}contexts`} component={isSuperUser ? Contexts : RedirectPage} />
        <Route
          path={`${match.url}credit_cards2`}
          component={isSuperUser || permission.clients ? PrePayCredits : RedirectPage}
        />
        <Route
          path={`${match.url}card-images`}
          component={isSuperUser || permission.cards ? CardImages : RedirectPage}
        />
        <Route
          path={`${match.url}usa-financial`}
          component={isSuperUser ? UsaFinancial : RedirectPage}
        />
        <Route
          path={`${match.url}pre-paid-configs`}
          component={isSuperUser ? PrePaidConfigs : RedirectPage}
        />

        <Route
          path={`${match.url}ban_ip`}
          component={isSuperUser || permission.clients ? BanIp : RedirectPage}
        />
        <Route
          path={`${match.url}client-notes`}
          component={isSuperUser || permission.clients ? ClientNotes : RedirectPage}
        />
        <Route
          path={`${match.url}labels-creation`}
          component={isSuperUser || permission.orders ? LabelsCreation : RedirectPage}
        />
        <Route
          path={`${match.url}word-mapping`}
          component={isSuperUser || permission.orders ? WordMapping : RedirectPage}
        />
        <Route
          path={`${match.url}error`}
          render={() => (
            <RedirectPage
              status="404"
              title="404"
              subTitle="Sorry, the page you visited does not exist."
            />
          )}
        />
        <Redirect to="/error" />
      </Switch>
    </StackedLayouts>
  )
}

export const RedirectPage = ({
  status = '403',
  title = '403',
  subTitle = 'Sorry, you are not authorized to access this page.',
}) => {
  return (
    <Result
      status={status}
      title={title}
      subTitle={subTitle}
      // extra={<Button type="primary">Back Home</Button>}
    />
  )
}

export default AppLayout
