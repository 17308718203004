const banIpConstants = {
  GETALL_REQUEST: 'BAN_IP_GETALL_REQUEST',
  GETALL_SUCCESS: 'BAN_IP_GETALL_SUCCESS',
  GETALL_FAILURE: 'BAN_IP_GETALL_FAILURE',

  SAVE_BAN_IP_REQUEST: 'SAVE_BAN_IP_REQUEST',
  SAVE_BAN_IP_SUCCESS: 'SAVE_BAN_IP_SUCCESS',
  SAVE_BAN_IP_FAILURE: ' SAVE_BAN_IP_FAILURE',
}
export default banIpConstants
