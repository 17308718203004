import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import TableFilter from 'components/table-filter'
import v4 from 'uuid/v4'
import NavContainer from '../../../../components/nav-container'
import { usaFinancialActions } from '../../../../_actions'
import { usaFinancialConstants } from '../../../../_constants'

const { getUsaFinancialTriggers } = usaFinancialActions
const { statuses } = usaFinancialConstants

const statusRender = text => {
  return `${statuses.find(st => st.value.toString() === text.toString())?.text}`
}

const ListUsaFinancialTriggers = () => {
  const initColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
    },
    {
      title: 'User Id',
      dataIndex: 'user_id',
      key: 'user_id',
      width: '10%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status_filter',
      render: statusRender,
      filters: statuses,
      width: '10%',
    },
    {
      title: 'Card Id',
      dataIndex: 'card_id',
      key: 'card_id',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Trigger Id',
      dataIndex: 'trigger_id',
      key: 'trigger_id',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      width: '10%',
    },
    {
      title: 'Redtail Tag',
      dataIndex: 'redtail_tag',
      key: 'redtail_tag',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      width: '10%',
    },
    {
      title: 'All Users',
      dataIndex: 'all_users',
      key: 'all_users',
      render: statusRender,
      filters: statuses,
    },
    {
      title: 'Message',
      dataIndex: 'message',
      filterSearch: true,
      key: 'message',
    },
    {
      title: 'Wishes',
      dataIndex: 'wishes',
      key: 'wishes',
    },
    {
      title: 'Font Id',
      dataIndex: 'font_id',
      key: 'font_id',
    },
  ]

  const { triggers } = useSelector(state => state.usaFinancial)
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch({ type: usaFinancialConstants.SETBYID })
    }
  }, [])

  const dataSource = useMemo(() => {
    if (triggers?.rows?.length > 0) {
      return {
        total: triggers.total,
        page: triggers.page,
        rows: triggers.rows?.map(item => ({
          ...item,
          id: item.id,
          card_id: item?.card_id ? (
            <Link to={`/cards/edit/${item.card_id}`}> {item.card_id} </Link>
          ) : (
            ''
          ),
          font_id: item.font ? (
            <Link to={`/fonts/edit/${item.font.id}`}>{item.font.label}</Link>
          ) : (
            '---'
          ),
          key: v4(),
        })),
      }
    }
    return { rows: [], total: 0, page: 1 }
  }, [triggers])

  return (
    <NavContainer menu={[]}>
      <TableFilter
        columns={initColumns}
        data={dataSource}
        getDataAction={getUsaFinancialTriggers}
        actionsColum={false}
        doubleClick={false}
      />
    </NavContainer>
  )
}

export default ListUsaFinancialTriggers
