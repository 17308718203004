import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import NavContainer from '../../../../components/nav-container'
import ListViewer from '../../../../components/list-viewer'
import { creditCardsActions } from '../../../../_actions'
import { creditCardsConstants } from '../../../../_constants'
import {
  getCreditCardsRows,
  getPage,
  getTotalItems,
} from '../../../../_selectors/credit-cards.selector.js'

const { listItemKeyToLabel, typeCard } = creditCardsConstants

const ListCreditCards = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const rows = useSelector(getCreditCardsRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const user_id = useSelector(state => state.queryParams.where?.user_id)

  if (!user_id) {
    history.push('/error')
  }

  useEffect(() => {
    return () => {
      dispatch({ type: creditCardsConstants.SETALL })
    }
  }, [])

  const listItems = useMemo(() => {
    if (rows?.length > 0) {
      return {
        total,
        page,
        rows: rows.map(item => {
          const res = []
          Object.keys(listItemKeyToLabel).forEach(sKey => {
            const itemLine = { label: listItemKeyToLabel[sKey], value: item[sKey], key: sKey }
            if (sKey === 'type') {
              itemLine.value =
                typeCard.find(type => type.value === item.type)?.text || 'Unknown type card'
            }
            res.push(itemLine)
          })
          return res
        }),
      }
    }
    return { rows: [], total: 0, page: 1 }
  }, [rows, total, page])

  return (
    <NavContainer menu={[]}>
      <ListViewer data={listItems} getDataAction={creditCardsActions.getAll} />
    </NavContainer>
  )
}

export default ListCreditCards
