import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Form, InputNumber, Select } from 'antd'
import NavContainer from '../../../../components/nav-container'
import { prePaidConfigsActions } from '../../../../_actions'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import { prePaidConfigsConstants } from '../../../../_constants'
import { convertDataForSelect } from '_helpers'

const { formItemLayout, navMenu, statuses } = prePaidConfigsConstants

const navMenuInit = [navMenu.list, navMenu.manage]

const CreateItem = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const creditsFrom = Form.useWatch('quantity_from', form)
  const creditsTo = Form.useWatch('quantity_to', form)

  const onFinish = values => {
    dispatch(prePaidConfigsActions.addItem(convertFormValuesDeep(values)))
  }

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      <Form {...formItemLayout} name="basic" form={form} onFinish={onFinish} scrollToFirstError>
        <Form.Item name="status" label="Status" initialValue={1}>
          <Select options={convertDataForSelect(statuses)} />
        </Form.Item>
        <Form.Item
          label="Cards from"
          name="quantity_from"
          rules={[
            {
              required: true,
              message: 'Please input value!',
            },
          ]}
        >
          <InputNumber min={0} max={creditsTo - 1 || null} />
        </Form.Item>

        <Form.Item
          label="Cards to"
          name="quantity_to"
          rules={[
            {
              required: true,
              message: 'Please input value!',
            },
          ]}
        >
          <InputNumber min={creditsFrom + 1 || 0} />
        </Form.Item>

        <Form.Item
          label="Discount"
          name="discount"
          initialValue={0}
          rules={[
            {
              required: true,
              message: 'Please input value!',
            },
          ]}
        >
          <InputNumber min={0} />
        </Form.Item>

        <Form.Item
          name="price_per_card"
          label="Price Per Card"
          initialValue={0}
          rules={[
            {
              required: true,
              message: 'Please input value!',
            },
          ]}
        >
          <InputNumber min={0} />
        </Form.Item>

        <Form.Item
          name="postage"
          label="Postage"
          initialValue={0}
          rules={[
            {
              required: true,
              message: 'Please input value!',
            },
          ]}
        >
          <InputNumber min={0} />
        </Form.Item>

        <Form.Item
          name="credit_active_in_month"
          label="Credit Active (month)"
          initialValue={36}
          rules={[
            {
              required: true,
              message: 'Please input value!',
            },
          ]}
        >
          <InputNumber min={0} defaultValue={36} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </NavContainer>
  )
}

export default CreateItem
