import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import v4 from 'uuid/v4'
import getCurrentUrl from '../../../../_helpers/url-utils'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import { ShippingMethodsActions } from '../../../../_actions'
import { settingsConstants, ShippingMethodsConstants } from '../../../../_constants'
import {
  getShippingMethodsRows,
  getPage,
  getTotalItems,
} from '_selectors/shipping-methods.selector'

const {
  ShippingMethodsStatuses,
  navMenu,
  advancedSearch,
  isShowInOutbound,
} = ShippingMethodsConstants

const statusRender = text => {
  return `${ShippingMethodsStatuses.find(st => st.value.toString() === text.toString())?.text}`
}

const showInOutboundRender = text => {
  return `${isShowInOutbound.find(st => !!st.value === Boolean(text))?.text}`
}

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Name',
    dataIndex: 'method_name',
    key: 'method_name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Label',
    dataIndex: 'method_label',
    key: 'method_label',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Discount',
    dataIndex: 'discount',
    key: 'discount',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Add Postage fee',
    dataIndex: 'add_postage_fee',
    key: 'add_postage_fee',
    sorter: true,
    render: statusRender,
    filters: ShippingMethodsStatuses,
    onFilter: (value, record) => record.status?.toString().indexOf(value) === 0,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Show Options',
    dataIndex: 'show_options',
    key: 'show_options',
    sorter: true,
    render: statusRender,
    filters: ShippingMethodsStatuses,
    onFilter: (value, record) => record.status?.toString().indexOf(value) === 0,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sorter: true,
    render: statusRender,
    filters: ShippingMethodsStatuses,
    onFilter: (value, record) => record.status?.toString().indexOf(value) === 0,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Check Recipient Address',
    dataIndex: 'check_recipient_address',
    key: 'check_recipient_address',
    sorter: true,
    render: statusRender,
    filters: ShippingMethodsStatuses,
    onFilter: (value, record) => record.status?.toString().indexOf(value) === 0,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Check Return Address',
    dataIndex: 'check_return_address',
    key: 'check_return_address',
    sorter: true,
    render: statusRender,
    filters: ShippingMethodsStatuses,
    onFilter: (value, record) => record.status?.toString().indexOf(value) === 0,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Show in Outbound',
    dataIndex: 'show_in_outbound',
    render: showInOutboundRender,
    key: 'show_in_outbound',
    filters: isShowInOutbound,
  },
]

const navMenuInit = [navMenu.list, navMenu.create]

const ManageItems = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)

  const navigateToList = () => {
    dispatch({ type: ShippingMethodsConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}/list`,
    })
  }
  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getShippingMethodsRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)
  const { can_edit_shipping_methods, superuser } = useSelector(state => state.authentication.user)

  const deleteItem = async id => {
    await dispatch(ShippingMethodsActions.deleteItem(id))
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.list.key:
          return {
            ...menu,
            url: navigateToList,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  const statusesSelect = useMemo(() => {
    return ShippingMethodsStatuses.map(status => ({ value: status.value, label: status.text }))
  }, [ShippingMethodsStatuses])

  const updAdvancedSearch = advancedSearch.map(field => {
    if (field.key === 'status_filter') return { ...field, options: statusesSelect }
    return field
  })

  const canEdit = superuser || can_edit_shipping_methods

  return (
    <NavContainer match={match} menu={navMenuParse}>
      <TableFilter
        deleteItems={deleteItem}
        columns={initColumns}
        data={dataSource}
        getDataAction={ShippingMethodsActions.getAll}
        isEditOnId={!!canEdit}
        advancedSearch={updAdvancedSearch}
      />
    </NavContainer>
  )
}

export default ManageItems
