import { ShippingRatesConstants } from '../_constants'
import { ShippingRatesService } from '../_services'
import { history } from '../_helpers'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: ShippingRatesConstants.GETALL_REQUEST })
  try {
    const shippingRates = await ShippingRatesService.getAll(params, typeAction)
    dispatch({ type: ShippingRatesConstants.GETALL_SUCCESS, payload: shippingRates.data })
  } catch (error) {
    dispatch({ type: ShippingRatesConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const addItem = value => async dispatch => {
  dispatch({ type: ShippingRatesConstants.ADD_REQUEST })

  try {
    const shippingRate = await ShippingRatesService.addItem({ value })
    dispatch({
      type: ShippingRatesConstants.ADD_SUCCESS,
      payload: { ...value, id: shippingRate.new.id },
    })

    history.push(`/shipping-rates/view/${shippingRate.new.id}`)
  } catch (error) {
    dispatch({ type: ShippingRatesConstants.ADD_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: ShippingRatesConstants.GETBYID_REQUEST })

  try {
    const shippingRate = await ShippingRatesService.getItemById(id)
    dispatch({ type: ShippingRatesConstants.GETBYID_SUCCESS, payload: shippingRate.data })
  } catch (error) {
    dispatch({ type: ShippingRatesConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const deleteItem = id => async dispatch => {
  dispatch({ type: ShippingRatesConstants.DELETE_REQUEST })
  try {
    await ShippingRatesService.deleteItem(id)
    history.push('/shipping-rates')
    dispatch({ type: ShippingRatesConstants.DELETE_SUCCESS, payload: id })
  } catch (error) {
    dispatch({ type: ShippingRatesConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const updateItemById = (id, value) => async dispatch => {
  dispatch({ type: ShippingRatesConstants.UPDATE_REQUEST })

  try {
    const updated = await ShippingRatesService.updateItemById(id, value)
    dispatch({
      type: ShippingRatesConstants.UPDATE_SUCCESS,
      payload: updated?.message?.toString(),
    })
    history.push(`/shipping-rates/view/${id}`)
  } catch (error) {
    dispatch({ type: ShippingRatesConstants.UPDATE_FAILURE, payload: error.toString() })
  }
}

const ShippingRatesActions = {
  getAll,
  getItemById,
  deleteItem,
  updateItemById,
  addItem,
}

export default ShippingRatesActions
