import { alertConstants } from '../_constants'

const alertActions = {
  success,
  error,
  clear,
  status,
  statusClear,
  modal,
  completeOrderModal,
  clearCompleteOrderModal,
}

function success(message) {
  return { type: alertConstants.SUCCESS, message }
}

function error(message) {
  return { type: alertConstants.ERROR, message }
}

function clear() {
  return { type: alertConstants.CLEAR }
}

function status(messages) {
  const updMessages = messages.map((mes, idx) => ({ key: idx, ...mes }))
  return { type: alertConstants.STATUS, updMessages }
}

function modal(message) {
  return { type: alertConstants.MODAL, payload: message }
}
function completeOrderModal(message) {
  return { type: alertConstants.COMPLETE_MODAL, payload: message }
}

function clearCompleteOrderModal() {
  return { type: alertConstants.CLEAR_COMPLETE_MODAL }
}

function statusClear() {
  return { type: alertConstants.STATUS_CLEAR }
}
export default alertActions
