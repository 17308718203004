import React from 'react'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { cardsActions, ordersActions } from '_actions'
import { useDispatch } from 'react-redux'

const useCardDelete = () => {
  const dispatch = useDispatch()

  const getWarningMessage = async (id, withoutLoader) => {
    const orders = await dispatch(
      ordersActions.getAll(
        {
          offset: 1,
          where: { is_bulk: 0, card_id: id },
        },
        withoutLoader ? 'background' : '',
      ),
    )

    return orders.rows.length ? 'There are orders using this card.' : null
  }

  const deleteCardHandler = async id => {
    console.log('deleteCardHandler FIRE')
    let deleteConfirmationText = 'Are you sure you want to delete this item?'
    const warningMessage = await getWarningMessage(id)

    if (warningMessage) deleteConfirmationText = `${warningMessage} ${deleteConfirmationText}`

    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: deleteConfirmationText,
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(cardsActions.deleteItem(id))
      },
    })
  }

  return {
    deleteCardHandler,
    getWarningMessage,
  }
}

export default useCardDelete
