import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useHistory, useRouteMatch } from 'react-router-dom'

import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Tooltip,
  Upload,
} from 'antd'
import { QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import {
  batchOrdersActions,
  cardDimensionActions,
  cardsActions,
  categoriesActions,
  queryParamsActions,
} from '../../../../_actions'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { appConstants, cardsConstants, messagesConstants } from '../../../../_constants'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import { convertDataForSelect, parseFileForSetFields } from '../../../../_helpers'
import ConfirmWarningCheckbox from 'components/confirm-warning-checkbox'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import useCardDelete from 'hooks/cards/useCardDelete'
import ImposedCardModal from 'components/imposed-card-modal'
import ChangeCategoryConfirmationPopup from 'components/change-category-modal'
import useValidationEmailForNotification from '../../../../hooks/clients/useValidationEmailForNotification.js'
import { getCategoriesRows } from '../../../../_selectors/categories.selector.js'
import {
  getCardById,
  getCategories,
  getTotalImages,
} from '../../../../_selectors/cards.selector.js'
import { getCardDimensions } from '_selectors/card-dimension.selector'

const {
  formItemLayout,
  navMenu,
  fieldsNotNull,
  cardStatuses,
  cardTaxExempt,
  cardCoverRestricted,
  cardAvailableFree,
  cardOrientation,
  cardSupressReturnAddress,
  fieldsToIgnore,
  marginsTooltipMessage,
} = cardsConstants

const { riskWarningMessage } = messagesConstants

const navMenuInit = [
  navMenu.list,
  navMenu.create,
  navMenu.view,
  navMenu.delete,
  navMenu.manage,
  navMenu.images,
  navMenu.pdf,
  navMenu.imposeCards,
  navMenu.imposeCardsPython,
]

const EditItem = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const match = useRouteMatch()
  const { id } = useParams()
  const [form] = Form.useForm()
  const view = getCurrentUrl(match.url)
  const { deleteCardHandler } = useCardDelete()

  const [navMenuParse, setNavMenuParse] = useState([])
  const [isCustomizable, setIsCustomizable] = useState(false)
  // const [isPrintModalActive, setPrintModalActive] = useState(false)
  const [activeModal, setActiveModal] = useState(null)
  const [isChangeCategoryPopupActive, setChangeCategoryPopupActive] = useState(false)
  const [isCustomCategoryChanged, setCustomCategoryChanged] = useState(false)
  const [changed, setChange] = useState(false)
  const [isAllowedChangeDefault, setAllowChangeDefault] = useState({
    preview_margin_top: false,
    preview_margin_bottom: false,
    top_margin_no_logo: false,
    bottom_margin_no_logo: false,
  })
  const [coverImage, setCover] = useState(null)

  const activeCardDimensionId = Form.useWatch('dimension_id', form)

  const { emailWarning, setEmailWarning, validateEmailList } = useValidationEmailForNotification()

  const totalImages = useSelector(getTotalImages)
  const card = useSelector(getCardById)
  const categories = useSelector(getCategories)
  const loadedCategories = useSelector(getCategoriesRows)

  const cardDimensions = useSelector(getCardDimensions)

  const charactersWidth = Form.useWatch('width', form)
  const charactersLines = Form.useWatch('height', form)

  const fetchData = async () => {
    await Promise.all([
      dispatch(categoriesActions.getAll({ order: { key: 'name', order: 'ASC' } })),
      dispatch(cardDimensionActions.getAll({ where: { status: 1 } })),
    ])
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    dispatch(cardsActions.getItemById(id))
    return () => {
      dispatch({ type: cardsConstants.SETBYID })
    }
  }, [id, dispatch])

  useEffect(() => {
    const count = charactersWidth * charactersLines

    form.setFieldValue('character_count', count || 0)
  }, [charactersWidth, charactersLines])

  useEffect(() => {
    if (activeCardDimensionId && cardDimensions.length) {
      const activeCardDimension = cardDimensions.find(dim => dim.id === activeCardDimensionId)

      if (activeCardDimension) {
        const { name, status, character_count, ...dataToSet } = activeCardDimension
        form.setFieldsValue(dataToSet)
      }
    }
  }, [activeCardDimensionId, cardDimensions])

  const cardDimensionsSelect = useMemo(
    () =>
      cardDimensions?.map(dimension => ({
        value: dimension.id,
        label: dimension.name,
      })),
    [cardDimensions],
  )

  const categoriesSelect = useMemo(() => {
    return loadedCategories?.map(category => ({
      value: category.id,
      label: category.name,
      taxonomy: category.taxonomy,
    }))
  }, [loadedCategories])

  useEffect(() => {
    if (isCustomizable && !!card) {
      const defaultValues = {}

      if (!isAllowedChangeDefault.preview_margin_top)
        defaultValues.preview_margin_top = card.preview_margin_top
      if (!isAllowedChangeDefault.preview_margin_bottom)
        defaultValues.preview_margin_bottom = card.preview_margin_bottom
      if (!isAllowedChangeDefault.top_margin_no_logo)
        defaultValues.top_margin_no_logo = card.top_margin_no_logo
      if (!isAllowedChangeDefault.bottom_margin_no_logo)
        defaultValues.bottom_margin_no_logo = card.bottom_margin_no_logo

      form.setFieldsValue(defaultValues)
    }
  }, [isCustomizable, isAllowedChangeDefault, card])

  const navigateToView = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  const navigateToCardImage = () => {
    dispatch(queryParamsActions.setQueryParams({ card_id: card.id }))
    history.push({
      pathname: `/card-images/${id}`,
    })
  }

  const generatePdf = () => {
    dispatch(cardsActions.generatePdf(card.id, 1, card.name))
  }

  const printImposeCardsHandler = () => setActiveModal('imposed-modal-python')

  const printImposeCardsTemplateHandler = () => setActiveModal('imposed-modal-python-template')

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToView,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: () => deleteCardHandler(id),
          }
        case navMenu.images.key:
          return {
            ...menu,
            url: navigateToCardImage,
          }
        case navMenu.pdf.key:
          return {
            ...menu,
            url: generatePdf,
          }

        case navMenu.imposeCards.key:
          return {
            ...menu,
            url: printImposeCardsHandler,
          }
        case navMenu.imposeCardsPython.key:
          return {
            ...menu,
            url: printImposeCardsTemplateHandler,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit, card])

  useEffect(() => {
    if (card) {
      form.setFieldsValue({
        ...card,
        cover: parseFileForSetFields(card.cover, {
          url: `${appConstants.IMAGES_URL}/cardimages/${card.cover}`,
        }),
        categories: categories?.filter(cat => cat.value).map(cat => cat.id),
      })
      onCategoryChange(card.category)
    }
  }, [form, card])

  const normFile = e => {
    let file = e && e.fileList

    if (Array.isArray(e)) file = e

    const reader = new FileReader()
    reader.onload = () => setCover(reader.result)
    reader.readAsDataURL(file[0].originFileObj)

    return file
  }

  const onCategoryChange = category => {
    const originCardIsCustom = card.category.taxonomy === 'CUSTOM'
    const shouldOpenPopup =
      originCardIsCustom && category.taxonomy !== 'CUSTOM' && !isCustomCategoryChanged

    if (shouldOpenPopup) {
      setChangeCategoryPopupActive(true)
    }
    if (category.taxonomy === 'CUSTOMIZED') setIsCustomizable(true)
    else setIsCustomizable(false)
  }

  const changeCustomCategoryHandler = () => {
    setChangeCategoryPopupActive(false)
    setCustomCategoryChanged(true)
  }

  const declineChangeCustomCategoryHandler = () => {
    form.setFieldValue('category_id', card.category_id)
    setChangeCategoryPopupActive(false)
  }

  const onPrintImposedCards = async (sizes, formValues) => {
    const isTemplateMode = activeModal.toLowerCase().includes('template')
    const version = isTemplateMode ? 6 : 5

    const payload = {
      cardId: id,
      cardName: card.name,
      quantity: formValues.quantity,
      pageSize: sizes,
      withBarcode: true,
      version,
      imposition_id: formValues?.imposition_id || null,
    }

    await dispatch(batchOrdersActions.generateImposedCards(payload))
  }

  const onFinish = async values => {
    const newItem = {}

    Object.keys(values).forEach(key => {
      if (fieldsToIgnore.includes(key)) return

      if (key !== 'total_images' && values[key] !== card[key]) {
        if (!values[key] && fieldsNotNull.includes(key)) {
          newItem[key] = 0
        } else if (key === 'cover') {
          if (values.cover[0]?.name === card.cover) newItem.file = null
          // eslint-disable-next-line prefer-destructuring
          else if (values.cover.length > 0) {
            newItem.file = {
              ...values.cover[0],
              thumbUrl: coverImage,
            }
          } else newItem.file = { status: 'removed' }
        } else if (key === 'category') {
          newItem.categories = values.categories.map(item => item)
        } else if (key === 'category_id') {
          newItem.category_id = values.category_id
          // } else if (!isCustomizable) {
          //   newItem.full_bleed = 0
        } else newItem[key] = values[key]
      } else {
        newItem[key] = values[key]
      }
    })

    await dispatch(cardsActions.updateItemById(id, convertFormValuesDeep(newItem)))
  }

  return (
    <NavContainer menu={navMenuParse}>
      <ImposedCardModal
        isVisible={
          activeModal === 'imposed-modal-python' || activeModal === 'imposed-modal-python-template'
        }
        isAllCardsFlat={card?.orientation === 'F'}
        onOk={onPrintImposedCards}
        onCancel={() => setActiveModal(false)}
        singleCardMode
        templateMode={activeModal === 'imposed-modal-python-template'}
      />
      <ChangeCategoryConfirmationPopup
        isVisible={isChangeCategoryPopupActive}
        onSubmit={changeCustomCategoryHandler}
        onCancel={declineChangeCustomCategoryHandler}
      />
      {useGlobalLoader(
        <div>
          <p>Fields with * are required.</p>
          <Form
            {...formItemLayout}
            name="basic"
            form={form}
            onFinish={onFinish}
            onFieldsChange={() => setChange(true)}
            scrollToFirstError
          >
            <Form.Item name="status" label="Status">
              <Select options={convertDataForSelect(cardStatuses)} />
            </Form.Item>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input name!',
                },
                () => ({
                  validator(_, value) {
                    if (value.trim() === '/')
                      return Promise.reject(
                        new Error(`Cannot use the reserved word '/' as a card name.
                      `),
                      )
                    return Promise.resolve()
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="SKU" name="sku">
              <Input />
            </Form.Item>
            <Form.Item label="Category" name="category_id">
              <Select
                onSelect={(input, option) => onCategoryChange(option)}
                showSearch
                options={categoriesSelect}
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
            <Form.Item label="Card Dimension" name="dimension_id">
              <Select
                showSearch
                options={cardDimensionsSelect}
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
            <Form.Item name="tax_exempt" label="Tax Exempt">
              <Select options={convertDataForSelect(cardTaxExempt)} />
            </Form.Item>

            <Form.Item name="cover_restricted" label="Cover Restricted">
              <Select options={convertDataForSelect(cardCoverRestricted)} />
            </Form.Item>

            {isCustomizable ? (
              <Form.Item label="Full Bleed" name="full_bleed" valuePropName="checked">
                <Checkbox name="full_bleed" />
              </Form.Item>
            ) : null}

            <Form.Item
              label="Cover"
              name="cover"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                name="icon"
                maxCount={1}
                accept="image/png, image/jpeg"
                beforeUpload={() => {
                  return false
                }}
                action=""
                listType="picture"
              >
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>

            <Form.Item label="Price" name="price">
              <InputNumber min={0} />
            </Form.Item>

            <Form.Item label="Description" name="description">
              <Input.TextArea rows={5} />
            </Form.Item>

            <Form.Item label="Notes" name="notes">
              <Input.TextArea rows={5} />
            </Form.Item>

            <Form.Item
              label="Character Count"
              name="character_count"
              rules={[
                {
                  required: true,
                  message: 'Please fill the Character Count!',
                },
                () => ({
                  validator(_, value) {
                    if (value < 1)
                      return Promise.reject(new Error('Characters counter cannot be a 0!'))
                    return Promise.resolve()
                  },
                }),
              ]}
              style={{
                marginBottom: 0,
              }}
            >
              <Space style={{ alignItems: 'center' }}>
                <Form.Item name="width" style={{ display: 'inline-block', margin: 0 }}>
                  <InputNumber
                    placeholder="Characters width"
                    style={{ width: '100%' }}
                    min={0}
                    step={1}
                  />
                </Form.Item>
                X
                <Form.Item name="height" style={{ display: 'inline-block', margin: 0 }}>
                  <InputNumber
                    placeholder="Characters lines"
                    style={{ width: '100%' }}
                    min={0}
                    step={1}
                  />
                </Form.Item>
                =
                <Form.Item name="character_count" style={{ display: 'inline-block', margin: 0 }}>
                  <InputNumber disabled style={{ width: '100%' }} />
                </Form.Item>
              </Space>
            </Form.Item>

            <Form.Item label="Quantity" name="quantity">
              <InputNumber />
            </Form.Item>

            <Form.Item name="available_free" label="Available Free">
              <Select options={convertDataForSelect(cardAvailableFree)} />
            </Form.Item>

            <Form.Item name="orientation" label="Orientation">
              <Select options={convertDataForSelect(cardOrientation)} disabled />
            </Form.Item>
            <Form.Item label="Sort No" initialValue={0} name="sort_no">
              <InputNumber min={0} />
            </Form.Item>

            <Form.Item label="Images">
              <Button
                size="small"
                type="text"
                className="list-view-item-bold list-item-underline px-0"
                style={{ border: 'none' }}
                onClick={() => navigateToCardImage(card.id)}
              >
                {totalImages ?? 0}
              </Button>
            </Form.Item>

            <Form.Item name="supress_return_address" label="Supress Return Address">
              <Select options={convertDataForSelect(cardSupressReturnAddress)} />
            </Form.Item>
            <Divider orientation="left">
              <span style={{ marginRight: '0.5rem' }}>Preview attributes</span>
              <Tooltip title={marginsTooltipMessage} placement="top">
                <QuestionCircleOutlined />
              </Tooltip>
            </Divider>

            <Form.Item
              label="Margin Top"
              tooltip={isCustomizable ? { title: riskWarningMessage } : null}
            >
              <Space direction="vertical">
                <Form.Item name="preview_margin_top" noStyle>
                  <InputNumber step={0.005} />
                </Form.Item>
                <ConfirmWarningCheckbox
                  visible={isCustomizable}
                  checked={isAllowedChangeDefault.preview_margin_top}
                  field="preview_margin_top"
                  setAllow={setAllowChangeDefault}
                />
              </Space>
            </Form.Item>

            <Form.Item label="Margin Right" name="preview_margin_right">
              <InputNumber step={0.005} />
            </Form.Item>

            <Form.Item
              label="Margin Bottom"
              tooltip={isCustomizable ? { title: riskWarningMessage } : null}
            >
              <Space direction="vertical">
                <Form.Item name="preview_margin_bottom" noStyle>
                  <InputNumber step={0.005} />
                </Form.Item>
                <ConfirmWarningCheckbox
                  visible={isCustomizable}
                  checked={isAllowedChangeDefault.preview_margin_bottom}
                  field="preview_margin_bottom"
                  setAllow={setAllowChangeDefault}
                />
              </Space>
            </Form.Item>

            <Form.Item label="Margin Left" name="preview_margin_left">
              <InputNumber step={0.005} />
            </Form.Item>

            {isCustomizable ? (
              <>
                <Divider orientation="left">Preview attributes (no-logo, deprecated)</Divider>

                <Form.Item
                  label="Top margin no logo"
                  rules={[
                    {
                      required: true,
                      message: 'Please input value!',
                    },
                  ]}
                  tooltip={isCustomizable ? { title: riskWarningMessage } : null}
                >
                  <Space direction="vertical">
                    <Form.Item name="top_margin_no_logo" noStyle>
                      <InputNumber step={0.005} defaultValue={0.3} disabled />
                    </Form.Item>
                    <ConfirmWarningCheckbox
                      visible={isCustomizable}
                      checked={isAllowedChangeDefault.top_margin_no_logo}
                      field="top_margin_no_logo"
                      setAllow={setAllowChangeDefault}
                    />
                  </Space>
                </Form.Item>

                <Form.Item
                  label="Bottom margin no logo"
                  rules={[
                    {
                      required: true,
                      message: 'Please input value!',
                    },
                  ]}
                  tooltip={isCustomizable ? { title: riskWarningMessage } : null}
                >
                  <Space direction="vertical">
                    <Form.Item name="bottom_margin_no_logo" noStyle>
                      <InputNumber step={0.005} defaultValue={0.3} disabled />
                    </Form.Item>
                    <ConfirmWarningCheckbox
                      visible={isCustomizable}
                      checked={isAllowedChangeDefault.bottom_margin_no_logo}
                      field="bottom_margin_no_logo"
                      setAllow={setAllowChangeDefault}
                    />
                  </Space>
                </Form.Item>
              </>
            ) : null}

            <Divider orientation="left">Printing attributes</Divider>
            <Form.Item label="Open Height" name="open_height">
              <InputNumber step={0.005} disabled />
            </Form.Item>

            <Form.Item label="Open Width" name="open_width">
              <InputNumber step={0.005} disabled />
            </Form.Item>

            <Form.Item label="Closed Height" name="closed_height">
              <InputNumber step={0.005} disabled />
            </Form.Item>

            <Form.Item label="Closed Width" name="closed_width">
              <InputNumber step={0.005} disabled />
            </Form.Item>

            <Form.Item label="Margin Top" name="margin_top">
              <InputNumber step={0.005} disabled />
            </Form.Item>

            <Form.Item label="Margin Right" name="margin_right">
              <InputNumber step={0.005} disabled />
            </Form.Item>

            <Form.Item label="Margin Bottom" name="margin_bottom">
              <InputNumber step={0.005} disabled />
            </Form.Item>

            <Form.Item label="Margin Left" name="margin_left">
              <InputNumber step={0.005} disabled />
            </Form.Item>

            <Form.Item label="Envelope Height" name="envelope_height">
              <InputNumber step={0.005} disabled />
            </Form.Item>

            <Form.Item label="Envelope Width" name="envelope_width">
              <InputNumber step={0.005} disabled />
            </Form.Item>
            <Form.Item label="Envelope Font Size" name="envelope_font_size">
              <InputNumber step={1} min={0} disabled />
            </Form.Item>

            <Form.Item
              label="Envelope Lines Between Addresses"
              name="envelope_lines_between_addresses"
            >
              <InputNumber step={1} min={0} disabled />
            </Form.Item>

            <Form.Item label="Envelope To Tabs" name="envelope_to_tabs">
              <InputNumber step={1} min={0} disabled />
            </Form.Item>

            <Form.Item label="Envelope Margin Top" name="envelope_margin_top">
              <InputNumber step={0.005} disabled />
            </Form.Item>

            <Form.Item label="Envelope Margin Right" name="envelope_margin_right">
              <InputNumber step={0.005} disabled />
            </Form.Item>

            <Form.Item label="Envelope Margin Bottom" name="envelope_margin_bottom">
              <InputNumber step={0.005} disabled />
            </Form.Item>

            <Form.Item label="Envelope Margin Left" name="envelope_margin_left">
              <InputNumber step={0.005} disabled />
            </Form.Item>

            <Divider orientation="left">Additional Details</Divider>

            <Form.Item label="Details Author" name="details_author">
              <Input />
            </Form.Item>

            <Form.Item label="Details Envelope" name="details_envelope">
              <Input />
            </Form.Item>

            <Divider orientation="left">Low Stock Notifications</Divider>

            <Form.Item label="Low Stock Threshold" name="low_stock_threshold">
              <Input />
            </Form.Item>

            <Form.Item label="Client Notification Emails">
              <Tooltip title="Wrong format. Check your value." open={emailWarning}>
                <Form.Item name="client_notification_emails" noStyle>
                  <Input.TextArea
                    rows={6}
                    onFocus={() => setEmailWarning(false)}
                    onBlur={validateEmailList}
                    style={emailWarning ? { border: '1px solid #E9D502' } : {}}
                  />
                </Form.Item>
              </Tooltip>
              <p style={{ fontSize: '13px' }}>
                * You can separate emails by writing them on a new line, use space or one of the
                special symbols like ";" or ","
              </p>
            </Form.Item>

            <Divider orientation="left" />

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!changed}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>,
      )}
    </NavContainer>
  )
}

export default EditItem
