import React from 'react'

import './style.scss'

const FramePreview = ({ qrCodeUrl, qrCodeSize, positionX, positionY, frame }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          height: '2.75in',
          width: 'fit-content',
        }}
      >
        <div
          style={{
            height: '100%',
            backgroundImage: `url(${qrCodeUrl})`,
            backgroundSize: `auto ${qrCodeSize}%`,
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: `${positionX}%`,
            backgroundPositionY: `${positionY}%`,
            position: 'relative',
          }}
        >
          <div className="horizontal-line"></div>
          <div className="vertical-line"></div>
          <img style={{ height: '100%' }} src={frame} />
        </div>
      </div>
    </div>
  )
}

export default FramePreview
