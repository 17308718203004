import React from 'react'
import { Card } from 'antd'

import './style.scss'

const Section = ({ title, children }) => {
  return (
    <Card className="form-section">
      <>
        <span className="form-section__title">{title}</span>
        {children}
      </>
    </Card>
  )
}

export default Section
