import {paginationConstants} from '../_constants';


// eslint-disable-next-line no-unused-vars
const paginationChanged = (value) => (dispatch, getState) => {
  dispatch({ type: paginationConstants.PAGINATION_CHANGED, value })
}

/**
 * Used only to save pagination settings in localStorage
 */
// eslint-disable-next-line no-unused-vars
const pageSizeChanged = (view, pageSize) => (dispatch, getState) => {

  const storedPaginationStr = localStorage.getItem('pagination')
  const storedPagination  = storedPaginationStr ? JSON.parse(storedPaginationStr) : {}
  const { username } = JSON.parse(localStorage.getItem('user'))
  const userSettingsPagination = storedPagination[username] || {}
  localStorage.setItem('pagination', JSON.stringify({ [username]: { ...userSettingsPagination, [view]: pageSize } }))

  dispatch({ type: paginationConstants.PAGE_SIZE_CHANGED, value: { view, pageSize } })
}

const paginationActions = {
  paginationChanged,
  pageSizeChanged,
}

export default paginationActions
