import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { batchOrdersActions } from '_actions'
import GroupedOrdersCollapse from 'components/batch-orders/grouped-orders.collapse'

const CardsList = ({
  dateSend,
  groupByGCards,
  groupByInserts,
  selectHandler,
  setActivePanelList,
  isPrintedList,
}) => {
  const dispatch = useDispatch()
  const { paidOrdersByDate, printedOrders } = useSelector(state => state.batchOrders)

  useEffect(() => {
    dateSend
      ? dispatch(batchOrdersActions.getOrdersByDate(dateSend, groupByGCards, groupByInserts))
      : dispatch(batchOrdersActions.getPrintedOrders(isPrintedList, groupByGCards, groupByInserts))
  }, [dateSend, isPrintedList])

  const cardsByDate = useMemo(() => paidOrdersByDate[dateSend], [paidOrdersByDate])

  const onCardPanelChange = keys => {
    const lastKey = keys[keys.length - 1]
    setActivePanelList(prev => [...prev, ...keys])
    dispatch(batchOrdersActions.setActivePanel({ [lastKey]: lastKey }))
  }

  if ((!cardsByDate && !isPrintedList) || (!printedOrders.length && !dateSend)) return null

  return (
    <GroupedOrdersCollapse
      onChange={onCardPanelChange}
      groups={isPrintedList ? printedOrders : cardsByDate}
      selectHandler={selectHandler}
      dateSend={dateSend}
      isPrintedList={isPrintedList}
    />
  )
}

export default CardsList
