import { subscriptionConstants } from '../_constants'
import { subscriptionsService } from '../_services'
import { history } from '../_helpers'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: subscriptionConstants.GETALL_REQUEST })
  try {
    const subscriptions = await subscriptionsService.getAll(params, typeAction)
    dispatch({ type: subscriptionConstants.GETALL_SUCCESS, payload: subscriptions.data })
  } catch (error) {
    dispatch({ type: subscriptionConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const addItem = value => async dispatch => {
  dispatch({ type: subscriptionConstants.ADD_REQUEST })

  try {
    const subscription = await subscriptionsService.addItem({ value })
    dispatch({
      type: subscriptionConstants.ADD_SUCCESS,
      payload: { ...value, id: subscription.id },
    })
    history.push(`/subscriptions/view/${subscription.id}`)
  } catch (error) {
    dispatch({ type: subscriptionConstants.ADD_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: subscriptionConstants.GETBYID_REQUEST })

  try {
    const subscription = await subscriptionsService.getItemById(id)
    dispatch({ type: subscriptionConstants.GETBYID_SUCCESS, payload: subscription.data })
  } catch (error) {
    dispatch({ type: subscriptionConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const deleteItem = id => async dispatch => {
  dispatch({ type: subscriptionConstants.DELETE_REQUEST })
  try {
    await subscriptionsService.deleteItem(id)
    history.push('/subscriptions')
    dispatch({ type: subscriptionConstants.DELETE_SUCCESS, payload: id })
  } catch (error) {
    dispatch({ type: subscriptionConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const updateItemById = (id, value) => async dispatch => {
  dispatch({ type: subscriptionConstants.UPDATE_REQUEST })

  try {
    const updated = await subscriptionsService.updateItemById(id, value)
    dispatch({ type: subscriptionConstants.UPDATE_SUCCESS, payload: updated?.message?.toString() })
    history.push(`/subscriptions/view/${id}`)
  } catch (error) {
    dispatch({ type: subscriptionConstants.UPDATE_FAILURE, payload: error.toString() })
  }
}

const subscriptionActions = {
  getAll,
  getItemById,
  deleteItem,
  updateItemById,
  addItem,
}

export default subscriptionActions
