import { shippingConfigsConstants } from '../_constants'

const initialState = {
  grouped: {},
  items: [],
  item: {},
  shipper: {},
  box: {},
  card: {},
}

function ShippingConfigs(state = initialState, { type, payload }) {
  switch (type) {
    case shippingConfigsConstants.GETALL_SUCCESS:
      return { ...state, items: payload, item: {} }
    case shippingConfigsConstants.GET_GROUP_BY_TYPE_SUCCESS:
      return { ...state, items: [], item: {}, [payload.type]: payload }
    case shippingConfigsConstants.GET_GROUPED_SUCCESS:
      return { ...state, grouped: payload, items: [], item: {} }
    case shippingConfigsConstants.GETBYID_SUCCESS:
      return { ...state, item: payload }
    case shippingConfigsConstants.DELETE_SUCCESS:
      return { ...state }
    case shippingConfigsConstants.UPDATE_SUCCESS:
      return { ...state, items: state.items }
    case shippingConfigsConstants.ADD_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          total: state.items.total + 1,
        },
      }
    case shippingConfigsConstants.SETBYID:
      return {
        ...state,
        item: {},
      }
    case shippingConfigsConstants.SETALL:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default ShippingConfigs
