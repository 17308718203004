import { prePayCreditsConstants } from '../_constants'
import { prePayCreditsService } from '../_services'
import { history } from '../_helpers'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: prePayCreditsConstants.GETALL_REQUEST })
  try {
    const prePayCredits = await prePayCreditsService.getAll(params, typeAction)
    dispatch({ type: prePayCreditsConstants.GETALL_SUCCESS, payload: prePayCredits.data })
  } catch (error) {
    dispatch({ type: prePayCreditsConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const addItem = value => async dispatch => {
  dispatch({ type: prePayCreditsConstants.ADD_REQUEST })

  try {
    const prePayCredit = await prePayCreditsService.addItem({ value })
    dispatch({
      type: prePayCreditsConstants.ADD_SUCCESS,
      payload: { ...value, id: prePayCredit.id },
    })
    history.push(`/credit_cards2/view/${prePayCredit.id}`)
  } catch (error) {
    dispatch({ type: prePayCreditsConstants.ADD_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: prePayCreditsConstants.GETBYID_REQUEST })

  try {
    const prePayCredit = await prePayCreditsService.getItemById(id)
    dispatch({ type: prePayCreditsConstants.GETBYID_SUCCESS, payload: prePayCredit.data })
  } catch (error) {
    dispatch({ type: prePayCreditsConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const updateItemById = (id, value) => async dispatch => {
  dispatch({ type: prePayCreditsConstants.UPDATE_REQUEST })

  try {
    const updated = await prePayCreditsService.updateItemById(id, value)
    dispatch({ type: prePayCreditsConstants.UPDATE_SUCCESS, payload: updated?.message?.toString() })
    history.push(`/credit_cards2/view/${id}`)
  } catch (error) {
    dispatch({ type: prePayCreditsConstants.UPDATE_FAILURE, payload: error.toString() })
  }
}

const prePayCreditsActions = {
  getAll,
  getItemById,
  updateItemById,
  addItem,
}

export default prePayCreditsActions
