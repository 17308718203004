import { signatureConstants } from '../_constants'
import { signatureService } from '../_services'
import { history } from '../_helpers'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: signatureConstants.GETALL_REQUEST })
  try {
    const signatures = await signatureService.getAll(params, typeAction)
    dispatch({ type: signatureConstants.GETALL_SUCCESS, payload: signatures.data })
  } catch (error) {
    dispatch({ type: signatureConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const addItem = value => async dispatch => {
  dispatch({ type: signatureConstants.ADD_REQUEST })

  try {
    const signature = await signatureService.addItem({ value })
    dispatch({ type: signatureConstants.ADD_SUCCESS, payload: { ...value, id: signature.id } })
    history.push(`/signatures/view/${signature.id}`)
  } catch (error) {
    dispatch({ type: signatureConstants.ADD_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: signatureConstants.GETBYID_REQUEST })

  try {
    const signature = await signatureService.getItemById(id)
    dispatch({ type: signatureConstants.GETBYID_SUCCESS, payload: signature.data })
  } catch (error) {
    dispatch({ type: signatureConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const deleteItem = id => async dispatch => {
  dispatch({ type: signatureConstants.DELETE_REQUEST })
  try {
    await signatureService.deleteItem(id)
    history.push('/signatures')
    dispatch({ type: signatureConstants.DELETE_SUCCESS, payload: id })
  } catch (error) {
    dispatch({ type: signatureConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const updateItemById = (id, value) => async dispatch => {
  dispatch({ type: signatureConstants.UPDATE_REQUEST })

  try {
    const updated = await signatureService.updateItemById(id, value)
    dispatch({ type: signatureConstants.UPDATE_SUCCESS, payload: updated?.message?.toString() })
    history.push(`/signatures/view/${id}`)
  } catch (error) {
    dispatch({ type: signatureConstants.UPDATE_FAILURE, payload: error.toString() })
  }
}

const uploadFontForSign = value => async dispatch => {
  dispatch({ type: signatureConstants.UPLOAD_FONT_REQUEST })

  try {
    await signatureService.uploadFontForSign({ value })
    dispatch({ type: signatureConstants.UPLOAD_FONT_SUCCESS })
  } catch (error) {
    dispatch({ type: signatureConstants.UPLOAD_FONT_FAILURE })
  }
}

const downloadCurrentFont = isCenterline => async dispatch => {
  dispatch({ type: signatureConstants.DOWNLOAD_CURRENT_FONT_REQUEST })

  try {
    await signatureService.downloadCurrentFont(isCenterline)
    dispatch({ type: signatureConstants.DOWNLOAD_CURRENT_FONT_SUCCESS })
  } catch (error) {
    dispatch({ type: signatureConstants.DOWNLOAD_CURRENT_FONT_FAILURE })
  }
}

const getSignatureFont = () => async dispatch => {
  dispatch({ type: signatureConstants.GET_SIGNATURE_FONT_REQUEST })

  try {
    const res = await signatureService.getSignatureFont()
    dispatch({ type: signatureConstants.GET_SIGNATURE_FONT_SUCCESS })
    return res
  } catch (error) {
    dispatch({ type: signatureConstants.GET_SIGNATURE_FONT_FAILURE })
  }
}

const signatureActions = {
  getAll,
  getItemById,
  deleteItem,
  updateItemById,
  addItem,
  uploadFontForSign,
  downloadCurrentFont,
  getSignatureFont,
}

export default signatureActions
