import { alertConstants } from '../_constants'

const initialState = {
  alert: null,
  alertStatus: null,
  typeResponse: null,
  modal: null,
  completeModal: null,
}

function alerts(state = initialState, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        ...state,
        alert: {
          type: 'success',
          message: action.message,
        },
      }
    case alertConstants.ERROR:
      return {
        ...state,
        alert: {
          type: 'error',
          message: action.message,
        },
      }
    case alertConstants.CLEAR:
      return {
        ...state,
        alert: null,
      }
    case alertConstants.STATUS:
      return {
        ...state,
        alertStatus: action.updMessages,
      }
    case alertConstants.STATUS_CLEAR:
      return {
        ...state,
        alertStatus: null,
      }
    case alertConstants.TYPE_RESPONSE:
      return {
        ...state,
        typeResponse: action.payload,
      }
    case alertConstants.MODAL:
      return { ...state, modal: action.payload }
    case alertConstants.COMPLETE_MODAL:
      return { ...state, completeModal: action.payload }
    case alertConstants.CLEAR_COMPLETE_MODAL:
      return { ...state, completeModal: null }
    default:
      return state
  }
}

export default alerts
