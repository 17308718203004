import { history } from '../_helpers'
import { notesConstants } from '_constants'
import { clientNotesService } from '_services'

const getItemById = id => async dispatch => {
  dispatch({ type: notesConstants.GETBYID_REQUEST })

  try {
    const note = await clientNotesService.getItemById(id)
    dispatch({ type: notesConstants.GETBYID_SUCCESS, payload: note })
  } catch (error) {
    dispatch({ type: notesConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const createNote = value => async dispatch => {
  dispatch({ type: notesConstants.CREATE_REQUEST })

  try {
    const note = await clientNotesService.createNote({ value })
    dispatch({
      type: notesConstants.CREATE_SUCCESS,
      payload: {
        ...value,
        id: note.id,
      },
    })
    history.push(`/client-notes/view/${note.new.id}`)
  } catch (error) {
    dispatch({ type: notesConstants.CREATE_SUCCESS, payload: error.toString() })
  }
}

const updateItem = value => async dispatch => {
  dispatch({ type: notesConstants.UPDATE_REQUEST })

  try {
    const updated = await clientNotesService.updateItem({ value })
    dispatch({
      type: notesConstants.UPDATE_SUCCESS,
      payload: updated?.message?.toString(),
    })
    history.push(`/client-notes`)
  } catch (error) {
    dispatch({ type: notesConstants.UPDATE_SUCCESS, payload: error.toString() })
  }
}

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: notesConstants.GETALL_REQUEST })
  try {
    const cards = await clientNotesService.getAll(params, typeAction)
    dispatch({ type: notesConstants.GETALL_SUCCESS, payload: cards.data })
  } catch (error) {
    dispatch({ type: notesConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const deleteItem = id => async dispatch => {
  dispatch({ type: notesConstants.DELETE_REQUEST })
  try {
    await clientNotesService.deleteItem(id)
    history.push(`/client-notes`)
    dispatch({ type: notesConstants.DELETE_SUCCESS, payload: id })
  } catch (error) {
    dispatch({ type: notesConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const getCountNotes = id => async dispatch => {
  dispatch({ type: notesConstants.GETCOUNT_REQUEST })

  try {
    const count = await clientNotesService.getCountNotes(id)
    dispatch({ type: notesConstants.GETCOUNT_SUCCESS, payload: count })
  } catch (error) {
    dispatch({ type: notesConstants.GETCOUNT_FAILURE, payload: error.toString() })
  }
}

const clientNotesActions = {
  getItemById,
  createNote,
  updateItem,
  getAll,
  deleteItem,
  getCountNotes,
}

export default clientNotesActions
