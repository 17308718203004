const wordMappingConstants = {
  GETALL_REQUEST: 'WORD_MAPPING_GETALL_REQUEST',
  GETALL_SUCCESS: 'WORD_MAPPING_GETALL_SUCCESS',
  GETALL_FAILURE: 'WORD_MAPPING_GETALL_FAILURE',

  GETBYID_REQUEST: 'WORD_MAPPING_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'WORD_MAPPING_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'WORD_MAPPING_GETBYID_FAILURE',

  ADD_REQUEST: 'WORD_MAPPING_ADD_REQUEST',
  ADD_SUCCESS: 'WORD_MAPPING_ADD_SUCCESS',
  ADD_FAILURE: 'WORD_MAPPING_ADD_FAILURE',

  DELETE_REQUEST: 'WORD_MAPPING_DELETE_REQUEST',
  DELETE_SUCCESS: 'WORD_MAPPING_DELETE_SUCCESS',
  DELETE_FAILURE: 'WORD_MAPPING_DELETE_FAILURE',

  UPDATE_REQUEST: 'WORD_MAPPING_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'WORD_MAPPING_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'WORD_MAPPING_UPDATE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    list: { key: 'list', title: 'List Word Mappings', url: '/word-mapping/list' },
    manage: { key: 'manage', title: 'Manage Word Mappings', url: '/word-mapping' },
    create: {
      title: 'Create Word Mapping',
      key: 'create',
      url: '/word-mapping/create',
    },
    view: { key: 'view', title: 'View Word Mapping', url: '' },
    delete: { key: 'delete', title: 'Delete Word Mapping', url: '' },
    edit: { key: 'edit', title: 'Update Word Mapping', url: '' },
  },

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },
  listItemKeyToLabel: {
    id: 'ID',
    full_word: 'Break Word',
    short_word: 'Abbreviation',
    allow_replace: 'Allow Replace',
    allow_split: 'Allow Split',
  },
  viewItemKeyToLabel: {
    id: 'ID',
    full_word: 'Break Word',
    short_word: 'Abbreviation',
    allow_replace: 'Allow Replace',
    allow_split: 'Allow Split',
  },

  advancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'Break word', key: 'full_word' },
    { label: 'Abbreviation', key: 'short_word' },
    { label: 'Allow Replace', key: 'allow_replace' },
    { label: 'Allow Split', key: 'allow_split' },
  ],

  statuses: [
    { value: 0, text: 'No' },
    { value: 1, text: 'Yes' },
  ],
}
export default wordMappingConstants
