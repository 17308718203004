import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { clientNotesActions } from '_actions'
import NavContainer from '../../../../components/nav-container'
import ItemViewer from '../../../../components/item-viewer'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { notesConstants } from '_constants'
import clientsActions from '../../../../_actions/clients.actions.js'
import { isEmpty } from 'lodash'
import employeesActions from '../../../../_actions/employees.actions.js'

const { navMenu, viewItemKeyToLabel } = notesConstants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.edit, navMenu.delete, navMenu.manage]

const ViewItem = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const view = getCurrentUrl(match.url)
  const { id } = useParams()

  const [navMenuParse, setNavMenuParse] = useState([])

  const { item: clientNote } = useSelector(state => state.clientNotes)
  const { client } = useSelector(state => state.clients.item)
  const employeeData = useSelector(state => state.employees.item)

  useEffect(() => {
    dispatch(clientNotesActions.getItemById(id))
    return () => {
      dispatch({ type: notesConstants.SETBYID })
    }
  }, [id, dispatch])

  const fetchData = useCallback(async () => {
    await Promise.all([
      dispatch(clientsActions.getItemById(clientNote.user_id)),
      dispatch(employeesActions.getItemById(clientNote.employee_id)),
    ])
  }, [clientNote])

  useEffect(() => {
    if (clientNote && !isEmpty(clientNote)) {
      fetchData()
    }
  }, [clientNote])

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(clientNotesActions.deleteItem(id))
      },
    })
  }

  const onEditItem = () => {
    history.push({
      pathname: `${view}/edit/${id}`,
    })
  }

  const onListItems = () => {
    history.push({
      pathname: `${view}/list`,
    })
  }

  const onCreateItem = () => {
    history.push({
      pathname: `${view}/create`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.edit.key:
          return {
            ...menu,
            url: onEditItem,
          }
        case navMenu.list.key:
          return {
            ...menu,
            url: onListItems,
          }
        case navMenu.create.key:
          return {
            ...menu,
            url: onCreateItem,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const viewItem = useMemo(() => {
    if (Object.keys(clientNote).length) {
      return Object.keys(viewItemKeyToLabel).reduce((obj, sKey) => {
        let itemValue = clientNote[sKey]
        if (sKey === 'user')
          itemValue = (
            <Link to={`/clients/edit/${clientNote.user_id}`}>
              [ID: {clientNote.user_id}] {client.login}
            </Link>
          )
        if (sKey === 'employee_id')
          itemValue = `[ID: ${clientNote.employee_id}] ${employeeData?.username || ''}`
        return {
          ...obj,
          [sKey]: itemValue,
        }
      }, {})
    }
    return {}
  }, [clientNote, client])

  return (
    <NavContainer menu={navMenuParse}>
      <ItemViewer item={viewItem} keyToLabelMap={viewItemKeyToLabel} />
    </NavContainer>
  )
}

export default ViewItem
