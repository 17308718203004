import { wordMappingConstants } from '../_constants'
import { wordMappingService } from '../_services'
import { history } from '../_helpers'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: wordMappingConstants.GETALL_REQUEST })
  try {
    const addressLineBreaks = await wordMappingService.getAll(params, typeAction)
    dispatch({ type: wordMappingConstants.GETALL_SUCCESS, payload: addressLineBreaks.data })
  } catch (error) {
    dispatch({ type: wordMappingConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const addItem = value => async dispatch => {
  dispatch({ type: wordMappingConstants.ADD_REQUEST })

  try {
    const breakWord = await wordMappingService.addItem({ value })
    dispatch({
      type: wordMappingConstants.ADD_SUCCESS,
      payload: { ...value, id: breakWord.id },
    })
    history.push(`/word-mapping/view/${breakWord.id}`)
  } catch (error) {
    dispatch({ type: wordMappingConstants.ADD_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: wordMappingConstants.GETBYID_REQUEST })

  try {
    const breakWord = await wordMappingService.getItemById(id)
    dispatch({ type: wordMappingConstants.GETBYID_SUCCESS, payload: breakWord.data })
  } catch (error) {
    dispatch({ type: wordMappingConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const deleteItem = id => async dispatch => {
  dispatch({ type: wordMappingConstants.DELETE_REQUEST })
  try {
    await wordMappingService.deleteItem(id)
    history.push(`/word-mapping`)
    dispatch({ type: wordMappingConstants.DELETE_SUCCESS, payload: id })
  } catch (error) {
    dispatch({ type: wordMappingConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const updateItemById = (id, value) => async dispatch => {
  dispatch({ type: wordMappingConstants.UPDATE_REQUEST })

  try {
    const updated = await wordMappingService.updateItemById(id, value)
    dispatch({
      type: wordMappingConstants.UPDATE_SUCCESS,
      payload: updated?.message?.toString(),
    })
    history.push(`/word-mapping/view/${id}`)
  } catch (error) {
    dispatch({ type: wordMappingConstants.UPDATE_FAILURE, payload: error.toString() })
  }
}

const wordMappingActions = {
  getAll,
  getItemById,
  updateItemById,
  deleteItem,
  addItem,
}

export default wordMappingActions
