const cronJobConstants = {
  GETALL_REQUEST: 'JOBS_GETALL_REQUEST',
  GETALL_SUCCESS: 'JOBS_GETALL_SUCCESS',
  GETALL_FAILURE: 'JOBS_GETALL_FAILURE',

  GET_BY_NAME_REQUEST: 'JOBS_GET_BY_NAME_REQUEST',
  GET_BY_NAME_SUCCESS: 'JOBS_GET_BY_NAME_SUCCESS',
  GET_BY_NAME_FAILURE: 'JOBS_GET_BY_NAME_FAILURE',

  CLEAR_ALL_REQUEST: 'CLEAR_ALL_REQUEST',
  CLEAR_ALL_SUCCESS: 'CLEAR_ALL_SUCCESS',
  CLEAR_ALL_FAILURE: 'CLEAR_ALL_FAILURE',

  REMOVE_BY_NAME_REQUEST: 'REMOVE_BY_NAME_REQUEST',
  REMOVE_BY_NAME_SUCCESS: 'REMOVE_BY_NAME_SUCCESS',
  REMOVE_BY_NAME_FAILURE: 'REMOVE_BY_NAME_FAILURE',

  RETRY_FAILED_REQUEST: 'RETRY_FAILED_REQUEST',
  RETRY_FAILED_SUCCESS: 'RETRY_FAILED_SUCCESS',
  RETRY_FAILED_FAILURE: 'RETRY_FAILED_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  statusesList: [
    {
      color: '#1677ff',
      title: 'active',
    },
    {
      color: '#fadb14',
      title: 'waiting',
    },
    {
      color: '#52c41a',
      title: 'completed',
    },
    {
      color: '#f5222d',
      title: 'failed',
    },
    {
      color: '#722ed1',
      title: 'delayed',
    },
    { color: 'grey', title: 'paused' },
  ],
}

export default cronJobConstants
