import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, InputNumber, Select } from 'antd'
import NavContainer from '../../../../components/nav-container'
import { prePaidConfigsActions } from '../../../../_actions'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import { prePaidConfigsConstants } from '../../../../_constants'
import { useParams } from 'react-router'
import { convertDataForSelect } from '_helpers'

const { formItemLayout, navMenu, statuses } = prePaidConfigsConstants

const navMenuInit = [navMenu.list, navMenu.manage]

const EditItem = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { id } = useParams()
  const [changed, setChange] = useState(false)

  const {
    item: { prePaidPlan },
  } = useSelector(state => state.prePaidConfigs)

  const creditsTo = Form.useWatch('quantity_to', form)
  const creditsFrom = Form.useWatch('quantity_', form)

  useEffect(() => {
    dispatch(prePaidConfigsActions.getItemById(id))
    return () => {
      dispatch({ type: prePaidConfigsConstants.SETBYID })
    }
  }, [id, dispatch])

  useEffect(() => {
    if (prePaidPlan) form.setFieldsValue(prePaidPlan)
  }, [prePaidPlan])

  const onFinish = values => {
    dispatch(prePaidConfigsActions.updateItemById(id, convertFormValuesDeep(values)))
  }

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      <Form
        {...formItemLayout}
        name="basic"
        form={form}
        onFinish={onFinish}
        onFieldsChange={() => setChange(true)}
        scrollToFirstError
      >
        <Form.Item name="status" label="Status">
          <Select options={convertDataForSelect(statuses)} />
        </Form.Item>
        <Form.Item
          label="Cards from"
          name="quantity_from"
          rules={[
            {
              required: true,
              message: 'Please input value!',
            },
          ]}
        >
          <InputNumber min={0} max={creditsTo - 1 || null} />
        </Form.Item>

        <Form.Item
          label="Cards to"
          name="quantity_to"
          rules={[
            {
              required: true,
              message: 'Please input value!',
            },
          ]}
        >
          <InputNumber min={creditsFrom + 1 || 0} />
        </Form.Item>

        <Form.Item
          label="Discount"
          name="discount"
          initialValue={0}
          rules={[
            {
              required: true,
              message: 'Please input value!',
            },
          ]}
        >
          <InputNumber min={0} />
        </Form.Item>

        <Form.Item
          name="price_per_card"
          label="Price Per Card"
          initialValue={0}
          rules={[
            {
              required: true,
              message: 'Please input value!',
            },
          ]}
        >
          <InputNumber min={0} />
        </Form.Item>

        <Form.Item
          name="postage"
          label="Postage"
          initialValue={0}
          rules={[
            {
              required: true,
              message: 'Please input value!',
            },
          ]}
        >
          <InputNumber min={0} />
        </Form.Item>

        <Form.Item
          name="credit_active_in_month"
          label="Credit Active (month)"
          rules={[
            {
              required: true,
              message: 'Please input value!',
            },
          ]}
        >
          <InputNumber min={0} defaultValue={36} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={!changed}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </NavContainer>
  )
}

export default EditItem
