import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { Image, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { giftCardActions } from '../../../../_actions'
import NavContainer from '../../../../components/nav-container'
import ItemViewer from '../../../../components/item-viewer'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { appConstants, giftCardsConstants } from '../../../../_constants'

const { navMenu, viewItemKeyToLabel } = giftCardsConstants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.edit, navMenu.delete, navMenu.manage]

const ViewItem = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const { id } = useParams()

  const [navMenuParse, setNavMenuParse] = useState([])

  const {
    item: { gcard },
  } = useSelector(state => state.giftCards)

  const view = getCurrentUrl(match.url)

  useEffect(() => {
    dispatch(giftCardActions.getItemById(id))
    return () => {
      dispatch({ type: giftCardsConstants.SETBYID })
    }
  }, [id, dispatch])

  const onEditItem = () => {
    history.push({
      pathname: `${view}/edit/${id}`,
    })
  }

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(giftCardActions.deleteItem(id))
      },
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.edit.key:
          return {
            ...menu,
            url: onEditItem,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const viewItem = useMemo(() => {
    if (gcard) {
      return Object.keys(viewItemKeyToLabel).reduce((obj, sKey) => {
        let itemValue
        if (gcard.image && sKey === 'preview_image') {
          itemValue = (
            <Image
              width={200}
              src={`${appConstants.IMAGES_URL}/cardimages/${gcard.image}`}
              fallback={appConstants.FALLBACK_IMAGE}
              preview={false}
            />
          )
        } else {
          itemValue = gcard[sKey]
        }
        return {
          ...obj,
          [sKey]: itemValue,
        }
      }, {})
    }
    return {}
  }, [gcard])

  return (
    <NavContainer menu={navMenuParse}>
      <ItemViewer item={viewItem} keyToLabelMap={viewItemKeyToLabel} />
    </NavContainer>
  )
}

export default ViewItem
