import creditHistoryConstants from '../_constants/credit-history.constants.js'

const initialState = {
  items: [],
}

function creditHistory(state = initialState, { type, payload }) {
  switch (type) {
    case creditHistoryConstants.GETALL_SUCCESS:
      return { ...state, items: payload }
    default:
      return state
  }
}

export default creditHistory
