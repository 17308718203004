import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Checkbox, Form, Input, InputNumber, Select } from 'antd'
import NavContainer from '../../../../components/nav-container'
import { clientGroupActions, discountsCodesActions as dcActions } from '../../../../_actions'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import { discountsCodesConstants } from '../../../../_constants'
import { convertDataForSelect } from '../../../../_helpers'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import { getClientGroupsRows } from '../../../../_selectors/client-group.selector.js'

const { formItemLayout, navMenu, itemTypes } = discountsCodesConstants

const navMenuInit = [navMenu.list, navMenu.manage]

const CreateItem = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const clientGroups = useSelector(getClientGroupsRows)

  const clientGroupsSelect = useMemo(() => {
    return clientGroups?.map(group => ({ value: group.id, label: group.title }))
  }, [clientGroups])

  useEffect(() => {
    dispatch(clientGroupActions.getAll())
  }, [])

  const onFinish = async values => {
    const newCode = {
      ...values,
      users_limit: values.users_limit || 0,
    }
    const convertedCode = convertFormValuesDeep(newCode)
    await dispatch(dcActions.addItem(convertedCode))
  }

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      {useGlobalLoader(
        <Form {...formItemLayout} name="basic" form={form} onFinish={onFinish} scrollToFirstError>
          <Form.Item
            label="Code"
            name="code"
            rules={[
              {
                required: true,
                message: 'Please input code!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Domain Regex" name="domain_regex" initialValue="">
            <Input placeholder="[\.@]example.com" />
          </Form.Item>

          <Form.Item label="Limit Redemptions" name="users_limit" initialValue="">
            <InputNumber step={1} min={0} placeholder="0 - unlimited" />
          </Form.Item>

          <Form.Item label="Credit " name="credit" initialValue="1.00">
            <InputNumber step={0.01} min={1.0} />
          </Form.Item>

          <Form.Item name="type" label="Type" initialValue="normal">
            <Select options={convertDataForSelect(itemTypes)} />
          </Form.Item>

          <Form.Item label="Invoiced" name="invoiced" initialValue={false} valuePropName="checked">
            <Checkbox />
          </Form.Item>

          <Form.Item label="Client Group">
            <Select
              showSearch
              options={clientGroupsSelect}
              allowClear
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>,
      )}
    </NavContainer>
  )
}

export default CreateItem
