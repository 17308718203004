import axios from 'axios'
import serialize from '_helpers/hw-serialize-params'
import apiUrl from './service'
import { v1 } from './api'

const getAll = async (params, typeAction) => {
  const result = await axios.get(`${apiUrl}/${v1}/employees?${serialize(params)}`, {
    params: { stopSpinner: typeAction },
  })
  return handleResponse(result)
}

const getItemById = async id => {
  const result = await axios.get(`${apiUrl}/${v1}/employees/${id}`)
  return handleResponse(result)
}

const deleteItem = async id => {
  const result = await axios.delete(`${apiUrl}/${v1}/employees/${id}`, {
    params: { stopSpinner: 'paginate' },
  })
  return handleResponse(result)
}

const addItem = async ({ value }) => {
  const result = await axios.post(`${apiUrl}/${v1}/employees`, JSON.stringify(value))
  return handleResponse(result)
}

const updateItemById = async (id, value) => {
  const result = await axios.put(
    `${apiUrl}/${v1}/employees/${id}`,
    JSON.stringify({ changes: value }),
  )
  return handleResponse(result)
}

const reGenerateToken = async id => {
  const result = await axios.put(`${apiUrl}/${v1}/employees/${id}/token`)
  return handleResponse(result)
}

const resetPassword = async email => {
  await axios.post(`${apiUrl}/${v1}/auth/reset`, { email })
}

const handleResponse = result => {
  return result.data
}

const employeesService = {
  getAll,
  getItemById,
  deleteItem,
  updateItemById,
  addItem,
  reGenerateToken,
  resetPassword,
}

export default employeesService
