import { useState, useEffect, useCallback, useMemo } from 'react'

/* eslint-disable import/prefer-default-export */
export const useMyOrdersTable = rows => {
  const [selectedCards, setSelectedCards] = useState([])
  const [allOrdersIds, setAllOrdersIds] = useState()

  const isAllCardsFlat = useMemo(
    () =>
      selectedCards.every(card => {
        if (card.selected.length === 0) return true
        return card.orientation === 'F'
      }),
    [selectedCards],
  )

  const orientationsConflict = useMemo(() => {
    const includesFlatCard = selectedCards.find(
      card => card.orientation === 'F' && card.selected.length !== 0,
    )
    const includesA2Card = selectedCards.find(
      card => card.orientation !== 'F' && card.selected.length !== 0,
    )

    return includesFlatCard && includesA2Card
  }, [selectedCards])

  const rowClassName = useCallback(row => {
    let className = ''

    if (row.intl && !row.childrenNodes) className += ' yellow-bg'
    if ((row.gcard !== '-' || row.custom_inserts !== '-') && !row.childrenNodes)
      className += ' blue-bg'
    if (row.parent_id) className += ' batch-order-children'

    return className
  }, [])

  const initCardsData = () => {
    const cards = rows.map(row => ({
      id: row.card.id,
      keys: row.orders.some(order => order.childrenNodes) && row.orders.map(i => i.id),
      selected: row.orders.flatMap(order =>
        order.childrenNodes
          ? { orderId: order.id, ids: order.childrenNodes.map(i => i.id) }
          : order.id,
      ),
      orientation: row.card.orientation,
    }))

    setSelectedCards(cards)
  }

  const selectAllOrdersByCardId = cardId => {
    const cards = selectedCards.map(card => {
      const rowToSelect = rows.find(({ card }) => card.id === cardId)

      if (card.id === cardId) {
        return {
          ...card,
          keys:
            rowToSelect.orders.some(order => order.childrenNodes) &&
            rowToSelect.orders.map(i => i.id),
          selected: rowToSelect.orders.flatMap(order =>
            order.childrenNodes
              ? { orderId: order.id, ids: order.childrenNodes.map(i => i.id) }
              : order.id,
          ),
        }
      }
      return card
    })

    return setSelectedCards(cards)
  }

  useEffect(() => {
    if (rows) {
      const allOrdersIds = []
      rows.forEach(row => {
        const ordersIds = row.orders
          .map(order => (order.childrenNodes ? order.childrenNodes.map(i => i.id) : order.id))
          .flat()
        allOrdersIds.push(...ordersIds)
      })
      setAllOrdersIds(allOrdersIds)
    }
  }, [rows])

  const selectUnselectNested = (card, record, childrenLength, callback) => {
    if (card.id === record.card_id) {
      const keyRemoveAdd = keys => {
        const _keysStorage = new Set(keys)

        if (record.childrenNodes?.length === childrenLength) {
          return _keysStorage.add(record.id)
        }

        _keysStorage.delete(record.id)

        return _keysStorage
      }

      const keys = keyRemoveAdd(card.keys)

      return {
        ...card,
        keys: [...keys],
        selected: card.selected.map(x => {
          if (x.orderId === record.id) {
            return {
              ...x,
              ids: callback(x),
            }
          }
          return x
        }),
      }
    }

    return card
  }

  const control = (id, card) => {
    const { ids } = card

    return {
      deselect: () => ids.filter(i => i !== id),
      select: () => ids.concat(id),
      deselectall: () => [],
      selectall: ids => ids,
    }
  }

  const onSelect = (record, selected) => {
    if (!selected) {
      if (record.childrenNodes) {
        const result = selectedCards.map(card =>
          selectUnselectNested(card, record, 0, x => control(record.id, x).deselectall()),
        )

        return setSelectedCards(result)
      }

      const result = selectedCards.map(card => {
        const { selected, keys } = card

        return {
          ...card,
          selected: selected.filter(i => i !== record.id),
          keys: keys && keys.filter(i => i !== record.id),
        }
      })

      return setSelectedCards(result)
    }

    if (record.childrenNodes) {
      const nestedIds = record.childrenNodes.map(i => i.id)

      const result = selectedCards.map(card =>
        selectUnselectNested(card, record, record.childrenNodes.length, x =>
          control(record.id, x).selectall(nestedIds),
        ),
      )

      return setSelectedCards(result)
    }

    const result = selectedCards.map(card => {
      const { selected, keys, id: selectedCardId } = card

      if (record.card_id === selectedCardId) {
        return {
          ...card,
          selected: selected.concat(record.id),
          keys: keys && keys.concat(record.id),
        }
      }

      return card
    })

    return setSelectedCards(result)
  }

  return {
    initCardsData,
    selectedCards,
    allOrdersIds,
    isAllCardsFlat,
    orientationsConflict,
    selectUnselectNested,
    control,
    rowClassName,
    setSelectedCards,
    onSelect,
    selectAllOrdersByCardId,
  }
}
