import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Checkbox, Form, Input } from 'antd'

import NavContainer from '../../../../components/nav-container'
import { wordMappingActions } from '../../../../_actions'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import { wordMappingConstants } from '../../../../_constants'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'

const { formItemLayout, navMenu } = wordMappingConstants

const navMenuInit = [navMenu.list, navMenu.manage]

const CreateItem = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const abbreviationIsAllowed = Form.useWatch('allow_replace', form)

  const onFinish = values => {
    dispatch(wordMappingActions.addItem(convertFormValuesDeep(values)))
  }

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      {useGlobalLoader(
        <Form
          {...formItemLayout}
          name="basic"
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          initialValues={{
            allow_split: true,
            allow_replace: true,
          }}
        >
          <Form.Item
            label="Word"
            name="full_word"
            rules={[
              {
                required: true,
                message: 'Please input break word!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Allow Split Address Line" name="allow_split" valuePropName="checked">
            <Checkbox name="allow_split" />
          </Form.Item>

          <Form.Item label="Allow Abbreviation" name="allow_replace" valuePropName="checked">
            <Checkbox name="allow_replace" />
          </Form.Item>

          {abbreviationIsAllowed ? (
            <Form.Item
              label="Abbreviation"
              name="short_word"
              rules={[
                {
                  required: true,
                  message: 'Please input abbreviation!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          ) : null}

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>,
      )}
    </NavContainer>
  )
}

export default CreateItem
