import { framesConstants } from '../_constants'
import { framesService } from '../_services'
import { history } from '../_helpers'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: framesConstants.GETALL_REQUEST })
  try {
    const frames = await framesService.getAll(params, typeAction)
    dispatch({ type: framesConstants.GETALL_SUCCESS, payload: frames.data })
  } catch (error) {
    dispatch({ type: framesConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const addItem = value => async dispatch => {
  dispatch({ type: framesConstants.ADD_REQUEST })

  try {
    const frame = await framesService.addItem({ value })
    dispatch({ type: framesConstants.ADD_SUCCESS, payload: { ...value, id: frame.id } })
    history.push(`/qr-code-frames/view/${frame.id}`)
  } catch (error) {
    dispatch({ type: framesConstants.ADD_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: framesConstants.GETBYID_REQUEST })

  try {
    const frame = await framesService.getItemById(id)
    dispatch({ type: framesConstants.GETBYID_SUCCESS, payload: frame.data })
  } catch (error) {
    dispatch({ type: framesConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const deleteItem = id => async dispatch => {
  dispatch({ type: framesConstants.DELETE_REQUEST })
  try {
    await framesService.deleteItem(id)
    history.push(`/qr-code-frames`)
    dispatch({ type: framesConstants.DELETE_SUCCESS, payload: id })
  } catch (error) {
    dispatch({ type: framesConstants.DELETE_FAILURE, payload: error.toString() })
  }
}

const updateItemById = (id, value) => async dispatch => {
  dispatch({ type: framesConstants.UPDATE_REQUEST })

  try {
    const updated = await framesService.updateItemById(id, value)
    dispatch({ type: framesConstants.UPDATE_SUCCESS, payload: updated?.message?.toString() })
    history.push(`/qr-code-frames/view/${id}`)
  } catch (error) {
    dispatch({ type: framesConstants.UPDATE_FAILURE, payload: error.toString() })
  }
}

const framesActions = {
  getAll,
  getItemById,
  addItem,
  deleteItem,
  updateItemById,
}

export default framesActions
