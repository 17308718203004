import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import v4 from 'uuid/v4'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { activitiesActions } from '../../../../_actions'
import { activitiesConstants } from '../../../../_constants'
import {
  getActivityPage,
  getActivityRows,
  getActivityTotalItems,
} from '../../../../_selectors/clients.selector.js'

const { navMenu, advancedSearch } = activitiesConstants

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
  },
  {
    title: 'Date Created',
    dataIndex: 'date_created',
    key: 'date_created*date',
    sorter: true,
  },
  {
    title: 'Device',
    dataIndex: 'device',
    key: 'device',
    sorter: true,
  },
  {
    title: 'Ip',
    dataIndex: 'ip',
    key: 'ip',
    sorter: true,
  },
]

const navMenuInit = [navMenu.view, navMenu.edit, navMenu.manage]

const ListActivity = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getActivityRows)
  const page = useSelector(getActivityPage)
  const total = useSelector(getActivityTotalItems)

  const view = getCurrentUrl(match.url)

  useEffect(() => {
    return () => {
      dispatch({ type: activitiesConstants.SETALL })
    }
  }, [])

  const onEditItem = () => {
    history.push({
      pathname: `${view}/edit/${id}`,
    })
  }

  const onViewItem = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.edit.key:
          return {
            ...menu,
            url: onEditItem,
          }
        case navMenu.view.key:
          return {
            ...menu,
            url: onViewItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  return (
    <NavContainer match={match} menu={navMenuParse}>
      <TableFilter
        columns={initColumns}
        data={dataSource}
        getDataAction={activitiesActions.getClientActivity}
        actionsColum={false}
        isEditOnId={false}
        advancedSearch={advancedSearch}
      />
    </NavContainer>
  )
}

export default ListActivity
