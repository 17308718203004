import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import v4 from 'uuid/v4'
import getCurrentUrl from '../../../../_helpers/url-utils'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import { giftCardActions } from '../../../../_actions'
import { appConstants, giftCardsConstants, settingsConstants } from '../../../../_constants'
import {
  getGiftCardsRows,
  getPage,
  getTotalItems,
} from '../../../../_selectors/gift-cards.selector.js'

const { navMenu, advancedSearch } = giftCardsConstants

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Sort Order',
    dataIndex: 'sort',
    key: 'sort',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Image',
    dataIndex: 'image',
    key: 'image',
    hideFilter: true,
    align: 'center',
    render: imageUrl => (
      <img
        style={{ height: '100px' }}
        alt=" "
        src={`${appConstants.IMAGES_URL}/cardimages/${imageUrl}`}
      />
    ),
  },
]

const navMenuInit = [navMenu.list, navMenu.create]

const ManageItems = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getGiftCardsRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const deleteItems = async id => {
    await dispatch(giftCardActions.deleteItem(id))
  }

  const navigateToList = () => {
    dispatch({ type: giftCardsConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}/list`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.list.key:
          return {
            ...menu,
            url: navigateToList,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  return (
    <NavContainer menu={navMenuParse}>
      <TableFilter
        deleteItems={deleteItems}
        columns={initColumns}
        data={dataSource}
        getDataAction={giftCardActions.getAll}
        advancedSearch={advancedSearch}
        showAdvancedButton={!!true}
      />
    </NavContainer>
  )
}

export default ManageItems
