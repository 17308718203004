import React from 'react'
import style from './style.module.scss'

const Footer = () => {
  return (
    <div className={style.footer}>
      <div className={style.footerInner}>
        <p className="mb-0">
          Handwrytten Admin App |{' '}
          <a href="" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
          {` | Version ${process.env.REACT_APP_VERSION}`}
        </p>
      </div>
    </div>
  )
}

export default Footer
