import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Divider, Form, InputNumber, Select, Tooltip, Upload } from 'antd'
import { QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons'
import convertFormValuesDeep from '_helpers/hw-object-utils'
import NavContainer from '../../../../components/nav-container'
import { presetCardImagesConstants } from '../../../../_constants'
import { convertDataForSelect } from '../../../../_helpers'
import presetCardImagesActions from '_actions/preset-card-images.actions'
import AdvancedCheckboxList from 'components/advanced-checkbox-list'
import { cardDimensionActions } from '_actions'
import { getCardDimensions } from '_selectors/card-dimension.selector'

const {
  formItemLayout,
  navMenu,
  cardImageType,
  presetCardImageStatuses,
  sortNoTooltipMessage,
} = presetCardImagesConstants

const navMenuInit = [navMenu.list, navMenu.manage]

const CreateItem = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const [cardImage, setCardImage] = useState(null)

  const dimensionList = useSelector(getCardDimensions)

  useEffect(() => {
    dispatch(cardDimensionActions.getAll({ where: { can_customize: 1 } }))
  }, [])

  const normFile = e => {
    let file = e && e.fileList

    if (Array.isArray(e)) file = e

    const reader = new FileReader()
    reader.onload = () => setCardImage(reader.result)
    reader.readAsDataURL(file[0].originFileObj)

    return file
  }

  const statuses = useMemo(
    () =>
      presetCardImageStatuses.map(status => ({
        value: parseInt(status.value, 10),
        label: status.text,
      })),
    [presetCardImageStatuses],
  )

  const props = {
    showUploadList: {
      showRemoveIcon: false,
    },
  }

  const onFinish = async values => {
    const newItem = {
      file: {
        name: values.file[0].name,
        thumbUrl: cardImage,
        size: values.file[0].size,
      },
      type: values.type,
      sort_no: values.sort_no,
      status: values.status,
      dimension: values.dimension,
    }

    await dispatch(presetCardImagesActions.addItem(convertFormValuesDeep(newItem)))
  }

  const dimensions = useMemo(() => {
    return dimensionList?.map(e => ({ value: e.id, label: e.name }))
  }, [dimensionList])

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      <Form {...formItemLayout} name="basic" form={form} onFinish={onFinish} scrollToFirstError>
        <Form.Item
          label="Status"
          name="status"
          initialValue={1}
          rules={[
            {
              required: true,
              message: 'Please select Status!',
            },
          ]}
        >
          <Select options={statuses} />
        </Form.Item>

        <Form.Item
          name="type"
          label="Type"
          initialValue=""
          rules={[
            {
              required: true,
              message: 'Please select Type!',
            },
          ]}
        >
          <Select style={{ width: '140px' }} options={convertDataForSelect(cardImageType)} />
        </Form.Item>

        <Form.Item
          label={
            <>
              Sort No
              <Tooltip title={sortNoTooltipMessage} placement="top">
                <QuestionCircleOutlined style={{ marginLeft: '0.2rem' }} />
              </Tooltip>
            </>
          }
          name="sort_no"
          initialValue={0}
          rules={[
            {
              required: true,
              message: 'Please fill the field!',
            },
          ]}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          label="Image"
          name="file"
          valuePropName="file"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: 'Please input Image!',
            },
          ]}
        >
          <Upload
            {...props}
            name="icon"
            maxCount={1}
            accept="image/png, image/jpeg"
            beforeUpload={() => false}
            action=""
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>

        <Divider orientation="left">Dimension</Divider>
        <div>
          <AdvancedCheckboxList
            formItemProps={{
              name: 'dimension',
              valuePropName: 'value',
              rules: [
                {
                  required: true,
                  message: 'Please select Dimension!',
                },
              ],
            }}
            buttonText="Add Dimension"
            options={dimensions}
            form={form}
            initialValues={dimensionList?.filter(ca => ca.value).map(fca => fca.id)}
          />
        </div>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </NavContainer>
  )
}

export default CreateItem
