import { userConstants } from '../_constants'

const initialState = {
  user: null,
  permis: null,
  sentMailToResetPassword: false,
}

function users(state = initialState, action) {
  switch (action.type) {
    case userConstants.GETBYID_SUCCESS:
      return {
        ...state,
        user: action.user,
      }
    case userConstants.SETBYID:
      return {
        ...state,
        user: null,
      }
    case userConstants.SET_PERMIS:
      return {
        ...state,
        permis: action.payload,
      }
    case userConstants.SENT_EMAIL_SUCCESS:
      return {
        ...state,
        sentMailToResetPassword: true,
      }
    default:
      return state
  }
}

export default users
