import React, { useMemo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { banIpActions } from '_actions'
import { Form, Input, Button } from 'antd'

const ManageItems = () => {
  const dispatch = useDispatch()
  const [banIp, setBanIP] = useState('')

  const { items } = useSelector(state => state.banIP)

  const getIpList = async () => {
    await dispatch(banIpActions.getAll())
  }

  const ipRules = useMemo(() => {
    return items
      .map(item => item.concat('\n'))
      .toString()
      .replaceAll(',', '')
  }, [items])

  useEffect(() => {
    getIpList()
  }, [])

  useEffect(() => {
    setBanIP(ipRules)
  }, [ipRules])

  const handleChange = e => {
    const regex = /[a-zA-Z]+/g
    const value = e.target.value.replace(regex, '')
    setBanIP(value)
  }

  const onFinish = () => {
    return dispatch(banIpActions.saveBanIP(banIp))
  }

  return (
    <Form>
      <h2 className="text-gray-6">Change IPv4 blocking rules in one actions</h2>
      <p> IP ranges: (example: 10.11.12.13 or 10.11.12.1 - 10.11.12.127 or 10.11.12.1/28)</p>
      <Form.Item style={{ height: '100%', width: '40vw' }}>
        <Input.TextArea rows={20} value={banIp} onChange={e => handleChange(e)} />
      </Form.Item>
      <Form.Item>
        <Button onClick={onFinish}>Save</Button>
      </Form.Item>
    </Form>
  )
}

export default ManageItems
