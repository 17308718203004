import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { ImpositionDraggableItem, DraggablePageBarcode } from './index.js'
import { inchToPx } from '_helpers/convert'
import impositionLayoutsConstants from '_constants/imposition-layouts.constants.js'

const initialMatchLevels = {
  left: false,
  top: false,
  right: false,
  bottom: false,
}

const ImpositionDraggableList = ({
  paperSize,
  config,
  configId,
  cardDimensions,
  repeatModeData,
  lockedCards,
  setLockedCards,
}) => {
  const dispatch = useDispatch()

  const initialBarcodeContainerSize = {
    width: inchToPx(0.5),
    height: inchToPx(cardDimensions.card_height),
  }

  const [barcodeContainerSize, setBarcodeContainerSize] = useState(initialBarcodeContainerSize)
  const [availableScreenWidth, setAvailableScreenWidth] = useState(1)

  const isRepeatMode = !!repeatModeData?.active

  const sectionDividers = useMemo(() => {
    const dividers = []

    if (!repeatModeData?.active || repeatModeData?.sections_count < 2) return dividers

    const sectionWidth = paperSize.width / repeatModeData.sections_count

    for (let xPosition = sectionWidth; xPosition < paperSize.width; xPosition += sectionWidth) {
      dividers.push(xPosition)
    }

    return dividers
  }, [repeatModeData, paperSize.width])

  const sectionsCoordinates = useMemo(() => {
    const sections = []

    if (!repeatModeData?.active) return sections

    const sectionWidth = paperSize.width / repeatModeData.sections_count

    for (let xPosition = 0; xPosition < paperSize.width; xPosition += sectionWidth) {
      sections.push([inchToPx(xPosition), inchToPx(xPosition + sectionWidth)])
    }

    return sections
  }, [repeatModeData, paperSize.width])

  useEffect(() => {
    if (isRepeatMode && config?.barcode) {
      // update additional barcodes position according to the page barcode
      dispatch({
        type: impositionLayoutsConstants.UPDATE_ADDITIONAL_BARCODES,
        payload: { configId, pageWidth: inchToPx(paperSize.width) },
      })
    }
  }, [
    isRepeatMode,
    config?.barcode?.x,
    config?.barcode?.y,
    paperSize.width,
    repeatModeData?.sections_count,
  ])

  const cardSizeWithRotate = useMemo(() => {
    const size = {
      height: inchToPx(cardDimensions.card_height),
      width: inchToPx(cardDimensions.card_width),
    }

    // if card is rotated
    if (config.card_rotate) {
      size.width = inchToPx(cardDimensions.card_height)
      size.height = inchToPx(cardDimensions.card_width)
    }

    return size
  }, [cardDimensions, config.card_rotate])

  useEffect(() => {
    const barcodeContainerWidth = inchToPx(0.5)

    // change barcode container size depend on position
    if (config.barcode_position === 'left' || config.barcode_position === 'right') {
      setBarcodeContainerSize({
        height: cardSizeWithRotate.height,
        width: barcodeContainerWidth,
      })
    } else if (config.barcode_position === 'top' || config.barcode_position === 'bottom') {
      setBarcodeContainerSize({
        height: barcodeContainerWidth,
        width: cardSizeWithRotate.width,
      })
    } else {
      const noneSize = {
        height: 0,
        width: 0,
      }
      setBarcodeContainerSize(noneSize)
    }
  }, [config.barcode_position, cardSizeWithRotate])

  useEffect(() => {
    const handleWindowResize = () => {
      const mainContainer = document.getElementsByTagName('main')[0]
      const { width } = mainContainer.getBoundingClientRect()
      setAvailableScreenWidth(width)
    }

    handleWindowResize()

    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const scale = useMemo(() => {
    if (availableScreenWidth > inchToPx(paperSize.width)) return '1'

    return (availableScreenWidth / paperSize.width / 100).toFixed(2)
  }, [paperSize.width, availableScreenWidth])

  const barcodeSizeWithRotate = useMemo(() => {
    const size = {
      height: inchToPx(config.barcode.height),
      width: inchToPx(config.barcode.width),
    }

    // if barcode is rotated
    if (config.barcode.rotate) {
      size.width = inchToPx(config.barcode.height)
      size.height = inchToPx(config.barcode.width)
    }

    return size
  }, [config.barcode.height, config.barcode.width, config.barcode.rotate])

  const pageSizeInPx = useMemo(
    () => ({
      height: inchToPx(paperSize.height),
      width: inchToPx(paperSize.width),
    }),
    [paperSize],
  )

  const checkNeighborRelations = (id, x, y) => {
    const maxLevelDeviation = 0.5 // px

    const isDraggableBarcode = id === null
    const neighbors = config.front.concat(config.barcode).filter(element => {
      // if draggable element is barcode - simply return all elements with id
      if (isDraggableBarcode) {
        return !!element.id
      }

      return element.id !== id
    })

    const draggableItemEdges = {
      top: y,
      left: x,
      right: x + cardSizeWithRotate.width,
      bottom: y + cardSizeWithRotate.height,
    }

    if (isDraggableBarcode) {
      draggableItemEdges.right = x + barcodeSizeWithRotate.width
      draggableItemEdges.bottom = y + barcodeSizeWithRotate.height
    } else if (config.barcode_position === 'left') {
      draggableItemEdges.left -= barcodeContainerSize.width
    } else if (config.barcode_position === 'top') {
      draggableItemEdges.top -= barcodeContainerSize.height
    } else if (config.barcode_position === 'right') {
      draggableItemEdges.right += barcodeContainerSize.width
    } else if (config.barcode_position === 'bottom') {
      draggableItemEdges.bottom += barcodeContainerSize.height
    }

    const levelMatch = { ...initialMatchLevels }
    const pageCenterPosition = {
      x: pageSizeInPx.width / 2,
      y: pageSizeInPx.height / 2,
    }

    let distances = {
      left: x,
      top: y,
      right: pageSizeInPx.width - cardSizeWithRotate.width - x,
      bottom: pageSizeInPx.height - cardSizeWithRotate.height - y,
    }

    if (isDraggableBarcode) {
      distances = {
        left: x,
        top: y,
        right: pageSizeInPx.width - barcodeSizeWithRotate.width - x,
        bottom: pageSizeInPx.height - barcodeSizeWithRotate.height - y,
      }
    } else if (config.barcode_position === 'left') {
      distances.left -= barcodeContainerSize.width
    } else if (config.barcode_position === 'top') {
      distances.top -= barcodeContainerSize.height
    } else if (config.barcode_position === 'right') {
      distances.right -= barcodeContainerSize.width
    } else if (config.barcode_position === 'bottom') {
      distances.bottom -= barcodeContainerSize.height
    }

    // if it is repeated mode - we need to calculate distance for each vertical divider instead of page center
    if (isRepeatMode) {
      for (let index = 0; index < sectionDividers.length; index++) {
        const dividerXPosition = inchToPx(sectionDividers[index])
        if (draggableItemEdges.right <= dividerXPosition) {
          distances.right = dividerXPosition - draggableItemEdges.right

          if (Math.abs(draggableItemEdges.right - dividerXPosition) < maxLevelDeviation) {
            levelMatch.right = true
          }

          break
        }

        if (Math.abs(draggableItemEdges.right - dividerXPosition) < maxLevelDeviation) {
          levelMatch.right = true
        }
      }
    } else {
      if (draggableItemEdges.left > pageCenterPosition.x) {
        // element right of center
        distances.left = draggableItemEdges.left - pageCenterPosition.x
      } else if (draggableItemEdges.right < pageCenterPosition.x) {
        // element left of center
        distances.right = pageCenterPosition.x - draggableItemEdges.right
      }

      // level match with center lines
      if (Math.abs(draggableItemEdges.left - pageCenterPosition.x) < maxLevelDeviation) {
        levelMatch.left = true
      }

      if (Math.abs(draggableItemEdges.right - pageCenterPosition.x) < maxLevelDeviation) {
        levelMatch.right = true
      }
    }

    if (draggableItemEdges.bottom < pageCenterPosition.y) {
      // element top of center
      distances.bottom = pageCenterPosition.y - draggableItemEdges.bottom
    } else if (draggableItemEdges.top > pageCenterPosition.y) {
      // element bottom of center
      distances.top = draggableItemEdges.top - pageCenterPosition.y
    }

    if (Math.abs(draggableItemEdges.top - pageCenterPosition.y) < maxLevelDeviation) {
      levelMatch.top = true
    }

    if (Math.abs(draggableItemEdges.bottom - pageCenterPosition.y) < maxLevelDeviation) {
      levelMatch.bottom = true
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const neighbor of neighbors) {
      const neighborEdges = {
        top: neighbor.y,
        left: neighbor.x,
        right: neighbor.x + cardSizeWithRotate.width,
        bottom: neighbor.y + cardSizeWithRotate.height,
      }

      // if element has no id - it is page barcode
      if (!neighbor.id) {
        neighborEdges.right = neighbor.x + barcodeSizeWithRotate.width

        neighborEdges.bottom = neighbor.y + barcodeSizeWithRotate.height
      } else if (neighbor?.type === 'code39') {
        neighborEdges.right = neighbor.x + inchToPx(0.8)

        neighborEdges.bottom = neighbor.y + inchToPx(0.6)
      } else if (config.barcode_position === 'left') {
        neighborEdges.left -= barcodeContainerSize.width
      } else if (config.barcode_position === 'top') {
        neighborEdges.top -= barcodeContainerSize.height
      } else if (config.barcode_position === 'right') {
        neighborEdges.right += barcodeContainerSize.width
      } else if (config.barcode_position === 'bottom') {
        neighborEdges.bottom += barcodeContainerSize.height
      }

      // check level matches
      if (
        Math.abs(draggableItemEdges.top - neighborEdges.top) < maxLevelDeviation ||
        Math.abs(draggableItemEdges.top - neighborEdges.bottom) < maxLevelDeviation
      ) {
        levelMatch.top = true
      }

      if (
        Math.abs(draggableItemEdges.left - neighborEdges.left) < maxLevelDeviation ||
        Math.abs(draggableItemEdges.left - neighborEdges.right) < maxLevelDeviation
      ) {
        levelMatch.left = true
      }

      if (
        Math.abs(draggableItemEdges.right - neighborEdges.right) < maxLevelDeviation ||
        Math.abs(draggableItemEdges.right - neighborEdges.left) < maxLevelDeviation
      ) {
        levelMatch.right = true
      }

      if (
        Math.abs(draggableItemEdges.bottom - neighborEdges.bottom) < maxLevelDeviation ||
        Math.abs(draggableItemEdges.bottom - neighborEdges.top) < maxLevelDeviation
      ) {
        levelMatch.bottom = true
      }

      // calculate distances to closest neighbor elements
      const leftDistance = draggableItemEdges.left - neighborEdges.right
      const topDistance = draggableItemEdges.top - neighborEdges.bottom
      const rightDistance = draggableItemEdges.right - neighborEdges.left
      const bottomDistance = draggableItemEdges.bottom - neighborEdges.top

      // left distance calculation
      if (
        neighborEdges.right < draggableItemEdges.left &&
        Math.abs(leftDistance) < distances.left
      ) {
        distances.left = Math.abs(leftDistance)
      }

      // top distance calculation
      if (neighborEdges.bottom < draggableItemEdges.top && Math.abs(topDistance) < distances.top) {
        distances.top = Math.abs(topDistance)
      }

      // right distance calculation
      if (
        neighborEdges.left > draggableItemEdges.right &&
        Math.abs(rightDistance) < distances.right
      ) {
        distances.right = Math.abs(rightDistance)
      }

      // bottom distance calculation
      if (
        neighborEdges.top > draggableItemEdges.bottom &&
        Math.abs(bottomDistance) < distances.bottom
      ) {
        distances.bottom = Math.abs(bottomDistance)
      }
    }

    return {
      levelMatch,
      distances,
    }
  }

  return (
    <div style={{ height: pageSizeInPx.height * scale }}>
      <div
        className="imposition-template-paper"
        style={{
          width: `${pageSizeInPx.width}px`,
          height: `${pageSizeInPx.height}px`,
          scale,
        }}
      >
        {isRepeatMode ? (
          <>
            {sectionDividers.map(xPosition => (
              <div
                className="vertical-divider"
                key={xPosition}
                style={{ left: `${xPosition}in` }}
              />
            ))}
            <div className="disabled-gray-zone" style={{ left: `${sectionDividers[0]}in` }} />
          </>
        ) : (
          <>
            <div className="imposition-template-paper__center-point" />
            <div className="vertical-divider" />
            <div className="horizontal-divider" />
          </>
        )}
        {/* RENDER PAGE BARCODE */}
        <DraggablePageBarcode
          data={config.barcode}
          configId={configId}
          scale={scale}
          paperSize={pageSizeInPx}
          isRepeatMode={isRepeatMode}
          sectionBounds={sectionsCoordinates?.[0]}
          checkNeighborRelations={checkNeighborRelations}
        />
        {/* RENDER PAGE ADDITIONAL BARCODES */}
        {config.front
          .filter(element => element.type === 'code39')
          .map(element => (
            <DraggablePageBarcode
              key={element.id}
              data={element}
              configId={configId}
              scale={scale}
              paperSize={pageSizeInPx}
              isRepeatMode={isRepeatMode}
              sectionBounds={sectionsCoordinates[element?.section_id]}
              checkNeighborRelations={checkNeighborRelations}
              isAdditionalBarcode
              isRepeatedBarcode={isRepeatMode && element?.section_id !== 0}
            />
          ))}

        {/* RENDER FRONT IMAGES */}
        {config.front
          .filter(element => element.type === 'image')
          .map((element, index) => {
            const id = element.id || `config_${index}`
            return (
              <ImpositionDraggableItem
                key={id}
                id={id}
                index={index}
                data={element}
                configId={configId}
                scale={scale}
                cardSize={{
                  height: inchToPx(cardDimensions.card_height),
                  width: inchToPx(cardDimensions.card_width),
                }}
                paperSize={pageSizeInPx}
                sectionBounds={sectionsCoordinates[element?.section_id]}
                isRepeatMode={isRepeatMode}
                isRepeatedCard={isRepeatMode && element?.section_id !== 0}
                barcodeContainerSize={barcodeContainerSize}
                barcodeContainerPosition={config.barcode_position}
                cardSizeWithRotate={cardSizeWithRotate}
                isLocked={lockedCards.includes(id)}
                setLockedCards={setLockedCards}
                checkNeighborRelations={checkNeighborRelations}
              />
            )
          })}
      </div>
    </div>
  )
}

export default ImpositionDraggableList
