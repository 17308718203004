import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { ManageItems, ViewItem } from './views'
import Content from '../../components/content'

const Automations = () => {
  const match = useRouteMatch()

  return (
    <Content>
      <Switch>
        <Route exact path={match.url} component={ManageItems} />
        <Route path={`${match.url}/view/:id`} component={ViewItem} />
        <Redirect to={match.url} />
      </Switch>
    </Content>
  )
}

export default Automations
