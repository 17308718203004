const denominationConstants = {
  GETALL_REQUEST: 'DENOMINATIONS_GETALL_REQUEST',
  GETALL_SUCCESS: 'DENOMINATIONS_SUCCESS',
  GETALL_FAILURE: 'DENOMINATIONS_FAILURE',

  ADD_REQUEST: 'DENOMINATIONS_ADD_REQUEST',
  ADD_SUCCESS: 'DENOMINATIONS_ADD_SUCCESS',
  ADD_FAILURE: 'DENOMINATIONS_ADD_FAILURE',

  GETBYID_REQUEST: 'DENOMINATIONS_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'DENOMINATIONS_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'DENOMINATIONS_GETBYID_FAILURE',

  DELETE_REQUEST: 'DENOMINATIONS_DELETE_REQUEST',
  DELETE_SUCCESS: 'DENOMINATIONS_DELETE_SUCCESS',
  DELETE_FAILURE: 'DENOMINATIONS_DELETE_FAILURE',

  UPDATE_REQUEST: 'DENOMINATIONS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'DENOMINATIONS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'DENOMINATIONS_UPDATE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    list: { key: 'list', title: 'List Denomination', url: '/denominations/list' },
    manage: { key: 'manage', title: 'Manage Denomination', url: '/denominations' },
    create: { title: 'Create Denomination', key: 'create', url: '/denominations/create' },
    edit: { key: 'edit', title: 'Update Denomination', url: '' },
    delete: { key: 'delete', title: 'Delete Denomination', url: '' },
    view: { key: 'view', title: 'View Denomination', url: '' },
  },

  listItemKeyToLabel: {
    id: 'ID',
    gcard_id: 'Gift Card',
    nominal: 'Nominal Value',
    price: 'Price',
    active: 'Active',
  },

  viewItemKeyToLabel: {
    id: 'ID',
    gcard_id: 'Gift Card',
    nominal: 'Nominal Value',
    price: 'Price',
    active: 'Active',
  },
  advancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'Gift Card', key: 'gcard_id' },
    { label: 'Nominal Value', key: 'nominal' },
    { label: 'Price', key: 'price' },
  ],

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },
}

export default denominationConstants
