import { campaignStepsConstants } from '../_constants'

const initialState = {
  items: [],
  item: {},
}

function campaignSteps(state = initialState, { type, payload }) {
  switch (type) {
    case campaignStepsConstants.GETALL_SUCCESS:
      return { ...state, items: payload, item: {} }
    case campaignStepsConstants.GETBYID_SUCCESS:
      return { ...state, item: payload }
    case campaignStepsConstants.SETBYID:
      return {
        ...state,
        item: {},
      }
    case campaignStepsConstants.SETALL:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default campaignSteps
