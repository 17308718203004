import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, InputNumber, Select, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import { clientGroupActions, framesActions } from '../../../../_actions'
import { convertDataForSelect } from '../../../../_helpers'
import { framesConstants } from '../../../../_constants'
import { convertValuesToFormData } from '_helpers/hw-object-utils'
import FramePreview from 'components/frame-preview'
import QrCode from '../../../../assets/image/qr-code.png'
import { getClientGroupsRows } from '../../../../_selectors/client-group.selector.js'

const { formItemLayout, navMenu, frameStatuses, frameTypes } = framesConstants

const navMenuInit = [navMenu.list, navMenu.manage]

const CreateItem = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const [frame, setFrame] = useState(null)

  const positionX = Form.useWatch('position_x', form) || 0
  const positionY = Form.useWatch('position_y', form) || 0
  const qrCodeSize = Form.useWatch('qr_code_size', form) || 100

  const clientGroups = useSelector(getClientGroupsRows)

  const groups = useMemo(() => clientGroups?.map(e => ({ value: e.id, label: e.title })), [
    clientGroups,
  ])

  useEffect(() => {
    dispatch(clientGroupActions.getAll())
  }, [dispatch])

  const normFile = e => {
    let file = e && e.fileList
    if (Array.isArray(e)) file = e
    const reader = new FileReader()
    reader.onload = () => setFrame(reader.result)
    if (e.fileList.length) {
      reader.readAsDataURL(file[0].originFileObj)
    } else {
      setFrame('')
    }
    return file
  }

  useEffect(() => {
    form.setFieldsValue({
      status: 0,
    })
  }, [form])

  const onFinish = async values => {
    if (!values.position_y) values.position_y = 0
    if (!values.position_x) values.position_x = 0
    if (!values.qr_code_size) values.qr_code_size = 100
    if (!values.user_group_id) values.user_group_id = []

    await dispatch(framesActions.addItem(convertValuesToFormData(values)))
  }

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      <Form {...formItemLayout} name="basic" form={form} onFinish={onFinish} scrollToFirstError>
        <Form.Item
          name="status"
          label="Status"
          rules={[
            {
              required: true,
              message: 'Please select value!',
            },
          ]}
        >
          <Select options={convertDataForSelect(frameStatuses)} />
        </Form.Item>
        <Form.Item
          name="type"
          label="Type"
          rules={[
            {
              required: true,
              message: 'Please select value!',
            },
          ]}
        >
          <Select options={convertDataForSelect(frameTypes)} />
        </Form.Item>

        <Form.Item label="Customer Groups" name="user_group_id">
          <Select
            allowClear
            showSearch
            options={groups}
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
            }
          />
        </Form.Item>

        <Form.Item
          label="Frame"
          name="file"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: 'Please upload file!',
            },
          ]}
        >
          <Upload
            name="icon"
            beforeUpload={() => {
              return false
            }}
            action=""
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item label="QR-code x position(%)" name="position_x">
          <InputNumber step={1} min={0} max={100} defaultValue={0} />
        </Form.Item>
        <Form.Item label="QR-code y position(%)" name="position_y">
          <InputNumber step={1} min={0} max={100} defaultValue={0} />
        </Form.Item>
        <Form.Item label="QR-code size(%)" name="qr_code_size">
          <InputNumber step={1} min={0} max={100} defaultValue={100} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>

      {frame && (
        <FramePreview
          qrCodeUrl={QrCode}
          qrCodeSize={qrCodeSize}
          positionX={positionX}
          positionY={positionY}
          frame={frame}
        />
      )}
    </NavContainer>
  )
}
export default CreateItem
