const parseFileForSetFields = (file, options = {}) => {
  return file
    ? [
        {
          uid: '-1',
          name: file,
          status: 'done',
          url: file,
          key: file,
          ...options,
        },
      ]
    : []
}

export default parseFileForSetFields
