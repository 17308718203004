import axios from 'axios'
import serialize from '_helpers/hw-serialize-params'
import apiUrl from './service'
import { v1 } from './api'

const getClientActivity = async (params, typeAction, id) => {
  const result = await axios.get(`${apiUrl}/${v1}/activities/client/${id}?${serialize(params)}`, {
    params: { stopSpinner: typeAction },
  })
  return handleResponse(result)
}
const getEmployeeActivity = async (params, typeAction, id) => {
  const result = await axios.get(`${apiUrl}/${v1}/activities/employee/${id}?${serialize(params)}`, {
    params: { stopSpinner: typeAction },
  })
  return handleResponse(result)
}

const handleResponse = result => {
  return result.data
}

const activityService = {
  getClientActivity,
  getEmployeeActivity,
}

export default activityService
