import { creditCardsConstants } from '../_constants'

const initialState = {
  items: [],
}

function creditCards(state = initialState, { type, payload }) {
  switch (type) {
    case creditCardsConstants.GETALL_SUCCESS:
      return { ...state, items: payload }
    case creditCardsConstants.SETALL:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default creditCards
