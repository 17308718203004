import axios from 'axios'
import serialize from '_helpers/hw-serialize-params'
import { v1 } from './api'
import apiUrl from './service'

const getAll = async (params, typeAction) => {
  const result = await axios.get(`${apiUrl}/${v1}/fonts?${serialize(params)}`, {
    params: { stopSpinner: typeAction },
  })
  return handleResponse(result)
}

const getItemById = async id => {
  const result = await axios.get(`${apiUrl}/${v1}/fonts/${id}`)
  return handleResponse(result)
}

const deleteItem = async id => {
  const result = await axios.delete(`${apiUrl}/${v1}/fonts/${id}`, {
    params: { stopSpinner: 'paginate' },
  })
  return handleResponse(result)
}

const addItem = async ({ value }) => {
  const result = await axios.post(`${apiUrl}/${v1}/fonts`, JSON.stringify(value))
  return handleResponse(result)
}

const updateItemById = async (id, changes, isChangeVisible) => {
  const result = await axios.put(
    `${apiUrl}/${v1}/fonts/${id}?isChangeVisible=${!!isChangeVisible}`,
    {
      changes,
      stopSpinner: 'changeVisible',
    },
  )
  return handleResponse(result)
}

const handleResponse = result => {
  return result.data
}

const fontsService = {
  getAll,
  getItemById,
  deleteItem,
  updateItemById,
  addItem,
}

export default fontsService
