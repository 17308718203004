import React, { useEffect, useState, useMemo } from 'react'
import { Button, Checkbox, Divider, Form, Input, Upload } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import NavContainer from '../../../../components/nav-container'
import { categoriesActions, clientGroupActions, denominationActions } from '../../../../_actions'
import { clientGroupConstants } from '../../../../_constants'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import { UploadOutlined } from '@ant-design/icons'
import { convertValuesToFormData } from '_helpers/hw-object-utils'
import AdvancedCheckboxList from 'components/advanced-checkbox-list'
import sortByIdAndPrice from '_helpers/sort-by-id-and-price'
import { getCategoriesRows } from '../../../../_selectors/categories.selector.js'
import { getDenominationRows } from '../../../../_selectors/denominations.selector.js'

const {
  navMenu,
  layout,
  tailLayout,
  LIMIT_CATEGORIES_PROP,
  LIMIT_DENOMINATIONS_PROP,
} = clientGroupConstants

const navMenuInit = [navMenu.list, navMenu.manage]

const CreateItems = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const [, setGroupsDisabled] = useState(true)

  const rows = useSelector(getCategoriesRows)
  const denominationsList = useSelector(getDenominationRows)

  useEffect(() => {
    Promise.all([
      dispatch(categoriesActions.getAll({ order: { key: 'name', order: 'ASC' } })),
      dispatch(denominationActions.getAll()),
    ])
  }, [])

  // its need to set [] when the employee dont select any categories
  useEffect(() => {
    form.setFieldsValue({
      category: [],
      no_delivery_confirmation: 0,
      denomination: [],
    })
  }, [])

  const categories = useMemo(() => rows?.map(e => ({ value: e.id, label: e.name })), [rows])

  const denominations = useMemo(
    () =>
      denominationsList
        ? sortByIdAndPrice(
            denominationsList?.map(e => ({
              value: e.id,
              label: `${e.gcard.name} - $${parseFloat(e.nominal).toFixed(2)}`,
              id: e.gcard_id,
              price: e.nominal,
            })),
          )
        : [],
    [denominationsList],
  )

  const onLimitChange = ({ target: { checked } }) => {
    setGroupsDisabled(!checked)

    // if (!checked) form.setFieldsValue({ category: [] })
  }

  const onUpload = e => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const onFinish = async values => {
    const payload = {
      ...values,
      denomination: values.denomination.map(item => item.value),
    }
    await dispatch(clientGroupActions.addItem(convertValuesToFormData(payload)))
  }

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      {useGlobalLoader(
        <Form {...layout} name="basic" form={form} onFinish={onFinish} scrollToFirstError>
          <Form.Item
            {...tailLayout}
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: 'Please input title!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="No Delivery Confirmation"
            name="no_delivery_confirmation"
            valuePropName="checked"
          >
            <Checkbox name="no_delivery_confirmation" />
          </Form.Item>

          <Form.Item
            {...tailLayout}
            label="Logo"
            name="logo"
            valuePropName="fileList"
            getValueFromEvent={onUpload}
          >
            <Upload
              name="icon"
              maxCount={1}
              beforeUpload={() => {
                return false
              }}
              action=""
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>

          <p style={{ marginTop: '-12px', fontSize: '13px' }}>
            * Upload size must be no larger than 157 x 44.
          </p>

          <Divider orientation="left">Categories</Divider>
          <Form.Item
            {...tailLayout}
            name={LIMIT_CATEGORIES_PROP}
            valuePropName="checked"
            onChange={onLimitChange}
            initialValue={false}
          >
            <Checkbox>Limit Categories</Checkbox>
          </Form.Item>

          <div>
            <AdvancedCheckboxList
              formItemProps={{
                name: 'category',
                valuePropName: 'value',
              }}
              buttonText="Add Category"
              options={categories}
              form={form}
            />
          </div>
          <Divider orientation="left">Denominations</Divider>
          <Form.Item
            {...tailLayout}
            name={LIMIT_DENOMINATIONS_PROP}
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox>Limit Denominations</Checkbox>
          </Form.Item>

          <div>
            <AdvancedCheckboxList
              isButtonAboveTags
              sortOnAddTag={sortByIdAndPrice}
              formItemProps={{
                name: 'denomination',
                valuePropName: 'value',
              }}
              buttonText="Add Gift Card"
              options={denominations}
              form={form}
              switchStyle={{ flex: 'unset' }}
              tagsContainerStyle={{ display: 'flex', flexDirection: 'column' }}
            />
          </div>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>,
      )}
    </NavContainer>
  )
}

export default CreateItems
