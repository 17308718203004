import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Tooltip,
  Upload,
} from 'antd'
import { QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import { cardDimensionActions, cardsActions, categoriesActions } from '../../../../_actions'
import { convertDataForSelect } from '../../../../_helpers'
import { cardsConstants, messagesConstants } from '../../../../_constants'
import ConfirmWarningCheckbox from 'components/confirm-warning-checkbox'
import useValidationEmailForNotification from '../../../../hooks/clients/useValidationEmailForNotification.js'
import { getCategoriesRows } from '../../../../_selectors/categories.selector.js'
import { getCardDimensions } from '../../../../_selectors/card-dimension.selector.js'

const {
  formItemLayout,
  navMenu,
  cardStatuses,
  defaultValueFields,
  cardTaxExempt,
  cardCoverRestricted,
  cardAvailableFree,
  cardOrientation,
  cardSupressReturnAddress,
  fieldsToIgnore,
  marginsTooltipMessage,
} = cardsConstants

const { riskWarningMessage } = messagesConstants

const navMenuInit = [navMenu.list, navMenu.manage]

const CreateItem = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [isCustomizable, setIsCustomizable] = useState(false)

  const categories = useSelector(getCategoriesRows)
  const cardDimensions = useSelector(getCardDimensions)

  const [coverImage, setCover] = useState(null)
  const [isAllowedChangeDefault, setAllowChangeDefault] = useState({
    preview_margin_top: false,
    preview_margin_bottom: false,
    top_margin_no_logo: false,
    bottom_margin_no_logo: false,
  })

  const activeCardDimensionId = Form.useWatch('dimension_id', form)

  const fetchData = async () => {
    await Promise.all([
      dispatch(categoriesActions.getAll({ order: { key: 'name', order: 'ASC' } })),
      dispatch(cardDimensionActions.getAll({ where: { status: 1 } })),
    ])
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (activeCardDimensionId) {
      const activeCardDimension = cardDimensions.find(dim => dim.id === activeCardDimensionId)
      const { name, status, ...dataToSet } = activeCardDimension
      form.setFieldsValue(dataToSet)
    }
  }, [activeCardDimensionId, cardDimensions])

  const { emailWarning, setEmailWarning, validateEmailList } = useValidationEmailForNotification()

  const categoriesSelect = useMemo(() => {
    return categories?.map(category => ({
      value: category.id,
      label: category.name,
      taxonomy: category.taxonomy,
    }))
  }, [categories])

  const cardDimensionsSelect = useMemo(() => {
    if (!cardDimensions?.length) return null

    return cardDimensions?.map(dimension => ({
      value: dimension.id,
      label: dimension.name,
    }))
  }, [cardDimensions])

  const normFile = e => {
    let file = e && e.fileList
    if (Array.isArray(e)) file = e
    const reader = new FileReader()
    reader.onload = () => setCover(reader.result)
    reader.readAsDataURL(file[0].originFileObj)
    return file
  }

  useEffect(() => {
    form.setFieldsValue({
      ...defaultValueFields,
      category_id: categoriesSelect?.[0].value,
      dimension_id: cardDimensionsSelect[0]?.value || null,
    })
  }, [form, categoriesSelect, cardDimensionsSelect])

  useEffect(() => {
    if (isCustomizable) {
      const defaultValues = {}

      if (!isAllowedChangeDefault.preview_margin_top) defaultValues.preview_margin_top = 1.0
      if (!isAllowedChangeDefault.preview_margin_bottom) defaultValues.preview_margin_bottom = 0.65
      if (!isAllowedChangeDefault.top_margin_no_logo) defaultValues.top_margin_no_logo = 0.3
      if (!isAllowedChangeDefault.bottom_margin_no_logo) defaultValues.bottom_margin_no_logo = 0.3

      form.setFieldsValue(defaultValues)
    }
  }, [isCustomizable, isAllowedChangeDefault])

  const onFinish = async values => {
    const cover = values.cover?.[0]
    const newItem = {}
    Object.keys(values).forEach(keys => {
      if (fieldsToIgnore.includes(keys)) return

      if (values[keys] && keys !== 'cover') newItem[keys] = values[keys]
      if (keys === 'cover' && cover) {
        newItem.file = {
          ...cover,
          thumbUrl: coverImage,
        }
      } else if (!isCustomizable) {
        newItem.full_bleed = 0
      }
    })

    await dispatch(cardsActions.addItem(newItem))
  }

  const onCategoryChange = category => {
    if (category.taxonomy === 'CUSTOMIZED') setIsCustomizable(true)
    else setIsCustomizable(false)
  }

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      <Form {...formItemLayout} name="basic" form={form} onFinish={onFinish} scrollToFirstError>
        <Form.Item name="status" label="Status">
          <Select options={convertDataForSelect(cardStatuses)} />
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input name!',
            },
            () => ({
              validator(_, value) {
                if (value.trim() === '/')
                  return Promise.reject(
                    new Error(`Cannot use the reserved word '/' as a card name.
                `),
                  )
                return Promise.resolve()
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="SKU" name="sku">
          <Input />
        </Form.Item>
        <Form.Item label="Category" name="category_id">
          <Select
            onSelect={(input, option) => onCategoryChange(option)}
            showSearch
            options={categoriesSelect}
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item label="Card Dimension" name="dimension_id">
          <Select
            showSearch
            options={cardDimensionsSelect}
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item name="tax_exempt" label="Tax Exempt">
          <Select options={convertDataForSelect(cardTaxExempt)} />
        </Form.Item>
        <Form.Item name="cover_restricted" label="Cover Restricted">
          <Select options={convertDataForSelect(cardCoverRestricted)} />
        </Form.Item>
        {isCustomizable ? (
          <Form.Item label="Full Bleed" name="full_bleed" valuePropName="checked">
            <Checkbox name="full_bleed" />
          </Form.Item>
        ) : null}
        <Form.Item label="Cover" name="cover" valuePropName="fileList" getValueFromEvent={normFile}>
          <Upload
            name="icon"
            maxCount={1}
            accept="image/png, image/jpeg"
            beforeUpload={() => {
              return false
            }}
            action=""
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item label="Price" name="price">
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input.TextArea rows={5} />
        </Form.Item>
        <Form.Item label="Notes" name="notes">
          <Input.TextArea rows={5} />
        </Form.Item>
        {/* <Form.Item label="Character Width" name="width">
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item label="Character Lines" name="height">
          <InputNumber min={0} />
        </Form.Item> */}
        <Form.Item label="Character Count" name="character_count">
          <InputNumber min={0} disabled />
        </Form.Item>
        <Form.Item label="Quantity" name="quantity">
          <InputNumber />
        </Form.Item>
        <Form.Item name="available_free" label="Available Free">
          <Select options={convertDataForSelect(cardAvailableFree)} />
        </Form.Item>
        <Form.Item name="orientation" label="Orientation">
          <Select options={convertDataForSelect(cardOrientation)} disabled />
        </Form.Item>
        <Form.Item label="Sort No" initialValue={0} name="sort_no">
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item label="Images" name="images">
          <span className="ant-form-text">images</span>
        </Form.Item>
        <Form.Item name="supress_return_address" label="Supress Return Address">
          <Select options={convertDataForSelect(cardSupressReturnAddress)} />
        </Form.Item>
        <Divider orientation="left">
          <span style={{ marginRight: '0.5rem' }}>Preview attributes</span>
          <Tooltip title={marginsTooltipMessage} placement="top">
            <QuestionCircleOutlined />
          </Tooltip>
        </Divider>

        <Form.Item
          label="Margin Top"
          tooltip={isCustomizable ? { title: riskWarningMessage } : null}
        >
          <Space direction="vertical">
            <Form.Item name="preview_margin_top" noStyle>
              <InputNumber step={0.005} />
            </Form.Item>
            <ConfirmWarningCheckbox
              visible={isCustomizable}
              checked={isAllowedChangeDefault.preview_margin_top}
              field="preview_margin_top"
              setAllow={setAllowChangeDefault}
            />
          </Space>
        </Form.Item>

        <Form.Item label="Margin Right" name="preview_margin_right">
          <InputNumber step={0.005} />
        </Form.Item>
        <Form.Item
          label="Margin Bottom"
          tooltip={isCustomizable ? { title: riskWarningMessage } : null}
        >
          <Space direction="vertical">
            <Form.Item name="preview_margin_bottom" noStyle>
              <InputNumber step={0.005} />
            </Form.Item>
            <ConfirmWarningCheckbox
              visible={isCustomizable}
              checked={isAllowedChangeDefault.preview_margin_bottom}
              field="preview_margin_bottom"
              setAllow={setAllowChangeDefault}
            />
          </Space>
        </Form.Item>
        <Form.Item label="Margin Left" name="preview_margin_left">
          <InputNumber step={0.005} />
        </Form.Item>
        {isCustomizable ? (
          <>
            <Divider orientation="left">Preview attributes (no-logo, deprecated)</Divider>

            <Form.Item
              label="Top margin no logo"
              rules={[
                {
                  required: true,
                  message: 'Please input value!',
                },
              ]}
              tooltip={isCustomizable ? { title: riskWarningMessage } : null}
            >
              <Space direction="vertical">
                <Form.Item name="top_margin_no_logo" noStyle>
                  <InputNumber step={0.005} defaultValue={0.3} disabled />
                </Form.Item>
                <ConfirmWarningCheckbox
                  visible={isCustomizable}
                  checked={isAllowedChangeDefault.top_margin_no_logo}
                  field="top_margin_no_logo"
                  setAllow={setAllowChangeDefault}
                />
              </Space>
            </Form.Item>

            <Form.Item
              label="Bottom margin no logo"
              rules={[
                {
                  required: true,
                  message: 'Please input value!',
                },
              ]}
              tooltip={isCustomizable ? { title: riskWarningMessage } : null}
            >
              <Space direction="vertical">
                <Form.Item name="bottom_margin_no_logo" noStyle>
                  <InputNumber step={0.005} defaultValue={0.3} disabled />
                </Form.Item>
                <ConfirmWarningCheckbox
                  visible={isCustomizable}
                  checked={isAllowedChangeDefault.bottom_margin_no_logo}
                  field="bottom_margin_no_logo"
                  setAllow={setAllowChangeDefault}
                />
              </Space>
            </Form.Item>
          </>
        ) : null}
        <Divider orientation="left">Printing attributes</Divider>
        <Form.Item label="Open Height" name="open_height">
          <InputNumber step={0.005} disabled />
        </Form.Item>
        <Form.Item label="Open Width" name="open_width">
          <InputNumber step={0.005} disabled />
        </Form.Item>
        <Form.Item label="Closed Height" name="closed_height">
          <InputNumber step={0.005} disabled />
        </Form.Item>
        <Form.Item label="Closed Width" name="closed_width">
          <InputNumber step={0.005} disabled />
        </Form.Item>
        <Form.Item label="Margin Top" name="margin_top">
          <InputNumber step={0.005} disabled />
        </Form.Item>
        <Form.Item label="Margin Right" name="margin_right">
          <InputNumber step={0.005} disabled />
        </Form.Item>
        <Form.Item label="Margin Bottom" name="margin_bottom">
          <InputNumber step={0.005} disabled />
        </Form.Item>
        <Form.Item label="Margin Left" name="margin_left">
          <InputNumber step={0.005} disabled />
        </Form.Item>
        <Form.Item label="Envelope Height" name="envelope_height">
          <InputNumber step={0.005} disabled />
        </Form.Item>
        <Form.Item label="Envelope Width" name="envelope_width">
          <InputNumber step={0.005} disabled />
        </Form.Item>
        <Form.Item label="Envelope Font Size" name="envelope_font_size">
          <InputNumber step={1} min={0} disabled />
        </Form.Item>
        <Form.Item label="Envelope Lines Between Addresses" name="envelope_lines_between_addresses">
          <InputNumber step={1} min={0} disabled />
        </Form.Item>
        <Form.Item label="Envelope To Tabs" name="envelope_to_tabs">
          <InputNumber step={1} min={0} disabled />
        </Form.Item>
        <Form.Item label="Envelope Margin Top" name="envelope_margin_top">
          <InputNumber step={0.005} disabled />
        </Form.Item>
        <Form.Item label="Envelope Margin Right" name="envelope_margin_right">
          <InputNumber step={0.005} disabled />
        </Form.Item>
        <Form.Item label="Envelope Margin Bottom" name="envelope_margin_bottom">
          <InputNumber step={0.005} disabled />
        </Form.Item>
        <Form.Item label="Envelope Margin Left" name="envelope_margin_left">
          <InputNumber step={0.005} disabled />
        </Form.Item>

        <Divider orientation="left">Additional Details</Divider>
        <Form.Item label="Details Author" name="details_author">
          <Input />
        </Form.Item>
        <Form.Item label="Details Envelope" name="details_envelope">
          <Input />
        </Form.Item>
        <Divider orientation="left">Low Stock Notifications</Divider>
        <Form.Item label="Low Stock Threshold" name="low_stock_threshold">
          <Input />
        </Form.Item>

        <Form.Item label="Client Notification Emails">
          <Tooltip title="Wrong format. Check your value." open={emailWarning}>
            <Form.Item name="client_notification_emails" noStyle>
              <Input.TextArea
                rows={6}
                onFocus={() => setEmailWarning(false)}
                onBlur={validateEmailList}
                style={emailWarning ? { border: '1px solid #E9D502' } : {}}
              />
            </Form.Item>
          </Tooltip>
          <p style={{ fontSize: '13px' }}>
            * You can separate emails by writing them on a new line, use space or one of the special
            symbols like ";" or ","
          </p>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </NavContainer>
  )
}
export default CreateItem
