import React, { useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EditOutlined } from '@ant-design/icons'

import { ImpositionLayoutConfig } from 'components/imposition-layout'

import useEditPageUnload from './useEditPageUnload'
import { cardDimensionActions, impositionLayoutsActions } from '_actions'
import { impositionLayoutsConstants } from '_constants'
import { impositionLayoutsSelectors } from '_selectors/imposition-layouts.selector'
import { getCardDimensions } from '../../_selectors/card-dimension.selector.js'

const useImpositionLayout = impositionId => {
  const dispatch = useDispatch()

  const [activeTab, setActiveTab] = useState('1')

  const { name = '', height = 0, width = 0 } =
    useSelector(impositionLayoutsSelectors.getImposition) || {}
  const configsData = useSelector(impositionLayoutsSelectors.getConfigs)
  const cardDimensionsList = useSelector(getCardDimensions)

  useEffect(() => {
    if (cardDimensionsList?.length) {
      setActiveTab(String(cardDimensionsList[0].id)) // set first available card_dimension as default value
    }
  }, [cardDimensionsList])

  // check configs that was changed to warn the client about unsaved data when navigating
  const isSomeConfigEdited = useMemo(() => {
    if (configsData) {
      return configsData.some(configData => configData?.edited)
    }
  }, [configsData])

  useEditPageUnload(isSomeConfigEdited)

  const fetchData = async () => {
    const promises = [dispatch(cardDimensionActions.getAll({ where: { status: 1 } }))]

    if (impositionId) {
      promises.push(
        dispatch(impositionLayoutsActions.getItemById(impositionId)),
        dispatch(impositionLayoutsActions.getImpositionConfigs(impositionId)),
      )
    }

    await Promise.all(promises)
  }

  useEffect(() => {
    fetchData()
    return () => {
      dispatch({ type: impositionLayoutsConstants.RESET_CONFIGS }) // clear configs data on unmount
    }
  }, [impositionId])

  const pageSize = useMemo(
    () => ({
      height,
      width,
    }),
    [height, width],
  )

  // create default config if some dimension has no configs yet
  const createEmptyConfig = dimensionId => {
    const emptyConfigData = {
      dimension_id: dimensionId,
      imposition_id: impositionId,
      status: 1,
      config: {
        barcode: {
          value: '0',
          x: 0,
          y: 0,
          width: 0.8,
          height: 0.6,
          rotate: 270,
        },
        barcode_position: 'left',
        card_rotate: 0,
        front: [],
        back: [],
      },
    }
    dispatch({
      type: impositionLayoutsConstants.CREATE_EMPTY_CONFIG,
      payload: {
        emptyConfigData,
      },
    })
    return emptyConfigData
  }

  const tabsData = useMemo(() => {
    const dataList = []

    if (!cardDimensionsList || (impositionId && !configsData)) return []
    // eslint-disable-next-line no-restricted-syntax
    for (const dimension of cardDimensionsList) {
      const cardDimensionWithTrim = {
        ...dimension,
        card_height: +dimension.open_height + 0.25,
        card_width: +dimension.open_width + 0.25,
      }

      const configData =
        configsData.find(config => config.dimension_id === dimension.id) ||
        createEmptyConfig(dimension.id)

      const data = {
        dimensionName: dimension.name,
        dimensionId: dimension.id,
        configData,
        cardDimensions: cardDimensionWithTrim,
      }

      dataList.push(data)
    }

    return dataList
  }, [impositionId, configsData, cardDimensionsList, pageSize, activeTab])

  const tabItems = useMemo(() => {
    return tabsData.map(tabData => ({
      label: tabData.dimensionName,
      key: String(tabData.dimensionId),
      icon: tabData.configData?.edited ? <EditOutlined /> : null,
      children: (
        <ImpositionLayoutConfig
          paperSize={pageSize}
          configId={tabData.dimensionId}
          impositionId={+impositionId}
          configData={tabData.configData}
          cardDimensions={tabData.cardDimensions}
          isActiveTab={activeTab === String(tabData.dimensionId)}
        />
      ),
    }))
  }, [tabsData])

  //   const generatePreviewHandler = async () => {
  //     const convertedConfigs = impositionHelpers.convertConfigsBeforeSave(configs, pageSize)

  //     const previewData = {
  //       height,
  //       width,
  //       config: convertedConfigs,
  //     }
  //     console.log('TODO')
  //     // await dispatch(impositionTemplatesActions.generateImpositionPreview(previewData))
  //   }

  return {
    name,
    activeTab,
    tabItems,
    setActiveTab,
  }
}

export default useImpositionLayout
