import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { fontActions } from '../../../../_actions'
import NavContainer from '../../../../components/nav-container'
import ListViewer from '../../../../components/list-viewer'
import { fontConstants } from '../../../../_constants'
import { getPage, getFontsRows, getTotalItems } from '../../../../_selectors/fonts.selector.js'

const { navMenu, listItemKeyToLabel, renderComponent, settingsConstants } = fontConstants

const navMenuInit = [navMenu.create, navMenu.manage]

const ListItems = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getFontsRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const navigateToManage = () => {
    dispatch({ type: fontConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.manage.key:
          return {
            ...menu,
            url: navigateToManage,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const listItems = useMemo(() => {
    return {
      total,
      page,
      rows: rows?.map(item => {
        const res = []
        Object.keys(listItemKeyToLabel).forEach(sKey => {
          const itemLine = { key: sKey, label: listItemKeyToLabel[sKey], value: item[sKey] || '' }
          if (sKey === 'id')
            itemLine.clickHandler = () => history.push({ pathname: `/fonts/view/${item[sKey]}` })
          if (sKey === 'image') {
            itemLine.type = 'image'
            itemLine.value = renderComponent.image(item[sKey], item.font_name)
          }
          if (sKey === 'sort') {
            itemLine.value = item.sort || '0'
          }
          res.push(itemLine)
        })
        return res
      }),
    }
  }, [rows, total, page])

  return (
    <NavContainer menu={navMenuParse}>
      <ListViewer data={listItems} getDataAction={fontActions.getAll} />
    </NavContainer>
  )
}

export default ListItems
