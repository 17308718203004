const discountsCodesConstants = {
  GETALL_REQUEST: 'DISCOUNTS_CODES_GETALL_REQUEST',
  GETALL_SUCCESS: 'DISCOUNTS_CODES_GETALL_SUCCESS',
  GETALL_FAILURE: 'DISCOUNTS_CODES_GETALL_FAILURE',

  GETBYID_REQUEST: 'DISCOUNTS_CODES_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'DISCOUNTS_CODES_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'DISCOUNTS_CODES_GETBYID_FAILURE',

  ADD_REQUEST: 'DISCOUNTS_CODES_ADD_REQUEST',
  ADD_SUCCESS: 'DISCOUNTS_CODES_ADD_SUCCESS',
  ADD_FAILURE: 'DISCOUNTS_CODES_ADD_FAILURE',

  DELETE_REQUEST: 'DISCOUNTS_CODES_DELETE_REQUEST',
  DELETE_SUCCESS: 'DISCOUNTS_CODES_DELETE_SUCCESS',
  DELETE_FAILURE: 'DISCOUNTS_CODES_DELETE_FAILURE',

  UPDATE_REQUEST: 'DISCOUNTS_CODES_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'DISCOUNTS_CODES_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'DISCOUNTS_CODES_UPDATE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    list: { key: 'list', title: 'List Discount Code', url: '/discount-codes/list' },
    manage: { key: 'manage', title: 'Manage Discount Code', url: '/discount-codes' },
    create: { title: 'Create Discount Code', key: 'create', url: '/discount-codes/create' },
    edit: { key: 'edit', title: 'Update Discount Code', url: '' },
    delete: { key: 'delete', title: 'Delete Discount Code', url: '' },
    view: { key: 'view', title: 'View Discount Code', url: '' },
  },

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },

  itemTypes: [
    { value: 'normal', text: 'Normal' },
    { value: 'cards_only', text: 'Bonus (Cards Only)' },
  ],

  viewItemKeyToLabel: {
    id: 'ID',
    code: 'Code',
    domain_regex: 'Domain Regex',
    count: 'Count',
    users_limit: 'Limit Redemptions',
    credit: 'Credit',
    type: 'Type',
    invoiced: 'Invoiced',
    user_group: 'Client Group',
  },

  listItemKeyToLabel: {
    id: 'ID',
    code: 'Code',
    domain_regex: 'Domain Regex',
    credit: 'Credit',
    count: 'Count',
    users_limit: 'Limit Redemptions',
    invoiced: 'Invoiced',
    user_group: 'Client Group',
  },
  advancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'Code', key: 'code' },
    { label: 'Count', key: 'count' },
  ],
}
export default discountsCodesConstants
