const qaConstants = {
  ASSIGN_MANIFEST_REQUEST: 'QA_ASSIGN_MANIFEST_REQUEST',
  ASSIGN_MANIFEST_SUCCESS: 'QA_ASSIGN_MANIFEST_SUCCESS',
  ASSIGN_MANIFEST_FAILURE: 'QA_ASSIGN_MANIFEST_FAILURE',

  SUBMIT_QA_REQUEST: 'QA_SUBMIT_QA_REQUEST',
  SUBMIT_QA_SUCCESS: 'QA_SUBMIT_QA_SUCCESS',
  SUBMIT_QA_FAILURE: 'QA_SUBMIT_QA_FAILURE',

  UPDATE_QA_REQUEST: 'QA_UPDATE_QA_REQUEST',
  UPDATE_QA_SUCCESS: 'QA_UPDATE_QA_SUCCESS',
  UPDATE_QA_FAILURE: 'QA_UPDATE_QA_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  defaultNotesList: [
    'Ink transfer',
    'Writing incomplete',
    'Writing outside the margins',
    'Missing card/envelope',
    'Pen drag',
    'Cards missing images',
    'Machine/Sealer Stamper damage',
    'Missing Signatures (SVGS etc)',
    'Wrap text on addresses',
  ],
}
export default qaConstants
