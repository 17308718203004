import React, { useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import { Alert, Avatar, Badge, Button, Dropdown, Menu, Space } from 'antd'
import styles from './style.module.scss'
import { userActions, queryParamsActions } from '../../../../../_actions'
import Title from 'antd/es/typography/Title.js'
import { useHistory } from 'react-router'

const ProfileMenu = () => {
  const [count] = useState(0)
  const dispatch = useDispatch()

  // useEffect(() => {
  //   dispatch(shopifyActions.getCountOfIssuesShops())
  // }, [])

  const history = useHistory()
  const username = useSelector(state => state.authentication.user.username)
  const { count: issuesCount, shop_ids } = useSelector(state => state.shopify.issues)
  const permis = useSelector(state => state.users.permis)
  const isSuperUser = useMemo(() => {
    return Object.keys(permis).includes('superuser')
  }, [permis])

  const logout = e => {
    e.preventDefault()
    dispatch(userActions.logout())
  }

  const redirectToShopify = () => {
    dispatch(queryParamsActions.setQueryParams({ shop_id: shop_ids.join(',') }))
    history.push('/shopify/issues')
  }

  const titleShops = useMemo(() => {
    return issuesCount === 1 ? 'shop' : 'shops'
  }, [issuesCount])

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className="fe fe-log-out me-2" />
          <FormattedMessage id="topBar.profileMenu.logout" />
        </a>
      </Menu.Item>
    </Menu>
  )
  return (
    <Space direction="horizontal">
      {!!issuesCount && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '1rem',
          }}
        >
          {isSuperUser ? (
            <>
              <Button danger onClick={redirectToShopify}>
                <a>{`${issuesCount} Shopify ${titleShops} have removed webhook. Please resubscribe the shop`}</a>
              </Button>
            </>
          ) : (
            <div style={{ paddingBotton: '.1rem' }}>
              <Alert
                type="warning"
                showIcon
                message={`${issuesCount} Shopify ${titleShops}  have removed webhook. Pleace contact to superuser.`}
              />
            </div>
          )}
        </div>
      )}
      <Dropdown overlay={menu} trigger={['click']}>
        <div className={styles.dropdown}>
          <Badge count={count}>
            <Space style={{ display: 'flex', alignItems: 'baseline' }}>
              <Title level={5}>{username}</Title>
              <Avatar
                shape="square"
                style={{ backgroundColor: '#4b7cf3' }}
                icon={<UserOutlined />}
              />
            </Space>
          </Badge>
        </div>
      </Dropdown>
    </Space>
  )
}

export default ProfileMenu
