const automationsConstants = {
  GETALL_REQUEST: 'AUTOMATIONS_GETALL_REQUEST',
  GETALL_SUCCESS: 'AUTOMATIONS_GETALL_SUCCESS',
  GETALL_FAILURE: 'AUTOMATIONS_GETALL_FAILURE',

  GETBYID_REQUEST: 'AUTOMATIONS_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'AUTOMATIONS_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'AUTOMATIONS_GETBYID_FAILURE',

  GET_ORDER_REQUEST: 'AUTOMATIONS_GET_ORDER_REQUEST',
  GET_ORDER_SUCCESS: 'AUTOMATIONS_GET_ORDER_SUCCESS',
  GET_ORDER_FAILURE: 'AUTOMATIONS_GET_ORDER_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  automationType: [
    { value: 'birthday', text: 'Birthday' },
    { value: 'anniversary', text: 'Anniversary' },
  ],

  automationDeliveryType: [
    { value: 0, text: 'NO' },
    { value: 1, text: 'YES' },
  ],

  automationStatus: [
    { value: 'upcoming', text: 'Upcoming' },
    { value: 'in_progress', text: 'In Progress' },
    { value: 'done', text: 'Done' },
  ],

  navMenu: {
    manage: { key: 'manage', title: 'Manage Automations', url: '/automations' },
    view: { key: 'view', title: 'View Automation', url: '' },
    manageOrders: { key: 'manage-orders', title: 'Manage Automation Orders', url: '' },
    manageRecipients: { key: 'manage-recipients', title: 'Manage Automation Recipients', url: '' },
  },

  viewItemKeyToLabel: {
    id: 'ID',
    type: 'Type',
    name: 'Name',
    user: 'User',
    message: 'Message',
    wishes: 'Wishes',
    start_date: 'Start Date',
    end_date: 'End Date',
    total_address: 'Total Addresses',
    total_orders: 'Total Orders',
    delivery_confirmation: 'Delivery Confirmation',
    status: 'Status',
  },
}

export default automationsConstants
