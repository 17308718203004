const templateCategoriesConstants = {
  GETALL_REQUEST: 'TEMPLATE_CATEGORIES_CONSTANTS_GETALL_REQUEST',
  GETALL_SUCCESS: 'TEMPLATE_CATEGORIES_CONSTANTS_GETALL_SUCCESS',
  GETALL_FAILURE: 'TEMPLATE_CATEGORIES_CONSTANTS_GETALL_FAILURE',

  GETBYID_REQUEST: 'TEMPLATE_CATEGORIES_CONSTANTS_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'TEMPLATE_CATEGORIES_CONSTANTS_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'TEMPLATE_CATEGORIES_CONSTANTS_GETBYID_FAILURE',

  ADD_REQUEST: 'TEMPLATE_CATEGORIES_CONSTANTS_ADD_REQUEST',
  ADD_SUCCESS: 'TEMPLATE_CATEGORIES_CONSTANTS_ADD_SUCCESS',
  ADD_FAILURE: 'TEMPLATE_CATEGORIES_CONSTANTS_ADD_FAILURE',

  DELETE_REQUEST: 'TEMPLATE_CATEGORIES_CONSTANTS_DELETE_REQUEST',
  DELETE_SUCCESS: 'TEMPLATE_CATEGORIES_CONSTANTS_DELETE_SUCCESS',
  DELETE_FAILURE: 'TEMPLATE_CATEGORIES_CONSTANTS_DELETE_FAILURE',

  UPDATE_REQUEST: 'TEMPLATE_CATEGORIES_CONSTANTS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'TEMPLATE_CATEGORIES_CONSTANTS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'TEMPLATE_CATEGORIES_CONSTANTS_UPDATE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    list: { key: 'list', title: 'List Template Category', url: '/template-categories/list' },
    create: {
      key: 'create',
      title: 'Create Template Category',
      url: '/template-categories/create',
    },
    manage: { key: 'manage', title: 'Manage Template Category', url: '/template-categories' },
    view: { key: 'view', title: 'View Template Category', url: '' },
    update: { key: 'edit', title: 'Update Template Category', url: '' },
    delete: { key: 'delete', title: 'Delete Template Category', url: '' },
  },

  listItemKeyToLabel: {
    id: 'ID',
    name: 'Name',
  },

  viewItemKeyToLabel: {
    id: 'ID',
    name: 'Name',
  },

  advancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'Name', key: 'name' },
  ],

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },
}
export default templateCategoriesConstants
