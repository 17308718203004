import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import { ChangePassword, EditItem, ListItems, ManageItems } from './views'

import Content from '../../components/content'

const Clients = () => {
  const match = useRouteMatch()
  return (
    <Content>
      <Switch>
        <Route exact path={`${match.url}`} component={ListItems} />
        <Route path={`${match.url}/profile`} component={ManageItems} />
        <Route path={`${match.url}/edit`} component={EditItem} />
        <Route path={`${match.url}/changepassword`} component={ChangePassword} />

        <Redirect to={match.url} />
      </Switch>
    </Content>
  )
}

export default Clients
