import axios from 'axios'
import serialize from '_helpers/hw-serialize-params'
import { v1 } from './api'
import apiUrl from './service'

const getAll = async (params, typeAction) => {
  const result = await axios.get(`${apiUrl}/${v1}/discount_rules?${serialize(params)}`, {
    params: { stopSpinner: typeAction },
  })
  return handleResponse(result)
}

const addItem = async ({ value }) => {
  const result = await axios.post(`${apiUrl}/${v1}/discount_rules`, JSON.stringify(value))
  return handleResponse(result)
}

const getItemById = async id => {
  const result = await axios.get(`${apiUrl}/${v1}/discount_rules/${id}`)
  return handleResponse(result)
}

const deleteItem = async value => {
  const result = await axios.delete(`${apiUrl}/${v1}/discount_rules/${value}`, {
    params: { stopSpinner: 'paginate' },
  })
  return handleResponse(result)
}

const updateItemById = async (id, value) => {
  const result = await axios.put(
    `${apiUrl}/${v1}/discount_rules/${id}`,
    JSON.stringify({ changes: value }),
  )
  return handleResponse(result)
}

const handleResponse = result => {
  return result.data
}

const discountsRulesService = {
  getAll,
  getItemById,
  deleteItem,
  updateItemById,
  addItem,
}

export default discountsRulesService
