const contextsConstants = {
  GETALL_REQUEST: 'CONTEXTS_CONSTANTS_GETALL_REQUEST',
  GETALL_SUCCESS: 'CONTEXTS_CONSTANTS_GETALL_SUCCESS',
  GETALL_FAILURE: 'CONTEXTS_CONSTANTS_GETALL_FAILURE',

  GETBYID_REQUEST: 'CONTEXTS_CONSTANTS_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'CONTEXTS_CONSTANTS_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'CONTEXTS_CONSTANTS_GETBYID_FAILURE',

  ADD_REQUEST: 'CONTEXTS_CONSTANTS_ADD_REQUEST',
  ADD_SUCCESS: 'CONTEXTS_CONSTANTS_ADD_SUCCESS',
  ADD_FAILURE: 'CONTEXTS_CONSTANTS_ADD_FAILURE',

  DELETE_REQUEST: 'CONTEXTS_CONSTANTS_DELETE_REQUEST',
  DELETE_SUCCESS: 'CONTEXTS_CONSTANTS_DELETE_SUCCESS',
  DELETE_FAILURE: 'CONTEXTS_CONSTANTS_DELETE_FAILURE',

  UPDATE_REQUEST: 'CONTEXTS_CONSTANTS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CONTEXTS_CONSTANTS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CONTEXTS_CONSTANTS_UPDATE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  checkbox: {
    style: { lineHeight: '32px' },
    span: 10,
  },

  navMenu: {
    create: { key: 'create', title: 'Create Context', url: '/contexts/create' },
    manage: { key: 'manage', title: 'Manage Contexts', url: '/contexts' },
    // listUser: { key: 'listUser', title: 'List Employees', url: '/employees/list' },
    view: { key: 'view', title: 'View Employee', url: '' },
    // activity: { key: 'activity', title: 'List Activity', url: '' },
    // password: { key: 'password', title: 'Reset Password', url: '' },
    edit: { key: 'edit', title: 'Update Context', url: '' },
    delete: { key: 'delete', title: 'Delete Context', url: '' },
  },

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },

  viewItemKeyToLabel: {
    id: 'ID',
    description: 'Description',
  },

  // userStatuses: [
  //   { value: '-1', text: 'Banned' },
  //   { value: '0', text: 'Not Active' },
  //   { value: '1', text: 'Active' },
  // ],

  // listItemKeyToLabel: {
  //   username: 'Username',
  //   create_at: 'Registration date',
  //   lastvisit_at: 'Last visit',
  // },

  // advancedSearch: [
  //   { label: 'ID', key: 'id' },
  //   { label: 'username', key: 'username' },
  //   { label: 'E-mail', key: 'email' },
  //   { label: 'Activation Key', key: 'activkey' },
  //   { label: 'Registration date', key: 'create_at*date' },
  //   { label: 'Last visit', key: 'lastvisit_at*date' },
  //   { label: 'Superuser', key: 'superuser_filter', type: 'select' },
  //   { label: 'Status', key: 'status_filter', type: 'select' },
  // ],

  // actionsFindLink: {
  //   post: false,
  //   auth: false,
  //   login: false,
  //   logout: false,
  //   cardOrder: false,
  //   card: 'cards',
  //   order: 'orders',
  //   client: 'clients',
  //   admin: 'employees',
  //   employee: 'employees',
  //   gcard: 'gift-cards',
  //   giftCard: 'gift-cards',
  //   template: 'templates',
  //   cardImage: 'card-images',
  //   card_images: 'card-images',
  //   templateCategory: 'template-categories',
  //   template_category: 'template-categories',
  // },
}
export default contextsConstants
