import React from 'react'
import { cronJobConstants } from '_constants'

import './style.scss'

const { statusesList } = cronJobConstants

const StatusBar = ({ counts }) => {
  return (
    <div className="status-bar">
      {Object.entries(counts).map(([status, count]) => {
        const { color } = statusesList.find(st => st.title === status)

        if (!count) return null

        return (
          <div
            className="status-bar__status"
            style={{
              backgroundColor: color,
              flex: count,
            }}
          >
            {count}
          </div>
        )
      })}
    </div>
  )
}

export default StatusBar
