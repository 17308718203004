import React, { useEffect, useMemo } from 'react'
import { Space, Tabs } from 'antd'

import ReadyToShippingList from 'components/labels-creation/ready-to-shipping.list'
import ArchivedLabelsList from 'components/labels-creation/archived-labels.list'
import { settingsConstants } from '_constants'
import { useDispatch } from 'react-redux'
import { history } from '_helpers'

const LabelsCreation = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    }
  }, [])

  const isCompletedOrdersTab = useMemo(() => window.location.href.includes('completed'), [window])

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <h3 style={{ marginRight: 10 }}>UPS/USPS</h3>
      <Tabs
        defaultActiveKey={isCompletedOrdersTab ? '2' : '1'}
        type="card"
        items={[
          {
            key: '1',
            label: 'Remaining Orders',
            children: <ReadyToShippingList />,
          },
          {
            key: '2',
            label: 'Completed Orders',
            children: <ArchivedLabelsList />,
            destroyInactiveTabPane: true,
          },
        ]}
        onTabClick={tab => {
          dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
          if (tab === '1') {
            return history.push(`/labels-creation`)
          }
          history.push(`/labels-creation?tab=completed`)
        }}
      />
    </Space>
  )
}

export default LabelsCreation
