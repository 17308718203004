const prePaidConfigsConstants = {
  GETALL_REQUEST: 'PREPAID_CONFIGS_GETALL_REQUEST',
  GETALL_SUCCESS: 'PREPAID_CONFIGS_SUCCESS',
  GETALL_FAILURE: 'PREPAID_CONFIGS_FAILURE',

  ADD_REQUEST: 'PREPAID_CONFIGS_ADD_REQUEST',
  ADD_SUCCESS: 'PREPAID_CONFIGS_ADD_SUCCESS',
  ADD_FAILURE: 'PREPAID_CONFIGS_ADD_FAILURE',

  GETBYID_REQUEST: 'PREPAID_CONFIGS_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'PREPAID_CONFIGS_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'PREPAID_CONFIGS_GETBYID_FAILURE',

  DELETE_REQUEST: 'PREPAID_CONFIGS_DELETE_REQUEST',
  DELETE_SUCCESS: 'PREPAID_CONFIGS_DELETE_SUCCESS',
  DELETE_FAILURE: 'PREPAID_CONFIGS_DELETE_FAILURE',

  UPDATE_REQUEST: 'PREPAID_CONFIGS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'PREPAID_CONFIGS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'PREPAID_CONFIGS_UPDATE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    list: {
      key: 'list',
      title: 'List Pre-paid Configs',
      url: '/pre-paid-configs/list',
    },
    manage: {
      key: 'manage',
      title: 'Manage Pre-paid Configs',
      url: '/pre-paid-configs',
    },
    create: {
      key: 'create',
      title: 'Create Pre-paid Config',
      url: '/pre-paid-configs/create',
    },
    edit: { key: 'edit', title: 'Update Pre-paid Config', url: '' },
    delete: { key: 'delete', title: 'Delete Pre-paid Config', url: '' },
    view: { key: 'view', title: 'View Pre-paid Config', url: '' },
  },

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },

  listItemKeyToLabel: {
    id: 'ID',
    quantity_from: 'Cards From',
    quantity_to: 'Cards To',
    discount: 'Discount',
    price_per_card: 'Price Per Card',
    postage: 'Postage',
    status: 'Status',
  },

  viewItemKeyToLabel: {
    id: 'ID',
    quantity_from: 'Cards From',
    quantity_to: 'Cards To',
    discount: 'Discount',
    price_per_card: 'Price Per Card',
    postage: 'Postage',
    status: 'Status',
  },

  statuses: [
    { value: 1, text: 'Active' },
    { value: 0, text: 'Not Active' },
  ],
}
export default prePaidConfigsConstants
