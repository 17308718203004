/**
 * Convert values to save in db - booleans is saved as 0|1, undefined = null
 * @param formValues
 */
import { isMoment } from 'moment'

const convertFormValuesDeep = formValues => {
  if (formValues === null) return formValues

  if (typeof formValues === 'undefined') return null

  const formValuesKeys = Object.keys(formValues)
  if (formValuesKeys.length === 0) {
    return formValues
  }
  const res = {}
  formValuesKeys.forEach(key => {
    if (formValues[key] && typeof formValues[key] === 'object' && !isMoment(formValues[key])) {
      if (Array.isArray(formValues[key])) {
        res[key] = formValues[key].map(arrVal => convertFormValuesDeep(arrVal))
      } else if (typeof formValues[key] === 'boolean') {
        res[key] = formValues[key] ? 1 : 0
      } else if (typeof formValues[key] === 'number') {
        res[key] = formValues[key]
      } else {
        res[key] = convertFormValuesDeep(formValues[key])
      }
    } else if (typeof formValues[key] === 'undefined') {
      res[key] = null
    } else if (typeof formValues[key] === 'boolean') {
      res[key] = formValues[key] ? 1 : 0
    } else {
      res[key] = formValues[key]
    }
  })
  return res
}

export const convertValuesToFormData = formValues => {
  const formData = new FormData()

  if (formValues === null || typeof formValues === 'undefined') return formData

  const formValuesKeys = Object.keys(formValues)
  if (formValuesKeys.length === 0) {
    return formValues
  }

  formValuesKeys.forEach(key => {
    if (Array.isArray(formValues[key])) {
      if (formValues[key][0]?.originFileObj) {
        formData.append(key, formValues[key][0].originFileObj)
      } else if (formValues[key].length === 0) {
        formData.append(key, [])
      } else {
        formValues[key].forEach(val => formData.append(key, val))
      }
    } else if (typeof formValues[key] === 'undefined') {
      formData.append(key, null)
    } else if (typeof formValues[key] === 'boolean') {
      formData.append(key, formValues[key] ? 1 : 0)
    } else {
      formData.append(key, formValues[key])
    }
  })

  return formData
}

export default convertFormValuesDeep
