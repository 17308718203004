import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { Button, Form, Input, Select } from 'antd'
import NavContainer from '../../../../components/nav-container'
import { taxCodesActions } from '../../../../_actions'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { taxCodesConstants } from '../../../../_constants'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'

const { formItemLayout, navMenu, taxCodesServices, taxCodesItems } = taxCodesConstants

const navMenuInit = [navMenu.list, navMenu.view, navMenu.manage]

const EditItem = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)
  const { id } = useParams()

  const [navMenuParse, setNavMenuParse] = useState([])
  const [changed, setChange] = useState(false)
  const {
    item: { taxCode },
  } = useSelector(state => state.taxcodes)

  useEffect(() => {
    dispatch(taxCodesActions.getItemById(id))
    return () => {
      dispatch({ type: taxCodesConstants.SETBYID })
    }
  }, [])

  useEffect(() => {
    if (taxCode) {
      form.setFieldsValue({ ...taxCode })
    }
  }, [taxCode, form])

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToView,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const serviceOptions = useMemo(
    () => taxCodesServices.map(status => ({ value: status.value, label: status.text })),
    [taxCodesServices],
  )

  const taxItemOptions = useMemo(
    () => taxCodesItems.map(status => ({ value: status.value, label: status.text })),
    [taxCodesItems],
  )

  // //////////////////
  // Methods
  // /////////////////

  const navigateToView = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  // //////////////////
  // Form handlers
  // /////////////////

  const onFinish = async values => {
    const mergedCode = {
      ...values,
      id: taxCode.id,
    }
    const convertedCode = convertFormValuesDeep(mergedCode)
    await dispatch(taxCodesActions.updateItemById(taxCode.id, convertedCode))
  }

  return (
    <NavContainer menu={navMenuParse}>
      <div>
        <p>Fields with * are required.</p>
        {useGlobalLoader(
          <Form
            {...formItemLayout}
            name="editClient"
            form={form}
            onFinish={onFinish}
            onFieldsChange={() => setChange(true)}
            scrollToFirstError
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input name!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Service"
              name="service"
              rules={[
                {
                  required: true,
                  message: 'Please select service!',
                },
              ]}
            >
              <Select options={serviceOptions} />
            </Form.Item>

            <Form.Item
              label="Tax Code"
              name="tax_code"
              rules={[
                {
                  required: true,
                  message: 'Please input code!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Tax Item"
              name="tax_item"
              rules={[
                {
                  required: true,
                  message: 'Please select tax item!',
                },
              ]}
            >
              <Select options={taxItemOptions} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!changed}>
                Save
              </Button>
            </Form.Item>
          </Form>,
        )}
      </div>
    </NavContainer>
  )
}

export default EditItem
