/* eslint-disable prefer-destructuring */
// function from old php for calculate height of sign

function measureHeight(aFont, aSize, aChars, aOptions = {}) {
  aOptions.range = aOptions.range || 3 // multiples the aSize by this much

  if (aChars === '') {
    // no characters, so obviously everything is 0
    return {
      relativeBot: 0,
      relativeTop: 0,
      height: 0,
      width: 0,
    }
    // otherwise i will get IndexSizeError: Index or size is negative or greater than the allowed amount error somewhere below
  }

  // validateOptionsObj(aOptions, defaultOptions); // not needed because all defaults are undefined

  let can
  let ctx
  if (!aOptions.canAndCtx) {
    can = document.createElement('canvas')
    can.mozOpaque = 'true' // improved performanceo on firefox i guess
    ctx = can.getContext('2d')
  } else {
    can = aOptions.canAndCtx.can
    ctx = aOptions.canAndCtx.ctx
  }

  let w = aOptions.width
  if (!w) {
    ctx.textBaseline = 'alphabetic'
    ctx.textAlign = 'left'
    ctx.font = aFont
    w = ctx.measureText(aChars).width
  }

  w = Math.ceil(w) // needed as i use w in the calc for the loop, it needs to be a whole number

  // must set width/height, as it wont paint outside of the bounds
  can.width = w
  can.height = aSize * aOptions.range

  ctx.font = aFont // need to set the .font again, because after changing width/height it makes it forget for some reason
  ctx.textBaseline = 'alphabetic'
  ctx.textAlign = 'left'
  ctx.fillStyle = '#cc0000'

  const avgOfRange = (aOptions.range + 1) / 2
  const yBaseline = Math.ceil(aSize * avgOfRange)

  ctx.fillText(aChars, 0, yBaseline)

  const yEnd = aSize * aOptions.range

  const { data } = ctx.getImageData(0, 0, w, yEnd)

  let botBound = -1
  let topBound = -1

  // measureHeightY:
  for (let y = 0; y <= yEnd; y++) {
    for (let x = 0; x < w; x += 1) {
      const n = 4 * (w * y + x)
      const r = data[n]
      const g = data[n + 1]
      const b = data[n + 2]
      // let a = data[n + 3];

      if (r + g + b > 0) {
        // non black px found
        if (topBound === -1) {
          topBound = y
        }
        botBound = y // break measureHeightY; // dont break measureHeightY ever, keep going, we till yEnd. so we get proper height for strings like "`." or ":" or "!"
        break
      }
    }
  }

  return {
    relativeBot: botBound - yBaseline, // relative to baseline of 0 // bottom most row having non-black
    relativeTop: topBound - yBaseline, // relative to baseline of 0 // top most row having non-black
    height: botBound - topBound + 1,
    width: w, // EDIT: comma has been added to fix old broken code.
  }
}

export default measureHeight
