import axios from 'axios'
import serialize from '_helpers/hw-serialize-params'
import apiUrl from './service'
import { v1 } from './api'

const getAll = async (params, typeAction) => {
  const result = await axios.get(`${apiUrl}/${v1}/prepaid_credits?${serialize(params)}`, {
    params: { stopSpinner: typeAction },
  })
  return handleResponse(result)
}

const getItemById = async id => {
  const result = await axios.get(`${apiUrl}/${v1}/prepaid_credits/${id}`)
  return handleResponse(result)
}

const addItem = async ({ value }) => {
  const result = await axios.post(`${apiUrl}/${v1}/prepaid_credits`, JSON.stringify(value))
  return handleResponse(result)
}

const updateItemById = async (id, value) => {
  const result = await axios.put(`${apiUrl}/${v1}/prepaid_credits/${id}`, JSON.stringify(value))
  return handleResponse(result)
}

const handleResponse = result => {
  return result.data
}

const prepaidCreditsType3Service = {
  getAll,
  getItemById,
  updateItemById,
  addItem,
}

export default prepaidCreditsType3Service
