import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Button, Form, InputNumber, Modal, Select, Input, Divider, Tooltip, Space } from 'antd'
import { ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import NavContainer from '../../../../components/nav-container'
import { cardDimensionActions } from '../../../../_actions'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { convertDataForSelect } from '../../../../_helpers'
import { dimensionCardConstants } from '../../../../_constants'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import Section from 'components/section'

const {
  formItemLayout,
  navMenu,
  cardStatuses,
  cardOrientation,
  cardCanCustomize,
  marginsTooltipMessage,
} = dimensionCardConstants

const navMenuInit = [navMenu.create, navMenu.view, navMenu.delete, navMenu.manage]

const EditItem = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const match = useRouteMatch()
  const { id } = useParams()
  const [form] = Form.useForm()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])
  const [changed, setChange] = useState(false)

  const {
    item: { cardDimension: card },
  } = useSelector(state => state.dimensionCard)

  const charactersWidth = Form.useWatch('character_width', form)
  const charactersLines = Form.useWatch('character_height', form)

  useEffect(() => {
    dispatch(cardDimensionActions.getItemById(id))
    return () => {
      dispatch({ type: dimensionCardConstants.SETBYID })
    }
  }, [id, dispatch])

  useEffect(() => {
    const count = charactersWidth * charactersLines

    form.setFieldValue('character_count', count || 0)
  }, [charactersWidth, charactersLines])

  const deleteItems = async () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item?',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(cardDimensionActions.deleteItem(id))
        history.push({
          pathname: `${view}`,
        })
      },
    })
  }

  const navigateToView = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToView,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: deleteItems,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit, card])

  useEffect(() => {
    if (card) {
      form.setFieldsValue({
        ...card,
      })
    }
  }, [form, card])

  const onFinish = async values => {
    const { character_height, character_width, ...payload } = values

    await dispatch(cardDimensionActions.updateItemById(id, payload))
  }

  return (
    <NavContainer menu={navMenuParse}>
      {useGlobalLoader(
        <div>
          <p>Fields with * are required.</p>
          <Form
            {...formItemLayout}
            initialValues={{ status: 1, orientation: 'L', can_customize: 0 }}
            name="basic"
            form={form}
            onFinish={onFinish}
            onFieldsChange={() => setChange(true)}
            scrollToFirstError
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input name!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item name="status" label="Status">
              <Select options={convertDataForSelect(cardStatuses)} />
            </Form.Item>

            <Form.Item name="orientation" label="Orientation">
              <Select options={convertDataForSelect(cardOrientation)} />
            </Form.Item>

            <Form.Item
              label="Open Width"
              name="open_width"
              rules={[
                {
                  required: true,
                  message: 'Please fill the field!',
                },
              ]}
            >
              <InputNumber step={0.005} />
            </Form.Item>

            <Form.Item
              label="Open Height"
              name="open_height"
              rules={[
                {
                  required: true,
                  message: 'Please fill the field!',
                },
              ]}
            >
              <InputNumber step={0.005} />
            </Form.Item>

            <Form.Item
              label="Character Count"
              name="character_count"
              rules={[
                {
                  required: true,
                  message: 'Please fill the Character Count!',
                },
                () => ({
                  validator(_, value) {
                    if (value < 1)
                      return Promise.reject(new Error('Characters counter cannot be a 0!'))
                    return Promise.resolve()
                  },
                }),
              ]}
              style={{
                marginBottom: 0,
              }}
            >
              <Space style={{ alignItems: 'center' }}>
                <Form.Item name="character_width" style={{ display: 'inline-block', margin: 0 }}>
                  <InputNumber
                    placeholder="Characters width"
                    style={{ width: '100%' }}
                    min={0}
                    step={1}
                  />
                </Form.Item>
                X
                <Form.Item name="character_height" style={{ display: 'inline-block', margin: 0 }}>
                  <InputNumber
                    placeholder="Characters lines"
                    style={{ width: '100%' }}
                    min={0}
                    step={1}
                  />
                </Form.Item>
                =
                <Form.Item name="character_count" style={{ display: 'inline-block', margin: 0 }}>
                  <InputNumber disabled style={{ width: '100%' }} />
                </Form.Item>
              </Space>
            </Form.Item>

            <Section title="Custom Card Properties">
              <Form.Item name="can_customize" label="Can Customize">
                <Select options={convertDataForSelect(cardCanCustomize)} />
              </Form.Item>
              <Form.Item
                name="price"
                label="Price"
                initialValue={0}
                rules={[
                  {
                    required: true,
                    message: 'Please input value!',
                  },
                ]}
              >
                <InputNumber min={0} />
              </Form.Item>

              <Form.Item label="SKU" name="sku">
                <Input maxLength={255} />
              </Form.Item>

              <Form.Item label="Description" name="description">
                <Input />
              </Form.Item>

              <Divider orientation="left">
                <span style={{ marginRight: '0.5rem' }}>Margins</span>
              </Divider>
              <Form.Item
                label="Margin Top"
                name="margin_top"
                rules={[
                  {
                    required: true,
                    message: 'Please fill the field!',
                  },
                ]}
              >
                <InputNumber step={0.005} />
              </Form.Item>
              <Form.Item
                label="Margin Right"
                name="margin_right"
                rules={[
                  {
                    required: true,
                    message: 'Please fill the field!',
                  },
                ]}
              >
                <InputNumber step={0.005} />
              </Form.Item>
              <Form.Item
                label="Margin Bottom"
                name="margin_bottom"
                rules={[
                  {
                    required: true,
                    message: 'Please fill the field!',
                  },
                ]}
              >
                <InputNumber step={0.005} />
              </Form.Item>
              <Form.Item
                label="Margin Left"
                name="margin_left"
                rules={[
                  {
                    required: true,
                    message: 'Please fill the field!',
                  },
                ]}
              >
                <InputNumber step={0.005} />
              </Form.Item>
            </Section>

            <Divider orientation="left">Envelope</Divider>

            <Form.Item
              label="Envelope Height"
              name="envelope_height"
              rules={[
                {
                  required: true,
                  message: 'Please fill the field!',
                },
              ]}
            >
              <InputNumber step={0.005} />
            </Form.Item>
            <Form.Item
              label="Envelope Width"
              name="envelope_width"
              rules={[
                {
                  required: true,
                  message: 'Please fill the field!',
                },
              ]}
            >
              <InputNumber step={0.005} />
            </Form.Item>
            <Form.Item
              label="Envelope Font Size"
              name="envelope_font_size"
              rules={[
                {
                  required: true,
                  message: 'Please fill the field!',
                },
              ]}
            >
              <InputNumber step={1} min={0} />
            </Form.Item>
            <Form.Item
              label="Envelope Lines Between Addresses"
              name="envelope_lines_between_addresses"
              rules={[
                {
                  required: true,
                  message: 'Please fill the field!',
                },
              ]}
            >
              <InputNumber step={1} min={0} />
            </Form.Item>
            <Form.Item
              label="Envelope To Tabs"
              name="envelope_to_tabs"
              rules={[
                {
                  required: true,
                  message: 'Please fill the field!',
                },
              ]}
            >
              <InputNumber step={1} min={0} />
            </Form.Item>
            <Form.Item
              label="Envelope Margin Top"
              name="envelope_margin_top"
              rules={[
                {
                  required: true,
                  message: 'Please fill the field!',
                },
              ]}
            >
              <InputNumber step={0.005} />
            </Form.Item>
            <Form.Item
              label="Envelope Margin Right"
              name="envelope_margin_right"
              rules={[
                {
                  required: true,
                  message: 'Please fill the field!',
                },
              ]}
            >
              <InputNumber step={0.005} />
            </Form.Item>
            <Form.Item
              label="Envelope Margin Bottom"
              name="envelope_margin_bottom"
              rules={[
                {
                  required: true,
                  message: 'Please fill the field!',
                },
              ]}
            >
              <InputNumber step={0.005} />
            </Form.Item>
            <Form.Item
              label="Envelope Margin Left"
              name="envelope_margin_left"
              rules={[
                {
                  required: true,
                  message: 'Please fill the field!',
                },
              ]}
            >
              <InputNumber step={0.005} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!changed}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>,
      )}
    </NavContainer>
  )
}

export default EditItem
