import axios from 'axios'
import serialize from '_helpers/hw-serialize-params'
import downloader from '_helpers/file-download'
import apiUrl from './service'
import { v1 } from './api'

const getAll = async (params, typeAction) => {
  const result = await axios.get(`${apiUrl}/${v1}/orders?${serialize(params)}`, {
    params: { stopSpinner: typeAction },
  })
  return handleResponse(result)
}

const getItemById = async id => {
  const result = await axios.get(`${apiUrl}/${v1}/orders/${id}`)
  return handleResponse(result)
}

const deleteItem = async value => {
  const result = await axios.delete(`${apiUrl}/${v1}/orders/${value}`, {
    params: { stopSpinner: 'paginate' },
  })
  return handleResponse(result)
}

const createRedo = async id => {
  const result = await axios.post(`${apiUrl}/${v1}/orders/${id}/redo`, {
    params: { stopSpinner: 'paginate' },
  })
  return handleResponse(result)
}

const updateItemById = async (id, value, isAssign) => {
  const result = await axios.put(
    `${apiUrl}/${v1}/orders/${id}`,
    JSON.stringify({ changes: value }),
    {
      params: { stopSpinner: isAssign },
    },
  )
  return handleResponse(result)
}

const downloadDocxNote = async orderId => {
  const result = await axios.get(`${apiUrl}/${v1}/orders/${orderId}/gendocx/note`, {
    responseType: 'blob',
  })
  downloader(result.data, `o-${orderId}-note.docx`)
}

const downloadDocxEnvelope = async orderId => {
  const result = await axios.get(`${apiUrl}/${v1}/orders/${orderId}/gendocx/envelope`, {
    responseType: 'blob',
  })
  downloader(result.data, `o-${orderId}-envelope.docx`)
}

const handleResponse = result => {
  return result.data
}

const ordersService = {
  getAll,
  getItemById,
  deleteItem,
  updateItemById,
  downloadDocxNote,
  downloadDocxEnvelope,
  createRedo,
}

export default ordersService
