import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { Button, Divider, Form, Input, InputNumber, Modal, Select, Tooltip } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { merge } from 'lodash'
import NavContainer from '../../../../components/nav-container'
import getCurrentUrl from '../../../../_helpers/url-utils'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import { insertsActions } from '../../../../_actions'
import { insertsConstants } from '../../../../_constants'
import { convertDataForSelect } from '../../../../_helpers'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import useValidationEmailForNotification from '../../../../hooks/clients/useValidationEmailForNotification.js'
import useClientsToOptionsList from 'hooks/clients/useClientsToOptionsList'
const { formItemLayout, navMenu, insertStatus, insertable } = insertsConstants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.view, navMenu.delete, navMenu.manage]

const EditItem = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const match = useRouteMatch()
  const [form] = Form.useForm()
  const view = getCurrentUrl(match.url)
  const { id } = useParams()

  const [navMenuParse, setNavMenuParse] = useState([])
  const [changed, setChange] = useState(false)

  const {
    item: { insert },
  } = useSelector(state => state.inserts)

  const { emailWarning, setEmailWarning, validateEmailList } = useValidationEmailForNotification()

  const clientsListOptions = useClientsToOptionsList()

  const navigateToView = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(insertsActions.deleteItem(id))
      },
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.list.key:
          return {
            ...menu,
            url: `${view}/list/`,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToView,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  useEffect(() => {
    dispatch(insertsActions.getItemById(id))
    return () => {
      dispatch({ type: insertsConstants.SETBYID })
    }
  }, [id])

  useEffect(() => {
    form.setFieldsValue({
      ...insert,
    })
  }, [insert, form])

  const onFinish = async values => {
    const updateItem = {}
    Object.keys(values).forEach(val => {
      if (val !== 'newValue' && val !== 'quantity') updateItem[val] = values[val]
      if (val === 'quantity') updateItem.quantity = values.newValue ?? values.quantity
    })
    const mInsert = merge(insert, updateItem)
    const convertedInsert = convertFormValuesDeep(mInsert)

    await dispatch(insertsActions.updateItemById(insert.id, convertedInsert))
  }

  return (
    <NavContainer menu={navMenuParse}>
      {useGlobalLoader(
        <div>
          <p>Fields with * are required.</p>
          <Form
            {...formItemLayout}
            name="editClientGroup"
            form={form}
            onFinish={onFinish}
            onFieldsChange={() => setChange(true)}
            scrollToFirstError
          >
            <Form.Item name="status" label="Status">
              <Select options={convertDataForSelect(insertStatus)} />
            </Form.Item>
            <Form.Item label="User" name="user_id">
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Select or search a user"
                optionFilterProp="children"
                filterOption={(input, option) => option.label.includes(input.toString())}
                options={clientsListOptions}
              />
            </Form.Item>

            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Name cannot be blank!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="insertable"
              label="Insertable"
              rules={[
                {
                  required: true,
                  message: 'Please select option!',
                },
              ]}
            >
              <Select options={convertDataForSelect(insertable)} />
            </Form.Item>

            <Form.Item
              label="Price"
              name="price"
              rules={[
                {
                  required: true,
                  message: 'Price cannot be blank!',
                },
              ]}
            >
              <InputNumber step={0.01} min={0.0} />
            </Form.Item>

            <Form.Item label="Quantity" name="quantity">
              <span className="ant-form-text">{insert?.quantity}</span>
            </Form.Item>

            <Form.Item label="Set new value" name="newValue">
              <InputNumber step={1} min={0} />
            </Form.Item>

            <Divider orientation="left">Low Stock Notifications</Divider>

            <Form.Item label="Low Stock Threshold" name="low_stock_threshold">
              <InputNumber step={1} min={0} />
            </Form.Item>

            <Form.Item label="Client Notification Emails">
              <Tooltip title="Wrong format. Check your value." open={emailWarning}>
                <Form.Item name="client_notification_emails" noStyle>
                  <Input.TextArea
                    rows={5}
                    onFocus={() => setEmailWarning(false)}
                    onBlur={validateEmailList}
                    style={emailWarning ? { border: '1px solid #E9D502' } : {}}
                  />
                </Form.Item>
              </Tooltip>
              <p style={{ fontSize: '13px' }}>
                * You can separate emails by writing them on a new line, use space or one of the
                special symbols like ";" or ","
              </p>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!changed}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>,
      )}
    </NavContainer>
  )
}

export default EditItem
