import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
// import { Link } from 'react-router-dom'
// import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { Button, Form, Input, Layout, Space } from 'antd'
import { userActions } from '_actions'
import Footer from '../../components/cleanui/layout/Footer'

const { Content } = Layout

const LoginPage = () => {
  const [form] = Form.useForm()
  const history = useHistory()
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const isQaLoginPage = useMemo(() => pathname.includes('completion'), [pathname])

  //     // reset login status
  //     logout()

  const moveToRegister = () => {
    history.push('/register')
  }

  const moveToResetPassword = () => {
    history.push('/reset')
  }

  const onFinish = ({ email, password }) => {
    const error = []

    if (password.length < 4) {
      error.push({ name: 'password', errors: ['Password is too short (minimum is 4 characters)'] })
    }
    if (error.length) return form.setFields(error)
    dispatch(userActions.login({ email, password }, isQaLoginPage))
    return form.setFields(error)
  }

  return (
    <Layout>
      <Content>
        <div className="container col-m-6 col-md-offset-3 container-login-form">
          <h2 className="text-center my-lg-2 my-sm-2 my-md-2">Login</h2>
          <Form
            name="editClient"
            form={form}
            onFinish={onFinish}
            layout="vertical"
            scrollToFirstError
          >
            <Form.Item
              label="Username or Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'username or email cannot be blank',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input password!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              {!isQaLoginPage ? (
                <Space>
                  <Button type="primary" htmlType="submit">
                    LogIn
                  </Button>
                  <Button onClick={moveToRegister} style={{ background: '#23bc23', color: '#fff' }}>
                    Register
                  </Button>
                  <Button type="link" onClick={moveToResetPassword}>
                    Forgot password
                  </Button>
                </Space>
              ) : (
                <Button type="primary" htmlType="submit" block>
                  LogIn
                </Button>
              )}
            </Form.Item>
          </Form>
        </div>
      </Content>
      <Footer />
    </Layout>
  )
}

export default LoginPage
