import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, Input, InputNumber, Select } from 'antd'
import html2canvas from 'html2canvas'
import { measureHeight } from '_helpers'
import NavContainer from '../../../../components/nav-container'
import { signatureConstants, spinnerConstants } from '../../../../_constants'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import { signatureActions } from '../../../../_actions'
import useClientsToOptionsList from 'hooks/clients/useClientsToOptionsList'

const { formItemLayout, navMenu } = signatureConstants

const navMenuInit = [navMenu.manage, navMenu.upload]

const CreateItem = () => {
  const [isLoadSignatureFont, setLoadSignatureFont] = useState(false)
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const ref = useRef()

  const clientsListOptions = useClientsToOptionsList()

  useEffect(() => {
    setLoadSignatureFont(true)
  }, [])

  const [signaturePreview, setSignaturePreview] = useState('')

  const user_id = useSelector(state => state.queryParams?.where?.user_id)

  const croppedSignImage = async (signaturePreview, ref) => {
    const res = await html2canvas(ref.current).then(canvas => {
      const font = '166px HWSign'
      const croppedCanvas = document.createElement('canvas')
      const ctx = croppedCanvas.getContext('2d')
      const ctxCanvas = canvas.getContext('2d')
      const measure = measureHeight(font, 166, signaturePreview, {
        canAndCtx: { can: canvas, ctx: ctxCanvas },
      })

      croppedCanvas.width = measure.width + 40
      croppedCanvas.height = measure.height + 40

      ctx.clearRect(0, 0, croppedCanvas.width, croppedCanvas.height)
      ctx.fillStyle = '#0040ac'
      ctx.font = font
      ctx.textBaseline = 'alphabetic'
      const yBaseline = -measure.relativeTop + 20
      ctx.fillText(signaturePreview, 20, yBaseline)
      const imgData2 = croppedCanvas.toDataURL('img/png')
      return imgData2
    })
    return res
  }

  const onFinish = async values => {
    dispatch({ type: spinnerConstants.START })
    const res = await croppedSignImage(signaturePreview, ref)
    const newItem = {
      ...values,
      file: {
        name: `${values.name}.png`,
        thumbUrl: res,
      },
      size_offset: values.size_offset === null ? 0 : values.size_offset,
    }
    dispatch(signatureActions.addItem(convertFormValuesDeep(newItem)))
  }

  useEffect(() => {
    if (user_id) {
      form.setFieldsValue({
        user_id: user_id || '',
      })
    }
  }, [form, user_id])

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      {isLoadSignatureFont && (
        <Form {...formItemLayout} name="basic" form={form} onFinish={onFinish} scrollToFirstError>
          <Form.Item
            label="User"
            name="user_id"
            rules={[
              {
                required: true,
                message: 'Please select User!',
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Select or search a user"
              optionFilterProp="children"
              filterOption={(input, option) => option.label.includes(input.toString())}
              options={clientsListOptions}
            />
          </Form.Item>

          <Form.Item label="Name" name="name">
            <Input />
          </Form.Item>

          <Form.Item
            label="Code"
            name="code"
            rules={[
              {
                required: true,
                message: 'Please input code!',
              },
            ]}
          >
            <Input onChange={event => setSignaturePreview(event.target.value)} />
          </Form.Item>
          <Form.Item label="Size Offset (percent)" name="size_offset">
            <InputNumber step={1} min={-99} max={99} defaultValue={0} />
          </Form.Item>
          <span
            ref={ref}
            style={{
              fontFamily: 'HWSign',
              fontSize: 166,
              color: '#0040ac',
            }}
          >
            {signaturePreview}
          </span>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>
      )}
    </NavContainer>
  )
}

export default CreateItem
