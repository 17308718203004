const apiKeysConstants = {
  GETALL_REQUEST: 'API_KEYS_GETALL_REQUEST',
  GETALL_SUCCESS: 'API_KEYS_SUCCESS',
  GETALL_FAILURE: 'API_KEYS_FAILURE',

  SETALL: 'SETALL',

  navMenu: {
    view: { key: 'view', title: 'View Client', url: '' },
    edit: { key: 'edit', title: 'Update Client', url: '' },
    list: { key: 'list', title: 'List Client', url: '/clients/list' },
    manage: { key: 'manage', title: 'Manage Client', url: '/clients' },
  },

  advancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'Name', key: 'name' },
    { label: 'Api Key', key: 'uid' },
  ],
}

export default apiKeysConstants
