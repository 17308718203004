import React, { useEffect, useState } from 'react'
import { Button, Space, Tooltip } from 'antd'
import { useDispatch } from 'react-redux'

import { ImpositionConfigDataForm, ImpositionDraggableList } from './index.js'
import { impositionHelpers } from '_helpers'
import { impositionLayoutsActions } from '_actions'

import './style.scss'

const ImpositionLayoutConfig = ({
  paperSize,
  configData,
  configId,
  impositionId,
  cardDimensions,
  isActiveTab,
}) => {
  const dispatch = useDispatch()
  const [tooltipMessage, setTooltipMessage] = useState('')
  const [isNewConfig, setIsNewConfig] = useState(true)
  const [lockedCards, setLockedCards] = useState([])

  useEffect(() => {
    if (configData?.config) {
      // if config data has no id - is new (create mode)
      setIsNewConfig(!configData?.id)

      if (!configData.config.front.length) {
        setTooltipMessage('There is no cards!')
      } else if (!+configData.config.barcode.value) {
        setTooltipMessage('Barcode number is required!')
      } else {
        setTooltipMessage('')
      }
    }
  }, [configData])

  const onSubmit = async () => {
    const cardSize = {
      height: cardDimensions.card_height,
      width: cardDimensions.card_width,
    }

    const convertedConfig = impositionHelpers.convertConfigBeforeSave(
      configData.config,
      paperSize,
      cardSize,
    )

    const payload = {
      dimension_id: configId,
      imposition_id: impositionId,
      config: JSON.stringify(convertedConfig),
      status: 1,
    }

    if (isNewConfig) {
      await dispatch(impositionLayoutsActions.createConfig(payload))
      await dispatch(impositionLayoutsActions.getImpositionConfigs(impositionId))
    } else {
      dispatch(impositionLayoutsActions.updateConfigById(configData.id, payload))
    }
  }

  return (
    <div>
      <ImpositionConfigDataForm
        configId={configId}
        config={configData?.config}
        isActiveTab={isActiveTab}
        paperSize={paperSize}
        repeatModeData={configData?.repeatMode}
        cardDimensions={cardDimensions}
        isCardRotateDisabled={!!lockedCards.length}
      />
      <ImpositionDraggableList
        paperSize={paperSize}
        config={configData?.config}
        configId={configId}
        cardDimensions={cardDimensions}
        repeatModeData={configData?.repeatMode}
        lockedCards={lockedCards}
        setLockedCards={setLockedCards}
      />

      <Space>
        <Tooltip title={tooltipMessage}>
          <Button
            style={{ marginTop: '1.5rem' }}
            type="primary"
            size="large"
            onClick={onSubmit}
            disabled={!!tooltipMessage}
          >
            {isNewConfig ? 'Save' : 'Update'} Configs
          </Button>
        </Tooltip>

        {/* <Tooltip title={tooltipMessage}>
          <Button
            style={{ marginTop: '1.5rem' }}
            size="large"
            onClick={() => {
              console.log('preview!')
            }}
            disabled={!!tooltipMessage}
            // loading={previewLoading}
          >
            Generate Preview
          </Button>
        </Tooltip> */}
      </Space>
    </div>
  )
}

export default ImpositionLayoutConfig
