import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import v4 from 'uuid/v4'
import { Tooltip } from 'antd'

import getCurrentUrl from '../../../../_helpers/url-utils'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import { templateActions, templateCategoriesActions as tcActions } from '../../../../_actions'
import { settingsConstants, templateConstants } from '../../../../_constants'
import { getTemplatesRows, getPage, getTotalItems } from '_selectors/templates.selector'
import { getTemplateCategoriesRows } from '_selectors/template-categories.selector'

const { navMenu, statuses, advancedSearch } = templateConstants

const statusRender = text => {
  return `${statuses.find(st => st.value.toString() === text.toString())?.text}`
}

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    width: 80,
  },
  {
    title: 'User_Id',
    dataIndex: 'user_id',
    key: 'user_id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    width: 80,
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category_id',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status_filter',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: statusRender,
    filters: statuses,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    ellipsis: {
      showTitle: false,
    },
    maxWidth: '50px',
    render: message => (
      <Tooltip placement="topLeft" title={message}>
        <div
          style={{
            maxWidth: '120px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {message}
        </div>
      </Tooltip>
    ),
  },
  {
    title: 'Client Groups',
    dataIndex: 'client_groups',
    key: 'client_groups',
    hideFilter: true,
  },
]

const navMenuInit = [navMenu.list, navMenu.create]

const ManageItems = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getTemplatesRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const templateCategories = useSelector(getTemplateCategoriesRows)

  const deleteItems = async id => {
    await dispatch(templateActions.deleteItem(id))
  }

  const navigateToList = () => {
    dispatch({ type: templateConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}/list`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.list.key:
          return {
            ...menu,
            url: navigateToList,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  useEffect(() => {
    dispatch(tcActions.getAll())
  }, [dispatch])

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        category: item.template_category?.name || '',
        client_groups:
          item.user_groups?.map((group, index) => {
            return (
              <span key={group.id}>
                {index !== 0 ? <br /> : ''}
                <span>{group.title}</span>
              </span>
            )
          }) || '',
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page, templateCategories])

  const templateCategoriesSelect = useMemo(() => {
    return templateCategories?.map(template => ({ value: template.id, label: template.name }))
  }, [templateCategories])

  const statusesSelect = useMemo(() => {
    return statuses.map(status => ({ value: status.value, label: status.text }))
  }, [statuses])

  const updAdvancedSearch = advancedSearch.map(field => {
    if (field.key === 'category_id') return { ...field, options: templateCategoriesSelect }
    if (field.key === 'status_filter') return { ...field, options: statusesSelect }
    return field
  })

  return (
    <NavContainer menu={navMenuParse}>
      <TableFilter
        deleteItems={deleteItems}
        columns={initColumns}
        data={dataSource}
        getDataAction={templateActions.getAll}
        advancedSearch={updAdvancedSearch}
        showAdvancedButton={!!true}
      />
    </NavContainer>
  )
}

export default ManageItems
