import React from 'react'
import { Tabs } from 'antd'

import DetailsDataHighlighter from './details-data-highlighter'

const DetailsDataContent = ({ data, opts, returnvalue, activeKey, onKeyChange, logs }) => {
  const dataTabs = [
    {
      label: 'Data',
      key: 'data',
      children: (
        <DetailsDataHighlighter>{JSON.stringify({ data }, null, 2)}</DetailsDataHighlighter>
      ),
    },
    {
      label: 'Options',
      key: 'options',
      children: <DetailsDataHighlighter>{JSON.stringify(opts, null, 2)}</DetailsDataHighlighter>,
    },
    {
      label: 'Result',
      key: 'result',
      children: (
        <DetailsDataHighlighter>{JSON.stringify(returnvalue, null, 2)}</DetailsDataHighlighter>
      ),
    },
    {
      label: 'Logs',
      key: 'logs',
      children: <DetailsDataHighlighter>{JSON.stringify(logs, null, 2)}</DetailsDataHighlighter>,
    },
  ]
  return (
    <div className="details-data-content">
      <Tabs type="card" items={dataTabs} activeKey={activeKey} onChange={onKeyChange} />
    </div>
  )
}

export default DetailsDataContent
