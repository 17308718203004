import { templateCategoriesConstants as tcConstants } from '../_constants'
import { templateCategoriesService as tcService } from '../_services'
import { history } from '../_helpers'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: tcConstants.GETALL_REQUEST })
  try {
    const templateCategories = await tcService.getAll(params, typeAction)
    dispatch({ type: tcConstants.GETALL_SUCCESS, payload: templateCategories.data })
  } catch (error) {
    dispatch({ type: tcConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const addItem = value => async dispatch => {
  dispatch({ type: tcConstants.ADD_REQUEST })
  try {
    const templateCategory = await tcService.addItem({ value })
    dispatch({ type: tcConstants.ADD_SUCCESS, payload: { ...value, id: templateCategory.id } })
    history.push(`/template-categories/view/${templateCategory.id}`)
  } catch (error) {
    dispatch({ type: tcConstants.ADD_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: tcConstants.GETBYID_REQUEST })
  try {
    const templateCategory = await tcService.getItemById(id)
    dispatch({ type: tcConstants.GETBYID_SUCCESS, payload: templateCategory.data })
  } catch (error) {
    dispatch({ type: tcConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const deleteItem = id => async dispatch => {
  dispatch({ type: tcConstants.DELETE_REQUEST })
  try {
    await tcService.deleteItem(id)
    history.push('/template-categories')
    dispatch({ type: tcConstants.DELETE_SUCCESS, payload: id })
  } catch (error) {
    dispatch({ type: tcConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const updateItemById = (id, value) => async dispatch => {
  dispatch({ type: tcConstants.UPDATE_REQUEST })
  try {
    const updated = await tcService.updateItemById(id, value)
    dispatch({ type: tcConstants.UPDATE_SUCCESS, payload: updated?.message?.toString() })
    history.push(`/template-categories/view/${id}`)
  } catch (error) {
    dispatch({ type: tcConstants.UPDATE_FAILURE, payload: error.toString() })
  }
}

const templateCategoriesActions = {
  getAll,
  getItemById,
  updateItemById,
  deleteItem,
  addItem,
}

export default templateCategoriesActions
