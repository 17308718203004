import { useState } from 'react'

const useValidationEmailForNotification = () => {
  const [emailWarning, setEmailWarning] = useState(false)
  const validateEmailList = e => {
    const list = e.target.value

    const emailsArray = list.split(/[\n;, ]/i).filter(v => !!v)

    const isEveryEmailValid = emailsArray.every(email =>
      email.match(/^[\w-\.+?!']+@([\w-]+\.)+[\w-]+$/),
    )

    setEmailWarning(!isEveryEmailValid)
  }

  return {
    emailWarning,
    setEmailWarning,
    validateEmailList,
  }
}

export default useValidationEmailForNotification
