import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, DatePicker, Form, Input, InputNumber, Select } from 'antd'

import NavContainer from '../../../../components/nav-container'
import { prePayCreditsType3Actions } from '../../../../_actions'
import { prepaidCreditsType3Constants } from '../../../../_constants'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import dayjs from 'dayjs'
import useClientsToOptionsList from 'hooks/clients/useClientsToOptionsList'

const { navMenu, formItemLayout } = prepaidCreditsType3Constants

const navMenuInit = [navMenu.list, navMenu.manage]

const CreateItem = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const user_id = useSelector(state => state.queryParams?.where?.user_id)

  const clientsListOptions = useClientsToOptionsList()

  const onFinish = async values => {
    const mergedData = {
      ...values,
      expires_at: values.expires_at?.format('YYYY-MM-DD'),
    }
    await dispatch(prePayCreditsType3Actions.addItem(convertFormValuesDeep(mergedData)))
  }

  useEffect(() => {
    form.setFieldsValue({
      user_id: user_id || '',
    })
  }, [form, user_id])

  const disabledDate = current => {
    return current && current < dayjs().endOf('day')
  }

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      <Form
        {...formItemLayout}
        name="createPayPreCredit"
        form={form}
        onFinish={onFinish}
        scrollToFirstError
      >
        <Form.Item
          label="User"
          name="user_id"
          rules={[
            {
              required: true,
              message: 'Please select User!',
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Select or search a user"
            optionFilterProp="children"
            filterOption={(input, option) => option.label.includes(input.toString())}
            options={clientsListOptions}
          />
        </Form.Item>

        <Form.Item
          label="Credit"
          name="credit"
          rules={[
            {
              required: true,
              message: 'Please input credit amount!',
            },
          ]}
        >
          <InputNumber step={0.1} min={0} style={{ width: '146px' }} />
        </Form.Item>

        <Form.Item label="Note" name="note">
          <Input />
        </Form.Item>

        <Form.Item
          label="Discount (percent)"
          name="discount"
          rules={[
            {
              required: true,
              message: 'Please input amount!',
            },
          ]}
        >
          <InputNumber step={1} style={{ width: '146px' }} min={0} max={99} />
        </Form.Item>

        <Form.Item
          label="Expires At"
          name="expires_at"
          rules={[
            {
              required: true,
              message: 'Please select expiration date!',
            },
          ]}
        >
          <DatePicker disabledDate={disabledDate} />
        </Form.Item>

        <Button type="primary" htmlType="submit">
          Create
        </Button>
      </Form>
    </NavContainer>
  )
}

export default CreateItem
