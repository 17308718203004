const ShippingMethodsConstants = {
  GETALL_REQUEST: 'SHIPPING_METHODS_GETALL_REQUEST',
  GETALL_SUCCESS: 'SHIPPING_METHODS_SUCCESS',
  GETALL_FAILURE: 'SHIPPING_METHODS_FAILURE',

  ADD_REQUEST: 'SHIPPING_METHODS_ADD_REQUEST',
  ADD_SUCCESS: 'SHIPPING_METHODS_ADD_SUCCESS',
  ADD_FAILURE: 'SHIPPING_METHODS_ADD_FAILURE',

  GETBYID_REQUEST: 'SHIPPING_METHODS_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'SHIPPING_METHODS_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'SHIPPING_METHODS_GETBYID_FAILURE',

  DELETE_REQUEST: 'SHIPPING_METHODS_DELETE_REQUEST',
  DELETE_SUCCESS: 'SHIPPING_METHODS_DELETE_SUCCESS',
  DELETE_FAILURE: 'SHIPPING_METHODS_DELETE_FAILURE',

  UPDATE_REQUEST: 'SHIPPING_METHODS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'SHIPPING_METHODS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'SHIPPING_METHODS_UPDATE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    list: { key: 'list', title: 'List Shipping Methods', url: '/shipping-methods/list' },
    manage: { key: 'manage', title: 'Manage Shipping Methods', url: '/shipping-methods' },
    create: { key: 'create', title: 'Create Shipping Methods', url: '/shipping-methods/create' },
    edit: { key: 'edit', title: 'Update Shipping Methods', url: '' },
    delete: { key: 'delete', title: 'Delete Shipping Methods', url: '' },
    view: { key: 'view', title: 'View Shipping Methods', url: '' },
  },

  // ShippingMethodsStatuses: [
  //   { value: 'ACTIVE', text: 'YES' },
  //   { value: 'DRAFT', text: 'NO' },
  // ],

  isShowInOutbound: [
    { value: 1, text: 'Yes' },
    { value: 0, text: 'No' },
  ],

  listItemKeyToLabel: {
    id: 'ID',
    method_name: 'Name',
    method_label: 'Label',
    discount: 'Discount',
    add_postage_fee: 'Add Postage fee',
    show_options: 'Show Options',
    status: 'Status',
    check_recipient_address: 'Check Recipient Address',
    check_return_address: 'Check Return Address',
    show_in_outbound: 'Show in Outbound',
  },
  viewItemKeyToLabel: {
    id: 'ID',
    method_name: 'Name',
    method_label: 'Label',
    discount: 'Discount',
    add_postage_fee: 'Add Postage fee',
    show_options: 'Show Options',
    status: 'Status',
    check_recipient_address: 'Check Recipient Address',
    check_return_address: 'Check Return Address',
    show_in_outbound: 'Show in Outbound',
  },
  advancedSearch: [
    { id: 'ID' },
    { method_name: 'Name' },
    { method_label: 'Label' },
    { discount: 'Discount' },
    { add_postage_fee: 'Add Postage fee' },
    { show_options: 'Show Options' },
    { status: 'Status' },
    { check_recipient_address: 'Check Recipient Address' },
    { check_return_address: 'Check Return Address' },
  ],
  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },
  ShippingMethodsStatuses: [
    { value: 1, text: 'Active' },
    { value: 0, text: 'Not Active' },
  ],
}
export default ShippingMethodsConstants
