import { shopifyConstants } from '../_constants'

const initialState = {
  items: [],
  issues: 0,
  issues_items: [],
}

function shopify(state = initialState, { type, payload }) {
  switch (type) {
    case shopifyConstants.GETALL_SUCCESS:
      return { ...state, items: payload, item: {} }
    case shopifyConstants.GETBYID_SUCCESS:
      return { ...state, item: payload }
    case shopifyConstants.SETBYID:
      return {
        ...state,
        item: {},
      }
    case shopifyConstants.SETALL:
      return {
        ...state,
        items: [],
      }
    case shopifyConstants.GETISSUES_SUCCESS:
      return {
        ...state,
        issues: payload,
      }
    case shopifyConstants.GETISSUES_ITEMS_SUCCESS:
      return {
        ...state,
        issues_items: payload,
      }
    default:
      return state
  }
}

export default shopify
