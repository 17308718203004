import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { CreateItem, EditItem, ManageItems, UploadItem, ViewItem } from './views'
import Content from '../../components/content'
import signatureActions from '../../_actions/signatures.actions.js'

const Signatures = () => {
  const dispatch = useDispatch()
  const match = useRouteMatch()

  const fetchData = async () => {
    const results = document.getElementsByTagName('style')
    const stylesAsList = Array.from(results)
    const oldSignatureFont = stylesAsList.find(style => style.dataset.signaturefont)

    if (oldSignatureFont) {
      oldSignatureFont.remove()
    }

    const data = await dispatch(signatureActions.getSignatureFont())
    const styleSheet = document.createElement('style')
    styleSheet.setAttribute('data-signaturefont', 1)
    styleSheet.innerText = data
    document.head.appendChild(styleSheet)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Content>
      <Switch>
        <Route exact path={match.url} component={ManageItems} />
        <Route path={`${match.url}/create`} component={CreateItem} />
        <Route path={`${match.url}/view/:id`} component={ViewItem} />
        <Route path={`${match.url}/edit/:id`} component={EditItem} />
        <Route path={`${match.url}/upload`} component={UploadItem} />

        <Redirect to={match.url} />
      </Switch>
    </Content>
  )
}

export default Signatures
