import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { Badge } from 'antd'
import { Card, CardBody } from 'reactstrap'
import './style.scss'

const Navigation = ({ active, menu }) => {
  const isLinkDisabled = (object, key) => Object.prototype.hasOwnProperty.call(object, key)
  return (
    <Card
      className="card--not-full-height documentation__nav-wrap"
      style={{ position: 'sticky', top: 15 }}
    >
      <CardBody className="px-1">
        <div>
          <div className="d-flex flex-column col-sm-12 px-3 nav-menu">
            {menu.map(item => {
              if (typeof item.url === 'function') {
                return (
                  <div key={item.key} style={{ padding: '5px 0' }}>
                    <a
                      onClick={item.url}
                      style={{
                        margin: '5px 0',
                        fontSize: 'medium',
                        pointerEvents: isLinkDisabled(item, 'disabled') && 'none',
                        opacity: isLinkDisabled(item, 'disabled') && 0.7,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                      }}
                      className={`documentation__nav-link ${
                        active === item.key ? ' documentation__nav--active' : ''
                      }`}
                    >
                      {item.title}{' '}
                      {item?.count || item?.count === 0 ? (
                        <Badge
                          showZero
                          size="default"
                          overflowCount={item?.count + 1}
                          style={{ marginLeft: '5px' }}
                          count={item.count}
                        />
                      ) : null}
                    </a>
                  </div>
                )
              }
              return (
                <Link
                  style={{ margin: '5px 0', fontSize: 'medium' }}
                  key={item.key}
                  to={{
                    pathname: item.url,
                    state: item.params,
                  }}
                  component={item.component}
                  className={`documentation__nav-link${
                    active === item.key ? ' documentation__nav--active' : ''
                  }`}
                >
                  {item.title}{' '}
                  {item?.count || item?.count === 0 ? (
                    <Badge
                      showZero
                      size="default"
                      overflowCount={item?.count + 1}
                      style={{ marginLeft: '5px' }}
                      count={item.count}
                    />
                  ) : null}
                </Link>
              )
            })}
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

Navigation.propTypes = {
  active: PropTypes.string,
  menu: PropTypes.array,
}

Navigation.defaultProps = {
  active: '',
  menu: [],
}

export default Navigation
