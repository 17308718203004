import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import v4 from 'uuid/v4'

import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'

import { impositionLayoutsActions } from '../../../../_actions'
import { impositionLayoutsConstants } from '../../../../_constants'
import { impositionLayoutsSelectors } from '_selectors/imposition-layouts.selector'

const { navMenu, layoutStatuses } = impositionLayoutsConstants

const statusRender = text =>
  `${layoutStatuses.find(st => st.value.toString() === text.toString())?.text}`

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '10%',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '30%',
  },
  {
    title: 'Width',
    dataIndex: 'width',
    key: 'width',
    width: '20%',
    sorter: true,
  },
  {
    title: 'Height',
    dataIndex: 'height',
    key: 'height',
    width: '20%',
    sorter: true,
  },
  {
    title: 'Layouts',
    dataIndex: 'total_layouts',
    key: 'total_layouts',
    width: '20%',
    hideFilter: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: statusRender,
    filters: layoutStatuses,
    width: '10%',
    sorter: true,
  },
  {
    title: 'Date Created',
    dataIndex: 'date_created',
    key: 'date_created*date',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
]

const navMenuInit = [navMenu.create]

const ManageItems = ({ match }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const rows = useSelector(impositionLayoutsSelectors.getImpositionRows)
  const page = useSelector(impositionLayoutsSelectors.getPage)
  const total = useSelector(impositionLayoutsSelectors.getTotalItems)

  const deleteItems = async id => {
    await dispatch(impositionLayoutsActions.deleteItem(id))
  }

  const navigateToLayout = id => {
    history.push({
      pathname: `imposition-layouts/layout/${id}`,
    })
  }

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        total_layouts: (
          <Space onClick={() => navigateToLayout(item.id)}>
            <Button
              size="small"
              type="text"
              className="list-view-item-bold list-item-underline px-0"
              style={{ border: 'none' }}
            >
              {item.total_layouts}
            </Button>
            <Button
              style={{ color: '#595c97' }}
              size="small"
              shape="square"
              icon={<PlusOutlined />}
            />
          </Space>
        ),
      })),
      key: v4(),
      total,
      page,
    }
  }, [rows, total, page])

  return (
    <NavContainer match={match} menu={navMenuInit}>
      <TableFilter
        deleteItems={deleteItems}
        columns={initColumns}
        data={dataSource}
        getDataAction={impositionLayoutsActions.getAll}
      />
    </NavContainer>
  )
}

export default ManageItems
