const prepaidCreditsType3Constants = {
  GETALL_REQUEST: 'PREPAYCREDITS_TYPE3_GETALL_REQUEST',
  GETALL_SUCCESS: 'PREPAYCREDITS_TYPE3_GETALL_SUCCESS',
  GETALL_FAILURE: 'PREPAYCREDITS_TYPE3_GETALL_FAILURE',

  GETBYID_REQUEST: 'PREPAYCREDITS_TYPE3_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'PREPAYCREDITS_TYPE3_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'PREPAYCREDITS_TYPE3_GETBYID_FAILURE',

  ADD_REQUEST: 'PREPAYCREDITS_TYPE3_ADD_REQUEST',
  ADD_SUCCESS: 'PREPAYCREDITS_TYPE3_ADD_SUCCESS',
  ADD_FAILURE: 'PREPAYCREDITS_TYPE3_ADD_FAILURE',

  DELETE_REQUEST: 'PREPAYCREDITS_TYPE3_DELETE_REQUEST',
  DELETE_SUCCESS: 'PREPAYCREDITS_TYPE3_DELETE_SUCCESS',
  DELETE_FAILURE: 'PREPAYCREDITS_TYPE3_DELETE_FAILURE',

  UPDATE_REQUEST: 'PREPAYCREDITS_TYPE3_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'PREPAYCREDITS_TYPE3_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'PREPAYCREDITS_TYPE3_UPDATE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    list: { key: 'list', title: 'List Pre-Pay Credits (Credit Type 3)', url: 'credit_type3/list' },
    create: {
      key: 'create',
      title: 'Create Pre-Pay Credits (Credit Type 3)',
      url: '/credit_type3/create',
    },
    manage: {
      key: 'manage',
      title: 'Manage Pre-Pay Credits (Credit Type 3)',
      url: '/credit_type3',
    },
    edit: { key: 'edit', title: 'Update Pre-Pay Credits (Credit Type 3)', url: '' },
    view: { key: 'view', title: 'View Pre-Pay Credits (Credit Type 3)', url: '' },
  },

  advancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'User', key: 'user_id' },
    { label: 'Credit', key: 'credit' },
    { label: 'Discount', key: 'discount' },
    { label: 'Expires At', key: 'expires_at*date' },
  ],

  formItemLayout: {
    labelCol: {
      xs: { span: 4 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 4 },
      sm: { span: 10 },
    },
  },

  listItemKeyToLabel: {
    id: 'ID',
    user_id: 'User ID',
    credit: 'Credit',
    discount: 'Discount',
    expires_at: 'Expires At',
  },

  viewItemKeyToLabel: {
    id: 'ID',
    user_id: 'User ID',
    credit: 'Credit',
    discount: 'Discount',
    expires_at: 'Expires At',
  },
}
export default prepaidCreditsType3Constants
