import React from 'react'

const fontConstants = {
  GETALL_REQUEST: 'FONTS_GETALL_REQUEST',
  GETALL_SUCCESS: 'FONTS_GETALL_SUCCESS',
  GETALL_FAILURE: 'FONTS_GETALL_FAILURE',

  GETBYID_REQUEST: 'FONTS_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'FONTS_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'FONTS_GETBYID_FAILURE',

  ADD_REQUEST: 'FONTS_ADD_REQUEST',
  ADD_SUCCESS: 'FONTS_ADD_SUCCESS',
  ADD_FAILURE: 'FONTS_ADD_FAILURE',

  DELETE_REQUEST: 'FONTS_DELETE_REQUEST',
  DELETE_SUCCESS: 'FONTS_DELETE_SUCCESS',
  DELETE_FAILURE: 'FONTS_DELETE_FAILURE',

  UPDATE_REQUEST: 'FONTS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'FONTS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'FONTS_UPDATE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    list: { key: 'list', title: 'List Font', url: '/fonts/list' },
    manage: { key: 'manage', title: 'Manage Font', url: '/fonts' },
    create: { title: 'Create Font', key: 'create', url: '/fonts/create' },
    edit: { key: 'edit', title: 'Update Font', url: '' },
    delete: { key: 'delete', title: 'Delete Font', url: '' },
    view: { key: 'view', title: 'View Font', url: '' },
  },

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },

  checkBoxGroupStyle: {
    display: 'grid',
    gridGap: 20,
    maxHeight: '300px',
    border: '1px solid #e4e9f0',
    overflowY: 'scroll',
    padding: '10px 0 10px 10px',
  },

  viewItemKeyToLabel: {
    id: 'Unique Font ID',
    font_name: 'Font Name',
    label: 'Font Title',
    line_spacing: 'Line Spacing',
    sort: 'Sort',
    image: 'Preview',
  },

  listItemKeyToLabel: {
    id: 'Unique Font ID',
    font_name: 'Font Name',
    label: 'Font Title',
    line_spacing: 'Line Spacing',
    sort: 'Sort',
    image: 'Preview',
  },
  advancedSearch: [
    { label: 'Unique Font ID', key: 'id' },
    { label: 'Font Title', key: 'label' },
  ],

  renderComponent: {
    // eslint-disable-next-line react/display-name
    image: (url, title = '') => (url ? <img width="450" src={url} alt={title} /> : '----'),
  },
}
export default fontConstants
