import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import v4 from 'uuid/v4'
import getCurrentUrl from '../../../../_helpers/url-utils'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import { notificationActions } from '../../../../_actions'
import { notificationConstants, settingsConstants } from '../../../../_constants'
import { getNotificationsRows, getPage, getTotalItems } from '_selectors/notifications.selector'

const {
  navMenu,
  advancedSearch,
  notificationColor,
  notificationStatuses,
  visibleToAllAuthorized,
  visibleToGuest,
} = notificationConstants

const visibleToAllAuthorizedRender = text => {
  return `${visibleToAllAuthorized.find(st => !!st.value === Boolean(text))?.text}`
}

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    width: '20%',
  },
  {
    title: 'Color',
    dataIndex: 'color',
    key: 'color_filter',
    filters: notificationColor,
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Employee',
    dataIndex: 'employee_id',
    key: 'employee_id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status_filter',
    filters: notificationStatuses,
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Visible To All Authorized',
    dataIndex: 'visible_to_all',
    key: 'visible_to_all_filter',
    filter: visibleToAllAuthorizedRender,
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
]

const navMenuInit = [navMenu.list, navMenu.create]

const ManageItems = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getNotificationsRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const { superuser, can_edit_notifications } = useSelector(state => state.authentication.user)

  const deleteItem = async id => {
    await dispatch(notificationActions.deleteItem(id))
  }

  const navigateToList = () => {
    dispatch({ type: notificationConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}/list`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.list.key:
          return {
            ...menu,
            url: navigateToList,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  const notificationColorSelect = useMemo(() => {
    return notificationColor?.map(color => ({ value: color.value, label: color.text }))
  }, [notificationColor])

  const statusesSelect = useMemo(() => {
    return notificationStatuses.map(status => ({ value: status.value, label: status.text }))
  }, [notificationStatuses])

  const visibleToGuestSelect = useMemo(() => {
    return visibleToGuest.map(status => ({ value: status.value, label: status.text }))
  }, [visibleToGuest])

  const visibleToAllAuthorizedSelect = useMemo(() => {
    return visibleToAllAuthorized.map(status => ({ value: status.value, label: status.text }))
  }, [visibleToAllAuthorized])

  const updAdvancedSearch = advancedSearch.map(field => {
    if (field.key === 'color_filter') return { ...field, options: notificationColorSelect }
    if (field.key === 'status_filter') return { ...field, options: statusesSelect }
    if (field.key === 'visible_to_all_filter')
      return { ...field, options: visibleToAllAuthorizedSelect }
    if (field.key === 'visible_to_guest_filter') return { ...field, options: visibleToGuestSelect }
    return field
  })

  const canEdit = superuser || can_edit_notifications

  return (
    <NavContainer match={match} menu={navMenuParse}>
      <TableFilter
        deleteItems={deleteItem}
        columns={initColumns}
        data={dataSource}
        getDataAction={notificationActions.getAll}
        isEditOnId={canEdit}
        advancedSearch={updAdvancedSearch}
        showAdvancedButton={!!true}
      />
    </NavContainer>
  )
}

export default ManageItems
