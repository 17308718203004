import { apiKeysConstants } from '../_constants'

const initialState = {
  items: null,
}

function apiKeys(state = initialState, { type, payload }) {
  switch (type) {
    case apiKeysConstants.GETALL_SUCCESS:
      return { ...state, items: payload }
    case apiKeysConstants.SETALL:
      return {
        ...state,
        items: null,
      }
    default:
      return state
  }
}

export default apiKeys
