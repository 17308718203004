import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Checkbox, Col, Form, Input, Row, Select } from 'antd'
import NavContainer from '../../../../components/nav-container'
import { employeesConstants } from '../../../../_constants'
import { employeesActions } from '../../../../_actions'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'

const { navMenu, formItemLayout, checkbox, permissionsField } = employeesConstants

const navMenuInit = [navMenu.manage, navMenu.listUser]

const CreateItem = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const onFinish = async values => {
    const newValue = convertFormValuesDeep({
      ...values,
      notify_on_low_stock: values.notify_on_low_stock ? 1 : 0,
      notify_on_failed_purchase: values.notify_on_failed_purchase ? 1 : 0,
      ...values.permissions?.reduce((acc, item) => ({ ...acc, [item]: 1 }), {}),
    })
    delete newValue.permissions

    await dispatch(employeesActions.addItem(newValue))
  }

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      <Form {...formItemLayout} name="basic" form={form} onFinish={onFinish} scrollToFirstError>
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: 'Please input username!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Password" name="password">
          <Input />
        </Form.Item>

        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              required: true,
              message: 'Please input E-mail!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          initialValue="0"
          label="Super User"
          name="superuser"
          rules={[
            {
              required: true,
              message: 'Please input Super User!',
            },
          ]}
        >
          <Select>
            <Select.Option value="0">No</Select.Option>
            <Select.Option value="1">Yes</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item initialValue="0" label="Status" name="status">
          <Select>
            <Select.Option value={-1}>Banned</Select.Option>
            <Select.Option value={0}>Not Active</Select.Option>
            <Select.Option value={1}>Active</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="First Name" name="first_name">
          <Input />
        </Form.Item>

        <Form.Item label="Last Name" name="last_name">
          <Input />
        </Form.Item>

        <Form.Item name="permissions" label="Permissions">
          <Checkbox.Group>
            <Row>
              {permissionsField.map(item => (
                <Col key={item.value} span={checkbox.span}>
                  <Checkbox value={item.value} style={checkbox.style}>
                    {item.label}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>

        <Form.Item label="Notify On Low Stock" name="notify_on_low_stock" valuePropName="checked">
          <Checkbox style={checkbox.style} />
        </Form.Item>

        <Form.Item
          label="Notify On Failed Purchase"
          name="notify_on_failed_purchase"
          valuePropName="checked"
        >
          <Checkbox style={checkbox.style} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </NavContainer>
  )
}

export default CreateItem
