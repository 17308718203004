/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-dynamic-require */
import React, { useEffect, useMemo, useState } from 'react'
import {
  Alert,
  Button,
  Collapse,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Tooltip,
  Table,
} from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import Text from 'antd/es/typography/Text'
import { arrayEquals } from '_helpers'
import { useMyOrdersTable } from 'hooks/batch-orders/useMyOrdersTable'
import BatchOrdersTable from '../../../../components/batch-orders-table'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import { batchOrdersActions } from '../../../../_actions'
import { alertConstants, batchOrdersConstants } from '../../../../_constants'
import ImposedCardModal from 'components/imposed-card-modal'
import deliveryLogo from '../../../../assets/image/truck-delivered-icon.png'

import '../../../../components/batch-orders-table/style.scss'
import alertActions from '../../../../_actions/alert.actions'

const { serviceCode, packageType } = batchOrdersConstants
const { Panel } = Collapse

const Control = ({
  setGenerateShippingLabels,
  isGenerateShippingLabels,
  selectedRows,
  isHide,
  setRefresh,
  allOrdersIds,
  isAllCardsFlat,
  orientationsConflict,
}) => {
  const dispatch = useDispatch()

  const [toolTip, setToolTip] = useState({})
  const [isActualManifest, setIsActualManifest] = useState(false)
  const [activeModal, setActiveModal] = useState(null)

  const isManifestCreated = useSelector(state => state.batchOrders.manifestsStatus?.status)
  const fileLink = useSelector(state => state.batchOrders.manifestsStatus?.file)
  const file_cutLink = useSelector(state => state.batchOrders.manifestsStatus?.file_cut)
  const manifestsForIds = useSelector(state => state.batchOrders.manifestsStatus?.manifestsForIds)
  const isEnvelopesDownloaded = useSelector(state => state.batchOrders?.isEnvelopesDownloaded)
  const isNotesDownloaded = useSelector(state => state.batchOrders?.isNotesDownloaded)
  const isImposedDownloaded = useSelector(state => state.batchOrders?.isImposedDownloaded)
  const user = useSelector(({ authentication }) => authentication.user)
  const manifestId = useSelector(state => state.batchOrders.manifestsStatus?.manifest_id)

  const discardModal = useSelector(state => state.alerts.modal)
  const completeModal = useSelector(state => state.alerts.completeModal)

  const employeeId = useSelector(state => state.authentication.user.id)
  const { statusMessage } = useSelector(state => state.batchOrders.statusLabels)
  const permission = useSelector(state => state.users.permis)

  useEffect(() => {
    dispatch(batchOrdersActions.statusManifest())
  }, [])

  const ids = useMemo(() => {
    const set = new Set(
      selectedRows.flatMap(card => {
        if (card.selected.some(x => x.ids)) {
          return card.selected.flatMap(card => (card.ids ? card.ids?.map(id => id) : card))
        }

        return card.selected
      }),
    )

    return Array.from(set)
  }, [selectedRows])

  const checkActualManifest = (manifestsForIds, ids) => {
    const isEquals = arrayEquals(manifestsForIds, ids)
    setIsActualManifest(isEquals)
  }

  useEffect(() => {
    if (isManifestCreated) {
      checkActualManifest(manifestsForIds, ids)
    }
  }, [ids])

  useEffect(() => {
    if (isManifestCreated) {
      checkActualManifest(manifestsForIds, ids)
    }
  }, [manifestsForIds])

  const ganarateToDownloadManifest = useMemo(() => {
    let message = null

    message = (
      <div>
        A manifest for the orders already has been created.
        <br />
        {!fileLink ? null : (
          <>
            <a style={{ color: 'blue' }} target="_blank" rel="noopener noreferrer" href={fileLink}>
              Download Manifest
            </a>
            <br />
          </>
        )}
        {!file_cutLink ? null : (
          <a
            style={{ color: 'blue' }}
            target="_blank"
            rel="noopener noreferrer"
            href={file_cutLink}
          >
            Download Manifest Cut
          </a>
        )}
      </div>
    )

    return message
  }, [fileLink, file_cutLink])

  const hasPermission = useMemo(() => {
    return permission.superuser || permission.can_create_ups_shipping_labels
  }, [permission])

  const btnStyle = {
    margin: 10,
  }

  const toolTipChange = (visible, toolTipKey) => {
    setToolTip(prev => {
      switch (toolTipKey) {
        case 'notesToolTip':
        case 'envelopesToolTip':
        case 'imposedToolTip':
        case 'imposedPythonToolTip':
        case 'completeOrders':
          return { ...prev, [toolTipKey]: visible && !isActualManifest }
        case 'generateToolTip':
        case 'manifestTooltip':
        case 'manifestCutTooltip':
        case 'printToolTip':
        case 'cancelToolTip':
          return { ...prev, [toolTipKey]: visible && ids.length === 0 }
        default:
          return prev
      }
    })
  }

  const generateLabelPdf = async () => {
    const params = {
      orderIds: ids,
      employee_id: employeeId,
    }
    await dispatch(batchOrdersActions.printLabels(params))
  }

  const cancelLabels = async () => {
    const params = {
      orderIds: ids,
      employee_id: employeeId,
    }
    await dispatch(batchOrdersActions.cancelLabels(params))
    await dispatch(batchOrdersActions.statusLabels(!isHide))
  }

  useEffect(() => {
    if (completeModal) {
      Modal.confirm({
        title: completeModal.title,
        icon: <ExclamationCircleOutlined />,
        content: completeModal.text,
        okText: 'Continue',
        okButtonProps: { type: 'primary' },
        cancelText: 'Cancel',
        onOk: () => {
          confirm()
          dispatch({ type: alertConstants.CLEAR_COMPLETE_MODAL })
        },
        afterClose: () => dispatch({ type: alertConstants.CLEAR_COMPLETE_MODAL }),
      })
    }
  }, [completeModal])

  useEffect(() => {
    if (discardModal) {
      Modal.confirm({
        title: 'Discard Orders',
        icon: <ExclamationCircleOutlined />,
        content: discardModal.text,
        okText: 'Discard Orders',
        okButtonProps: { type: 'danger' },
        cancelText: 'Cancel',
        onOk: () => {
          dispatch(batchOrdersActions.discardOrders(allOrdersIds, true))
          setRefresh(true)
        },
        afterClose: () => dispatch({ type: alertConstants.MODAL, payload: null }),
      })
    }
  }, [discardModal])

  const discardOrders = async () => {
    // false = confirmed, Not click on Ok modal window
    await dispatch(batchOrdersActions.discardOrders(allOrdersIds, false))
    setRefresh(true)
  }

  const createManifest = async (orderIds, isCut, isNewManifest) => {
    await dispatch(batchOrdersActions.createManifest(orderIds, isCut, isNewManifest))
  }

  const confirm = async () => {
    await dispatch(batchOrdersActions.completeOrders(ids))
    if (allOrdersIds.length === ids.length) {
      setRefresh(true)
    } else {
      setIsActualManifest(false)
      dispatch(batchOrdersActions.clearManifestsStatus(batchOrdersConstants.CLEAR_MANIFESTS_STATUS))
    }
  }

  const preConfirmModal = () => {
    const notFoundedDoc = []

    if (!isNotesDownloaded) notFoundedDoc.push('notes')
    if (!isEnvelopesDownloaded) notFoundedDoc.push('envelopes')
    if (!isImposedDownloaded) notFoundedDoc.push('imposed')

    // add only in front of last words
    const correctMessage = notFoundedDoc.join(',').replace(/,([^,]*)$/, ' and $1')

    const modalText = (
      <span>
        Are you sure you want to continue without{' '}
        <span style={{ fontWeight: '600' }}>{correctMessage}</span>?
      </span>
    )

    const modalInfo = {
      title: 'Info',
      text: modalText,
    }

    dispatch(alertActions.completeOrderModal(modalInfo))
  }

  const onConfirmHandler = () => {
    const isAllDocxDownloaded = isNotesDownloaded && isEnvelopesDownloaded && isImposedDownloaded
    if (isAllDocxDownloaded) confirm()
    else preConfirmModal()
  }

  const onPrintImposedCards = async (sizes, formValues) => {
    const isTemplateMode = activeModal.toLowerCase().includes('template')
    const version = isTemplateMode ? 6 : 5

    const payload = {
      ids,
      pageSize: sizes,
      manifest_id: +manifestId,
      employee_name: user.username,
      withBarcode: true,
      onlyCustom: formValues.only_custom,
      version,
      imposition_id: formValues?.imposition_id || null,
    }

    await dispatch(batchOrdersActions.generateImposedCards(payload))
  }

  return (
    <div style={{ marginTop: 10 }}>
      <ImposedCardModal
        isVisible={activeModal?.includes('imposed-modal')}
        isAllCardsFlat={isAllCardsFlat}
        onOk={onPrintImposedCards}
        onCancel={() => setActiveModal(null)}
        warningMessage={
          orientationsConflict
            ? `You have selected cards with a different orientation. The imposition PDF size adjusted to 13x19`
            : ''
        }
        templateMode={activeModal?.includes('imposed-modal-python-template')}
      />

      <div>
        <Tooltip
          onVisibleChange={visible => toolTipChange(visible, 'notesToolTip')}
          visible={!!toolTip.notesToolTip}
          title={toolTipTitle.manifest}
          color="red"
        >
          <Button
            style={btnStyle}
            onClick={() => dispatch(batchOrdersActions.createDocxNotes(ids))}
            disabled={!isActualManifest}
            type="primary"
          >
            Get Word: Notes
          </Button>
        </Tooltip>

        <Tooltip
          onVisibleChange={visible => toolTipChange(visible, 'envelopesToolTip')}
          visible={!!toolTip.envelopesToolTip}
          title={toolTipTitle.manifest}
          color="red"
        >
          <Button
            style={btnStyle}
            onClick={() => dispatch(batchOrdersActions.createDocxEnvelopes(ids))}
            disabled={!isActualManifest}
            type="primary"
          >
            Get Word: Envelopes
          </Button>
        </Tooltip>
      </div>
      <div>
        <Button
          style={btnStyle}
          onClick={() => dispatch(batchOrdersActions.createPDFNotes(ids))}
          disabled={!isActualManifest}
          type="primary"
        >
          Get Notes HWF
        </Button>
        <Button
          style={btnStyle}
          onClick={() => dispatch(batchOrdersActions.createPDFEnvelopes(ids))}
          disabled={!isActualManifest}
          type="primary"
        >
          Get Envelopes HWF
        </Button>
      </div>
      <div>
        <Tooltip
          onVisibleChange={visible => toolTipChange(visible, 'manifestTooltip')}
          visible={!!toolTip.manifestTooltip}
          title={toolTipTitle.labels}
          color="red"
        >
          <Button
            style={btnStyle}
            onClick={() => createManifest(ids, false, !isActualManifest)}
            type="primary"
            disabled={ids.length === 0}
          >
            Print Manifest
          </Button>
        </Tooltip>
        <Tooltip
          onVisibleChange={visible => toolTipChange(visible, 'manifestCutTooltip')}
          visible={!!toolTip.manifestCutTooltip}
          title={toolTipTitle.labels}
          color="red"
        >
          <Button
            style={btnStyle}
            onClick={() => createManifest(ids, true, !isActualManifest)}
            type="primary"
            disabled={ids.length === 0}
          >
            Print Manifest (Cut)
          </Button>
        </Tooltip>
        {/* <Tooltip */}
        {/*  onVisibleChange={visible => toolTipChange(visible, 'imposedToolTip')} */}
        {/*  visible={!!toolTip.imposedToolTip} */}
        {/*  title={toolTipTitle.manifest} */}
        {/*  color="red" */}
        {/* > */}
        {/*  <Button */}
        {/*    style={btnStyle} */}
        {/*    onClick={() => setActiveModal('customImposedModal')} */}
        {/*    type="primary" */}
        {/*    disabled={ids.length === 0 || !isActualManifest} */}
        {/*  > */}
        {/*    Print Imposed Cards */}
        {/*  </Button> */}
        {/* </Tooltip> */}

        {/* <Tooltip */}
        {/*  onVisibleChange={visible => toolTipChange(visible, 'imposedNonCustomToolTip')} */}
        {/*  visible={!!toolTip.imposedNonCustomToolTip} */}
        {/*  title={toolTipTitle.manifest} */}
        {/*  color="red" */}
        {/* > */}
        {/*  <Button */}
        {/*    style={btnStyle} */}
        {/*    onClick={() => setActiveModal('imposedModal')} */}
        {/*    type="primary" */}
        {/*    disabled={ids.length === 0 || !isActualManifest} */}
        {/*  > */}
        {/*    Print Imposed Cards (includes non custom) */}
        {/*  </Button> */}
        {/* </Tooltip> */}

        <Tooltip
          onVisibleChange={visible => toolTipChange(visible, 'imposedToolTip')}
          visible={!!toolTip.imposedToolTip}
          title={toolTipTitle.manifest}
          color="red"
        >
          <Button
            type={ids.length === 0 || !isActualManifest ? 'primary' : 'default'}
            style={
              ids.length === 0 || !isActualManifest
                ? btnStyle
                : { ...btnStyle, backgroundColor: '#2eb82e', color: '#ffffff' }
            }
            onClick={() => setActiveModal('imposed-modal-python')}
            className={ids.length === 0 || !isActualManifest ? 'imposition-playwright-button' : ''}
            disabled={ids.length === 0 || !isActualManifest}
          >
            Print Imposed Cards (Python)
          </Button>
        </Tooltip>

        <Tooltip
          onVisibleChange={visible => toolTipChange(visible, 'imposedPythonToolTip')}
          visible={!!toolTip.imposedPythonToolTip}
          title={toolTipTitle.manifest}
          color="red"
        >
          <Button
            type={ids.length === 0 || !isActualManifest ? 'primary' : 'default'}
            style={
              ids.length === 0 || !isActualManifest
                ? btnStyle
                : { ...btnStyle, backgroundColor: '#2eb82e', color: '#ffffff' }
            }
            onClick={() => setActiveModal('imposed-modal-python-template')}
            className={ids.length === 0 || !isActualManifest ? 'imposition-playwright-button' : ''}
            disabled={ids.length === 0 || !isActualManifest}
          >
            Print Imposed Cards (Python Template)
          </Button>
        </Tooltip>

        {isManifestCreated && isActualManifest && (
          <div style={{ width: 'max-content' }}>
            <Alert description={ganarateToDownloadManifest} type="info" showIcon />
          </div>
        )}
      </div>
      {hasPermission && (
        <div>
          <Tooltip
            onVisibleChange={visible => toolTipChange(visible, 'generateToolTip')}
            visible={!!toolTip.generateToolTip}
            title={toolTipTitle.labels}
            color="red"
          >
            <Button
              disabled={ids.length === 0}
              style={btnStyle}
              onClick={() => setGenerateShippingLabels(!isGenerateShippingLabels)}
              type="primary"
            >
              Generate Shipping Labels...
            </Button>
          </Tooltip>
          <Tooltip
            onVisibleChange={visible => toolTipChange(visible, 'printToolTip')}
            visible={!!toolTip.printToolTip}
            title={toolTipTitle.labels}
            color="red"
          >
            <Button
              style={btnStyle}
              onClick={generateLabelPdf}
              type="primary"
              disabled={ids.length === 0}
            >
              Print Shipping Labels
            </Button>
          </Tooltip>
          <Tooltip
            onVisibleChange={visible => toolTipChange(visible, 'cancelToolTip')}
            visible={!!toolTip.cancelToolTip}
            title={toolTipTitle.labels}
            color="red"
          >
            <Button style={btnStyle} onClick={cancelLabels} danger disabled={ids.length === 0}>
              Cancel Shipping Labels
            </Button>
          </Tooltip>
          <div>{statusMessage}</div>
        </div>
      )}
      <ShippingLabelsForm
        setGenerateShippingLabels={setGenerateShippingLabels}
        isGenerateShippingLabels={isGenerateShippingLabels}
        orderIds={ids}
        isHide={isHide}
      />
      <div>
        <Tooltip
          onVisibleChange={visible => toolTipChange(visible, 'completeOrders')}
          visible={!!toolTip.completeOrders}
          title={toolTipTitle.manifest}
          color="red"
        >
          <Button disabled={!isActualManifest} style={btnStyle} onClick={onConfirmHandler}>
            Complete Orders
          </Button>
        </Tooltip>
        <Button style={btnStyle} danger onClick={discardOrders}>
          Discard All Orders
        </Button>
      </div>
    </div>
  )
}

const ShippingLabelsForm = ({
  setGenerateShippingLabels,
  isGenerateShippingLabels,
  orderIds,
  isHide,
}) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const employeeId = useSelector(state => state.authentication.user.id)
  const countOrdersWithLabels = useSelector(state => state.batchOrders.countOrdersWithLabels)
  const ordersInWorkCount = useSelector(state => state.batchOrders.ordersInWorkCount)
  const generatedLabelsError = useSelector(state => state.batchOrders.generatedLabelsError)

  const shipingWithSelect = useMemo(() => {
    return listItemKeyToShiping.map(value => {
      if (value.key === 'package_type') {
        return { ...value, options: packageType }
      }
      if (value.key === 'service_code') {
        return { ...value, options: serviceCode }
      }
      return value
    })
  }, [])

  const shippingForm = shipingWithSelect.map(field => {
    const { type, key, label, options = [] } = field
    let rules = []
    if (key === 'email') {
      rules = [
        {
          type: 'email',
          message: 'The input is not valid E-mail!',
        },
        {
          required: true,
          message: 'Please input E-mail!',
        },
      ]
    }
    if (key === 'weight') {
      rules = [
        {
          required: true,
          message: 'Please input weight!',
        },
      ]
    }
    if (type === 'select') {
      return (
        <Form.Item
          name={key}
          label={label}
          key={key}
          rules={[
            {
              required: true,
              message: `Please select ${label}!`,
            },
          ]}
        >
          <Select options={options} showSearch />
        </Form.Item>
      )
    }
    return (
      <Form.Item label={label} name={key} key={key} rules={rules || false}>
        {key === 'email' ? <Input /> : <InputNumber min={0} />}
      </Form.Item>
    )
  })

  const onFinish = async values => {
    const params = {
      values: convertFormValuesDeep(values),
      orderIds,
      employee_id: employeeId,
    }
    // hideForm()
    await dispatch(batchOrdersActions.generateLabels(params))
    await dispatch(batchOrdersActions.statusLabels(!isHide))
  }

  const hideForm = () => {
    form.resetFields()
    setGenerateShippingLabels(false)
  }

  return (
    <div
      className={`shipping-labels-form ${
        isGenerateShippingLabels ? 'shipping-labels-form__show' : ''
      }`}
    >
      <Divider orientation="left" />
      {!!countOrdersWithLabels && (
        <>
          <Alert
            message="Warning!"
            showIcon
            description={`${countOrdersWithLabels} of ${ordersInWorkCount} orders has generated labels!`}
            type="warning"
          />
          <Divider orientation="left" />
        </>
      )}
      <Form {...layout} name="AdvancedSearch" form={form} onFinish={onFinish} scrollToFirstError>
        {shippingForm}
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" style={{ marginRight: 10 }}>
            Generate
          </Button>
          <Button htmlType="button" onClick={hideForm}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
      {generatedLabelsError && (
        <>
          <Text style={{ color: 'red' }}>[Order </Text>
          <Text
            style={{ cursor: 'pointer' }}
            onClick={() => window.open(`/orders/edit/${generatedLabelsError.orderId}`, '_blank')}
            strong
            underline
          >
            {generatedLabelsError.orderId}
          </Text>
          <Text style={{ color: 'red' }}>{`] Failure: ${generatedLabelsError.text}`}</Text>
        </>
      )}
    </div>
  )
}

const MyOrders = ({ setRefresh }) => {
  const dispatch = useDispatch()

  const [isHide, setHide] = useState(true)
  const [testData, setTestData] = useState([])
  const [isGenerateShippingLabels, setGenerateShippingLabels] = useState(false)
  const ordersInWork = useSelector(state => state.batchOrders.ordersInWork)
  const ordersInWorkCount = useSelector(state => state.batchOrders.ordersInWorkCount)
  const permission = useSelector(state => state.users.permis)

  const hasPermission = useMemo(() => {
    return permission.superuser || permission.can_create_ups_shipping_labels
  }, [permission])

  const {
    rowClassName,
    selectUnselectNested,
    selectedCards,
    setSelectedCards,
    allOrdersIds,
    initCardsData,
    selectAllOrdersByCardId,
    onSelect,
    control,
    isAllCardsFlat,
    orientationsConflict,
  } = useMyOrdersTable(testData)

  const { icons } = useSelector(state => state.batchOrders.statusLabels)

  const detailsColumns = columns.map(col => {
    if (col.dataIndex === 'id') {
      return {
        ...col,
        render: id => {
          const statusImg = (hasPermission && icons?.find(icon => icon.id === id)) || null
          return {
            children: (
              <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <Text
                  style={{ cursor: 'pointer', marginRight: '10px' }}
                  onClick={() => window.open(`/orders/view/${id}`, '_blank')}
                  strong
                  underline
                >
                  {id}
                </Text>
                {statusImg ? (
                  <Tooltip
                    overlayStyle={{ whiteSpace: 'pre-line' }}
                    title={statusImg.tooltip}
                    color="grey"
                  >
                    <img
                      style={{ margin: '0 15px' }}
                      src={getStatusIcon(statusImg.img)}
                      alt={statusImg.alt}
                      width="23px"
                    />
                  </Tooltip>
                ) : (
                  ''
                )}
              </div>
            ),
          }
        },
      }
    }
    return col
  })

  useEffect(() => {
    return () => {
      dispatch(batchOrdersActions.clearView(batchOrdersConstants.CLEAR_MY_ORDERS_VIEW))
      dispatch(batchOrdersActions.clearManifestsStatus(batchOrdersConstants.CLEAR_MANIFESTS_STATUS))
    }
  }, [])

  useEffect(() => {
    if (!isHide) dispatch(batchOrdersActions.statusLabels(isHide))
  }, [isHide])

  useEffect(() => {
    if (testData.length > 0) {
      initCardsData()
    }
  }, [testData])

  const genExtra = (cardId, item, defaultLength) => {
    const card = selectedCards.find(card => card.id === cardId)

    // const nestedIds = item.orders.flatMap(i => {
    //   if (i.childrenNodes) {
    //     return i.childrenNodes.map(node => node.id)
    //   }
    //   return i.id
    // })

    const currOrderLength = card => {
      if (card.keys) return card.keys.length

      return card.selected.length
    }

    const olength = currOrderLength(card)
    const isSelectAll = olength !== defaultLength

    return (
      <Button
        onClick={event => {
          event.stopPropagation()
          if (isSelectAll) return selectAllOrdersByCardId(cardId)
          const cards = selectedCards.map(card => {
            if (card.id === cardId && card.keys) {
              return {
                ...card,
                keys: [],
                // selected: [],
                selected: card.selected.map(i => {
                  return {
                    ...i,
                    ids: [],
                  }
                }),
              }
            }

            if (card.id === cardId) {
              return {
                ...card,
                selected: [],
              }
            }

            return card
          })
          return setSelectedCards(cards)
        }}
      >
        {isSelectAll ? `Select All` : `Unselect All`}
      </Button>
    )
  }

  useEffect(() => {
    if (ordersInWork) {
      const tableDataCopy = JSON.parse(JSON.stringify(ordersInWork))

      tableDataCopy.forEach(row => {
        row.orders.map(row => {
          row.childrenNodes = row.children
          delete row.children
          return row
        })
      })

      setTestData(tableDataCopy)
    }
  }, [ordersInWork])

  const _expandedRowRender = currrecord => {
    const CARD_ID = currrecord.card_id
    const RECORD_ID = currrecord.id

    const _getSelectedIds = order => order.orderId === RECORD_ID

    const selectedRowKeys = selectedCards
      .find(card => card.id === CARD_ID)
      .selected.find(_getSelectedIds)?.ids

    const rowSelection = {
      selectedRowKeys,
      onSelect: (record, selected, selectedRows) => {
        if (!selected) {
          const current = selectedCards.map(card =>
            selectUnselectNested(card, currrecord, selectedRows.length, x =>
              control(record.id, x).deselect(),
            ),
          )
          return setSelectedCards(current)
        }

        const current = selectedCards.map(card =>
          selectUnselectNested(card, currrecord, selectedRows.length, x =>
            control(record.id, x).select(),
          ),
        )

        return setSelectedCards(current)
      },
      hideSelectAll: true,
    }

    return (
      <Table
        rowSelection={rowSelection}
        columns={detailsColumns}
        dataSource={currrecord.childrenNodes}
        pagination
        className="batch-orders-nested__table"
      />
    )
  }

  return ordersInWorkCount > 0 ? (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginBottom: '10px',
        }}
      >
        <h3 style={{ marginRight: 10 }}>{ordersInWorkCount} Orders for printing</h3>
        <Button onClick={() => setHide(!isHide)}>{isHide ? 'Show' : 'Hide'}</Button>
      </div>
      {!isHide && (
        <>
          {testData.map((item, index) => {
            // const isNestedOrder = selectedCards[index].selected?.some(i => i.orderId)
            const ids = selectedCards[index].selected
            const nestedKeys = selectedCards[index].keys

            const getSelectedRowKeys = () => {
              if (selectedCards[index].keys) return nestedKeys

              return ids
            }

            const selectedRowKeys = getSelectedRowKeys()
            const defaultLength = item.orders.map(i => i.id).length

            return (
              <Collapse key={item.card?.id} defaultActiveKey={[item.card?.id]}>
                <Panel
                  header={`${item.card?.name}: ${item.card?.cnt}`}
                  key={item.card?.id}
                  extra={genExtra(item.card?.id, item, defaultLength)}
                >
                  <BatchOrdersTable
                    pagination
                    columns={detailsColumns}
                    dataSource={item.orders}
                    rowClassName={rowClassName}
                    rowSelection={{
                      selectedRowKeys,
                      hideSelectAll: true,
                      onSelect,
                    }}
                    expandable={{
                      defaultExpandAllRows: true,
                      expandedRowRender: record => _expandedRowRender(record, index),
                      rowExpandable: record => record?.childrenNodes?.length,
                    }}
                    rowKey="id"
                  />
                </Panel>
              </Collapse>
            )
          })}
        </>
      )}
      <Control
        setGenerateShippingLabels={setGenerateShippingLabels}
        isGenerateShippingLabels={isGenerateShippingLabels}
        selectedRows={selectedCards}
        isHide={isHide}
        setRefresh={setRefresh}
        allOrdersIds={allOrdersIds}
        isAllCardsFlat={isAllCardsFlat}
        orientationsConflict={orientationsConflict}
      />
    </>
  ) : (
    <h3>No orders for printing</h3>
  )
}

export default MyOrders

const renderBulk = (text, row) => {
  const obj = {
    children: text,
    props: { colSpan: 1 },
  }
  if (row.is_bulk) {
    obj.props.colSpan = 7
    obj.children = (
      <div className="w-100 d-flex" style={{ background: '#fff', padding: 7 }}>
        {row.note || 'No notes'}
      </div>
    )
  }
  return obj
}

const renderDeliveryConfirmation = delivery_confirmation =>
  delivery_confirmation ? (
    <Tooltip title="Delivery Confirmation">
      <img src={deliveryLogo} width={45} style={{ marginRight: '.2rem' }} />
    </Tooltip>
  ) : (
    ''
  )

const renderNonBulk = (text, row) => {
  const obj = {
    children: text || null,
    props: { colSpan: 1 },
  }
  if (row.is_bulk) {
    obj.props.colSpan = 0
    obj.children = row.note
  }
  return obj
}

const getStatusIcon = iconLabel => {
  // eslint-disable-next-line global-require
  const icon = require(`../../../../assets/${iconLabel}`)
  return icon
}

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    width: 150,
  },
  {
    title: 'Delivery Confirmation',
    dataIndex: 'delivery_confirmation',
    render: renderDeliveryConfirmation,
  },
  {
    title: 'Redo',
    dataIndex: 'redo',
    width: 75,
    render: renderBulk,
  },
  {
    title: 'Gift Card',
    dataIndex: 'gcard',
    render: renderNonBulk,
  },
  {
    title: 'Custom Inserts',
    dataIndex: 'custom_inserts',
    render: renderNonBulk,
  },
  {
    title: 'Intl',
    dataIndex: 'intl',
    width: 75,
    render: renderNonBulk,
  },
  {
    title: 'From Name',
    dataIndex: ['from', 'name'],
    render: renderNonBulk,
  },
  {
    title: 'To Name',
    dataIndex: ['to', 'name'],
    render: renderNonBulk,
  },
  {
    title: 'Message',
    dataIndex: 'message',
    width: 220,
    ellipsis: true,
    render: renderNonBulk,
  },
]

const listItemKeyToShiping = [
  { label: 'Package Type', key: 'package_type', type: 'select' },
  { label: 'Package Width (inches)', key: 'width' },
  { label: 'Package Height (inches)', key: 'height' },
  { label: 'Package Length (inches)', key: 'length' },
  { label: 'Package Weight (lbs)', key: 'weight' },
  { label: 'Notify Email', key: 'email' },
  { label: 'Service', key: 'service_code', type: 'select' },
]

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 8 },
}
const tailLayout = {
  wrapperCol: { offset: 5, span: 8 },
}

const toolTipTitle = {
  manifest: 'Please, print Manifest or Manifest Cut',
  labels: 'No selected orders',
}
