import React, { useEffect, useMemo, useState } from 'react'
import { Button, List, Tooltip, Typography } from 'antd'
import { useParams, useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { paginationActions } from '../../_actions'
import { appConstants, settingsConstants, spinnerConstants } from '../../_constants'
import useGlobalLoader from '../../hooks/loader/useGlobalLoader.js'

const { Text } = Typography
// eslint-disable-next-line no-unused-vars
const ListViewer = ({ data: { rows, total, page = 1 }, getDataAction, isAssign }) => {
  const dispatch = useDispatch()
  const match = useRouteMatch()

  const [currentPage, setCurrentPage] = useState(1)
  const { id } = useParams()

  const { [match.url]: storedPageSize } = useSelector(state => state.pagination)
  const { loadingManagePage } = useSelector(state => state.spinner)

  const action = useSelector(state => state.settings.managePageAction)
  const { where } = useSelector(state => state.queryParams)
  const userId = useSelector(state => state.authentication.user.id)

  const currentPageSize = useMemo(() => {
    return storedPageSize || appConstants.DEFAULT_PAGE_SIZE
  }, [storedPageSize])

  const pageSizeChanged = (current, size) => {
    if (rows?.length === 0) return
    dispatch(paginationActions.pageSizeChanged(match.url, size))
    dispatch({ type: spinnerConstants.START_ON_MANAGE_PAGE })
  }

  const paginationChanged = (page, pageSize) => {
    dispatch(paginationActions.paginationChanged(page, pageSize))
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: 'paginate' })
    dispatch({ type: spinnerConstants.START_ON_MANAGE_PAGE })
    setCurrentPage(page)
  }

  useEffect(() => {
    let whereStatus = where
    switch (match.url) {
      case '/orders/available':
        whereStatus = { employee_id: null, status: 'paid' }
        break
      case '/orders/my':
        whereStatus = { employee_id: userId }
        break
      default:
        whereStatus = where
    }

    dispatch(
      getDataAction(
        {
          offset: currentPage || 1,
          limit: currentPageSize,
          where: whereStatus,
          like: null,
          order: null,
        },
        action,
        id,
      ),
    )
  }, [currentPageSize, currentPage])

  // change page when we have only one item on page and delete this item/
  // useEffect(() => {
  //   setCurrentPage(page)
  // }, [page])

  useEffect(() => {
    if (rows?.length >= 0) dispatch({ type: spinnerConstants.STOP_ON_MANAGE_PAGE })
  }, [rows])

  return useGlobalLoader(
    <div>
      <List
        header={
          <div className="list-view-title">
            Displaying {total === 0 || !total ? 0 : (page - 1) * currentPageSize + 1} -{' '}
            {total || total === 0 ? Math.min(page * currentPageSize, total) : 0} of {total ?? 0}{' '}
            results.
          </div>
        }
        loading={loadingManagePage || isAssign}
        itemLayout="horizontal"
        dataSource={rows}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: currentPageSize,
          current: currentPage,
          onShowSizeChange: pageSizeChanged,
          onChange: paginationChanged,
          total,
        }}
        renderItem={item => {
          const image = item.find(itemLine => itemLine.type === 'image')
          return (
            <List.Item
              style={
                image
                  ? {
                      display: 'grid',
                      gridTemplateColumns: `${Number(image.value.props.width) + 5}px 1fr`,
                      alignItems: 'start',
                    }
                  : {}
              }
            >
              {image && <div>{image.value}</div>}
              <div>
                {item.map(itemLine => {
                  return itemLine.type === 'image' ? (
                    ''
                  ) : (
                    <div
                      key={itemLine?.key}
                      className={`${itemLine.float ? 'list-view-item-float-left' : ''}`}
                    >
                      <span className="list-view-item-bold">
                        {itemLine?.type === 'image' ? '' : `${itemLine.label}: `}
                      </span>
                      {itemLine.clickHandler ? (
                        <Tooltip
                          title={itemLine?.toolTip || `Click to edit`}
                          placement="rightBottom"
                        >
                          <Button
                            size="small"
                            type="text"
                            className="list-view-item-bold list-item-underline px-0"
                            onClick={itemLine?.clickHandler}
                          >
                            <Text strong underline>
                              {itemLine?.value}
                            </Text>
                          </Button>
                        </Tooltip>
                      ) : (
                        <span>
                          {itemLine?.value || itemLine?.value === 0 ? itemLine?.value : '----'}
                        </span>
                      )}
                    </div>
                  )
                })}
              </div>
            </List.Item>
          )
        }}
      />
    </div>,
  )
}
ListViewer.defaultProps = {
  data: {},
  getDataAction: () => {},
}
ListViewer.propTypes = {
  data: PropTypes.object,
  getDataAction: PropTypes.func,
}
export default ListViewer
