import axios from 'axios'
import apiUrl from './service'
import { v1 } from './api'
import serialize from '_helpers/hw-serialize-params'

const getItemById = async id => {
  const result = await axios.get(`${apiUrl}/${v1}/client_notes/${id}`)
  return handleResponse(result)
}

const createNote = async ({ value }) => {
  const result = await axios.post(`${apiUrl}/${v1}/client_notes`, JSON.stringify(value))
  return handleResponse(result)
}

const updateItem = async ({ value }) => {
  const result = await axios.put(`${apiUrl}/${v1}/client_notes`, JSON.stringify(value))
  return handleResponse(result)
}

const getAll = async (params, typeAction) => {
  const result = await axios.get(`${apiUrl}/${v1}/client_notes?${serialize(params)}`, {
    params: { stopSpinner: typeAction },
  })
  return handleResponse(result)
}

const deleteItem = async id => {
  const result = await axios.delete(`${apiUrl}/${v1}/client_notes/${id}`, {
    params: { stopSpinner: 'paginate' },
  })
  return handleResponse(result)
}

const getCountNotes = async id => {
  const result = await axios.get(`${apiUrl}/${v1}/client_notes/count/${id}`)
  return handleResponse(result)
}

const handleResponse = result => {
  return result.data
}

const clientNotesService = {
  getItemById,
  createNote,
  updateItem,
  getAll,
  deleteItem,
  getCountNotes,
}

export default clientNotesService
