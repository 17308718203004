import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import ManageItems from './manage'
import Content from '../../components/content'

const BanIp = () => {
  const match = useRouteMatch()

  return (
    <Content>
      <Switch>
        <Route exact path={match.url} component={ManageItems} />
        <Redirect to={match.url} />
      </Switch>
    </Content>
  )
}

export default BanIp
