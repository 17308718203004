import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Badge, Button, Modal, Tabs, Typography } from 'antd'
import lodash from 'lodash'
import { DeleteFilled } from '@ant-design/icons'

import CronJobsDetailsList from 'components/cron-jobs/details/cron-job-details.list'
import { useDispatch, useSelector } from 'react-redux'
import cronJobsActions from '_actions/cron-jobs.actions'

const CronJobsDetails = () => {
  const dispatch = useDispatch()
  const { job_name } = useParams()

  const [activeTab, setActiveTab] = useState('latest')

  const { item } = useSelector(state => state.cronJobs)

  useEffect(() => {
    if (job_name) {
      dispatch(cronJobsActions.getByName(job_name, activeTab))
    }
  }, [job_name, activeTab])

  if (lodash.isEmpty(item)) return null

  const latestTabData = ['latest', 0]

  const changeTabHandler = key => {
    setActiveTab(key)
  }

  const deleteAllHandler = () => {
    Modal.confirm({
      title: 'Are you sure?',
      content: 'Are you sure that you want to clean all completed jobs?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      icon: null,
      onOk: () => dispatch(cronJobsActions.clearAll(job_name, activeTab)),
    })
  }

  const tabs = [latestTabData, ...Object.entries(item.counts)].map(([key, count]) => ({
    key,
    label: (
      <span>
        {key.toUpperCase()}
        <Badge count={count} style={{ marginLeft: '0.5rem' }} />
      </span>
    ),
    children: (
      <>
        {!!item.list.length && key === 'completed' ? (
          <Button className="delete-button" type="text" onClick={deleteAllHandler}>
            <DeleteFilled className="delete-icon" />
            Clean all
          </Button>
        ) : null}
        <CronJobsDetailsList
          activeTab={activeTab}
          list={item.list}
          group={key}
          jobName={job_name}
        />
      </>
    ),
  }))

  return (
    <div>
      <Typography.Title level={2}>{job_name}</Typography.Title>
      <Tabs onChange={changeTabHandler} defaultActiveKey="latest" items={tabs} />
    </div>
  )
}

export default CronJobsDetails
