import { userConstants } from '../_constants'
import { authService } from '../_services'
import { history } from '../_helpers'
import alertActions from './alert.actions'

const login = (user, isQaPage) => async dispatch => {
  dispatch({ type: userConstants.LOGIN_REQUEST })
  try {
    const result = await authService.login(user)

    localStorage.setItem('user', JSON.stringify(result.data?.user))
    dispatch({ type: userConstants.LOGIN_SUCCESS, user: result.data?.user })

    // const path = sessionStorage.getItem('path')

    // if (path) {
    //   history.push(path)
    //   sessionStorage.removeItem('path')
    // } else {
    if (!isQaPage) history.push('/')
  } catch (error) {
    dispatch({ type: userConstants.GETALL_FAILURE, error: error.toString() })
  }
}

const logout = isQaPage => async dispatch => {
  dispatch({ type: userConstants.LOGOUT_REQUEST })
  try {
    await authService.logout()
    dispatch({ type: userConstants.SET_PERMIS, payload: null })
    dispatch({ type: userConstants.SETBYID })
    dispatch({ type: userConstants.LOGOUT_SUCCESS })
    // if (event) {
    //   sessionStorage.removeItem('path')
    // }
    if (isQaPage) {
      history.push('/completion/auth/login')
    } else {
      history.push('/login')
    }
  } catch (error) {
    dispatch({ type: userConstants.LOGOUT_FAILURE, error: error.toString() })
  }
}

const register = newUser => async dispatch => {
  dispatch({ type: userConstants.REGISTER_REQUEST })
  try {
    const user = await authService.register(newUser)
    history.push('/login')
    dispatch({ type: userConstants.REGISTER_SUCCESS, user })
  } catch (error) {
    dispatch({ type: userConstants.GETALL_FAILURE, error: error.toString() })
  }
}

const changePassword = (pass, userId) => async dispatch => {
  dispatch({ type: userConstants.CHANGE_PASSWORD_REQUEST })
  try {
    await authService.changePassword(pass, userId)
    history.push('/user/profile')
    dispatch({ type: userConstants.CHANGE_PASSWORD_SUCCESS })
  } catch (error) {
    dispatch({ type: userConstants.CHANGE_PASSWORD_FAILURE, payload: error.toString() })
  }
}

const updateUser = (userInfo, userId) => async dispatch => {
  dispatch({ type: userConstants.UPDATE_REQUEST })
  try {
    await authService.updateUser(userInfo, userId)
    history.push('/user/profile')
  } catch (error) {
    dispatch({ type: userConstants.UPDATE_FAILURE, payload: error.toString() })
  }
}

const getUserById = userId => async dispatch => {
  dispatch({ type: userConstants.GETBYID_REQUEST })
  try {
    const user = await authService.getItemById(userId)
    dispatch({ type: userConstants.GETBYID_SUCCESS, user: user.data })
  } catch (error) {
    dispatch({ type: userConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const sentEmailToResetPassword = email => async dispatch => {
  dispatch({ type: userConstants.SENT_EMAIL_REQUEST })
  try {
    await authService.sentEmailToResetPassword(email)
    dispatch({ type: userConstants.SENT_EMAIL_SUCCESS })
  } catch (error) {
    dispatch({ type: userConstants.SENT_EMAIL_FAILURE, payload: error.toString() })
  }
}

const restorePassword = (password, token) => async dispatch => {
  dispatch({ type: userConstants.RESTORE_PASSWORD_REQUEST })
  try {
    const { data } = await authService.restorePassword(password, token)
    dispatch({ type: userConstants.RESTORE_PASSWORD_SUCCESS })
    history.push('/login')

    dispatch(alertActions.success(data.message.toString()))
  } catch (error) {
    dispatch({ type: userConstants.RESTORE_PASSWORD_FAILURE, payload: error.toString() })
  }
}

const setPermission = permission => {
  return {
    type: userConstants.SET_PERMIS,
    payload: permission,
  }
}

const userActions = {
  login,
  logout,
  register,
  changePassword,
  updateUser,
  getUserById,
  setPermission,
  sentEmailToResetPassword,
  restorePassword,
}

export default userActions
