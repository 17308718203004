import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { Button, Form, InputNumber, Modal, Select, Upload } from 'antd'
import { ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import { clientGroupActions, framesActions } from '../../../../_actions'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { appConstants, framesConstants } from '../../../../_constants'
import { convertDataForSelect, parseFileForSetFields } from '../../../../_helpers'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import FramePreview from 'components/frame-preview'
import { convertValuesToFormData } from '_helpers/hw-object-utils'
import QrCode from '../../../../assets/image/qr-code.png'
import { getClientGroupsRows } from '../../../../_selectors/client-group.selector.js'

const { formItemLayout, navMenu, frameStatuses, frameTypes } = framesConstants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.view, navMenu.delete, navMenu.manage]

const EditItem = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const match = useRouteMatch()
  const { id } = useParams()
  const [form] = Form.useForm()
  const view = getCurrentUrl(match.url)

  const [changed, setChange] = useState(false)

  const [frame, setFrame] = useState(null)

  const positionX = Form.useWatch('position_x', form) || 0
  const positionY = Form.useWatch('position_y', form) || 0
  const qrCodeSize = Form.useWatch('qr_code_size', form) || 100

  const [navMenuParse, setNavMenuParse] = useState([])

  const clientGroups = useSelector(getClientGroupsRows)

  const groups = useMemo(() => clientGroups?.map(e => ({ value: e.id, label: e.title })), [
    clientGroups,
  ])

  useEffect(() => {
    dispatch(clientGroupActions.getAll())
  }, [dispatch])

  const {
    item: { frame: frameData },
  } = useSelector(state => state.frames)

  useEffect(() => {
    dispatch(framesActions.getItemById(id))
    return () => {
      dispatch({ type: framesConstants.SETBYID })
    }
  }, [id, dispatch])

  const deleteFrameHandler = async id => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item?',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(framesActions.deleteItem(id))
      },
    })
  }

  useEffect(() => {
    if (frameData) {
      const frameUrl = `${appConstants.IMAGES_URL}/frames/${frameData.url}`
      form.setFieldsValue({
        ...frameData,
        file: parseFileForSetFields(frameData.url, {
          url: frameUrl,
        }),
      })
      setFrame(frameUrl)
    }
  }, [form, frameData])

  const navigateToView = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToView,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: () => deleteFrameHandler(id),
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit, frameData])

  const normFile = e => {
    let file = e && e.fileList

    if (Array.isArray(e)) file = e

    const reader = new FileReader()
    reader.onload = () => setFrame(reader.result)
    if (e.fileList.length) {
      reader.readAsDataURL(file[0].originFileObj)
    } else {
      setFrame('')
    }

    return file
  }

  const onFinish = async values => {
    if (!values.position_y) values.position_y = 0
    if (!values.position_x) values.position_x = 0
    if (!values.qr_code_size) values.qr_code_size = 100
    if (!values.user_group_id) values.user_group_id = []

    await dispatch(framesActions.updateItemById(id, convertValuesToFormData(values)))
  }

  return (
    <NavContainer menu={navMenuParse}>
      {useGlobalLoader(
        <div>
          <p>Fields with * are required.</p>

          <Form
            {...formItemLayout}
            name="basic"
            form={form}
            onFinish={onFinish}
            scrollToFirstError
            onFieldsChange={() => setChange(true)}
          >
            <Form.Item
              name="status"
              label="Status"
              rules={[
                {
                  required: true,
                  message: 'Please select value!',
                },
              ]}
            >
              <Select options={convertDataForSelect(frameStatuses)} />
            </Form.Item>
            <Form.Item
              name="type"
              label="Type"
              rules={[
                {
                  required: true,
                  message: 'Please select value!',
                },
              ]}
            >
              <Select options={convertDataForSelect(frameTypes)} />
            </Form.Item>

            <Form.Item label="Customer Groups" name="user_group_id">
              <Select
                allowClear
                showSearch
                options={groups}
                filterOption={(input, option) =>
                  (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
                }
              />
            </Form.Item>

            <Form.Item
              label="Frame"
              name="file"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: true,
                  message: 'Please upload file!',
                },
              ]}
            >
              <Upload
                name="icon"
                beforeUpload={() => {
                  return false
                }}
                action=""
                listType="picture"
              >
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>

            <Form.Item label="QR-code x position(%)" name="position_x">
              <InputNumber step={1} min={0} max={100} defaultValue={0} />
            </Form.Item>
            <Form.Item label="QR-code y position(%)" name="position_y">
              <InputNumber step={1} min={0} max={100} defaultValue={0} />
            </Form.Item>
            <Form.Item label="QR-code size(%)" name="qr_code_size">
              <InputNumber step={1} min={0} max={100} defaultValue={100} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!changed}>
                Save
              </Button>
            </Form.Item>
          </Form>

          {frame && (
            <FramePreview
              qrCodeUrl={QrCode}
              qrCodeSize={qrCodeSize}
              positionX={positionX}
              positionY={positionY}
              frame={frame}
            />
          )}
        </div>,
      )}
    </NavContainer>
  )
}

export default EditItem
