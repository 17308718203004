import React, { useMemo } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { ManifestForm, RedoList } from './views'
import Content from '../../components/content'
import { useSelector } from 'react-redux'
import LoginPage from 'pages/login'
import QaLayout from './layout'

const QaSystem = () => {
  const match = useRouteMatch()

  const userId = useSelector(state => state.authentication?.user?.id)
  const token = JSON.parse(localStorage.getItem('user'))?.activkey || ''

  const authData = useMemo(() => !!(userId && token), [userId, token])

  return (
    <QaLayout>
      <Content>
        <Switch>
          <Route path={`${match.url}/auth/login`}>
            {authData ? <Redirect to={`${match.url}/manifest`} /> : <LoginPage />}
          </Route>

          <Route path={`${match.url}/manifest/view/:manifest_id`}>
            {authData ? <RedoList /> : <Redirect to={`${match.url}/auth/login`} />}
          </Route>

          <Route path={`${match.url}/manifest`}>
            {authData ? <ManifestForm /> : <Redirect to={`${match.url}/auth/login`} />}
          </Route>

          <Redirect to={match.url} />
        </Switch>
      </Content>
    </QaLayout>
  )
}

export default QaSystem
