const insertsConstants = {
  GETALL_REQUEST: 'INSERTS_GETALL_REQUEST',
  GETALL_SUCCESS: 'INSERTS_GETALL_SUCCESS',
  GETALL_FAILURE: 'INSERTS_GETALL_FAILURE',

  GETBYID_REQUEST: 'INSERTS_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'INSERTS_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'INSERTS_GETBYID_FAILURE',

  ADD_REQUEST: 'INSERTS_ADD_REQUEST',
  ADD_SUCCESS: 'INSERTS_ADD_SUCCESS',
  ADD_FAILURE: 'INSERTS_ADD_FAILURE',

  DELETE_REQUEST: 'INSERTS_DELETE_REQUEST',
  DELETE_SUCCESS: 'INSERTS_DELETE_SUCCESS',
  DELETE_FAILURE: 'INSERTS_DELETE_FAILURE',

  UPDATE_REQUEST: 'INSERTS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'INSERTS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'INSERTS_UPDATE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    list: { key: 'list', title: 'List Insert', url: '/inserts/list' },
    manage: { key: 'manage', title: 'Manage Insert', url: '/inserts' },
    create: { title: 'Create Insert', key: 'create', url: '/inserts/create' },
    edit: { key: 'edit', title: 'Update Insert', url: '' },
    delete: { key: 'delete', title: 'Delete Insert', url: '' },
    view: { key: 'view', title: 'View Insert', url: '' },
  },

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },

  insertStatus: [
    { value: 0, text: 'Not Active' },
    { value: 1, text: 'Active' },
  ],

  insertable: [
    { value: 'fully_insertable', text: 'Fully Insertable' },
    { value: 'partial', text: 'Partial' },
    { value: 'no', text: 'No' },
  ],

  viewItemKeyToLabel: {
    id: 'ID',
    user: 'User',
    status: 'Status',
    name: 'Name',
    insertable: 'Insertable',
    price: 'Price',
    quantity: 'Quantity',
    low_stock_threshold: 'Low Stock Threshold',
    client_notification_emails: 'Client Notification Emails',
  },

  listItemKeyToLabel: {
    id: 'ID',
    user: 'User',
    status: 'Status',
    name: 'Name',
    insertable: 'Insertable',
    price: 'Price',
    quantity: 'Quantity',
    low_stock_threshold: 'Low Stock Threshold',
  },
  advancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'Status', key: 'status' },
    { label: 'User', key: 'user_id' },
    { label: 'Name', key: 'name' },
    { label: 'Insertable', key: 'insertable' },
    { label: 'Price', key: 'price' },
  ],
}
export default insertsConstants
