const cardsConstants = {
  GETALL_REQUEST: 'CARDS_GETALL_REQUEST',
  GETALL_SUCCESS: 'CARDS_GETALL_SUCCESS',
  GETALL_FAILURE: 'CARDS_GETALL_FAILURE',

  GETBYID_REQUEST: 'CARDS_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'CARDS_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'CARDS_GETBYID_FAILURE',

  ADD_REQUEST: 'CARDS_ADD_REQUEST',
  ADD_SUCCESS: 'CARDS_ADD_SUCCESS',
  ADD_FAILURE: 'CARDS_ADD_FAILURE',

  DELETE_REQUEST: 'CARDS_DELETE_REQUEST',
  DELETE_SUCCESS: 'CARDS_DELETE_SUCCESS',
  DELETE_FAILURE: 'CARDS_DELETE_FAILURE',

  UPDATE_REQUEST: 'CARDS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CARDS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CARDS_UPDATE_FAILURE',

  GENERATE_PDF_REQUEST: 'CARDS_GENERATE_REQUEST',
  GENERATE_PDF_SUCCESS: 'CARDS_GENERATE_SUCCESS',
  GENERATE_PDF_FAILURE: 'CARDS_GENERATE_PDF_FAILURE',
  PDF_DOWNLOAD_PROGRESS: 'CARDS_PDF_DOWNLOAD_PROGRESS',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  cardStatuses: [
    { value: 0, text: 'Active' },
    { value: 1, text: 'Not Active' },
  ],

  cardOrientation: [
    { value: 'L', text: 'Landscape' },
    { value: 'P', text: 'Portrait' },
    { value: 'F', text: 'Flat' },
  ],

  orientationKeys: {
    L: 'Landscape',
    P: 'Portrait',
    F: 'Flat',
  },

  fullBleed: [
    { value: 0, text: 'NO' },
    { value: 1, text: 'YES' },
  ],

  cardOnHomePage: [
    { value: 0, text: 'NO' },
    { value: 1, text: 'YES' },
  ],

  cardTaxExempt: [
    { value: 0, text: 'NO' },
    { value: 1, text: 'YES' },
  ],

  cardCoverRestricted: [
    { value: 0, text: 'NO' },
    { value: 1, text: 'YES' },
  ],

  cardAvailableFree: [
    { value: 0, text: 'NO' },
    { value: 1, text: 'YES' },
  ],

  cardSupressReturnAddress: [
    { value: 0, text: 'NO' },
    { value: 1, text: 'YES' },
  ],

  navMenu: {
    list: { key: 'list', title: 'List Card', url: '/cards/list' },
    manage: { key: 'manage', title: 'Manage Card', url: '/cards' },
    create: { title: 'Create Card', key: 'create', url: '/cards/create' },
    view: { key: 'view', title: 'View Card', url: '' },
    delete: { key: 'delete', title: 'Delete Card', url: '' },
    images: { title: 'Images', key: 'images', url: '/images' },
    edit: { key: 'edit', title: 'Update Card', url: '' },
    pdf: { key: 'pdf_download', title: 'Save as PDF', url: '' },
    imposeCards: { key: 'impose_cards', title: 'Impose Cards (python)', url: '' },
    imposeCardsPython: {
      key: 'impose_cards_python',
      title: 'Impose Cards (python template)',
      url: '',
    },
    createWithSameSettings: {
      title: 'Create New Card with Same Settings',
      key: 'createWithSameSettings',
      url: '/cards/create-by-sample',
    },
  },

  marginsTooltipMessage: 'These fields affect the display of text',

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },
  listItemKeyToLabel: {
    image: '',
    id: 'ID',
    status: 'Status',
    name: 'Name',
    category: 'Category',
    cover: 'Cover',
    price: 'Price',
    orientation: 'Orientation',
    description: 'Description',
  },
  viewItemKeyToLabel: {
    id: 'ID',
    status: 'Status',
    name: 'Name',
    user_id: 'User`s Login',
    category: 'Category',
    full_bleed: 'Full Bleed',
    cover: 'Cover',
    half_inside: 'Half Inside',
    orientation: 'Orientation',
    price: 'Price',
    description: 'Description',
    notes: 'Notes',
    width: 'Character Width',
    height: 'Character Lines',
    quantity: 'Quantity',
    low_stock_threshold: 'Low Stock Threshold',
    available_free: 'Available Free',
    sort_no: 'Sort No',
    supress_return_address: 'Supress Return Address',
    date_created: 'Date Created',
    cover_image: 'Cover Image',
    half_inside_image: 'Half Inside',
  },

  fieldsNotNull: [
    'quantity',
    'available_free',
    'sort_no',
    'cover_restricted',
    'preview_margin_left',
    'preview_margin_top',
    'preview_margin_right',
    'preview_margin_bottom',
  ],

  fieldsToIgnore: [
    'character_count',
    'orientation',
    'closed_height',
    'closed_width',
    'open_height',
    'open_width',
    'margin_top',
    'margin_right',
    'margin_bottom',
    'margin_left',
    'envelope_height',
    'envelope_width',
    'envelope_font_size',
    'envelope_lines_between_addresses',
    'envelope_to_tabs',
    'envelope_margin_top',
    'envelope_margin_right',
    'envelope_margin_bottom',
    'envelope_margin_left',
  ],

  advancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'User id', key: 'user_id' },
    { label: 'Status', key: 'status_filter', type: 'select' },
    { label: 'Name', key: 'name' },
    { label: 'Category', key: 'category_id' },
    { label: 'Cover', key: 'cover' },
    { label: 'Price', key: 'price' },
    { label: 'Description', key: 'description' },
    { label: 'Character Width', key: 'width' },
    { label: 'Character Lines', key: 'height' },
    { label: 'Quantity', key: 'quantity' },
    { label: 'Available Free', key: 'available_free' },
    { label: 'Sort No', key: 'sort_no' },
    { label: 'Date Created', key: 'date_created*date' },
  ],

  defaultValueFields: {
    status: 0,
    home_card: 0,
    tax_exempt: 0,
    cover_restricted: 0,
    quantity: 0,
    available_free: 0,
    orientation: 'L',
    sort_no: 0,
    supress_return_address: 0,
    preview_margin_top: 0.0,
    preview_margin_right: 0.0,
    preview_margin_bottom: 0.0,
    preview_margin_left: 0.0,
  },
}
export default cardsConstants
