import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import v4 from 'uuid/v4'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import { signatureActions } from '../../../../_actions'
import { appConstants, signatureConstants } from '../../../../_constants'
import { getSignaturesRows, getPage, getTotalItems } from '_selectors/signatures.selector'

const { navMenu } = signatureConstants

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'User ID',
    dataIndex: 'user_id',
    key: 'user_id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Size Offset (Percent)',
    dataIndex: 'size_offset',
    key: 'size_offset',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Preview as Font',
    dataIndex: 'code',
    key: 'font_preview',
    hideFilter: true,
    render: code => (
      <span
        style={{
          fontFamily: 'HWSign',
          color: '#0040ac',
          fontSize: '2rem',
        }}
      >
        {code}
      </span>
    ),
  },
  {
    title: 'Preview as Image',
    dataIndex: 'preview',
    hideFilter: true,
    render: url => (
      <img height="40" src={`${appConstants.IMAGES_URL}/signatures/${url}`} alt={url} />
    ),
  },
]
const navMenuInit = [navMenu.create, navMenu.upload]

const ManageItems = ({ match }) => {
  const dispatch = useDispatch()

  const rows = useSelector(getSignaturesRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)
  const { superuser } = useSelector(state => state.authentication.user)

  // const fetchData = async () => {
  //   document.head.innerHTML += `<link href="${process.env.REACT_APP_API_URL}/api/v1/admin/signatures/getSignatureFont" rel="stylesheet" />`
  // }

  useEffect(() => {
    // fetchData()
    return () => {
      dispatch({ type: signatureConstants.SETALL })
    }
  }, [])

  const deleteItems = async id => {
    await dispatch(signatureActions.deleteItem(id))
  }

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  return (
    <NavContainer match={match} menu={navMenuInit}>
      <TableFilter
        deleteItems={deleteItems}
        columns={initColumns}
        data={dataSource}
        getDataAction={signatureActions.getAll}
        isEditOnId={!!superuser}
        showAdvancedSearch={false}
        showAdvancedButton={!!true}
      />
    </NavContainer>
  )
}

export default ManageItems
