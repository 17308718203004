import React from 'react'
import { Modal } from 'antd'

const ChangeCategoryConfirmationPopup = ({ isVisible, onSubmit, onCancel }) => {
  return (
    <Modal open={isVisible} onCancel={onCancel} onOk={onSubmit}>
      Card will no long be associated with this user. Continue?
    </Modal>
  )
}

export default ChangeCategoryConfirmationPopup
