import React from 'react'
import { Route, Switch } from 'react-router-dom'
// import 'antd/lib/style/index.less' // antd core styles
import './components/kit/vendors/antd/themes/default.less' // default theme antd components
import './components/kit/vendors/antd/themes/dark.less' // dark theme antd components
import './global.scss' // app & third-party component styles
// import './App.less'
import { render } from 'react-dom'
import { Provider } from 'react-redux'

import { ConnectedRouter } from 'connected-react-router'
import Localization from './localization'
import { history, store } from './_helpers'
import App from './app'

// configureFakeBackend();

const rootEl = document.getElementById('app')

render(
  <Provider store={store}>
    <Localization>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </ConnectedRouter>
    </Localization>
  </Provider>,
  rootEl,
)
