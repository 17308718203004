const notificationConstants = {
  GETALL_REQUEST: 'NOTIFICATION_GETALL_REQUEST',
  GETALL_SUCCESS: 'NOTIFICATION_SUCCESS',
  GETALL_FAILURE: 'NOTIFICATION_FAILURE',

  ADD_REQUEST: 'NOTIFICATION_ADD_REQUEST',
  ADD_SUCCESS: 'NOTIFICATION_ADD_SUCCESS',
  ADD_FAILURE: 'NOTIFICATION_ADD_FAILURE',

  GETBYID_REQUEST: 'NOTIFICATION_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'NOTIFICATION_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'NOTIFICATION_GETBYID_FAILURE',

  DELETE_REQUEST: 'NOTIFICATION_DELETE_REQUEST',
  DELETE_SUCCESS: 'NOTIFICATION_DELETE_SUCCESS',
  DELETE_FAILURE: 'NOTIFICATION_DELETE_FAILURE',

  UPDATE_REQUEST: 'NOTIFICATION_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'NOTIFICATION_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'NOTIFICATION_UPDATE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    list: { key: 'list', title: 'List Notification', url: '/notifications/list' },
    manage: { key: 'manage', title: 'Manage Notification', url: '/notifications' },
    create: { key: 'create', title: 'Create Notification', url: '/notifications/create' },
    edit: { key: 'edit', title: 'Update Notification', url: '' },
    delete: { key: 'delete', title: 'Delete Notification', url: '' },
    view: { key: 'view', title: 'View Notification', url: '' },
  },

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },

  checkBoxGroupStyle: {
    display: 'grid',
    gridGap: 20,
    maxHeight: '300px',
    border: '1px solid #e4e9f0',
    overflowY: 'scroll',
    padding: '10px 0 10px 10px',
  },

  listItemKeyToLabel: {
    id: 'ID',
    message: 'Message',
    color: 'Color',
    employee_id: 'Employee',
    status: 'Status',
    visible_to_all: 'Visible To All Authorized',
    visible_to_guest: 'Visible To Unauthorized',
  },
  viewItemKeyToLabel: {
    id: 'ID',
    message: 'Message',
    color: 'Color',
    employee_id: 'Employee',
    status: 'Status',
    visible_to_all: 'Visible To All Authorized',
    visible_to_guest: 'Visible To Unauthorized',
    created_at: 'Created At',
    updated_at: 'Updated At',
  },
  advancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'Message', key: 'message' },
    { label: 'Color', key: 'color_filter', type: 'select' },
    { label: 'Employee', key: 'employee_id' },
    { label: 'Status', key: 'status_filter', type: 'select' },
    { label: 'Visible To All Authorized', key: 'visible_to_all_filter', type: 'select' },
    { label: 'Visible To Unauthorized', key: 'visible_to_guest_filter', type: 'select' },
    { label: 'Starts At', key: 'starts_at*date' },
    { label: 'Ends At', key: 'ends_at*date' },
    { label: 'Created At', key: 'created_at*date' },
    { label: 'Updated At', key: 'updated_at*date' },
  ],
  notificationColor: [
    { value: 'RED', text: 'RED' },
    { value: 'GREEN', text: 'GREEN' },
  ],
  notificationStatuses: [
    { value: 'ACTIVE', text: 'ACTIVE' },
    { value: 'DRAFT', text: 'DRAFT' },
  ],
  visibleToAllAuthorized: [
    { value: 1, text: 'Yes' },
    { value: 0, text: 'No' },
  ],

  visibleToGuest: [
    { value: 1, text: 'Yes' },
    { value: 0, text: 'No' },
  ],
}

export default notificationConstants
