const labelsCreationConstants = {
  GET_READY_TO_SHIPPING_REQUEST: 'LABELS_CREATION_READY_TO_SHIPPING_REQUEST',
  GET_READY_TO_SHIPPING_SUCCESS: 'LABELS_CREATION_READY_TO_SHIPPING_SUCCESS',
  GET_READY_TO_SHIPPING_FAILURE: 'LABELS_CREATION_READY_TO_SHIPPING_FAILURE',

  GET_ARCHIVED_LABELS_REQUEST: 'LABELS_CREATION_ARCHIVED_LABELS_REQUEST',
  GET_ARCHIVED_LABELS_SUCCESS: 'LABELS_CREATION_ARCHIVED_LABELS_SUCCESS',
  GET_ARCHIVED_LABELS_FAILURE: 'LABELS_CREATION_ARCHIVED_LABELS_FAILURE',

  GET_SERVICE_OPTIONS_REQUEST: 'LABELS_CREATION_SERVICE_OPTIONS_REQUEST',
  GET_SERVICE_OPTIONS_SUCCESS: 'LABELS_CREATION_SERVICE_OPTIONS_SUCCESS',
  GET_SERVICE_OPTIONS_FAILURE: 'LABELS_CREATION_SERVICE_OPTIONS_FAILURE',
  RESET_SERVICE_OPTIONS: 'LABELS_CREATION_SERVICE_OPTIONS_RESET',

  GENERATE_LABELS_REQUEST: 'LABELS_CREATION_GENERATE_LABELS_REQUEST',
  GENERATE_LABELS_SUCCESS: 'LABELS_CREATION_GENERATE_LABELS_SUCCESS',
  GENERATE_LABELS_FAILURE: 'LABELS_CREATION_GENERATE_LABELS_FAILURE',

  STATUS_LABELS_V2_REQUEST: 'LABELS_CREATION_STATUS_LABELS_V2_REQUEST',
  STATUS_LABELS_V2_SUCCESS: 'LABELS_CREATION_STATUS_LABELS_V2_SUCCESS',
  STATUS_LABELS_V2_FAILURE: 'LABELS_CREATION_STATUS_LABELS_V2_FAILURE',

  PRINT_LABELS_REQUEST: 'LABELS_CREATION_PRINT_LABELS_REQUEST',
  PRINT_LABELS_SUCCESS: 'LABELS_CREATION_PRINT_LABELS_SUCCESS',
  PRINT_LABELS_FAILURE: 'LABELS_CREATION_PRINT_LABELS_FAILURE',

  COMPLETE_LABELS_REQUEST: 'LABELS_CREATION_COMPLETE_LABELS_REQUEST',
  COMPLETE_LABELS_SUCCESS: 'LABELS_CREATION_COMPLETE_LABELS_SUCCESS',
  COMPLETE_LABELS_FAILURE: 'LABELS_CREATION_COMPLETE_LABELS_FAILURE',

  CANCEL_LABELS_REQUEST: 'LABELS_CREATION_CANCEL_LABELS_REQUEST',
  CANCEL_LABELS_SUCCESS: 'LABELS_CREATION_CANCEL_LABELS_SUCCESS',
  CANCEL_LABELS_FAILURE: 'LABELS_CREATION_CANCEL_LABELS_FAILURE',

  deliveryByOptions: [
    { label: 'UPS', value: 'UPS' },
    { label: 'USPS', value: 'USPS' },
  ],

  packageTypeOptions: [
    { label: 'UNKNOWN', value: '00' },
    { label: '[01] UPS Letter', value: '01' },
    { label: '[02] Customer Supplied Package', value: '02' },
    { label: '[03] Tube', value: '03' },
    { label: '[04] PAK', value: '04' },
    { label: '[21] UPS Express Box', value: '21' },
    { label: '[24] UPS 25KG Box', value: '24' },
    { label: '[25] UPS 10KG Box', value: '25' },
    { label: '[30] Pallet', value: '30' },
    { label: '[2a] Small Express Box', value: '2a' },
    { label: '[2b] Medium Express Box', value: '2b' },
    { label: '[2c] Large Express Box', value: '2c' },
    { label: '[56] Flats', value: '56' },
    { label: '[57] Parcels', value: '57' },
    { label: '[58] BPM', value: '58' },
    { label: '[59] First Class', value: '59' },
    { label: '[60] Priority', value: '60' },
    { label: '[61] Machinables', value: '61' },
    { label: '[62] Irregulars', value: '62' },
    { label: '[63] Parcel Post', value: '63' },
    { label: '[64] BPM Parcel', value: '64' },
    { label: '[65] Media Mail', value: '65' },
    { label: '[66] BPM Flat', value: '66' },
    { label: '[67] Standard Flat', value: '67' },
  ],

  archiveAdvancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'User', key: 'user=>login' },
    { label: 'Card Name', key: 'card=>name' },
    { label: 'Rate Name', key: 'rate=>name' },
    { label: 'Count', key: 'cnt' },
    { label: 'Tracking Code', key: 'ups_shipment=>ext_id' },
    { label: 'Date Mailed', key: 'date_mailed*date' },
  ],

  remainingAdvancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'User', key: 'user=>login' },
    { label: 'Card', key: 'card=>name' },
    { label: 'Date Send', key: 'date_send*date' },
  ],
}

export default labelsCreationConstants
