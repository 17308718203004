import React, { useRef, useState, useEffect, useMemo } from 'react'
import { Button, Form, InputNumber } from 'antd'
import Draggable from 'react-draggable'
import lodash from 'lodash'
import { useDispatch } from 'react-redux'

import { CardContent } from './index.js'
import { impositionLayoutsConstants } from '_constants'
import { mmToPx, pxToInch, pxToMm } from '_helpers/convert'
import useDebounce from 'hooks/useDebounce.js'

const initialMatchLevels = {
  left: false,
  top: false,
  right: false,
  bottom: false,
}

export const initialNeighborDistances = {
  left: Infinity,
  top: Infinity,
  right: Infinity,
  bottom: Infinity,
}

const ImpositionDraggableItem = ({
  index,
  id,
  data,
  cardSize,
  paperSize,
  configId,
  scale,
  isRepeatMode,
  isRepeatedCard,
  isLocked,
  barcodeContainerSize,
  barcodeContainerPosition,
  checkNeighborRelations,
  cardSizeWithRotate,
  sectionBounds,
  setLockedCards,
}) => {
  const dispatch = useDispatch()
  const [controlledPosition, setControlledPosition] = useState({ x: data.x, y: data.y })
  const [isDragging, setIsDragging] = useState(false)
  const [matchedLevels, setMatchedLevels] = useState(initialMatchLevels)
  const [neighborDistances, setNeighborDistances] = useState(initialNeighborDistances)
  const [popoverOpen, setPopoverOpen] = useState(false)

  const debouncedPosition = useDebounce(controlledPosition, 100)

  const [popoverForm] = Form.useForm()

  const leftDistanceRef = useRef(null)
  const topDistanceRef = useRef(null)
  const rightDistanceRef = useRef(null)
  const bottomDistanceRef = useRef(null)

  useEffect(() => {
    return () => {
      setLockedCards(prev => prev.filter(item => item !== id))
    }
  }, [])

  useEffect(() => {
    setControlledPosition({
      x: data.x,
      y: data.y,
    })
  }, [data.x, data.y])

  const isSampleCard = useMemo(() => isRepeatMode && !isRepeatedCard, [
    isRepeatMode,
    isRepeatedCard,
  ])

  useEffect(() => {
    // update position of all repeated cards when sample card position is changed
    if (isSampleCard) {
      dispatch({
        type: impositionLayoutsConstants.UPDATE_REPEATED_CARDS,
        payload: { configId, pageWidth: paperSize.width },
      })
    }
  }, [debouncedPosition])

  const containerDirection = useMemo(() => {
    if (barcodeContainerPosition === 'left') return 'row'
    if (barcodeContainerPosition === 'top') return 'column'
    if (barcodeContainerPosition === 'right') return 'row-reverse'
    if (barcodeContainerPosition === 'bottom') return 'column-reverse'
    return 'column'
  }, [barcodeContainerPosition])

  const rotateStyle = useMemo(() => {
    const style = { transform: `rotate(${data.rotate}deg)`, position: 'absolute' }
    if (data.rotate === 270) {
      if (barcodeContainerPosition === 'right') {
        style.transformOrigin = 'top right'
        style.transform = `rotate(${data.rotate}deg) translateY(-100%)`
      } else if (barcodeContainerPosition === 'bottom') {
        style.transformOrigin = 'bottom left'
        style.transform = `rotate(${data.rotate}deg) translateY(100%)`
      } else {
        style.transformOrigin = 'top left'
        style.transform = `rotate(${data.rotate}deg) translateX(-100%)`
      }
    } else if (data.rotate === 90) {
      if (barcodeContainerPosition === 'right') {
        style.transformOrigin = 'top right'
        style.transform = `rotate(${data.rotate}deg) translateX(100%)`
      } else if (barcodeContainerPosition === 'bottom') {
        style.transformOrigin = 'bottom left'
        style.transform = `rotate(${data.rotate}deg) translateX(-100%)`
      } else {
        style.transformOrigin = 'top left'
        style.transform = `rotate(${data.rotate}deg) translateY(-100%)`
      }
    }
    return style
  }, [data.rotate, barcodeContainerPosition])

  const barcodeContainerStyle = useMemo(() => {
    if (barcodeContainerPosition === 'left')
      return {
        borderRight: '1px dashed black',
        left: `-${barcodeContainerSize.width}px`,
        padding: '0.2in 0.125in',
        lineHeight: '0.7',
        flexDirection: 'column',
      }
    if (barcodeContainerPosition === 'top')
      return {
        borderBottom: '1px dashed black',
        top: `-${barcodeContainerSize.height}px`,
        padding: '0.125in 0.2in',
        lineHeight: '1',
        flexDirection: 'row-reverse',
      }
    if (barcodeContainerPosition === 'right')
      return {
        borderLeft: '1px dashed black',
        right: `-${barcodeContainerSize.width}px`,
        padding: '0.2in 0.125in',
        lineHeight: '0.7',
        flexDirection: 'column',
      }
    return {
      borderTop: '1px dashed black',
      bottom: `-${barcodeContainerSize.height}px`,
      padding: '0.125in 0.2in',
      lineHeight: '1',
      flexDirection: 'row-reverse',
    }
  }, [barcodeContainerPosition, barcodeContainerSize])

  const barcodeStyle = useMemo(() => {
    if (barcodeContainerPosition === 'left' || barcodeContainerPosition === 'right') {
      return {
        height: '70%',
        width: '0.25in',
      }
    }
    // } else if (barcodeContainerPosition === 'top' || barcodeContainerPosition === 'bottom') {
    return {
      width: '70%',
      height: '0.25in',
    }
    // }
  }, [barcodeContainerPosition])

  const leftInnerDistanceStyle = useMemo(() => {
    if (leftDistanceRef.current) {
      const labelRect = leftDistanceRef.current.getBoundingClientRect()
      let style = { left: barcodeContainerPosition === 'left' ? -barcodeContainerSize.width : 0 }

      if (labelRect.width + 4 > neighborDistances.left * scale) {
        style = {
          ...style,
          transform: 'translate(15%, -50%)',
          color: 'white',
          zIndex: 3,
        }
      }
      return style
    }

    return {}
  }, [neighborDistances.left])

  const topInnerDistanceStyle = useMemo(() => {
    if (leftDistanceRef.current) {
      const labelRect = topDistanceRef.current.getBoundingClientRect()
      const style = { top: barcodeContainerPosition === 'top' ? -barcodeContainerSize.height : 0 }

      if (labelRect.height > neighborDistances.top * scale) {
        return {
          ...style,
          transform: 'translate(-50%, 15%)',
          color: 'white',
          zIndex: 3,
        }
      }
      return style
    }
    return {}
  }, [neighborDistances.top])

  const rightInnerDistanceStyle = useMemo(() => {
    if (rightDistanceRef.current) {
      const labelRect = rightDistanceRef.current.getBoundingClientRect()
      const style = {
        right: barcodeContainerPosition === 'right' ? -barcodeContainerSize.width : 0,
      }

      if (labelRect.width + 4 > neighborDistances.right * scale) {
        return {
          ...style,
          transform: 'translate(-15%, -50%)',
          color: 'white',
          zIndex: 3,
        }
      }
      return style
    }
    return {}
  }, [neighborDistances.right])

  const bottomInnerDistanceStyle = useMemo(() => {
    if (bottomDistanceRef.current) {
      const labelRect = bottomDistanceRef.current.getBoundingClientRect()
      const style = {
        bottom: barcodeContainerPosition === 'bottom' ? -barcodeContainerSize.height : 0,
      }

      if (labelRect.height > neighborDistances.bottom * scale) {
        return {
          ...style,
          transform: 'translate(-50%, -15%)',
          color: 'white',
          zIndex: 3,
        }
      }
      return style
    }
    return {}
  }, [neighborDistances.bottom])

  const elementActualSize = useMemo(() => {
    const elementSize = {
      height: cardSizeWithRotate.height,
      width: cardSizeWithRotate.width,
    }

    if (barcodeContainerPosition === 'left' || barcodeContainerPosition === 'right') {
      elementSize.width += Math.min(barcodeContainerSize.width, barcodeContainerSize.height)
    } else {
      elementSize.height += Math.min(barcodeContainerSize.width, barcodeContainerSize.height)
    }
    return elementSize
  }, [cardSizeWithRotate, barcodeContainerPosition, barcodeContainerSize])

  const randomColorForCard = useMemo(() => {
    return Math.floor(100000 + Math.random() * 900000)
  }, [])

  const draggableItemBounds = useMemo(() => {
    const bounds = {
      left: 0,
      top: 0,
      right: (sectionBounds?.[1] || paperSize.width) - cardSizeWithRotate.width,
      bottom: paperSize.height - cardSizeWithRotate.height,
    }

    if (barcodeContainerPosition === 'left') {
      bounds.left += barcodeContainerSize.width
    } else if (barcodeContainerPosition === 'top') {
      bounds.top += barcodeContainerSize.height
    } else if (barcodeContainerPosition === 'right') {
      bounds.right -= barcodeContainerSize.width
    } else if (barcodeContainerPosition === 'bottom') {
      bounds.bottom -= barcodeContainerSize.height
    }

    return bounds
  }, [paperSize, cardSizeWithRotate, barcodeContainerPosition, barcodeContainerSize, sectionBounds])

  const convertDistanceToMm = num => `${pxToMm(num).toFixed(1)}mm`

  const maxAllowedPosition = useMemo(() => {
    const barcodeReservedSpace = Math.min(barcodeContainerSize.width, barcodeContainerSize.height)
    const maxCoordinates = {
      left: 0,
      top: 0,
      right: paperSize.width,
      bottom: paperSize.height,
    }

    // if it is a repeat mode set section bounds
    if (isRepeatMode && sectionBounds) {
      const [left, right] = sectionBounds
      maxCoordinates.left = left
      maxCoordinates.right = right
    }

    if (barcodeContainerPosition === 'left') {
      maxCoordinates.left += barcodeReservedSpace
    } else if (barcodeContainerPosition === 'top') {
      maxCoordinates.top += barcodeReservedSpace
    } else if (barcodeContainerPosition === 'right') {
      maxCoordinates.right -= barcodeReservedSpace
    } else if (barcodeContainerPosition === 'bottom') {
      maxCoordinates.bottom -= barcodeReservedSpace
    }

    return maxCoordinates
  }, [
    isRepeatMode,
    isRepeatedCard,
    sectionBounds,
    barcodeContainerPosition,
    barcodeContainerSize,
    paperSize,
  ])

  const dragAnimationHandler = (e, position) => {
    requestAnimationFrame(() => onControlledDrag(position))
  }

  const onControlledDrag = position => {
    const { x, y } = position

    setControlledPosition({ x, y })
    setIsDragging(true)

    const { levelMatch, distances } = checkNeighborRelations(data.id, x, y)

    setMatchedLevels(levelMatch)
    setNeighborDistances(distances)
  }

  const checkPositionLimitations = () => {
    const updatedPosition = {}

    if (controlledPosition.x < maxAllowedPosition.left) {
      updatedPosition.x = maxAllowedPosition.left
    } else if (controlledPosition.y < maxAllowedPosition.top) {
      updatedPosition.y = maxAllowedPosition.top
    } else if (controlledPosition.x + cardSizeWithRotate.width > maxAllowedPosition.right) {
      updatedPosition.x = maxAllowedPosition.right - cardSizeWithRotate.width
    } else if (controlledPosition.y + cardSizeWithRotate.height > maxAllowedPosition.bottom) {
      updatedPosition.y = maxAllowedPosition.bottom - cardSizeWithRotate.height
    }

    // if some position needs to be updated - save new coordinates
    if (!lodash.isEmpty(updatedPosition)) {
      setControlledPosition(prev => ({
        ...prev,
        ...updatedPosition,
      }))

      saveElementPosition(updatedPosition)
    }
  }

  useEffect(() => {
    checkPositionLimitations()
  }, [maxAllowedPosition])

  const saveElementPosition = newPosition => {
    dispatch({
      type: impositionLayoutsConstants.UPDATE_ELEMENT_POSITION,
      payload: {
        configId,
        elementId: data.id,
        position: { ...controlledPosition, ...newPosition },
      },
    })
  }

  const onStop = () => {
    saveElementPosition()
    setMatchedLevels(initialMatchLevels)
    setNeighborDistances(initialNeighborDistances)

    setTimeout(() => {
      setIsDragging(false)
    }, 0)
  }

  const onDeleteItem = id => {
    dispatch({
      type: impositionLayoutsConstants.DELETE_CARD,
      payload: {
        configId,
        idToDelete: id,
      },
    })
  }

  useEffect(() => {
    if (popoverOpen) {
      const initialPositionMm = {
        x: pxToMm(controlledPosition.x).toFixed(1),
        y: pxToMm(controlledPosition.y).toFixed(1),
      }

      popoverForm.setFieldsValue({ ...initialPositionMm })
    }
  }, [popoverOpen])

  const togglePopoverHandler = v => setPopoverOpen(v)

  const popoverSavePositionHandler = values => {
    const updatedPosition = {
      x: mmToPx(values.x),
      y: mmToPx(values.y),
    }

    setPopoverOpen(false)
    setControlledPosition(updatedPosition)

    saveElementPosition(updatedPosition)
  }

  const togglePositionLock = () => {
    if (isLocked) {
      setLockedCards(prev => prev.filter(item => item !== id))
    } else {
      setLockedCards(prev => [...prev, id])
    }
  }

  const changePositionPopover = () => {
    return (
      <Form
        name={`popover_${configId}_form`}
        form={popoverForm}
        onFinish={popoverSavePositionHandler}
      >
        <Form.Item label="X" name="x">
          <InputNumber
            style={{ width: '100%' }}
            step={0.5}
            min={pxToMm(maxAllowedPosition.left).toFixed(1)}
            max={pxToMm(maxAllowedPosition.right).toFixed(1)}
          />
        </Form.Item>

        <Form.Item label="Y" name="y">
          <InputNumber
            style={{ width: '100%' }}
            step={0.5}
            min={pxToMm(maxAllowedPosition.top).toFixed(1)}
            max={pxToMm(maxAllowedPosition.bottom).toFixed(1)}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Ok
          </Button>
        </Form.Item>
      </Form>
    )
  }

  return (
    <Draggable
      bounds={draggableItemBounds}
      position={controlledPosition}
      onStart={() => setPopoverOpen(false)}
      onDrag={dragAnimationHandler}
      onStop={onStop}
      scale={+scale}
      cancel=".ant-popover, .actions-content"
      defaultClassName={
        isLocked ? 'react-draggable-container-disabled' : 'react-draggable-container'
      }
      disabled={isLocked || isRepeatedCard}
    >
      <div
        className="draggable-container"
        style={{
          flexDirection: containerDirection,
          height: cardSizeWithRotate.height,
          width: cardSizeWithRotate.width,
          zIndex: isDragging ? 9 : 1,
          opacity: isRepeatedCard ? 0.5 : 1,
        }}
      >
        {isDragging ? (
          <>
            <div
              className={`draggable-container__level top ${matchedLevels.top ? 'active' : ''}`}
              style={{
                top: barcodeContainerPosition === 'top' ? -barcodeContainerSize.height : 0,
              }}
            />
            <div
              className={`draggable-container__level left ${matchedLevels.left ? 'active' : ''}`}
              style={{
                left: barcodeContainerPosition === 'left' ? -barcodeContainerSize.width : 0,
              }}
            />
            <div
              className={`draggable-container__level right ${matchedLevels.right ? 'active' : ''}`}
              style={{
                right: barcodeContainerPosition === 'right' ? -barcodeContainerSize.width : 0,
              }}
            />
            <div
              className={`draggable-container__level bottom ${
                matchedLevels.bottom ? 'active' : ''
              }`}
              style={{
                bottom: barcodeContainerPosition === 'bottom' ? -barcodeContainerSize.height : 0,
              }}
            />

            <div
              className="distance-label left-distance"
              ref={leftDistanceRef}
              style={leftInnerDistanceStyle}
            >
              {convertDistanceToMm(neighborDistances.left)}
            </div>

            <div
              className="distance-label top-distance"
              ref={topDistanceRef}
              style={topInnerDistanceStyle}
            >
              {convertDistanceToMm(neighborDistances.top)}
            </div>

            <div
              className="distance-label right-distance"
              ref={rightDistanceRef}
              style={rightInnerDistanceStyle}
            >
              {convertDistanceToMm(neighborDistances.right)}
            </div>

            <div
              className="distance-label bottom-distance"
              ref={bottomDistanceRef}
              style={bottomInnerDistanceStyle}
            >
              {convertDistanceToMm(neighborDistances.bottom)}
            </div>
          </>
        ) : null}
        {barcodeContainerPosition ? (
          <div
            className="barcode-container"
            style={{
              width: barcodeContainerSize.width,
              height: barcodeContainerSize.height,
              ...barcodeContainerStyle,
            }}
          >
            <div className="barcode-container__barcode" style={barcodeStyle} />

            <div className="barcode-container__card-id">#####</div>
          </div>
        ) : null}
        <div
          className="card-background"
          style={{
            width: `${cardSize.width}px`,
            height: `${cardSize.height}px`,
            backgroundColor: isRepeatedCard ? 'gray' : `#${randomColorForCard}`,
            ...rotateStyle,
          }}
        />
        <CardContent
          id={index + 1}
          popoverOpen={popoverOpen}
          cardSize={{
            height: pxToInch(cardSize.height),
            width: pxToInch(cardSize.width),
          }}
          containerSize={{
            height: pxToInch(elementActualSize.height),
            width: pxToInch(elementActualSize.width),
          }}
          edges={{
            left: convertDistanceToMm(controlledPosition.x),
            top: convertDistanceToMm(controlledPosition.y),
            right: convertDistanceToMm(controlledPosition.x + cardSizeWithRotate.width),
            bottom: convertDistanceToMm(controlledPosition.y + cardSizeWithRotate.height),
          }}
          isLocked={isLocked}
          isRepeatMode={isRepeatMode}
          hideActions={isRepeatedCard}
          onDelete={() => onDeleteItem(data.id)}
          togglePopoverHandler={togglePopoverHandler}
          changePositionPopover={changePositionPopover}
          togglePositionLock={togglePositionLock}
        />
      </div>
    </Draggable>
  )
}

export default ImpositionDraggableItem
