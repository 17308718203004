import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { Button, Form, InputNumber, Modal, Select } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { merge } from 'lodash'
import NavContainer from '../../../../components/nav-container'
import { clientGroupActions, discountsRulesActions as drActions } from '../../../../_actions'
import getCurrentUrl from '../../../../_helpers/url-utils'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import { discountsRulesConstants } from '../../../../_constants'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import { getClientGroupsRows } from '../../../../_selectors/client-group.selector.js'

const { formItemLayout, navMenu } = discountsRulesConstants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.view, navMenu.delete, navMenu.manage]

const EditItem = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { id } = useParams()

  const [navMenuParse, setNavMenuParse] = useState([])
  const [changed, setChange] = useState(false)
  const {
    item: { discount_rule: rule },
  } = useSelector(state => state.discountRules)

  const clientGroups = useSelector(getClientGroupsRows)

  const view = getCurrentUrl(match.url)

  const clientGroupsSelect = useMemo(() => {
    return clientGroups?.map(group => ({ value: group.id, label: group.title }))
  }, [clientGroups])

  useEffect(() => {
    dispatch(clientGroupActions.getAll())
  }, [])

  useEffect(() => {
    dispatch(drActions.getItemById(id))
    return () => {
      dispatch({ type: discountsRulesConstants.SETBYID })
    }
  }, [id, dispatch])

  const navigateToView = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(drActions.deleteItem(id))
      },
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToView,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  useEffect(() => {
    form.setFieldsValue({ ...rule })
  }, [rule, form])

  const onFinish = async values => {
    const mRule = merge(rule, values)
    const convertedRule = convertFormValuesDeep(mRule)
    await dispatch(drActions.updateItemById(rule.id, convertedRule))
  }

  return (
    <NavContainer menu={navMenuParse}>
      {useGlobalLoader(
        <div>
          <p>Fields with * are required.</p>
          <Form
            {...formItemLayout}
            name="basic"
            form={form}
            onFinish={onFinish}
            onFieldsChange={() => setChange(true)}
            scrollToFirstError
          >
            <Form.Item label="User Group" name="user_group_id">
              <Select
                showSearch
                options={clientGroupsSelect}
                allowClear
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>

            <Form.Item
              label="Min Number"
              name="min_number"
              rules={[
                {
                  required: true,
                  message: 'Please input number!',
                },
              ]}
            >
              <InputNumber step={1} min={0} />
            </Form.Item>

            <Form.Item label="Discount" name="discount">
              <InputNumber step={1} min={0} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!changed}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>,
      )}
    </NavContainer>
  )
}

export default EditItem
