import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { Button, Form, Input, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import { templateCategoriesActions as tcActions } from '../../../../_actions'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { templateCategoriesConstants } from '../../../../_constants'

const { navMenu, formItemLayout } = templateCategoriesConstants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.view, navMenu.manage]

const EditItem = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const { id } = useParams()
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const [navMenuParse, setNavMenuParse] = useState([])
  const [changed, setChange] = useState(false)
  const {
    item: { template_category: templateCategory },
  } = useSelector(state => state.templateCategories)

  const view = getCurrentUrl(match.url)

  useEffect(() => {
    dispatch(tcActions.getItemById(id))
    return () => {
      dispatch({ type: templateCategoriesConstants.SETBYID })
    }
  }, [id, dispatch])

  const navigateToView = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(tcActions.deleteItem(id))
      },
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToView,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  useEffect(() => {
    form.setFieldsValue({
      ...templateCategory,
    })
  }, [form, templateCategory])

  const onFinish = values => dispatch(tcActions.updateItemById(id, values))

  return (
    <NavContainer menu={navMenuParse}>
      <div>
        <p>Fields with * are required.</p>
        <Form
          {...formItemLayout}
          name="basic"
          form={form}
          onFinish={onFinish}
          onFieldsChange={() => setChange(true)}
          scrollToFirstError
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input name!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={!changed}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </NavContainer>
  )
}

export default EditItem
