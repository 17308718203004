import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { clientGroupActions, discountsCodesActions as dcActions } from '../../../../_actions'
import NavContainer from '../../../../components/nav-container'
import ItemViewer from '../../../../components/item-viewer'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { discountsCodesConstants } from '../../../../_constants'
import { getClientGroupsRows } from '../../../../_selectors/client-group.selector.js'

const { navMenu, viewItemKeyToLabel, itemTypes } = discountsCodesConstants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.edit, navMenu.delete, navMenu.manage]

const ViewItem = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const { id } = useParams()

  const [navMenuParse, setNavMenuParse] = useState([])

  const {
    item: { discount_code: code },
  } = useSelector(state => state.discountCodes)

  const clientGroups = useSelector(getClientGroupsRows)

  const view = getCurrentUrl(match.url)

  useEffect(() => {
    dispatch(clientGroupActions.getAll())
  }, [])

  useEffect(() => {
    dispatch(dcActions.getItemById(id))
    return () => {
      dispatch({ type: discountsCodesConstants.SETBYID })
    }
  }, [id, dispatch])

  const onEditItem = () => {
    history.push({
      pathname: `${view}/edit/${id}`,
    })
  }

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(dcActions.deleteItem(id))
      },
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.edit.key:
          return {
            ...menu,
            url: onEditItem,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const viewItem = useMemo(() => {
    if (code) {
      return Object.keys(viewItemKeyToLabel).reduce((obj, sKey) => {
        let itemValue = code[sKey]
        if (sKey === 'user_group' && code.group_id)
          itemValue = (
            <Link to={`/client-groups/edit/${code.group_id}`}>
              {clientGroups?.find(group => group.id === code.group_id)?.title}
            </Link>
          )
        if (sKey === 'type') {
          itemValue = itemTypes.find(type => type.value === code.type)?.text
        }
        return {
          ...obj,
          [sKey]: itemValue,
        }
      }, {})
    }
    return {}
  }, [code])

  return (
    <NavContainer menu={navMenuParse}>
      <ItemViewer item={viewItem} keyToLabelMap={viewItemKeyToLabel} />
    </NavContainer>
  )
}

export default ViewItem
