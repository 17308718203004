import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import ItemViewer from '../../../../components/item-viewer'
import getCurrentUrl from '../../../../_helpers/url-utils'

import { prePaidConfigsActions } from '../../../../_actions'
import { prePaidConfigsConstants } from '../../../../_constants'

const { navMenu, viewItemKeyToLabel, statuses } = prePaidConfigsConstants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.edit, navMenu.delete, navMenu.manage]

const ViewItem = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const { id } = useParams()
  const dispatch = useDispatch()

  const {
    item: { prePaidPlan },
  } = useSelector(state => state.prePaidConfigs)

  const [navMenuParse, setNavMenuParse] = useState([])

  const view = getCurrentUrl(match.url)

  useEffect(() => {
    dispatch(prePaidConfigsActions.getItemById(id))
    return () => {
      dispatch({ type: prePaidConfigsConstants.SETBYID })
    }
  }, [id, dispatch])

  const onEditItem = () => {
    history.push({
      pathname: `${view}/edit/${id}`,
    })
  }

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(prePaidConfigsActions.deleteItem(id))
      },
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.edit.key:
          return {
            ...menu,
            url: onEditItem,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const viewItem = useMemo(() => {
    if (prePaidPlan) {
      return Object.keys(viewItemKeyToLabel).reduce((obj, sKey) => {
        let itemValue
        if (sKey === 'status') {
          itemValue =
            statuses.find(status => status.value.toString() === prePaidPlan.status.toString())
              ?.text || 'Unknown Status'
        } else if (sKey === 'discount') {
          itemValue = `${Number(prePaidPlan.discount).toFixed()}%`
        } else {
          itemValue = prePaidPlan[sKey]
        }
        return {
          ...obj,
          [sKey]: itemValue,
        }
      }, {})
    }
    return {}
  }, [prePaidPlan])

  return (
    <NavContainer menu={navMenuParse}>
      <ItemViewer item={viewItem} keyToLabelMap={viewItemKeyToLabel} />
    </NavContainer>
  )
}

export default ViewItem
