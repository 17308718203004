import axios from 'axios'
import apiUrl from './service'
import { v1 } from './api'

const getAll = async typeAction => {
  const result = await axios.get(`${apiUrl}/${v1}/jobs`, {
    params: { stopSpinner: typeAction },
  })
  return handleResponse(result)
}

const cleanAll = async (name, status, typeAction) => {
  const correctStatus = `?status=${status}`
  const result = await axios.post(`${apiUrl}/${v1}/jobs/${name}/cleanAll${correctStatus}`, {
    params: { stopSpinner: typeAction },
  })
  return handleResponse(result)
}

const remove = async (id, name, typeAction) => {
  const result = await axios.post(`${apiUrl}/${v1}/jobs/${name}/remove`, {
    params: { stopSpinner: typeAction },
    jobId: id,
  })
  return handleResponse(result)
}

const getByName = async (name, status, typeAction) => {
  const correctStatus = status === 'latest' ? '' : `?status=${status}`
  const result = await axios.get(`${apiUrl}/${v1}/jobs/${name}${correctStatus}`, {
    params: { stopSpinner: typeAction },
  })
  return handleResponse(result)
}

const retry = async (name, id) => {
  const result = await axios.post(`${apiUrl}/${v1}/jobs/${name}/retry`, {
    jobId: id,
  })
  return handleResponse(result)
}

const handleResponse = result => {
  return result.data
}

const cronJobsService = {
  getAll,
  getByName,
  cleanAll,
  remove,
  retry,
}

export default cronJobsService
