import React, { useCallback, useMemo, useState, useEffect } from 'react'
import OrdersTable from 'pages/batch-orders/views/list/orders-table'
import { Button, Collapse, Input } from 'antd'
import { BarcodeOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { cardsActions } from '_actions'
import { cardsConstants } from '_constants'

import upsLogo from '../../assets/image/ups.png'
import redoLogo from '../../assets/image/redo.png'
import giftCardLogo from '../../assets/image/gift-card.png'
import insertLogo from '../../assets/image/insert-card.png'
import deliveryLogo from '../../assets/image/truck-delivered-icon.png'

import generateUniqueCardId from '_helpers/generate-unique-card-id'

import './style.scss'

const { cardOrientation } = cardsConstants

const GenExtra = ({
  dItem: {
    cnt,
    id,
    name,
    taxonomy,
    closed_width,
    closed_height,
    sku,
    is_redo,
    is_ups,
    rate_id,
    is_confirmation,
    is_gift_card,
    is_insert,
  },
  dateSend,
  printedOrderCount,
  selectHandler,
}) => {
  const dispatch = useDispatch()

  const updId = useMemo(() => {
    return generateUniqueCardId(
      id,
      is_redo,
      is_ups,
      is_confirmation,
      rate_id,
      is_gift_card,
      is_insert,
      dateSend,
      printedOrderCount,
    )
  }, [
    is_redo,
    id,
    is_confirmation,
    is_ups,
    rate_id,
    is_gift_card,
    is_insert,
    dateSend,
    printedOrderCount,
  ])

  const { count: selectedRowsCount } =
    useSelector(state => state.batchOrders.selectedRows[updId]) || {}

  const [countOrders, setCountOrders] = useState(0)

  const ordersTotal = useMemo(() => parseInt(cnt, 10), [cnt])

  useEffect(() => {
    if (selectedRowsCount && selectedRowsCount !== countOrders) {
      setCountOrders(selectedRowsCount)
    }
  }, [selectedRowsCount])

  const handleInputChange = event => {
    setCountOrders(
      parseInt(event.currentTarget.value, 10) > ordersTotal
        ? ordersTotal
        : parseInt(event.currentTarget.value, 10),
    )
  }

  const handleDownloadPdfClick = event => {
    event.stopPropagation()
    dispatch(cardsActions.generatePdf(id, 0, name))
  }

  const handleDownloadPdfQRClick = event => {
    event.stopPropagation()
    dispatch(cardsActions.generatePdf(id, 1, name))
  }

  const downloadBar = useMemo(() => {
    if (Number(closed_width) <= 0 || Number(closed_height) <= 0) return null
    return (
      <div style={{ display: 'inline-flex', flexDirection: 'row' }}>
        <Button
          className="mx-1"
          onClick={handleDownloadPdfQRClick}
          icon={<BarcodeOutlined />}
          style={{ flex: 1 }}
        >
          {`Get PDF ${taxonomy !== 'CUSTOM' ? '(Non Custom)' : ''}`}
        </Button>
        <Button className="mx-1" onClick={handleDownloadPdfClick} style={{ flex: 1 }}>
          {`Get PDF ${taxonomy !== 'CUSTOM' ? '(Non Custom)' : ''}`}
        </Button>
      </div>
    )
  }, [taxonomy, closed_width, closed_height])

  const handleSelectAll = event => {
    const updId = generateUniqueCardId(
      id,
      is_redo,
      is_ups,
      is_confirmation,
      rate_id,
      is_gift_card,
      is_insert,
      dateSend,
      printedOrderCount,
    )

    const selectAllOrUnselect = selectedRowsCount === ordersTotal ? 0 : ordersTotal
    setCountOrders(selectAllOrUnselect)
    selectHandler(event, updId, selectAllOrUnselect)
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      selectHandler(event, updId, countOrders)
    }
  }

  return (
    <div className="px-1" onMouseUp={e => e.stopPropagation()}>
      {sku && <p>{sku}</p>}
      <div style={{ display: 'flex' }}>
        {downloadBar}
        <div style={{ display: 'inline-flex', height: 'fit-content' }}>
          <Button className="mx-1" type="primary" onClick={handleSelectAll}>
            {selectedRowsCount === ordersTotal ? 'Unselect All' : 'Select All'}
          </Button>
          {` or `}
          <Button
            className="mx-1"
            type="primary"
            onClick={e => selectHandler(e, updId, countOrders)}
          >
            Select
          </Button>
          <Input
            className="mx-1"
            style={{ width: '70px' }}
            value={countOrders}
            onClick={event => {
              event.stopPropagation()
            }}
            type="number"
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            max={ordersTotal}
            min={0}
          />{' '}
          orders
        </div>
      </div>
    </div>
  )
}

const GroupedOrdersCollapse = ({
  activePanelList,
  groups,
  selectHandler,
  onChange,
  dateSend,
  isPrintedList,
}) => {
  const { selectedRows, printedOrderCount } = useSelector(state => state.batchOrders)
  const { orientation: currentOrientation } = useSelector(state => state.batchOrders)

  const orientationLabel = orientation =>
    cardOrientation.find(item => item.value === orientation)?.text || ''

  const cardLabel = useCallback(card => {
    if (
      card.is_confirmation ||
      card.is_ups ||
      card.is_redo ||
      card?.is_gift_card ||
      card?.is_insert
    ) {
      return (
        <span>
          {!!card.is_redo && <img src={redoLogo} width={45} style={{ marginRight: '.2rem' }} />}
          {!!card.is_confirmation && (
            <img src={deliveryLogo} width={45} style={{ marginRight: '.2rem' }} />
          )}
          {!!card.is_gift_card && (
            <img src={giftCardLogo} width={45} style={{ marginRight: '.2rem' }} />
          )}
          {!!card.is_insert && <img src={insertLogo} width={40} style={{ marginRight: '.2rem' }} />}
          {!!card.is_ups && <img src={upsLogo} width={30} style={{ marginRight: '.2rem' }} />}
          {card.rate_name && <span style={{ color: 'green' }}> ({card.rate_name}) </span>}
          &nbsp; {card.name}: {card.cnt} &nbsp;
          <span style={{ color: 'black' }}>({orientationLabel(card.orientation)})</span>
        </span>
      )
    }

    return (
      <span>
        {card.name}: {card.cnt} &nbsp;
        <span style={{ color: 'black' }}>({orientationLabel(card.orientation)})</span>
      </span>
    )
  }, [])

  const groupsList = useMemo(() => {
    return groups.map(card => {
      const key = `${card.id}_${card.is_redo ?? 0}${card.is_ups ?? 0}${card.is_confirmation ??
        0}${card.rate_id ?? 0}${card.is_gift_card ?? 0}${card.is_insert ?? 0}${dateSend || ''}${
        isPrintedList ? printedOrderCount : 0
      }`

      let isImpositionWarning = false

      if (currentOrientation) {
        const isCurrentOrientationFlat = currentOrientation === 'F'

        isImpositionWarning =
          (isCurrentOrientationFlat && card.orientation !== 'F') ||
          (!isCurrentOrientationFlat && card.orientation === 'F')
      }

      return {
        key,
        label: cardLabel(card),
        className:
          isImpositionWarning && typeof printedOrderCount === 'number'
            ? 'collapse-background-row'
            : '',
        children: (
          <OrdersTable
            cardId={key}
            is_redo={card.is_redo}
            deliveryConfirmation={card.is_confirmation}
            is_ups={card.is_ups}
            is_mixed={card.is_mixed}
            rateId={card.rate_id}
            is_gift_card={card.is_gift_card}
            is_insert={card.is_insert}
            dateSend={dateSend}
            isCardPrinted={card.is_printed}
            isPrintedList={isPrintedList}
          />
        ),
        extra: (
          <GenExtra
            dItem={card}
            selectHandler={selectHandler}
            dateSend={dateSend}
            printedOrderCount={isPrintedList ? printedOrderCount : null}
          />
        ),
      }
    })
  }, [groups, selectHandler, dateSend, isPrintedList, selectedRows, currentOrientation])

  return (
    <Collapse
      activeKey={activePanelList}
      onChange={onChange}
      className="batch-orders-panel"
      items={groupsList}
    />
  )
}

export default GroupedOrdersCollapse
