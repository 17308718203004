import { clientGroupConstants } from '../_constants'

const initialState = {
  items: [],
  item: {
    group: {},
    categories: [],
  },
}

function clientGroups(state = initialState, { type, payload }) {
  switch (type) {
    case clientGroupConstants.GETALL_SUCCESS:
      return { ...state, items: payload, item: {} }
    case clientGroupConstants.GETBYID_SUCCESS:
      return { ...state, item: payload }
    case clientGroupConstants.DELETE_SUCCESS:
      return { ...state }
    case clientGroupConstants.UPDATE_SUCCESS:
      return { ...state, items: state.items }
    case clientGroupConstants.ADD_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          total: state.items.total + 1,
        },
      }
    case clientGroupConstants.SETBYID:
      return {
        ...state,
        item: {
          group: {},
          categories: [],
        },
      }
    case clientGroupConstants.SETALL:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default clientGroups
