import { impositionLayoutsConstants } from '../_constants'
import { impositionLayoutsService } from '../_services'
import { history } from '../_helpers'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: impositionLayoutsConstants.GETALL_REQUEST })
  try {
    const impositions = await impositionLayoutsService.getAll(params, typeAction)
    dispatch({
      type: impositionLayoutsConstants.GETALL_SUCCESS,
      payload: impositions.data,
    })
  } catch (error) {
    dispatch({
      type: impositionLayoutsConstants.GETALL_FAILURE,
      payload: error.toString(),
    })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: impositionLayoutsConstants.GETBYID_REQUEST })

  try {
    const imposition = await impositionLayoutsService.getItemById(id)
    dispatch({ type: impositionLayoutsConstants.GETBYID_SUCCESS, payload: imposition.data })
  } catch (error) {
    dispatch({ type: impositionLayoutsConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const addItem = value => async dispatch => {
  dispatch({ type: impositionLayoutsConstants.ADD_REQUEST })

  try {
    const imposition = await impositionLayoutsService.addItem({ value })
    dispatch({
      type: impositionLayoutsConstants.ADD_SUCCESS,
      payload: { ...value, id: imposition.id },
    })
    history.push(`/imposition-layouts/view/${imposition.id}`)
  } catch (error) {
    dispatch({ type: impositionLayoutsConstants.ADD_FAILURE, payload: error.toString() })
  }
}

const updateItemById = (id, value) => async dispatch => {
  dispatch({ type: impositionLayoutsConstants.UPDATE_REQUEST })

  try {
    const updated = await impositionLayoutsService.updateItemById(id, value)
    dispatch({
      type: impositionLayoutsConstants.UPDATE_SUCCESS,
      payload: updated?.message?.toString(),
    })
    history.push(`/imposition-layouts/view/${id}`)
  } catch (error) {
    dispatch({ type: impositionLayoutsConstants.UPDATE_FAILURE, payload: error.toString() })
  }
}

const deleteItem = id => async dispatch => {
  dispatch({ type: impositionLayoutsConstants.DELETE_REQUEST })
  try {
    await impositionLayoutsService.deleteItem(id)
    history.push(`/imposition-layouts`)
    dispatch({ type: impositionLayoutsConstants.DELETE_SUCCESS, payload: id })
  } catch (error) {
    dispatch({
      type: impositionLayoutsConstants.DELETE_FAILURE,
      payload: error.toString(),
    })
  }
}

// CONFIGS

const getImpositionConfigs = id => async dispatch => {
  dispatch({ type: impositionLayoutsConstants.GET_IMPOSITION_CONFIGS_REQUEST })

  try {
    const configs = await impositionLayoutsService.getImpositionConfigs(id)
    dispatch({
      type: impositionLayoutsConstants.GET_IMPOSITION_CONFIGS_SUCCESS,
      payload: configs.data.rows,
    })
  } catch (error) {
    dispatch({
      type: impositionLayoutsConstants.GET_IMPOSITION_CONFIGS_FAILURE,
      payload: error.toString(),
    })
  }
}

const createConfig = payload => async dispatch => {
  dispatch({ type: impositionLayoutsConstants.CREATE_CONFIG_REQUEST })

  try {
    await impositionLayoutsService.createConfig(payload)
    dispatch({
      type: impositionLayoutsConstants.CREATE_CONFIG_SUCCESS,
      payload: { dimensionId: payload.dimension_id },
    })
  } catch (error) {
    dispatch({ type: impositionLayoutsConstants.CREATE_CONFIG_FAILURE, payload: error.toString() })
  }
}

const updateConfigById = (id, payload) => async dispatch => {
  dispatch({ type: impositionLayoutsConstants.UPDATE_CONFIG_REQUEST })

  try {
    const updated = await impositionLayoutsService.updateConfigById(id, payload)
    dispatch({
      type: impositionLayoutsConstants.UPDATE_CONFIG_SUCCESS,
      payload: { updatedId: +updated.id },
    })
  } catch (error) {
    dispatch({ type: impositionLayoutsConstants.UPDATE_CONFIG_FAILURE, payload: error.toString() })
  }
}

const impositionLayoutsActions = {
  getAll,
  getItemById,
  addItem,
  updateItemById,
  deleteItem,
  getImpositionConfigs,
  createConfig,
  updateConfigById,
}

export default impositionLayoutsActions
