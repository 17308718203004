import { categoriesConstants } from '../_constants'

const initialState = {
  items: [],
  item: {
    category: {},
    groups: [],
  },
}

function categories(state = initialState, { type, payload }) {
  switch (type) {
    case categoriesConstants.GETALL_SUCCESS:
      return { ...state, items: payload, item: {} }
    case categoriesConstants.GETBYID_SUCCESS:
      return { ...state, item: payload }
    case categoriesConstants.DELETE_SUCCESS:
      return { ...state }
    case categoriesConstants.UPDATE_SUCCESS:
      return { ...state, items: state.items }
    case categoriesConstants.ADD_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          total: state.items.total + 1,
        },
      }
    case categoriesConstants.SETBYID:
      return {
        ...state,
        item: {
          category: {},
          groups: [],
        },
      }
    case categoriesConstants.SETALL:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default categories
