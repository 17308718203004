import { mailsConstants } from '../_constants'

const initialState = {
  items: [],
}

function mails(state = initialState, { type, payload }) {
  switch (type) {
    case mailsConstants.GETALL_SUCCESS:
      return { ...state, items: payload }
    case mailsConstants.DELETE_SUCCESS:
      return { ...state }
    case mailsConstants.SETALL:
      return {
        ...state,
        items: [],
      }
    case mailsConstants.RESEND_SUCCESS:
      return { ...state, items: state.items }
    default:
      return state
  }
}

export default mails
