import { taxCodesConstants } from '../_constants'
import { taxcodesService } from '../_services'
import { history } from '../_helpers'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: taxCodesConstants.GETALL_REQUEST })
  try {
    const taxCodes = await taxcodesService.getAll(params, typeAction)
    dispatch({ type: taxCodesConstants.GETALL_SUCCESS, payload: taxCodes.data })
  } catch (error) {
    dispatch({ type: taxCodesConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: taxCodesConstants.GETBYID_REQUEST })

  try {
    const taxCode = await taxcodesService.getItemById(id)
    dispatch({ type: taxCodesConstants.GETBYID_SUCCESS, payload: taxCode.data })
  } catch (error) {
    dispatch({ type: taxCodesConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const updateItemById = (id, value) => async dispatch => {
  dispatch({ type: taxCodesConstants.UPDATE_REQUEST })

  try {
    const updated = await taxcodesService.updateItemById(id, value)
    dispatch({ type: taxCodesConstants.UPDATE_SUCCESS, payload: updated?.message?.toString() })
    history.push(`/taxcodes/view/${id}`)
  } catch (error) {
    dispatch({ type: taxCodesConstants.UPDATE_FAILURE, payload: error.toString() })
  }
}

const taxCodesActions = {
  getAll,
  getItemById,
  updateItemById,
}

export default taxCodesActions
