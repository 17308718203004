import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Checkbox, DatePicker, Form, Input, Select } from 'antd'
import moment from 'moment'
import NavContainer from '../../../../components/nav-container'
import { clientGroupActions, notificationActions } from '../../../../_actions'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import { notificationConstants } from '../../../../_constants'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import AdvancedCheckboxList from 'components/advanced-checkbox-list'
import { getClientGroupsRows } from '_selectors/client-group.selector'

const { notificationStatuses, notificationColor, navMenu, formItemLayout } = notificationConstants

const navMenuInit = [navMenu.list, navMenu.manage]

const CreateItem = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const groups = useSelector(getClientGroupsRows)

  const clientGroups = useMemo(() => groups?.map(e => ({ value: e.id, label: e.title })), [groups])

  const plainStatuses = useMemo(
    () => notificationStatuses.map(status => ({ value: status.value, label: status.text })),
    [notificationStatuses],
  )

  const plainColors = useMemo(
    () => notificationColor.map(status => ({ value: status.value, label: status.text })),
    [notificationColor],
  )

  useEffect(() => {
    dispatch(clientGroupActions.getAll())
  }, [])

  function range(start, end) {
    const result = []
    for (let i = start; i < end; i++) {
      result.push(i)
    }
    return result
  }

  function disabledDateTime() {
    return {
      disabledMinutes: () => range(1, 60),
      disabledSeconds: () => range(1, 60),
    }
  }

  const onFinish = async values => {
    const newValues = {
      ...values,
      starts_at: values.starts_at?.format('YYYY-MM-DD hh:mm:ss'),
      ends_at: values.ends_at?.format('YYYY-MM-DD hh:mm:ss'),
    }

    const isAfter = moment(newValues.starts_at).isAfter(newValues.ends_at)

    if (isAfter && !!newValues.starts_at) {
      return form.setFields([
        {
          name: 'ends_at',
          errors: ['End date is lower than start date'],
        },
      ])
    }

    const convertedNotification = convertFormValuesDeep(newValues)
    await dispatch(notificationActions.addItem(convertedNotification))

    return form.setFields([])
  }
  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      {useGlobalLoader(
        <Form {...formItemLayout} name="basic" form={form} onFinish={onFinish} scrollToFirstError>
          <Form.Item
            label="Message"
            name="message"
            rules={[
              {
                required: true,
                message: 'Please input message!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Color" name="color" initialValue="GREEN">
            <Select options={plainColors} />
          </Form.Item>

          <Form.Item label="Status" name="status" initialValue="ACTIVE">
            <Select options={plainStatuses} />
          </Form.Item>

          <Form.Item label="Starts At" name="starts_at">
            <DatePicker disabledTime={disabledDateTime} showTime={{ format: 'HH:mm' }} />
          </Form.Item>

          <Form.Item label="Ends At" name="ends_at">
            <DatePicker disabledTime={disabledDateTime} showTime={{ format: 'HH:mm' }} />
          </Form.Item>

          <Form.Item
            label="Visible To Unauthorized"
            name="visible_to_guest"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox name="visible_to_guest" />
          </Form.Item>

          <Form.Item
            label="Visible To All Authorized"
            name="visible_to_all"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox name="visible_to_all" />
          </Form.Item>

          <AdvancedCheckboxList
            formItemProps={{
              label: 'Customer Groups',
              name: 'user_groups',
              valuePropName: 'value',
            }}
            buttonText="Add Customer Group"
            options={clientGroups}
            form={form}
          />

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>,
      )}
    </NavContainer>
  )
}

export default CreateItem
