import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'

import { Button, Form, Input, Layout, Space } from 'antd'
import { userActions } from '_actions'
import Footer from '../../../components/cleanui/layout/Footer'

const { Content } = Layout

const ChangePassword = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const history = useHistory()
  const { hash } = useParams()

  const moveToLogin = () => {
    history.push('/login')
  }

  const onFinish = async values => {
    const valuesKey = Object.keys(values)
    const error = []

    if (values.password !== values.passwordConfirmation)
      error.push({ name: 'passwordConfirmation', errors: ['Retype Password is incorrect'] })

    valuesKey.forEach(key => {
      if (values[key].length < 4) {
        error.push({ name: key, errors: ['Password is too short (minimum is 4 characters)'] })
      }
    }, {})
    if (error.length) return form.setFields(error)
    await dispatch(userActions.restorePassword(values, hash))
    return form.setFields(error)
  }

  return (
    <Layout>
      <Content>
        <div className="container col-m-6 col-md-offset-3 container-login-form">
          <h2 className="text-center my-lg-2 my-sm-2 my-md-2">Restore Password</h2>
          <Form name="Reset" form={form} onFinish={onFinish} layout="vertical" scrollToFirstError>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Password cannot be blank!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Retype Password"
              name="passwordConfirmation"
              rules={[
                {
                  required: true,
                  message: 'Retype Password cannot be blank!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Restore Password
                </Button>
                <Button onClick={moveToLogin} style={{ background: '#23bc23', color: '#fff' }}>
                  LogIn
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </Content>
      <Footer />
    </Layout>
  )
}

export default ChangePassword
