import { cardImagesConstants } from '../_constants'
import { cardImagesService } from '../_services'
import { history } from '../_helpers'

const getAll = (params, typeAction, id) => async dispatch => {
  const paramsWithId = {
    ...params,
    where: {
      ...params.where,
      card_id: id,
    },
  }

  const requestParams = id ? paramsWithId : params

  dispatch({ type: cardImagesConstants.GETALL_REQUEST })
  try {
    const cardImages = await cardImagesService.getAll(requestParams, typeAction)
    dispatch({ type: cardImagesConstants.GETALL_SUCCESS, payload: cardImages.data })
  } catch (error) {
    dispatch({ type: cardImagesConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const addItem = value => async dispatch => {
  dispatch({ type: cardImagesConstants.ADD_REQUEST })

  try {
    const cardImage = await cardImagesService.addItem(value)
    dispatch({ type: cardImagesConstants.ADD_SUCCESS, payload: { ...value, id: cardImage.id } })
    history.push(`/card-images/view/${cardImage.id}`)
  } catch (error) {
    dispatch({ type: cardImagesConstants.ADD_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: cardImagesConstants.GETBYID_REQUEST })

  try {
    const cardImage = await cardImagesService.getItemById(id)
    dispatch({ type: cardImagesConstants.GETBYID_SUCCESS, payload: cardImage.data })
  } catch (error) {
    dispatch({ type: cardImagesConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const deleteItem = id => async dispatch => {
  dispatch({ type: cardImagesConstants.DELETE_REQUEST })
  try {
    await cardImagesService.deleteItem(id)
    history.push(`/card-images`)
    dispatch({ type: cardImagesConstants.DELETE_SUCCESS, payload: id })
  } catch (error) {
    dispatch({ type: cardImagesConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const updateItemById = (id, value) => async dispatch => {
  dispatch({ type: cardImagesConstants.UPDATE_REQUEST })

  try {
    const updated = await cardImagesService.updateItemById(id, value)
    dispatch({ type: cardImagesConstants.UPDATE_SUCCESS, payload: updated?.message?.toString() })
    history.push(`/card-images/view/${id}`)
  } catch (error) {
    dispatch({ type: cardImagesConstants.UPDATE_FAILURE, payload: error.toString() })
  }
}

const cardImagesActions = {
  getAll,
  getItemById,
  updateItemById,
  deleteItem,
  addItem,
}

export default cardImagesActions
