import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Checkbox, Form, InputNumber, Select } from 'antd'
import NavContainer from '../../../../components/nav-container'
import { denominationActions, giftCardActions } from '../../../../_actions'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import { denominationConstants } from '../../../../_constants'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import { getGiftCardsRows } from '../../../../_selectors/gift-cards.selector.js'

const { formItemLayout, navMenu } = denominationConstants

const navMenuInit = [navMenu.list, navMenu.manage]

const CreateItem = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const giftCards = useSelector(getGiftCardsRows)

  useEffect(() => {
    dispatch(giftCardActions.getAll({ order: { key: 'name', order: 'ASC' } }))
  }, [])

  const giftCardsSelect = useMemo(() => {
    return giftCards?.map(card => ({ value: card.id, label: card.name }))
  }, [giftCards])

  const onFinish = async values => {
    const convertedDenomination = convertFormValuesDeep(values)
    await dispatch(denominationActions.addItem(convertedDenomination))
  }

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      {useGlobalLoader(
        <Form {...formItemLayout} name="basic" form={form} onFinish={onFinish} scrollToFirstError>
          <Form.Item
            label="Gift Card"
            name="gcard_id"
            rules={[
              {
                required: true,
                message: 'Please select card!',
              },
            ]}
          >
            <Select options={giftCardsSelect} />
          </Form.Item>

          <Form.Item
            label="Nominal"
            name="nominal"
            rules={[
              {
                required: true,
                message: 'Please input nominal!',
              },
            ]}
          >
            <InputNumber step={0.01} min={0.0} />
          </Form.Item>

          <Form.Item
            label="Price"
            name="price"
            rules={[
              {
                required: true,
                message: 'Please input price!',
              },
            ]}
          >
            <InputNumber step={0.01} min={0.0} />
          </Form.Item>

          <Form.Item label="Active" name="active" initialValue valuePropName="checked">
            <Checkbox />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>,
      )}
    </NavContainer>
  )
}

export default CreateItem
