import { impositionHelpers } from '_helpers'
import { impositionLayoutsConstants } from '../_constants'

const initialState = {
  items: null,
  item: null,
  configs: null,
}

function impositionLayouts(state = initialState, { type, payload }) {
  switch (type) {
    case impositionLayoutsConstants.GETALL_SUCCESS:
      return { ...state, items: payload }
    case impositionLayoutsConstants.GETBYID_SUCCESS:
      return { ...state, item: payload }
    case impositionLayoutsConstants.DELETE_SUCCESS:
      return { ...state }
    case impositionLayoutsConstants.UPDATE_SUCCESS:
      return { ...state, items: state.items }
    case impositionLayoutsConstants.ADD_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          total: state.items.total + 1,
        },
      }

    // ----- CONFIGS -----
    case impositionLayoutsConstants.GET_IMPOSITION_CONFIGS_SUCCESS:
      const parsedConfigs = payload.map(item => ({ ...item, config: JSON.parse(item.config) }))
      const configsWithConvertedCoordinates = impositionHelpers.convertInchCoordinates(
        parsedConfigs,
      )

      return {
        ...state,
        configs: configsWithConvertedCoordinates,
      }

    case impositionLayoutsConstants.CREATE_EMPTY_CONFIG:
      return {
        ...state,
        configs: [...state.configs, payload.emptyConfigData],
      }

    case impositionLayoutsConstants.UPDATE_ELEMENT_POSITION:
      const updatedConfigsAfterMove = state.configs.map(configData => {
        if (configData.dimension_id === payload.configId) {
          const updatedFrontElements = configData.config.front.map(card => {
            if (card.type === 'image' && card.id === payload.elementId) {
              return { ...card, x: payload.position.x, y: payload.position.y }
            }
            return card
          })
          const updatedConfig = { ...configData.config, front: updatedFrontElements }
          const updatedConfigData = { ...configData, config: updatedConfig, edited: true }

          return updatedConfigData
        }
        return configData
      })

      return {
        ...state,
        configs: updatedConfigsAfterMove,
      }

    case impositionLayoutsConstants.ADD_NEW_CARD:
      const updatedConfigsAfterAdd = state.configs.map(configData => {
        if (configData.dimension_id === payload.configId) {
          const updatedFrontElements = [...configData.config.front, payload.newCard]
          const updatedConfig = { ...configData.config, front: updatedFrontElements }
          const updatedConfigData = { ...configData, config: updatedConfig, edited: true }

          return updatedConfigData
        }
        return configData
      })

      return {
        ...state,
        configs: updatedConfigsAfterAdd,
      }

    case impositionLayoutsConstants.DELETE_CARD:
      const updatedConfigsAfterDelete = state.configs.map(configData => {
        if (configData.dimension_id === payload.configId) {
          const updatedFrontElements = configData.config.front.filter(
            card => card.id !== payload.idToDelete,
          )
          const updatedConfig = { ...configData.config, front: updatedFrontElements }
          const updatedConfigData = { ...configData, config: updatedConfig, edited: true }

          return updatedConfigData
        }
        return configData
      })

      return {
        ...state,
        configs: updatedConfigsAfterDelete,
      }

    case impositionLayoutsConstants.UPDATE_ELEMENT_ROTATE:
      const updatedConfigsAfterRotate = state.configs.map(configData => {
        if (configData.dimension_id === payload.configId) {
          const updatedFrontElements = configData.config.front.map(element => {
            if (element.type === 'image') {
              return { ...element, rotate: +payload.rotate }
            }

            return element
          })
          const updatedConfig = {
            ...configData.config,
            front: updatedFrontElements,
            card_rotate: +payload.rotate,
          }
          const updatedConfigData = {
            ...configData,
            config: updatedConfig,
            edited: true,
          }

          return updatedConfigData
        }
        return configData
      })

      return {
        ...state,
        configs: updatedConfigsAfterRotate,
      }

    case impositionLayoutsConstants.CHANGE_BARCODE_POSITION:
      const updatedConfigsAfterBarcodePosition = state.configs.map(configData => {
        if (configData.dimension_id === payload.configId) {
          const updatedConfig = { ...configData.config, barcode_position: payload.barcodePosition }
          const updatedConfigData = { ...configData, config: updatedConfig }

          if (payload.isEdited) {
            updatedConfigData.edited = true
          }

          return updatedConfigData
        }
        return configData
      })

      return {
        ...state,
        configs: updatedConfigsAfterBarcodePosition,
      }

    case impositionLayoutsConstants.UPDATE_PAGE_BARCODE_VALUE:
      const updatedConfigsWithBarcodeValue = state.configs.map(configData => {
        if (configData.dimension_id === payload.configId) {
          const updatedConfig = {
            ...configData.config,
            barcode: { ...configData.config.barcode, value: payload.value },
          }
          const updatedConfigData = { ...configData, config: updatedConfig, edited: true }

          return updatedConfigData
        }
        return configData
      })

      return {
        ...state,
        configs: updatedConfigsWithBarcodeValue,
      }

    case impositionLayoutsConstants.UPDATE_PAGE_BARCODE_ROTATE:
      const updatedConfigsWithBarcodeRotate = state.configs.map(configData => {
        if (configData.dimension_id === payload.configId) {
          const updatedConfig = {
            ...configData.config,
            barcode: { ...configData.config.barcode, rotate: payload.rotate },
          }
          const updatedConfigData = { ...configData, config: updatedConfig, edited: true }

          return updatedConfigData
        }
        return configData
      })

      return {
        ...state,
        configs: updatedConfigsWithBarcodeRotate,
      }

    case impositionLayoutsConstants.UPDATE_PAGE_BARCODE_POSITION:
      const updatedConfigsWithBarcodePosition = state.configs.map(configData => {
        if (configData.dimension_id === payload.configId) {
          const updatedConfig = {
            ...configData.config,
            barcode: { ...configData.config.barcode, x: payload.x, y: payload.y },
          }
          const updatedConfigData = { ...configData, config: updatedConfig, edited: true }

          return updatedConfigData
        }
        return configData
      })

      return {
        ...state,
        configs: updatedConfigsWithBarcodePosition,
      }

    case impositionLayoutsConstants.CREATE_CONFIG_SUCCESS:
      const configsAfterCreate = state.configs.map(configData => {
        if (configData.dimension_id === payload.dimensionId) {
          const updatedConfigData = { ...configData }

          delete updatedConfigData.edited

          return updatedConfigData
        }
        return configData
      })

      return {
        ...state,
        configs: configsAfterCreate,
      }

    case impositionLayoutsConstants.UPDATE_CONFIG_SUCCESS:
      const configsAfterUpdate = state.configs.map(configData => {
        if (configData.id === payload.updatedId) {
          const updatedConfigData = { ...configData }

          delete updatedConfigData.edited

          return updatedConfigData
        }
        return configData
      })

      return {
        ...state,
        configs: configsAfterUpdate,
      }

    case impositionLayoutsConstants.SET_REPEAT_MODE:
      const configsAfterSetRepeat = state.configs.map(configData => {
        if (configData.dimension_id === payload.configId) {
          const { paperWidth, actualCardWidth, configId, ...rest } = payload
          const updatedConfigData = {
            ...configData,
            repeatMode: { ...configData.repeatMode, ...rest },
          }

          if (!payload.active) {
            updatedConfigData.config.front = []
          } else if (payload.cardPositions && payload.additionalBarcodes) {
            // need to reset all previous cards and repeat one on all sections
            const newCards = payload.cardPositions.map((position, index) => {
              return {
                id: `${Date.now()}_${index}`,
                x: position.x,
                y: position.y,
                rotate: updatedConfigData.config.card_rotate,
                type: 'image',
                section_id: index,
              }
            })

            updatedConfigData.config.front = [...newCards, ...payload.additionalBarcodes]
          }

          if (payload.barcode_value) {
            updatedConfigData.config.front = updatedConfigData.config.front.map(element => {
              if (element.type === 'code39') {
                return { ...element, value: +payload.barcode_value }
              }

              return element
            })
          }
          return updatedConfigData
        }
        return configData
      })

      return {
        ...state,
        configs: configsAfterSetRepeat,
      }

    case impositionLayoutsConstants.UPDATE_ADDITIONAL_BARCODES:
      const updatedConfigsWithAdditionalBarcodes = state.configs.map(configData => {
        if (configData.dimension_id === payload.configId) {
          const updatedConfig = configData.config

          updatedConfig.front = updatedConfig.front.map(element => {
            if (element.type === 'code39') {
              const sectionWidth = payload.pageWidth / configData.repeatMode.sections_count
              const newPosition = impositionHelpers.getAdditionalBarcodePosition(
                element.section_id,
                sectionWidth,
                updatedConfig.barcode,
              )
              return {
                ...element,
                x: newPosition.x,
                y: newPosition.y,
              }
            }

            return element
          })

          return { ...configData, config: updatedConfig }
        }
        return configData
      })

      return {
        ...state,
        configs: updatedConfigsWithAdditionalBarcodes,
      }

    case impositionLayoutsConstants.UPDATE_REPEATED_CARDS:
      const updatedConfigsWithRepeatedCards = state.configs.map(configData => {
        if (configData.dimension_id === payload.configId) {
          const updatedConfig = configData.config

          updatedConfig.front = updatedConfig.front.map((element, index) => {
            if (element.type === 'image' && index !== 0) {
              const sectionWidth = payload.pageWidth / configData.repeatMode.sections_count
              const newPosition = impositionHelpers.getRepeatedCardPosition(
                element.section_id,
                sectionWidth,
                updatedConfig.front[0],
              )

              return {
                ...element,
                x: newPosition.x,
                y: newPosition.y,
              }
            }

            return element
          })

          return { ...configData, config: updatedConfig }
        }
        return configData
      })

      return {
        ...state,
        configs: updatedConfigsWithRepeatedCards,
      }

    case impositionLayoutsConstants.RESET_CONFIGS:
      return {
        ...state,
        configs: null,
      }
    case impositionLayoutsConstants.SETBYID:
      return {
        ...state,
        item: null,
      }
    case impositionLayoutsConstants.SETALL:
      return {
        ...state,
        items: null,
      }
    default:
      return state
  }
}

export default impositionLayouts
