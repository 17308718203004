import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import v4 from 'uuid/v4'
import getCurrentUrl from '../../../../_helpers/url-utils'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import { contextsActions } from '../../../../_actions'
import { contextsConstants, settingsConstants } from '../../../../_constants'
import { Link } from 'react-router-dom'
import {
  getContextsRows,
  getPage,
  getTotalItems,
} from '../../../../_selectors/contexts.selector.js'

const { navMenu, advancedSearch } = contextsConstants

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Employees',
    dataIndex: 'employees',
    key: 'employees',
  },
  {
    title: 'Clients',
    dataIndex: 'clients',
    key: 'clients',
  },
]

const navMenuInit = [navMenu.create]

const ManageItems = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getContextsRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const navigateToManage = () => {
    dispatch({ type: contextsConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.manage.key:
          return {
            ...menu,
            url: navigateToManage,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const deleteItem = async id => {
    await dispatch(contextsActions.deleteItem(id))
  }

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        employees: item?.employees.map(employee => (
          <Link key={employee.id} to={`/employees/view/${employee.id}`}>
            {employee.username}
            <br />
          </Link>
        )),
        clients: item?.users.map(client => (
          <Link key={client.id} to={`/clients/view/${client.id}`}>
            {client.login ? client.login : `${client.fname} ${client.lname}`}
            <br />
          </Link>
        )),
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  return (
    <NavContainer match={match} menu={navMenuParse}>
      <TableFilter
        deleteItems={deleteItem}
        columns={initColumns}
        data={dataSource}
        getDataAction={contextsActions.getAll}
        advancedSearch={advancedSearch}
      />
    </NavContainer>
  )
}

export default ManageItems
