import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import ItemViewer from '../../../../components/item-viewer'
import { denominationActions, giftCardActions } from '../../../../_actions'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { denominationConstants } from '../../../../_constants'
import { getGiftCardsRows } from '../../../../_selectors/gift-cards.selector.js'

const { viewItemKeyToLabel, navMenu } = denominationConstants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.edit, navMenu.delete, navMenu.manage]

const ViewItem = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const match = useRouteMatch()
  const { id } = useParams()

  const {
    item: { denomination },
  } = useSelector(state => state.denominations)
  const giftCards = useSelector(getGiftCardsRows)

  const [navMenuParse, setNavMenuParse] = useState([])
  const view = getCurrentUrl(match.url)

  const onEditItem = () => {
    history.push({
      pathname: `${view}/edit/${id}`,
    })
  }

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(denominationActions.deleteItem(id))
      },
    })
  }

  useEffect(() => {
    dispatch(giftCardActions.getAll())
  }, [])

  useEffect(() => {
    dispatch(denominationActions.getItemById(id))
    return () => {
      dispatch({ type: denominationConstants.SETBYID })
    }
  }, [id])

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.edit.key:
          return {
            ...menu,
            url: onEditItem,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const viewItem = useMemo(() => {
    if (denomination) {
      return Object.keys(viewItemKeyToLabel).reduce((obj, sKey) => {
        if (sKey === 'gcard_id') {
          return {
            ...obj,
            [sKey]: giftCards?.find(card => card.id === denomination[sKey])?.name,
          }
        }
        return {
          ...obj,
          [sKey]: denomination[sKey],
        }
      }, {})
    }
    return {}
  }, [denomination, giftCards])

  return (
    <NavContainer menu={navMenuParse}>
      <ItemViewer item={viewItem} keyToLabelMap={viewItemKeyToLabel} />
    </NavContainer>
  )
}

export default ViewItem
