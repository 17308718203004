import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { ManageItems } from './views'
import Content from '../../components/content'

const AutomationRecipients = () => {
  const match = useRouteMatch()

  return (
    <Content>
      <Switch>
        <Route exact path={`${match.url}/:automation_id`} component={ManageItems} />

        <Redirect to={match.url} />
      </Switch>
    </Content>
  )
}

export default AutomationRecipients
