import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Button, Form, Input, Modal, Select, Upload, InputNumber } from 'antd'
import { ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import NavContainer from '../../../../components/nav-container'
import { categoriesActions } from '../../../../_actions'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { convertFormValuesDeep, parseFileForSetFields } from '../../../../_helpers'
import { appConstants, categoriesConstants } from '../../../../_constants'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import AdvancedCheckboxList from 'components/advanced-checkbox-list'
import { getCategory, getClientGroup } from '../../../../_selectors/categories.selector.js'

const { formItemLayout, navMenu, taxonomySelects } = categoriesConstants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.view, navMenu.delete, navMenu.manage]

const EditItem = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const { id } = useParams()
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const [navMenuParse, setNavMenuParse] = useState([])
  const [changed, setChange] = useState(false)

  const category = useSelector(getCategory)
  const groups = useSelector(getClientGroup)

  const view = getCurrentUrl(match.url)

  useEffect(() => {
    dispatch(categoriesActions.getItemById(id))
    return () => {
      dispatch({ type: categoriesConstants.SETBYID })
    }
  }, [id, dispatch])

  const navigateToView = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(categoriesActions.deleteItem(id))
      },
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToView,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  useEffect(() => {
    form.setFieldsValue({
      ...category,
      user_groups: groups?.filter(group => group.value).map(group => group.id),
      icon: parseFileForSetFields(category?.icon, {
        url: category?.icon ? `${appConstants.IMAGES_URL}/cardimages/${category.icon}` : '',
      }),
    })
  }, [form, groups, category])

  const userGroups = useMemo(() => {
    return groups?.map(e => ({ value: e.id, label: e.name }))
  }, [groups])

  const normFile = e => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const onFinish = async values => {
    const newObj = {}

    if (values.icon.length === 0) {
      newObj.file = { status: 'removed' }
    } else {
      newObj.file = values.icon?.[0]?.name !== category.icon ? values.icon?.[0] : null
    }

    Object.keys(values).forEach(item => {
      if (item !== 'icon') newObj[item] = values[item]
    })

    await dispatch(categoriesActions.updateItemById(id, convertFormValuesDeep(newObj)))
  }

  return (
    <NavContainer menu={navMenuParse}>
      {useGlobalLoader(
        <div>
          <p>Fields with * are required.</p>
          <Form
            {...formItemLayout}
            name="basic"
            form={form}
            onFinish={onFinish}
            onFieldsChange={() => setChange(true)}
            scrollToFirstError
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input title!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Slug"
              name="slug"
              rules={[
                {
                  required: true,
                  message: 'Please input title!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Meta title" name="meta_title">
              <Input />
            </Form.Item>

            <Form.Item label="Meta description" name="meta_description">
              <Input />
            </Form.Item>

            <Form.Item
              name="icon"
              label="Icon"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                name="icon"
                maxCount={1}
                beforeUpload={file => {
                  console.log(file)
                  return false
                }}
                action=""
                listType="picture"
              >
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>
            <Form.Item name="taxonomy" label="Taxonomy">
              <Select>
                {taxonomySelects.map(item => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.text}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="sort" label="Sort">
              <InputNumber />
            </Form.Item>

            <AdvancedCheckboxList
              formItemProps={{
                label: 'Customer Groups',
                name: 'user_groups',
                valuePropName: 'value',
              }}
              buttonText="Add Customer Group"
              options={userGroups}
              form={form}
              onChangeValues={() => setChange(true)}
              initialValues={groups?.filter(group => group.value).map(group => group.id)}
            />

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!changed}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>,
      )}
    </NavContainer>
  )
}

export default EditItem
