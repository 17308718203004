const couponConstants = {
  GETALL_REQUEST: 'COUPONS_GETALL_REQUEST',
  GETALL_SUCCESS: 'COUPONS_GETALL_SUCCESS',
  GETALL_FAILURE: 'COUPONS_GETALL_FAILURE',

  GETBYID_REQUEST: 'COUPONS_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'COUPONS_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'COUPONS_GETBYID_FAILURE',

  ADD_REQUEST: 'COUPONS_ADD_REQUEST',
  ADD_SUCCESS: 'COUPONS_ADD_SUCCESS',
  ADD_FAILURE: 'COUPONS_ADD_FAILURE',

  DELETE_REQUEST: 'COUPONS_DELETE_REQUEST',
  DELETE_SUCCESS: 'COUPONS_DELETE_SUCCESS',
  DELETE_FAILURE: 'COUPONS_DELETE_FAILURE',

  UPDATE_REQUEST: 'COUPONS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'COUPONS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'COUPONS_UPDATE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  dateFormatList: ['DD/MM/YYYY', 'DD/MM/YY'],

  navMenu: {
    list: { key: 'list', title: 'List Coupon', url: '/coupons/list' },
    manage: { key: 'manage', title: 'Manage Coupon', url: '/coupons' },
    create: { title: 'Create Coupon', key: 'create', url: '/coupons/create' },
    edit: { key: 'edit', title: 'Update Coupon', url: '' },
    delete: { key: 'delete', title: 'Delete Coupon', url: '' },
    view: { key: 'view', title: 'View Coupon', url: '' },
  },

  formItemLayout: {
    labelCol: {
      xs: { span: 4 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 4 },
      sm: { span: 10 },
    },
  },

  listItemKeyToLabel: {
    id: 'ID',
    code: 'Coupon name',
    credit: 'Coupon value',
    expiration_date: 'Expiration',
  },
  viewItemKeyToLabel: {
    id: 'ID',
    code: 'Coupon name',
    credit: 'Coupon value',
    expiration_date: 'Expiration',
  },
  advancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'Coupon name', key: 'code' },
    { label: 'Coupon value', key: 'credit' },
    { label: 'Expiration', key: 'expiration_date*date' }
  ],
}
export default couponConstants
