import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  AppstoreAddOutlined,
  DeleteOutlined,
  EditOutlined,
  RedoOutlined,
  SearchOutlined,
  CopyOutlined,
} from '@ant-design/icons'
import { Button, Input, Popconfirm, Space, Table, Tooltip } from 'antd'
import { Link, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import AdvancedSearch from 'components/advanced-search'
import { useDispatch, useSelector } from 'react-redux'
import generateParamsAdvancedSearch from '_helpers/generate-params-advanced-search'
import { paginationActions, queryParamsActions, shopifyActions } from '../../_actions'
import { appConstants, settingsConstants, spinnerConstants } from '../../_constants'
import parseParams from '../../_helpers/hw-parse-params'
import isEmptyObject from '../../_helpers/hw-empty-object'
import useGlobalLoader from '../../hooks/loader/useGlobalLoader.js'
import './styles.scss'

const TableFilter = ({
  columns,
  data: { rows, total, page = 1 },
  deleteItems,
  getDataAction,
  actionsColum = true,
  resendMail,
  isEditOnId = true,
  advancedSearch = [],
  showAdvancedButton,
  showAdvancedSearch = true,
  doubleClick = true,
  // only for visible fonts on Manage page
  changedVisible = false,
  itemBasePath,
  defaultSort = null,
  onPagination = () => {},
  isChildTable,
  warningCheck,
  reSubscribeShop,
  expandableTable = false,
  noLimit,
  rowSelection = false,
  customActions,
  fixedActions,
}) => {
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  let inputRef = useRef()
  const { id } = useParams()

  // get params from url
  const { search } = useLocation()
  const offsetFromUrl = new URLSearchParams(search).get('offset')
  const limitFromUrl = new URLSearchParams(search).get('limit')

  const [rowId, setRowId] = useState('')
  const whereQueryParams = useSelector(state => state.queryParams.where)
  const action = useSelector(state => state.settings.managePageAction)

  const { loadingManagePage, isGlobalLoading } = useSelector(state => state.spinner)
  const { [match.url]: storedPageSize } = useSelector(state => state.pagination)

  const routerAction = useSelector(state => state.router.action)

  const currentPageSize = useMemo(() => {
    return storedPageSize || appConstants.DEFAULT_PAGE_SIZE
  }, [storedPageSize])

  const [currentPage, setCurrentPage] = useState(offsetFromUrl || page)
  const [limit, setLimit] = useState(limitFromUrl || currentPageSize)

  const [isTableChange, setTableChange] = useState(false)
  const [isAdvancedSearch, setAdvancedSearch] = useState(false)
  const [deleteConfirmationParams, setDeleteConfirmationParams] = useState(null)

  const parseWhere = whereQueryParams => {
    const where = {}
    if (whereQueryParams) {
      Object.keys(whereQueryParams).forEach(param => {
        where[param] = [whereQueryParams[param]]
      })
    }
    return where
  }

  const [searchInfo, setSearchInfo] = useState({
    filteredInfo:
      match.url === '/orders'
        ? { status_filter: ['paid'], ...parseWhere(whereQueryParams) }
        : parseWhere(whereQueryParams) || null,
    sortedInfo: defaultSort,
  })

  useEffect(() => {
    if (action === 'setDefaultColumns') {
      setSearchInfo({
        filteredInfo:
          match.url === '/orders'
            ? { status_filter: ['paid'], ...parseWhere(whereQueryParams) }
            : parseWhere(whereQueryParams) || null,
        sortedInfo: null,
      })
      setCurrentPage(1)
      setAdvancedSearch(false)
      dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: 'paginate' })
    }
  }, [action])

  // change page when we have only one item on page and delete this item
  useEffect(() => {
    setCurrentPage(page)
  }, [page])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getColumnSearchProps = dataIndex => {
    const column = columns.find(col => col.key === dataIndex)
    // Set default column settings if no present in initial setup
    let columnSettings = {}
    if (!column?.onFilter && !column?.hideFilter) {
      columnSettings = {
        ...columnSettings,
        onFilterDropdownVisibleChange: visible => {
          if (visible && !column.filters) {
            setTimeout(() => inputRef.select())
          }
        },
      }
    }

    if (!column?.filters && !column?.hideFilter) {
      columnSettings = {
        ...columnSettings,
        // eslint-disable-next-line react/display-name
        filterIcon: filtered => (
          <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        // eslint-disable-next-line react/display-name
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
          // log
          return (
            <div style={{ padding: 8 }}>
              <Input
                ref={node => {
                  inputRef = node
                }}
                placeholder={`Search ${column.dataIndex.split('_').join(' ')}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => confirm()}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
              <Button
                type="primary"
                onClick={() => confirm()}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90, marginRight: 8 }}
              >
                Search
              </Button>
              <Button
                onClick={() => {
                  clearFilters()
                  confirm()
                }}
                size="small"
                style={{ width: 90 }}
              >
                Reset
              </Button>
            </div>
          )
        },
      }
    }

    return columnSettings
  }

  const correctPathUrl = () => {
    let correctUrl = match.url
    const splitPath = match.url.split('/')

    if (splitPath[1] === 'card-images') {
      correctUrl = splitPath.slice(0, 2).join('/')
    }
    return correctUrl
  }

  const searchColumns = useMemo(() => {
    return columns.map(col => {
      const { filteredInfo, sortedInfo } = searchInfo
      return {
        ...col,
        filteredValue: filteredInfo?.[col.key] || null,
        sortOrder:
          sortedInfo?.columnKey === col.key && sortedInfo?.order ? sortedInfo?.order : false,
        ...getColumnSearchProps(col.key),
      }
    })
  }, [columns, searchInfo, getColumnSearchProps])

  const doubleClickOnRow = record => {
    if (!doubleClick) return
    const correctUrl = correctPathUrl()

    history.push({
      pathname: `${itemBasePath || correctUrl}/view/${record.id}`,
    })
  }

  const clickOnUpdate = record => {
    const correctUrl = correctPathUrl()

    history.push({
      pathname: `${correctUrl}/edit/${record.id}`,
    })
  }

  const clickOnCopy = (event, id) => {
    event.stopPropagation()
    navigator.clipboard.writeText(id)
  }

  const linkOnUpdate = (record, isEdit = true) => {
    const correctUrl = correctPathUrl()

    return `${itemBasePath || correctUrl}/${isEdit ? 'edit' : 'view'}/${record.id}`
  }

  const onDeleteItem = async recordId => {
    dispatch({ type: spinnerConstants.START_ON_MANAGE_PAGE })
    await deleteItems(recordId)
    setDeleteConfirmationParams(null)
    // check to only one element on page and delete item
    const newPage = rows.length === 1 && page !== 1 ? page - 1 : page
    setCurrentPage(newPage)
    if (newPage === page) {
      const { filteredInfo, sortedInfo } = searchInfo
      setSearchInfo({ filteredInfo, sortedInfo })
    }
  }

  const sendMail = async mailId => {
    await dispatch(resendMail(mailId))
    await dispatch(getDataAction({ offset: page, limit: currentPageSize }))
  }

  const subscribeShop = async shop_id => {
    await dispatch(reSubscribeShop({ shop_id }))
    const res = await dispatch(shopifyActions.getCountOfIssuesShops())
    await dispatch(
      shopifyActions.getIssuesShops({
        offset: page,
        limit: currentPageSize,
        where: { shop_id: res.shop_ids },
      }),
    )
  }

  const deleteWithWaringCheck = async id => {
    dispatch({ type: spinnerConstants.START_ON_MANAGE_PAGE })
    let confirmationText = 'Sure to delete?'
    const warningMessage = await warningCheck(id, true)
    if (warningMessage) confirmationText = `${warningMessage} ${confirmationText}`

    setDeleteConfirmationParams({
      id,
      text: confirmationText,
    })
    dispatch({ type: spinnerConstants.STOP_ON_MANAGE_PAGE })
  }
  const actions = [
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      className: 'ant-table-column-title',
      dataIndex: '',
      fixed: fixedActions ? 'right' : false,
      // eslint-disable-next-line react/display-name
      render: customActions
        ? record => customActions(record)
        : record => {
            return (
              <Space size="small">
                {typeof actionsColum === 'object' && actionsColum.includes('resubscribe') && (
                  <Tooltip title={`re-subscribe webhook ${record.shop_id}`} placement="leftTop">
                    <Button
                      size="small"
                      shape="square"
                      icon={<AppstoreAddOutlined />}
                      onClick={() => subscribeShop(record.shop_id)}
                      data-button="resend"
                    />
                  </Tooltip>
                )}
                {typeof actionsColum === 'object' && actionsColum.includes('resend') && (
                  <Tooltip title={`Resend mail ${record.id}`} placement="leftTop">
                    <Button
                      size="small"
                      shape="square"
                      icon={<RedoOutlined />}
                      onClick={() => sendMail(record.id)}
                      data-button="resend"
                    />
                  </Tooltip>
                )}
                {((typeof actionsColum === 'object' && actionsColum.includes('view')) ||
                  (typeof actionsColum === 'boolean' && actionsColum)) && (
                  <Tooltip title={`View item ${record.id}`} placement="top">
                    <Link to={() => linkOnUpdate(record, false)}>
                      <Button
                        style={{ color: '#595c97' }}
                        size="small"
                        shape="square"
                        icon={<SearchOutlined />}
                        onClick={() => doubleClickOnRow(record)}
                      />
                    </Link>
                  </Tooltip>
                )}
                {((typeof actionsColum === 'object' && actionsColum.includes('edit')) ||
                  (typeof actionsColum === 'boolean' && actionsColum)) && (
                  <Tooltip title={`Edit item ${record.id}`} placement="top">
                    <Link to={() => linkOnUpdate(record)}>
                      <Button
                        style={{ color: '#595c97' }}
                        size="small"
                        shape="square"
                        icon={<EditOutlined />}
                        onClick={() => clickOnUpdate(record)}
                      />
                    </Link>
                  </Tooltip>
                )}
                {((typeof actionsColum === 'object' && actionsColum.includes('delete')) ||
                  (typeof actionsColum === 'boolean' && actionsColum)) && (
                  <Tooltip title={`Delete item ${record.id}`} placement="rightTop">
                    {warningCheck ? (
                      <Popconfirm
                        title={deleteConfirmationParams?.text}
                        open={deleteConfirmationParams?.id === record.id}
                        onConfirm={() =>
                          onDeleteItem(match.url === '/fonts' ? +record.font_id : +record.id)
                        }
                        onCancel={() => setDeleteConfirmationParams(null)}
                      >
                        <Button
                          style={{ color: '#595c97' }}
                          size="small"
                          shape="square"
                          color="red"
                          icon={<DeleteOutlined />}
                          data-button="delete"
                          onClick={() => deleteWithWaringCheck(record.id)}
                        />
                      </Popconfirm>
                    ) : (
                      <Popconfirm
                        title="Sure to delete?"
                        onConfirm={() =>
                          onDeleteItem(match.url === '/fonts' ? +record.font_id : +record.id)
                        }
                      >
                        <Button
                          style={{ color: '#595c97' }}
                          size="small"
                          shape="square"
                          color="red"
                          icon={<DeleteOutlined />}
                          data-button="delete"
                        />
                      </Popconfirm>
                    )}
                  </Tooltip>
                )}
              </Space>
            )
          },
    },
  ]

  const updSearchColumns = searchColumns.map(column => {
    const correctUrl = correctPathUrl()

    const key = correctUrl.includes('shopify') ? column.key === 'shop_id' : column.key === 'id'

    if (key && isEditOnId) {
      return {
        ...column,
        width: column.width || '120px',
        // eslint-disable-next-line react/display-name
        render: record => {
          if (window.location.href.includes('shopify')) console.log(record, 'record')
          return (
            <Space size="small">
              <Tooltip title={`Copy item Id: ${record}`} placement="leftTop">
                <Button
                  style={{ color: '#595c97' }}
                  size="small"
                  shape="square"
                  icon={<CopyOutlined />}
                  onClick={event => clickOnCopy(event, record)}
                />
              </Tooltip>
              <Link to={`${itemBasePath || correctUrl}/edit/${record}`}>
                <Button
                  size="small"
                  type="text"
                  className="list-view-item-bold list-item-underline px-0"
                  style={{ border: 'none' }}
                >
                  {record}
                </Button>
              </Link>
            </Space>
          )
        },
      }
    }
    return column
  })
  const actionsColumns =
    typeof actionsColum === 'boolean' && !actionsColum
      ? updSearchColumns
      : [...updSearchColumns, ...actions]

  const pageSizeChanged = (current, size) => {
    dispatch(paginationActions.pageSizeChanged(match.url, size))
  }

  const paginationChanged = ({ current, pageSize }, filters, sorted, { action: innerAction }) => {
    if (innerAction === 'paginate') onPagination()
    setSearchInfo({ filteredInfo: filters, sortedInfo: sorted })
    let offset = current

    dispatch({ type: spinnerConstants.START_ON_MANAGE_PAGE })

    // eslint-disable-next-line default-case
    switch (innerAction) {
      case 'sort':
        offset = 1
        break
      case 'paginate':
        if (pageSize !== limit) {
          setLimit(pageSize)
        }
        dispatch(paginationActions.paginationChanged(current, pageSize))
        break
      case 'filter':
        offset = 1
        break
    }
    if (pageSize !== currentPageSize) {
      pageSizeChanged(current, pageSize)
    }
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: innerAction })
    setTableChange(true)
    setCurrentPage(offset)
  }

  const getChildTableData = async (params, action, id) => {
    dispatch({ type: spinnerConstants.START_ON_MANAGE_PAGE })
    await dispatch(getDataAction(params, action, id))
    dispatch({ type: spinnerConstants.STOP_ON_MANAGE_PAGE })
  }

  useEffect(() => {
    const query = {
      like: {},
      where: {
        ...whereQueryParams,
      },
      order: {},
      date: {},
      relation: {},
    }

    const isOrderPage = match.url === '/orders'

    if (action === 'advanced') {
      const param = generateParamsAdvancedSearch(searchInfo.filteredInfo, match.url)

      const whereStatus = isOrderPage ? { is_bulk: 0, ...param.where } : param.where
      const params = {
        offset: 1,
        // offset: currentPage || 1,
        limit: limit || currentPageSize,
        like: param.like,
        order: param.order,
        where: whereStatus,
        date: param.date,
      }

      // in noLimit mode - remove limit param to fetch all data at once
      if (noLimit) {
        delete params.limit
      }

      dispatch(getDataAction(params, action, id))
    } else {
      const { sortedInfo, filteredInfo } = searchInfo
      parseParams(query, sortedInfo, filteredInfo)

      const isEmpty = isEmptyObject(query.order)

      let whereStatus

      switch (true) {
        case match.url === '/orders':
          whereStatus = {
            ...(!isTableChange && { status: 'paid' }),
            is_bulk: 0,
            ...query.where,
          }
          break
        case match.url === '/shopify':
          whereStatus = null
          break

        default:
          whereStatus = query.where
          break
      }

      const params = {
        offset: action !== null ? currentPage || 1 : 1,
        limit: limit || currentPageSize,
        like: query.like,
        order: isEmpty && isOrderPage ? { key: 'id', order: 'DESC' } : query.order,
        where:
          routerAction === 'POP' &&
          match.url !== '/orders' &&
          !match.url.includes('/card-images') &&
          !match.url.includes('/automation-orders') &&
          !match.url.includes('/shipping-configs') &&
          !match.url.includes('/issues') &&
          !match.url.includes('/credit_history') &&
          !isTableChange
            ? null
            : whereStatus,
        relation: query.relation,
        date: query.date,
      }

      // in noLimit mode - remove limit param to fetch all data at once
      if (noLimit) {
        delete params.limit
      }

      if (
        routerAction === 'POP' &&
        match.url !== '/orders' &&
        !match.url.includes('/card-images') &&
        !match.url.includes('/automation-orders') &&
        !match.url.includes('/shipping-configs') &&
        !match.url.includes('/issues') &&
        !match.url.includes('/credit_history') &&
        !isTableChange
      ) {
        dispatch(queryParamsActions.clearQueryParams())
      }

      if (typeof getDataAction === 'function') {
        if (isChildTable) getChildTableData(params, action, id)
        else dispatch(getDataAction(params, action, id))
      }
    }
  }, [
    dispatch,
    currentPage,
    limit,
    currentPageSize,
    getDataAction,
    id,
    match.url,
    searchInfo,
    changedVisible,
  ])

  const handleSelectRow = (event, id) => {
    const isClickDeleteButton =
      event.target.dataset?.button === 'delete' || event.target.dataset?.icon === 'delete'
    const isClickResendButton =
      event.target.dataset?.button === 'resend' || event.target.dataset?.icon === 'redo'
    // if check on checkbox => dont select row (for manage fonts)
    if (!event.target.dataset.visible && !isClickDeleteButton && !isClickResendButton) {
      setRowId(rowId !== id ? id : '')
    }
  }

  useEffect(() => {
    if (rows?.length >= 0) dispatch({ type: spinnerConstants.STOP_ON_MANAGE_PAGE })
  }, [rows])

  const tableTitle = useCallback(() => {
    if (noLimit) {
      return (
        <div className="list-view-title">
          Displaying {rows?.length} of {total ?? 0} results.
        </div>
      )
    }

    return (
      <div className="list-view-title">
        Displaying {total === 0 || !total ? 0 : (page - 1) * currentPageSize + 1} -{' '}
        {total || total === 0 ? Math.min(page * currentPageSize, total) : 0} of {total ?? 0}{' '}
        results.
      </div>
    )
  }, [noLimit, rows, total, currentPageSize, page])

  return useGlobalLoader(
    <div className="mb-4 kit__utils__table">
      {(rows?.length === 0 || rows?.length !== 0) && (
        <>
          {showAdvancedSearch && (
            <>
              {showAdvancedButton && (
                <Button
                  size="small"
                  type="text"
                  className="list-view-item-bold list-item-underline px-0"
                  style={{ border: 'none', color: '#06c' }}
                  onClick={() => setAdvancedSearch(!isAdvancedSearch)}
                >
                  Advanced Search
                </Button>
              )}
              <p style={{ whiteSpace: 'normal' }}>
                You may optionally enter a comparison operator (<b>&lt;</b>, <b>&lt;=</b>,{' '}
                <b>&gt;</b>, <b>&gt;=</b>, <b>&lt;&gt;</b> or <b>=</b>) at the beginning of each of
                your search values to specify how the comparison should be done.
              </p>
              {isAdvancedSearch && (
                <AdvancedSearch
                  advancedSearch={advancedSearch}
                  columns={searchColumns}
                  setTableChange={setTableChange}
                  setSearchInfo={setSearchInfo}
                />
              )}
            </>
          )}

          <Table
            title={tableTitle}
            columns={actionsColumns}
            dataSource={
              rows?.length !== 0 && rows?.length > currentPageSize && !noLimit
                ? rows.slice(0, currentPageSize)
                : rows
            }
            // bordered
            // TODO: calculate table height depending on viewport height - paddings
            // scroll={{ y: 540 }}
            loading={loadingManagePage || isGlobalLoading}
            onChange={paginationChanged}
            pagination={
              noLimit
                ? false
                : {
                    showSizeChanger: true,
                    pageSize: currentPageSize,
                    current: currentPage,
                    total,
                    rootClassName: 'pagination-container',
                  }
            }
            rowClassName={(record, index) => {
              let addClass = ''
              if (match.url !== '/orders') {
                addClass = index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
              }
              if (record.className) addClass = record.className
              return `${record.id === rowId && 'clickRowStyl'} ${addClass}`
            }}
            scroll={{ x: true }}
            onRow={record => {
              return {
                onDoubleClick: () => doubleClickOnRow(record), // double click row
                onClick: event => handleSelectRow(event, record.id),
              }
            }}
            expandable={
              expandableTable && {
                expandedRowRender: record => record.expandableContent,
                rowExpandable: record => !!record.expandableContent,
              }
            }
            rowSelection={rowSelection}
          />
        </>
      )}
    </div>,
  )
}

TableFilter.defaultProps = {
  columns: [],
  data: {},
}

export default TableFilter
