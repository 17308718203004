import React, { useState } from 'react'
import { Card, Modal, Progress } from 'antd'
import { DeleteFilled, ReloadOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import useAddValueToClipboard from 'hooks/clipboard/useAddValueToClipboard'
import cronJobsActions from '_actions/cron-jobs.actions'
import DetailsTimeline from './details-timeline'
import DetailsHeader from './details-header'
import DetailsDataContent from './details-data-content'

const CronJobDetailsCard = ({ jobData, deleteAllowed, jobName, activeTab }) => {
  const dispatch = useDispatch()
  const [activeKey, setActiveKey] = useState('data')
  const onKeyChange = key => {
    setActiveKey(key)
  }

  const {
    id,
    name,
    data,
    opts,
    returnvalue,
    progress,
    timestamp,
    finishedOn,
    processedOn,
    logs,
  } = jobData

  const removeCronByName = () => {
    dispatch(cronJobsActions.removeByName(id, jobName))
  }

  const { addValueToClipboard, contextHolder } = useAddValueToClipboard()

  const deleteJobHandler = () => {
    Modal.confirm({
      title: 'Are you sure?',
      content: 'Are you sure that you want to clean this job?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      icon: null,
      onOk: () => removeCronByName(),
    })
  }

  const handleReloadOutlined = () => {
    Modal.confirm({
      title: 'Are you sure?',
      content: 'Are you sure that you want to retry this failed job?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      icon: null,
      onOk: () => dispatch(cronJobsActions.retryFailed(jobName, id)),
    })
  }

  const correctCopyDate = activeKey => {
    if (activeKey === 'result') {
      return JSON.stringify({ result: returnvalue }, null, 2)
    }
    if (activeKey === 'options') {
      return JSON.stringify({ options: opts }, null, 2)
    }
    return JSON.stringify({ data }, null, 2)
  }

  return (
    <Card>
      <DetailsHeader id={id} name={name} />
      {deleteAllowed ? (
        <DeleteFilled className="delete-icon top-right-corner" onClick={deleteJobHandler} />
      ) : null}
      <div className="details-body">
        <DetailsTimeline timestamp={timestamp} processedOn={processedOn} finishedOn={finishedOn} />
        <DetailsDataContent
          onKeyChange={onKeyChange}
          activeKey={activeKey}
          data={data}
          opts={opts}
          logs={logs}
          returnvalue={returnvalue}
        />
        {contextHolder}
        <h3
          style={{
            margin: '5rem 0 0 1rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <span
            onClick={() => addValueToClipboard(correctCopyDate(activeKey), activeKey)}
            style={{ cursor: 'pointer', color: '#1677ff' }}
            className="fe fe-copy"
          />
          {activeTab === 'failed' ? (
            <ReloadOutlined
              style={{ margin: '10px', cursor: 'pointer', color: 'orange' }}
              onClick={() => handleReloadOutlined()}
            />
          ) : null}
        </h3>
        <Progress
          type="circle"
          percent={progress}
          format={percent => `${percent}%`}
          size={70}
          style={{ alignSelf: 'flex-end' }}
        />
      </div>
    </Card>
  )
}

export default CronJobDetailsCard
