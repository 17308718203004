import {applyMiddleware, createStore} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
// import { createLogger } from 'redux-logger'
import rootReducer from '../_reducers'

// const loggerMiddleware = createLogger()

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunkMiddleware)),
  // applyMiddleware(thunkMiddleware),
  // composeWithDevTools(applyMiddleware(thunkMiddleware, loggerMiddleware)),
)

export default store
