import React, { useCallback, useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom'

import {
  Alert,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
} from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import Text from 'antd/es/typography/Text'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import NavContainer from '../../../../components/nav-container'
import { batchOrdersActions, ordersActions } from '../../../../_actions'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { ordersConstants, limitConstants } from '../../../../_constants'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import RefundBulkOrderModal from 'components/refund-bulk-order-modal'

const { formItemLayout, navMenu, orderStatuses, refundStatus } = ordersConstants
const { MAX_ADDRESS_LENGTH, MAX_NAME_LENGTH } = limitConstants

const navMenuInit = [
  // navMenu.list,
  navMenu.view,
  navMenu.delete,
  navMenu.manage,
  navMenu.note,
  navMenu.envelope,
  navMenu.envelope_hmf,
  navMenu.note_hmf,
]

const EditItem = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { id } = useParams()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])
  const [changed, setChange] = useState(false)
  const [isRefundModalActive, setRefundModalActive] = useState(false)
  const {
    item: { fonts, order, countries, employees, states },
  } = useSelector(state => state.orders)

  const { user: currentUser } = useSelector(state => state.authentication)

  const [selectedEmployeeId, setSelectedEmployeeId] = useState(order?.employee_id)
  const [selectedCountryId, setSelectedCountryId] = useState({
    from: order?.from?.country_id,
    to: order?.to?.country_id,
  })

  const isRefundParentActive = Form.useWatch('refund-parent', form)

  const navigateToView = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(ordersActions.deleteItem(id))
      },
    })
  }

  const createRedo = async id => {
    await dispatch(ordersActions.getCreateRedo(id))
    await dispatch(ordersActions.getItemById(id))
  }

  const disableRedoCreate = useMemo(() => {
    if (!!order?.copies && order?.status) {
      return !!order?.copies?.length || order?.status === 'complete'
    }
    return false
  }, [order])

  useEffect(() => {
    dispatch(ordersActions.getItemById(id))
    return () => {
      dispatch({ type: ordersConstants.SETBYID })
    }
  }, [id, dispatch])

  useEffect(() => {
    if (order) {
      setSelectedEmployeeId(order.employee_id)
      form.setFieldsValue({
        ...order,
        date_send: order?.date_send ? dayjs(order.date_send) : null,
        from: {
          ...order?.from,
          birthday: order?.from?.birthday ? dayjs(order.from.birthday) : null,
        },
        to: {
          ...order?.to,
          birthday: order?.to?.birthday ? dayjs(order.to.birthday) : null,
        },
      })
    }
  }, [order, form])

  useEffect(() => {
    if (order) {
      setSelectedCountryId({
        from: order?.from?.country_id,
        to: order?.to?.country_id,
      })
    }
  }, [order?.to, order?.from])

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToView,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        case navMenu.note.key:
          return {
            ...menu,
            url() {
              if (order.card) {
                dispatch(ordersActions.getDocxNote(id))
              }
            },
            ...(order?.card ? {} : { disabled: true }),
          }
        case navMenu.envelope.key:
          return {
            ...menu,
            url() {
              if (order.card) {
                dispatch(ordersActions.getDocxEnvelope(id))
              }
            },
            ...(order?.card ? {} : { disabled: true }),
          }
        case navMenu.envelope_hmf.key:
          return {
            ...menu,
            url() {
              if (order.card) {
                dispatch(batchOrdersActions.createPDFEnvelopes(id))
              }
            },
            ...(order?.card ? {} : { disabled: true }),
          }
        case navMenu.note_hmf.key:
          return {
            ...menu,
            url() {
              if (order.card) {
                dispatch(batchOrdersActions.createPDFNotes([id]))
              }
            },
            ...(order?.card ? {} : { disabled: true }),
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit, order])

  const onFinish = values => {
    const mergedOrder = {
      ...values,
      id: order.id,
      date_send: values.date_send.format('YYYY-MM-DD HH:mm:ss'),
      shouldRefund: values.refund && !isRefundParentActive ? 1 : 0,
      shouldFullRefund: isRefundParentActive ? 1 : 0,
      from: {
        ...values.from,
        state: values.from.state || '',
        order_id: order.id,
        birthday: values.from.birthday?.format('YYYY-MM-DD'),
        business_name: values.from.business_name ?? '',
        address2: values.from.address2 ?? '',
      },
      to: {
        ...values.to,
        state: values.to.state || '',
        order_id: order.id,
        birthday: values.to.birthday?.format('YYYY-MM-DD'),
        business_name: values.to.business_name ?? '',
        address2: values.to.address2 ?? '',
      },
    }

    const convertedOrder = convertFormValuesDeep(mergedOrder)
    dispatch(ordersActions.updateItemById(order.id, convertedOrder))
  }

  const onEmployeeChange = value => {
    form.setFieldsValue({ employee_id: value })
    setSelectedEmployeeId(value)
  }

  const wrongData = useMemo(() => {
    const isWrongData = []
    if (!order?.from) {
      isWrongData.push('from')
    }
    if (!order?.to) {
      isWrongData.push('to')
    }
    return isWrongData
  }, [order])

  const [insert] = order?.inserts || [null]

  const isRequiredState = useCallback(
    type => {
      return !!states?.filter(el => el.country_id === selectedCountryId[type]).length
    },
    [states, selectedCountryId],
  )

  const statesByCountry = useCallback(
    type => {
      if (states?.length) {
        return states.filter(el => el.country_id === selectedCountryId[type])
      }
      return []
    },
    [states, selectedCountryId],
  )

  const resetState = type => {
    form.resetFields([[type, 'state']])
  }

  const requiredFromState = useMemo(() => isRequiredState('from'), [selectedCountryId.from])
  const requiredToState = useMemo(() => isRequiredState('to'), [selectedCountryId.to])

  const statesFrom = useMemo(() => statesByCountry('from'), [selectedCountryId.from])
  const statesTo = useMemo(() => statesByCountry('to'), [selectedCountryId.to])

  const onClickRefund = e => {
    const { checked } = e.target;
    if (order.parent_id) {
      if (checked) setRefundModalActive(true)
      else {
        form.setFieldValue('refund', false)
        form.setFieldValue('refund-parent', false)
      }
    }
  }

  const onCloseRefundModal = () => setRefundModalActive(false)

  const refundChildOrder = () => {
    form.setFieldValue('refund', true)
    onCloseRefundModal()
  }

  const refundParentOrder = () => {
    form.setFieldValue('refund-parent', true)
    onCloseRefundModal()
  }

  return (
    <NavContainer menu={navMenuParse}>
      <RefundBulkOrderModal
        isVisible={isRefundModalActive}
        refundChildOrder={refundChildOrder}
        refundParentOrder={refundParentOrder}
        onCloseModal={() => {
          onCloseRefundModal()
          form.setFieldValue('refund', false)
        }}
      />
      {useGlobalLoader(
        <>
          <p>Fields with * are required.</p>
          {order && currentUser && order.employee_id !== currentUser.id && (
            <Alert
              message="Warning!"
              description="This order is assigned to another employee!"
              type="warning"
            />
          )}

          <Form
            {...formItemLayout}
            name="editOrder"
            form={form}
            onFinish={onFinish}
            onFieldsChange={() => setChange(true)}
            scrollToFirstError
          >
            <Form.Item label="User">
              {order ? (
                <>
                  {`[ID : `}
                  <Link to={`/clients/edit/${order.user_id}`}>
                    <Text strong underline>
                      {order.user_id}
                    </Text>
                  </Link>
                  {`] ${order.user?.name}`}
                </>
              ) : (
                '----'
              )}
            </Form.Item>

            <Form.Item label="Card">
              {order?.card_id ? (
                <>
                  {`[ID : `}
                  <Link to={`/cards/edit/${order.card_id}`}>
                    <Text strong underline>
                      {order.card_id}
                    </Text>
                  </Link>
                  {`] ${order?.card?.name} (${order.card?.category})`}
                </>
              ) : (
                '----'
              )}
            </Form.Item>

            <Form.Item label="Gift Card" className={order?.gift_card ? `blue-bg` : ''}>
              {order?.gift_card ? (
                <>
                  {`[ID : `}
                  <Link to={`/gift-cards/edit/${order.gift_card.id}`}>
                    <Text strong>{order.gift_card.id}</Text>
                  </Link>
                  {`] ${order.gift_card.nominal}$ ${order.gift_card.name}`}
                </>
              ) : (
                '----'
              )}
            </Form.Item>

            <Form.Item label="Custom Inserts" className={insert && `blue-bg`}>
              {insert ? (
                <>
                  {`[ID : `}
                  <Link to={`/inserts/edit/${insert.id}`}>
                    <Text strong>{insert.id}</Text>
                  </Link>
                  {`] ${insert.name}$ ${insert.price}`}
                </>
              ) : (
                '----'
              )}
            </Form.Item>

            <Form.Item label="Coupon" name="discount_code">
              <span className="ant-form-text">{order?.discount_code || '----'}</span>
            </Form.Item>

            <Form.Item label="Used Credit" name="used_credit">
              <span className="ant-form-text">{order?.used_credit || '----'}</span>
            </Form.Item>

            <Form.Item label="Date Created" name="date_created">
              <span className="ant-form-text">{order?.date_created}</span>
            </Form.Item>

            <Form.Item label="Date Payed" name="date_payed">
              <span className="ant-form-text">{order?.date_payed || '----'}</span>
            </Form.Item>

            <Form.Item label="Date Complete" name="date_complete">
              <span className="ant-form-text">{order?.date_complete || '----'}</span>
            </Form.Item>

            <Form.Item label="Date Send" name="date_send">
              <DatePicker />
            </Form.Item>

            <Form.Item label="Reviewed At" name="reviewed_at">
              <span className="ant-form-text">{order?.reviewed_at || '----'}</span>
            </Form.Item>

            <Form.Item label="Salesperson" name="salesperson_employee_id">
              <span className="ant-form-text">{order?.salesperson_employee_id || '----'}</span>
            </Form.Item>

            <Form.Item label="Account Manager" name="am_employee_id">
              <span className="ant-form-text">{order?.am_employee_id || '----'}</span>
            </Form.Item>

            <Form.Item label="Date Mailed" name="date_mailed">
              <span className="ant-form-text">{order?.date_mailed || '----'}</span>
            </Form.Item>

            <Form.Item label="Status" name="status">
              <Select>
                {orderStatuses.map(statuses => (
                  <Select.Option key={statuses.value} value={statuses.value}>
                    {statuses.text}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            {order?.status === 'paid' && (
              <>
                <Form.Item
                  label="Refund"
                  name="refund"
                  initialValue={false}
                  valuePropName="checked"
                >
                  <Checkbox onChange={onClickRefund}>
                    {`Cancel order and refund $${order?.order_price} ${
                      isRefundParentActive ? '(full order)' : ''
                    }`}
                  </Checkbox>
                </Form.Item>

                {/* empty form item added to control refund-parent field which isn't visible */}
                <Form.Item
                  label="Refund"
                  name="refund-parent"
                  initialValue={false}
                  valuePropName="checked"
                />
              </>
            )}

            <Form.Item label="QA Status" name="qa_status">
              <span className="ant-form-text">{order?.qa_status || '----'}</span>
            </Form.Item>

            <Form.Item label="Manifest Id" name="manifest_id">
              <span className="ant-form-text">{order?.manifest_id || '----'}</span>
            </Form.Item>

            {!!order?.copies?.length && (
              <Form.Item label="Redo On" name="copies">
                <div style={{ display: 'flex', flexDirection: 'column', padding: '5px' }}>
                  {order.copies.map(copy => (
                    <div key={copy}>
                      <Link to={`/orders/edit/${copy}`}>{copy}</Link>
                    </div>
                  ))}
                </div>
              </Form.Item>
            )}

            {order?.redo_id && (
              <Form.Item label="Redo Of" name="redo_id">
                <Link to={`/orders/edit/${order.redo_id}`}>{order.redo_id}</Link>
              </Form.Item>
            )}

            <Form.Item label="Redo " name="redo_of_id">
              <Button disabled={!disableRedoCreate} onClick={() => createRedo(order?.id)}>
                Create Redo
              </Button>
            </Form.Item>

            <Form.Item label="Employee">
              <Form.Item name="employee_id">
                <Select
                  options={employees}
                  allowClear
                  onChange={onEmployeeChange}
                  value={selectedEmployeeId}
                />
              </Form.Item>

              {currentUser.id !== selectedEmployeeId && (
                <Form.Item name="employee_id">
                  <Button className="mt-1" onClick={() => onEmployeeChange(currentUser.id)}>
                    Assign to me
                  </Button>
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item label="QA Employee">
              <Form.Item name="qa_employee_id">
                <Select options={employees} allowClear />
              </Form.Item>
            </Form.Item>

            <Form.Item label="Message" name="message">
              <Input.TextArea rows={5} />
            </Form.Item>

            <Form.Item label="Wishes" name="wishes">
              <Input.TextArea rows={4} />
            </Form.Item>

            <Form.Item label="Transaction Id" name="transaction_id">
              <span className="ant-form-text">{order?.transaction_id || '----'}</span>
            </Form.Item>

            <Form.Item label="For Free" name="for_free" valuePropName="checked">
              <Checkbox disabled />
            </Form.Item>

            <Form.Item label="Payment Method" name="payment_method">
              <span className="ant-form-text">{order?.invoiced ? 'Invoice' : 'Credit card'}</span>
            </Form.Item>

            <Form.Item label="Tax exempt" name="tax_exempt">
              <span className="ant-form-text">{order?.tax_exempt ? 'YES' : 'NO'}</span>
            </Form.Item>

            <Form.Item label="Font" name="font">
              <Select options={fonts} allowClear />
            </Form.Item>

            <Form.Item label="Signature Size Offset" name="size_offset">
              <InputNumber defaultValue={order.size_offset} min={-99} max={99} addonAfter="%" />
            </Form.Item>

            <Form.Item label="Order Price" name="order_price">
              <span className="ant-form-text">{order?.order_price || '----'}</span>
            </Form.Item>

            <Form.Item label="Card Price" name="card_price">
              <span className="ant-form-text">{order?.card_price || '----'}</span>
            </Form.Item>

            {order?.price_orig && (
              <>
                <Form.Item label="Price Without Discount" name="price_orig">
                  <span className="ant-form-text">{order?.price_orig}</span>
                </Form.Item>

                <Form.Item label="Discount" name="discount_percents">
                  <span className="ant-form-text">{order?.discount_percents} %</span>
                </Form.Item>
              </>
            )}

            {!wrongData.includes('to') && (
              <Form.Item
                label="Postage"
                name="postage"
                className={order?.to?.country_id !== 1 ? `yellow-bg` : ''}
              >
                <span className="ant-form-text">{order?.postage || '----'}</span>
              </Form.Item>
            )}

            <Form.Item label="Tax" name="tax">
              <span className="ant-form-text">{order?.tax || '----'}</span>
            </Form.Item>

            <Form.Item label="Payed" name="payed">
              <span className="ant-form-text">{order?.payed || '----'}</span>
            </Form.Item>

            <Form.Item label="Refund Status" name="refund_status">
              <span className="ant-form-text">
                {refundStatus?.find(item => item.value === order?.refund_status)?.text || '----'}
              </span>
            </Form.Item>

            <Form.Item label="Date Refunded" name="date_refunded">
              <span className="ant-form-text">{order?.date_refunded || '----'}</span>
            </Form.Item>

            <Form.Item label="Client Metadata" name="client_metadata">
              <span className="ant-form-text">{order.client_metadata || '----'}</span>
            </Form.Item>

            <Form.Item label="Delivery Confirmation">
              {order.delivery_confirmation ? (
                <>
                  Confirmation status:{' '}
                  <span className="ant-form-text">{order.confirmation_status || '---'}</span>
                  <br />
                  Mail phase: <span className="ant-form-text">{order.mail_phase || '---'}</span>
                </>
              ) : (
                'No'
              )}
            </Form.Item>

            <Divider orientation="left">From</Divider>

            {wrongData.includes('from') ? (
              <Alert
                message="Error"
                description="Wrong order data you can only delete this order."
                type="error"
                showIcon
              />
            ) : (
              <>
                <Form.Item
                  label="Name"
                  name={['from', 'name']}
                  rules={[
                    {
                      max: MAX_NAME_LENGTH,
                      message: `Name must be less than ${MAX_NAME_LENGTH} characters`,
                    },
                    {
                      required: true,
                      message: 'Please input name!',
                    },
                  ]}
                >
                  <Input maxLength={MAX_NAME_LENGTH} showCount />
                </Form.Item>

                <Form.Item
                  label="Business Name"
                  name={['from', 'business_name']}
                  rules={[
                    {
                      max: MAX_NAME_LENGTH,
                      message: `Business Name must be less than ${MAX_NAME_LENGTH} characters`,
                    },
                  ]}
                >
                  <Input maxLength={MAX_NAME_LENGTH} showCount />
                </Form.Item>

                <Form.Item
                  label="Address1"
                  name={['from', 'address1']}
                  rules={[
                    {
                      required: true,
                      message: 'Please input address1!',
                    },
                    {
                      max: MAX_ADDRESS_LENGTH,
                      message: `Address1 must be less than ${MAX_ADDRESS_LENGTH} characters`,
                    },
                  ]}
                >
                  <Input maxLength={MAX_ADDRESS_LENGTH} showCount />
                </Form.Item>

                <Form.Item
                  label="Address2"
                  name={['from', 'address2']}
                  rules={[
                    {
                      max: MAX_ADDRESS_LENGTH,
                      message: `Address2 must be less than ${MAX_ADDRESS_LENGTH} characters`,
                    },
                  ]}
                >
                  <Input maxLength={MAX_ADDRESS_LENGTH} showCount />
                </Form.Item>

                <Form.Item
                  label="City"
                  name={['from', 'city']}
                  rules={[
                    {
                      required: true,
                      message: 'Please input city!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="State"
                  name={['from', 'state']}
                  rules={[
                    {
                      required: requiredFromState,
                    },
                  ]}
                >
                  <Select
                    options={statesFrom.map(({ short_name }) => ({
                      value: short_name,
                      label: short_name,
                    }))}
                    allowClear
                    disabled={!requiredFromState}
                  />
                </Form.Item>

                <Form.Item
                  label="Zip"
                  name={['from', 'zip']}
                  rules={[
                    {
                      required: true,
                      message: 'Please input zip!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item label="Country" name={['from', 'country_id']}>
                  <Select
                    options={countries}
                    allowClear
                    onChange={value => {
                      setSelectedCountryId({ ...selectedCountryId, from: value })
                      resetState('from')
                    }}
                  />
                </Form.Item>

                <Form.Item label="Birthday" name={['from', 'birthday']}>
                  <DatePicker />
                </Form.Item>
              </>
            )}

            <Divider orientation="left">To</Divider>

            {wrongData.includes('to') ? (
              <Alert
                message="Error"
                description="Wrong order data you can only delete this order."
                type="error"
                showIcon
              />
            ) : (
              <>
                <Form.Item
                  label="Name"
                  name={['to', 'name']}
                  rules={[
                    {
                      required: true,
                      message: 'Please input name!',
                    },
                    {
                      max: MAX_NAME_LENGTH,
                      message: `Name must be less than ${MAX_NAME_LENGTH} characters`,
                    },
                  ]}
                >
                  <Input maxLength={MAX_NAME_LENGTH} showCount />
                </Form.Item>

                <Form.Item
                  label="Business Name"
                  name={['to', 'business_name']}
                  rules={[
                    {
                      max: MAX_NAME_LENGTH,
                      message: `Business Name must be less than ${MAX_NAME_LENGTH} characters`,
                    },
                  ]}
                >
                  <Input maxLength={MAX_NAME_LENGTH} showCount />
                </Form.Item>

                <Form.Item
                  label="Address1"
                  name={['to', 'address1']}
                  rules={[
                    {
                      required: true,
                      message: 'Please input address1!',
                    },
                    {
                      max: MAX_ADDRESS_LENGTH,
                      message: `Address1 must be less than ${MAX_ADDRESS_LENGTH} characters`,
                    },
                  ]}
                >
                  <Input maxLength={MAX_ADDRESS_LENGTH} showCount />
                </Form.Item>

                <Form.Item
                  label="Address2"
                  name={['to', 'address2']}
                  rules={[
                    {
                      max: MAX_ADDRESS_LENGTH,
                      message: `Address2 must be less than ${MAX_ADDRESS_LENGTH} characters`,
                    },
                  ]}
                >
                  <Input maxLength={MAX_ADDRESS_LENGTH} showCount />
                </Form.Item>

                <Form.Item
                  label="City"
                  name={['to', 'city']}
                  rules={[
                    {
                      required: true,
                      message: 'Please input city!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="State"
                  name={['to', 'state']}
                  rules={[
                    {
                      required: requiredToState,
                    },
                  ]}
                >
                  {requiredToState ? (
                    <Select
                      options={statesTo.map(({ short_name }) => ({
                        value: short_name,
                        label: short_name,
                      }))}
                      allowClear
                    />
                  ) : (
                    <Input />
                  )}
                </Form.Item>

                <Form.Item
                  label="Zip"
                  name={['to', 'zip']}
                  rules={[
                    {
                      required: true,
                      message: 'Please input zip!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item label="Country" name={['to', 'country_id']}>
                  <Select
                    options={countries}
                    allowClear
                    onChange={value => {
                      setSelectedCountryId({ ...selectedCountryId, to: value })
                      resetState('to')
                    }}
                  />
                </Form.Item>

                <Form.Item label="Birthday" name={['to', 'birthday']}>
                  <DatePicker />
                </Form.Item>
              </>
            )}
            <Divider />

            <Form.Item label="Webhook Url" name="webhook_url">
              <Input />
            </Form.Item>

            {!wrongData.length && (
              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={!changed}>
                  Save
                </Button>
              </Form.Item>
            )}
          </Form>
        </>,
      )}
    </NavContainer>
  )
}

export default EditItem
