import axios from 'axios'
import serialize from '_helpers/hw-serialize-params'
import apiUrl from './service'
import { v1 } from './api'
import downloader from '../_helpers/file-download'

const getAll = async (params, typeAction) => {
  const result = await axios.get(`${apiUrl}/${v1}/cards?${serialize(params)}`, {
    params: { stopSpinner: typeAction },
  })
  return handleResponse(result)
}

const getItemById = async id => {
  const result = await axios.get(`${apiUrl}/${v1}/cards/${id}`)
  return handleResponse(result)
}

const deleteItem = async value => {
  const result = await axios.delete(`${apiUrl}/${v1}/cards/${value}`, {
    params: { stopSpinner: 'paginate' },
  })
  return handleResponse(result)
}

const addItem = async ({ value }) => {
  const result = await axios.post(`${apiUrl}/${v1}/cards`, JSON.stringify(value))
  return handleResponse(result)
}

const updateItemById = async (id, value) => {
  const result = await axios.put(`${apiUrl}/${v1}/cards/${id}`, JSON.stringify({ changes: value }))
  return handleResponse(result)
}

const generatePdf = async (id, withQr, onDownload) => {
  const result = await axios.get(`${apiUrl}/${v1}/cards/generatePdf/${id}?withQR=${withQr}`, {
    params: { stopSpinner: 'background' },
    responseType: 'blob',
    onDownloadProgress: onDownload,
  })

  const filename = `${result.headers['card-name']}.pdf`

  downloader(result.data, filename)
}

const handleResponse = result => {
  return result.data
}

const cardsService = {
  getAll,
  getItemById,
  deleteItem,
  updateItemById,
  addItem,
  generatePdf,
}

export default cardsService
