import React, { useMemo } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Breadcrumb } from 'antd'
import { appConstants } from '_constants'
import { queryParamsActions } from '_actions'
import useAddValueToClipboard from '../../../../hooks/clipboard/useAddValueToClipboard.js'

const { replaceBreadcrumbsTitles, disabledLinks } = appConstants

const Breadcrumbs = ({ location = [] }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const whereQueryParams = useSelector(state => state.queryParams.where)

  const pathNames = useMemo(() => {
    return location.pathname
      .split('/')
      .filter(x => x)
      .map(el => el.replaceAll('-', ' '))
  }, [location])

  const { addValueToClipboard, isEditOrViewPage, contextHolder } = useAddValueToClipboard(pathNames)

  const toPath = (path, isDisableLink = false) => {
    if (whereQueryParams && !isDisableLink && breadcrumbsList[0].key === path)
      dispatch(queryParamsActions.clearQueryParams())
    if (!isDisableLink) history.push(`/${path}`)
  }

  const firstChartToAppearCase = title => {
    return (
      title
        .toString()
        .slice(0, 1)
        .toUpperCase() + title.toString().slice(1)
    )
  }

  const toKebabCase = string => string.split(' ').join('-')

  const breadcrumbsList = pathNames.reduce((acc, value, index) => {
    const isDisableLink =
      index === pathNames.length - 1 ||
      disabledLinks.includes(toKebabCase(value)) ||
      (pathNames.includes('automation orders') && index === pathNames.length - 2)

    let viewNameShow = value

    let currentBreadcrumbsTitle = null
    let queryParam = null
    const checkViewNames = replaceBreadcrumbsTitles.filter(
      titleArray => titleArray.value.toLowerCase() === value.toString().toLowerCase(),
    )

    checkViewNames.forEach((item, index, all) => {
      if (whereQueryParams?.[item.queryKey]) {
        queryParam = whereQueryParams[item.queryKey]
        currentBreadcrumbsTitle = item
      }
      if (!currentBreadcrumbsTitle && all.length === 1) {
        currentBreadcrumbsTitle = item
      }
    })

    if (currentBreadcrumbsTitle) {
      const { queryKey, replaceValue } = currentBreadcrumbsTitle
      let { from } = currentBreadcrumbsTitle

      if (replaceValue) {
        viewNameShow = replaceValue
      }

      if (from && queryKey && queryParam) {
        const checkViewName = replaceBreadcrumbsTitles.find(
          titleArray => titleArray.value.toLowerCase() === from.toString().toLowerCase(),
        )
        if (checkViewName?.replaceValue) {
          from = toKebabCase(checkViewName.replaceValue)
        }
        acc.push(
          <Breadcrumb.Item key={from} onClick={() => toPath(from)} style={{ cursor: 'pointer' }}>
            {firstChartToAppearCase(from)}
          </Breadcrumb.Item>,
        )

        acc.push(
          <Breadcrumb.Item
            key={queryKey + queryParam}
            onClick={() => toPath(`${from}/view/${queryParam}`)}
            style={{ cursor: 'pointer' }}
          >
            {firstChartToAppearCase(queryParam)}
          </Breadcrumb.Item>,
        )
      }
    }

    const withoutLastParam =
      acc?.some(breadcrumb => breadcrumb?.key?.includes('automation orders')) &&
      index === pathNames.length - 1 // last element

    if (!withoutLastParam) {
      const formattedValue = value.split(' ').join('-')
      acc.push(
        <Breadcrumb.Item
          key={value}
          onClick={() => toPath(formattedValue, isDisableLink)}
          style={{ cursor: !isDisableLink ? 'pointer' : 'default' }}
        >
          {firstChartToAppearCase(viewNameShow)}
        </Breadcrumb.Item>,
      )
    }

    return acc
  }, [])

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {contextHolder}
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push('/')} style={{ cursor: 'pointer' }}>
          Home
        </Breadcrumb.Item>
        {breadcrumbsList}
      </Breadcrumb>
      {isEditOrViewPage && (
        <span
          onClick={() => addValueToClipboard(pathNames[pathNames.length - 1])}
          style={{ marginLeft: '1rem', cursor: 'pointer' }}
          className="fe fe-copy"
        />
      )}
    </div>
  )
}

export default withRouter(Breadcrumbs)
