import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import v4 from 'uuid/v4'
import getCurrentUrl from '../../../../_helpers/url-utils'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import { prePaidConfigsActions } from '../../../../_actions'
import { prePaidConfigsConstants, settingsConstants } from '../../../../_constants'
import { getPrePaidConfigsRows, getPage, getTotalItems } from '_selectors/pre-paid-configs.selector'

const { navMenu, statuses } = prePaidConfigsConstants

const statusRender = text => {
  return `${statuses.find(st => st.value.toString() === text.toString())?.text}`
}

const percentageRender = num => `${Number(num).toFixed()}%`

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '10%',
  },
  {
    title: 'Cards From',
    dataIndex: 'quantity_from',
    key: 'quantity_from',
    sorter: true,
  },
  {
    title: 'Cards To',
    dataIndex: 'quantity_to',
    key: 'quantity_to',
    sorter: true,
  },
  {
    title: 'Discount',
    dataIndex: 'discount',
    key: 'discount',
    sorter: true,
    hideFilter: true,
    render: percentageRender,
  },
  {
    title: 'Price Per Card',
    dataIndex: 'price_per_card',
    key: 'price_per_card',
    sorter: true,
    hideFilter: true,
  },
  {
    title: 'Postage',
    dataIndex: 'postage',
    key: 'postage',
    sorter: true,
    hideFilter: true,
  },
  {
    title: 'Credit Active (month)',
    dataIndex: 'credit_active_in_month',
    key: 'credit_active_in_month',
    sorter: true,
    hideFilter: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: statusRender,
    filters: statuses,
    sorter: true,
  },
]

const navMenuInit = [navMenu.list, navMenu.create]

const ManageItems = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getPrePaidConfigsRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const deleteItems = async id => {
    await dispatch(prePaidConfigsActions.deleteItem(id))
  }

  const navigateToList = () => {
    dispatch({ type: prePaidConfigsConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}/list`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.list.key:
          return {
            ...menu,
            url: navigateToList,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  return (
    <NavContainer match={match} menu={navMenuParse}>
      <TableFilter
        deleteItems={deleteItems}
        columns={initColumns}
        data={dataSource}
        getDataAction={prePaidConfigsActions.getAll}
      />
    </NavContainer>
  )
}

export default ManageItems
