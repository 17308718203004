import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import v4 from 'uuid/v4'
import TableFilter from 'components/table-filter'
import NavContainer from 'components/nav-container'
import { clientNotesActions } from '_actions'
import { notesConstants } from '_constants'
import {
  getClientNotesRows,
  getPage,
  getTotalItems,
} from '../../../../_selectors/client-notes.selector.js'

const { navMenu, advancedSearch } = notesConstants

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '10%',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'User id',
    dataIndex: 'user_id',
    key: 'user_id',
    width: '10%',
  },
  {
    title: 'Timestamp',
    dataIndex: 'timestamp',
    key: 'timestamp',
    width: '10%',
  },
  {
    title: 'Note',
    dataIndex: 'note',
    key: 'note',
    width: '50%',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render(children) {
      return {
        props: {
          style: {
            maxWidth: '100px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        },
        children,
      }
    },
  },
  {
    title: 'Employee id',
    dataIndex: 'employee_id',
    key: 'employee_id',
    width: '10%',
  },
]

const navMenuInit = [navMenu.list, navMenu.create]

const ManageNotes = ({ match }) => {
  const { pathname } = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  const rows = useSelector(getClientNotesRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const [navMenuParse, setNavMenuParse] = useState([])

  useEffect(() => {
    return () => {
      dispatch({ type: notesConstants.SETALL })
    }
  }, [])

  const deleteItem = async id => {
    await dispatch(clientNotesActions.deleteItem(id))
  }

  const onListItems = () => {
    history.push({
      pathname: `${pathname}/list`,
    })
  }

  const onCreateItem = () => {
    history.push({
      pathname: `${pathname}/create`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.list.key:
          return {
            ...menu,
            url: onListItems,
          }
        case navMenu.create.key:
          return {
            ...menu,
            url: onCreateItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  return (
    <NavContainer match={match} menu={navMenuParse}>
      <TableFilter
        deleteItems={deleteItem}
        columns={initColumns}
        data={dataSource}
        getDataAction={clientNotesActions.getAll}
        advancedSearch={advancedSearch}
      />
    </NavContainer>
  )
}

export default ManageNotes
