import { clientGroupConstants } from '../_constants'
import { clientService } from '../_services'
import { history } from '../_helpers'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: clientGroupConstants.GETALL_REQUEST })
  try {
    const clientGroups = await clientService.getAll(params, typeAction)
    dispatch({ type: clientGroupConstants.GETALL_SUCCESS, payload: clientGroups.data })
  } catch (error) {
    dispatch({ type: clientGroupConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const addItem = value => async dispatch => {
  dispatch({ type: clientGroupConstants.ADD_REQUEST })

  try {
    const clientGroup = await clientService.addItem(value)
    dispatch({ type: clientGroupConstants.ADD_SUCCESS, payload: { ...value, id: clientGroup.id } })
    history.push(`/client-groups/view/${clientGroup.id}`)
  } catch (error) {
    dispatch({ type: clientGroupConstants.ADD_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: clientGroupConstants.GETBYID_REQUEST })

  try {
    const clientGroup = await clientService.getItemById(id)
    dispatch({ type: clientGroupConstants.GETBYID_SUCCESS, payload: clientGroup.data })
    return clientGroup
  } catch (error) {
    dispatch({ type: clientGroupConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const deleteItem = id => async dispatch => {
  dispatch({ type: clientGroupConstants.DELETE_REQUEST })
  try {
    await clientService.deleteItem(id)
    history.push(`/client-groups`)
    dispatch({ type: clientGroupConstants.DELETE_SUCCESS, payload: id })
  } catch (error) {
    dispatch({ type: clientGroupConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const updateItemById = (id, value) => async dispatch => {
  dispatch({ type: clientGroupConstants.UPDATE_REQUEST })

  try {
    const updated = await clientService.updateItemById(id, value)
    dispatch({ type: clientGroupConstants.UPDATE_SUCCESS, payload: updated?.message?.toString() })
    history.push(`/client-groups/view/${id}`)
  } catch (error) {
    dispatch({ type: clientGroupConstants.UPDATE_FAILURE, payload: error.toString() })
  }
}

const clientGroupActions = {
  getAll,
  getItemById,
  updateItemById,
  deleteItem,
  addItem,
}

export default clientGroupActions
