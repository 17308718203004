import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import NavContainer from '../../../../components/nav-container'
import ItemViewer from '../../../../components/item-viewer'
import { taxCodesActions } from '../../../../_actions'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { taxCodesConstants } from '../../../../_constants'

const { viewItemKeyToLabel, navMenu, taxCodesItems, taxCodesServices } = taxCodesConstants

const navMenuInit = [navMenu.list, navMenu.edit, navMenu.manage]

const ViewItem = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const match = useRouteMatch()
  const { id } = useParams()

  const {
    item: { taxCode },
  } = useSelector(state => state.taxcodes)

  const [navMenuParse, setNavMenuParse] = useState([])
  const view = getCurrentUrl(match.url)

  const onEditItem = () => {
    history.push({
      pathname: `${view}/edit/${id}`,
    })
  }

  useEffect(() => {
    dispatch(taxCodesActions.getItemById(id))
    return () => {
      dispatch({ type: taxCodesConstants.SETBYID })
    }
  }, [])

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.edit.key:
          return {
            ...menu,
            url: onEditItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const viewItem = useMemo(() => {
    if (taxCode) {
      return Object.keys(viewItemKeyToLabel).reduce((obj, sKey) => {
        let itemValue
        if (sKey === 'tax_item') {
          itemValue = taxCodesItems.find(item => item.value === taxCode[sKey]).text
        } else if (sKey === 'service') {
          itemValue = taxCodesServices.find(item => item.value === taxCode[sKey]).text
        } else {
          itemValue = taxCode[sKey]
        }
        return {
          ...obj,
          [sKey]: itemValue,
        }
      }, {})
    }
    return {}
  }, [taxCode])

  return (
    <NavContainer menu={navMenuParse}>
      <ItemViewer item={viewItem} keyToLabelMap={viewItemKeyToLabel} />
    </NavContainer>
  )
}

export default ViewItem
