import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import v4 from 'uuid/v4'
import { Link } from 'react-router-dom'
import { Checkbox } from 'antd'
import getCurrentUrl from '../../../../_helpers/url-utils'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import { fontActions } from '../../../../_actions'
import { fontConstants, settingsConstants, spinnerConstants } from '../../../../_constants'
import { getPage, getFontsRows, getTotalItems } from '../../../../_selectors/fonts.selector.js'
import { getCanEditFonts, getIsSuperUser } from '../../../../_selectors/authentication.selector.js'

const { navMenu, renderComponent, advancedSearch } = fontConstants

const initColumns = [
  {
    title: 'Font ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Font Name',
    dataIndex: 'font_name',
    key: 'font_name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Font Title',
    dataIndex: 'label',
    key: 'label',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Preview',
    dataIndex: 'image',
    key: 'image',
    hideFilter: true,
  },
  {
    title: 'Client Groups',
    dataIndex: 'user_groups',
    key: 'user_groups',
    hideFilter: true,
  },
  {
    title: 'Visible',
    dataIndex: 'visible',
    key: 'visible',
    hideFilter: true,
  },
]

const navMenuInit = [navMenu.list, navMenu.create]

const ManageItems = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)

  const superuser = useSelector(getIsSuperUser)
  const can_edit_fonts = useSelector(getCanEditFonts)

  const [navMenuParse, setNavMenuParse] = useState([])
  const [changedVisible, setChangeVisible] = useState(false)

  const rows = useSelector(getFontsRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const deleteItems = async id => {
    await dispatch(fontActions.deleteItem(id))
  }

  const navigateToList = () => {
    dispatch({ type: fontConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}/list`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.list.key:
          return {
            ...menu,
            url: navigateToList,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const changeVisible = async ({ target }, id) => {
    const font = rows.find(item => item.id === id)

    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: 'change-visible' })
    dispatch({ type: spinnerConstants.START_ON_MANAGE_PAGE })
    // true - mean that is change visible on manage page
    await dispatch(fontActions.updateItemById(id, { ...font, visible: target.checked }, true))
    setChangeVisible(!changedVisible)
  }

  const canEdit = superuser || can_edit_fonts

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        user_groups: item.user_groups.map(group => (
          <Link key={group.id} to={`/client-groups/edit/${group.id}`}>
            {group.title}
            <br />
          </Link>
        )),
        visible: (
          <Checkbox
            defaultChecked={item.visible === 1}
            onChange={e => changeVisible(e, item.font_id)}
            data-visible="visible"
          />
        ),
        image: item.image ? renderComponent.image(item.image, item.font_name) : '----',
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  return (
    <NavContainer menu={navMenuParse}>
      <TableFilter
        deleteItems={deleteItems}
        columns={initColumns}
        data={dataSource}
        getDataAction={fontActions.getAll}
        actionsColum={['edit', 'delete']}
        isEditOnId={!!canEdit}
        advancedSearch={advancedSearch}
        changedVisible={changedVisible}
        showAdvancedButton={!!true}
      />
    </NavContainer>
  )
}

export default ManageItems
