import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { RedirectPage } from 'containers/layouts'
import { CreateItem, EditItem, ListItems, ManageItems, ViewItem } from './views'
import Content from '../../components/content'
// import { RedirectPage } from "../../containers/layouts"

const ClientGroups = () => {
  const permis = useSelector(state => state.users.permis)
  const permisKeys = useMemo(() => {
    return Object.keys(permis)
  }, [permis])

  const match = useRouteMatch()
  return (
    <Content>
      <Switch>
        <Route exact path={match.url} component={ManageItems} />
        <Route path={`${match.url}/list`} component={ListItems} />
        <Route path={`${match.url}/create`} component={CreateItem} />
        <Route
          path={`${match.url}/edit/:id`}
          component={
            permisKeys.includes('superuser') || permisKeys.includes('can_edit_users')
              ? EditItem
              : RedirectPage
          }
        />
        <Route path={`${match.url}/view/:id`} component={ViewItem} />

        <Redirect to="/error" />
      </Switch>
    </Content>
  )
}

export default ClientGroups
