import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'

import NavContainer from '../../../../components/nav-container'
import ItemViewer from '../../../../components/item-viewer'
import { automationsConstants } from '../../../../_constants'
import automationActions from '_actions/automations.actions'
import { getAutomationById } from '../../../../_selectors/automations.selector.js'

const { navMenu, viewItemKeyToLabel, automationType, automationStatus } = automationsConstants

const navMenuInit = [navMenu.manage, navMenu.manageOrders, navMenu.manageRecipients]

const ViewItem = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const history = useHistory()

  const [navMenuParse, setNavMenuParse] = useState([])

  const automation = useSelector(getAutomationById)

  useEffect(() => {
    dispatch(automationActions.getItemById(id))
    return () => {
      dispatch({ type: automationsConstants.SETBYID })
    }
  }, [id, dispatch])

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.manageOrders.key:
          return {
            ...menu,
            url: navigateToAutomationOrders,
            count: automation?.total_orders,
          }
        case navMenu.manageRecipients.key:
          return {
            ...menu,
            url: navigateToAutomationRecipients,
            count: automation?.total_address,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit, automation])

  const navigateToAutomationOrders = () => {
    history.push({
      pathname: `/automation-orders/${automation.id}/${automation?.orders?.join(',') || ''}`,
    })
  }

  const navigateToAutomationRecipients = () => {
    history.push({
      pathname: `/automation-recipients/${automation.id}`,
    })
  }

  const viewItem = useMemo(() => {
    if (automation) {
      return Object.keys(viewItemKeyToLabel).reduce((obj, sKey) => {
        let itemValue = automation[sKey]
        if (sKey === 'user') {
          itemValue = (
            <Link to={`/clients/edit/${automation.user?.id}`}>
              [ID: {automation.user?.id}] {automation.user?.name}
            </Link>
          )
        } else if (sKey === 'type') {
          itemValue = `${automationType.find(st => st.value === itemValue)?.text}`
        } else if (sKey === 'status') {
          itemValue = itemValue
            ? `${automationStatus.find(st => st.value === itemValue)?.text}`
            : '---'
        } else if (sKey === 'total_address') {
          itemValue = itemValue ? (
            <Link to={`/automation-recipients/${automation.id}`}>{itemValue}</Link>
          ) : (
            itemValue
          )
        } else if (sKey === 'delivery_confirmation') {
          itemValue = itemValue ? 'YES' : 'NO'
        } else if (sKey === 'total_orders') {
          itemValue = itemValue ? (
            <Link to={`/automation-orders/${automation.id}/${automation?.orders?.join(',')}`}>
              {itemValue}
            </Link>
          ) : (
            itemValue
          )
        }
        return {
          ...obj,
          [sKey]: itemValue ?? '---',
        }
      }, {})
    }
    return {}
  }, [automation])

  return (
    <NavContainer menu={navMenuParse}>
      <ItemViewer item={viewItem} keyToLabelMap={viewItemKeyToLabel} />
    </NavContainer>
  )
}
export default ViewItem
