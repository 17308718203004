import { usaFinancialConstants } from '../_constants'

const initialState = {
  items: [],
  item: {},
  triggers: {},
}

function usaFinancial(state = initialState, { type, payload }) {
  switch (type) {
    case usaFinancialConstants.GETALL_SUCCESS:
      return { ...state, items: payload, item: {} }
    case usaFinancialConstants.GETBYID_SUCCESS:
      return { ...state, item: payload }
    case usaFinancialConstants.DELETE_SUCCESS:
      return { ...state }
    case usaFinancialConstants.UPDATE_SUCCESS:
      return { ...state, items: state.items }
    case usaFinancialConstants.GET_USA_FINANCIAL_TRIGGER_SUCCESS:
      return { ...state, triggers: payload }
    case usaFinancialConstants.ADD_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          total: state.items.total + 1,
        },
      }
    case usaFinancialConstants.SETBYID:
      return {
        ...state,
        item: {},
        triggers: {},
      }
    case usaFinancialConstants.SETALL:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default usaFinancial
