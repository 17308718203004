const serialize = (params, prefix) => {
  if (typeof params === 'undefined') {
    return ''
  }
  const str = []
  const paramsKey = Object.keys(params)
  if (paramsKey.length !== 0) {
    paramsKey.forEach(param => {
      if (param === 'date' && !prefix) {
        const query = params[param]
        if (query) {
          const dateKeys = Object.keys(query)
          if (dateKeys.length !== 0) {
            const result = serialize(query, param)
            if (!(result === '' || Object.keys(query).length === 0)) {
              str.push(result)
            }
          }
        }
      } else if (param === 'attrs') {
        if (params[param]) {
          const { fields, exclude } = params[param]
          if (Array.isArray(fields)) {
            const stringFields = fields.join(',')
            const query = exclude ? `${param}[exclude]=${stringFields}` : `${param}=${stringFields}`
            str.push(query)
          }
        }
      } else if (param === 'like') {
        const query = params[param]
        if (query) {
          const parseLike = {}
          const likeKeys = Object.keys(query)
          if (likeKeys.length !== 0) {
            likeKeys.forEach(key => {
              parseLike[key] = query[key]
            })
            const result = serialize(parseLike, param)
            str.push(result)
          }
        }
      } else if (param === 'where' || param === 'relation') {
        const query = params[param]
        if (query) {
          const whereKeys = Object.keys(query)
          if (whereKeys.length !== 0) {
            const result = serialize(query, param)
            if (!(result === '' || Object.keys(query).length === 0)) {
              str.push(result)
            }
          }
        }
      } else if (param === 'order') {
        if (params[param]) {
          const { key, order } = params[param]
          let query
          if (key) {
            if (key.includes('=>')) {
              const parseParam = key.split('=>')
              query = `order[${parseParam[0]}]=${parseParam[1]},${order}`
            } else if (key.includes('filter')) {
              const parseParam = key.split('_filter')
              query = `order[${parseParam[0]}]=${order}`
            } else {
              query = `order[${key}]=${order}`
            }
            str.push(query)
          }
        }
      } else if (params[param] || typeof params[param] === 'number' || prefix) {
        let query
        if (prefix && param === 'redo_id') {
          query = `${prefix}[${param}]=${params[param] ? '<> null' : null}`
        } else if (prefix === 'date') {
          query = `date[${param}]=${params[param]}`
        } else if (param.includes('=>')) {
          const parseParam = param.split('=>')
          query = prefix ? `where[${parseParam[0]}]=${parseParam[1]},${params[param]}` : ''
        } else if (param.includes('filter')) {
          const parseParam = param.split('_filter')
          query = prefix ? `where[${parseParam[0]}]=${params[param]}` : ''
        } else {
          query = prefix ? `${prefix}[${param}]=${params[param]}` : `${param}=${params[param]}`
        }
        str.push(query)
      }
    })
    return str.join('&')
  }
  return ''
}

export default serialize
