import { useSelector } from 'react-redux'
import { Skeleton } from 'antd'
import React from 'react'

const useGlobalLoader = (FromReactComponent, page = null) => {
  const { isGlobalLoading } = useSelector(state => state.spinner)

  if (isGlobalLoading)
    return <Skeleton active paragraph={{ rows: page === 'batch-orders' ? 10 : 4 }} />
  return FromReactComponent
}

export default useGlobalLoader
