import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import v4 from 'uuid/v4'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import { shopifyActions, queryParamsActions } from '../../../../_actions'
import { shopifyConstants } from '../../../../_constants'
import { getCurrentUrl } from '_helpers'
import { useHistory } from 'react-router'
import {
  getIssueItemsPage,
  getIssueItemsTotalItems,
  getShopifyIssues,
  getShopifyIssuesItemsRows,
} from '_selectors/shopify.selector'

const { navMenu } = shopifyConstants

const navMenuInit = [navMenu.manage]

const initColumns = [
  {
    title: 'Shop ID',
    dataIndex: 'shop_id',
    key: 'shop_id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'User',
    dataIndex: 'user_id',
    key: 'user_id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Shop',
    dataIndex: 'myshopify_domain',
    key: 'myshopify_domain',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Count of trigger',
    dataIndex: 'count_of_triggers',
    key: 'count_of_triggers',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Shopify Triggers',
    dataIndex: 'shopify_triggers',
    key: 'shopify_triggers',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
]

const IssuesItems = ({ match }) => {
  const dispatch = useDispatch()
  const [navMenuParse, setNavMenuParse] = useState([])
  const view = getCurrentUrl(match.url)
  const history = useHistory()

  const rows = useSelector(getShopifyIssuesItemsRows)
  const page = useSelector(getIssueItemsPage)
  const total = useSelector(getIssueItemsTotalItems)
  const issues = useSelector(getShopifyIssues)

  const whereQueryParams = useSelector(state => state.queryParams.where)

  useEffect(() => {
    if (issues?.shop_ids) {
      dispatch(queryParamsActions.setQueryParams({ shop_id: issues.shop_ids.join(',') }))
    }
  }, [issues?.shop_ids])

  const navigateToManageShops = () => {
    dispatch(queryParamsActions.setQueryParams())
    history.push({
      pathname: `${view}`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.manage.key:
          return {
            ...menu,
            url: navigateToManageShops,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  useEffect(() => {
    if (issues && !issues?.count) {
      dispatch(queryParamsActions.setQueryParams())
      history.push({
        pathname: `${view}`,
      })
    }
  }, [issues?.count])

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        shopify_triggers: item.shopify_triggers.map(trigger => (
          <React.Fragment key={trigger.id}>
            {trigger.event.split('_').join(' ')}{' '}
            {trigger.purchase_threshold || trigger.purchase_quantity || trigger.annual_interval
              ? `(${trigger.purchase_threshold ||
                  trigger.purchase_quantity ||
                  trigger.annual_interval})`
              : ''}
            <br />
          </React.Fragment>
        )),
        count_of_triggers: item.shopify_triggers.length,
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  // if not set shop_id to query params, then not get shops
  if (!whereQueryParams?.shop_id)
    return (
      <NavContainer match={match} menu={navMenuParse}>
        <TableFilter columns={initColumns} data={[]} />
      </NavContainer>
    )

  return (
    <NavContainer match={match} menu={navMenuParse}>
      <TableFilter
        columns={initColumns}
        data={dataSource}
        getDataAction={shopifyActions.getIssuesShops}
        actionsColum={['resubscribe']}
        reSubscribeShop={shopifyActions.reSubscribeShop}
      />
    </NavContainer>
  )
}

export default IssuesItems
