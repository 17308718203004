import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, DatePicker, Form, Input, InputNumber, Radio, Select } from 'antd'
import NavContainer from '../../../../components/nav-container'
import { usaFinancialActions } from '../../../../_actions'
import { usaFinancialConstants } from '../../../../_constants'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import { convertDataForSelect } from '../../../../_helpers'

const { navMenu, formItemLayout, statuses, radioButton, defaultRadioButton } = usaFinancialConstants

const navMenuInit = [navMenu.list, navMenu.manage]

const CreateItem = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [radioButtonValue, setRadioButtonValue] = useState(radioButton[0].value)

  const visibleDatePicker = useMemo(() => {
    return radioButtonValue === defaultRadioButton
  }, [radioButtonValue, radioButton, defaultRadioButton])

  const onFinish = async values => {
    const convert = convertFormValuesDeep({
      ...values,
      date: visibleDatePicker ? values.date.toString() : null,
      key: values.key !== defaultRadioButton ? values.key : null,
    })
    await dispatch(usaFinancialActions.addItem(convert))
  }

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      <Form {...formItemLayout} name="basic" form={form} onFinish={onFinish} scrollToFirstError>
        <Form.Item
          label="Label"
          name="label"
          rules={[
            {
              required: true,
              message: 'Please input Label!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Trigger Type" name="key">
          <Radio.Group
            onChange={event => setRadioButtonValue(event.target.value)}
            defaultValue={defaultRadioButton}
          >
            <div className="row" style={{ margin: '0 1rem' }}>
              {radioButton.map(radio => (
                <div className="mb-2">
                  <Radio value={radio.value}>{radio.title}</Radio>
                </div>
              ))}
            </div>
          </Radio.Group>
        </Form.Item>
        {visibleDatePicker && (
          <Form.Item
            label="Date"
            name="date"
            rules={[
              {
                required: true,
                message: 'Please choose date!',
              },
            ]}
          >
            <DatePicker format="MM-DD" />
          </Form.Item>
        )}

        <Form.Item label="Default Card ID" name="default_card_id">
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item label="Sort Order" name="sort_order" initialValue={0}>
          <InputNumber step={1} min={0} />
        </Form.Item>
        <Form.Item name="status" label="Status" initialValue={0}>
          <Select options={convertDataForSelect(statuses)} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </NavContainer>
  )
}

export default CreateItem
