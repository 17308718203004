import axios from 'axios'
import serialize from '_helpers/hw-serialize-params'
import downloader from '_helpers/file-download'
import apiUrl from './service'
import { v1 } from './api'

const getAll = async (params, typeAction) => {
  const result = await axios.get(`${apiUrl}/${v1}/signatures?${serialize(params)}`, {
    params: { stopSpinner: typeAction },
  })
  return handleResponse(result)
}

const addItem = async ({ value }) => {
  const result = await axios.post(`${apiUrl}/${v1}/signatures`, JSON.stringify(value))
  return handleResponse(result)
}

const getItemById = async id => {
  const result = await axios.get(`${apiUrl}/${v1}/signatures/${id}`)
  return handleResponse(result)
}

const deleteItem = async value => {
  const result = await axios.delete(`${apiUrl}/${v1}/signatures/${value}`, {
    params: { stopSpinner: 'paginate' },
  })
  return handleResponse(result)
}

const updateItemById = async (id, value) => {
  const result = await axios.put(
    `${apiUrl}/${v1}/signatures/${id}`,
    JSON.stringify({ changes: value }),
  )
  return handleResponse(result)
}

const getSignatureFont = async () => {
  const result = await axios.get(`${apiUrl}/${v1}/signatures/getSignatureFont`)
  return handleResponse(result)
}

const uploadFontForSign = async ({ value }) => {
  const result = await axios.post(`${apiUrl}/${v1}/signatures/uploadFont`, JSON.stringify(value))
  return handleResponse(result)
}

const downloadCurrentFont = async isCenterline => {
  const result = await axios.get(
    `${apiUrl}/${v1}/signatures/getFont?isCenterline=${isCenterline ? 1 : 0}`,
    {
      responseType: 'blob',
    },
  )
  const fileName = isCenterline ? 'CenterlineFont.ttf' : 'font.ttf'
  downloader(result.data, fileName)
  return handleResponse(result)
}

const handleResponse = result => {
  return result.data
}

const signatureService = {
  getAll,
  getItemById,
  deleteItem,
  updateItemById,
  addItem,
  uploadFontForSign,
  downloadCurrentFont,
  getSignatureFont,
}

export default signatureService
