import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import v4 from 'uuid/v4'
import { useHistory } from 'react-router'
import getCurrentUrl from '../../../../_helpers/url-utils'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import { couponActions } from '../../../../_actions'
import { couponConstants, settingsConstants } from '../../../../_constants'
import { getCouponRows, getPage, getTotalItems } from '../../../../_selectors/coupons.selector.js'
import { getIsSuperUser } from '../../../../_selectors/authentication.selector.js'

const { navMenu, advancedSearch } = couponConstants

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Coupon name',
    dataIndex: 'code',
    key: 'code',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Coupon value',
    dataIndex: 'credit',
    key: 'credit',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Expiration date',
    dataIndex: 'expiration_date',
    key: 'expiration_date*date',
  },
]

const navMenuInit = [navMenu.list, navMenu.create]

const ManageItems = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getCouponRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const superuser = useSelector(getIsSuperUser)
  const { can_edit_coupons } = useSelector(state => state.authentication.user)

  const deleteItems = async id => {
    await dispatch(couponActions.deleteItem(id))
  }

  const navigateToList = () => {
    dispatch({ type: couponConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}/list`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.list.key:
          return {
            ...menu,
            url: navigateToList,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  const canEdit = superuser || can_edit_coupons

  return (
    <NavContainer menu={navMenuParse}>
      <TableFilter
        deleteItems={deleteItems}
        columns={initColumns}
        data={dataSource}
        getDataAction={couponActions.getAll}
        isEditOnId={!!canEdit}
        advancedSearch={advancedSearch}
      />
    </NavContainer>
  )
}

export default ManageItems
