import React, { useEffect, useMemo, useRef } from 'react'
import { Form, Button, Modal, InputNumber, Checkbox, Input, Select, Skeleton, Space } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { batchOrdersConstants } from '../../_constants'
import { impositionLayoutsActions } from '_actions'
import { impositionLayoutsSelectors } from '_selectors/imposition-layouts.selector'

import './style.scss'

const { imposedCardSizes } = batchOrdersConstants

const ImposedCardModal = ({
  isVisible,
  onOk,
  onCancel,
  singleCardMode = false,
  warningMessage = '',
  isAllCardsFlat = false,
  templateMode,
}) => {
  const dispatch = useDispatch()
  const impositionTemplates = useSelector(impositionLayoutsSelectors.getImpositionRows)

  const [form] = Form.useForm()

  const inputRef = useRef()

  const defaultSizesOptions = useMemo(
    () =>
      imposedCardSizes.map(size => ({
        value: JSON.stringify(size.value),
        label: size.text,
      })),
    [imposedCardSizes, isAllCardsFlat],
  )

  useEffect(() => {
    if (isVisible && templateMode && !impositionTemplates?.length) {
      dispatch(impositionLayoutsActions.getAll({ like: { status: 1 } }))
    }
  }, [isVisible, templateMode, impositionTemplates])

  useEffect(() => {
    form.setFieldsValue({
      sizes: !isAllCardsFlat ? defaultSizesOptions[0].label : defaultSizesOptions[1].label,
    })
  }, [isAllCardsFlat, form])

  const impositionTemplateOptions = useMemo(() => {
    if (!templateMode || !impositionTemplates) return []

    return impositionTemplates?.map(template => ({
      value: template.id,
      label: template.name,
      recommended: isAllCardsFlat
        ? +template.height === 12 && +template.width === 18 // recommended paper size for flat cards is 12x18
        : +template.height >= 13 && +template.width >= 19, // recommended paper size for NOT flat cards is 13x19 or bigger
    }))
  }, [isAllCardsFlat, templateMode, impositionTemplates])

  useEffect(() => {
    if (templateMode && impositionTemplateOptions?.length) {
      // get the first recommended value, or the first if there is no recommended one
      const defaultTemplate =
        impositionTemplateOptions.find(template => !!template.recommended) ||
        impositionTemplateOptions[0]

      form.setFieldsValue({
        imposition_id: defaultTemplate.value,
      })
    }
  }, [impositionTemplateOptions, templateMode])

  const onCloseModal = () => {
    onCancel()
    form.resetFields()
  }

  const submitHandler = async formValues => {
    let defaultSize = !isAllCardsFlat ? imposedCardSizes[0].value : imposedCardSizes[1].value

    if (templateMode) {
      const currentTemplate = impositionTemplates.find(
        template => template.id === formValues.imposition_id,
      )

      defaultSize = {
        height: +currentTemplate.height,
        width: +currentTemplate.width,
      }
    }

    await onOk(defaultSize, formValues)
  }

  const onSubmit = values => {
    submitHandler(values)
    onCloseModal()
  }

  return (
    <Modal
      maskClosable={false}
      visible={isVisible}
      footer={[]}
      onCancel={onCloseModal}
      afterOpenChange={open => open && singleCardMode && inputRef.current?.focus()}
      destroyOnClose
    >
      {templateMode && !impositionTemplateOptions?.length ? (
        <Skeleton active />
      ) : (
        <Form
          name="createInvestmentLetter"
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          initialValues={{
            only_custom: false,
            sizes: !isAllCardsFlat ? defaultSizesOptions[0].label : defaultSizesOptions[1].label,
          }}
        >
          {singleCardMode ? (
            <Form.Item
              label="Quantity"
              name="quantity"
              rules={[
                {
                  required: true,
                  message: 'Please input quantity!',
                },
              ]}
            >
              <InputNumber ref={inputRef} min={0} style={{ width: '100%' }} />
            </Form.Item>
          ) : null}

          {templateMode ? (
            <Form.Item label="Imposition Template" name="imposition_id">
              <Select
                options={impositionTemplateOptions}
                labelRender={({ value, label }) => {
                  const isRecommended = impositionTemplateOptions.find(
                    option => option.value === value,
                  )?.recommended

                  return `${label} ${isRecommended ? '(recommended)' : ''}`
                }}
                optionRender={option => (
                  <Space
                    style={{
                      fontWeight: option.data.recommended ? '500' : '400',
                    }}
                  >
                    {option.label}
                    {option.data.recommended ? (
                      <p style={{ color: 'green', margin: 0 }}>(recommended)</p>
                    ) : null}
                  </Space>
                )}
              />
            </Form.Item>
          ) : (
            <Form.Item label="Sizes" name="sizes">
              <Input disabled />
            </Form.Item>
          )}
          {!!warningMessage.length && !templateMode && (
            <div>
              <p style={{ color: 'grey' }}>{warningMessage}</p>
            </div>
          )}

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {!singleCardMode ? (
              <Form.Item name="only_custom" valuePropName="checked">
                <Checkbox>Only custom</Checkbox>
              </Form.Item>
            ) : null}
          </div>
          <Button key="back" onClick={onCloseModal} size="large" style={{ marginRight: '1rem' }}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" size="large">
            Generate
          </Button>
        </Form>
      )}
    </Modal>
  )
}

export default ImposedCardModal
