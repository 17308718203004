import axios from 'axios'
import serialize from '_helpers/hw-serialize-params'
import apiUrl from './service'
import { v1 } from './api'

const getAll = async (params, typeAction) => {
  const result = await axios.get(`${apiUrl}/${v1}/tax_codes?${serialize(params)}`, {
    params: { stopSpinner: typeAction },
  })
  return handleResponse(result)
}

const getItemById = async id => {
  const result = await axios.get(`${apiUrl}/${v1}/tax_codes/${id}`)
  return handleResponse(result)
}

const updateItemById = async (id, value) => {
  const result = await axios.put(
    `${apiUrl}/${v1}/tax_codes/${id}`,
    JSON.stringify({ changes: value }),
  )
  return handleResponse(result)
}

const handleResponse = result => {
  return result.data
}

const taxcodesService = {
  getAll,
  getItemById,
  updateItemById,
}

export default taxcodesService
