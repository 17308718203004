import { mailsConstants } from '../_constants'
import { mailsService } from '../_services'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: mailsConstants.GETALL_REQUEST })
  try {
    const mails = await mailsService.getAll(params, typeAction)
    dispatch({ type: mailsConstants.GETALL_SUCCESS, payload: mails.data })
  } catch (error) {
    dispatch({ type: mailsConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const deleteItem = id => async dispatch => {
  dispatch({ type: mailsConstants.DELETE_REQUEST })
  try {
    await mailsService.deleteItem(id)
    dispatch({ type: mailsConstants.DELETE_SUCCESS, payload: id })
  } catch (error) {
    dispatch({ type: mailsConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const resendItem = id => async dispatch => {
  dispatch({ type: mailsConstants.RESEND_REQUEST })

  try {
    const updated = await mailsService.resendMail(id)
    dispatch({ type: mailsConstants.RESEND_SUCCESS, payload: updated?.message?.toString() })
  } catch (error) {
    dispatch({ type: mailsConstants.RESEND_FAILURE, payload: error.toString() })
  }
}

const mailsActions = {
  getAll,
  deleteItem,
  resendItem,
}

export default mailsActions
