import { countryConstants } from '../_constants'
import { countriesService } from '../_services'
import { history } from '../_helpers'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: countryConstants.GETALL_REQUEST })
  try {
    const countries = await countriesService.getAll(params, typeAction)
    dispatch({ type: countryConstants.GETALL_SUCCESS, payload: countries.data })
  } catch (error) {
    dispatch({ type: countryConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const addItem = value => async dispatch => {
  dispatch({ type: countryConstants.ADD_REQUEST })

  try {
    const country = await countriesService.addItem({ value })
    dispatch({ type: countryConstants.ADD_SUCCESS, payload: { ...value, id: country.id } })

    history.push(`/countries/view/${country.id}`)
  } catch (error) {
    dispatch({ type: countryConstants.ADD_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: countryConstants.GETBYID_REQUEST })

  try {
    const country = await countriesService.getItemById(id)
    dispatch({ type: countryConstants.GETBYID_SUCCESS, payload: country.data })
  } catch (error) {
    dispatch({ type: countryConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const deleteItem = id => async dispatch => {
  dispatch({ type: countryConstants.DELETE_REQUEST })
  try {
    await countriesService.deleteItem(id)
    history.push('/countries')
    dispatch({ type: countryConstants.DELETE_SUCCESS, payload: id })
  } catch (error) {
    dispatch({ type: countryConstants.DELETE_FAILURE, payload: error.toString() })
  }
}

const updateItemById = (id, value) => async dispatch => {
  dispatch({ type: countryConstants.UPDATE_REQUEST })

  try {
    const updated = await countriesService.updateItemById(id, value)
    dispatch({ type: countryConstants.UPDATE_SUCCESS, payload: updated?.message?.toString() })

    history.push(`/countries/view/${id}`)
  } catch (error) {
    dispatch({ type: countryConstants.UPDATE_FAILURE, payload: error.toString() })
  }
}

const countriesActions = {
  getAll,
  getItemById,
  deleteItem,
  updateItemById,
  addItem,
}

export default countriesActions
