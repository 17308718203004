import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import v4 from 'uuid/v4'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import { multiStepActions } from '../../../../_actions'
import { Button } from 'antd'
import { getMultiStepRows, getPage, getTotalItems } from '_selectors/multi-step.selector'

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'User Login',
    dataIndex: 'user',
    key: 'user=>login',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Steps',
    dataIndex: 'total_steps',
    key: 'total_steps',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
]

const ManageItems = () => {
  const history = useHistory()

  const rows = useSelector(getMultiStepRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const navigateToSteps = campaign_id => {
    history.push({
      pathname: `campaign-steps/${campaign_id}`,
    })
  }

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        user: item.user_id ? (
          item.user ? (
            <Link to={`/clients/edit/${item.user.id}`}>{item.user.name}</Link>
          ) : (
            item.user_id
          )
        ) : (
          ''
        ),
        total_steps: (
          <Button
            size="small"
            type="text"
            className="list-view-item-bold list-item-underline px-0"
            style={{ border: 'none' }}
            onClick={() => navigateToSteps(item.id)}
          >
            {item.total_steps}
          </Button>
        ),
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  return (
    <NavContainer menu={[]}>
      <TableFilter
        columns={initColumns}
        data={dataSource}
        getDataAction={multiStepActions.getAll}
        isEditOnId={false}
        actionsColum={['view']}
      />
    </NavContainer>
  )
}

export default ManageItems
