import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router'
import { Image } from 'antd'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { giftCardActions } from '../../../../_actions'
import NavContainer from '../../../../components/nav-container'
import ListViewer from '../../../../components/list-viewer'
import { appConstants, giftCardsConstants, settingsConstants } from '../../../../_constants'
import {
  getGiftCardsRows,
  getPage,
  getTotalItems,
} from '../../../../_selectors/gift-cards.selector.js'

const { navMenu, listItemKeyToLabel } = giftCardsConstants

const navMenuInit = [navMenu.create, navMenu.manage]

const ListItems = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getGiftCardsRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const navigateToManage = () => {
    dispatch({ type: giftCardsConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.manage.key:
          return {
            ...menu,
            url: navigateToManage,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const listItems = useMemo(() => {
    if (rows?.length) {
      return {
        total,
        page,
        rows: rows?.map(item => {
          const res = []
          Object.keys(listItemKeyToLabel).forEach(sKey => {
            const itemLine = { label: listItemKeyToLabel[sKey], key: sKey, value: item[sKey] }
            if (sKey === 'image') {
              itemLine.toolTip = `Click To Edit`
              itemLine.type = 'image'
              itemLine.clickHandler = () => {
                history.push({ pathname: `/gift-cards/edit/${item[sKey]}` })
              }
              itemLine.value = (
                <Image
                  width={200}
                  src={`${appConstants.IMAGES_URL}/cardimages/${item.image}`}
                  fallback={appConstants.FALLBACK_IMAGE}
                  preview={false}
                />
              )
            } else if (sKey === 'id') {
              itemLine.toolTip = `Click To Edit`
              itemLine.clickHandler = () => {
                history.push({ pathname: `/gift-cards/view/${item.id}` })
              }
            }
            res.push(itemLine)
          })
          return res
        }),
      }
    }
    return { rows: [], total, page }
  }, [rows, page, total])

  return (
    <NavContainer menu={navMenuParse}>
      <ListViewer data={listItems} getDataAction={giftCardActions.getAll} />
    </NavContainer>
  )
}

export default ListItems
