import React, { useEffect, useState, useRef } from 'react'
import { Button, Divider, Input, List, Select, Space, Typography } from 'antd'
import { useParams } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { CloseCircleOutlined, DownOutlined, PlusOutlined } from '@ant-design/icons'

import QaWarning from './warning-message'
import { qaActions } from '_actions'
import { qaConstants } from '_constants'

import '../style.scss'

const { defaultNotesList } = qaConstants

const OrderNoteSelect = ({ value, onChange, notesOptions, setNotesOptions }) => {
  const [customNote, setCustomNote] = useState('')
  const inputRef = useRef(null)

  const filterOptionsHandler = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const customNoteChangeHandler = event => {
    setCustomNote(event.target.value)
  }

  const addNewOption = () => {
    if (customNote) {
      setNotesOptions(prev => [
        ...prev,
        {
          label: customNote,
          value: customNote,
        },
      ])
      onChange(customNote)
      setCustomNote('')
      setTimeout(() => inputRef.current?.focus(), 0)
    }
  }

  const submitCustomNoteHandler = e => {
    e.preventDefault()
    addNewOption()
  }

  const inputKeyDownHandler = event => {
    event.stopPropagation()

    if (event.key === 'Enter') {
      addNewOption()
    }
  }

  return (
    <Select
      showSearch
      placeholder="Select a note"
      popupClassName="note-select"
      optionFilterProp="children"
      value={value}
      onChange={onChange}
      allowClear
      filterOption={filterOptionsHandler}
      options={notesOptions}
      style={{ width: isMobile ? '13rem' : '15rem', height: '3rem' }}
      suffixIcon={isMobile && value ? <CloseCircleOutlined /> : <DownOutlined />}
      dropdownRender={menu => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space style={{ padding: '0 8px 4px' }}>
            <Input
              placeholder="Other"
              ref={inputRef}
              value={customNote}
              onChange={customNoteChangeHandler}
              onKeyDown={inputKeyDownHandler}
            />
            <Button type="text" icon={<PlusOutlined />} onClick={submitCustomNoteHandler} />
          </Space>
        </>
      )}
    />
  )
}

const RedoList = () => {
  const dispatch = useDispatch()
  const { manifest_id } = useParams()
  const { incompletedOrderIds, completedOrderIds, skipOrderIds } = useSelector(state => state.qa)
  const [rejectedIds, setRejectedIds] = useState([])
  const [lastSelectedId, setLastSelectedId] = useState(null)
  const [continueWithSkipped, setContinueWithSkipped] = useState(false)
  const [alreadySelected, setAlreadySelected] = useState([])
  const [notesList, setNotesList] = useState({})
  const [notesOptions, setNotesOptions] = useState([])

  useEffect(() => {
    const initialNotesOptions = defaultNotesList.map(note => ({ label: note, value: note }))
    setNotesOptions(initialNotesOptions)
  }, [])

  useEffect(() => {
    if (!completedOrderIds) {
      dispatch(qaActions.assignManifest(manifest_id))
    }
  }, [completedOrderIds])

  // logic if manifest has some skip order ids
  if (skipOrderIds?.length && !continueWithSkipped) {
    const message = `If you continue, the following orders will be skipped: ${skipOrderIds.join(
      ', ',
    )}`

    const continueWithSkippedHandler = () => {
      setContinueWithSkipped(true)
    }

    return (
      <QaWarning
        manifest_id={manifest_id}
        title="The manifest contains already accepted or rejected orders!"
        message={message}
        onSubmit={continueWithSkippedHandler}
      />
    )
  }

  // logic if manifest has some incompleted order ids
  if (incompletedOrderIds?.length) {
    const message = `If you continue, the following orders will be removed from the manifest: ${incompletedOrderIds.join(
      ', ',
    )}`

    const removeInactiveOrdersHandler = async () => {
      const payload = {
        remove_order_ids: incompletedOrderIds,
      }

      await dispatch(qaActions.updateManifestOrders(manifest_id, payload))
    }

    return (
      <QaWarning
        manifest_id={manifest_id}
        title="The manifest has incomplete orders!"
        message={message}
        submitText="Remove"
        onSubmit={removeInactiveOrdersHandler}
      />
    )
  }

  if (!completedOrderIds) return null

  const orderIdOptions = completedOrderIds.map(id => ({
    value: id,
    label: id.toString(),
  }))

  const filteredOrderIdOptions = orderIdOptions.filter(
    option => !rejectedIds.includes(option.value),
  )

  const onRejectedIdsChange = id => {
    setRejectedIds(prev => [...prev, id])
    setLastSelectedId(id)
  }

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const removeIdHandler = idToRemove => {
    setNotesList(prev => {
      const notes = prev
      delete notes[idToRemove]
      return notes
    })

    setRejectedIds(prev => {
      return prev.filter(id => id !== idToRemove)
    })
    setLastSelectedId(null)
  }

  const onNoteChangeHandler = (value, order) => {
    setNotesList(prev => ({ ...prev, [order]: value }))
  }

  const onSearchHandler = search => {
    const escapeBracketsRegex = new RegExp(
      '[\\-\\[\\]\\/\\{\\}\\(\\)\\*\\+\\?\\.\\,\\\\\\^\\$\\|]',
      'g',
    )
    const searchString = search.replaceAll(escapeBracketsRegex, '')
    const matchValidOptions = filteredOrderIdOptions.find(option =>
      String(option.value).includes(searchString),
    )

    if (search.length && !matchValidOptions) {
      const matchSelected = rejectedIds.filter(id => String(id).match(searchString))

      setAlreadySelected(matchSelected)
    } else {
      setAlreadySelected([])
    }
  }

  const filterNotes = notes => {
    const filtered = {}

    // eslint-disable-next-line no-restricted-syntax
    for (const order in notes) {
      if (notes[order]) {
        filtered[order] = notes[order]
      }
    }

    return filtered
  }

  const onFinish = () => {
    const payload = {
      rejected_order_ids: rejectedIds,
      skip_order_ids: skipOrderIds,
      notes: filterNotes(notesList),
    }

    dispatch(qaActions.submitQa(manifest_id, payload))
  }

  return (
    <div className="qa-page">
      <Typography.Title level={2}>Manifest #{manifest_id}</Typography.Title>

      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
        <Typography.Text style={{ fontSize: '.8rem', fontWeight: 600 }}>
          Enter Order ID to add to Re-Do List
        </Typography.Text>
        <Select
          showSearch
          placeholder="Select an order id"
          optionFilterProp="children"
          value={null}
          onChange={onRejectedIdsChange}
          onSearch={onSearchHandler}
          filterOption={filterOption}
          options={filteredOrderIdOptions}
          style={{ width: '100%' }}
          dropdownRender={menu => {
            if (alreadySelected.length) {
              return (
                <Typography style={{ padding: '0.5rem' }}>
                  This orders that matching your search are already selected:{' '}
                  {alreadySelected.join(', ')}
                </Typography>
              )
            }

            return menu
          }}
        />
        {lastSelectedId ? (
          <Typography.Text style={{ fontSize: '.8rem', color: 'green' }}>
            Order with id #{lastSelectedId} was added.
          </Typography.Text>
        ) : null}
      </div>

      <div style={{ marginTop: '3rem', marginBottom: '1rem' }}>
        <Typography.Title level={3}>ReDo List:</Typography.Title>
        {rejectedIds.length ? (
          <List
            size="small"
            header={<Typography.Text style={{ fontWeight: 600 }}>ID</Typography.Text>}
            bordered
            dataSource={rejectedIds}
            renderItem={item => (
              <List.Item style={{ border: 'none', borderBottom: '1px solid #e4e9f0' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    gap: '1rem',
                    paddingRight: '0.5rem',
                  }}
                >
                  <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Typography.Text style={{ marginLeft: '1rem' }}>{item}</Typography.Text>
                    <OrderNoteSelect
                      value={notesList[item]}
                      notesOptions={notesOptions}
                      onChange={value => onNoteChangeHandler(value, item)}
                      setNotesOptions={setNotesOptions}
                    />
                  </Space>
                  <CloseCircleOutlined onClick={() => removeIdHandler(item)} />
                </div>
              </List.Item>
            )}
          />
        ) : (
          <Typography.Title level={5}>ReDo list is empty</Typography.Title>
        )}
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button type="primary" onClick={onFinish}>
          Done
        </Button>
      </div>
    </div>
  )
}

export default RedoList
