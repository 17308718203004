import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Checkbox, Form, Input, InputNumber, Select } from 'antd'
import NavContainer from '../../../../components/nav-container'
import { subscriptionActions } from '../../../../_actions'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'

import { subscriptionConstants } from '../../../../_constants'

const {
  subscriptionStatuses,
  subscriptionVisibility,
  formItemLayout,
  navMenu,
} = subscriptionConstants

const navMenuInit = [navMenu.list, navMenu.manage]

const CreateItem = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const onFinish = async values => {
    const convertedSubscription = convertFormValuesDeep(values)
    await dispatch(subscriptionActions.addItem(convertedSubscription))
  }

  const plainStatuses = useMemo(
    () => subscriptionStatuses.map(status => ({ value: status.value, label: status.text })),
    [],
  )

  const plainVisibilityOptions = useMemo(
    () =>
      subscriptionVisibility.map(visibility => ({
        value: visibility.value,
        label: visibility.text,
      })),
    [],
  )

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      <Form {...formItemLayout} name="basic" form={form} onFinish={onFinish} scrollToFirstError>
        <Form.Item label="Status" name="status" initialValue="DRAFT">
          <Select options={plainStatuses} />
        </Form.Item>

        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input name!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Visibility" name="visibility" initialValue="standard">
          <Select options={plainVisibilityOptions} />
        </Form.Item>

        <Form.Item label="Description" name="description">
          <Input.TextArea rows={5} />
        </Form.Item>

        <Form.Item label="Cost Description" name="cost_description">
          <Input.TextArea rows={5} />
        </Form.Item>

        <Form.Item
          label="Delivery Confirmation Free"
          name="confirmation_free"
          valuePropName="checked"
        >
          <Checkbox name="confirmation_free" />
        </Form.Item>

        <Form.Item
          label="Fee"
          name="fee"
          rules={[
            {
              required: true,
              message: 'Please input fee!',
            },
          ]}
        >
          <InputNumber step={0.01} />
        </Form.Item>

        <Form.Item label="Taxable Amount" name="taxable_amount" initialValue={0.0}>
          <InputNumber step={0.01} />
        </Form.Item>

        <Form.Item label="Tax Exempt" name="tax_exempt" initialValue="0">
          <Select>
            <Select.Option value="0">NO</Select.Option>
            <Select.Option value="1">YES</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Credit2"
          name="credit2"
          rules={[
            {
              required: true,
              message: 'Please input price!',
            },
          ]}
        >
          <InputNumber step={0.01} />
        </Form.Item>

        <Form.Item
          label="Discount"
          name="discount"
          rules={[
            {
              required: true,
              message: 'Please input price!',
            },
          ]}
        >
          <InputNumber step={0.01} />
        </Form.Item>

        <Form.Item
          label="Is best value"
          name="is_best_value"
          initialValue={false}
          valuePropName="checked"
        >
          <Checkbox name="is_best_value" />
        </Form.Item>

        <Form.Item label="Color" name="color">
          <Input maxLength={6} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </NavContainer>
  )
}

export default CreateItem
