import React, { useEffect, useState } from 'react'
import { FloatButton } from 'antd'
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons'

const ScrollButton = ({ scrollElementRef, scrollToEnd, countOfSelectedRows }) => {
  const [isEndPage, setIsEndPage] = useState(false)

  const handleScrollEvent = () => {
    const pageScrollHeight = document.body.scrollHeight

    const detectionScrollEndValue = window.innerHeight + window.scrollY + 150

    const isEnd = detectionScrollEndValue > pageScrollHeight

    if (isEnd) {
      return setIsEndPage(true)
    }

    return setIsEndPage(false)
  }

  const handleButtonClick = () => {
    const scrollElement = scrollElementRef.current

    if (isEndPage) {
      return scrollElement.scrollIntoView({ alignToTop: true })
    }

    if (scrollElement) {
      if (scrollToEnd) return window.scrollTo(0, document.body.scrollHeight)
      return scrollElement.scrollIntoView({ block: 'end' })
    }

    return true
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScrollEvent)

    return () => {
      window.removeEventListener('scroll', handleScrollEvent)
    }
  }, [])

  return (
    <FloatButton
      badge={
        countOfSelectedRows
          ? {
              count: countOfSelectedRows,
              color: '#ff4d4f',
              overflowCount: countOfSelectedRows + 1,
            }
          : {}
      }
      tooltip={!!countOfSelectedRows && <div>Selected Orders</div>}
      shape="circle"
      icon={isEndPage ? <UpCircleOutlined /> : <DownCircleOutlined />}
      type="primary"
      style={buttonStyle}
      onClick={handleButtonClick}
      size="large"
    />
  )
}

const buttonStyle = {
  position: 'fixed',
  bottom: 20,
  right: 20,
  zIndex: 99,
}

export default ScrollButton
