import React from 'react'
import appConstants from './app.constants'

const cardImagesConstants = {
  GETALL_REQUEST: 'CARDIMAGES_GETALL_REQUEST',
  GETALL_SUCCESS: 'CARDIMAGES_GETALL_SUCCESS',
  GETALL_FAILURE: 'CARDIMAGES_GETALL_FAILURE',

  GETBYID_REQUEST: 'CARDIMAGES_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'CARDIMAGES_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'CARDIMAGES_GETBYID_FAILURE',

  ADD_REQUEST: 'CARDIMAGES_ADD_REQUEST',
  ADD_SUCCESS: 'CARDIMAGES_ADD_SUCCESS',
  ADD_FAILURE: 'CARDIMAGES_ADD_FAILURE',

  DELETE_REQUEST: 'CARDIMAGES_DELETE_REQUEST',
  DELETE_SUCCESS: 'CARDIMAGES_DELETE_SUCCESS',
  DELETE_FAILURE: 'CARDIMAGES_DELETE_FAILURE',

  UPDATE_REQUEST: 'CARDIMAGES_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CARDIMAGES_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CARDIMAGES_UPDATE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  cardImageType: [
    { value: '', text: 'NONE' },
    { value: 'front', text: 'FRONT' },
    { value: 'inside', text: 'INSIDE' },
    { value: 'back', text: 'BACK' },
    { value: 'envelope', text: 'ENVELOPE' },
    { value: 'front print', text: 'FRONT PRINT' },
    { value: 'inside print', text: 'INSIDE PRINT' },
  ],

  navMenu: {
    list: { key: 'list', title: 'List Card Images', url: '/card-images/list' },
    manage: { key: 'manage', title: 'Manage Card Images', url: '/card-images' },
    create: { title: 'Create Card Image', key: 'create', url: '/card-images/create' },
    view: { key: 'view', title: 'View Card Image', url: '' },
    delete: { key: 'delete', title: 'Delete Card Image', url: '' },
    edit: { key: 'edit', title: 'Update Card Image', url: '' },
  },

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },
  listItemKeyToLabel: {
    id: 'ID',
    image: 'Image',
    type: 'Type',
    sort_no: 'Sort No',
    date_created: 'Date Created',
    image_preview: 'Image Preview',
  },
  viewItemKeyToLabel: {
    id: 'ID',
    card_id: 'Card',
    image: 'Image',
    type: 'Type',
    sort_no: 'Sort No',
    date_created: 'Date Created',
    image_preview: 'Image Preview',
  },

  advancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'Card', key: 'card_id' },
    { label: 'Image', key: 'image' },
    { label: 'Preview', key: 'preview' },
    { label: 'Type', key: 'type_filter', type: 'select' },
    { label: 'Sort No', key: 'sort_no' },
    { label: 'Date Created', key: 'date_created*date' },
  ],

  renderComponent: {
    // eslint-disable-next-line react/display-name
    image: (url, title = '') =>
      url ? (
        <img width="250" src={`${appConstants.IMAGES_URL}/cardimages/${url}`} alt={title} />
      ) : (
        '----'
      ),
  },
}
export default cardImagesConstants
