const giftCardsConstants = {
  GETALL_REQUEST: 'GIFT_CARDS_CONSTANTS_GETALL_REQUEST',
  GETALL_SUCCESS: 'GIFT_CARDS_CONSTANTS_GETALL_SUCCESS',
  GETALL_FAILURE: 'GIFT_CARDS_CONSTANTS_GETALL_FAILURE',

  GETBYID_REQUEST: 'GIFT_CARDS_CONSTANTS_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'GIFT_CARDS_CONSTANTS_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'GIFT_CARDS_CONSTANTS_GETBYID_FAILURE',

  ADD_REQUEST: 'GIFT_CARDS_CONSTANTS_ADD_REQUEST',
  ADD_SUCCESS: 'GIFT_CARDS_CONSTANTS_ADD_SUCCESS',
  ADD_FAILURE: 'GIFT_CARDS_CONSTANTS_ADD_FAILURE',

  DELETE_REQUEST: 'GIFT_CARDS_CONSTANTS_DELETE_REQUEST',
  DELETE_SUCCESS: 'GIFT_CARDS_CONSTANTS_DELETE_SUCCESS',
  DELETE_FAILURE: 'GIFT_CARDS_CONSTANTS_DELETE_FAILURE',

  UPDATE_REQUEST: 'GIFT_CARDS_CONSTANTS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'GIFT_CARDS_CONSTANTS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'GIFT_CARDS_CONSTANTS_UPDATE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    list: { key: 'list', title: 'List Gift Card', url: '/gift-cards/list' },
    manage: { key: 'manage', title: 'Manage Gift Card', url: '/gift-cards' },
    create: { key: 'create', title: 'Create Gift Card', url: '/gift-cards/create' },
    edit: { key: 'edit', title: 'Update Gift Card', url: '' },
    delete: { key: 'delete', title: 'Delete Gift Card', url: '' },
    view: { key: 'view', title: 'View Gift Card', url: '' },
  },

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },

  listItemKeyToLabel: {
    id: 'ID',
    name: 'Name',
    image: 'Image',
  },
  viewItemKeyToLabel: {
    id: 'ID',
    name: 'Name',
    sort: 'Sort Order',
    image: 'Image',
    preview_image: 'Image',
  },
  advancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'Name', key: 'name' },
    { label: 'Image', key: 'image' },
  ],
}
export default giftCardsConstants
