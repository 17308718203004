import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { Button, Form, Input, Layout, Space } from 'antd'
import { userActions } from '_actions'
import Footer from '../../components/cleanui/layout/Footer'

const { Content } = Layout

const RegisterPage = () => {
  const [form] = Form.useForm()
  const history = useHistory()
  const dispatch = useDispatch()

  const moveToLogin = () => {
    history.push('/login')
  }

  const onFinish = values => {
    const valuesKey = Object.keys(values)
    const error = []

    if (values.password !== values.checkPass)
      error.push({ name: 'checkPass', errors: ['Retype Password is incorrect'] })

    valuesKey.forEach(key => {
      if (key === 'username') {
        if (values[key].length < 3 && values[key].length > 20) {
          error.push({
            name: key,
            errors: ['Incorrect username (length between 3 and 20 characters).'],
          })
        }
        if (!values[key].match('^[A-Za-z0-9_]+$')) {
          error.push({ name: key, errors: ['Incorrect symbols (A-z0-9).'] })
        }
      }
      if (values[key].length < 4 && (key === 'checkPass' || key === 'password')) {
        error.push({ name: key, errors: ['Password is too short (minimum is 4 characters)'] })
      }
    }, {})
    if (error.length) return form.setFields(error)
    // if (error === false) {
    const { email, username, password } = values
    dispatch(userActions.register({ email, username, password }))
    // }
    return form.setFields([])
  }

  return (
    <Layout>
      <Content>
        <div className="container col-m-6 col-md-offset-3 container-login-form">
          <h2 className="text-center my-lg-2 my-sm-2 my-md-2">Register</h2>
          <Form
            name="editClient"
            form={form}
            onFinish={onFinish}
            layout="vertical"
            scrollToFirstError
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'email cannot be blank',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: 'username cannot be blank',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input password!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Retype Password"
              name="checkPass"
              rules={[
                {
                  required: true,
                  message: 'Retype Password cannot be blank!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Space>
                <Button htmlType="submit" style={{ background: '#23bc23', color: '#fff' }}>
                  Register
                </Button>
                <Button type="primary" onClick={moveToLogin}>
                  LogIn
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </Content>
      <Footer />
    </Layout>
  )
}

export default RegisterPage
