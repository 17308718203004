import { employeesConstants } from '../_constants'

const initialState = {
  items: [],
  item: {},
}

function employees(state = initialState, { type, payload }) {
  switch (type) {
    case employeesConstants.GETALL_SUCCESS:
      return { ...state, items: payload, item: {} }
    case employeesConstants.GETBYID_SUCCESS:
      return { ...state, item: payload }
    case employeesConstants.DELETE_SUCCESS:
      return { ...state }
    case employeesConstants.UPDATE_SUCCESS:
      return { ...state, items: state.items }
    case employeesConstants.GETTOKEN_SUCCESS:
      return { ...state, item: { ...state.item, api_token: payload } }
    case employeesConstants.ADD_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          total: state.items.total + 1,
        },
      }
    case employeesConstants.SETBYID:
      return {
        ...state,
        item: {},
      }
    case employeesConstants.SETALL:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default employees
