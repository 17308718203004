import axios from 'axios'
import apiUrl from './service'
import { v1 } from './api'
import serialize from '_helpers/hw-serialize-params'

const getAll = async (params, typeAction, id) => {
  const result = await axios.get(`${apiUrl}/${v1}/apikey/?${serialize(params)}`, {
    params: { stopSpinner: typeAction, id },
  })
  return handleResponse(result)
}

const handleResponse = result => {
  return result.data
}

const apiKeysService = {
  getAll,
}

export default apiKeysService
