import axios from 'axios'
import serialize from '_helpers/hw-serialize-params'
import apiUrl from './service'
import { v1 } from './api'

const getAll = async (params, typeAction) => {
  const result = await axios.get(`${apiUrl}/${v1}/ipv4Rules${serialize(params)}`, {
    params: { stopSpinner: typeAction },
  })
  return handleResponse(result)
}

const saveBanIP = async banIP => {
  const result = await axios.post(`${apiUrl}/${v1}/ipv4Rules`, JSON.stringify({ rules: banIP }))
  return handleResponse(result)
}

const handleResponse = result => {
  return result.data
}

const banIpService = {
  getAll,
  saveBanIP,
}

export default banIpService
