const batchOrdersConstants = {
  GET_GROUPS_REQUEST: 'BATCH_ORDERS_GET_GROUPS_REQUEST',
  GET_GROUPS_SUCCESS: 'BATCH_ORDERS_GET_GROUPS_SUCCESS',
  GET_GROUPS_FAILURE: 'BATCH_ORDERS_GET_GROUPS_FAILURE',

  GET_ORDERS_BY_DATE_REQUEST: 'BATCH_ORDERS_GET_ORDERS_BY_DATE_REQUEST',
  GET_ORDERS_BY_DATE_SUCCESS: 'BATCH_ORDERS_GET_ORDERS_BY_DATE_SUCCESS',
  GET_ORDERS_BY_DATE_FAILURE: 'BATCH_ORDERS_GET_ORDERS_BY_DATE_FAILURE',

  GET_PRINTED_ORDERS_REQUEST: 'BATCH_ORDERS_GET_PRINTED_ORDERS_REQUEST',
  GET_PRINTED_ORDERS_SUCCESS: 'BATCH_ORDERS_GET_PRINTED_ORDERS_SUCCESS',
  GET_PRINTED_ORDERS_FAILURE: 'BATCH_ORDERS_GET_PRINTED_ORDERS_FAILURE',

  GETBYCARD_REQUEST: 'BATCH_ORDERS_GETBYCARD_REQUEST',
  GETBYCARD_REQUEST_SUCCESS: 'BATCH_ORDERS_GETBYCARD_SUCCESS',
  GETBYCARD_REQUEST_FAILURE: 'BATCH_ORDERS_GETBYCARD_FAILURE',

  ADD_REQUEST: 'BATCH_ORDERS_ADD_REQUEST',
  ADD_SUCCESS: 'BATCH_ORDERS_ADD_SUCCESS',
  ADD_FAILURE: 'BATCH_ORDERS_ADD_FAILURE',

  DELETE_REQUEST: 'BATCH_ORDERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'BATCH_ORDERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'BATCH_ORDERS_DELETE_FAILURE',

  UPDATE_REQUEST: 'BATCH_ORDERS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'BATCH_ORDERS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'BATCH_ORDERS_UPDATE_FAILURE',

  SAVENOTE_REQUEST: 'BATCH_ORDERS_SAVENOTE_REQUEST',
  SAVENOTE_SUCCESS: 'BATCH_ORDERS_SAVENOTE_SUCCESS',
  SAVENOTE_FAILURE: 'BATCH_ORDERS_SAVENOTE_FAILURE',

  IMPOSITIONS_DOWNLOAD_PROGRESS: 'BATCH_ORDERS_IMPOSITIONS_DOWNLOAD_PROGRESS',
  IMPOSITIONS_DOWNLOAD_END: 'BATCH_ORDERS_IMPOSITIONS_DOWNLOAD_END',

  PROCESS_REQUEST: 'BATCH_ORDERS_PROCESS_REQUEST',
  PROCESS_SUCCESS: 'BATCH_ORDERS_PROCESS_SUCCESS',
  PROCESS_FAILURE: 'BATCH_ORDERS_PROCESS_FAILURE',

  SAVE_SELECTED_ROWS: 'BATCH_ORDERS_SAVE_SELECTED_ROWS',
  CLEAR_SELECTED_ROWS: 'BATCH_ORDERS_CLEAR_SELECTED_ROWS',
  CLEAR_BATCH_ORDERS_VIEW: 'BATCH_ORDERS_CLEAR_VIEW',
  CLEAR_MY_ORDERS_VIEW: 'BATCH_ORDERS_CLEAR_MY_VIEW',
  CLEAR_CHECKED_ORDERS: 'BATCH_ORDERS_CLEAR_CHECKED_ORDERS',

  COMPLETE_ORDERS_REQUEST: 'COMPLETE_ORDERS_REQUEST',
  COMPLETE_ORDERS_SUCCESS: 'COMPLETE_ORDERS_SUCCESS',
  COMPLETE_ORDERS_FAILURE: 'COMPLETE_ORDERS_FAILURE',

  DISCARD_ORDERS_REQUEST: 'DISCARD_ORDERS_REQUEST',
  DISCARD_ORDERS_SUCCESS: 'DISCARD_ORDERS_SUCCESS',
  DISCARD_ORDERS_FAILURE: 'DISCARD_ORDERS_FAILURE',

  CREATE_DOCX_NOTES_REQUEST: 'CREATE_DOCX_NOTES_REQUEST',
  CREATE_DOCX_NOTES_SUCCESS: 'CREATE_DOCX_NOTES_SUCCESS',
  CREATE_DOCX_NOTES_FAILURE: 'CREATE_DOCX_NOTES_FAILURE',

  CREATE_DOCX_NOTES_PDF_REQUEST: 'CREATE_DOCX_NOTES_PDF_REQUEST',
  CREATE_DOCX_NOTES_PDF_SUCCESS: 'CREATE_DOCX_NOTES_PDF_SUCCESS',
  CREATE_DOCX_NOTES_PDF_FAILURE: 'CREATE_DOCX_NOTES_PDF_FAILURE',

  CREATE_DOCX_ENVELOPES_REQUEST: 'CREATE_DOCX_ENVELOPES_REQUEST',
  CREATE_DOCX_ENVELOPES_SUCCESS: 'CREATE_DOCX_ENVELOPES_SUCCESS',
  CREATE_DOCX_ENVELOPES_FAILURE: 'CREATE_DOCX_ENVELOPES_FAILURE',

  CREATE_DOCX_PDF_ENVELOPES_REQUEST: 'CREATE_DOCX_PDF_ENVELOPES_REQUEST',
  CREATE_DOCX_PDF_ENVELOPES_SUCCESS: 'CREATE_DOCX_PDF_ENVELOPES_SUCCESS',
  CREATE_DOCX_PDF_ENVELOPES_FAILURE: 'CREATE_DOCX_PDF_ENVELOPES_FAILURE',

  CREATE_MANIFEST_REQUEST: 'CREATE_MANIFEST_REQUEST',
  CREATE_MANIFEST_SUCCESS: 'CREATE_MANIFEST_SUCCESS',
  CREATE_MANIFEST_FAILURE: 'CREATE_MANIFEST_FAILURE',

  PRINT_LABELS_REQUEST: 'PRINT_LABELS_REQUEST',
  PRINT_LABELS_SUCCESS: 'PRINT_LABELS_SUCCESS',
  PRINT_LABELS_FAILURE: 'PRINT_LABELS_FAILURE',

  CANCEL_LABELS_REQUEST: 'CANCEL_LABELS_REQUEST',
  CANCEL_LABELS_SUCCESS: 'CANCEL_LABELS_SUCCESS',
  CANCEL_LABELS_FAILURE: 'CANCEL_LABELS_FAILURE',

  GENERATE_LABELS_REQUEST: 'GENERATE_LABELS_REQUEST',
  GENERATE_LABELS_SUCCESS: 'GENERATE_LABELS_SUCCESS',
  GENERATE_LABELS_FAILURE: 'GENERATE_LABELS_FAILURE',

  STATUS_LABELS_REQUEST: 'STATUS_LABELS_REQUEST',
  STATUS_LABELS_SUCCESS: 'STATUS_LABELS_SUCCESS',
  STATUS_LABELS_FAILURE: 'STATUS_LABELS_FAILURE',

  GETALL_IN_WORK_REQUEST: 'BATCH_ORDERS_IN_WORK_REQUEST',
  GETALL_IN_WORK_SUCCESS: 'BATCH_ORDERS_IN_WORK_SUCCESS',
  GETALL_IN_WORK_FAILURE: 'BATCH_ORDERS_IN_WORK_FAILURE',

  CHECK_IN_WORK_REQUEST: 'BATCH_ORDERS_CHECK_IN_WORK_REQUEST',
  CHECK_IN_WORK_SUCCESS: 'BATCH_ORDERS_CHECK_IN_WORK_SUCCESS',
  CHECK_IN_WORK_FAILURE: 'BATCH_ORDERS_CHECK_IN_WORK_FAILURE',

  STATUS_MANIFEST_REQUEST: 'STATUS_MANIFEST_REQUEST',
  STATUS_MANIFEST_SUCCESS: 'STATUS_MANIFEST_SUCCESS',
  STATUS_MANIFEST_FAILURE: 'STATUS_MANIFEST_FAILURE',

  CLEAR_MANIFESTS_STATUS: 'CLEAR_MANIFESTS_STATUS',
  UPDATE_MANIFESTS_STATUS: 'UPDATE_MANIFESTS_STATUS',

  GENERATE_IMPOSED_CARDS_REQUEST: 'GENERATE_IMPOSED_CARDS_REQUEST',
  GENERATE_IMPOSED_CARDS_REQUEST_SUCCESS: 'GENERATE_IMPOSED_CARDS_REQUEST_SUCCESS',
  GENERATE_IMPOSED_CARDS_FAILURE: 'GENERATE_IMPOSED_CARDS_FAILURE',

  GENERATE_WORK_LIST_REQUEST: 'GENERATE_WORK_LIST_REQUEST',
  GENERATE_WORK_LIST_SUCCESS: 'GENERATE_WORK_LIST_SUCCESS',
  GENERATE_WORK_LIST_FAILURE: 'GENERATE_WORK_LIST_FAILURE',

  GET_TOTAL_COUNT_REQUEST: 'GET_TOTAL_COUNT_REQUEST',
  GET_TOTAL_COUNT_SUCCESS: 'GET_TOTAL_COUNT_SUCCESS',
  GET_TOTAL_COUNT_FAILURE: 'GET_TOTAL_COUNT_FAILURE',

  SET_ACTIVE_PANEL: 'SET_ACTIVE_PANEL',
  SET_ROWS_WITH_BUTTON: 'SET_ROWS_WITH_BUTTON',

  SET_ORIENTATION: 'SET_ORIENTATION',
  RESET_ORIENTATION: 'RESET_ORIENTATION',

  serviceCode: [
    { label: '[01] UPS Next Day Air', value: '01' },
    { label: '[02] UPS Second Day Air', value: '02' },
    { label: '[03] UPS Ground', value: '03' },
    { label: '[07] UPS Worldwide Express', value: '07' },
    { label: '[08] UPS Worldwide Expedited', value: '08' },
    { label: '[11] UPS Standard', value: '11' },
    { label: '[12] UPS Three-Day Select', value: '12' },
    { label: '[13] Next Day Air Saver', value: '13' },
    { label: '[14] UPS Next Day Air Early AM', value: '14' },
    { label: '[54] UPS Worldwide Express Plus', value: '54' },
    { label: '[59] UPS Second Day Air AM', value: '59' },
    { label: '[65] UPS Saver', value: '65' },
    { label: '[70] UPS Access Point Economy', value: '70' },
  ],
  packageType: [
    { label: 'UNKNOWN', value: '00' },
    { label: '[01] UPS Letter', value: '01' },
    { label: '[02] Customer Supplied Package', value: '02' },
    { label: '[03] Tube', value: '03' },
    { label: '[04] PAK', value: '04' },
    { label: '[21] UPS Express Box', value: '21' },
    { label: '[24] UPS 25KG Box', value: '24' },
    { label: '[25] UPS 10KG Box', value: '25' },
    { label: '[30] Pallet', value: '30' },
    { label: '[2a] Small Express Box', value: '2a' },
    { label: '[2b] Medium Express Box', value: '2b' },
    { label: '[2c] Large Express Box', value: '2c' },
    { label: '[56] Flats', value: '56' },
    { label: '[57] Parcels', value: '57' },
    { label: '[58] BPM', value: '58' },
    { label: '[59] First Class', value: '59' },
    { label: '[60] Priority', value: '60' },
    { label: '[61] Machinables', value: '61' },
    { label: '[62] Irregulars', value: '62' },
    { label: '[63] Parcel Post', value: '63' },
    { label: '[64] BPM Parcel', value: '64' },
    { label: '[65] Media Mail', value: '65' },
    { label: '[66] BPM Flat', value: '66' },
    { label: '[67] Standard Flat', value: '67' },
  ],

  imposedCardSizes: [
    {
      value: {
        height: 13,
        width: 19,
      },
      text: '13x19',
    },
    {
      value: {
        height: 12,
        width: 18,
      },
      text: '12x18',
    },
  ],
}

export default batchOrdersConstants
