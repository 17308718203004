import React, { useState, useMemo, useEffect } from 'react'
import { Button, Collapse, Divider, Form, InputNumber, List, Select, Space, Typography } from 'antd'
import Text from 'antd/es/typography/Text'

import { useDispatch, useSelector } from 'react-redux'
import { labelsCreationActions } from '_actions'
import { labelsCreationConstants } from '_constants'

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import './style.scss'

const { packageTypeOptions, deliveryByOptions } = labelsCreationConstants

const GeneratedLabelsPopover = ({ generatedPackages, serviceOptions }) => {
  const [activeCollapseKeys, setActiveCollapseKeys] = useState([])

  return (
    <Collapse
      key="collapse"
      activeKey={activeCollapseKeys}
      onChange={keys => setActiveCollapseKeys(keys)}
      style={{ marginBottom: '0.5rem' }}
      items={[
        {
          key: 'previous_setting',
          label: 'Previous Settings',
          forceRender: true,
          children: (
            <>
              {generatedPackages.map(generatedPackage => (
                <Collapse
                  key="collapse"
                  activeKey={activeCollapseKeys}
                  onChange={keys => setActiveCollapseKeys(keys)}
                  style={{ marginBottom: '0.5rem' }}
                  items={[generatedPackage].map(item => {
                    return {
                      key: item.id,
                      label: 'Generated Package',
                      forceRender: true,
                      children: (
                        <List.Item>
                          <Space direction="vertical">
                            <Typography.Text>
                              <Typography.Text strong>Height: </Typography.Text> {item.height}
                            </Typography.Text>
                            <Typography.Text>
                              <Typography.Text strong>Length: </Typography.Text> {item.length}
                            </Typography.Text>
                            <Typography.Text>
                              <Typography.Text strong>Width: </Typography.Text> {item.width}
                            </Typography.Text>
                            <Typography.Text>
                              <Typography.Text strong>Weight: </Typography.Text> {item.weight}
                            </Typography.Text>
                          </Space>
                        </List.Item>
                      ),
                    }
                  })}
                />
              ))}
              <Form.Item label="Service" key="service" style={{ marginTop: '0.75rem' }}>
                <Select
                  disabled
                  value={
                    serviceOptions
                      .find(service => service.value === generatedPackages[0].service_code)
                      ?.label?.props.children.join('') || '-'
                  }
                />
              </Form.Item>
            </>
          ),
        },
      ]}
    />
  )
}

const PackageForm = ({ collapseKey, form, name, restField }) => {
  useEffect(() => {
    // set temporary collapseKey value so collapse will open this form in case of error
    form.setFieldValue(['recommended_packages', name, 'collapseKey'], collapseKey)

    // set initial package value
    const packageType = form.getFieldValue(['recommended_packages', name, 'package_code'])
    if (!packageType) {
      form.setFieldValue(
        ['recommended_packages', name, 'package_code'],
        packageTypeOptions[0].value,
      )
    }
  }, [])

  return (
    <Space direction="vertical">
      <Form.Item
        {...restField}
        label="Package Type"
        name={[name, 'package_code']}
        rules={[
          {
            required: true,
            message: `Please select Package Type!`,
          },
        ]}
      >
        <Select options={packageTypeOptions} showSearch />
      </Form.Item>

      <Form.Item
        {...restField}
        label="Package Width (inches)"
        name={[name, 'width']}
        rules={[
          {
            required: true,
            message: `Please input Width!`,
          },
        ]}
      >
        <InputNumber min={0} />
      </Form.Item>

      <Form.Item
        {...restField}
        label="Package Height (inches)"
        name={[name, 'height']}
        rules={[
          {
            required: true,
            message: `Please input Height!`,
          },
        ]}
      >
        <InputNumber min={0} />
      </Form.Item>

      <Form.Item
        {...restField}
        label="Package Length (inches)"
        name={[name, 'length']}
        rules={[
          {
            required: true,
            message: `Please input Length!`,
          },
        ]}
      >
        <InputNumber min={0} />
      </Form.Item>

      <Form.Item
        {...restField}
        label="Package Weight (lbs)"
        name={[name, 'weight']}
        rules={[
          {
            required: true,
            message: 'Please input weight!',
          },
        ]}
      >
        <InputNumber min={0} />
      </Form.Item>
    </Space>
  )
}

const ShippingLabelsForm = ({
  setIsLoading,
  form,
  setActive,
  orderId,
  currentOrder,
  initialValues,
  tailLayout,
}) => {
  const dispatch = useDispatch()
  const { serviceOptions } = useSelector(state => state.labelsCreation)

  const [activeCollapseKeys, setActiveCollapseKeys] = useState([])
  const [requestErrors, setRequestErrors] = useState([])

  const recommendedPackages = Form.useWatch('recommended_packages', form)
  const serviceName = Form.useWatch('service_name', form)
  const serviceCode = Form.useWatch('service_code', form)

  const isSubmitDisabled = useMemo(() => !recommendedPackages || !recommendedPackages.length, [
    recommendedPackages,
  ])

  useEffect(() => {
    if (currentOrder) {
      form.setFieldValue('recommended_packages', currentOrder.recommended_packages)
    }
  }, [currentOrder])

  const fetchServiceOptions = async orderId => {
    setIsLoading(true)
    await dispatch(labelsCreationActions.getServiceOptions({ order_id: orderId }))
    setIsLoading(false)
  }

  useEffect(() => {
    if (orderId) {
      fetchServiceOptions(orderId)
    }

    return () => {
      dispatch({ type: labelsCreationConstants.RESET_SERVICE_OPTIONS })
    }
  }, [orderId])

  const serviceOptionsList = useMemo(() => {
    if (!serviceOptions) return []

    const serviceKey = `${serviceName.toLowerCase()}_rates`
    return serviceOptions[serviceKey].map(service => ({
      value: service.code,
      label: (
        <Typography.Text type={!service.recommended ? 'secondary' : ''}>
          [{service.code}] {service.name}
        </Typography.Text>
      ),
    }))
  }, [serviceOptions, serviceName])

  useEffect(() => {
    const availableCodes = serviceOptionsList.map(option => option.value)

    if (availableCodes.length) {
      // if current service options don't includes selected code - set first default option
      if (!availableCodes.includes(serviceCode)) {
        form.setFieldValue('service_code', availableCodes[0])
      }
    }
  }, [serviceOptionsList, serviceCode])

  if (!currentOrder) return null

  const hideForm = () => {
    form.resetFields()
    setActive(false)
  }

  const getPackageTypeByCode = code => {
    if (code === '00') return 'CUSTOM BOX'
    return packageTypeOptions.find(option => option.value === code)?.label
  }

  const getPackageLabelByIndex = index => {
    const packageData = form.getFieldValue(['recommended_packages', index])

    const labelText = getPackageTypeByCode(packageData?.package_code)

    return `${labelText} ${+packageData?.length || 0}x${+packageData?.width ||
      0}x${+packageData?.height || 0}`
  }

  const onFinishFailed = ({ values, errorFields }) => {
    const errorBoxes = errorFields.filter(field => field.name.includes('recommended_packages'))

    // logic to find error package forms and open collapses to show user error fields
    if (errorBoxes.length) {
      const errorBoxesIndexes = []
      const errorCollapseKeys = []

      errorBoxes.forEach(errorBox => {
        const errorIndex = errorBox.name[1]
        if (!errorBoxesIndexes.includes(errorIndex)) {
          errorBoxesIndexes.push(errorIndex)
        }
      })

      values.recommended_packages.forEach((rPackage, index) => {
        if (errorBoxesIndexes.includes(index)) {
          errorCollapseKeys.push(rPackage.collapseKey)
        }
      })
      setActiveCollapseKeys(errorCollapseKeys)
    }
  }

  const onFinish = async values => {
    // remove temporary fields
    const updatedPackages = values.recommended_packages.map(({ collapseKey, ...rest }) => rest)
    const params = {
      boxes: updatedPackages,
      service_code: values.service_code,
      service_name: values.service_name,
      order_id: orderId,
    }

    setIsLoading(true)
    const response = await dispatch(labelsCreationActions.generateLabelsV2(params))
    await dispatch(labelsCreationActions.statusLabelsV2())
    setIsLoading(false)

    if (response?.staticMessages?.length) {
      setRequestErrors(response?.staticMessages)
    } else {
      hideForm()
    }
  }

  return (
    <div className="shipping-labels-form__show">
      <Divider orientation="left" style={{ marginTop: 0, marginBottom: '0.5rem' }} />
      {currentOrder.generated_packages ? (
        <GeneratedLabelsPopover
          generatedPackages={currentOrder.generated_packages}
          serviceOptions={serviceOptionsList}
        />
      ) : null}
      <Form
        initialValues={initialValues}
        name="main_labels_form"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError
      >
        <Typography.Title level={5}>Packages: </Typography.Title>
        <Form.Item noStyle shouldUpdate>
          {() => (
            <Form.List name="recommended_packages">
              {(fields, { add, remove }) => (
                <>
                  <Collapse
                    key="collapse"
                    activeKey={activeCollapseKeys}
                    onChange={keys => setActiveCollapseKeys(keys)}
                    items={fields.map(({ key, name, ...restField }) => {
                      return {
                        key,
                        label: getPackageLabelByIndex(name),
                        extra: (
                          <MinusCircleOutlined
                            onClick={e => {
                              e.stopPropagation()
                              remove(name)
                            }}
                          />
                        ),
                        forceRender: true,
                        children: (
                          <PackageForm
                            collapseKey={key}
                            form={form}
                            label={
                              currentOrder.recommended_packages[name]?.package_label || 'CUSTOM BOX'
                            }
                            name={name}
                            restField={restField}
                          />
                        ),
                      }
                    })}
                  />
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                      style={{ marginTop: '1rem' }}
                    >
                      Add package
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          )}
        </Form.Item>

        <Form.Item
          label="Delivery by"
          name="service_name"
          key="service_name"
          rules={[
            {
              required: true,
              message: `Please select Delivery Service!`,
            },
          ]}
        >
          <Select options={deliveryByOptions} showSearch initialValues="UPS" disabled />
        </Form.Item>

        <Form.Item
          label="Service"
          name="service_code"
          key="service_code"
          rules={[
            {
              required: true,
              message: `Please select Service!`,
            },
          ]}
        >
          <Select options={serviceOptionsList} showSearch />
        </Form.Item>

        {requestErrors.length ? (
          <Space direction="vertical" style={{ rowGap: 0 }}>
            <Text type="danger" strong>
              Errors:{' '}
            </Text>
            {requestErrors.map(error => (
              <Text key={error.text} type="danger">
                {error.text}
              </Text>
            ))}
          </Space>
        ) : null}

        <Form.Item {...tailLayout}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginRight: 10 }}
            disabled={isSubmitDisabled}
          >
            Generate
          </Button>
          <Button htmlType="button" onClick={hideForm}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default ShippingLabelsForm
