import React from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { coy } from 'react-syntax-highlighter/dist/esm/styles/prism'

const DetailsDataHighlighter = ({ children }) => {
  return (
    <div className="data-highlighter">
      <SyntaxHighlighter
        language="json"
        style={coy}
        wrapLongLines
        codeTagProps={{ style: { overflowWrap: 'anywhere' } }}
      >
        {children}
      </SyntaxHighlighter>
    </div>
  )
}

export default DetailsDataHighlighter
