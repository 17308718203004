import React, { useEffect } from 'react'
import { Badge, Space } from 'antd'
import List from 'components/cron-jobs/cron-job.list'

import { cronJobConstants } from '_constants'
import { useDispatch, useSelector } from 'react-redux'
import cronJobsActions from '_actions/cron-jobs.actions'

const { statusesList } = cronJobConstants

const CronJobsList = () => {
  const dispatch = useDispatch()
  const { items } = useSelector(state => state.cronJobs)

  useEffect(() => {
    dispatch(cronJobsActions.getAll())
  }, [])

  return (
    <div>
      <Space>
        {statusesList.map(({ color, title }) => (
          <Badge
            key={title}
            color={color}
            text={title}
            style={{ marginRight: '2rem', textTransform: 'uppercase' }}
          />
        ))}
      </Space>
      <List data={items} />
    </div>
  )
}

export default CronJobsList
