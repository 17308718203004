import { queryParamsConstants } from '../_constants'

const setQueryParams = params => {
  return {
    type: queryParamsConstants.SET_QUERY_PARAMS,
    payload: params,
  }
}

const clearQueryParams = () => ({ type: queryParamsConstants.CLEAR_QUERY_PARAMS })

const queryParamsActions = {
  setQueryParams,
  clearQueryParams,
}

export default queryParamsActions
