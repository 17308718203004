const multiStepConstants = {
  GETALL_REQUEST: 'MULTI_STEP_GETALL_REQUEST',
  GETALL_SUCCESS: 'MULTI_STEP_SUCCESS',
  GETALL_FAILURE: 'MULTI_STEP_FAILURE',

  GETBYID_REQUEST: 'MULTI_STEP_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'MULTI_STEP_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'MULTI_STEP_GETBYID_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    manage: { key: 'manage', title: 'Manage Multi Step Campaigns', url: '/multi-step' },
    view: { key: 'view', title: 'View Multi Step Campaign', url: '' },
    manageSteps: { key: 'manage-steps', title: 'Manage Steps', url: '' },
  },

  listItemKeyToLabel: {
    id: 'ID',
    name: 'Name',
    total_steps: 'Steps',
  },
  viewItemKeyToLabel: {
    id: 'ID',
    name: 'Name',
    user: 'User',
    total_steps: 'Steps',
  },

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },
}
export default multiStepConstants
