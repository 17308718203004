import React from 'react'
import { Space, Tabs, Typography } from 'antd'

const ImpositionLayoutFormGroup = ({ name, activeTab, tabItems, setActiveTab }) => {
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Space>
        <Typography.Title level={2}>{name}</Typography.Title>
      </Space>

      <Tabs
        defaultActiveKey="1"
        activeKey={activeTab}
        type="card"
        size="large"
        items={tabItems}
        onChange={tab => setActiveTab(String(tab))}
      />
    </Space>
  )
}

export default ImpositionLayoutFormGroup
