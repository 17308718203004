import React, { useMemo } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Card, CardBody, Col, Row } from 'reactstrap'
import Navigation from '../navigation'
import './styles.scss'
import useAddValueToClipboard from '../../hooks/clipboard/useAddValueToClipboard.js'

const NavContainer = ({ menu = [], children }) => {
  const menuExists = useMemo(() => {
    return menu && menu.length > 0
  }, [menu])

  return (
    <Row>
      <Col xs={menuExists ? '9' : '12'} xl={menuExists ? '10' : '12'} className="px-0 pe-lg-3 pe-2">
        <Card className="card--not-full-height">
          <CardBody className="documentation__changelog">
            <Title />
            {children}
          </CardBody>
        </Card>
      </Col>
      {menuExists && (
        <Col xs="3" xl="2" className="px-0 ps-lg-3 ps-2">
          <NavMenu menu={menu} />
        </Col>
      )}
    </Row>
  )
}

const Title = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const { where } = useSelector(state => state.queryParams)

  const parseLocName = useMemo(() => {
    return history.location.pathname
      .split('/')
      .filter(item => item)
      .map(item => {
        item = item.replace('-', ' ')
        return item.charAt(0).toUpperCase() + item.slice(1)
      })
  }, [history.location.pathname])

  const [, pageName, id] = parseLocName

  const { addValueToClipboard, isEditOrViewPage, contextHolder } = useAddValueToClipboard(pageName)

  const whereTitle = useMemo(() => {
    if (where?.category_id !== undefined) {
      return 'Clients Cards'
    }
    return where && !id && `#${Object.values(where)[0]}`
  }, [where, id])

  if (match.url === '/orders/my') {
    return <h3 className="text-gray-6">My Orders</h3>
  }
  if (match.url === '/cards/create-by-sample') {
    return <h3 className="text-gray-6">Create Card by Sample</h3>
  }

  if (match.url.includes('/automation-orders')) {
    const automation_id = pageName
    return <h3 className="text-gray-6">Automation Orders #{automation_id}</h3>
  }

  if (match.url.includes('/automation-recipients')) {
    const automation_id = pageName
    return <h3 className="text-gray-6">Automation Recipients #{automation_id}</h3>
  }

  if (match.url.includes('/campaign-steps')) {
    if (match.url.includes('/campaign-steps/view')) {
      return <h3 className="text-gray-6">Campaign Step #{id}</h3>
    }
    const campaign_id = pageName

    return <h3 className="text-gray-6">Campaign Steps #{campaign_id}</h3>
  }

  if (match.url.includes('/shopify')) {
    if (match.url.includes('/issues')) {
      return <h3 className="text-gray-6">Shopify shop Issue List</h3>
    }
    return <h3 className="text-gray-6">Manage Shopify shop</h3>
  }

  if (match.url.includes('/shipping-configs')) {
    return <h3 className="text-gray-6">Shipping Configs</h3>
  }

  if (pageName === 'Usa_financial_triggers') {
    return <h3 className="text-gray-6">Manage USA Financial Triggers {id && `#${id}`}</h3>
  }

  return (
    <h3 className="text-gray-6">
      {contextHolder}
      {pageName || 'Manage'} {id && `#${id}`} {whereTitle}
      {isEditOrViewPage && (
        <span
          onClick={() => addValueToClipboard(id)}
          style={{ marginLeft: '1rem', cursor: 'pointer' }}
          className="fe fe-copy"
        />
      )}
    </h3>
  )
}

const NavMenu = ({ menu }) => {
  return <Navigation menu={menu} active="faq" />
}

export default NavContainer
