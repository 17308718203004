import { queryParamsConstants } from '../_constants'

const initialState = {
  where: null,
}

function queryParams(state = initialState, { type, payload }) {
  switch (type) {
    case queryParamsConstants.SET_QUERY_PARAMS:
      return { ...state, where: payload }
    case queryParamsConstants.CLEAR_QUERY_PARAMS:
      return initialState
    default:
      return state
  }
}

export default queryParams
