import { useCallback, useState } from 'react'

// eslint-disable-next-line import/prefer-default-export
export const useBatchOrdersTable = rows => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedOrders, setSelectedOrders] = useState([])

  const rowClassName = useCallback(row => {
    let className = ''

    if (row.intl && !row.childrenNodes) className += ' yellow-bg'
    if ((row.gcard !== '-' || row.custom_inserts !== '-') && !row.childrenNodes)
      className += ' blue-bg'
    if (row.parent_id) className += ' batch-order-children'

    return className
  }, [])

  const selected = rows => {
    return {
      keys: rows.flatMap(i => i.id),
      orders: rows.flatMap(i => {
        if (i.childrenNodes) {
          return i.childrenNodes.map(i => i.id)
        }

        return i.id
      }),
    }
  }

  const deselectAllChildrens = () => {
    setSelectedRowKeys([])
    setSelectedOrders([])
  }

  // eslint-disable-next-line consistent-return
  const defaultSelectAll = selectedRows => {
    if (selectedRows.length === selectedRowKeys.length) {
      return deselectAllChildrens()
    }
    const selectedKeys = selected(selectedRows).keys
    const selectedOrders = selected(selectedRows).orders

    setSelectedRowKeys(selectedKeys)
    setSelectedOrders(selectedOrders)
  }

  const countSelect = (selectedRows, count) => {
    const selectedKeys = selected(selectedRows).keys
    const selectedOrders = selected(selectedRows).orders.slice(0, count)

    const getKeys = () => {
      return selectedRows.map(i => {
        if (i.childrenNodes) {
          const currentOrders = selectedOrders.filter(value =>
            i.childrenNodes.map(i => i.id).includes(value),
          )

          const isIntersected = i.childrenNodes.every(
            (item, index) => item.id === currentOrders[index],
          )

          if (isIntersected) {
            return i.id
          }
        }

        return null
      })
    }

    const keys = selectedKeys.filter(value => [...selectedOrders, ...getKeys()].includes(value))

    setSelectedRowKeys(keys)
    setSelectedOrders(selectedOrders)
  }

  const totalLength = () => {
    const ids = rows.flatMap(i => {
      if (i.childrenNodes) {
        return i.childrenNodes.map(i => i.id)
      }

      return i.id
    })

    return ids.length
  }

  const configureSelection = {
    type: 'checkbox',
    checkStrictly: false,
    selectedRowKeys,
    onSelectAll: () => defaultSelectAll(rows),
    onSelect: (record, selected) => {
      const isChildrenNodes = record.childrenNodes
      const childrenNodesIds = record.childrenNodes?.map(i => i.id)

      if (!selected) {
        return (
          setSelectedRowKeys(prev => prev.filter(key => key !== record.id)),
          setSelectedOrders(prev =>
            prev.filter(i => {
              if (isChildrenNodes) {
                return childrenNodesIds.indexOf(i) < 0
              }

              return i !== record.id
            }),
          )
        )
      }

      setSelectedRowKeys(prev => prev.concat(record.id))
      setSelectedOrders(prev => {
        if (isChildrenNodes) {
          return prev.concat(childrenNodesIds)
        }

        return prev.concat(record.id)
      })

      return true
    },
  }

  const selectChildrenNodes = (record, selected) => {
    if (!selected) {
      return setSelectedOrders(prev => prev.filter(i => i !== record.id))
    }

    setSelectedOrders(prev => prev.concat(record.id))
    return true
  }

  return {
    selectedRowKeys,
    setSelectedRowKeys,
    rowClassName,
    configureSelection,
    selectChildrenNodes,
    selectedOrders,
    deselectAllChildrens,
    rows,
    totalLength,
    defaultSelectAll,
    countSelect,
  }
}
