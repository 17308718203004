import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router'
import getCurrentUrl from '../../../../_helpers/url-utils'
import NavContainer from '../../../../components/nav-container'
import ListViewer from '../../../../components/list-viewer'
import { insertsActions } from '../../../../_actions'
import { insertsConstants, settingsConstants } from '../../../../_constants'
import { getInsertsRows, getPage, getTotalItems } from '_selectors/inserts.selector'

const { navMenu, listItemKeyToLabel, insertStatus, insertable } = insertsConstants

const navMenuInit = [navMenu.create, navMenu.manage]

const ListItems = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getInsertsRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const navigateToManage = () => {
    dispatch({ type: insertsConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.manage.key:
          return {
            ...menu,
            url: navigateToManage,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const listItems = useMemo(() => {
    return {
      total,
      page,
      rows: rows?.map(item => {
        const res = []
        Object.keys(listItemKeyToLabel).forEach(sKey => {
          const itemLine = { label: listItemKeyToLabel[sKey], value: item[sKey], key: sKey }
          if (sKey === 'id') {
            itemLine.clickHandler = () => {
              history.push({
                pathname: `/inserts/view/${item.id}`,
              })
            }
            itemLine.toolTip = 'Click to view'
          } else if (sKey === 'status') {
            itemLine.value = `${
              insertStatus.find(st => st.value.toString() === itemLine.value.toString())?.text
            }`
          } else if (sKey === 'insertable') {
            itemLine.value = `${
              insertable.find(st => st.value.toString() === itemLine.value)?.text
            }`
          } else if (sKey === 'user') {
            if (item.user) {
              itemLine.clickHandler = () => {
                history.push({
                  pathname: `/clients/edit/${item.user.id}`,
                })
              }
              itemLine.value = `[ID: ${item.user.id}] ${item.user.name}`
            }
          } else if (sKey === 'quantity') {
            itemLine.value = item.quantity || '0'
          } else if (sKey === 'low_stock_threshold') {
            itemLine.value = item.low_stock_threshold || 'none'
          }
          res.push(itemLine)
        })
        return res
      }),
    }
  }, [rows, total, page])

  return (
    <NavContainer menu={navMenuParse}>
      <ListViewer data={listItems} getDataAction={insertsActions.getAll} />
    </NavContainer>
  )
}

export default ListItems
