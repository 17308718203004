import React from 'react'
import { Space, Input } from 'antd'
import UserMenu from './UserMenu'
import DownloadBar from './DownloadsBar'
import { useDispatch, useSelector } from 'react-redux'
import menuActions from '_actions/menu.actions'

import style from './style.module.scss'

const { Search } = Input

const TopBar = () => {
  const dispatch = useDispatch()

  const isMenuCollapsed = useSelector(state => state.settings.isMenuCollapsed)
  const search = useSelector(state => state.menu.search)

  const onSearch = e => {
    dispatch(menuActions.setSearch(e.target.value))
  }
  return (
    <div className={style.topbar}>
      <div className="me-auto">{/* <Search /> */}</div>
      <div className={isMenuCollapsed ? style['topbar-container'] : ''}>
        {isMenuCollapsed && (
          <Search
            placeholder="Search"
            value={search}
            onChange={onSearch}
            enterButton
            allowClear
            style={{ width: '15rem' }}
          />
        )}
        <Space>
          <DownloadBar />
          <UserMenu />
        </Space>
      </div>
    </div>
  )
}

export default TopBar
