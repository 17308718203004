import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userActions } from '../../_actions'

const DashboardAlpha = () => {
  const dispatch = useDispatch()

  const { user } = useSelector(state => state.authentication)

  return (
    <div>
      <h2>Welcome to Handwrytten Admin</h2>
      <h5>
        Hello, {user.username},{' '}
        <a href="#" onClick={() => dispatch(userActions.logout())}>
          Logout
        </a>
        ?
      </h5>
    </div>
  )
}

export default DashboardAlpha
