import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { ManageItems, ViewItem, ListItems, CreateItem, EditItem } from './views'
import Content from '../../components/content'

const PrepaidCreditsType3 = () => {
  const match = useRouteMatch()

  return (
    <Content>
      <Switch>
        <Route exact path={`${match.url}`} component={ManageItems} />
        <Route path={`${match.url}/create`} component={CreateItem} />
        <Route path={`${match.url}/list`} component={ListItems} />
        <Route path={`${match.url}/view/:id`} component={ViewItem} />
        <Route path={`${match.url}/edit/:id`} component={EditItem} />

        <Redirect to={match.url} />
      </Switch>
    </Content>
  )
}

export default PrepaidCreditsType3
