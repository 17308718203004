import { categoriesConstants } from '../_constants'
import { categoriesService } from '../_services'
import { history } from '../_helpers'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: categoriesConstants.GETALL_REQUEST })
  try {
    const categories = await categoriesService.getAll(params, typeAction)
    dispatch({ type: categoriesConstants.GETALL_SUCCESS, payload: categories.data })
  } catch (error) {
    dispatch({ type: categoriesConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const addItem = value => async dispatch => {
  dispatch({ type: categoriesConstants.ADD_REQUEST })

  try {
    const category = await categoriesService.addItem({ value })
    dispatch({ type: categoriesConstants.ADD_SUCCESS, payload: { ...value, id: category.id } })
    history.push(`/categories/view/${category.id}`)
  } catch (error) {
    dispatch({ type: categoriesConstants.ADD_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: categoriesConstants.GETBYID_REQUEST })

  try {
    const category = await categoriesService.getItemById(id)
    dispatch({ type: categoriesConstants.GETBYID_SUCCESS, payload: category.data })
  } catch (error) {
    dispatch({ type: categoriesConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const deleteItem = id => async dispatch => {
  dispatch({ type: categoriesConstants.DELETE_REQUEST })
  try {
    await categoriesService.deleteItem(id)
    history.push(`/categories`)
    dispatch({ type: categoriesConstants.DELETE_SUCCESS, payload: id })
  } catch (error) {
    dispatch({ type: categoriesConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const updateItemById = (id, value) => async dispatch => {
  dispatch({ type: categoriesConstants.UPDATE_REQUEST })

  try {
    const updated = await categoriesService.updateItemById(id, value)
    dispatch({ type: categoriesConstants.UPDATE_SUCCESS, payload: updated?.message?.toString() })
    history.push(`/categories/view/${id}`)
  } catch (error) {
    dispatch({ type: categoriesConstants.UPDATE_FAILURE, payload: error.toString() })
  }
}

const categoriesActions = {
  getAll,
  getItemById,
  addItem,
  deleteItem,
  updateItemById,
}

export default categoriesActions
