import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import v4 from 'uuid/v4'
import { useHistory } from 'react-router-dom'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { appConstants, framesConstants, settingsConstants } from '../../../../_constants'
import { framesActions } from '../../../../_actions'
import { getFrameRows, getPage, getTotalItems } from '../../../../_selectors/frames.selector.js'

const { navMenu, frameStatuses, advancedSearch, frameTypes } = framesConstants

const statusRender = text => {
  return `${frameStatuses.find(st => st.value.toString() === text.toString())?.text}`
}

const typeRender = text => {
  return `${frameTypes.find(st => st.value.toString() === text.toString())?.text}`
}

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status_filter',
    filters: frameStatuses,
    render: statusRender,
    width: '10%',
    sorter: true,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    filters: frameTypes,
    render: typeRender,
    key: 'type',
    sorter: true,
  },
  {
    title: 'Client Groups',
    dataIndex: 'user_groups',
    key: 'user_groups=>title',
    sorter: true,
  },
  {
    title: 'Frame',
    dataIndex: 'frame',
    key: 'frame',
    // eslint-disable-next-line react/display-name
    render: imageUrl => <img style={{ height: '100px' }} alt=" " src={imageUrl} />,
    hideFilter: true,
  },
]

const navMenuInit = [navMenu.list, navMenu.create]

const ManageItems = ({ match }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getFrameRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const navigateToList = () => {
    dispatch({ type: framesConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}/list`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.list.key:
          return {
            ...menu,
            url: navigateToList,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const deleteItems = async id => {
    await dispatch(framesActions.deleteItem(id))
  }

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        frame: item.url ? `${appConstants.IMAGES_URL}/frames/${item.url}` : '',
        user_groups: item.user_group?.title,
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  const frameStatusesSelect = useMemo(
    () => frameStatuses.map(status => ({ value: status.value, label: status.text })),
    [frameStatuses],
  )

  const updAdvancedSearch = advancedSearch.map(field => {
    if (field.key === 'status_filter') return { ...field, options: frameStatusesSelect }
    return field
  })

  return (
    <NavContainer match={match} menu={navMenuParse}>
      <TableFilter
        deleteItems={deleteItems}
        columns={initColumns}
        data={dataSource}
        getDataAction={framesActions.getAll}
        advancedSearch={updAdvancedSearch}
      />
    </NavContainer>
  )
}

export default ManageItems
