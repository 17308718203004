import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
// import { useSelector } from "react-redux"
import { CreateItem, EditItem, ListItems, ManageItems, ViewItem } from './views'
import Content from '../../components/content'

const Countries = () => {
  const match = useRouteMatch()
  // const permis = useSelector(state => state.users.permis);
  // const permisKeys = Object.keys(permis);
  return (
    <Content>
      <Switch>
        {/* {!permisKeys.includes('superuser') && <Redirect to='/error'/>} */}
        <Route exact path={match.url} component={ManageItems} />
        <Route path={`${match.url}/create`} component={CreateItem} />
        <Route path={`${match.url}/list`} component={ListItems} />
        <Route path={`${match.url}/edit/:id`} component={EditItem} />
        <Route path={`${match.url}/view/:id`} component={ViewItem} />

        <Redirect to={match.url} />
      </Switch>
    </Content>
  )
}

export default Countries
