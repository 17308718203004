import { spinnerConstants } from '../_constants'

const initialState = {
  isLoading: false,
  loadingManagePage: true,
  isGlobalLoading: false,
}

function spinner(state = initialState, { type }) {
  switch (type) {
    case spinnerConstants.START:
      return { ...state, isLoading: true }
    case spinnerConstants.STOP:
      return { ...state, isLoading: false }
    case spinnerConstants.START_GLOBAL_LOADER:
      return { ...state, isGlobalLoading: true }
    case spinnerConstants.STOP_GLOBAL_LOADER:
      return { ...state, isGlobalLoading: false }
    case spinnerConstants.START_ON_MANAGE_PAGE:
      return { ...state, loadingManagePage: true }
    case spinnerConstants.STOP_ON_MANAGE_PAGE:
      return { ...state, loadingManagePage: false }
    default:
      return state
  }
}

export default spinner
