import { cardsConstants } from '../_constants'

const initialState = {
  items: [],
  item: {},
  downloadProgress: [],
}

function cards(state = initialState, { type, payload }) {
  switch (type) {
    case cardsConstants.GETALL_SUCCESS:
      return { ...state, items: payload, item: {} }
    case cardsConstants.GETBYID_SUCCESS:
      return { ...state, item: payload }
    case cardsConstants.DELETE_SUCCESS:
      return { ...state }
    case cardsConstants.UPDATE_SUCCESS:
      return { ...state, items: state.items }
    case cardsConstants.ADD_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          total: state.items.total + 1,
        },
      }
    case cardsConstants.GENERATE_PDF_REQUEST:
      const newProgress = { filename: payload, percentage: null }
      return { ...state, downloadProgress: [...state.downloadProgress, newProgress] }

    case cardsConstants.PDF_DOWNLOAD_PROGRESS:
      const downloadsClone = [...state.downloadProgress]

      const existingProgressIndex = state.downloadProgress.findIndex(
        el => el.filename === payload.filename,
      )

      const progressExist = existingProgressIndex !== -1

      if (progressExist) downloadsClone[existingProgressIndex].percentage = payload.percentage

      return {
        ...state,
        downloadProgress: !progressExist ? [...state.downloadProgress, payload] : downloadsClone,
      }

    case cardsConstants.GENERATE_PDF_SUCCESS:
    case cardsConstants.GENERATE_PDF_FAILURE:
      const downloads = [...state.downloadProgress]
      const indexToRemove = state.downloadProgress.findIndex(el => el.filename === payload)

      if (indexToRemove > -1) {
        downloads.splice(indexToRemove, 1)
      }
      return {
        ...state,
        downloadProgress: downloads,
      }
    case cardsConstants.SETBYID:
      return {
        ...state,
        item: {},
      }
    case cardsConstants.SETALL:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default cards
