import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import v4 from 'uuid/v4'
import { Link } from 'react-router-dom'
import getCurrentUrl from '../../../../_helpers/url-utils'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import { insertsActions } from '../../../../_actions'
import { insertsConstants, settingsConstants } from '../../../../_constants'
import { getInsertsRows, getPage, getTotalItems } from '_selectors/inserts.selector'

const { navMenu, advancedSearch, insertStatus, insertable } = insertsConstants

const statusRender = text => {
  return `${insertStatus.find(st => st.value?.toString() === text?.toString())?.text}`
}

const insertableRender = text => {
  return `${insertable.find(st => st.value === text)?.text}`
}

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user=>login',
    width: '20%',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status_filter',
    render: statusRender,
    filters: insertStatus,
    width: '10%',
    sorter: true,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '40%',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Insertable',
    dataIndex: 'insertable',
    key: 'insertable',
    render: insertableRender,
    filters: insertable,
    width: '10%',
    sorter: true,
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    width: '20%',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
]

const navMenuInit = [navMenu.list, navMenu.create]

const ManageItems = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getInsertsRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const group_id = useSelector(state => state.queryParams?.where?.group_id)
  const { superuser } = useSelector(state => state.authentication.user)

  const deleteItem = async id => {
    await dispatch(insertsActions.deleteItem(id))
  }

  const navigateToList = () => {
    dispatch({ type: insertsConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}/list`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.list.key:
          return {
            ...menu,
            url: navigateToList,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  let insertsColumns = []

  if (group_id) {
    insertsColumns = initColumns.map(column => {
      if (column.key === 'user') {
        return {
          title: 'Client Group',
          dataIndex: 'group_id',
          key: 'group_id',
          width: '20%',
          sorter: true,
          sortDirections: ['descend', 'ascend'],
        }
      }
      return column
    })
  }

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        user: <Link to={`/clients/edit/${item?.user?.id}`}>{item?.user?.name}</Link>,
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  return (
    <NavContainer menu={navMenuParse}>
      <TableFilter
        deleteItems={deleteItem}
        columns={group_id ? insertsColumns : initColumns}
        data={dataSource}
        getDataAction={insertsActions.getAll}
        isEditOnId={!!superuser}
        advancedSearch={advancedSearch}
        showAdvancedButton={!!true}
      />
    </NavContainer>
  )
}

export default ManageItems
