import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Form, Input, InputNumber, Select } from 'antd'
import NavContainer from '../../../../components/nav-container'
import { ShippingRatesActions } from '../../../../_actions'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import { ShippingRatesConstants } from '../../../../_constants'
import { convertDataForSelect } from '../../../../_helpers'

const { ShippingRatesStatuses, formItemLayout, navMenu } = ShippingRatesConstants

const navMenuInit = [navMenu.list, navMenu.manage]

const CreateItem = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const onFinish = async values => {
    const mergedShippingRate = {
      ...values,
      mark_up: values.mark_up / 100, // 30% => 0.03
    }
    const convertedShippingRates = convertFormValuesDeep(mergedShippingRate)

    await dispatch(ShippingRatesActions.addItem(convertedShippingRates))
  }

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      <Form {...formItemLayout} name="basic" form={form} onFinish={onFinish} scrollToFirstError>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input name!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Ups Code"
          name="ups_code"
          rules={[
            {
              required: true,
              message: 'Please input ups code!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Base"
          name="base"
          initialValue={0}
          rules={[
            {
              required: true,
              message: 'Please input base!',
            },
          ]}
        >
          <InputNumber step={0.1} min={0.0} />
        </Form.Item>

        <Form.Item
          label="Plus Price per Card"
          name="per_card_fee"
          initialValue={0}
          rules={[
            {
              required: true,
              message: 'Please input Plus Price per Card!',
            },
          ]}
        >
          <InputNumber step={0.1} min={0.0} />
        </Form.Item>

        <Form.Item
          label="Minimum fee"
          name="minimum_fee"
          initialValue={0}
          rules={[
            {
              required: true,
              message: 'Please input Minimum fee!',
            },
          ]}
        >
          <InputNumber step={1} min={0} />
        </Form.Item>

        <Form.Item label="Additional fee" name="additional_fee" initialValue={0}>
          <InputNumber step={1} min={0} />
        </Form.Item>

        <Form.Item label="Markup" name="mark_up" initialValue={0}>
          <InputNumber
            min={0}
            max={100}
            formatter={value => `${value}%`}
            parser={value => value.replace('%', '')}
          />
        </Form.Item>

        <Form.Item label="Sort Order" name="sort_order" initialValue={0}>
          <InputNumber step={1} min={0} />
        </Form.Item>

        <Form.Item label="Earliest business days" name="earliest_business_days" initialValue={0}>
          <InputNumber step={1} min={0} />
        </Form.Item>

        <Form.Item
          label="Extra day per num orders"
          name="extra_day_per_num_orders"
          initialValue={0}
        >
          <InputNumber step={1} min={0} />
        </Form.Item>

        <Form.Item label="Status" name="status" initialValue={0}>
          <Select options={convertDataForSelect(ShippingRatesStatuses)} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </NavContainer>
  )
}

export default CreateItem
