import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import v4 from 'uuid/v4'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { appConstants, presetCardImagesConstants, settingsConstants } from '../../../../_constants'
import {
  getPresetCardImageRows,
  getPage,
  getTotalItems,
} from '../../../../_selectors/preset-card-images.selector'
import presetCardImagesActions from '_actions/preset-card-images.actions'
import { cardDimensionActions } from '_actions'
import { getCardDimensions } from '_selectors/card-dimension.selector'

const {
  navMenu,
  renderComponent,
  advancedSearch,
  cardImageType,
  presetCardImageStatuses,
} = presetCardImagesConstants

const statusRender = text => {
  return `${presetCardImageStatuses.find(st => st.value.toString() === text.toString())?.text}`
}

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Image',
    dataIndex: 'url',
    key: 'url',
    sorter: true,
    hideFilter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type_filter',
    sorter: true,
    filters: cardImageType,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Sort no',
    dataIndex: 'sort_no',
    key: 'sort_no',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Card Dimensions',
    dataIndex: 'dimensions',
    filterSearch: true,
    key: 'dimensions_relation_filter',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    filters: presetCardImageStatuses,
    sorter: true,
    render: statusRender,
    width: '10%',
    sortDirections: ['descend', 'ascend'],
  },
]

const navMenuInit = [navMenu.list, navMenu.create]

const ManageItems = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)
  const { id } = useParams()

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getPresetCardImageRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)
  const cardDimensions = useSelector(getCardDimensions)

  useEffect(() => {
    dispatch(cardDimensionActions.getAll())
  }, [])

  const cardDimensionsFilters = useMemo(
    () => cardDimensions?.map(dimension => ({ value: dimension.id, text: dimension.name })),
    [cardDimensions],
  )

  const columnsWithFilters = useMemo(() => {
    return initColumns.map(column => {
      if (column.key === 'dimensions_relation_filter') {
        return {
          ...column,
          filters: cardDimensionsFilters,
        }
      }
      return column
    })
  }, [cardDimensionsFilters])

  const deleteItems = async id => {
    await dispatch(presetCardImagesActions.deleteItem(id))
  }

  const navigateToList = () => {
    dispatch({ type: presetCardImagesConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}/list`,
    })
  }

  const navigateToCreate = () => {
    dispatch({ type: presetCardImagesConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}/create`,
    })
  }

  useEffect(() => {
    if (id && navMenuInit.length < 2) navMenuInit.push(navMenu.create)
  }, [id, navMenuInit.length])

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.list.key:
          return {
            ...menu,
            url: navigateToList,
          }
        case navMenu.create.key:
          return {
            ...menu,
            url: navigateToCreate,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        url: renderComponent.image(`${appConstants.IMAGES_URL}/${item.url}`, item.url),
        dimensions:
          item.dimensions?.map((dimension, index) => {
            return (
              <span key={dimension.id}>
                {index !== 0 ? <br /> : ''}
                <span>{dimension.title}</span>
              </span>
            )
          }) || '',

        dimensions: item.dimensions.map(dimension => (
          <Link key={dimension.id} to={`/card-dimension/edit/${dimension.id}`}>
            {dimension.title}
            <br />
          </Link>
        )),

        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  const cardImageTypeSelect = useMemo(() => {
    return cardImageType.map(status => ({ value: status.value, label: status.text }))
  }, [cardImageType])

  const updAdvancedSearch = advancedSearch.map(field => {
    if (field.key === 'type_filter') return { ...field, options: cardImageTypeSelect }
    return field
  })

  return (
    <NavContainer menu={navMenuParse}>
      <TableFilter
        deleteItems={deleteItems}
        columns={columnsWithFilters}
        data={dataSource}
        getDataAction={presetCardImagesActions.getAll}
        advancedSearch={updAdvancedSearch}
      />
    </NavContainer>
  )
}

export default ManageItems
