import { spinnerConstants } from '../_constants'

const spinnerActions = {
  start,
  stop,
  startGlobalLoader,
  stopGlobalLoader,
}

function start() {
  return { type: spinnerConstants.START }
}

function startGlobalLoader() {
  return { type: spinnerConstants.START_GLOBAL_LOADER }
}

function stopGlobalLoader() {
  return { type: spinnerConstants.STOP_GLOBAL_LOADER }
}

function stop() {
  return { type: spinnerConstants.STOP }
}

export default spinnerActions
