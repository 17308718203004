const discountsRulesConstants = {
  GETALL_REQUEST: 'DISCOUNT_RULES_CONSTANTS_GETALL_REQUEST',
  GETALL_SUCCESS: 'DISCOUNT_RULES_CONSTANTS_GETALL_SUCCESS',
  GETALL_FAILURE: 'DISCOUNT_RULES_CONSTANTS_GETALL_FAILURE',

  GETBYID_REQUEST: 'DISCOUNT_RULES_CONSTANTS_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'DISCOUNT_RULES_CONSTANTS_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'DISCOUNT_RULES_CONSTANTS_GETBYID_FAILURE',

  ADD_REQUEST: 'DISCOUNT_RULES_CONSTANTS_ADD_REQUEST',
  ADD_SUCCESS: 'DISCOUNT_RULES_CONSTANTS_ADD_SUCCESS',
  ADD_FAILURE: 'DISCOUNT_RULES_CONSTANTS_ADD_FAILURE',

  DELETE_REQUEST: 'DISCOUNT_RULES_CONSTANTS_DELETE_REQUEST',
  DELETE_SUCCESS: 'DISCOUNT_RULES_CONSTANTS_DELETE_SUCCESS',
  DELETE_FAILURE: 'DISCOUNT_RULES_CONSTANTS_DELETE_FAILURE',

  UPDATE_REQUEST: 'DISCOUNT_RULES_CONSTANTS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'DISCOUNT_RULES_CONSTANTS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'DISCOUNT_RULES_CONSTANTS_UPDATE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    list: { key: 'list', title: 'List Discount Rule', url: '/discount-rules/list' },
    manage: { key: 'manage', title: 'Manage Discount Rule', url: '/discount-rules' },
    create: { title: 'Create Discount Rule', key: 'create', url: '/discount-rules/create' },
    edit: { key: 'edit', title: 'Update Discount Rule', url: '' },
    delete: { key: 'delete', title: 'Delete Discount Rule', url: '' },
    view: { key: 'view', title: 'View Discount Rule', url: '' },
  },

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },

  viewItemKeyToLabel: {
    id: 'ID',
    user_group: 'User Group',
    min_number: 'Min Number',
    discount: 'Discount',
  },

  listItemKeyToLabel: {
    id: 'ID',
    user_group: 'User Group',
    min_number: 'Min Number',
    discount: 'Discount',
  },
  advancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'User Group', key: 'user_groups=>title' },
    { label: 'Min Number', key: 'min_number' },
    { label: 'Discount', key: 'discount' },
  ],
}
export default discountsRulesConstants
