const creditHistoryConstants = {
  GETALL_REQUEST: 'CREDIT_HISTORY_GETALL_REQUEST',
  GETALL_SUCCESS: 'CREDIT_HISTORY_SUCCESS',
  GETALL_FAILURE: 'CREDIT_HISTORY_FAILURE',

  SETALL: 'SETALL',

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },

  typeOperation: [
    { value: 'INITIAL', text: 'INITIAL' },
    { value: 'NEW', text: 'NEW INITIAL' },
    { value: 'INC', text: 'INCREMENT' },
    { value: 'DEC', text: 'DECREMENT' },
  ],

  typeCredit: [
    { value: 'credit', text: 'CREDIT (Account credit)' },
    { value: 'credit_type2', text: 'CREDIT_TYPE2 (Coupon credit)' },
    { value: 'credit_type3', text: 'CREDIT_TYPE3 (Prepaid credit)' },
    { value: 'user_credit2', text: 'USER_CREDIT2 (Bonus credit)' },
  ],

  listItemKeyToLabel: {
    id: 'ID',
    user_id: 'User',
    credit: 'Credit',
    type_operation: 'Type Operation',
    before_amount: 'Before Amount',
    amount: 'Amount',
    after_amount: 'After Amount',
    entity_id: 'User Credit',
    created_at: 'Created At',
    updated_at: 'Updated At',
  },
}
export default creditHistoryConstants
