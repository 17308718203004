const creditCardsConstants = {
  GETALL_REQUEST: 'CREDIT_CARDS_GETALL_REQUEST',
  GETALL_SUCCESS: 'CREDIT_CARDS_SUCCESS',
  GETALL_FAILURE: 'CREDIT_CARDS_FAILURE',

  SETALL: 'SETALL',

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },

  typeCard: [
    { value: 0, text: 'Visa' },
    { value: 1, text: 'MasterCard' },
    { value: 2, text: 'Discover' },
    { value: 3, text: 'American Express' },
    { value: 64, text: 'Apple Pay' },
  ],

  listItemKeyToLabel: {
    id: 'ID',
    user_id: 'User',
    anet_profile_id: 'Anet Profile',
    type: 'Type',
    card_number: 'Card Number',
    expiration_date: 'Expiration Date',
    zip: 'Zip',
    country_id: 'Country Id',
    address: 'Address',
  },
}
export default creditCardsConstants
