import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, Input, Select } from 'antd'
import NavContainer from 'components/nav-container'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import { notesConstants } from '_constants'
import { employeesActions, clientNotesActions } from '_actions'
import { useHistory } from 'react-router'
import { getEmployeeRows } from '../../../../_selectors/employees.selector.js'
import { getUser } from '../../../../_selectors/authentication.selector.js'
import useClientsToOptionsList from 'hooks/clients/useClientsToOptionsList'

const { navMenu } = notesConstants

const navMenuInit = [navMenu.manage, navMenu.list]

const CreateItem = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [form] = Form.useForm()

  const [navMenuParse, setNavMenuParse] = useState([])

  const employees = useSelector(getEmployeeRows)
  const user = useSelector(getUser)
  const { where } = useSelector(state => state.queryParams)

  const clientsListOptions = useClientsToOptionsList(employeesActions.getAll, {
    where: { status: 1 },
  })

  useEffect(() => {
    form.setFieldsValue({
      employee_id: user.id,
    })
  }, [form, user])

  useEffect(() => {
    if (where?.user_id)
      form.setFieldsValue({
        user_id: +where.user_id,
      })
  }, [where])

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.list.key:
          return {
            ...menu,
            url: onListItems,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const onListItems = () => {
    history.push({
      pathname: `/client-notes/list`,
    })
  }

  const plainEmployees = useMemo(() => {
    if (!employees) {
      return [
        {
          id: user.id,
          username: user.username,
        },
      ]
    }
    return employees.map(employee => ({
      id: employee.id,
      username: employee.username,
    }))
  }, [employees])

  const onFinish = values => {
    const newNote = { ...values, timestamp: Date.now().toString() }
    dispatch(clientNotesActions.createNote(convertFormValuesDeep(newNote)))
    form.resetFields()
  }

  return (
    <NavContainer menu={navMenuParse}>
      <>
        <p>Fields with * are required.</p>
        <Form name="createNote" form={form} onFinish={onFinish} scrollToFirstError>
          <Form.Item
            label="Employee"
            name="employee_id"
            rules={[
              {
                required: true,
                message: 'Please select Employee!',
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Select or search a employee"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children[1].toString().includes(input) || option.children[3]?.includes(input)
              }
            >
              {plainEmployees?.map(employee => (
                <Select.Option key={employee.id} value={employee.id}>
                  [ID: {employee.id}] {employee.username}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="User"
            name="user_id"
            rules={[
              {
                required: true,
                message: 'Please select User!',
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Select or search a user"
              optionFilterProp="children"
              filterOption={(input, option) => option.label.includes(input.toString())}
              options={clientsListOptions}
            />
          </Form.Item>

          <Form.Item
            label="Note"
            name="note"
            rules={[
              {
                required: true,
                message: 'Please input note!',
              },
            ]}
          >
            <Input.TextArea rows={5} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>
      </>
    </NavContainer>
  )
}

export default CreateItem
