import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import Cropper from 'react-cropper'
import { Button, Checkbox, Form, InputNumber, Modal, Select, Upload } from 'antd'
import { ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import { cardImagesActions, cardsActions } from '../../../../_actions'
import { appConstants, cardImagesConstants } from '../../../../_constants'
import { convertDataForSelect, parseFileForSetFields } from '../../../../_helpers'
import getCurrentUrl from '../../../../_helpers/url-utils'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import { getCardImage } from '../../../../_selectors/card-images.selector.js'
import { getCardById } from '../../../../_selectors/cards.selector.js'

const { formItemLayout, navMenu, cardImageType } = cardImagesConstants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.view, navMenu.delete, navMenu.manage]

const EditItem = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const view = getCurrentUrl(match.url)
  const [form] = Form.useForm()
  const params = useParams()

  const { id } = params
  const card_id = useSelector(state => state.queryParams?.where?.card_id)

  const card = useSelector(getCardById)
  const card_image = useSelector(getCardImage)

  const [navMenuParse, setNavMenuParse] = useState([])
  const [cardImage, setCardImage] = useState(null)
  const [cropper, setCropper] = useState()
  const [changed, setChange] = useState(false)

  useEffect(() => {
    dispatch(cardImagesActions.getItemById(id))
  }, [dispatch, id])

  useEffect(() => {
    if (card_image?.card_id) dispatch(cardsActions.getItemById(card_image?.card_id))
  }, [dispatch, card_image])

  useEffect(() => {
    return () => {
      dispatch({ type: cardImagesConstants.SETBYID })
    }
  }, [])

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(cardImagesActions.deleteItem(id))
      },
    })
  }

  const onViewItem = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }
  const onCreateItem = () => {
    history.push(`${view}/create/${card_image?.card_id}`)
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: onViewItem,
          }
        case navMenu.create.key:
          return {
            ...menu,
            url: onCreateItem,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit, card_image])

  useEffect(() => {
    if (card_image) {
      if (cropper && card) changeType(card_image.type)
      form.setFieldsValue({
        ...card_image,
        file: parseFileForSetFields(card_image.image, {
          url: `${appConstants.IMAGES_URL}/cardimages/${card_image.image}`,
        }),
      })
      setCardImage(`${appConstants.IMAGES_URL}/cardimages/${card_image.image}`)
    }
  }, [card_image, form, card])

  const normFile = e => {
    let file = e && e.fileList

    if (Array.isArray(e)) file = e

    const reader = new FileReader()
    reader.onload = () => setCardImage(reader.result)
    reader.readAsDataURL(file[0].originFileObj)

    return file
  }

  const onFinish = async ({ type, sort_no, crop, file }) => {
    const image = file.length ? file[0] : undefined

    const newItem = {
      card_id: card_id || card_image?.card_id,
      file: {
        name: image?.name || card_image.image,
      },
      type,
      sort_no,
    }

    if (crop) {
      const base64line = cropper.getCroppedCanvas().toDataURL()
      newItem.file.thumbUrl = base64line
      newItem.file.size = Math.round((base64line.length * 3) / 4)
    } else if (image?.name !== card_image.image) {
      newItem.file.thumbUrl = cardImage
      newItem.file.size = image.size
    }

    if (!newItem.file?.thumbUrl) newItem.file = null

    await dispatch(cardImagesActions.updateItemById(id, convertFormValuesDeep(newItem)))
  }

  const changeType = type => {
    let aspect = null
    if (cropper && card && type) {
      const { closed_height, closed_width, open_height, open_width } = card
      if (type === 'front' || type === 'back') aspect = closed_width / closed_height
      if (type === 'inside') aspect = open_width / open_height
      cropper.setAspectRatio(aspect)
    }
    return aspect
  }

  const props = {
    showUploadList: {
      showRemoveIcon: false,
    },
  }

  return (
    <NavContainer menu={navMenuParse}>
      {useGlobalLoader(
        <>
          <div>
            <p>Fields with * are required.</p>
            <Form
              {...formItemLayout}
              name="basic"
              form={form}
              onFinish={onFinish}
              onFieldsChange={() => setChange(true)}
              scrollToFirstError
            >
              <Form.Item
                label="Image"
                name="file"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[
                  {
                    required: true,
                    message: 'Please input Image!',
                  },
                ]}
              >
                <Upload
                  {...props}
                  name="icon"
                  listType="picture"
                  maxCount={1}
                  beforeUpload={() => false}
                  action=""
                >
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>

              <Form.Item label="Crop" name="crop" valuePropName="checked">
                <Checkbox />
              </Form.Item>

              <Form.Item name="type" label="Type">
                <Select
                  style={{ width: '140px' }}
                  options={convertDataForSelect(cardImageType)}
                  onChange={value => changeType(value)}
                />
              </Form.Item>

              <Form.Item label="Sort No" name="sort_no">
                <InputNumber />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType=" submit" disabled={!changed}>
                  Save
                </Button>
              </Form.Item>
            </Form>
          </div>
          {cardImage && (
            <Cropper
              style={{ width: '100%', height: '635px' }}
              src={cardImage}
              aspectRatio={() => changeType(card_image.type)}
              viewMode={2}
              autoCropArea={1}
              movable={false}
              rotatable={false}
              scalable={false}
              zoomable={false}
              toggleDragModeOnDblclick={false}
              onInitialized={instance => setCropper(instance)}
            />
          )}
        </>,
      )}
    </NavContainer>
  )
}

export default EditItem
