import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import v4 from 'uuid/v4'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import { campaignStepsConstants } from '../../../../_constants'
import { campaignStepsActions } from '_actions'
import {
  getPage,
  getCampaignStepRows,
  getTotalItems,
} from '../../../../_selectors/campaign-step.selector.js'

const { navMenu, deliveryConfirmationFilters } = campaignStepsConstants
const navMenuInit = [navMenu.manageCampaigns, navMenu.viewCampaign]

const renderDeliveryConfirmation = delivery_confirmation => (delivery_confirmation ? 'YES' : 'NO')

const renderMessage = children => {
  return {
    props: {
      style: {
        maxWidth: '300px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
    children,
  }
}

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Step Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'User',
    dataIndex: 'user',
    hideFilter: true,
  },
  {
    title: 'Campaign ID',
    dataIndex: 'multi_campaign_id',
    key: 'multi_campaign_id',
    hideFilter: true,
  },
  {
    title: 'Card',
    dataIndex: 'card',
    key: 'card=>name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Insert',
    dataIndex: 'insert',
    key: 'inserts=>name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Gift Card',
    dataIndex: 'denomination',
    key: 'denomination',
    hideFilter: true,
  },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: renderMessage,
  },
  {
    title: 'Wishes',
    dataIndex: 'wishes',
    key: 'wishes',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: renderMessage,
  },
  {
    title: 'Font',
    dataIndex: 'font',
    key: 'fonts=>font_name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Delivery Confirmation',
    dataIndex: 'delivery_confirmation',
    key: 'delivery_confirmation',
    sorter: true,
    render: renderDeliveryConfirmation,
    filters: deliveryConfirmationFilters,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Delay',
    dataIndex: 'delay',
    key: 'delay',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
]

const ManageItems = () => {
  const history = useHistory()
  const { id } = useParams()

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getCampaignStepRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const navigateToManageCampaigns = () => {
    history.push({
      pathname: `/multi-step`,
    })
  }

  const navigateToViewCampaign = () => {
    history.push({
      pathname: `/multi-step/view/${id}`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.manageCampaigns.key:
          return {
            ...menu,
            url: navigateToManageCampaigns,
          }
        case navMenu.viewCampaign.key:
          return {
            ...menu,
            url: navigateToViewCampaign,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        user: item.user_id ? (
          item.user ? (
            <Link to={`/clients/edit/${item.user.id}`}>{item.user.name}</Link>
          ) : (
            item.user_id
          )
        ) : (
          ''
        ),
        denomination: item.denomination ? (
          <Link to={`/denominations/edit/${item.denomination.id}`}>
            {item.denomination.gcard?.name} ${item.denomination?.nominal}
          </Link>
        ) : (
          '---'
        ),
        insert: item.insert ? (
          <Link to={`/inserts/edit/${item.insert?.id}`}>{item.insert?.name}</Link>
        ) : (
          '---'
        ),
        font: item.font ? (
          <Link to={`/fonts/edit/${item.font.id}`}>{item.font.font_name}</Link>
        ) : (
          '---'
        ),
        card: item.card ? <Link to={`/cards/edit/${item.card_id}`}> {item.card.name} </Link> : '',
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  return (
    <NavContainer menu={navMenuParse}>
      <TableFilter
        columns={initColumns}
        data={dataSource}
        getDataAction={campaignStepsActions.getAll}
        dataAction
        isEditOnId={false}
        actionsColum={['view']}
        itemBasePath="/campaign-steps"
      />
    </NavContainer>
  )
}

export default ManageItems
