import axios from 'axios'
import serialize from '_helpers/hw-serialize-params'
import apiUrl from './service'
import { v1 } from './api'

const getAll = async (params, typeAction, id) => {
  const result = await axios.get(
    `${apiUrl}/${v1}/multi_campaign/${id}/steps?${serialize(params)}`,
    {
      params: { stopSpinner: typeAction },
    },
  )
  return handleResponse(result)
}

const getItemById = async id => {
  const result = await axios.get(`${apiUrl}/${v1}/multi_campaign/steps/${id}`)
  return handleResponse(result)
}

const handleResponse = result => {
  return result.data
}

const campaignStepsService = {
  getAll,
  getItemById,
}

export default campaignStepsService
