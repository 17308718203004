import axios from 'axios'
import serialize from '_helpers/hw-serialize-params'
import apiUrl from './service'
import { v1 } from './api'

const getAll = async (params, typeAction, id) => {
  const result = await axios.get(`${apiUrl}/${v1}/client_mails/?${serialize(params)}`, {
    params: { stopSpinner: typeAction, id },
  })
  return handleResponse(result)
}

const deleteItem = async value => {
  const result = await axios.delete(`${apiUrl}/${v1}/client_mails/${value}`, {
    params: { stopSpinner: 'paginate' },
  })
  return handleResponse(result)
}

const resendMail = async mailId => {
  const result = await axios.put(`${apiUrl}/${v1}/client_mails/${mailId}/resend`)
  return handleResponse(result)
}

const handleResponse = result => {
  return result.data
}

const mailsService = {
  getAll,
  resendMail,
  deleteItem,
}

export default mailsService
