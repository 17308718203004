import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, Input, InputNumber, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import convertFormValuesDeep from '_helpers/hw-object-utils'
import NavContainer from '../../../../components/nav-container'
import { clientGroupActions, fontActions } from '../../../../_actions'
import { fontConstants } from '../../../../_constants'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import AdvancedCheckboxList from 'components/advanced-checkbox-list'
import { getClientGroupsRows } from '../../../../_selectors/client-group.selector.js'

const { formItemLayout, navMenu } = fontConstants

const navMenuInit = [navMenu.list, navMenu.manage]

const CreateItem = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const [previewImage, setPreviewImage] = useState(null)
  const [ttfFile, setTtfFile] = useState(null)
  const [centerlineTtfFile, setCenterlineTtfFile] = useState(null)

  const clientGroups = useSelector(getClientGroupsRows)

  const clientGroupsCheckbox = useMemo(() => {
    return clientGroups?.map(group => ({ value: group.id, label: group.title }))
  }, [clientGroups])

  useEffect(() => {
    dispatch(clientGroupActions.getAll())
  }, [])

  const normFile = e => {
    let file = e && e.fileList

    if (Array.isArray(e)) file = e
    const reader = new FileReader()
    reader.onload = () => setTtfFile(reader.result)

    if (e.fileList.length) reader.readAsDataURL(file[0].originFileObj)
    else setTtfFile(null)

    return file
  }

  const normCenterlineFile = e => {
    let file = e && e.fileList

    if (Array.isArray(e)) file = e
    const reader = new FileReader()
    reader.onload = () => setCenterlineTtfFile(reader.result)

    if (e.fileList.length) reader.readAsDataURL(file[0].originFileObj)
    else setCenterlineTtfFile(null)

    return file
  }

  const normFilePreviewImage = e => {
    let file = e && e.fileList

    if (Array.isArray(e)) file = e
    const reader = new FileReader()
    reader.onload = () => setPreviewImage(reader.result)

    if (e.fileList.length) reader.readAsDataURL(file[0].originFileObj)
    else setPreviewImage(null)

    return file
  }

  const onFinish = values => {
    const newObj = { font_eot_file: values.font_eot_file?.[0] || null }

    Object.keys(values).forEach(item => {
      if (item === 'font_file') newObj[item] = { ...values.font_file?.[0], buffer: ttfFile }
      else if (item === 'font_centerline_file')
        newObj[item] = { ...values.font_centerline_file?.[0], buffer: centerlineTtfFile }
      else if (item === 'image') newObj[item] = { ...values.image?.[0], thumbUrl: previewImage }
      else newObj[item] = values[item]
    })
    newObj.on_custom = 0
    dispatch(fontActions.addItem(convertFormValuesDeep(newObj)))
  }

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      {useGlobalLoader(
        <Form {...formItemLayout} name="basic" form={form} onFinish={onFinish} scrollToFirstError>
          <Form.Item
            label="Unique Font ID (example: astDunn)"
            name="id"
            rules={[
              {
                required: true,
                message: 'Please input font ID!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Font Name (example: astDunn)"
            name="font_name"
            rules={[
              {
                required: true,
                message: 'Please input font name!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Font Title (example: Casual David)"
            name="label"
            rules={[
              {
                required: true,
                message: 'Please input font title!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Line Spacing"
            initialValue={0.6}
            name="line_spacing"
            rules={[
              {
                required: true,
                message: 'Please input line spacing!',
              },
            ]}
          >
            <InputNumber step={0.1} />
          </Form.Item>

          <AdvancedCheckboxList
            formItemProps={{
              label: 'Customer Groups',
              name: 'user_groups',
              valuePropName: 'value',
            }}
            buttonText="Add Customer Group"
            options={clientGroupsCheckbox}
            form={form}
          />

          <Form.Item
            label="Font File (ttf)"
            name="font_file"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                required: true,
                message: 'Please input Font File!',
              },
            ]}
          >
            <Upload
              name="icon"
              accept=".ttf"
              maxCount={1}
              beforeUpload={file => {
                console.log(file)
                return false
              }}
              action=""
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            label="Centerline Font File (ttf)"
            name="font_centerline_file"
            valuePropName="centerlineFileList"
            getValueFromEvent={normCenterlineFile}
            rules={[
              {
                required: true,
                message: 'Please input Centerline Font File!',
              },
            ]}
          >
            <Upload
              name="icon"
              accept=".ttf"
              maxCount={1}
              beforeUpload={file => {
                console.log(file)
                return false
              }}
              action=""
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>

          {/* eot files used onlu for IE
            <Form.Item
              label="Font File (eot - optional)"
              name="font_eot_file"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                name="icon"
                accept=".eot"
                beforeUpload={file => {
                  console.log(file)
                  return false
                }}
                action=""
                listType="picture"
              >
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item> */}

          <Form.Item
            label="Preview Image"
            name="image"
            valuePropName="fileList"
            getValueFromEvent={normFilePreviewImage}
            rules={[
              {
                required: true,
                message: 'Please input Preview Image!',
              },
            ]}
          >
            <Upload
              name="icon"
              maxCount={1}
              beforeUpload={file => {
                console.log(file)
                return false
              }}
              action=""
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>

          <Form.Item label="Sort" name="sort" initialValue="0">
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>,
      )}
    </NavContainer>
  )
}

export default CreateItem
