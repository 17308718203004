const signatureConstants = {
  GETALL_REQUEST: 'SIGNATURE_GETALL_REQUEST',
  GETALL_SUCCESS: 'SIGNATURE_GETALL_SUCCESS',
  GETALL_FAILURE: 'SIGNATURE_GETALL_FAILURE',

  GETBYID_REQUEST: 'SIGNATURE_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'SIGNATURE_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'SIGNATURE_GETBYID_FAILURE',

  ADD_REQUEST: 'SIGNATURE_ADD_REQUEST',
  ADD_SUCCESS: 'SIGNATURE_ADD_SUCCESS',
  ADD_FAILURE: 'SIGNATURE_ADD_FAILURE',

  DELETE_REQUEST: 'SIGNATURE_DELETE_REQUEST',
  DELETE_SUCCESS: 'SIGNATURE_DELETE_SUCCESS',
  DELETE_FAILURE: 'SIGNATURE_DELETE_FAILURE',

  UPDATE_REQUEST: 'SIGNATURE_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'SIGNATURE_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'SIGNATURE_UPDATE_FAILURE',

  GET_SIGNATURE_FONT_REQUEST: 'GET_SIGNATURE_FONT_REQUEST',
  GET_SIGNATURE_FONT_SUCCESS: 'GET_SIGNATURE_FONT_SUCCESS',
  GET_SIGNATURE_FONT_FAILURE: 'GET_SIGNATURE_FONT_FAILURE',

  UPLOAD_FONT_REQUEST: 'SIGNATURE_UPLOAD_FONT_REQUEST',
  UPLOAD_FONT_SUCCESS: 'SIGNATURE_UPLOAD_FONT_SUCCESS',
  UPLOAD_FONT_FAILURE: 'SIGNATURE_UPLOAD_FONT_FAILURE',

  DOWNLOAD_CURRENT_FONT_REQUEST: 'SIGNATURE_DOWNLOAD_CURRENT_FONT_REQUEST',
  DOWNLOAD_CURRENT_FONT_SUCCESS: 'SIGNATURE_DOWNLOAD_CURRENT_FONT_SUCCESS',
  DOWNLOAD_CURRENT_FONT_FAILURE: 'SIGNATURE_DOWNLOAD_CURRENT_FONT_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    // list: { key: 'list', title: 'List Signature', url: '/signatures/list' },
    manage: { key: 'manage', title: 'Manage Signature', url: '/signatures' },
    create: { title: 'Create Signature', key: 'create', url: '/signatures/create' },
    upload: { title: 'Upload Font File', key: 'upload', url: '/signatures/upload' },
    edit: { key: 'edit', title: 'Update Signature', url: '' },
    delete: { key: 'delete', title: 'Delete Signature', url: '' },
    view: { key: 'view', title: 'View Signature', url: '' },
  },

  formItemLayout: {
    labelCol: {
      xs: { span: 8 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 8 },
      sm: { span: 10 },
    },
  },

  listItemKeyToLabel: {
    id: 'ID',
    user: 'User',
    name: 'Name',
    code: 'Code',
    preview: 'Preview URL',
  },
  viewItemKeyToLabel: {
    id: 'ID',
    user: 'User',
    name: 'Name',
    code: 'Code',
    size_offset: 'Size Offset (Percent)',
    preview: 'Preview URL',
    preview_image: 'Preview',
  },
  advancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'User ID', key: 'user_id' },
    { label: 'Name', key: 'name' },
    { label: 'Code', key: 'code' },
  ],
}
export default signatureConstants
