import { clientsConstants } from '../_constants'
import { clientsService } from '../_services'
import { history } from '../_helpers'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: clientsConstants.GETALL_REQUEST })
  try {
    const clients = await clientsService.getAll(params, typeAction)
    dispatch({ type: clientsConstants.GETALL_SUCCESS, payload: clients.data })
  } catch (error) {
    dispatch({ type: clientsConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: clientsConstants.GETBYID_REQUEST })

  try {
    const client = await clientsService.getItemById(id)
    dispatch({ type: clientsConstants.GETBYID_SUCCESS, payload: client.data })
  } catch (error) {
    dispatch({ type: clientsConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const deleteItem = id => async dispatch => {
  dispatch({ type: clientsConstants.DELETE_REQUEST })
  try {
    await clientsService.deleteItem(id)
    history.push(`/clients`)
    dispatch({ type: clientsConstants.DELETE_SUCCESS, payload: id })
  } catch (error) {
    dispatch({ type: clientsConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const updateItemById = (id, value) => async dispatch => {
  dispatch({ type: clientsConstants.UPDATE_REQUEST })

  try {
    const updated = await clientsService.updateItemById(id, value)
    dispatch({ type: clientsConstants.UPDATE_SUCCESS, payload: updated?.message?.toString() })
    history.push(`/clients/view/${id}`)
  } catch (error) {
    dispatch({ type: clientsConstants.UPDATE_FAILURE, payload: error.toString() })
  }
}

const getOrdersClient = params => async dispatch => {
  dispatch({ type: clientsConstants.ORDERS_REQUEST })

  try {
    const orders = await clientsService.getOrdersClient(params)
    dispatch({ type: clientsConstants.ORDERS_SUCCESS, payload: orders.data })
  } catch (error) {
    dispatch({ type: clientsConstants.ORDERS_FAILURE, payload: error.toString() })
  }
}

const getListClients = params => async dispatch => {
  dispatch({ type: clientsConstants.GETLISTUSERS_REQUEST })
  try {
    const clients = await clientsService.getListClients(params)
    dispatch({ type: clientsConstants.GETLISTUSERS_SUCCESS, payload: clients.data })
  } catch (error) {
    dispatch({ type: clientsConstants.GETLISTUSERS_FAILURE, payload: error.toString() })
  }
}

const genToken = clientId => async dispatch => {
  dispatch({ type: clientsConstants.GEN_TOKEN_REQUEST })
  try {
    const newToken = await clientsService.genToken(clientId)
    dispatch({ type: clientsConstants.GEN_TOKEN_SUCCESS, payload: newToken.data })
  } catch (error) {
    dispatch({ type: clientsConstants.GEN_TOKEN_FAILURE, payload: error.toString() })
  }
}

const clientsActions = {
  getAll,
  getItemById,
  deleteItem,
  updateItemById,
  getOrdersClient,
  getListClients,
  genToken,
}

export default clientsActions
