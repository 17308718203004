import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, DatePicker, Form, Input, InputNumber } from 'antd'
import NavContainer from '../../../../components/nav-container'
import { couponActions } from '../../../../_actions'
import { couponConstants } from '../../../../_constants'

const { formItemLayout, navMenu } = couponConstants

const navMenuInit = [navMenu.list, navMenu.manage]

const CreateItem = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const onFinish = async values => {
    const newItem = {
      ...values,
      expiration_date: `${values.expiration_date.format('YYYY-MM-DD')} 00:00:00`,
    }
    await dispatch(couponActions.addItem(newItem))
  }

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      <Form {...formItemLayout} name="basic" form={form} onFinish={onFinish} scrollToFirstError>
        <Form.Item
          label="Coupon name"
          name="code"
          rules={[
            {
              required: true,
              message: 'Please input name!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Coupon value"
          name="credit"
          rules={[
            {
              required: true,
              message: 'Please input value!',
            },
          ]}
        >
          <InputNumber step={1} />
        </Form.Item>

        <Form.Item
          label="Expiration Date"
          name="expiration_date"
          rules={[
            {
              required: true,
              message: 'Please select date!',
            },
          ]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </NavContainer>
  )
}

export default CreateItem
