import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Button, Form, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { signatureActions } from '_actions'
import NavContainer from '../../../../components/nav-container'
import { signatureConstants } from '../../../../_constants'

const { navMenu } = signatureConstants

const navMenuInit = [navMenu.create, navMenu.manage]

const UploadItem = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const [ttfFile, setTtfFile] = useState(null)
  const [ttfCenterlineFile, setTtfCenterlineFile] = useState(null)

  const normFile = e => {
    let file = e && e.fileList

    if (Array.isArray(e)) file = e
    const reader = new FileReader()
    reader.onload = () => setTtfFile(reader.result)
    reader.readAsDataURL(file[0].originFileObj)

    return file
  }

  const normCenterlineFile = e => {
    let file = e && e.fileList

    if (Array.isArray(e)) file = e
    const reader = new FileReader()
    reader.onload = () => setTtfCenterlineFile(reader.result)
    reader.readAsDataURL(file[0].originFileObj)

    return file
  }

  const fetchData = async () => {
    const results = document.getElementsByTagName('style')
    const stylesAsList = Array.from(results)
    const oldSignatureFont = stylesAsList.find(style => style.dataset.signaturefont)

    if (oldSignatureFont) {
      oldSignatureFont.remove()
    }

    const data = await dispatch(signatureActions.getSignatureFont())
    const styleSheet = document.createElement('style')
    styleSheet.setAttribute('data-signaturefont', 1)
    styleSheet.innerText = data
    document.head.appendChild(styleSheet)
  }

  const onFinish = async values => {
    const newFont = {
      file: {
        ...values?.signatures[0],
        buffer: ttfFile,
      },
      centerline_file: {
        ...values?.centerline_signatures[0],
        buffer: ttfCenterlineFile,
      },
    }
    await dispatch(signatureActions.uploadFontForSign(newFont))
    await fetchData()
  }

  const downloadCurrentFont = isCenterline => {
    dispatch(signatureActions.downloadCurrentFont(isCenterline))
  }

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      <Form name="basic" form={form} onFinish={onFinish} scrollToFirstError>
        <Form.Item
          label="Signatures Font File"
          name="signatures"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: 'Please upload file!',
            },
          ]}
        >
          <Upload
            name="icon"
            maxCount={1}
            accept=".ttf"
            beforeUpload={file => {
              console.log(file)
              return false
            }}
            action=""
          >
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          label="Signatures Centerline Font File"
          name="centerline_signatures"
          valuePropName="fileList"
          getValueFromEvent={normCenterlineFile}
          rules={[
            {
              required: true,
              message: 'Please upload file!',
            },
          ]}
        >
          <Upload
            name="icon"
            maxCount={1}
            accept=".ttf"
            beforeUpload={file => {
              console.log(file)
              return false
            }}
            action=""
          >
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Upload
          </Button>
        </Form.Item>

        <Form.Item>
          Download current font file:
          <Button style={{ padding: 0 }} type="link" onClick={() => downloadCurrentFont(false)}>
            link
          </Button>
        </Form.Item>

        <Form.Item>
          Download current Centerline font file:
          <Button style={{ padding: 0 }} type="link" onClick={() => downloadCurrentFont(true)}>
            link
          </Button>
        </Form.Item>
      </Form>
    </NavContainer>
  )
}

export default UploadItem
