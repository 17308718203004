const shippingConfigsConstants = {
  GETALL_REQUEST: 'SHIPPING_CONFIGS_GETALL_REQUEST',
  GETALL_SUCCESS: 'SHIPPING_CONFIGS_SUCCESS',
  GETALL_FAILURE: 'SHIPPING_CONFIGS_FAILURE',

  GET_GROUPED_REQUEST: 'SHIPPING_CONFIGS_GROUPED_REQUEST',
  GET_GROUPED_SUCCESS: 'SHIPPING_CONFIGS_GROUPED_SUCCESS',
  GET_GROUPED_FAILURE: 'SHIPPING_CONFIGS_GROUPED_FAILURE',

  GET_GROUP_BY_TYPE_REQUEST: 'SHIPPING_CONFIGS_GROUP_BY_TYPE_REQUEST',
  GET_GROUP_BY_TYPE_SUCCESS: 'SHIPPING_CONFIGS_GROUP_BY_TYPE_SUCCESS',
  GET_GROUP_BY_TYPE_FAILURE: 'SHIPPING_CONFIGS_GROUP_BY_TYPE_FAILURE',

  ADD_REQUEST: 'SHIPPING_CONFIGS_ADD_REQUEST',
  ADD_SUCCESS: 'SHIPPING_CONFIGS_ADD_SUCCESS',
  ADD_FAILURE: 'SHIPPING_CONFIGS_ADD_FAILURE',

  GETBYID_REQUEST: 'SHIPPING_CONFIGS_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'SHIPPING_CONFIGS_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'SHIPPING_CONFIGS_GETBYID_FAILURE',

  DELETE_REQUEST: 'SHIPPING_CONFIGS_DELETE_REQUEST',
  DELETE_SUCCESS: 'SHIPPING_CONFIGS_DELETE_SUCCESS',
  DELETE_FAILURE: 'SHIPPING_CONFIGS_DELETE_FAILURE',

  UPDATE_REQUEST: 'SHIPPING_CONFIGS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'SHIPPING_CONFIGS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'SHIPPING_CONFIGS_UPDATE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    list: { key: 'list', title: 'List Shipping Configs', url: '/shipping-configs/list' },
    manage: { key: 'manage', title: 'Manage Shipping Configs', url: '/shipping-configs' },
    create: { key: 'create', title: 'Create Shipping Configs', url: '/shipping-configs/create' },
    edit: { key: 'edit', title: 'Update Shipping Configs', url: '' },
    delete: { key: 'delete', title: 'Delete Shipping Configs', url: '' },
    view: { key: 'view', title: 'View Shipping Configs', url: '' },
  },
  shippingConfigsStatuses: [
    { value: 1, text: 'Active' },
    { value: 0, text: 'Not Active' },
  ],
  defaultListItemKeyToLabel: {
    id: 'ID',
    type: 'Type',
    status: 'Status',
  },
  defaultViewItemKeyToLabel: {
    id: 'ID',
    type: 'Type',
    status: 'Status',
  },
  shipperKeyToLabel: {
    shipper_name: 'Shipper Name',
    shipper_id: 'Shipper ID',
    shipper_address: 'Shipper Address',
    shipper_city: 'Shipper City',
    shipper_state: 'Shipper State',
    shipper_zip: 'Shipper Zip',
  },
  boxKeyToLabel: {
    card_size: 'Card Size',
    box_length: 'Box Length',
    box_width: 'Box Width',
    box_height: 'Box Height',
    quantity_from: 'Quantity From',
    quantity_to: 'Quantity To',
    empty_box_weight: 'Empty Box Weight',
  },
  cardKeyToLabel: {
    card_size: 'Card Size',
    card_weight: 'Card Weight',
    gift_weight: 'Gift Weight',
    insert_weight: 'Insert Weight',
  },
  configTypes: [
    { value: 'shipper', text: 'Shipper' },
    { value: 'box', text: 'Box' },
    { value: 'card', text: 'Card' },
  ],

  cardSizes: [
    { value: 'A2', text: 'A2' },
    { value: '5x7', text: '5x7' },
  ],

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },
}
export default shippingConfigsConstants
