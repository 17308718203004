import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import v4 from 'uuid/v4'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { mailsActions, queryParamsActions } from '../../../../_actions'
import { mailsConstants } from '../../../../_constants'
import { getMailRows, getPage, getTotalItems } from '../../../../_selectors/mails.selector.js'

const { navMenu, advancedSearch } = mailsConstants

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '10%',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Date Created',
    dataIndex: 'date_created',
    key: 'date_created*date',
    width: '10%',
  },
  {
    title: 'Subject',
    dataIndex: 'subject',
    key: 'subject',
    width: '10%',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message',
    width: '50%',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render(children) {
      return {
        props: {
          style: {
            maxWidth: '100px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        },
        children,
      }
    },
  },
  {
    title: 'Sent',
    dataIndex: 'sent',
    key: 'sent',
  },
  {
    title: 'Order',
    dataIndex: 'order_id',
    key: 'order_id',
    width: '10%',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
]

const navMenuInit = [navMenu.view, navMenu.edit, navMenu.list, navMenu.manage]

const ManageMails = ({ match }) => {
  const history = useHistory()
  const { id } = useParams()
  const dispatch = useDispatch()

  const rows = useSelector(getMailRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const [navMenuParse, setNavMenuParse] = useState([])
  const view = getCurrentUrl(match.url)

  useEffect(() => {
    return () => {
      dispatch({ type: mailsConstants.SETALL })
    }
  }, [])

  const deleteItem = async id => {
    await dispatch(mailsActions.deleteItem(id))
  }

  const onEditItem = () => {
    history.push({
      pathname: `${view}/edit/${id}`,
    })
  }

  const onViewItem = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  const onListItems = () => {
    dispatch(queryParamsActions.setQueryParams(null))
    history.push({
      pathname: '/clients/list',
    })
  }

  const onManageItems = () => {
    dispatch(queryParamsActions.setQueryParams(null))
    history.push({
      pathname: '/clients',
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.edit.key:
          return {
            ...menu,
            url: onEditItem,
          }
        case navMenu.view.key:
          return {
            ...menu,
            url: onViewItem,
          }
        case navMenu.list.key:
          return {
            ...menu,
            url: onListItems,
          }
        case navMenu.manage.key:
          return {
            ...menu,
            url: onManageItems,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  return (
    <NavContainer match={match} menu={navMenuParse}>
      <TableFilter
        deleteItems={deleteItem}
        columns={initColumns}
        data={dataSource}
        getDataAction={mailsActions.getAll}
        resendMail={mailsActions.resendItem}
        actionsColum={['resend', 'delete']}
        isEditOnId={false}
        advancedSearch={advancedSearch}
      />
    </NavContainer>
  )
}

export default ManageMails
