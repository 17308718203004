import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { userActions } from '_actions'
import NavContainer from '../../../../components/nav-container'
import ItemViewer from '../../../../components/item-viewer'
import { userConstants } from '../../../../_constants'

const { viewItemKeyToLabel, navMenu, userStatuses } = userConstants

const navMenuInit = [navMenu.list, navMenu.edit, navMenu.password, navMenu.logout]

const Profile = () => {
  const dispatch = useDispatch()

  const authUserId = useSelector(state => state.authentication.user.id)
  const user = useSelector(state => state.users.user)

  useEffect(() => {
    dispatch(userActions.getUserById(authUserId))
    return () => {
      dispatch({ type: userConstants.SETBYID })
    }
  }, [])

  const [navMenuParse, setNavMenuParse] = useState([])

  const logOut = () => {
    dispatch(userActions.logout())
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.logout.key:
          return {
            ...menu,
            url: logOut,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const viewItem = useMemo(() => {
    if (user) {
      return Object.keys(viewItemKeyToLabel).reduce((obj, sKey) => {
        let itemValue = user[sKey]
        if (sKey === 'status') {
          itemValue =
            userStatuses.find(status => status.value.toString() === user.status.toString())?.text ||
            'Unknown Status'
        }
        return {
          ...obj,
          [sKey]: itemValue,
        }
      }, {})
    }
    return {}
  }, [user])

  return (
    <NavContainer menu={navMenuParse}>
      <ItemViewer item={viewItem} keyToLabelMap={viewItemKeyToLabel} />
    </NavContainer>
  )
}

export default Profile
