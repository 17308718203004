import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, DatePicker, Form, Input, InputNumber, Select } from 'antd'

import NavContainer from '../../../../components/nav-container'
import { prePayCreditsActions } from '../../../../_actions'
import { prePayCreditsConstants } from '../../../../_constants'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import useClientsToOptionsList from 'hooks/clients/useClientsToOptionsList'

const { navMenu, formItemLayout } = prePayCreditsConstants

const navMenuInit = [navMenu.list, navMenu.manage]

const CreateItem = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const user_id = useSelector(state => state.queryParams?.where?.user_id)

  const clientsListOptions = useClientsToOptionsList()

  const onFinish = async values => {
    const newValue = {
      ...values,
      updated_at: '',
      expires_at: values.expires_at?.format('YYYY-MM-DD'),
    }
    await dispatch(prePayCreditsActions.addItem(convertFormValuesDeep(newValue)))
  }

  useEffect(() => {
    form.setFieldsValue({
      user_id: user_id || '',
    })
  }, [form, user_id])

  const range = (start, end) => {
    const result = []
    for (let i = start; i < end; i++) {
      result.push(i)
    }
    return result
  }

  const disabledDateTime = () => {
    return {
      disabledMinutes: () => range(1, 60),
      disabledSeconds: () => range(1, 60),
    }
  }

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      <Form
        {...formItemLayout}
        name="createPayPreCredit"
        form={form}
        onFinish={onFinish}
        scrollToFirstError
      >
        <Form.Item
          label="User"
          name="user_id"
          rules={[
            {
              required: true,
              message: 'Please select User!',
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Select or search a user"
            optionFilterProp="children"
            filterOption={(input, option) => option.label.includes(input.toString())}
            options={clientsListOptions}
          />
        </Form.Item>

        <Form.Item
          label="Amount"
          name="amount"
          rules={[
            {
              required: true,
              message: 'Please input amount!',
            },
          ]}
        >
          <InputNumber step={0.1} style={{ width: '146px' }} />
        </Form.Item>

        <Form.Item label="Note" name="note">
          <Input />
        </Form.Item>

        <Form.Item label="Expires At" name="expires_at">
          <DatePicker
            disabledTime={disabledDateTime}
            showTime={{
              hideDisabledOptions: true,
            }}
          />
        </Form.Item>

        <Form.Item name="create">
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </NavContainer>
  )
}

export default CreateItem
