import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import v4 from 'uuid/v4'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { cardImagesActions } from '../../../../_actions'
import { cardImagesConstants, settingsConstants } from '../../../../_constants'
import {
  getPage,
  getCardImageRows,
  getTotalItems,
} from '../../../../_selectors/card-images.selector.js'

const { navMenu, renderComponent, advancedSearch, cardImageType } = cardImagesConstants

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Image',
    dataIndex: 'image',
    key: 'image',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type_filter',
    sorter: true,
    filters: cardImageType,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Sort no',
    dataIndex: 'sort_no',
    key: 'sort_no',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
]

const navMenuInit = [navMenu.list]

const ManageItems = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)
  const { id } = useParams()

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getCardImageRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const deleteItems = async id => {
    await dispatch(cardImagesActions.deleteItem(id))
  }

  const navigateToList = () => {
    dispatch({ type: cardImagesConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}/list`,
    })
  }

  const navigateToCreate = () => {
    dispatch({ type: cardImagesConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}/create${id ? `/${id}` : ''}`,

    })
  }

  useEffect(() => {
    if (id && navMenuInit.length < 2) navMenuInit.push(navMenu.create)
  }, [id, navMenuInit.length])

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.list.key:
          return {
            ...menu,
            url: navigateToList,
          }
        case navMenu.create.key:
          return {
            ...menu,
            url: navigateToCreate,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        image: renderComponent.image(item.image, item.image),
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  const cardImageTypeSelect = useMemo(() => {
    return cardImageType.map(status => ({ value: status.value, label: status.text }))
  }, [cardImageType])

  const updAdvancedSearch = advancedSearch.map(field => {
    if (field.key === 'type_filter') return { ...field, options: cardImageTypeSelect }
    return field
  })

  return (
    <NavContainer menu={navMenuParse}>
      <TableFilter
        deleteItems={deleteItems}
        columns={initColumns}
        data={dataSource}
        getDataAction={cardImagesActions.getAll}
        advancedSearch={updAdvancedSearch}
      />
    </NavContainer>
  )
}

export default ManageItems
