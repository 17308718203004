import { automationService } from '../_services'
import { automationsConstants } from '_constants'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: automationsConstants.GETALL_REQUEST })
  try {
    const automations = await automationService.getAll(params, typeAction)
    dispatch({ type: automationsConstants.GETALL_SUCCESS, payload: automations.data })
  } catch (error) {
    dispatch({ type: automationsConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: automationsConstants.GETBYID_REQUEST })

  try {
    const automation = await automationService.getItemById(id)
    dispatch({ type: automationsConstants.GETBYID_SUCCESS, payload: automation.data })
  } catch (error) {
    dispatch({ type: automationsConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const getAutomationOrders = (params, typeAction) => async dispatch => {
  dispatch({ type: automationsConstants.GET_ORDER_REQUEST })
  try {
    const orders = await automationService.getAutomationOrders(params, typeAction)
    dispatch({ type: automationsConstants.GET_ORDER_SUCCESS, payload: orders.data })
  } catch (error) {
    dispatch({ type: automationsConstants.GET_ORDER_FAILURE, payload: error.toString() })
  }
}

const automationActions = {
  getAll,
  getItemById,
  getAutomationOrders,
}

export default automationActions
