/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Badge, Button, Checkbox, Collapse, Divider, Modal, Select, Space, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { isEmpty } from 'lodash'
import { batchOrdersActions, employeesActions } from '../../../../_actions'
import { batchOrdersConstants } from '../../../../_constants'
import getCount from '../../../../_selectors/batch-orders.selector'
import ScrollButton from '../../../../components/scroll-button'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader'
import { GenerateWorkListModal, WorkListModal } from 'components/work-list-modal'
import ImposedCardModal from 'components/imposed-card-modal'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import CardsList from './cards-list'
import Avatar from 'antd/es/avatar/avatar.js'
import GroupedOrdersCollapse from 'components/batch-orders/grouped-orders.collapse'

import './styles.scss'

const NoOrders = () => {
  return useGlobalLoader(
    <>
      <h3>No Orders</h3>
    </>,
  )
}

const OrdersList = ({ refresh, setRefresh }) => {
  const continueButtonRef = useRef(null)
  const listContainerRef = useRef(null)

  const dispatch = useDispatch()
  const history = useHistory()

  const { orderDates, paidOrders, printedOrderCount, orientation } = useSelector(
    state => state.batchOrders,
  )
  const selectedRows = useSelector(state => state.batchOrders.selectedRows)
  const countOfSelectedRows = useSelector(getCount)
  const employees = useSelector(state => state.employees.items)
  const { user } = useSelector(state => state.authentication)
  const ordersInWorkCount = useSelector(state => state.batchOrders.ordersInWorkCount)
  const workList = useSelector(state => state.batchOrders.workList)

  const [selectedEmployee, setSelectedEmployee] = useState(null)
  const [activePanelList, setActivePanelList] = useState([])
  const [continueDisabled, setContinueDisabled] = useState(isEmpty(selectedRows))
  const [activeModals, setActiveModals] = useState([])
  const [imposedOrdersIds, setImposedOrdersIds] = useState(null)
  const [worklistId, setWorklistId] = useState(null)
  const [groupByGCards, setGroupByGCards] = useState(true)
  const [groupByInserts, setGroupByInserts] = useState(true)
  const [firstRender, setFirstRender] = useState(true)
  const [activeDatePanels, setActiveDatePanels] = useState([])
  const [activePrintedOrdersPanel, setActivePrintedOrdersPanel] = useState([])
  const [isImpositionWarning, setIsImpositionWarning] = useState(false)

  const isPrinted = useMemo(() => typeof printedOrderCount === 'number', [printedOrderCount])

  const plainEmployees = useMemo(() => {
    if (!employees?.rows) {
      return [
        {
          value: user.id,
          label: user.username,
        },
      ]
    }
    return employees.rows.map(employee => ({
      value: employee.id,
      label: employee.username,
    }))
  }, [employees])

  const idsOfSelectedRows = useMemo(() => {
    return Object.values(selectedRows)
      ?.map(key => key.ids)
      .flat()
  }, [selectedRows])

  useEffect(() => {
    setSelectedEmployee(user.id)
  }, [user])

  useEffect(() => {
    if (ordersInWorkCount > 0) {
      if (selectedEmployee === user.id) {
        history.push(`/my-orders`)
      } else if (selectedEmployee !== null) {
        dispatch(batchOrdersActions.getGroups(groupByGCards, groupByInserts))
      }
    }
  }, [ordersInWorkCount])

  useEffect(() => {
    if (!refresh) {
      dispatch(batchOrdersActions.getGroups(groupByGCards, groupByInserts))
      dispatch(employeesActions.getAll({ where: { status: 1 } }))
    }
    setRefresh(false)
    return () => {
      dispatch(batchOrdersActions.clearView(batchOrdersConstants.CLEAR_BATCH_ORDERS_VIEW))
    }
  }, [])

  useEffect(() => {
    if (!firstRender) {
      dispatch(batchOrdersActions.clearView(batchOrdersConstants.CLEAR_BATCH_ORDERS_VIEW))
      onChangeActiveDatePanels([])
      setActivePanelList([])
      onChangePrintedOrderPanel([])
      dispatch(batchOrdersActions.getGroups(groupByGCards, groupByInserts))
    } else {
      setFirstRender(false)
    }
  }, [groupByGCards, groupByInserts])

  useEffect(() => {
    setContinueDisabled(isEmpty(selectedRows))
  }, [selectedRows])

  const selectHandler = useCallback(
    (e, card_id, count) => {
      dispatch(batchOrdersActions.setRowsWithButton({ [card_id]: { card_id, count } }))
      // setSelectedRowsWithButton({ card_id, count })
      // Check if panel is opened
      if (activePanelList.includes(card_id.toString())) {
        e.stopPropagation()
      }
    },
    [activePanelList],
  )

  const onContinueClick = () => {
    const copied = JSON.parse(JSON.stringify(selectedRows))
    const copiedWithRedo = {}

    for (const key in copied) {
      const keyId = key.toString().replace(/\D/g, '')
      if (copiedWithRedo.hasOwnProperty(keyId)) {
        copiedWithRedo[keyId].rows = [...copied[keyId].rows, ...copied[key].rows]
        copiedWithRedo[keyId].count = Number(copied[keyId].count) + Number(copied[key].count)
      } else {
        copiedWithRedo[keyId] = copied[key]
      }
    }

    // eslint-disable-next-line no-unused-vars
    for (const [_, value] of Object.entries(copiedWithRedo)) {
      value.rows.forEach(val => {
        val.children = val.childrenNodes
        delete val.childrenNodes
      })
    }

    dispatch(
      batchOrdersActions.processSelectedRows({
        employeeId: selectedEmployee,
        rows: copiedWithRedo,
      }),
    )
  }

  const employeeChangeHandler = value => {
    setSelectedEmployee(value)
  }

  const openGenerateListModal = () => setActiveModals(['generate-work-list'])

  const openWorkListModal = () => setActiveModals(['work-list'])

  const closeModal = modalName => {
    if (modalName) {
      const filteredModals = activeModals.filter(mod => mod !== modalName)
      return setActiveModals(filteredModals)
    }
    setActiveModals([])
  }

  const getImposedOrders = (ordersIds, worklist_id, imposedType) => {
    setImposedOrdersIds(ordersIds)
    setWorklistId(+worklist_id)
    setActiveModals(prev => [...prev, imposedType])
  }

  const getPrintableList = async (printable, name) => {
    await dispatch(batchOrdersActions.generatePrintableList({ printable, name }))
  }

  const isOnPrintImposedPython = useMemo(
    () => activeModals.find(modal => modal.includes('on-print')),
    [activeModals],
  )

  const isAllCardsFlat = useMemo(() => {
    if (worklistId) {
      const currentList = workList.find(list => +list.name.split(' ').slice(-1)[0] === +worklistId)
      const cardsToPrint = currentList?.printable
      return cardsToPrint?.every(card => card.orientation === 'F')
    }
    return orientation === 'F' && !isImpositionWarning && isOnPrintImposedPython
  }, [worklistId, workList, orientation, isImpositionWarning, isOnPrintImposedPython])

  const cancelImposedCards = () => {
    const modalToClose = activeModals.find(modal => modal.includes('imposed'))
    closeModal(modalToClose)
    setImposedOrdersIds(null)
    setWorklistId(null)
  }

  const generateImposedCards = async (sizes, formValues) => {
    const isTemplateMode = activeModals.some(modal => modal.includes('template'))
    const version = isTemplateMode ? 6 : 5
    const payload = {
      pageSize: sizes,
      withBarcode: true,
      worklist: worklistId,
      ids: imposedOrdersIds,
      version,
      onlyCustom: formValues.only_custom,
      imposition_id: formValues?.imposition_id || null,
    }

    await dispatch(batchOrdersActions.generateImposedCards(payload))
  }

  const changeGroupingHandler = setGrouping => {
    if (!isEmpty(selectedRows)) {
      Modal.confirm({
        title: 'Are you sure you want to change the grouping?',
        icon: <ExclamationCircleOutlined />,
        content: 'All your selected orders will be deselected.',
        okText: 'Continue',
        cancelText: 'Cancel',
        onOk: () => setGrouping(prev => !prev),
      })
    } else {
      setGrouping(prev => !prev)
    }
  }

  const confirmPrintImposedCardsHandler = (sizes, formValues) => {
    Modal.confirm({
      title: 'Are you sure you want to print imposed cards?',
      icon: <ExclamationCircleOutlined />,
      content: 'All your selected orders will be deselected.',
      okText: 'Continue',
      cancelText: 'Cancel',
      onOk: () => onPrintImposedCards(sizes, formValues),
    })
  }

  const getSelectedOrdersByKeyCount = key => {
    let count = 0

    for (const selectedKey in selectedRows) {
      if (selectedKey.includes(key)) {
        count += selectedRows[selectedKey].count
      }
    }

    return count
  }

  const getSelectedPrintedOrders = () => {
    let count = 0

    Object.values(selectedRows).forEach(row => {
      if (row.isPrintedOrder) count += row.count
    })

    return count
  }

  useEffect(() => {
    if (!Object.keys(selectedRows).length) {
      dispatch(batchOrdersActions.resetOrientation())
      return
    }

    const isOrdersWithCurrentOrientation = Object.values(selectedRows).find(
      row => row.orientation === orientation,
    )

    if (Object.keys(selectedRows).length === 1 || !isOrdersWithCurrentOrientation) {
      const orientation = Object.values(selectedRows)[0]?.orientation
      dispatch(batchOrdersActions.setOrientation(orientation))
    }
  }, [selectedRows])

  const orderDatesList = useMemo(() => {
    if (!orderDates) return
    return orderDates.map(({ dateSend, cnt }) => {
      const selectedOrdersCount = getSelectedOrdersByKeyCount(dateSend)
      return {
        key: dateSend,
        label: (
          <div className="d-flex flex-row justify-content-between align-items-center">
            <Typography.Text strong style={{ fontSize: '16px', color: '#4b7cf3' }}>
              {dateSend
                .split('-')
                .slice(1)
                .join('/')}
            </Typography.Text>
            <Space>
              <Badge count={selectedOrdersCount} overflowCount={selectedOrdersCount + 1}>
                <Avatar style={{ backgroundColor: '#4b7cf3', color: '#fff' }}>{cnt}</Avatar>
              </Badge>
            </Space>
          </div>
        ),
        children: (
          <CardsList
            dateSend={dateSend}
            groupByGCards={groupByGCards}
            groupByInserts={groupByInserts}
            selectHandler={selectHandler}
            setActivePanelList={setActivePanelList}
          />
        ),
      }
    })
  }, [orderDates, groupByGCards, groupByInserts, selectedRows, selectHandler, setActivePanelList])

  const printedItems = useMemo(() => {
    if (!isPrinted) return

    const selectedOrdersCount = getSelectedPrintedOrders()

    return [
      {
        key: printedOrderCount.toString(),
        label: (
          <div className="d-flex flex-row justify-content-between align-items-center">
            <Typography.Text strong style={{ fontSize: '16px', color: '#4b7cf3' }}>
              printed orders
            </Typography.Text>
            <Space>
              <Badge count={selectedOrdersCount} overflowCount={selectedOrdersCount + 1}>
                <Avatar style={{ backgroundColor: '#4b7cf3', color: '#fff' }}>
                  {printedOrderCount}
                </Avatar>
              </Badge>
            </Space>
          </div>
        ),
        children: (
          <CardsList
            groupByGCards={groupByGCards}
            groupByInserts={groupByInserts}
            selectHandler={selectHandler}
            setActivePanelList={setActivePanelList}
            isPrintedList={!!printedOrderCount}
          />
        ),
      },
    ]
  }, [
    printedOrderCount,
    groupByGCards,
    groupByInserts,
    selectedRows,
    selectHandler,
    setActivePanelList,
  ])

  const onPrintImposedCards = async (sizes, formValues) => {
    const isTemplateMode = activeModals.some(modal => modal.includes('template'))
    const version = isTemplateMode ? 6 : 5

    const payload = {
      ids: idsOfSelectedRows,
      pageSize: sizes,
      employee_name: user.username,
      withBarcode: true,
      onlyCustom: formValues.only_custom,
      version,
      file_name_suffix: new Date().getTime(),
      imposition_id: formValues?.imposition_id || null,
    }

    const res = await dispatch(batchOrdersActions.generateImposedCards(payload))
    if (res?.status === 200 && isPrinted) {
      dispatch(batchOrdersActions.clearView(batchOrdersConstants.CLEAR_BATCH_ORDERS_VIEW))
      onChangeActiveDatePanels([])
      setActivePanelList([])
      onChangePrintedOrderPanel([])
      await dispatch(batchOrdersActions.getGroups(groupByGCards, groupByInserts))
    }
  }

  const onPanelChange = keys => {
    const lastKey = keys[keys.length - 1]
    setActivePanelList(keys)
    dispatch(batchOrdersActions.setActivePanel({ [lastKey]: lastKey }))
  }

  const onChangeActiveDatePanels = keys => {
    setActiveDatePanels(keys)
  }

  const onChangePrintedOrderPanel = keys => {
    if (!printedOrderCount) return
    setActivePrintedOrdersPanel(keys)
  }

  const onOkImposedCardModal = (sizes, formValues) => {
    if (isOnPrintImposedPython)
      return isPrinted
        ? confirmPrintImposedCardsHandler(sizes, formValues)
        : onPrintImposedCards(sizes, formValues)

    return generateImposedCards(sizes, formValues)
  }

  const onPrintImposed = () => {
    setActiveModals(prev => [...prev, 'on-print-imposed-modal-python'])
  }

  const onPrintImposedTemplate = () => {
    setActiveModals(prev => [...prev, 'on-print-imposed-modal-python-template'])
  }

  useEffect(() => {
    // check cards orientations conflicts
    if (worklistId) {
      const currentList = workList.find(list => +list.name.split(' ').slice(-1)[0] === +worklistId)
      const cardsToPrint = currentList?.printable

      const includesFlatCard = cardsToPrint.find(card => card.orientation === 'F')
      const includesA2Card = cardsToPrint.find(card => card.orientation !== 'F')

      const orientationsConflict = includesFlatCard && includesA2Card

      setIsImpositionWarning(orientationsConflict)
    } else {
      const orders = Object.values(selectedRows)?.flat() || []

      const orientationsConflict = !!orders.find(order =>
        orientation === 'F' ? order.orientation !== orientation : order.orientation === 'F',
      )
      setIsImpositionWarning(orientationsConflict)
    }
  }, [worklistId, workList, selectedRows, orientation])

  return (
    <>
      {paidOrders ? (
        <>
          <div style={{ marginBottom: '1rem', height: '3rem' }}>
            <div className="buttons__container">
              <div className="d-flex flex-row buttons__row buttons__row">
                <Space>
                  <Checkbox
                    checked={groupByGCards}
                    onChange={() => changeGroupingHandler(setGroupByGCards)}
                    style={{ height: 'fit-content', alignItems: 'center' }}
                  >
                    Group by Gift Cards
                  </Checkbox>

                  <Checkbox
                    checked={groupByInserts}
                    onChange={() => changeGroupingHandler(setGroupByInserts)}
                    style={{ height: 'fit-content', alignItems: 'center' }}
                  >
                    Group by Inserts
                  </Checkbox>
                </Space>

                <div>
                  {!!countOfSelectedRows && (
                    <Space>
                      <Button
                        onClick={onPrintImposedTemplate}
                        type="primary"
                        style={{ marginRight: '1rem' }}
                      >
                        Print Imposed Cards (template){' '}
                        {countOfSelectedRows ? `(${countOfSelectedRows})` : ''}
                      </Button>
                      <Button
                        onClick={onPrintImposed}
                        type="primary"
                        style={{ marginRight: '1rem' }}
                      >
                        Print Imposed Cards {countOfSelectedRows ? `(${countOfSelectedRows})` : ''}
                      </Button>
                    </Space>
                  )}
                  <Button onClick={openGenerateListModal} type="primary">
                    Generate Work Lists
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <GenerateWorkListModal
            isVisible={activeModals.includes('generate-work-list')}
            onOk={openWorkListModal}
            onCancel={() => closeModal('generate-work-list')}
            groupByGCards={groupByGCards}
            groupByInserts={groupByInserts}
          />
          <WorkListModal
            isVisible={activeModals.includes('work-list')}
            onCancel={() => closeModal('work-list')}
            getImposedOrders={getImposedOrders}
            getPrintableList={getPrintableList}
          />
          <ImposedCardModal
            isVisible={activeModals.some(modal => modal.includes('imposed-modal'))}
            onOk={onOkImposedCardModal}
            onCancel={cancelImposedCards}
            warningMessage={
              isImpositionWarning
                ? `You have selected cards with a different orientation. The imposition PDF size adjusted to 13x19`
                : ''
            }
            isAllCardsFlat={isAllCardsFlat}
            templateMode={activeModals.some(modal => modal.includes('template'))}
          />
          <div className="d-flex flex-column h-200" ref={listContainerRef}>
            <GroupedOrdersCollapse
              activePanelList={activePanelList}
              groups={paidOrders}
              onChange={onPanelChange}
              selectHandler={selectHandler}
            />

            {!!orderDates && (
              <>
                <Divider />

                <Collapse
                  activeKey={activeDatePanels}
                  onChange={onChangeActiveDatePanels}
                  items={orderDatesList}
                  size="large"
                />
              </>
            )}

            {isPrinted && (
              <>
                <Divider />

                <Collapse
                  activeKey={activePrintedOrdersPanel}
                  onChange={onChangePrintedOrderPanel}
                  items={printedItems}
                  size="large"
                />
              </>
            )}
            <div className="p-1 my-2">
              Employee{' '}
              <Select
                className="width-20p"
                style={{ minWidth: '120px' }}
                options={plainEmployees || []}
                value={selectedEmployee}
                onChange={employeeChangeHandler}
              />{' '}
              {selectedEmployee !== user.id && (
                <Button type="primary" onClick={() => setSelectedEmployee(user.id)}>
                  Assign to me
                </Button>
              )}
            </div>
            <Button
              type="primary"
              style={{ width: 'max-content' }}
              onClick={onContinueClick}
              disabled={continueDisabled}
              ref={continueButtonRef}
            >
              Continue {countOfSelectedRows ? `(${countOfSelectedRows})` : ''}
            </Button>
            <ScrollButton
              countOfSelectedRows={countOfSelectedRows}
              scrollElementRef={listContainerRef}
            />
          </div>
        </>
      ) : (
        <NoOrders />
      )}
    </>
  )
}

export default OrdersList
