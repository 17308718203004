import React from 'react'

const presetCardImagesConstants = {
  GETALL_REQUEST: 'PRESETCARDIMAGES_GETALL_REQUEST',
  GETALL_SUCCESS: 'PRESETCARDIMAGES_GETALL_SUCCESS',
  GETALL_FAILURE: 'PRESETCARDIMAGES_GETALL_FAILURE',

  GETBYID_REQUEST: 'PRESETCARDIMAGES_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'PRESETCARDIMAGES_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'PRESETCARDIMAGES_GETBYID_FAILURE',

  ADD_REQUEST: 'PRESETCARDIMAGES_ADD_REQUEST',
  ADD_SUCCESS: 'PRESETCARDIMAGES_ADD_SUCCESS',
  ADD_FAILURE: 'PRESETCARDIMAGES_ADD_FAILURE',

  DELETE_REQUEST: 'PRESETCARDIMAGES_DELETE_REQUEST',
  DELETE_SUCCESS: 'PRESETCARDIMAGES_DELETE_SUCCESS',
  DELETE_FAILURE: 'PRESETCARDIMAGES_DELETE_FAILURE',

  UPDATE_REQUEST: 'PRESETCARDIMAGES_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'PRESETCARDIMAGES_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'PRESETCARDIMAGES_UPDATE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  cardImageType: [
    { value: 'front', text: 'FRONT' },
    { value: 'mixed', text: 'MIXED' },
    { value: 'back', text: 'BACK' },
  ],

  navMenu: {
    list: { key: 'list', title: 'List Preset Card Images', url: '/preset-card-images/list' },
    manage: { key: 'manage', title: 'Manage Preset Card Images', url: '/preset-card-images' },
    create: { title: 'Create Preset Card Image', key: 'create', url: '/preset-card-images/create' },
    view: { key: 'view', title: 'View Preset Card Image', url: '' },
    delete: { key: 'delete', title: 'Delete Preset Card Image', url: '' },
    edit: { key: 'edit', title: 'Update Preset Card Image', url: '' },
  },

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },
  listItemKeyToLabel: {
    id: 'ID',
    url: 'Image',
    type: 'Type',
    sort_no: 'Sort No',
    status: 'Status',
  },
  viewItemKeyToLabel: {
    id: 'ID',
    url: 'Image',
    type: 'Type',
    sort_no: 'Sort No',
    status: 'Status',
    dimension: 'Dimension',
  },

  advancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'Image', key: 'url' },
    { label: 'Type', key: 'type_filter', type: 'select' },
    { label: 'Sort No', key: 'sort_no' },
    { label: 'Status', key: 'status', type: 'select' },
  ],

  presetCardImageStatuses: [
    { value: 0, text: 'Not Active' },
    { value: 1, text: 'Active' },
  ],

  renderComponent: {
    // eslint-disable-next-line react/display-name
    image: (url, title = '') => (url ? <img width="160" src={url} alt={title} /> : '----'),
  },

  sortNoTooltipMessage: 'Controls the order of images. Higher numbers move the image closer',
}
export default presetCardImagesConstants
