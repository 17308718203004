import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { clientsActions } from '_actions'

const useClientsToOptionsList = (getDataAction, args) => {
  const dispatch = useDispatch()

  const clients = useSelector(state => state.clients.items.rows)

  const fetchAllData = async () => {
    await Promise.all([
      dispatch(
        clientsActions.getListClients({ attrs: { fields: ['id', 'login', 'fname', 'lname'] } }),
      ),
      getDataAction ? await dispatch(getDataAction(args || {})) : () => {},
    ])
  }

  useEffect(() => {
    fetchAllData()
  }, [])

  const clientsListOptions = useMemo(() => {
    if (clients) {
      return clients.map(({ id, login = '', fname = '', lname = '' }) => {
        return {
          key: id,
          value: id,
          label: `[ID:${id}] ${login || `${fname} ${lname}`}`,
        }
      })
    }
    return []
  }, [clients])

  return clientsListOptions
}

export default useClientsToOptionsList
