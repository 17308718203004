import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { CronJobsList, CronJobsDetails } from './views'
import Content from '../../components/content'

const CronJobs = () => {
  const match = useRouteMatch()

  return (
    <Content>
      <Switch>
        <Route exact path={match.url} component={CronJobsList} />
        <Route path={`${match.url}/:job_name`} component={CronJobsDetails} />

        <Redirect to={match.url} />
      </Switch>
    </Content>
  )
}

export default CronJobs
