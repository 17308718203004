import React, { useState, useMemo, useRef, useEffect } from 'react'
import Draggable from 'react-draggable'
import { useDispatch } from 'react-redux'
import lodash from 'lodash'

import { impositionLayoutsConstants } from '_constants'
import { inchToPx, pxToMm } from '_helpers/convert'
import { initialNeighborDistances } from './imposition-layout.draggable-item'

const DraggablePageBarcode = ({
  data,
  configId,
  scale,
  paperSize,
  isAdditionalBarcode,
  isRepeatMode,
  isRepeatedBarcode,
  sectionBounds,
  checkNeighborRelations,
}) => {
  const dispatch = useDispatch()

  const [isDragging, setIsDragging] = useState(false)
  const [position, setPosition] = useState({
    x: data.x,
    y: data.y,
  })
  const [neighborDistances, setNeighborDistances] = useState(initialNeighborDistances)

  const leftDistanceRef = useRef(null)
  const topDistanceRef = useRef(null)
  const rightDistanceRef = useRef(null)
  const bottomDistanceRef = useRef(null)

  useEffect(() => {
    setPosition({
      x: data.x,
      y: data.y,
    })
  }, [data.x, data.y])

  const barcodeSizeWithRotate = useMemo(() => {
    const size = {
      height: inchToPx(data.height),
      width: inchToPx(data.width),
    }

    // if barcode is rotated
    if (data.rotate) {
      size.width = inchToPx(data.height)
      size.height = inchToPx(data.width)
    }

    return size
  }, [data])

  const leftInnerDistanceStyle = useMemo(() => {
    if (leftDistanceRef.current) {
      const labelRect = leftDistanceRef.current.getBoundingClientRect()
      const leftDistance = neighborDistances.left
      if (labelRect.width + 4 > leftDistance) {
        return {
          transform: 'translate(10%, -50%)',
          color: 'white',
          zIndex: 5,
        }
      }
    }
    return {}
  }, [neighborDistances.left])

  const topInnerDistanceStyle = useMemo(() => {
    if (topDistanceRef.current) {
      const labelRect = topDistanceRef.current.getBoundingClientRect()
      const topDistance = neighborDistances.top
      if (labelRect.height + 4 > topDistance) {
        return {
          transform: 'translate(-50%, 10%)',
          color: 'white',
          zIndex: 5,
        }
      }
    }
    return {}
  }, [neighborDistances.top])

  const rightInnerDistanceStyle = useMemo(() => {
    if (rightDistanceRef.current) {
      const labelRect = rightDistanceRef.current.getBoundingClientRect()
      const rightDistance = neighborDistances.right
      if (labelRect.width + 4 > rightDistance) {
        return {
          transform: 'translate(-10%, -50%)',
          color: 'white',
          zIndex: 5,
        }
      }
    }
    return {}
  }, [neighborDistances.right])

  const bottomInnerDistanceStyle = useMemo(() => {
    if (bottomDistanceRef.current) {
      const labelRect = bottomDistanceRef.current.getBoundingClientRect()
      const bottomDistance = neighborDistances.bottom
      if (labelRect.height + 4 > bottomDistance) {
        return {
          transform: 'translate(-50%, -10%)',
          color: 'white',
          zIndex: 5,
        }
      }
    }
    return {}
  }, [neighborDistances.bottom])

  const draggableBarcodeBounds = useMemo(() => {
    const bounds = {
      left: 0,
      top: 0,
      right: (sectionBounds?.[1] || paperSize.width) - barcodeSizeWithRotate.width,
      bottom: paperSize.height - barcodeSizeWithRotate.height,
    }

    return bounds
  }, [paperSize, data.width, data.height, barcodeSizeWithRotate, sectionBounds])

  const rotateStyle = useMemo(() => {
    let style = {}
    if (data.rotate === 270) {
      style = {
        transformOrigin: 'left top',
        transform: `rotate(${data.rotate}deg) translateX(-${data.width}in)`,
      }
    } else if (data.rotate === 90) {
      style = {
        transform: `rotate(${data.rotate}deg) translateX(-${data.height}in)`,
        transformOrigin: 'left bottom',
      }
    }

    return style
  }, [data.rotate])

  const maxAllowedPosition = useMemo(() => {
    const maxCoordinates = {
      left: 0,
      top: 0,
      right: paperSize.width,
      bottom: paperSize.height,
    }

    // if it is a repeat mode set section bounds
    if (isRepeatMode && sectionBounds) {
      const [left, right] = sectionBounds
      maxCoordinates.left = left
      maxCoordinates.right = right
    }

    return maxCoordinates
  }, [isRepeatMode, sectionBounds, paperSize])

  const checkPositionLimitations = () => {
    const updatedPosition = {}

    if (position.x < maxAllowedPosition.left) {
      updatedPosition.x = maxAllowedPosition.left
    } else if (position.y < maxAllowedPosition.top) {
      updatedPosition.y = maxAllowedPosition.top
    } else if (position.x + barcodeSizeWithRotate.width > maxAllowedPosition.right) {
      updatedPosition.x = maxAllowedPosition.right - barcodeSizeWithRotate.width
    } else if (position.y + barcodeSizeWithRotate.height > maxAllowedPosition.bottom) {
      updatedPosition.y = maxAllowedPosition.bottom - barcodeSizeWithRotate.height
    }

    // if some position needs to be updated - save new coordinates
    if (!lodash.isEmpty(updatedPosition)) {
      setPosition(prev => ({
        ...prev,
        ...updatedPosition,
      }))

      saveBarcodePosition(updatedPosition)
    }
  }

  useEffect(() => {
    if (!isAdditionalBarcode) {
      checkPositionLimitations()
    }
  }, [isAdditionalBarcode, maxAllowedPosition])

  const convertDistanceToMm = num => `${pxToMm(num).toFixed(1)}mm`

  const saveBarcodePosition = newPosition => {
    dispatch({
      type: impositionLayoutsConstants.UPDATE_PAGE_BARCODE_POSITION,
      payload: {
        configId,
        ...position,
        ...newPosition,
      },
    })
  }

  const onDrag = (e, position) => {
    const { x, y } = position
    setIsDragging(true)
    setPosition({ x, y })

    const { distances } = checkNeighborRelations(null, x, y)

    setNeighborDistances(distances)
  }

  const onDrop = () => {
    setIsDragging(false)
    saveBarcodePosition()
  }

  return (
    <Draggable
      bounds={draggableBarcodeBounds}
      position={position}
      scale={+scale}
      onDrag={onDrag}
      onStop={onDrop}
      disabled={isAdditionalBarcode}
    >
      <div
        className="draggable-container"
        style={{
          zIndex: 4,
          height: barcodeSizeWithRotate.height,
          width: barcodeSizeWithRotate.width,
        }}
      >
        {isDragging ? (
          <>
            <div
              className="distance-label left-distance"
              ref={leftDistanceRef}
              style={leftInnerDistanceStyle}
            >
              {convertDistanceToMm(neighborDistances.left)}
            </div>

            <div
              className="distance-label top-distance"
              ref={topDistanceRef}
              style={topInnerDistanceStyle}
            >
              {convertDistanceToMm(neighborDistances.top)}
            </div>

            <div
              className="distance-label right-distance"
              ref={rightDistanceRef}
              style={rightInnerDistanceStyle}
            >
              {convertDistanceToMm(neighborDistances.right)}
            </div>

            <div
              className="distance-label bottom-distance"
              ref={bottomDistanceRef}
              style={bottomInnerDistanceStyle}
            >
              {convertDistanceToMm(neighborDistances.bottom)}
            </div>
          </>
        ) : null}
        <div
          className="page-barcode"
          style={{
            height: inchToPx(data.height),
            width: inchToPx(data.width),
            backgroundColor: isAdditionalBarcode ? '#d8d8d8' : '#9a9a9a',
            opacity: isRepeatedBarcode ? 0.5 : 1,
            ...rotateStyle,
          }}
        >
          {data.value}
        </div>
      </div>
    </Draggable>
  )
}

export default DraggablePageBarcode
