import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { Button, DatePicker, Form, Input, InputNumber, Select } from 'antd'
import dayjs from 'dayjs'
import NavContainer from '../../../../components/nav-container'
import { prepaidCreditsType3Constants } from '../../../../_constants'
import { prePayCreditsType3Actions } from '../../../../_actions'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import useClientsToOptionsList from 'hooks/clients/useClientsToOptionsList'

const { navMenu, formItemLayout } = prepaidCreditsType3Constants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.view, navMenu.manage]

const EditItem = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { id } = useParams()

  const { item: prePayCredit } = useSelector(state => state.prePayCreditsType3)

  const [navMenuParse, setNavMenuParse] = useState([])
  const [changed, setChange] = useState(false)
  const [isChangedCredit, setChangedCredit] = useState(false)

  const clientsListOptions = useClientsToOptionsList()

  useEffect(() => {
    return () => {
      dispatch({ type: prepaidCreditsType3Constants.SETBYID })
    }
  }, [])

  const onCreditChange = useCallback(
    value => {
      setChangedCredit(Number(prePayCredit?.credit) !== value)
    },
    [prePayCredit],
  )

  useEffect(() => {
    dispatch(prePayCreditsType3Actions.getItemById(id))
  }, [dispatch, id])

  const onViewItem = () => {
    history.push({
      pathname: `/credit_type3/view/${id}`,
    })
  }

  const onList = () => {
    history.push({
      pathname: `/credit_type3/list`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: onViewItem,
          }
        case navMenu.list.key:
          return {
            ...menu,
            url: onList,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  useEffect(() => {
    if (Object.keys(prePayCredit).length) {
      form.setFieldsValue({
        ...prePayCredit,
        expires_at: prePayCredit?.expires_at ? dayjs(prePayCredit?.expires_at) : null,
      })
    }
  }, [prePayCredit, form])

  const onFinish = async value => {
    const mergedData = {
      ...value,
      expires_at: value?.expires_at?.format('YYYY-MM-DD'),
    }
    const convertedPrePayCredits = convertFormValuesDeep(mergedData)

    await dispatch(prePayCreditsType3Actions.updateItemById(id, convertedPrePayCredits))
  }

  const disabledDate = current => {
    return current && current < dayjs().endOf('day')
  }

  return (
    <NavContainer menu={navMenuParse}>
      {useGlobalLoader(
        <>
          <p>Fields with * are required.</p>
          <Form
            {...formItemLayout}
            name="editPayPreCredit"
            form={form}
            onFinish={onFinish}
            onFieldsChange={() => setChange(true)}
            scrollToFirstError
          >
            <Form.Item
              label="User"
              name="user_id"
              rules={[
                {
                  required: true,
                  message: 'Please input User!',
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Select or search a user"
                filterOption={(input, option) => option.label.includes(input.toString())}
                options={clientsListOptions}
              />
            </Form.Item>

            <Form.Item
              label="Credit"
              name="credit"
              rules={[
                {
                  required: true,
                  message: 'Please input credit amount!',
                },
              ]}
            >
              <InputNumber
                step={0.1}
                min={0}
                style={{ width: '146px' }}
                onChange={onCreditChange}
              />
            </Form.Item>

            {isChangedCredit && (
              <Form.Item label="Note" name="note">
                <Input />
              </Form.Item>
            )}

            <Form.Item
              label="Discount (percent)"
              name="discount"
              rules={[
                {
                  required: true,
                  message: 'Please input amount!',
                },
              ]}
            >
              <InputNumber step={1} style={{ width: '146px' }} min={0} max={99} />
            </Form.Item>

            <Form.Item
              label="Expires At"
              name="expires_at"
              rules={[
                {
                  required: true,
                  message: 'Please select expiration date!',
                },
              ]}
            >
              <DatePicker disabledDate={disabledDate} />
            </Form.Item>

            <Button type="primary" htmlType="submit" disabled={!changed}>
              Save
            </Button>
          </Form>
        </>,
      )}
    </NavContainer>
  )
}

export default EditItem
