import React, { useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { useHistory } from 'react-router'
import v4 from 'uuid/v4'
import { Link } from 'react-router-dom'
import { ordersConstants } from '_constants'
// import getCurrentUrl from '../../../../_helpers/url-utils'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import { ordersActions } from '../../../../_actions'
import { getOrdersRows, getPage, getTotalItems } from '_selectors/orders.selector'

const {
  // navMenu,
  orderStatuses,
  orderIsRedo,
  advancedSearch,
  invoices,
  orderPlatform,
} = ordersConstants

const isRedoRender = text => {
  return `${orderIsRedo.find(st => !!st.value === Boolean(text))?.text}`
}

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    width: '100px',
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user=>login',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Card',
    dataIndex: 'card',
    key: 'card=>name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Used Credit',
    dataIndex: 'used_credit',
    key: 'used_credit',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Date Created',
    dataIndex: 'date_created',
    key: 'date_created*date',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status_filter',
    filters: orderStatuses,
    filteredValue: ['paid'],
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Is Redo',
    dataIndex: 'redo_id',
    render: isRedoRender,
    key: 'redo_id_filter',
    filters: orderIsRedo,
  },
]

// const navMenuInit = [navMenu.list, navMenu.listMyOrders, navMenu.availableOrders]
const ManageItems = () => {
  // const ManageItems = ({ match }) => {
  // const history = useHistory()
  const dispatch = useDispatch()
  // const view = getCurrentUrl(match.url)

  const [navMenuParse] = useState([])
  const rows = useSelector(getOrdersRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const deleteItems = async id => {
    await dispatch(ordersActions.deleteItem(id))
  }

  // const navigateToList = () => {
  //   dispatch({ type: ordersConstants.SETALL })
  //   dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
  //   history.push({
  //     pathname: `${view}/list`,
  //   })
  // }

  // useEffect(() => {
  //   const newMenu = navMenuInit.map(menu => {
  //     switch (menu.key) {
  //       case navMenu.list.key:
  //         return {
  //           ...menu,
  //           url: navigateToList,
  //         }
  //       default:
  //         return menu
  //     }
  //   })
  //   setNavMenuParse([...newMenu])
  // }, [navMenuInit])

  const addNewClass = (denominationId, address_to) => {
    const intl = address_to && address_to?.country_id !== 1
    let classes = ''
    if (denominationId) classes = `blue-bg`
    if (intl) classes = `${classes} yellow-bg`
    return classes
  }

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        user: <Link to={`/clients/edit/${item.user.id}`}> {item.user.name} </Link>,
        card: item.card ? <Link to={`/cards/edit/${item.card.id}`}> {item.card.name} </Link> : '',
        key: v4(),
        className: addNewClass(item?.denomination_id || item?.inserts?.length, item?.address_to),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  const orderStatusesSelect = useMemo(() => {
    return orderStatuses.map(status => ({ value: status.value, label: status.text }))
  }, [orderStatuses])

  const invoicedSelect = useMemo(() => {
    return invoices.map(invoice => ({ value: invoice.value, label: invoice.text }))
  }, [invoices])

  const platformSelect = useMemo(() => {
    return orderPlatform.map(invoice => ({ value: invoice.value, label: invoice.text }))
  }, [orderPlatform])

  const updAdvancedSearch = advancedSearch.map(field => {
    if (field.key === 'invoiced_filter') {
      return { ...field, options: invoicedSelect }
    }
    if (field.key === 'status_filter') {
      return { ...field, options: orderStatusesSelect }
    }
    if (field.key === 'platform_filter') {
      return { ...field, options: platformSelect }
    }
    return field
  })

  return (
    <NavContainer menu={navMenuParse}>
      <TableFilter
        deleteItems={deleteItems}
        columns={initColumns}
        data={dataSource}
        getDataAction={ordersActions.getAll}
        advancedSearch={updAdvancedSearch}
        showAdvancedButton={!!true}
      />
    </NavContainer>
  )
}

export default ManageItems
