import { clientsConstants } from '../_constants'

const initialState = {
  items: [],
  item: {
    countries: [],
    client: {},
    orders: {},
  },
}

function clients(state = initialState, { type, payload }) {
  switch (type) {
    case clientsConstants.GETALL_SUCCESS:
    case clientsConstants.GETCONTEXT_SUCCESS:
    case clientsConstants.GETLISTUSERS_SUCCESS:
      return { ...state, items: payload }
    case clientsConstants.ORDERS_SUCCESS:
      return { ...state, item: { ...state.item, orders: payload } }
    case clientsConstants.GETBYID_SUCCESS:
      return { ...state, item: { ...state.item, ...payload } }
    case clientsConstants.DELETE_SUCCESS:
      return { ...state }
    case clientsConstants.UPDATE_SUCCESS:
      return { ...state, items: state.items }
    case clientsConstants.GEN_TOKEN_SUCCESS:
      return {
        ...state,
        item: {
          ...state.item,
          client: {
            ...state.item.client,
            token: payload,
          },
        },
      }
    case clientsConstants.SETBYID:
      return {
        ...state,
        item: {
          countries: [],
          client: {},
          orders: {},
        },
      }
    case clientsConstants.SETALL:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default clients
