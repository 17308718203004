import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { userActions } from '_actions'
import { Button, Form, Input } from 'antd'
import { userConstants } from '../../../../_constants'
import NavContainer from '../../../../components/nav-container'

const { formItemLayout, navMenu } = userConstants

const navMenuInit = [navMenu.list, navMenu.profile, navMenu.edit, navMenu.logout]

const ChangePassword = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const [navMenuParse, setNavMenuParse] = useState([])
  const user = useSelector(state => state.authentication.user)

  const logOut = () => {
    dispatch(userActions.logout())
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.logout.key:
          return {
            ...menu,
            url: logOut,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const onFinish = async values => {
    const valuesKey = Object.keys(values)
    const error = []

    if (values.newPass !== values.checkPass)
      error.push({ name: 'checkPass', errors: ['Retype Password is incorrect'] })

    valuesKey.forEach(key => {
      if (values[key].length < 4) {
        error.push({ name: key, errors: ['Password is too short (minimum is 4 characters)'] })
      }
    }, {})
    if (error.length) return form.setFields(error)
    await dispatch(userActions.changePassword(values, user.id))
    return form.setFields([])
  }

  return (
    <NavContainer menu={navMenuParse}>
      <div>
        <p>Fields with * are required.</p>
        <Form
          {...formItemLayout}
          name="editClientGroup"
          form={form}
          onFinish={onFinish}
          scrollToFirstError
        >
          <Form.Item
            label="Old Password"
            name="oldPass"
            rules={[
              {
                required: true,
                message: 'Old Password cannot be blank.',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Password"
            name="newPass"
            rules={[
              {
                required: true,
                message: 'Password cannot be blank!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Retype Password"
            name="checkPass"
            rules={[
              {
                required: true,
                message: 'Retype Password cannot be blank!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </NavContainer>
  )
}

export default ChangePassword
