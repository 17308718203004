import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { Button, Modal, Space } from 'antd'
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons'

import NavContainer from '../../../../components/nav-container'
import ItemViewer from '../../../../components/item-viewer'
import getCurrentUrl from '../../../../_helpers/url-utils'

import { impositionLayoutsActions } from '../../../../_actions'
import { impositionLayoutsConstants } from '../../../../_constants'
import { impositionLayoutsSelectors } from '_selectors/imposition-layouts.selector'

const { navMenu, viewItemKeyToLabel, layoutStatuses } = impositionLayoutsConstants

const navMenuInit = [navMenu.manage, navMenu.create, navMenu.edit, navMenu.delete]

const ViewItem = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const { id } = useParams()

  const imposition = useSelector(impositionLayoutsSelectors.getImposition)

  const [navMenuParse, setNavMenuParse] = useState([])
  const view = getCurrentUrl(match.url)

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(impositionLayoutsActions.deleteItem(id))
        history.push({
          pathname: `${view}`,
        })
      },
    })
  }

  useEffect(() => {
    dispatch(impositionLayoutsActions.getItemById(id))
    return () => {
      dispatch({ type: impositionLayoutsConstants.SETBYID })
    }
  }, [id, dispatch])

  const onEditItem = () => {
    history.push({
      pathname: `${view}/edit/${id}`,
    })
  }

  const navigateToLayoutForm = () => {
    history.push({
      pathname: `${view}/layout/${id}`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.edit.key:
          return {
            ...menu,
            url: onEditItem,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit, imposition])

  const viewItem = useMemo(() => {
    if (imposition) {
      return Object.keys(viewItemKeyToLabel).reduce((obj, sKey) => {
        let itemValue
        if (sKey === 'status') {
          itemValue =
            layoutStatuses.find(status => status.value.toString() === imposition.status.toString())
              ?.text || 'Unknown Status'
        } else if (sKey === 'total_layouts') {
          itemValue = (
            <Space onClick={() => navigateToLayoutForm(imposition.id)}>
              <Button
                size="small"
                type="text"
                className="list-view-item-bold list-item-underline px-0"
                style={{ border: 'none', width: '100%' }}
              >
                {imposition.total_layouts}
              </Button>
              <Button
                style={{ color: '#595c97' }}
                size="small"
                shape="square"
                icon={<PlusOutlined />}
              />
            </Space>
          )
        } else {
          itemValue = imposition[sKey]
        }
        return {
          ...obj,
          [sKey]: itemValue,
        }
      }, {})
    }
    return {}
  }, [imposition])

  return (
    <NavContainer menu={navMenuParse}>
      <ItemViewer item={viewItem} keyToLabelMap={viewItemKeyToLabel} />
    </NavContainer>
  )
}

export default ViewItem
