const getImpositionRows = state => state.impositionLayouts.items?.rows || null
const getPage = state => state.impositionLayouts.items?.page || 1
const getTotalItems = state => state.impositionLayouts.items?.total || 0
const getImposition = state => state.impositionLayouts.item?.imposition || null
const getConfigs = state => state.impositionLayouts.configs

export const impositionLayoutsSelectors = {
  getImpositionRows,
  getPage,
  getTotalItems,
  getImposition,
  getConfigs,
}
