import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import v4 from 'uuid/v4'
import { Button } from 'antd'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import { clientGroupActions, clientsActions, queryParamsActions } from '../../../../_actions'
import { clientsConstants } from '../../../../_constants'
import { getClientRows, getPage, getTotalItems } from '../../../../_selectors/clients.selector.js'
import { getClientGroupsRows } from '../../../../_selectors/client-group.selector.js'

const { clientStatuses, clientPlatform, advancedSearch } = clientsConstants

const statusRender = text => {
  return `${clientStatuses.find(st => st.value?.toString() === text?.toString())?.text}`
}

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status_filter',
    render: statusRender,
    filters: clientStatuses,
    width: '10%',
  },
  {
    title: 'Login',
    dataIndex: 'login',
    key: 'login',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'First Name',
    dataIndex: 'fname',
    key: 'fname',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Last Name',
    dataIndex: 'lname',
    key: 'lname',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Phone',
    dataIndex: 'user_phone',
    key: 'user_phone',
  },
  {
    title: 'Group',
    dataIndex: 'user_group',
    filterSearch: true,
    key: 'group_id_filter',
  },
  {
    title: 'Orders',
    dataIndex: 'total_orders',
    key: 'total_orders',
  },
  {
    title: 'Discount Code',
    dataIndex: 'discount_code',
    key: 'discount_code',
  },
]

const navMenuInit = []

const ManageItems = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const rows = useSelector(getClientRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const userGroups = useSelector(getClientGroupsRows)

  useEffect(() => {
    dispatch(clientGroupActions.getAll())
  }, [])

  const deleteItem = async id => {
    await dispatch(clientsActions.deleteItem(id))
  }

  const userGroupsFilters = useMemo(
    () => userGroups?.map(group => ({ value: group.id, text: group.title })),
    [userGroups],
  )

  const columnsWithFilters = useMemo(() => {
    return initColumns.map(column => {
      if (column.key === 'group_id_filter') {
        return {
          ...column,
          filters: userGroupsFilters,
        }
      }
      return column
    })
  }, [userGroupsFilters])

  const redirectToOrders = user_id => {
    dispatch(queryParamsActions.setQueryParams({ user_id }))
    history.push({
      pathname: '/orders',
    })
  }

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        user_group: item?.user_group?.title || '',
        total_orders: (
          <Button
            size="small"
            type="text"
            className="list-view-item-bold list-item-underline px-0"
            style={{ border: 'none' }}
            onClick={() => redirectToOrders(item.id)}
          >
            {item.total_orders}
          </Button>
        ),
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page, userGroups])

  const clientStatusesSelect = useMemo(() => {
    return clientStatuses.map(status => ({ value: status.value, label: status.text }))
  }, [clientStatuses])

  const platformSelect = useMemo(() => {
    return clientPlatform.map(invoice => ({ value: invoice.value, label: invoice.text }))
  }, [clientPlatform])

  const updAdvancedSearch = advancedSearch.map(field => {
    if (field.key === 'status_filter') {
      return { ...field, options: clientStatusesSelect }
    }
    if (field.key === 'platform') {
      return { ...field, options: platformSelect }
    }
    return field
  })

  return (
    <NavContainer match={match} menu={navMenuInit}>
      <TableFilter
        deleteItems={deleteItem}
        columns={columnsWithFilters}
        data={dataSource}
        getDataAction={clientsActions.getAll}
        advancedSearch={updAdvancedSearch}
      />
    </NavContainer>
  )
}

export default ManageItems
