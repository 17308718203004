import React, { useMemo } from 'react'
import { Progress, Space, Spin, Tooltip } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'

import styles from './style.module.scss'

const DownloadBar = () => {
  const impositionDownloadProgress = useSelector(state => state.batchOrders.impositionProgress)
  const cardPdfDownloadProgress = useSelector(state => state.cards.downloadProgress)

  const downloadProgress = [...impositionDownloadProgress, ...cardPdfDownloadProgress]

  // render only last 3 items
  const itemsToRender = useMemo(() => downloadProgress.slice(-3), [downloadProgress.length])

  return (
    <Space style={{ marginRight: '1rem' }}>
      {itemsToRender.map(({ filename, percentage }) => (
        <Tooltip title={filename} key={filename}>
          {typeof percentage === 'number' ? (
            <Progress
              className={styles.progress_circle}
              type="circle"
              percent={percentage}
              width={40}
            />
          ) : (
            <Spin indicator={<LoadingOutlined style={{ fontSize: 35 }} spin />} />
          )}
        </Tooltip>
      ))}
    </Space>
  )
}

export default DownloadBar
