import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import v4 from 'uuid/v4'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import { shopifyActions } from '../../../../_actions'

import { shopifyConstants } from '../../../../_constants'
import getCurrentUrl from '../../../../_helpers/url-utils.js'
import {
  getShopifyRows,
  getPage,
  getTotalItems,
  getShopifyIssues,
} from '_selectors/shopify.selector'

const { navMenu } = shopifyConstants

const navMenuInit = [navMenu.list]

const initColumns = [
  {
    title: 'Shop ID',
    dataIndex: 'shop_id',
    key: 'shop_id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'User ID',
    dataIndex: 'user_id',
    key: 'user_id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Shop',
    dataIndex: 'myshopify_domain',
    key: 'myshopify_domain',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Count of trigger',
    dataIndex: 'count_of_triggers',
    key: 'count_of_triggers',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Shopify Triggers',
    dataIndex: 'shopify_triggers',
    key: 'shopify_triggers',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
]

const ManageItems = ({ match }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getShopifyRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)
  const issues = useSelector(getShopifyIssues)

  useEffect(() => {
    dispatch(shopifyActions.getCountOfIssuesShops())
  }, [])

  const navigateToIssuesShops = () => {
    if (!issues?.count) return
    history.push({
      pathname: `${view}/issues`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.list.key:
          return {
            ...menu,
            url: navigateToIssuesShops,
            count: issues?.count,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit, issues?.count])

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        shopify_triggers: item.shopify_triggers.map(trigger => (
          <>
            {trigger.event.split('_').join(' ')}{' '}
            {trigger.purchase_threshold || trigger.purchase_quantity || trigger.annual_interval
              ? `(${trigger.purchase_threshold ||
                  trigger.purchase_quantity ||
                  trigger.annual_interval})`
              : ''}
            <br />
          </>
        )),
        count_of_triggers: item.shopify_triggers.length,
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  return (
    <NavContainer match={match} menu={navMenuParse}>
      <TableFilter
        columns={initColumns}
        data={dataSource}
        getDataAction={shopifyActions.getAll}
        actionsColum={[]}
      />
    </NavContainer>
  )
}

export default ManageItems
