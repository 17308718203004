import { cardsConstants } from '../_constants'
import { cardsService } from '../_services'
import { history } from '../_helpers'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: cardsConstants.GETALL_REQUEST })
  try {
    const cards = await cardsService.getAll(params, typeAction)
    dispatch({ type: cardsConstants.GETALL_SUCCESS, payload: cards.data })
  } catch (error) {
    dispatch({ type: cardsConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const addItem = value => async dispatch => {
  dispatch({ type: cardsConstants.ADD_REQUEST })

  try {
    const card = await cardsService.addItem({ value })
    dispatch({ type: cardsConstants.ADD_SUCCESS, payload: { ...value, id: card.id } })
    history.push(`/cards/view/${card.id}`)
  } catch (error) {
    dispatch({ type: cardsConstants.ADD_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: cardsConstants.GETBYID_REQUEST })

  try {
    const card = await cardsService.getItemById(id)
    dispatch({ type: cardsConstants.GETBYID_SUCCESS, payload: card.data })
  } catch (error) {
    dispatch({ type: cardsConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const deleteItem = id => async dispatch => {
  dispatch({ type: cardsConstants.DELETE_REQUEST })
  try {
    await cardsService.deleteItem(id)
    history.push(`/cards`)
    dispatch({ type: cardsConstants.DELETE_SUCCESS, payload: id })
  } catch (error) {
    dispatch({ type: cardsConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const updateItemById = (id, value) => async dispatch => {
  dispatch({ type: cardsConstants.UPDATE_REQUEST })

  try {
    const updated = await cardsService.updateItemById(id, value)
    dispatch({ type: cardsConstants.UPDATE_SUCCESS, payload: updated?.message?.toString() })
    history.push(`/cards/view/${id}`)
  } catch (error) {
    dispatch({ type: cardsConstants.UPDATE_FAILURE, payload: error.toString() })
  }
}

const generatePdf = (id, withQr, cardName) => async dispatch => {
  const filename = `${cardName}${withQr ? '_withQR' : ''}.pdf`
  dispatch({ type: cardsConstants.GENERATE_PDF_REQUEST, payload: filename })

  const onDownload = event => {
    const filename = `${event.target.getResponseHeader('Card-Name')}.pdf`
    const total = event.target.getResponseHeader('X-File-Length')
    const percentage = Math.round((event.loaded * 100) / total)

    if (percentage <= 100) {
      dispatch({
        type: cardsConstants.PDF_DOWNLOAD_PROGRESS,
        payload: { percentage, filename },
      })

      if (percentage === 100)
        setTimeout(() => {
          dispatch({
            type: cardsConstants.GENERATE_PDF_SUCCESS,
            payload: filename,
          })
        }, 2000)
    }
  }

  try {
    await cardsService.generatePdf(id, withQr, onDownload)

    dispatch({ type: cardsConstants.GENERATE_PDF_SUCCESS, payload: filename })
  } catch (error) {
    dispatch({ type: cardsConstants.GENERATE_PDF_FAILURE, payload: filename })
  }
}

const cardsActions = {
  getAll,
  getItemById,
  updateItemById,
  deleteItem,
  addItem,
  generatePdf,
}

export default cardsActions
