import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import {
  EditItem,
  // ListAvailableItems,
  // ListItems,
  // ListMyItems,
  ManageItems,
  ViewItem,
} from './views'
import Content from '../../components/content'

const Orders = () => {
  const match = useRouteMatch()

  return (
    <Content>
      <Switch>
        <Route exact path={match.url} component={ManageItems} />
        {/* <Route path={`${match.url}/list`} component={ListItems} /> */}
        <Route path={`${match.url}/view/:id`} component={ViewItem} />
        <Route path={`${match.url}/edit/:id`} component={EditItem} />
        {/* <Route path={`${match.url}/my/`} component={ListMyItems} />
        <Route path={`${match.url}/available`} component={ListAvailableItems} /> */}

        <Redirect to={match.url} />
      </Switch>
    </Content>
  )
}

export default Orders
