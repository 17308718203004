import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Tooltip } from 'antd'
import creditHistoryConstants from '../../../../_constants/credit-history.constants.js'
import v4 from 'uuid'
import NavContainer from '../../../../components/nav-container'
import TableFilter from '../../../../components/table-filter'
import creditHistoryActions from '../../../../_actions/credit-history.actions.js'
import { queryParamsActions } from '../../../../_actions'
import {
  getCreditHistoryRows,
  getPage,
  getTotalItems,
} from '../../../../_selectors/credit-history.selector.js'

const { typeOperation, typeCredit } = creditHistoryConstants

const typeOperationRender = text => {
  return `${typeOperation.find(type => type.value.toString() === text.toString())?.text}`
}

const typeCreditRender = text => {
  return `${typeCredit.find(type => type.value.toString() === text.toString())?.text}`
}

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '10%',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    defaultSortOrder: 'descend',
  },
  {
    title: 'User',
    dataIndex: 'user_id',
    key: 'user_id',
    width: '10%',
  },
  {
    title: 'Credit',
    dataIndex: 'credit',
    key: 'credit_filter',
    width: '10%',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: typeCreditRender,
    filters: typeCredit,
  },
  {
    title: 'Type Operation',
    dataIndex: 'type_operation',
    key: 'type_operation_filter',
    width: '10%',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: typeOperationRender,
    filters: typeOperation,
  },
  {
    title: 'Note',
    dataIndex: 'note',
    key: 'note',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    ellipsis: {
      showTitle: false,
    },
    maxWidth: '50px',
    render: note => (
      <Tooltip placement="topLeft" title={note}>
        <div
          style={{
            maxWidth: '120px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {note}
        </div>
      </Tooltip>
    ),
  },
  {
    title: 'Before Amount',
    dataIndex: 'before_amount',
    key: 'before_amount',
    width: '10%',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    width: '10%',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'After Amount',
    dataIndex: 'after_amount',
    key: 'after_amount',
    width: '10%',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Entity',
    dataIndex: 'entity',
    key: 'entity',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Entity ID',
    dataIndex: 'entity_id',
    key: 'entity_id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    width: '10%',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Updated At',
    dataIndex: 'updated_at',
    key: 'updated_at',
    width: '10%',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
]

const ManageCreditHistory = ({ match }) => {
  const rows = useSelector(getCreditHistoryRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const whereQueryParams = useSelector(state => state.queryParams.where)

  const dispatch = useDispatch()

  const history = useHistory()

  const clientId = useMemo(() => history.location.pathname.match(/\d+/g), [
    history.location.pathname,
  ])

  useEffect(() => {
    if (clientId) dispatch(queryParamsActions.setQueryParams({ user_id: clientId[0] }))
  }, [clientId])

  const addNewClass = type_operation => {
    let classes = ''
    if (type_operation === 'INC') classes = `green-bg`
    if (type_operation === 'DEC') classes = `red-bg`
    if (type_operation === 'INITIAL') classes = `yellow-bg`
    if (type_operation === 'NEW') classes = `gold-bg`
    return classes
  }

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => {
        return {
          ...item,
          key: v4(),
          className: addNewClass(item?.type_operation),
        }
      }),
      total,
      page,
    }
  }, [rows, total, page])

  if (clientId && !whereQueryParams) return null

  return (
    <NavContainer match={match} menu={[]}>
      <TableFilter
        columns={initColumns}
        data={dataSource}
        getDataAction={creditHistoryActions.getAll}
        actionsColum={false}
        isEditOnId={false}
        defaultSort={{
          order: 'descend',
          field: 'id',
          columnKey: 'id',
        }}
      />
    </NavContainer>
  )
}

export default ManageCreditHistory
