import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Image } from 'antd'
import NavContainer from '../../../../components/nav-container'
import ListViewer from '../../../../components/list-viewer'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { cardsActions } from '../../../../_actions'
import { appConstants, cardsConstants, settingsConstants } from '../../../../_constants'
import { getCardsRows, getPage, getTotalItems } from '../../../../_selectors/cards.selector.js'

const { navMenu, listItemKeyToLabel, cardStatuses, orientationKeys } = cardsConstants

const navMenuInit = [navMenu.manage, navMenu.create]

const ListItems = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getCardsRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const navigateToManage = () => {
    dispatch({ type: cardsConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.manage.key:
          return {
            ...menu,
            url: navigateToManage,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const listItems = useMemo(() => {
    if (rows?.length > 0) {
      return {
        total,
        page,
        rows: rows?.map(item => {
          const res = []
          Object.keys(listItemKeyToLabel).forEach(sKey => {
            const itemLine = { label: listItemKeyToLabel[sKey], value: item[sKey], key: sKey }
            if (sKey === 'id') {
              itemLine.clickHandler = () => {
                history.push({ pathname: `/cards/view/${item[sKey]}` })
              }
            } else if (sKey === 'category') {
              itemLine.value = item.category?.name
            } else if (sKey === 'status') {
              itemLine.value =
                cardStatuses.find(status => status.value === item.status.toString())?.text ||
                'Unknown Status'
            } else if (sKey === 'orientation') {
              itemLine.value = orientationKeys[item.orientation] || 'Unknown Orientation'
            } else if (sKey === 'image') {
              itemLine.float = true
              itemLine.type = 'image'
              itemLine.value = (
                <Image
                  width={200}
                  src={`${appConstants.IMAGES_URL}/cardimages/${item.cover}`}
                  fallback={appConstants.FALLBACK_IMAGE}
                  preview={false}
                />
              )
            }
            res.push(itemLine)
          })
          return res
        }),
      }
    }
    return { rows: [], total, page }
  }, [rows, page, total])

  return (
    <NavContainer match={match} menu={navMenuParse}>
      <ListViewer data={listItems} getDataAction={cardsActions.getAll} />
    </NavContainer>
  )
}

export default ListItems
