import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { prePayCreditsType3Actions } from '../../../../_actions'
import NavContainer from '../../../../components/nav-container'
import ListViewer from '../../../../components/list-viewer'
import { prepaidCreditsType3Constants, settingsConstants } from '../../../../_constants'
import { getPrePaidType3Rows, getPage, getTotalItems } from '_selectors/pre-paid-type3.selector'

const { navMenu, listItemKeyToLabel } = prepaidCreditsType3Constants

const navMenuInit = [navMenu.create, navMenu.manage]

const ListItems = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getPrePaidType3Rows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const navigateToManage = () => {
    dispatch({ type: prepaidCreditsType3Constants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.manage.key:
          return {
            ...menu,
            url: navigateToManage,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const listItems = useMemo(() => {
    return {
      total,
      page,
      rows: rows?.map(item => {
        const res = []
        Object.keys(listItemKeyToLabel).forEach(sKey => {
          const itemLine = { label: listItemKeyToLabel[sKey], value: item[sKey], key: sKey }
          if (sKey === 'user_id') {
            itemLine.clickHandler = () => {
              history.push({ pathname: `/clients/edit/${item.user_id}` })
            }
            itemLine.value = item.user_id
          } else if (sKey === 'id') {
            itemLine.clickHandler = () => {
              history.push({ pathname: `/credit_type3/edit/${item.id}` })
            }
          } else if (sKey === 'discount') {
            itemLine.value = `${item[sKey]}%`
          }
          res.push(itemLine)
        })
        return res
      }),
    }
  }, [rows, total, rows])

  return (
    <NavContainer menu={navMenuParse}>
      <ListViewer data={listItems} getDataAction={prePayCreditsType3Actions.getAll} />
    </NavContainer>
  )
}

export default ListItems
