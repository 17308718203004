import { usaFinancialConstants } from '../_constants'
import { usaFinancialService } from '../_services'
import { history } from '../_helpers'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: usaFinancialConstants.GETALL_REQUEST })
  try {
    const usaFinancial = await usaFinancialService.getAll(params, typeAction)
    dispatch({ type: usaFinancialConstants.GETALL_SUCCESS, payload: usaFinancial.data })
  } catch (error) {
    dispatch({ type: usaFinancialConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const addItem = value => async dispatch => {
  dispatch({ type: usaFinancialConstants.ADD_REQUEST })

  try {
    const item = await usaFinancialService.addItem({ value })
    dispatch({ type: usaFinancialConstants.ADD_SUCCESS, payload: { ...value, id: item.new.id } })
    history.push(`/usa-financial/view/${item.new.id}`)
  } catch (error) {
    dispatch({ type: usaFinancialConstants.ADD_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: usaFinancialConstants.GETBYID_REQUEST })
  try {
    const item = await usaFinancialService.getItemById(id)
    dispatch({ type: usaFinancialConstants.GETBYID_SUCCESS, payload: item.data })
  } catch (error) {
    dispatch({ type: usaFinancialConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const deleteItem = id => async dispatch => {
  dispatch({ type: usaFinancialConstants.DELETE_REQUEST })
  try {
    await usaFinancialService.deleteItem(id)
    history.push('/usa-financial')
    dispatch({ type: usaFinancialConstants.DELETE_SUCCESS, payload: id })
  } catch (error) {
    dispatch({ type: usaFinancialConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const updateItemById = (id, value) => async dispatch => {
  dispatch({ type: usaFinancialConstants.UPDATE_REQUEST })

  try {
    const updated = await usaFinancialService.updateItemById(id, value)
    dispatch({ type: usaFinancialConstants.UPDATE_SUCCESS, payload: updated?.message?.toString() })
    history.push(`/usa-financial/view/${id}`)
  } catch (error) {
    dispatch({ type: usaFinancialConstants.UPDATE_FAILURE, payload: error.toString() })
  }
}

const getUsaFinancialTriggers = params => async dispatch => {
  dispatch({ type: usaFinancialConstants.GET_USA_FINANCIAL_TRIGGER_REQUEST })
  try {
    const trigger = await usaFinancialService.getUsaFinancialTriggers(params)
    dispatch({
      type: usaFinancialConstants.GET_USA_FINANCIAL_TRIGGER_SUCCESS,
      payload: trigger.data,
    })
  } catch (error) {
    dispatch({
      type: usaFinancialConstants.GET_USA_FINANCIAL_TRIGGER_FAILURE,
      payload: error.toString(),
    })
  }
}

const usaFinancialActions = {
  getAll,
  getItemById,
  updateItemById,
  deleteItem,
  addItem,
  getUsaFinancialTriggers,
}

export default usaFinancialActions
