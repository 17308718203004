import { apiKeysConstants } from '_constants'
import { apiKeysService } from '_services'

const getAll = (params, typeAction, id) => async dispatch => {
  // we set userId, because, when reload page not set user id and get all api keys
  const paramsWithId = {
    ...params,
    where: {
      ...params.where,
      user_id: id,
    },
  }

  dispatch({ type: apiKeysConstants.GETALL_REQUEST })
  try {
    const apiKeys = await apiKeysService.getAll(paramsWithId, typeAction)
    dispatch({ type: apiKeysConstants.GETALL_SUCCESS, payload: apiKeys.data })
  } catch (error) {
    dispatch({ type: apiKeysConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const apiKeysActions = {
  getAll,
}

export default apiKeysActions
