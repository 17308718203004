import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import v4 from 'uuid/v4'
import { Link, useHistory } from 'react-router-dom'
import { Button } from 'antd'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { appConstants, cardsConstants, settingsConstants } from '../../../../_constants'
import { cardsActions, categoriesActions, queryParamsActions } from '../../../../_actions'
import useCardDelete from 'hooks/cards/useCardDelete'
import { getPage, getCardsRows, getTotalItems } from '../../../../_selectors/cards.selector.js'
import { getCategoriesRows } from '../../../../_selectors/categories.selector.js'

const { navMenu, cardStatuses, cardOrientation, advancedSearch, orientationKeys } = cardsConstants

const statusRender = text => {
  return `${cardStatuses.find(st => st.value.toString() === text.toString())?.text}`
}

const orientationRender = text => {
  return orientationKeys[text]
}

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status_filter',
    render: statusRender,
    filters: cardStatuses,
    width: '10%',
    sorter: true,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
  },
  {
    title: 'Users Login',
    dataIndex: 'user',
    key: 'users_login',
    hideFilter: true,
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category=>name',
  },
  {
    title: 'Cover',
    dataIndex: 'cover',
    key: 'cover',
    // eslint-disable-next-line react/display-name
    render: imageUrl => <img style={{ width: '10rem' }} alt=" " src={imageUrl} />,
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    sorter: true,
  },
  {
    title: 'Images',
    dataIndex: 'images',
    key: 'total_images',
    sorter: true,
  },
  {
    title: 'Orientation',
    dataIndex: 'orientation',
    key: 'orientation_filter',
    render: orientationRender,
    filters: cardOrientation,
    width: '10%',
    sorter: true,
  },
]

const navMenuInit = [navMenu.list, navMenu.create]

const ManageItems = ({ match }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const view = getCurrentUrl(match.url)
  const { getWarningMessage } = useCardDelete()

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getCardsRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const categories = useSelector(getCategoriesRows)

  useEffect(() => {
    dispatch(categoriesActions.getAll())
  }, [])

  const navigateToList = () => {
    dispatch({ type: cardsConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}/list`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.list.key:
          return {
            ...menu,
            url: navigateToList,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const deleteItems = async id => {
    await dispatch(cardsActions.deleteItem(id))
  }

  const navigateToCardImage = cardId => {
    dispatch(queryParamsActions.setQueryParams({ card_id: cardId }))
    history.push({
      pathname: '/card-images',
    })
  }

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        user: item.user_id ? (
          item.user ? (
            <Link to={`/clients/edit/${item.user.id}`}>{item.user.name}</Link>
          ) : (
            item.user_id
          )
        ) : (
          ''
        ),
        images: (
          <Button
            size="small"
            type="text"
            className="list-view-item-bold list-item-underline px-0"
            style={{ border: 'none' }}
            onClick={() => navigateToCardImage(item.id)}
          >
            {item.total_images}
          </Button>
        ),
        category: item.category?.name,
        cover: item.cover ? `${appConstants.IMAGES_URL}/cardimages/${item.cover}` : '',
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  const cardStatusesSelect = useMemo(
    () => cardStatuses.map(status => ({ value: status.value, label: status.text })),
    [cardStatuses],
  )

  const templateCategoriesSelect = useMemo(() => {
    return categories?.map(category => ({ value: category.id, label: category.name }))
  }, [categories])

  const updAdvancedSearch = advancedSearch.map(field => {
    if (field.key === 'category_id') return { ...field, options: templateCategoriesSelect }
    if (field.key === 'status_filter') return { ...field, options: cardStatusesSelect }
    return field
  })

  return (
    <NavContainer match={match} menu={navMenuParse}>
      <TableFilter
        warningCheck={getWarningMessage}
        deleteItems={deleteItems}
        columns={initColumns}
        data={dataSource}
        getDataAction={cardsActions.getAll}
        advancedSearch={updAdvancedSearch}
      />
    </NavContainer>
  )
}

export default ManageItems
