import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import NavContainer from '../../../../components/nav-container'
import ItemViewer from '../../../../components/item-viewer'
import { cardDimensionActions } from '../../../../_actions'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { dimensionCardConstants } from '../../../../_constants'

const {
  navMenu,
  viewItemKeyToLabel,
  cardStatuses,
  cardOrientation,
  cardCanCustomize,
} = dimensionCardConstants

const navMenuInit = [navMenu.create, navMenu.edit, navMenu.delete, navMenu.manage]

const ViewItem = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const { id } = useParams()

  const {
    item: { cardDimension: card },
  } = useSelector(state => state.dimensionCard)

  const [navMenuParse, setNavMenuParse] = useState([])
  const view = getCurrentUrl(match.url)

  const deleteItems = async () => {
    await dispatch(cardDimensionActions.deleteItem(id))
    history.push({
      pathname: `${view}`,
    })
  }

  useEffect(() => {
    dispatch(cardDimensionActions.getItemById(id))
    return () => {
      dispatch({ type: dimensionCardConstants.SETBYID })
    }
  }, [id, dispatch])

  const onEditItem = () => {
    history.push({
      pathname: `${view}/edit/${id}`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.edit.key:
          return {
            ...menu,
            url: onEditItem,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: deleteItems,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit, card])

  const viewItem = useMemo(() => {
    if (card) {
      return Object.keys(viewItemKeyToLabel).reduce((obj, sKey) => {
        let itemValue
        if (sKey === 'status') {
          itemValue =
            cardStatuses.find(status => status.value.toString() === card.status.toString())?.text ||
            'Unknown Status'
        } else if (sKey === 'orientation') {
          itemValue =
            cardOrientation.find(orientation => orientation.value === card.orientation)?.text ||
            'Unknown Orientation'
        } else if (sKey === 'can_customize') {
          itemValue =
            cardCanCustomize.find(canCustomize => canCustomize.value === card.can_customize)
              ?.text || 'Unknown Value'
        } else {
          itemValue = card[sKey]
        }
        return {
          ...obj,
          [sKey]: itemValue,
        }
      }, {})
    }
    return {}
  }, [card])

  return (
    <NavContainer menu={navMenuParse}>
      <ItemViewer item={viewItem} keyToLabelMap={viewItemKeyToLabel} />
    </NavContainer>
  )
}

export default ViewItem
