import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, Input, Select } from 'antd'
import { settingsConstants, spinnerConstants } from '_constants'

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 8 },
}
const tailLayout = {
  wrapperCol: { offset: 5, span: 8 },
}

const AdvancedSearch = ({ advancedSearch, columns, setTableChange, setSearchInfo }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const user_id = useSelector(state => state.queryParams.where?.user_id)
  const card_id = useSelector(state => state.queryParams.where?.card_id)

  const columnsKeys = useMemo(() => {
    return columns.reduce((acc, cur) => {
      if (cur.filteredValue) {
        return { ...acc, [cur.key]: cur.filteredValue[0] }
      }
      return { ...acc, [cur.key]: '' }
    }, {})
  }, [columns])

  useEffect(() => {
    if (user_id) {
      form.setFieldsValue({
        ...columnsKeys,
        user_id,
      })
    } else if (card_id) {
      form.setFieldsValue({
        ...columnsKeys,
        card_id,
      })
    } else {
      form.setFieldsValue({
        ...columnsKeys,
      })
    }
  }, [columnsKeys, form, user_id, card_id])

  const onFinish = values => {
    dispatch({ type: spinnerConstants.START_ON_MANAGE_PAGE })

    const valuesKeys = Object.keys(values)
    const updValue = {}
    valuesKeys.forEach(key => {
      if (values[key] !== '' && values[key] !== undefined) {
        updValue[key] = [values[key]]
      }
    })

    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: 'advanced' })
    setTableChange(true)
    setSearchInfo({ filteredInfo: updValue, sortedInfo: null })
  }

  const advancedSearchForm = advancedSearch.map(field => {
    const { type, key, label, options } = field
    if (type === 'select') {
      return (
        <Form.Item name={key} label={label} key={key}>
          <Select options={options} showSearch allowClear />
        </Form.Item>
      )
    }
    return (
      <Form.Item label={label} name={key} key={key}>
        <Input />
      </Form.Item>
    )
  })

  return (
    <Form {...layout} name="AdvancedSearch" form={form} onFinish={onFinish} scrollToFirstError>
      {advancedSearchForm}
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Search
        </Button>
      </Form.Item>
    </Form>
  )
}

export default AdvancedSearch
