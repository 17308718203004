import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import moment from 'moment'
import v4 from 'uuid/v4'
import getCurrentUrl from '../../../../_helpers/url-utils'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import { queryParamsActions, usaFinancialActions } from '../../../../_actions'
import { settingsConstants, usaFinancialConstants } from '../../../../_constants'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import { getUsaFinancialRows, getPage, getTotalItems } from '_selectors/use-financial.selector'

const { navMenu, statuses } = usaFinancialConstants

const statusRender = text => {
  return `${statuses.find(st => st.value.toString() === text.toString())?.text}`
}

const dateRender = date => (date ? moment(date).format('MM-DD') : '')

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    width: 80,
  },
  {
    title: 'Label',
    dataIndex: 'label',
    key: 'label',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: dateRender,
  },
  {
    title: 'Default Card ID',
    dataIndex: 'default_card_id',
    key: 'default_card_id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Sort Order',
    dataIndex: 'sort_order',
    key: 'sort_order',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: statusRender,
    filters: statuses,
  },
  {
    title: 'Statistic By Triggers (All / Enabled)',
    dataIndex: 'statistic_triggers',
    key: 'statistic_triggers',
    sorter: false,
    width: '10%',
  },
]

const navMenuInit = [navMenu.list, navMenu.create]

const ManageItems = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getUsaFinancialRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const deleteItems = async id => {
    await dispatch(usaFinancialActions.deleteItem(id))
  }

  const navigateToList = () => {
    dispatch({ type: usaFinancialConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}/list`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.list.key:
          return {
            ...menu,
            url: navigateToList,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const redirectToUsaFinancialTriggers = id => {
    dispatch(queryParamsActions.setQueryParams({ status: '1', trigger_id: id }))
    history.push({
      pathname: '/usa_financial_triggers',
    })
  }

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        default_card_id: item?.default_card_id ? (
          <Link to={`/cards/edit/${item.default_card_id}`}> {item.default_card_id} </Link>
        ) : (
          ''
        ),
        statistic_triggers: (
          <Button
            size="small"
            type="text"
            className="list-view-item-bold list-item-underline px-0"
            style={{ border: 'none' }}
            onClick={() => redirectToUsaFinancialTriggers(item.id)}
          >
            {item.total_triggers} / {item.total_active_triggers}
          </Button>
        ),
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  return (
    <NavContainer menu={navMenuParse}>
      <TableFilter
        deleteItems={deleteItems}
        columns={initColumns}
        data={dataSource}
        getDataAction={usaFinancialActions.getAll}
      />
    </NavContainer>
  )
}

export default ManageItems
