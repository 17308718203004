import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { Button, Checkbox, Form, Input, Modal, Select } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import { contextsActions, employeesActions } from '../../../../_actions'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { contextsConstants } from '../../../../_constants'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import useClientsToOptionsList from 'hooks/clients/useClientsToOptionsList'
import { getEmployeeRows } from '../../../../_selectors/employees.selector.js'

const { formItemLayout, navMenu } = contextsConstants

const navMenuInit = [navMenu.create, navMenu.view, navMenu.delete, navMenu.manage]

const EditItem = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const match = useRouteMatch()

  const view = getCurrentUrl(match.url)
  const [form] = Form.useForm()
  const { id } = useParams()

  const [navMenuParse, setNavMenuParse] = useState([])
  const [changed, setChange] = useState(false)

  const employees = useSelector(getEmployeeRows)
  const {
    item: { context, employees: employeesList, users: clientsList },
  } = useSelector(state => state.contexts)

  const clientsListOptions = useClientsToOptionsList(employeesActions.getAll, {
    order: { key: 'username', order: 'ASC' },
    where: { status: 1 },
  })

  useEffect(() => {
    dispatch(contextsActions.getItemById(id))
    return () => {
      dispatch({ type: contextsConstants.SETBYID })
    }
  }, [id])

  useEffect(() => {
    if (context) {
      const employeesListUserName = employeesList
        ? employeesList.map(employee => employee.email)
        : []
      const usersList = clientsList ? clientsList.map(user => user.id) : []
      form.setFieldsValue({ ...context, employee_ids: employeesListUserName, user_ids: usersList })
    }
  }, [context, form, employeesList, clientsList])

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToView,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  // //////////////////
  // Methods
  // /////////////////

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(contextsActions.deleteItem(id))
      },
    })
  }

  const navigateToView = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  // //////////////////
  // Form handlers
  // /////////////////

  const employeesListOptions = useMemo(() => {
    if (employees) {
      return employees.map(({ id, email }) => {
        return {
          key: id,
          value: email,
          label: email,
        }
      })
    }
    return []
  }, [employees])

  const onFinish = async values => {
    const { employee_ids } = values

    const employee_idsList = []

    employees.forEach(employee => {
      if (employee_ids.includes(employee.email)) {
        employee_idsList.push(employee.id)
      }
    })

    values.employee_ids = employee_idsList

    const mergedContext = {
      ...values,
      id: context.id,
    }
    const convertedContext = convertFormValuesDeep(mergedContext)
    await dispatch(contextsActions.updateItemById(context.id, convertedContext))
  }

  return (
    <NavContainer menu={navMenuParse}>
      {useGlobalLoader(
        <div>
          <p>Fields with * are required.</p>
          <Form
            {...formItemLayout}
            name="basic"
            form={form}
            onFinish={onFinish}
            onFieldsChange={() => setChange(true)}
            scrollToFirstError
          >
            <Form.Item label="Description" name="description">
              <Input.TextArea rows={5} />
            </Form.Item>

            <Form.Item label="Employees" name="employee_ids">
              <Select
                mode="multiple"
                size="large"
                placeholder="Please select employees or search by email"
                options={employeesListOptions}
                // value={selectedEmployees}
                // defaultValue={selectedEmployees}
              />
            </Form.Item>

            <Form.Item label="Clients" name="user_ids">
              <Select
                mode="multiple"
                size="large"
                placeholder="Please select clients or search by login"
                filterOption={(input, option) => option.label.includes(input.toString())}
                options={clientsListOptions}
              />
            </Form.Item>

            <Form.Item
              label="Group by Date on Batch"
              name="group_by_day_on_batch"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!changed}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>,
      )}
    </NavContainer>
  )
}

export default EditItem
