import React from 'react'
import { Card, Typography } from 'antd'
import { useHistory } from 'react-router-dom'

import StatusBar from './cron-job.status-bar'

const CronJobCard = ({ name, counts, total }) => {
  const history = useHistory()
  const redirectToDetails = () => history.push(`/cron-jobs/${name}`)

  return (
    <Card
      title={name}
      style={{ width: 350, cursor: 'pointer' }}
      bodyStyle={{ paddingInline: '1rem' }}
      headStyle={{ padding: '1rem' }}
      onClick={redirectToDetails}
    >
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <StatusBar counts={counts} />
        <Typography.Text>{total} Jobs</Typography.Text>
      </div>
    </Card>
  )
}

export default CronJobCard
