import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import v4 from 'uuid/v4'
import { Button } from 'antd'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import { activitiesActions, employeesActions } from '../../../../_actions'
import { employeesConstants } from '../../../../_constants'
import {
  getActivityPage,
  getActivityRows,
  getActivityTotalItems,
} from '../../../../_selectors/employees.selector.js'

const { navMenu, actionsFindLink } = employeesConstants

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
  },
  {
    title: 'Date Created',
    dataIndex: 'date_created',
    key: 'date_created',
    sorter: true,
  },
  {
    title: 'Controller',
    dataIndex: 'controller',
    key: 'controller',
    sorter: true,
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    sorter: true,
  },
  {
    title: 'Changed Column',
    dataIndex: 'changed_column',
    key: 'changed_column',
    sorter: true,
    render: text => text || '-',
  },
  {
    title: 'From Value',
    dataIndex: 'from_value',
    key: 'from_value',
    sorter: true,
    render: text => text || '-',
  },
  {
    title: 'To Value',
    dataIndex: 'to_value',
    key: 'to_value',
    sorter: true,
    render: text => text || '-',
  },
]

const navMenuInit = [navMenu.create, navMenu.manage, navMenu.listUser]

const ListActivity = ({ match }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const rows = useSelector(getActivityRows)
  const page = useSelector(getActivityPage)
  const total = useSelector(getActivityTotalItems)

  const deleteItems = async id => {
    await dispatch(employeesActions.deleteItem(id))
  }

  useEffect(() => {
    return () => {
      dispatch({ type: employeesConstants.SETALL })
    }
  }, [])

  const getLinkOfAction = (controller, action, objectID) => {
    const disabledLink = ['create', 'update']

    if (!actionsFindLink[controller] || !objectID || !disabledLink.includes(action)) return action

    return (
      <Button
        size="small"
        type="text"
        className="list-view-item-bold list-item-underline px-0"
        style={{ border: 'none', textTransform: 'lowercase' }}
        onClick={() =>
          history.push({ pathname: `/${actionsFindLink[controller]}/view/${objectID}` })
        }
      >
        {action}
      </Button>
    )
  }

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        action: getLinkOfAction(item.controller, item.action, item.object_id),
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  return (
    <NavContainer match={match} menu={navMenuInit}>
      <TableFilter
        deleteItems={deleteItems}
        columns={initColumns}
        data={dataSource}
        getDataAction={activitiesActions.getEmployeeActivity}
        actionsColum={false}
        isEditOnId={false}
        showAdvancedSearch={false}
        showAdvancedButton={!!true}
      />
    </NavContainer>
  )
}

export default ListActivity
