import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import ItemViewer from '../../../../components/item-viewer'
import { ordersActions } from '../../../../_actions'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { ordersConstants } from '../../../../_constants'

const { navMenu, viewItemKeyToLabel, refundStatus, orderStatuses } = ordersConstants
const navMenuInit = [navMenu.edit, navMenu.delete, navMenu.manage]
const ViewItem = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const { id } = useParams()
  const [navMenuParse, setNavMenuParse] = useState([])

  const {
    item: { order, fonts },
  } = useSelector(state => state.orders)

  const view = getCurrentUrl(match.url)
  useEffect(() => {
    dispatch(ordersActions.getItemById(id))
    return () => {
      dispatch({ type: ordersConstants.SETBYID })
    }
  }, [id, dispatch])
  const onEditItem = () => {
    history.push({
      pathname: `${view}/edit/${id}`,
    })
  }

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(ordersActions.deleteItem(id))
      },
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.edit.key:
          return {
            ...menu,
            url: onEditItem,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const viewItem = useMemo(() => {
    if (order) {
      if (!order.to) {
        delete viewItemKeyToLabel.postage
      }
      return Object.keys(viewItemKeyToLabel).reduce(
        (obj, sKey) => {
          const { changeBackground } = obj
          let itemValue = order[sKey]
          if (sKey === 'card') {
            itemValue = (
              <Link to={`/cards/edit/${order.card_id}`}>
                [ID: {order.card_id}] {order.card?.name} ({order.card?.category})
              </Link>
            )
          } else if (sKey === 'user') {
            itemValue = (
              <Link to={`/clients/edit/${order.user?.id}`}>
                [ID: {order.user?.id}] {order.user?.name}
              </Link>
            )
          } else if (sKey === 'refund_status') {
            itemValue = refundStatus?.find(item => item.value === order.refund_status)?.text
          } else if (sKey === 'employee') {
            itemValue = order.employee ? (
              <Link to={`/employees/edit/${order.employee?.id}`}>
                [ID: {order.employee?.id}] {order.employee?.name}
              </Link>
            ) : (
              'Not set'
            )
          } else if (sKey === 'invoiced') {
            itemValue = order.invoiced ? 'Invoice' : 'Credit card'
          } else if (sKey === 'tax_exempt') {
            itemValue = order.tax_exempt ? 'YES' : 'NO'
          } else if (sKey === 'for_free') {
            itemValue = order.for_free === 0 ? 'No' : 'Yes'
          } else if (sKey === 'size_offset') {
            itemValue = `${order.size_offset}%`
          } else if (sKey === 'gift_card') {
            if (order.gift_card) changeBackground.push({ value: sKey, class: 'blue-bg' })
            itemValue = order.gift_card ? (
              <Link to={`/gift-cards/edit/${order.gift_card.id}`}>
                [ID: {order.gift_card.id}] {order.gift_card.nominal} $ {order.gift_card.name}
              </Link>
            ) : (
              'Not set'
            )
            if (order.denomination_id) changeBackground.push({ value: sKey, class: 'blue-bg' })
          } else if (sKey === 'postage' && order?.to?.country_id !== 1) {
            changeBackground.push({ value: sKey, class: 'yellow-bg' })
          } else if (sKey === 'status') {
            itemValue = orderStatuses.find(status => status.value === order.status)?.text
          } else if (sKey === 'inserts') {
            const [insert] = order.inserts || [null]
            if (insert) {
              changeBackground.push({ value: sKey, class: 'blue-bg' })
              itemValue = (
                <Link to={`/inserts/edit/${insert.id}`}>
                  [ID: ${insert.id}] ${insert.name} $ ${insert.price}
                </Link>
              )
            } else {
              itemValue = '---'
            }
          } else if (sKey === 'font') {
            itemValue = order.font ? (
              <Link to={`/fonts/edit/${order.font}`}>
                [ID: {order.font}] {fonts.find(font => font.value === order.font)?.label}{' '}
              </Link>
            ) : (
              '---'
            )
          } else if (sKey === 'delivery_confirmation') {
            itemValue = order.delivery_confirmation ? (
              <>
                Confirmation status: {order.confirmation_status || '---'}
                <br />
                Mail phase: {order.mail_phase || '---'}
              </>
            ) : (
              'No'
            )
          }
          return {
            ...obj,
            [sKey]: itemValue,
            changeBackground,
          }
        },
        { changeBackground: [] },
      )
    }
    return {}
  }, [order])

  return (
    <NavContainer menu={navMenuParse}>
      <ItemViewer item={viewItem} keyToLabelMap={viewItemKeyToLabel} />
    </NavContainer>
  )
}
export default ViewItem
