import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { Button, Form, Input, Modal, Select } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import { templateActions, templateCategoriesActions as tcActions } from '../../../../_actions'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { templateConstants } from '../../../../_constants'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import { convertDataForSelect } from '../../../../_helpers'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import AdvancedCheckboxList from 'components/advanced-checkbox-list'
import { getTemplateCategoriesRows } from '_selectors/template-categories.selector'

const { navMenu, formItemLayout, statuses } = templateConstants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.view, navMenu.delete, navMenu.manage]

const EditItem = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { id } = useParams()

  const [navMenuParse, setNavMenuParse] = useState([])
  const [changed, setChange] = useState(false)
  const templateCategories = useSelector(getTemplateCategoriesRows)
  const {
    item: { template, user_groups: groups },
  } = useSelector(state => state.templates)

  const view = getCurrentUrl(match.url)

  const templateCategoriesSelect = useMemo(() => {
    return templateCategories?.map(template => ({ value: template.id, label: template.name }))
  }, [templateCategories])

  useEffect(() => {
    dispatch(tcActions.getAll())
  }, [dispatch])

  useEffect(() => {
    dispatch(templateActions.getItemById(id))
    return () => {
      dispatch({ type: templateConstants.SETBYID })
    }
  }, [id, dispatch])

  const navigateToView = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(templateActions.deleteItem(id))
      },
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToView,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  useEffect(() => {
    form.setFieldsValue({
      ...template,
      user_groups: groups?.filter(group => group.value).map(group => group.id),
    })
  }, [form, groups, template])

  const userGroups = useMemo(() => {
    return groups?.map(e => ({ value: e.id, label: e.name }))
  }, [groups])

  const onFinish = async values => {
    const convert = convertFormValuesDeep(values)
    await dispatch(templateActions.updateItemById(id, convert))
  }
  return (
    <NavContainer menu={navMenuParse}>
      {useGlobalLoader(
        <div>
          <p>Fields with * are required.</p>
          <Form
            {...formItemLayout}
            name="basic"
            form={form}
            onFinish={onFinish}
            onFieldsChange={() => setChange(true)}
            scrollToFirstError
          >
            <Form.Item label="Category" name="category_id">
              <Select options={templateCategoriesSelect} />
            </Form.Item>

            <Form.Item name="status" label="Status">
              <Select options={convertDataForSelect(statuses)} />
            </Form.Item>

            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input name!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Message"
              name="message"
              rules={[
                {
                  required: true,
                  message: 'Please input message!',
                },
              ]}
            >
              <Input.TextArea rows={5} />
            </Form.Item>

            <Form.Item label="Wishes" name="wishes">
              <Input.TextArea rows={3} />
            </Form.Item>

            <AdvancedCheckboxList
              formItemProps={{
                label: 'Customer Groups',
                name: 'user_groups',
                valuePropName: 'value',
              }}
              buttonText="Add Customer Group"
              options={userGroups}
              form={form}
              onChangeValues={() => setChange(true)}
              initialValues={groups?.filter(group => group.value).map(group => group.id)}
            />

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!changed}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>,
      )}
    </NavContainer>
  )
}

export default EditItem
