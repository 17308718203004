import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Button, Divider, Form, Input, InputNumber, Select, Tooltip } from 'antd'
import NavContainer from '../../../../components/nav-container'
import { insertsActions } from '../../../../_actions'
import getCurrentUrl from '../../../../_helpers/url-utils'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import { insertsConstants } from '../../../../_constants'
import { convertDataForSelect } from '_helpers'
import useValidationEmailForNotification from '../../../../hooks/clients/useValidationEmailForNotification.js'
import useClientsToOptionsList from 'hooks/clients/useClientsToOptionsList'

const { formItemLayout, navMenu, insertStatus, insertable } = insertsConstants

const navMenuInit = [navMenu.list, navMenu.manage]

const CreateItems = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const match = useRouteMatch()
  const view = getCurrentUrl(match.url)

  const user_id = useSelector(state => state.queryParams?.where?.user_id)
  const group_id = useSelector(state => state.queryParams?.where?.group_id)

  const { emailWarning, setEmailWarning, validateEmailList } = useValidationEmailForNotification()

  const clientsListOptions = useClientsToOptionsList()

  const onFinish = async values => {
    const convertedInsert = convertFormValuesDeep(values)
    await dispatch(insertsActions.addItem(convertedInsert))
    history.push({
      pathname: `${view}`,
    })
  }

  useEffect(() => {
    if (user_id) {
      form.setFieldsValue({
        user_id: user_id || '',
      })
    } else if (group_id) {
      form.setFieldsValue({
        group_id: group_id || '',
      })
    }
  }, [form, user_id])

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      <Form
        {...formItemLayout}
        name="editClientGroup"
        form={form}
        onFinish={onFinish}
        scrollToFirstError
      >
        {group_id && (
          <Form.Item label="Client Group" name="group_id">
            <Input />
          </Form.Item>
        )}
        <Form.Item name="status" label="Status" initialValue={1}>
          <Select options={convertDataForSelect(insertStatus)} />
        </Form.Item>
        {!group_id && (
          <Form.Item label="User" name="user_id">
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Select or search a user"
              optionFilterProp="children"
              filterOption={(input, option) => option.label.includes(input.toString())}
              rules={[
                {
                  required: true,
                  message: 'Please select User!',
                },
              ]}
              options={clientsListOptions}
            />
          </Form.Item>
        )}

        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Name cannot be blank!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="insertable"
          label="Insertable"
          initialValue="fully_insertable"
          rules={[
            {
              required: true,
              message: 'Please select option!',
            },
          ]}
        >
          <Select options={convertDataForSelect(insertable)} />
        </Form.Item>

        <Form.Item
          label="Price"
          name="price"
          rules={[
            {
              required: true,
              message: 'Price cannot be blank!',
            },
          ]}
        >
          <InputNumber step={0.01} min={0.0} />
        </Form.Item>

        <Form.Item label="Quantity" name="quantity" initialValue={0}>
          <InputNumber step={1} min={0} />
        </Form.Item>

        <Divider orientation="left">Low Stock Notifications</Divider>

        <Form.Item label="Low Stock Threshold" name="low_stock_threshold">
          <InputNumber step={1} min={0} />
        </Form.Item>

        <Form.Item label="Client Notification Emails">
          <Tooltip title="Wrong format. Check your value." open={emailWarning}>
            <Form.Item name="client_notification_emails" noStyle>
              <Input.TextArea
                rows={5}
                onFocus={() => setEmailWarning(false)}
                onBlur={validateEmailList}
                style={emailWarning ? { border: '1px solid #E9D502' } : {}}
              />
            </Form.Item>
          </Tooltip>
          <p style={{ fontSize: '13px' }}>
            * You can separate emails by writing them on a new line, use space or one of the special
            symbols like ";" or ","
          </p>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </NavContainer>
  )
}

export default CreateItems
