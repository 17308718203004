const prePayCreditsConstants = {
  GETALL_REQUEST: 'PREPAYCREDITS_GETALL_REQUEST',
  GETALL_SUCCESS: 'PREPAYCREDITS_GETALL_SUCCESS',
  GETALL_FAILURE: 'PREPAYCREDITS_GETALL_FAILURE',

  GETBYID_REQUEST: 'PREPAYCREDITS_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'PREPAYCREDITS_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'PREPAYCREDITS_GETBYID_FAILURE',

  ADD_REQUEST: 'PREPAYCREDITS_ADD_REQUEST',
  ADD_SUCCESS: 'PREPAYCREDITS_ADD_SUCCESS',
  ADD_FAILURE: 'PREPAYCREDITS_ADD_FAILURE',

  UPDATE_REQUEST: 'PREPAYCREDITS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'PREPAYCREDITS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'PREPAYCREDITS_UPDATE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    list: { key: 'list', title: 'List Pre-Pay Credits', url: 'credit_cards2/list' },
    create: { key: 'create', title: 'Create Pre-Pay Credits', url: '/credit_cards2/create' },
    manage: { key: 'manage', title: 'Manage Pre-Pay Credits', url: '/credit_cards2' },
    edit: { key: 'edit', title: 'Update Pre-Pay Credits ', url: '' },
    view: { key: 'view', title: 'View Pre-Pay Credits', url: '' },
  },

  advancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'User', key: 'user_id' },
    { label: 'Amount', key: 'amount' },
    { label: 'Expires At', key: 'expires_at*date' },
    { label: 'Created At', key: 'created_at*date' },
    { label: 'Updated At', key: 'updated_at*date' },
  ],

  formItemLayout: {
    labelCol: {
      xs: { span: 4 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 4 },
      sm: { span: 10 },
    },
  },

  listItemKeyToLabel: {
    id: 'ID',
    user: 'User',
    amount: 'Amount',
    expires_at: 'Expires At',
    created_at: 'Created At',
    updated_at: 'Updated At',
  },

  viewItemKeyToLabel: {
    id: 'ID',
    user: 'User',
    amount: 'Amount',
    expires_at: 'Expires At',
    created_at: 'Created At',
    updated_at: 'Updated At',
  },
}
export default prePayCreditsConstants
