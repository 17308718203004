import { multiStepConstants } from '../_constants'
import { multiStepService } from '../_services'

const getAll = (params, typeAction) => async dispatch => {
  dispatch({ type: multiStepConstants.GETALL_REQUEST })
  try {
    const multiStepCampaigns = await multiStepService.getAll(params, typeAction)
    dispatch({ type: multiStepConstants.GETALL_SUCCESS, payload: multiStepCampaigns.data })
  } catch (error) {
    dispatch({ type: multiStepConstants.GETALL_FAILURE, payload: error.toString() })
  }
}

const getItemById = id => async dispatch => {
  dispatch({ type: multiStepConstants.GETBYID_REQUEST })
  try {
    const multiStepCampaigns = await multiStepService.getItemById(id)
    dispatch({ type: multiStepConstants.GETBYID_SUCCESS, payload: multiStepCampaigns.data })
  } catch (error) {
    dispatch({ type: multiStepConstants.GETBYID_FAILURE, payload: error.toString() })
  }
}

const multiStepActions = {
  getAll,
  getItemById,
}

export default multiStepActions
