const mailsConstants = {
  GETALL_REQUEST: 'MAILS_GETALL_REQUEST',
  GETALL_SUCCESS: 'MAILS_SUCCESS',
  GETALL_FAILURE: 'MAILS_FAILURE',

  DELETE_REQUEST: 'MAILS_DELETE_REQUEST',
  DELETE_SUCCESS: 'MAILS_DELETE_SUCCESS',
  DELETE_FAILURE: 'MAILS_DELETE_FAILURE',

  RESEND_REQUEST: 'MAILS_RESEND_REQUEST',
  RESEND_SUCCESS: 'MAILS_RESEND_SUCCESS',
  RESEND_FAILURE: 'MAILS_RESEND_FAILURE',

  SETALL: 'SETALL',

  navMenu: {
    view: { key: 'view', title: 'View Client', url: '' },
    edit: { key: 'edit', title: 'Update Client', url: '' },
    list: { key: 'list', title: 'List Client', url: '/clients/list' },
    manage: { key: 'manage', title: 'Manage Client', url: '/clients' },
  },

  advancedSearch: [
    { label: 'ID', key: 'id'},
    { label: 'User', key: 'user_id' },
    { label: 'Date Created', key: 'date_created*date' },
    { label: 'Type', key: 'type'},
    { label: 'Subject', key: 'subject'},
    { label: 'Message', key: 'message' },
    { label: 'Order', key: 'order_id' },
    { label: 'Sent', key: 'sent' },
  ],

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },
}
export default mailsConstants
