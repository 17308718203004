const taxCodesConstants = {
  GETALL_REQUEST: 'TAX_CODE_GETALL_REQUEST',
  GETALL_SUCCESS: 'TAX_CODE_SUCCESS',
  GETALL_FAILURE: 'TAX_CODE_FAILURE',

  GETBYID_REQUEST: 'TAX_CODE_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'TAX_CODE_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'TAX_CODE_GETBYID_FAILURE',

  UPDATE_REQUEST: 'TAX_CODE_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'TAX_CODE_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'TAX_CODE_UPDATE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    list: { key: 'list', title: 'List TaxCode', url: '/taxcodes/list' },
    manage: { key: 'manage', title: 'Manage TaxCode', url: '/taxcodes' },
    edit: { key: 'edit', title: 'Update TaxCode', url: '' },
    view: { key: 'view', title: 'View TaxCode', url: '' },
    delete: { key: 'delete', title: 'Delete TaxCode', url: '' },
  },

  taxCodesServices: [
    { value: 'zamp', text: 'Zamp' },
    { value: 'avalar', text: 'Avalar' },
  ],

  taxCodesItems: [
    { value: 'CARD', text: 'Card' },
    { value: 'CARD_PROVIDED', text: 'Card Provided' },
    { value: 'POSTAGE_DOMESTIC', text: 'Postage Domestic' },
    { value: 'POSTAGE_INTL', text: 'Postage Intl' },
    { value: 'GIFT_CARD', text: 'Gift Card' },
    { value: 'INSERT', text: 'Inset' },
    { value: 'DELIVERY_CONFIRMATION', text: 'Delivery Confirmation' },
    { value: 'SHIPPING', text: 'Shipping' },
  ],

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },

  listItemKeyToLabel: {
    id: 'ID',
    name: 'Name',
    service: 'Service',
    tax_code: 'Tax Code',
    tax_item: 'Tax Item',
  },
  viewItemKeyToLabel: {
    id: 'ID',
    name: 'Name',
    service: 'Service',
    tax_code: 'Tax Code',
    tax_item: 'Tax Item',
  },
  advancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'Name', key: 'name' },
    { label: 'Service', key: 'service' },
    { label: 'Tax Code', key: 'tax_code' },
    { label: 'Tax Item', key: 'tax_item' },
  ],
}
export default taxCodesConstants
