const clientGroupConstants = {
  GETALL_REQUEST: 'CLIENT_GROUP_GETALL_REQUEST',
  GETALL_SUCCESS: 'CLIENT_GROUP_GETALL_SUCCESS',
  GETALL_FAILURE: 'CLIENT_GROUP_GETALL_FAILURE',

  GETBYID_REQUEST: 'CLIENT_GROUP_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'CLIENT_GROUP_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'CLIENT_GROUP_GETBYID_FAILURE',

  ADD_REQUEST: 'CLIENT_GROUP_ADD_REQUEST',
  ADD_SUCCESS: 'CLIENT_GROUP_ADD_SUCCESS',
  ADD_FAILURE: 'CLIENT_GROUP_ADD_FAILURE',

  DELETE_REQUEST: 'CLIENT_GROUP_DELETE_REQUEST',
  DELETE_SUCCESS: 'CLIENT_GROUP_DELETE_SUCCESS',
  DELETE_FAILURE: 'CLIENT_GROUP_DELETE_FAILURE',

  UPDATE_REQUEST: 'CLIENT_GROUP_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CLIENT_GROUP_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CLIENT_GROUP_UPDATE_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  LIMIT_CATEGORIES_PROP: 'limit_categories',
  LIMIT_DENOMINATIONS_PROP: 'limit_denominations',
  USER_GROUPS_PROP: 'user_groups',

  navMenu: {
    list: { key: 'list', title: 'List Client Group', url: '/client-groups/list' },
    create: { key: 'create', title: 'Create Client Group', url: '/client-groups/create' },
    manage: { key: 'manage', title: 'Manage Client Group', url: '/client-groups' },
    edit: { key: 'edit', title: 'Update Client Group', url: '' },
    delete: { key: 'delete', title: 'Delete Client Group', url: '' },
    view: { key: 'view', title: 'View Client Group', url: '' },
    inserts: { key: 'inserts', title: 'List Inserts', url: '/inserts/list' },
  },

  deliveryType: [
    { value: 0, text: 'NO' },
    { value: 1, text: 'YES' },
  ],

  layout: {
    wrapperCol: {
      span: 16,
    },
  },

  tailLayout: {
    wrapperCol: {
      span: 16,
    },
  },

  listItemKeyToLabel: {
    id: 'ID',
    title: 'Title',
    logo: 'Logo',
    no_delivery_confirmation: 'No Delivery Confirmation',
  },
  viewItemKeyToLabel: {
    id: 'ID',
    title: 'Title',
    logo_url: 'Logo',
    no_delivery_confirmation: 'No Delivery Confirmation',
    logo_image: 'Logo Image',
  },
  advancedSearch: [
    { label: 'ID', key: 'id' },
    { label: 'Title', key: 'title' },
  ],
}
export default clientGroupConstants
