import React, { useEffect, useMemo } from 'react'
import {
  Button,
  Divider,
  Form,
  InputNumber,
  Popconfirm,
  Radio,
  Space,
  Switch,
  Tooltip,
  Typography,
} from 'antd'
import { useDispatch } from 'react-redux'

import CuttingMarkupSection from './cutting-markup-section'
import { impositionLayoutsConstants } from '_constants'
import { inchToMm, mmToPx, pxToMm } from '_helpers/convert'
import { impositionHelpers } from '_helpers'

const ImpositionConfigDataForm = ({
  configId,
  config,
  isActiveTab,
  paperSize,
  cardDimensions,
  repeatModeData,
  isCardRotateDisabled,
}) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const barcodePosition = Form.useWatch('barcode_position', form)
  const cardRotate = Form.useWatch('card_rotate', form)
  const additionalBarcodeValue = Form.useWatch('additional_barcode', form)
  const sectionsCount = Form.useWatch('sections_count', form)

  const isRepeatMode = !!repeatModeData?.active

  const pageBarcodeSizeWithRotate = useMemo(() => {
    const size = {
      height: config.barcode.height,
      width: config.barcode.width,
    }

    if (config.barcode.rotate) {
      size.height = config.barcode.width
      size.width = config.barcode.height
    }

    return size
  }, [config])

  const maxBarcodeX = useMemo(() => {
    // if this is repeat mode - available width it's the first section width
    const availableWidth = isRepeatMode ? paperSize.width / sectionsCount : paperSize.width
    return inchToMm(availableWidth - pageBarcodeSizeWithRotate.width).toFixed(1)
  }, [paperSize, pageBarcodeSizeWithRotate, isRepeatMode, sectionsCount])

  const maxBarcodeY = useMemo(() => {
    return inchToMm(paperSize.height - pageBarcodeSizeWithRotate.height).toFixed(1)
  }, [paperSize, pageBarcodeSizeWithRotate])

  const allowedBarcodePositions = useMemo(() => {
    const { open_height, open_width } = cardDimensions
    const sizeWithRotate = {
      height: config.card_rotate ? +open_width : +open_height,
      width: config.card_rotate ? +open_height : +open_width,
    }

    if (sizeWithRotate.height > sizeWithRotate.width) {
      return ['top', 'bottom']
    }
    return ['left', 'right']
  }, [cardDimensions, config.card_rotate])

  const isConfigIncludesCards = useMemo(() => {
    return !!config?.front?.length
  }, [config?.front])

  useEffect(() => {
    if (config) {
      const additionalBarcode = config.front.find(elem => elem.type === 'code39')

      // if config includes additional barcode - need to set repeat mode as initial mode
      if (additionalBarcode && !isRepeatMode) {
        form.setFieldsValue({
          additional_barcode: additionalBarcode.value,
          sections_count: config.front.length / 2,
        })
        dispatch({
          type: impositionLayoutsConstants.SET_REPEAT_MODE,
          payload: {
            configId,
            active: true,
            barcode_value: additionalBarcode.value,
            sections_count: config.front.length / 2,
          },
        })
      }
    }
  }, [config, isRepeatMode])

  useEffect(() => {
    form.validateFields({ dirty: true })
  }, [isActiveTab])

  useEffect(() => {
    form.setFieldsValue({
      page_barcode_rotate: String(config.barcode.rotate),
    })
  }, [config.barcode])

  useEffect(() => {
    const currentBarcodePosition = form.getFieldValue('barcode_position')

    if (!allowedBarcodePositions.includes(currentBarcodePosition)) {
      const newPosition = allowedBarcodePositions[0]
      form.setFieldValue('barcode_position', newPosition)
      changeBarcodePositionHandler(newPosition, true)
    }
  }, [allowedBarcodePositions])

  const actualCardWidth = useMemo(() => {
    const { card_height, card_width } = cardDimensions

    let width = +cardRotate ? card_height : card_width

    // if barcode on left or right side - it means that card container is an 0.5in wider
    if (barcodePosition === 'left' || barcodePosition === 'right') {
      width += 0.5
    }

    return width
  }, [cardDimensions, barcodePosition])

  const maxSectionsCount = useMemo(() => {
    return Math.floor(paperSize.width / actualCardWidth)
  }, [actualCardWidth, paperSize.width, cardRotate])

  useEffect(() => {
    if (isRepeatMode) {
      // if current sections count is bigger than max - set max value instead
      if (sectionsCount > maxSectionsCount) {
        form.setFieldValue('sections_count', maxSectionsCount)
        sectionsCountChangeHandler(maxSectionsCount)
      }
    }
  }, [sectionsCount, isRepeatMode, maxSectionsCount])

  const changeBarcodePositionHandler = (value, ignoreEdit) => {
    dispatch({
      type: impositionLayoutsConstants.CHANGE_BARCODE_POSITION,
      payload: { configId, barcodePosition: value, isEdited: !ignoreEdit },
    })
  }

  const pageBarcodeChangeHandler = value => {
    dispatch({
      type: impositionLayoutsConstants.UPDATE_PAGE_BARCODE_VALUE,
      payload: { configId, value: value ? String(value) : null },
    })
  }

  const changePageBarcodeRotateHandler = e => {
    dispatch({
      type: impositionLayoutsConstants.UPDATE_PAGE_BARCODE_ROTATE,
      payload: { configId, rotate: +e.target.value },
    })
  }

  const changeCardRotateHandler = e => {
    dispatch({
      type: impositionLayoutsConstants.UPDATE_ELEMENT_ROTATE,
      payload: { configId, rotate: +e.target.value },
    })
  }

  const addNewCardHandler = () => {
    const newCardTemplate = {
      id: Date.now(),
      x: 0,
      y: 0,
      rotate: config.card_rotate,
      type: 'image',
    }
    dispatch({
      type: impositionLayoutsConstants.ADD_NEW_CARD,
      payload: { configId, newCard: newCardTemplate },
    })
  }

  const pageBarcodePositionHandler = position => {
    const x = typeof position.x === 'number' ? mmToPx(position.x) : config.barcode.x

    const y = typeof position.y === 'number' ? mmToPx(position.y) : config.barcode.y

    dispatch({
      type: impositionLayoutsConstants.UPDATE_PAGE_BARCODE_POSITION,
      payload: {
        configId,
        x,
        y,
      },
    })
  }

  const toggleRepeatMode = checked => {
    dispatch({
      type: impositionLayoutsConstants.SET_REPEAT_MODE,
      payload: {
        configId,
        active: checked,
        barcode_value: additionalBarcodeValue,
        sections_count: sectionsCount,
        actualCardWidth,
        paperWidth: +paperSize.width,
        cardPositions: impositionHelpers.getInitialRepeatCardsPositions(
          paperSize.width,
          sectionsCount,
          barcodePosition,
        ),
        additionalBarcodes: impositionHelpers.createAdditionalBarcodes(
          paperSize.width,
          sectionsCount,
          config.barcode,
        ),
      },
    })
  }

  const additionalBarcodeChangeHandler = value => {
    dispatch({
      type: impositionLayoutsConstants.SET_REPEAT_MODE,
      payload: { configId, active: true, barcode_value: value ? String(value) : null },
    })
  }

  const sectionsCountChangeHandler = value => {
    dispatch({
      type: impositionLayoutsConstants.SET_REPEAT_MODE,
      payload: {
        configId,
        active: true,
        sections_count: value,
        barcode_value: additionalBarcodeValue,
        cardPositions: impositionHelpers.getInitialRepeatCardsPositions(
          paperSize.width,
          value,
          barcodePosition,
        ),
        additionalBarcodes: impositionHelpers.createAdditionalBarcodes(
          paperSize.width,
          value,
          config.barcode,
        ),
      },
    })
  }

  return (
    <Form
      style={{ marginBottom: '1rem' }}
      name={`imposition_config_form_${configId}`}
      form={form}
      initialValues={{
        page_barcode: config.barcode.value,
        page_barcode_rotate: String(config.barcode.rotate),
        barcode_position: config.barcode_position,
        card_rotate: String(config.card_rotate),
        additional_barcode: 1,
        sections_count: 2,
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', gap: '3rem' }}>
        {/* --- COMMON FORM SECTION ---  */}
        <Space direction="vertical" style={{ width: '100%' }}>
          <Divider orientation="left">Page Barcode</Divider>

          <Form.Item
            label="Page Barcode"
            name="page_barcode"
            rules={[{ required: true, message: 'Please input barcode value' }]}
          >
            <InputNumber onChange={pageBarcodeChangeHandler} />
          </Form.Item>

          <Form.Item label="Page Barcode Rotate" name="page_barcode_rotate">
            <Radio.Group onChange={changePageBarcodeRotateHandler}>
              <Radio value="0">0deg</Radio>
              <Radio value="90">90deg</Radio>
              <Radio value="270">270deg</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item label="Page Barcode Coordinates (mm)" name="page_barcode_coordinates">
            <Space>
              <InputNumber
                prefix="X:"
                value={pxToMm(config.barcode.x).toFixed(1)}
                step={0.5}
                min={0}
                max={maxBarcodeX}
                onChange={v => pageBarcodePositionHandler({ x: v })}
              />

              <InputNumber
                prefix="Y:"
                value={pxToMm(config.barcode.y).toFixed(1)}
                step={0.5}
                min={0}
                max={maxBarcodeY}
                onChange={v => pageBarcodePositionHandler({ y: v })}
              />
            </Space>
          </Form.Item>

          <Divider orientation="left" style={{ marginTop: 0 }}>
            Card Barcode
          </Divider>

          <Form.Item label="Barcode Position" name="barcode_position">
            <Radio.Group onChange={e => changeBarcodePositionHandler(e.target.value)}>
              <Radio value="left" disabled={!allowedBarcodePositions.includes('left')}>
                Left
              </Radio>
              <Radio value="top" disabled={!allowedBarcodePositions.includes('top')}>
                Top
              </Radio>
              <Radio value="right" disabled={!allowedBarcodePositions.includes('right')}>
                Right
              </Radio>
              <Radio value="bottom" disabled={!allowedBarcodePositions.includes('bottom')}>
                Bottom
              </Radio>
            </Radio.Group>
          </Form.Item>

          <Divider orientation="left" style={{ marginTop: 0 }}>
            Card
          </Divider>

          <Tooltip
            title={isCardRotateDisabled ? 'You cannot rotate card while some card is locked' : ''}
          >
            <Form.Item label="Card Rotate" name="card_rotate">
              <Radio.Group onChange={changeCardRotateHandler} disabled={isCardRotateDisabled}>
                <Radio value="0">0deg</Radio>
                <Radio value="90">90deg</Radio>
                <Radio value="270">270deg</Radio>
              </Radio.Group>
            </Form.Item>
          </Tooltip>

          <Tooltip title={isRepeatMode ? 'You have only one card per section on repeat mode.' : ''}>
            <Button onClick={addNewCardHandler} disabled={isRepeatMode}>
              Add New Card
            </Button>
          </Tooltip>
        </Space>

        {/* --- REPEAT MODE SECTION ---  */}
        <Space direction="vertical" style={{ width: '100%' }}>
          <Divider orientation="left">
            <Space>
              <Popconfirm
                title="Toggle mode will reset all your current cards"
                description="Are you sure you want to change mode?"
                disabled={!isConfigIncludesCards}
                onConfirm={() => {
                  toggleRepeatMode(!isRepeatMode)
                }}
                okText="Yes"
                cancelText="No"
              >
                <Switch
                  value={isRepeatMode}
                  onChange={checked => {
                    if (isConfigIncludesCards) return

                    toggleRepeatMode(checked)
                  }}
                />
              </Popconfirm>
              <Typography.Title level={5} style={{ margin: 0 }}>
                Repeat Mode
              </Typography.Title>
            </Space>
          </Divider>

          <Space direction="vertical">
            <Space direction="vertical" style={{ opacity: isRepeatMode ? 1 : 0.5 }}>
              <Form.Item label="Additional Barcode" name="additional_barcode">
                <InputNumber
                  disabled={!isRepeatMode}
                  min={1}
                  onChange={additionalBarcodeChangeHandler}
                />
              </Form.Item>

              <Form.Item label="Sections Count" name="sections_count">
                <InputNumber
                  disabled={!isRepeatMode}
                  min={2}
                  max={maxSectionsCount}
                  onChange={sectionsCountChangeHandler}
                />
              </Form.Item>

              <Form.Item label="Maximum Number of Sections">
                <Typography.Text strong>{maxSectionsCount}</Typography.Text>
              </Form.Item>
            </Space>

            <CuttingMarkupSection
              config={config}
              cardDimensions={cardDimensions}
              paperSize={paperSize}
            />
          </Space>
        </Space>
      </div>
    </Form>
  )
}

export default ImpositionConfigDataForm
