import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import v4 from 'uuid/v4'
import getCurrentUrl from '../../../../_helpers/url-utils'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import { ShippingRatesActions } from '../../../../_actions'
import { settingsConstants, ShippingRatesConstants } from '../../../../_constants'
import { getShippingRatesRows, getPage, getTotalItems } from '_selectors/shipping-rates.selector'

const { ShippingRatesStatuses, navMenu, advancedSearch } = ShippingRatesConstants

const statusRender = text => {
  return `${ShippingRatesStatuses.find(st => st.value.toString() === text.toString())?.text}`
}

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Ups Code',
    dataIndex: 'ups_code',
    key: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Minimum fee',
    dataIndex: 'minimum_fee',
    key: 'minimum_fee',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Additional fee',
    dataIndex: 'additional_fee',
    key: 'additional_fee',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Earliest business days',
    dataIndex: 'earliest_business_days',
    key: 'earliest_business_days',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Extra day per num orders',
    dataIndex: 'extra_day_per_num_orders',
    key: 'extra_day_per_num_orders',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Plus Price per Card',
    dataIndex: 'per_card_fee',
    key: 'per_card_fee',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Markup',
    dataIndex: 'mark_up',
    key: 'mark_up',
    sorter: true,
    render: val => `${val * 100}%`,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Base',
    dataIndex: 'base',
    key: 'base',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: statusRender,
    filters: ShippingRatesStatuses,
    onFilter: (value, record) => record.status?.toString().indexOf(value) === 0,
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
]

const navMenuInit = [navMenu.list, navMenu.create]

const ManageItems = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)

  const navigateToList = () => {
    dispatch({ type: ShippingRatesConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}/list`,
    })
  }
  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getShippingRatesRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)
  const { can_edit_shipping_rates, superuser } = useSelector(state => state.authentication.user)

  const deleteItem = async id => {
    await dispatch(ShippingRatesActions.deleteItem(id))
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.list.key:
          return {
            ...menu,
            url: navigateToList,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  const statusesSelect = useMemo(() => {
    return ShippingRatesStatuses.map(status => ({ value: status.value, label: status.text }))
  }, [ShippingRatesStatuses])

  const updAdvancedSearch = advancedSearch.map(field => {
    if (field.key === 'status_filter') return { ...field, options: statusesSelect }
    return field
  })

  const canEdit = superuser || can_edit_shipping_rates

  return (
    <NavContainer match={match} menu={navMenuParse}>
      <TableFilter
        deleteItems={deleteItem}
        columns={initColumns}
        data={dataSource}
        getDataAction={ShippingRatesActions.getAll}
        isEditOnId={!!canEdit}
        advancedSearch={updAdvancedSearch}
      />
    </NavContainer>
  )
}

export default ManageItems
