import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { Button, Checkbox, Col, Form, Input, Modal, Row, Select, Tooltip } from 'antd'
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import { employeesActions } from '../../../../_actions'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { employeesConstants } from '../../../../_constants'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import { employeesService } from '../../../../_services'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'

const { navMenu, formItemLayout, checkbox, permissionsField } = employeesConstants

const navMenuInit = [
  navMenu.create,
  navMenu.view,
  navMenu.delete,
  navMenu.manage,
  navMenu.listUser,
  navMenu.activity,
  navMenu.password,
]

const EditItem = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const { id } = useParams()
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const [navMenuParse, setNavMenuParse] = useState([])
  const [changed, setChange] = useState(false)

  const { item } = useSelector(state => state.employees)

  const view = getCurrentUrl(match.url)

  useEffect(() => {
    dispatch(employeesActions.getItemById(id))
    return () => {
      dispatch({ type: employeesConstants.SETBYID })
    }
  }, [id, dispatch])

  const navigateToView = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  const onListActivity = () => {
    history.push({
      pathname: `${view}/activity/${id}`,
    })
  }

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(employeesActions.deleteItem(id))
      },
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToView,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        case navMenu.activity.key:
          return {
            ...menu,
            url: onListActivity,
          }
        case navMenu.password.key:
          return {
            ...menu,
            url: () => employeesService.resetPassword(item.email),
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit, item])

  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        ...item,
        notify_on_low_stock: !!item.notify_on_low_stock,
        notify_on_failed_purchase: !!item.notify_on_failed_purchase,
        permissions: Object.keys(item).filter(key => key.indexOf('can_') === 0 && item[key] === 1),
      })
    }
  }, [form, item])

  const onFinish = async values => {
    const newValue = {}

    Object.keys(values).forEach(item => {
      if (
        item !== 'permissions' &&
        item !== 'notify_on_low_stock' &&
        item !== 'notify_on_failed_purchase'
      )
        newValue[item] = values[item]
      if (item === 'notify_on_low_stock' || item === 'notify_on_failed_purchase')
        newValue[item] = values[item] ? 1 : 0
      if (item === 'permissions') {
        permissionsField.forEach(item => {
          newValue[item.value] = values.permissions.find(field => field === item.value) ? 1 : 0
        })
      }
    })
    await dispatch(employeesActions.updateItemById(id, convertFormValuesDeep(newValue)))
  }

  const changeToken = () => dispatch(employeesActions.reGenerateToken(id))

  const disableCopy = e => {
    e.preventDefault()
    return false
  }

  return (
    <NavContainer menu={navMenuParse}>
      {useGlobalLoader(
        <div>
          <p>Fields with * are required.</p>
          <Form
            {...formItemLayout}
            name="basic"
            form={form}
            onFinish={onFinish}
            onFieldsChange={() => setChange(true)}
            scrollToFirstError
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input username!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Password" name="password">
              <Input type="password" onCopy={disableCopy} />
            </Form.Item>

            <Form.Item label="Api Token">
              <Button onClick={changeToken}>Regenerate token</Button>
              <span style={{ marginLeft: '10px' }}>{item.api_token || 'Not set'}</span>
            </Form.Item>

            <Form.Item
              label="E-mail"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input E-mail!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Super User"
              name="superuser"
              rules={[
                {
                  required: true,
                  message: 'Please input Super User!',
                },
              ]}
            >
              <Select>
                <Select.Option value={0}>No</Select.Option>
                <Select.Option value={1}>Yes</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Status" name="status">
              <Select>
                <Select.Option value={-1}>Banned</Select.Option>
                <Select.Option value={0}>Not Active</Select.Option>
                <Select.Option value={1}>Active</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="First Name" name="first_name">
              <Input />
            </Form.Item>

            <Form.Item label="Last Name" name="last_name">
              <Input />
            </Form.Item>

            <Form.Item name="permissions" label="Permissions" valuePropName="value">
              <Checkbox.Group>
                <Row>
                  {permissionsField.map(item => (
                    <Col key={item.value} span={checkbox.span}>
                      <Checkbox value={item.value} style={checkbox.style}>
                        {item.label}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>

            <Form.Item
              label="Notify On Low Stock"
              name="notify_on_low_stock"
              valuePropName="checked"
            >
              <Checkbox style={checkbox.style} />
            </Form.Item>

            <Form.Item
              label="Notify On Failed Purchase"
              name="notify_on_failed_purchase"
              valuePropName="checked"
            >
              <Checkbox style={checkbox.style} />
              &nbsp;&nbsp;&nbsp;
              <Tooltip title="Notify to failed replenish prepaid credit">
                <InfoCircleOutlined style={{ color: '#4b7cf3' }} />
              </Tooltip>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!changed}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>,
      )}
    </NavContainer>
  )
}

export default EditItem
