import { wordMappingConstants } from '../_constants'

const initialState = {
  items: null,
  item: null,
}

function wordMapping(state = initialState, { type, payload }) {
  switch (type) {
    case wordMappingConstants.GETALL_SUCCESS:
      return { ...state, items: payload }
    case wordMappingConstants.GETBYID_SUCCESS:
      return { ...state, item: payload }
    case wordMappingConstants.DELETE_SUCCESS:
      return { ...state }
    case wordMappingConstants.UPDATE_SUCCESS:
      return { ...state, items: state.items }
    case wordMappingConstants.ADD_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          total: state.items.total + 1,
        },
      }
    case wordMappingConstants.SETBYID:
      return {
        ...state,
        item: null,
      }
    case wordMappingConstants.SETALL:
      return {
        ...state,
        items: null,
      }
    default:
      return state
  }
}

export default wordMapping
