import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import Cropper from 'react-cropper'
import { Button, Checkbox, Form, InputNumber, Select, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import convertFormValuesDeep from '_helpers/hw-object-utils'
import NavContainer from '../../../../components/nav-container'
import { cardImagesConstants } from '../../../../_constants'
import { convertDataForSelect } from '../../../../_helpers'
import { cardImagesActions, cardsActions } from '../../../../_actions'

import 'cropperjs/dist/cropper.css'
import { getCardById } from '../../../../_selectors/cards.selector.js'

const { formItemLayout, navMenu, cardImageType } = cardImagesConstants

const navMenuInit = [navMenu.list, navMenu.manage]

const CreateItem = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { id } = useParams()

  const card_id = useSelector(state => state.queryParams?.where?.card_id)

  const card = useSelector(getCardById)

  const [cardImage, setCardImage] = useState(null)
  const [cropper, setCropper] = useState()

  useEffect(() => {
    dispatch(cardsActions.getItemById(id))
  }, [id, dispatch])

  const normFile = e => {
    let file = e && e.fileList

    if (Array.isArray(e)) file = e

    const reader = new FileReader()
    reader.onload = () => setCardImage(reader.result)
    reader.readAsDataURL(file[0].originFileObj)

    return file
  }

  const props = {
    showUploadList: {
      showRemoveIcon: false,
    },
  }

  const onFinish = async values => {
    const newItem = {
      card_id: card_id || id,
      file: {
        name: values.file[0].name,
        thumbUrl: cardImage,
        size: values.file[0].size,
      },
      type: values.type,
      sort_no: values.sort_no,
    }

    if (values.crop) {
      const base64line = cropper.getCroppedCanvas().toDataURL()
      newItem.file.thumbUrl = base64line
      newItem.file.size = Math.round((base64line.length * 3) / 4)
    }

    await dispatch(cardImagesActions.addItem(convertFormValuesDeep(newItem)))
  }

  const changeType = type => {
    let aspect = null
    if (cropper && card && type) {
      const { closed_height, closed_width, open_height, open_width } = card
      if (type === 'front' || type === 'back') aspect = closed_width / closed_height
      if (type === 'inside') aspect = open_width / open_height
      cropper.setAspectRatio(aspect)
    }
    return aspect
  }

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      <Form {...formItemLayout} name="basic" form={form} onFinish={onFinish} scrollToFirstError>
        <Form.Item
          label="Image"
          name="file"
          valuePropName="file"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: 'Please input Image!',
            },
          ]}
        >
          <Upload
            {...props}
            name="icon"
            maxCount={1}
            accept="image/png, image/jpeg"
            beforeUpload={() => false}
            action=""
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item label="Crop" name="crop" valuePropName="checked">
          <Checkbox />
        </Form.Item>

        <Form.Item name="type" label="Type" initialValue="">
          <Select
            style={{ width: '140px' }}
            options={convertDataForSelect(cardImageType)}
            onChange={value => changeType(value)}
          />
        </Form.Item>

        <Form.Item label="Sort No" name="sort_no" initialValue={0}>
          <InputNumber />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>

      {cardImage && (
        <Cropper
          style={{ width: '100%', height: '635px' }}
          src={cardImage}
          aspectRatio={null}
          viewMode={2}
          autoCropArea={1}
          movable={false}
          rotatable={false}
          scalable={false}
          zoomable={false}
          toggleDragModeOnDblclick={false}
          onInitialized={instance => setCropper(instance)}
        />
      )}
    </NavContainer>
  )
}

export default CreateItem
