import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Form, Input } from 'antd'
import { userActions } from '_actions'
import NavContainer from '../../../../components/nav-container'
import { userConstants } from '../../../../_constants'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'

const { formItemLayout, navMenu } = userConstants

const navMenuInit = [navMenu.list, navMenu.profile, navMenu.password, navMenu.logout]

const EditItem = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const [navMenuParse, setNavMenuParse] = useState([])
  const [changed, setChange] = useState(false)
  const authUserId = useSelector(state => state.authentication.user.id)
  const user = useSelector(state => state.users.user)

  useEffect(() => {
    dispatch(userActions.getUserById(authUserId))
    return () => {
      dispatch({ type: userConstants.SETBYID })
    }
  }, [])

  const logOut = () => {
    dispatch(userActions.logout())
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.logout.key:
          return {
            ...menu,
            url: logOut,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  useEffect(() => {
    form.setFieldsValue({
      ...user,
    })
  }, [user, form])

  const onFinish = async values => {
    await dispatch(userActions.updateUser(values, user.id))
  }

  return (
    <NavContainer menu={navMenuParse}>
      {useGlobalLoader(
        <div>
          <p>Fields with * are required.</p>
          <Form
            {...formItemLayout}
            name="editClientGroup"
            form={form}
            onFinish={onFinish}
            onFieldsChange={() => setChange(true)}
            scrollToFirstError
          >
            <Form.Item label="First Name" name="first_name">
              <Input />
            </Form.Item>
            <Form.Item label="Last Name" name="last_name">
              <Input />
            </Form.Item>

            <Form.Item
              label="username"
              name="username"
              rules={[
                {
                  required: true,
                  message: 'username cannot be blank',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="E-mail"
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'email cannot be blank',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!changed}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>,
      )}
    </NavContainer>
  )
}

export default EditItem
