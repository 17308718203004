import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import v4 from 'uuid/v4'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'

import getCurrentUrl from '../../../../_helpers/url-utils'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import { categoriesActions } from '../../../../_actions'
import { categoriesConstants, settingsConstants } from '../../../../_constants'
import {
  getPage,
  getCategoriesRows,
  getTotalItems,
} from '../../../../_selectors/categories.selector.js'

const { navMenu, advancedSearch } = categoriesConstants

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '10%',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '20%',
  },
  {
    title: 'Slug',
    dataIndex: 'slug',
    key: 'slug',
    width: '20%',
  },
  {
    title: 'Meta title',
    dataIndex: 'meta_title',
    key: 'meta_title',
    ellipsis: {
      showTitle: false,
    },
    maxWidth: '50px',
    render: title => (
      <Tooltip placement="topLeft" title={title}>
        <div
          style={{
            maxWidth: '120px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {title}
        </div>
      </Tooltip>
    ),
  },
  {
    title: 'Meta description',
    dataIndex: 'meta_description',
    key: 'meta_description',
    ellipsis: {
      showTitle: false,
    },
    maxWidth: '50px',
    render: description => (
      <Tooltip placement="topLeft" title={description}>
        <div
          style={{
            maxWidth: '120px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {description}
        </div>
      </Tooltip>
    ),
  },
  {
    title: 'Client Groups',
    dataIndex: 'user_groups',
    key: 'user_groups',
    width: '20%',
    hideFilter: true,
  },
]

const navMenuInit = [navMenu.list, navMenu.create]

const ManageItems = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getCategoriesRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const deleteItems = async id => {
    await dispatch(categoriesActions.deleteItem(id))
  }

  const navigateToList = () => {
    dispatch({ type: categoriesConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}/list`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.list.key:
          return {
            ...menu,
            url: navigateToList,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        user_groups: item.user_groups.map(group => (
          <Link key={group.id} to={`/client-groups/edit/${group.id}`}>
            {group.title}
            <br />
          </Link>
        )),
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  return (
    <NavContainer match={match} menu={navMenuParse}>
      <TableFilter
        deleteItems={deleteItems}
        columns={initColumns}
        data={dataSource}
        getDataAction={categoriesActions.getAll}
        advancedSearch={advancedSearch}
      />
    </NavContainer>
  )
}

export default ManageItems
