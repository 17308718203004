import axios from 'axios'
import serialize from '_helpers/hw-serialize-params'
import apiUrl from './service'
import { v1 } from './api'

const getAll = async (params, typeAction) => {
  const result = await axios.get(`${apiUrl}/${v1}/shopify?${serialize(params)}`, {
    params: { stopSpinner: typeAction },
  })
  return handleResponse(result)
}

const getCountOfIssuesShops = async (params, typeAction) => {
  const result = await axios.get(`${apiUrl}/${v1}/shopify/count?${serialize(params)}`, {
    params: { stopSpinner: typeAction },
  })
  return handleResponse(result)
}

const getIssuesShops = async (params, typeAction) => {
  const result = await axios.get(`${apiUrl}/${v1}/shopify/issues?${serialize(params)}`, {
    params: { stopSpinner: typeAction },
  })
  return handleResponse(result)
}

const reSubscribeShop = async (params, typeAction) => {
  const result = await axios.post(`${apiUrl}/${v1}/shopify/resubscribe?${serialize(params)}`, {
    params: { stopSpinner: typeAction },
  })
  return handleResponse(result)
}

const handleResponse = result => {
  return result.data
}

const shopifyService = {
  getAll,
  getCountOfIssuesShops,
  getIssuesShops,
  reSubscribeShop,
}

export default shopifyService
