import axios from 'axios'

import serialize from '_helpers/hw-serialize-params'
import apiUrl from './service'
import { v1 } from './api'

const getAll = async (params, typeAction) => {
  const result = await axios.get(`${apiUrl}/${v1}/automations?${serialize(params)}`, {
    params: { stopSpinner: typeAction },
  })
  return handleResponse(result)
}

const getItemById = async id => {
  const result = await axios.get(`${apiUrl}/${v1}/automations/${id}`)
  return handleResponse(result)
}

const getAutomationOrders = async (params, typeAction) => {
  const result = await axios.get(`${apiUrl}/${v1}/orders?${serialize(params)}`, {
    params: { stopSpinner: typeAction },
  })
  return handleResponse(result)
}

const handleResponse = result => {
  return result.data
}

const automationService = {
  getAll,
  getItemById,
  getAutomationOrders,
}

export default automationService
