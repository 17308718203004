import axios from 'axios'
import apiUrl from './service'
import { v1 } from './api'
import downloader from '../_helpers/file-download'
import serialize from '_helpers/hw-serialize-params'

const readyToShipping = async (params, typeAction) => {
  const result = await axios.get(
    `${apiUrl}/${v1}/batch_orders/labels/v2/remaining/?${serialize(params)}`,
    {
      params: { stopSpinner: typeAction },
    },
  )
  return handleResponse(result)
}

const archivedLabels = async (params, typeAction) => {
  const result = await axios.get(
    `${apiUrl}/${v1}/batch_orders/labels/v2/archive/?${serialize(params)}`,
    {
      params: { stopSpinner: typeAction },
    },
  )
  return handleResponse(result)
}

const generateLabelsV2 = async params => {
  const result = await axios.post(
    `${apiUrl}/${v1}/batch_orders/labels/v2/generate`,
    JSON.stringify(params),
    { params: { stopSpinner: 'background' } },
  )
  return handleResponse(result) || result
}

const printLabelsV2 = async params => {
  const result = await axios.post(
    `${apiUrl}/${v1}/batch_orders/labels/v2/print`,
    JSON.stringify(params),
    { responseType: 'blob', params: { stopSpinner: 'background' } },
  )

  if (result.data?.staticMessages) {
    return
  }

  downloader(result.data, `${result.headers['file-name']}.pdf`)
}

const completeLabelsV2 = async params => {
  const result = await axios.post(
    `${apiUrl}/${v1}/batch_orders/labels/v2/complete`,
    JSON.stringify(params),
    { params: { stopSpinner: 'background' } },
  )
  return handleResponse(result)
}

const cancelLabelsV2 = async params => {
  const result = await axios.post(
    `${apiUrl}/${v1}/batch_orders/labels/v2/cancel`,
    JSON.stringify(params),
    { params: { stopSpinner: 'background' } },
  )
  return handleResponse(result)
}

const getServiceOptions = async params => {
  const result = await axios.get(
    `${apiUrl}/${v1}/batch_orders/labels/v2/service?${serialize(params)}`,
    { params: { stopSpinner: 'background' } },
  )
  return handleResponse(result)
}

const statusLabelsV2 = async () => {
  const result = await axios.get(`${apiUrl}/${v1}/batch_orders/labels/v2/status`, {
    params: { stopSpinner: 'background' },
  })
  return handleResponse(result)
}

const handleResponse = result => {
  return result.data
}

const labelsCreationService = {
  readyToShipping,
  archivedLabels,
  generateLabelsV2,
  printLabelsV2,
  completeLabelsV2,
  cancelLabelsV2,
  statusLabelsV2,
  getServiceOptions,
}

export default labelsCreationService
