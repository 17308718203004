import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { Button, Checkbox, Form, Input, InputNumber, Modal, Select } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { merge } from 'lodash'
import NavContainer from '../../../../components/nav-container'
import { clientGroupActions, discountsCodesActions as dcActions } from '../../../../_actions'
import getCurrentUrl from '../../../../_helpers/url-utils'
import convertFormValuesDeep from '../../../../_helpers/hw-object-utils'
import { discountsCodesConstants } from '../../../../_constants'
import { convertDataForSelect } from '../../../../_helpers'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import { getClientGroupsRows } from '../../../../_selectors/client-group.selector.js'

const { formItemLayout, navMenu, itemTypes } = discountsCodesConstants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.view, navMenu.delete, navMenu.manage]

const EditItem = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { id } = useParams()

  const [navMenuParse, setNavMenuParse] = useState([])
  const [changed, setChange] = useState(false)
  const {
    item: { discount_code: code },
  } = useSelector(state => state.discountCodes)

  const clientGroups = useSelector(getClientGroupsRows)

  const view = getCurrentUrl(match.url)

  const clientGroupsSelect = useMemo(() => {
    return clientGroups?.map(group => ({ value: group.id, label: group.title }))
  }, [clientGroups])

  useEffect(() => {
    dispatch(clientGroupActions.getAll())
  }, [])

  useEffect(() => {
    dispatch(dcActions.getItemById(id))
    return () => {
      dispatch({ type: discountsCodesConstants.SETBYID })
    }
  }, [id, dispatch])

  const navigateToView = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(dcActions.deleteItem(id))
      },
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToView,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  useEffect(() => {
    form.setFieldsValue({ ...code })
  }, [code, form])

  const onFinish = async values => {
    const mCode = merge(code, values)
    const convertedCode = convertFormValuesDeep(mCode)
    await dispatch(dcActions.updateItemById(code.id, convertedCode))
  }

  return (
    <NavContainer menu={navMenuParse}>
      {useGlobalLoader(
        <div>
          <p>Fields with * are required.</p>
          <Form
            {...formItemLayout}
            name="basic"
            form={form}
            onFinish={onFinish}
            onFieldsChange={() => setChange(true)}
            scrollToFirstError
          >
            <Form.Item
              label="Code"
              name="code"
              rules={[
                {
                  required: true,
                  message: 'Please input code!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Domain Regex" name="domain_regex">
              <Input placeholder="[\.@]example.com" />
            </Form.Item>

            <Form.Item label="Limit Redemptions" name="users_limit">
              <InputNumber step={1} min={0} placeholder="0 - unlimited" />
            </Form.Item>

            <Form.Item label="Credit " name="credit">
              <InputNumber step={0.01} min={1.0} />
            </Form.Item>

            <Form.Item name="type" label="Type">
              <Select options={convertDataForSelect(itemTypes)} />
            </Form.Item>

            <Form.Item label="Invoiced" name="invoiced" valuePropName="checked">
              <Checkbox name="invoiced" />
            </Form.Item>

            <Form.Item name="group_id" label="Client Group">
              <Select
                showSearch
                options={clientGroupsSelect}
                allowClear
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!changed}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>,
      )}
    </NavContainer>
  )
}

export default EditItem
