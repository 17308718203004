import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router'
import getCurrentUrl from '../../../../_helpers/url-utils'
import NavContainer from '../../../../components/nav-container'
import ListViewer from '../../../../components/list-viewer'
import { prePaidConfigsActions } from '../../../../_actions'
import { prePaidConfigsConstants, settingsConstants } from '../../../../_constants'
import { getPrePaidConfigsRows, getPage, getTotalItems } from '_selectors/pre-paid-configs.selector'

const { navMenu, listItemKeyToLabel, statuses } = prePaidConfigsConstants
const navMenuInit = [navMenu.create, navMenu.manage]

const ListItems = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])
  const rows = useSelector(getPrePaidConfigsRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const navigateToManage = () => {
    dispatch({ type: prePaidConfigsConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.manage.key:
          return {
            ...menu,
            url: navigateToManage,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const listItems = useMemo(() => {
    if (rows?.length > 0) {
      return {
        total,
        page,
        rows: rows?.map(item => {
          const res = []
          Object.keys(listItemKeyToLabel).forEach(sKey => {
            const itemLine = { label: listItemKeyToLabel[sKey], value: item[sKey], key: sKey }
            if (sKey === 'id') {
              itemLine.clickHandler = () => {
                history.push({ pathname: `/pre-paid-configs/view/${item[sKey]}` })
              }
            } else if (sKey === 'status') {
              itemLine.value =
                statuses.find(status => status.value.toString() === item.status.toString())?.text ||
                'Unknown Status'
            } else if (sKey === 'discount') {
              itemLine.value = `${Number(item.discount).toFixed()}%`
            }
            res.push(itemLine)
          })
          return res
        }),
      }
    }
    return { rows: [], total, page }
  }, [rows, total, page])

  return (
    <NavContainer match={match} menu={navMenuParse}>
      <ListViewer data={listItems} getDataAction={prePaidConfigsActions.getAll} />
    </NavContainer>
  )
}

export default ListItems
