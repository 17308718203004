import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Form, Input, InputNumber, Select } from 'antd'

import NavContainer from '../../../../components/nav-container'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import { convertDataForSelect } from '_helpers'

import { impositionLayoutsActions } from '../../../../_actions'
import { impositionLayoutsConstants } from '../../../../_constants'

const { formItemLayout, navMenu, layoutStatuses } = impositionLayoutsConstants
const navMenuInit = [navMenu.manage]

const CreateItem = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const onFinish = async values => {
    await dispatch(impositionLayoutsActions.addItem(values))
  }

  return (
    <NavContainer menu={navMenuInit}>
      <p>Fields with * are required.</p>
      {useGlobalLoader(
        <Form
          {...formItemLayout}
          initialValues={{ status: 1 }}
          name="basic"
          form={form}
          onFinish={onFinish}
          scrollToFirstError
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input title!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Paper Height"
            name="height"
            rules={[
              {
                required: true,
                message: 'Please fill the field!',
              },
            ]}
          >
            <InputNumber min={1} />
          </Form.Item>

          <Form.Item
            label="Paper Width"
            name="width"
            rules={[
              {
                required: true,
                message: 'Please fill the field!',
              },
            ]}
          >
            <InputNumber min={1} />
          </Form.Item>

          <Form.Item name="status" label="Status">
            <Select options={convertDataForSelect(layoutStatuses)} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>,
      )}
    </NavContainer>
  )
}

export default CreateItem
