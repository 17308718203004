import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Form, Input, Modal } from 'antd'
import { useParams } from 'react-router'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import { wordMappingActions } from '../../../../_actions'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { convertFormValuesDeep } from '../../../../_helpers'
import { wordMappingConstants } from '../../../../_constants'
import useGlobalLoader from '../../../../hooks/loader/useGlobalLoader.js'
import { getWordMapp } from '../../../../_selectors/word-mapping.selector.js'

const { formItemLayout, navMenu } = wordMappingConstants

const navMenuInit = [navMenu.list, navMenu.create, navMenu.view, navMenu.delete, navMenu.manage]

const EditItem = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const { id } = useParams()
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const [navMenuParse, setNavMenuParse] = useState([])
  const [changed, setChange] = useState(false)

  const wordMap = useSelector(getWordMapp)

  const view = getCurrentUrl(match.url)

  const abbreviationIsAllowed = Form.useWatch('allow_replace', form)

  useEffect(() => {
    dispatch(wordMappingActions.getItemById(id))
    return () => {
      dispatch({ type: wordMappingConstants.SETBYID })
    }
  }, [id, dispatch])

  const navigateToView = () => {
    history.push({
      pathname: `${view}/view/${id}`,
    })
  }

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(wordMappingActions.deleteItem(id))
      },
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.view.key:
          return {
            ...menu,
            url: navigateToView,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  useEffect(() => {
    form.setFieldsValue({
      ...wordMap,
    })
  }, [form, wordMap])

  const onFinish = async values => {
    await dispatch(wordMappingActions.updateItemById(id, convertFormValuesDeep(values)))
  }

  return (
    <NavContainer menu={navMenuParse}>
      {useGlobalLoader(
        <div>
          <p>Fields with * are required.</p>
          <Form
            {...formItemLayout}
            name="basic"
            form={form}
            onFinish={onFinish}
            onFieldsChange={() => setChange(true)}
            scrollToFirstError
          >
            <Form.Item
              label="Word"
              name="full_word"
              rules={[
                {
                  required: true,
                  message: 'Please input break word!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Allow Split Address Line" name="allow_split" valuePropName="checked">
              <Checkbox name="allow_split" />
            </Form.Item>

            <Form.Item label="Allow Abbreviation" name="allow_replace" valuePropName="checked">
              <Checkbox name="allow_replace" />
            </Form.Item>

            {abbreviationIsAllowed ? (
              <Form.Item
                label="Abbreviation"
                name="short_word"
                rules={[
                  {
                    required: true,
                    message: 'Please input abbreviation!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            ) : null}

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!changed}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>,
      )}
    </NavContainer>
  )
}

export default EditItem
