import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const useEditPageUnload = preventUnload => {
  const history = useHistory()

  useEffect(() => {
    const onUnload = event => {
      event.preventDefault()
      event.returnValue = ''
    }
    if (preventUnload) {
      window.addEventListener('beforeunload', onUnload)

      return () => {
        window.removeEventListener('beforeunload', onUnload)
      }
    }
  }, [preventUnload])

  useEffect(() => {
    const unblock = history.block(() => {
      if (preventUnload) {
        return 'Are you sure you want to leave? Changes you made may not be saved.'
      }
    })

    return () => {
      unblock()
    }
  }, [history, preventUnload])
}

export default useEditPageUnload
