const shopifyConstants = {
  GETALL_REQUEST: 'SHOPIFY_GETALL_REQUEST',
  GETALL_SUCCESS: 'SHOPIFY_SUCCESS',
  GETALL_FAILURE: 'SHOPIFY_FAILURE',

  GETISSUES_REQUEST: 'SHOPIFY_ISSUES_REQUEST',
  GETISSUES_SUCCESS: 'SHOPIFY_ISSUE_SSUCCESS',
  GETISSUES_FAILURE: 'SHOPIFY_ISSUE_SFAILURE',

  GETISSUES_ITEMS_REQUEST: 'GETISSUES_ITEMS_GETALL_REQUEST',
  GETISSUES_ITEMS_SUCCESS: 'GETISSUES_ITEMS_SSUCCESS',
  GETISSUES_ITEMS_FAILURE: 'GETISSUES_ITEMS_FAILURE',

  GETBYID_REQUEST: 'SHOPIFY_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'SHOPIFY_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'SHOPIFY_GETBYID_FAILURE',

  RESUBSCRIBE_SHOP_REQUEST: 'RESUBSCRIBE_SHOP_REQUEST',
  RESUBSCRIBE_SHOP_SUCCESS: 'RESUBSCRIBE_SHOP_SUCCESS',
  RESUBSCRIBE_SHOP_FAILURE: 'RESUBSCRIBE_SHOP_FAILURE',

  SETBYID: 'SETBYID',
  SETALL: 'SETALL',

  navMenu: {
    list: { key: 'list', title: 'List Issues', url: '/shopify/list' },
    manage: { key: 'manage', title: 'Manage Shopify', url: '/shopify' },
    view: { key: 'view', title: 'View Shopify', url: '' },
  },

  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },

  listItemKeyToLabel: {
    id: 'ID',
    user_id: 'User',
    myshopify_domain: 'Shop',
    shopify_triggers: 'Shopify Triggers',
    count_of_triggers: 'Count of trigger',
  },
  viewItemKeyToLabel: {
    id: 'ID',
    name: 'Name',
    aliases: 'Aliases',
    delivery_cost: 'Delivery Cost',
  },
  // advancedSearch: [
  //   { label: 'ID', key: 'id' },
  //   { label: 'Name', key: 'name' },
  //   { label: 'Delivery Cost', key: 'delivery_cost' }
  // ],
}
export default shopifyConstants
