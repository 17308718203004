import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { Image, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import NavContainer from '../../../../components/nav-container'
import ItemViewer from '../../../../components/item-viewer'
import getCurrentUrl from '../../../../_helpers/url-utils'

import { clientGroupActions } from '../../../../_actions'
import { appConstants, clientGroupConstants } from '../../../../_constants'

const { viewItemKeyToLabel, navMenu } = clientGroupConstants

const navMenuInit = [
  navMenu.list,
  navMenu.create,
  navMenu.edit,
  navMenu.delete,
  navMenu.manage,
  navMenu.inserts,
]

const ViewItem = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const { id } = useParams()
  const dispatch = useDispatch()

  const [navMenuParse, setNavMenuParse] = useState([])
  const view = getCurrentUrl(match.url)

  const {
    item: { user_groups: userGroup },
  } = useSelector(state => state.clientGroups)

  const onEditItem = () => {
    history.push({
      pathname: `${view}/edit/${id}`,
    })
  }

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Delete Item',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this item',
      okText: 'Delete',
      okButtonProps: { type: 'danger' },
      cancelText: 'Cancel',
      onOk: async () => {
        await dispatch(clientGroupActions.deleteItem(id))
      },
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.edit.key:
          return {
            ...menu,
            url: onEditItem,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: onDeleteItem,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  useEffect(() => {
    dispatch(clientGroupActions.getItemById(id))
    return () => {
      dispatch({ type: clientGroupConstants.SETBYID })
    }
  }, [dispatch, id])

  const viewItem = useMemo(() => {
    if (userGroup) {
      return Object.keys(viewItemKeyToLabel).reduce((obj, sKey) => {
        let itemValue
        if (userGroup.logo_url && sKey === 'logo_image') {
          itemValue = (
            <Image
              width={44}
              fallback={appConstants.FALLBACK_IMAGE}
              src={`${appConstants.IMAGES_URL}/clientGroupImages/${userGroup.logo_url}`}
              preview={false}
            />
          )
        } else if (sKey === 'no_delivery_confirmation') {
          itemValue = `${!userGroup[sKey] ? 'NO' : 'YES'}`
        } else {
          itemValue = userGroup[sKey]
        }

        return {
          ...obj,
          [sKey]: itemValue,
        }
      }, {})
    }
    return {}
  }, [userGroup])

  return (
    <NavContainer menu={navMenuParse}>
      <ItemViewer item={viewItem} keyToLabelMap={viewItemKeyToLabel} />
    </NavContainer>
  )
}

export default ViewItem
