import { notesConstants } from '_constants'

const initialState = {
  items: [],
  item: {},
  count: 0,
}

function clientNotes(state = initialState, { type, payload }) {
  switch (type) {
    case notesConstants.GETALL_SUCCESS:
      return { ...state, items: payload, item: {} }
    case notesConstants.CREATE_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          total: state.items.total + 1,
        },
      }
    case notesConstants.GETBYID_SUCCESS: {
      return { ...state, item: { ...state.item, ...payload } }
    }
    case notesConstants.UPDATE_SUCCESS:
      return { ...state, items: state.items }
    case notesConstants.SETBYID:
      return {
        ...state,
        item: {},
      }
    case notesConstants.GETCOUNT_SUCCESS:
      return {
        ...state,
        count: payload,
      }
    case notesConstants.SETALL:
      return {
        ...state,
        items: [],
      }
    case notesConstants.SETCOUNT:
      return {
        ...state,
        count: 0,
      }
    case notesConstants.DELETE_SUCCESS:
      return { ...state }
    default:
      return state
  }
}

export default clientNotes
