import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import v4 from 'uuid/v4'
import { Button } from 'antd'
import getCurrentUrl from '../../../../_helpers/url-utils'
import TableFilter from '../../../../components/table-filter'
import NavContainer from '../../../../components/nav-container'
import { discountsCodesActions as dcActions, queryParamsActions } from '../../../../_actions'
import { discountsCodesConstants, settingsConstants } from '../../../../_constants'
import { getIsSuperUser } from '../../../../_selectors/authentication.selector.js'
import {
  getPage,
  getTotalItems,
  getDiscountCodeRows,
} from '../../../../_selectors/discount-codes.selector.js'

const { navMenu, advancedSearch } = discountsCodesConstants

const initColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Credit',
    dataIndex: 'credit',
    key: 'credit',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Clients',
    dataIndex: 'count',
    key: 'count',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Limit Redemptions',
    dataIndex: 'users_limit',
    key: 'users_limit',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
]

const navMenuInit = [navMenu.list, navMenu.create]

const ManageItems = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const view = getCurrentUrl(match.url)

  const [navMenuParse, setNavMenuParse] = useState([])

  const rows = useSelector(getDiscountCodeRows)
  const page = useSelector(getPage)
  const total = useSelector(getTotalItems)

  const superuser = useSelector(getIsSuperUser)
  const { can_edit_discount_codes } = useSelector(state => state.authentication.user)

  const deleteItems = async id => {
    await dispatch(dcActions.deleteItem(id))
  }

  const navigateToList = () => {
    dispatch({ type: discountsCodesConstants.SETALL })
    dispatch({ type: settingsConstants.SET_MANAGE_PAGE_ACTION, payload: null })
    history.push({
      pathname: `${view}/list`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.list.key:
          return {
            ...menu,
            url: navigateToList,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit])

  const redirectToClients = discount_code => {
    dispatch(queryParamsActions.setQueryParams({ discount_code }))
    history.push({
      pathname: '/clients',
    })
  }

  const canEdit = superuser || can_edit_discount_codes

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        count: (
          <Button
            size="small"
            type="text"
            className="list-view-item-bold list-item-underline px-0"
            style={{ border: 'none' }}
            onClick={() => redirectToClients(item.code)}
          >
            {item.count}
          </Button>
        ),
        key: v4(),
      })),
      total,
      page,
    }
  }, [rows, total, page])

  return (
    <NavContainer menu={navMenuParse}>
      <TableFilter
        deleteItems={deleteItems}
        columns={initColumns}
        data={dataSource}
        getDataAction={dcActions.getAll}
        isEditOnId={!!canEdit}
        advancedSearch={advancedSearch}
        showAdvancedButton={!!true}
      />
    </NavContainer>
  )
}

export default ManageItems
