import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import MainLayout from './main'

NProgress.configure({ showSpinner: false })

const mapStateToProps = ({ user }) => ({ user })
let previousPath = ''

const Layout = ({ children, location: { pathname, search } }) => {
  // NProgress & ScrollTop Management
  const currentPath = pathname + search
  if (currentPath !== previousPath) {
    window.scrollTo(0, 0)
    NProgress.start()
  }
  setTimeout(() => {
    NProgress.done()
    previousPath = currentPath
  }, 300)

  return (
    <Fragment>
      <Helmet titleTemplate="Handwrytten | %s" title=" Admin" />
      <MainLayout>{children}</MainLayout>
    </Fragment>
  )
}

export default withRouter(connect(mapStateToProps)(Layout))
