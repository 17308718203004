import React from 'react'
import { Button, Modal, Typography } from 'antd'

const RefundBulkOrderModal = ({ isVisible, onCloseModal, refundChildOrder, refundParentOrder }) => {
  return (
    <Modal
      maskClosable={false}
      open={isVisible}
      footer={[
        <Button key="submit-1" type="primary" onClick={refundChildOrder}>
          Refund Current Order
        </Button>,
        <Button key="submit-2" type="primary" onClick={refundParentOrder}>
          Refund Full Order
        </Button>,
      ]}
      onCancel={onCloseModal}
    >
      <Typography.Text>
        This order is part of bulk order. Would you like to cancel full order instead?
      </Typography.Text>
    </Modal>
  )
}

export default RefundBulkOrderModal
