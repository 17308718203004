import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { Image } from 'antd'
import NavContainer from '../../../../components/nav-container'
import ItemViewer from '../../../../components/item-viewer'
import { cardsActions, queryParamsActions } from '../../../../_actions'
import getCurrentUrl from '../../../../_helpers/url-utils'
import { appConstants, cardsConstants } from '../../../../_constants'
import useCardDelete from 'hooks/cards/useCardDelete'
import { getCardById, getCategories } from '../../../../_selectors/cards.selector.js'

const {
  navMenu,
  viewItemKeyToLabel,
  cardStatuses,
  cardOnHomePage,
  orientationKeys,
} = cardsConstants

const navMenuInit = [
  navMenu.list,
  navMenu.create,
  navMenu.edit,
  navMenu.delete,
  navMenu.manage,
  navMenu.images,
]

const ViewItem = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const { id } = useParams()
  const { deleteCardHandler } = useCardDelete()

  const card = useSelector(getCardById)
  const category = useSelector(getCategories)

  const [navMenuParse, setNavMenuParse] = useState([])
  const view = getCurrentUrl(match.url)

  useEffect(() => {
    dispatch(cardsActions.getItemById(id))
    return () => {
      dispatch({ type: cardsConstants.SETBYID })
    }
  }, [id, dispatch])

  const onEditItem = () => {
    history.push({
      pathname: `${view}/edit/${id}`,
    })
  }

  const navigateToCardImage = () => {
    dispatch(queryParamsActions.setQueryParams({ card_id: card.id }))
    history.push({
      pathname: `/card-images/${card.id}`,
    })
  }

  useEffect(() => {
    const newMenu = navMenuInit.map(menu => {
      switch (menu.key) {
        case navMenu.edit.key:
          return {
            ...menu,
            url: onEditItem,
          }
        case navMenu.delete.key:
          return {
            ...menu,
            url: () => deleteCardHandler(id),
          }
        case navMenu.images.key:
          return {
            ...menu,
            url: navigateToCardImage,
          }
        default:
          return menu
      }
    })
    setNavMenuParse([...newMenu])
  }, [navMenuInit, card])

  const viewItem = useMemo(() => {
    if (card) {
      return Object.keys(viewItemKeyToLabel).reduce((obj, sKey) => {
        let itemValue
        if (sKey === 'status') {
          itemValue =
            cardStatuses.find(status => status.value.toString() === card.status.toString())?.text ||
            'Unknown Status'
        } else if (sKey === 'orientation') {
          itemValue = orientationKeys[card.orientation] || 'Unknown Orientation'
        } else if (sKey === 'home_card') {
          itemValue =
            cardOnHomePage.find(status => status.value.toString() === card.home_card.toString())
              ?.text || 'Unknown'
          // } else if (sKey === 'full_bleed') {
          //   itemValue =
          //     fullBleed.find(status => status.value.toString() === card.full_bleed.toString())
          //       ?.text || 'Unknown'
        } else if (sKey === 'category') {
          const currentCategory = category.find(cat => cat.id === card.category_id)
          itemValue = currentCategory ? `[${currentCategory.id}] ${currentCategory.name}` : ''
        } else if (sKey === 'low_stock_threshold') {
          itemValue = card.low_stock_threshold || 'Not set'
        } else if (sKey === 'notes') {
          itemValue = card.notes === null ? 'Not set' : card.notes
        } else if (sKey === 'user_id') {
          itemValue = card.user_id === null ? 'Not set' : card.user_id
        } else if (sKey === 'cover_image') {
          itemValue = (
            <Image
              width={200}
              src={`${appConstants.IMAGES_URL}/cardimages/${card.cover}`}
              fallback={appConstants.FALLBACK_IMAGE}
              preview={false}
            />
          )
        } else if (sKey === 'half_inside_image') {
          itemValue = (
            <Image
              width={200}
              src={`${appConstants.IMAGES_URL}/cardimages/${card.half_inside}`}
              fallback={appConstants.FALLBACK_IMAGE}
              preview={false}
            />
          )
        } else {
          itemValue = card[sKey]
        }
        return {
          ...obj,
          [sKey]: itemValue,
        }
      }, {})
    }
    return {}
  }, [card, category])

  return (
    <NavContainer menu={navMenuParse}>
      <ItemViewer item={viewItem} keyToLabelMap={viewItemKeyToLabel} />
    </NavContainer>
  )
}

export default ViewItem
