import React, { useMemo } from 'react'
import { Button, Space, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { CopyOutlined } from '@ant-design/icons'

import { labelsCreationActions } from '_actions'
import { labelsCreationConstants } from '_constants'
import TableFilter from 'components/table-filter'
import './style.scss'

const { archiveAdvancedSearch } = labelsCreationConstants

const initColumns = [
  {
    title: '',
    dataIndex: 'img',
    key: 'img',
    hideFilter: true,
    // eslint-disable-next-line react/display-name
    render: imageUrl =>
      imageUrl ? <img style={{ height: '30px', width: '30px' }} alt=" " src={imageUrl} /> : null,
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Card Name',
    dataIndex: 'card_name',
    key: 'card=>name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Tracking Code',
    dataIndex: 'tracking_code',
    key: 'ups_shipment=>ext_id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    hideFilter: true,
  },
  {
    title: 'Count',
    dataIndex: 'cnt',
    key: 'cnt',
    hideFilter: true,
  },
  {
    title: 'Rate Name',
    dataIndex: 'rate_name',
    key: 'rate=>name',
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user=>login',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Employee',
    dataIndex: 'employee',
    key: 'shipment_employee=>username',
  },
  {
    title: 'Date Mailed',
    dataIndex: 'date_mailed',
    key: 'date_mailed*date',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    hideFilter: true,
  },
]

const ArchivedLabelsList = () => {
  const { rows, total, page } = useSelector(state => state.labelsCreation.ordersWithArchivedLabels)

  const rowClassName = isError => {
    let classes = ''
    if (isError) classes = `red-bg`
    return classes
  }

  const getRowAdditionalInfo = row => {
    const { name, business_name, address1, address2, city, state, zip, country } = row.address

    const convertedAddress = `${name ? `${name},` : ''} ${
      business_name ? `${business_name},` : ''
    } ${address1 ? `${address1},` : ''} ${address2 ? `${address2},` : ''} ${
      city ? `${city},` : ''
    } ${state ? `${state},` : ''} ${zip ? `${zip}` : ''} ${country ? `, ${country}` : ''}`

    if (!convertedAddress.trim().length && !row.error_message) return null

    return (
      <Space direction="vertical">
        {!!convertedAddress.trim().length && (
          <Space align="start">
            <Typography.Text strong>Address:</Typography.Text>
            <Typography.Text style={{ whiteSpace: 'break-spaces' }}>
              {convertedAddress}
            </Typography.Text>
          </Space>
        )}

        {row.error_message ? (
          <Space style={{ color: 'red' }}>
            <Typography.Text strong type="danger">
              Error:
            </Typography.Text>
            <Typography.Text type="danger">{row.error_message}</Typography.Text>
          </Space>
        ) : null}
      </Space>
    )
  }

  const dataSource = useMemo(() => {
    return {
      rows: rows?.map(item => ({
        ...item,
        user: item.user ? (
          <Link to={`/clients/edit/${item.user.id}`}> {item.user.email} </Link>
        ) : (
          '-'
        ),
        employee: item.employee ? (
          <Link to={`/employees/edit/${item.employee.id}`}> {item.employee.name} </Link>
        ) : (
          '-'
        ),
        tracking_code: item.tracking_code ? (
          <Space>
            <Button
              style={{ color: '#595c97' }}
              size="small"
              shape="square"
              icon={<CopyOutlined />}
              onClick={event => copyTrackingCode(event, item.tracking_code)}
            />
            <a href={item.tracking_link} target="_blank" rel="noopener noreferrer">
              {item.tracking_code}
            </a>
          </Space>
        ) : (
          '-'
        ),
        img: item.logo || null,
        key: item.id,
        className: rowClassName(item?.error_message),
        expandableContent: getRowAdditionalInfo(item) ?? null,
      })),
      total,
      page,
    }
  }, [rows, total, page])

  const copyTrackingCode = (event, code) => {
    event.stopPropagation()
    navigator.clipboard.writeText(code)
  }

  return (
    <TableFilter
      columns={initColumns}
      data={dataSource}
      getDataAction={labelsCreationActions.archivedLabels}
      advancedSearch={archiveAdvancedSearch}
      actionsColum={false}
      isEditOnId={false}
      doubleClick={false}
      expandableTable
    />
  )
}

export default ArchivedLabelsList
